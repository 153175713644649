import { Grid } from "@mui/material";
import Stripe from "./Stripe";
import Paypal from "./Paypal";
import LocalPayment from "./LocalPayment";

const PaymentMethodsDisplay = ({
  page,
  handleChangePaymentMethodUnDraggable,
}) => {
  return (
    <Grid container xs={12} sx={{ height: "fit-content" }}>
      {page?.label === "Stripe" && (
        <Stripe
          page={page}
          handleChangePaymentMethodUnDraggable={
            handleChangePaymentMethodUnDraggable
          }
        />
      )}
      {page?.label === "Paypal" && (
        <Paypal
          page={page}
          handleChangePaymentMethodUnDraggable={
            handleChangePaymentMethodUnDraggable
          }
        />
      )}
      {page?.label === "Local payment" && <LocalPayment />}
    </Grid>
  );
};

export default PaymentMethodsDisplay;
