import { createSlice } from "@reduxjs/toolkit";
import { getInitialGuideProgress } from "../../Api/common/users";

const initialState = []; // { type: null, data: null, options: null }

export const initialGuide = createSlice({
  name: "initialGuide",
  initialState,
  reducers: {
    setInitialGuide: (state, action) => {
        return action.payload
    },
  },
});


export const fetchInitialGuideProgress = (userId) => {
    return async (dispatch) => {
        try {
            const response = await getInitialGuideProgress(userId)

            if(response.status >= 200 && response.status < 300) {
                dispatch(initialGuideActions.setInitialGuide(response.data))
            }
        } catch(err) {
            console.log(err)
        }
    }
} 

export const initialGuideActions = initialGuide.actions;

export const selectInitialGuide = (state) => state.initialGuide;

export default initialGuide.reducer;
