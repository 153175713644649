import { Grid, useMediaQuery } from '@mui/material';
import { useState, useEffect } from 'react';
import AccountSettings from '../../components/Settings/AccountSettings';
import BusinessHours from '../../components/Settings/BusinessHours';
import CompanyDetails from '../../components/Settings/CompanyDetails';
import EmailSettings from '../../components/Settings/EmailSettings';
import FrontEndPanels from '../../components/Settings/FrontEndPanels';
import GeneralSettings from '../../components/Settings/GeneralSettings';
import Holidays from '../../components/Settings/Holidays';
import IntegrationsSettings from '../../components/Settings/IntegrationsSettings';
import PaymentSettings from '../../components/Settings/PaymentSettings';
import SettingsSidebar from '../../components/SettingsSidebar';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getSettings } from '../../Api/tenant/settings';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import ProcessSettings from '../../components/Settings/ProcessSettings';

const SettingsPages = ({
  fetchCurrentSettings,
  activeTab,
  currentSettings,
}) => {
  return (
    <>
      {activeTab.main === 'general-settings' && (
        <GeneralSettings
          subTab={activeTab.sub}
          fetchCurrentSettings={fetchCurrentSettings}
          currentSettings={currentSettings}
        />
      )}
      {activeTab.main === 'payment-settings' && (
        <PaymentSettings
          subTab={activeTab.sub}
          fetchCurrentSettings={fetchCurrentSettings}
          currentSettings={currentSettings}
        />
      )}
      {activeTab.main === 'company' && activeTab.sub === 'company-details' && (
        <CompanyDetails
          fetchCurrentSettings={fetchCurrentSettings}
          currentSettings={currentSettings}
        />
      )}
      {activeTab.main === 'company' && activeTab.sub === 'holidays' && (
        <Holidays
          fetchCurrentSettings={fetchCurrentSettings}
          currentSettings={currentSettings}
        />
      )}
      {/* TODO: create index file for these subTabs */}
      {activeTab.main === 'company' && activeTab.sub === 'business-hours' && (
        <BusinessHours
          fetchCurrentSettings={fetchCurrentSettings}
          currentSettings={currentSettings}
        />
      )}
      {activeTab.main === 'email-settings' && (
        <EmailSettings
          fetchCurrentSettings={fetchCurrentSettings}
          currentSettings={currentSettings}
        />
      )}
      {activeTab.main === 'integrations-settings' && (
        <IntegrationsSettings
          subTab={activeTab.sub}
          fetchCurrentSettings={fetchCurrentSettings}
          currentSettings={currentSettings}
        />
      )}
      {activeTab.main === 'account-settings' && (
        <AccountSettings
          subTab={activeTab.sub}
          fetchCurrentSettings={fetchCurrentSettings}
          currentSettings={currentSettings}
        />
      )}
      {activeTab.main === 'front-end-panels' && (
        <FrontEndPanels
          subTab={activeTab.sub}
          fetchCurrentSettings={fetchCurrentSettings}
          currentSettings={currentSettings}
        />
      )}
      {activeTab.main === 'process' && (
        <ProcessSettings
          subTab={activeTab.sub}
          fetchCurrentSettings={fetchCurrentSettings}
          currentSettings={currentSettings}
        />
      )}
    </>
  );
};

const Settings = () => {
  let [searchParams] = useSearchParams();
  const queryMain = searchParams.get('main');
  const querySub = searchParams.get('sub');
  const [activeTab, setActiveTab] = useState({
    main: queryMain ? queryMain : 'general-settings',
    sub: querySub ? querySub : 'general-settings',
  });
  const [openMenu, setOpenMenu] = useState(false);
  const [currentSettings, setCurrentSettings] = useState(null);
  let navigate = useNavigate();

  useEffect(() => {
    if (queryMain || querySub) {
      setActiveTab({
        main: queryMain && queryMain,
        sub: querySub && querySub,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);


  useEffect(() => {
    navigate(`/setting?main=${activeTab.main}&sub=${activeTab.sub}`)
  }, [activeTab, navigate])

  const mediaQuery1250 = useMediaQuery('(min-width:1250px)');

  const handleOpenMenu = () => {
    setOpenMenu(!openMenu);
  };

  const handleActiveTab = (activeTab, activeSubTab = null) => {
    setActiveTab({ main: activeTab, sub: activeSubTab });
  };

  useEffect(() => {
    setOpenMenu(false);
  }, [activeTab]);

  // API

  const fetchCurrentSettings = async () => {
    getSettings().then((res) => {
      setCurrentSettings({
        ...res.data,
      });
    });
  };

  useEffect(() => {
    fetchCurrentSettings();
  }, []);

  return (
    <Grid container xs={12} sx={{ position: 'relative' }}>
      <SettingsSidebar
        mediaQuery1250={mediaQuery1250}
        activeTab={activeTab}
        handleActiveTab={handleActiveTab}
        openMenu={openMenu}
        handleOpenMenu={handleOpenMenu}
        setOpenMenu={setOpenMenu}
      />
      <Grid
        sm={12}
        md={mediaQuery1250 ? 9.5 : 8.5}
        sx={{
          padding: { xs: '0 32px', md: '0 32px' },
          width: '100%',
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        {currentSettings ? (
          <SettingsPages
            activeTab={activeTab}
            fetchCurrentSettings={fetchCurrentSettings}
            currentSettings={currentSettings}
          />
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '80vh',
              width: '100%',
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default Settings;
