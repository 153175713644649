import { Grid, Container, Typography, Box } from "@mui/material";
import { React, useState } from "react";
import UpnizeLogo from "../../assets/images/UpnizeLogo.png";
import CustomtextField from "../../components/CustomTextField/index";
import CustomButton from "../../components/CustomButton";
import Footer from "../../components/footer";
import CustomProgress from "../../components/ProgressBar";
import { resetPassword } from "../../Api/common/users";
import { useNavigate, useParams } from "react-router-dom";
import PasswordStrengthMeter from "../../components/PasswordStrengthMeter/PasswordStrengthMeter";
import { useDispatch } from "react-redux";
import { callSnackbar } from "../../redux/slices/snackbar.slice";
import { useEffect } from "react";

function ResetPassword() {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState();
  const [loading, setLoading] = useState(false);
  const [errorPass, setErrorPass] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));
  const { token } = useParams();

  useEffect(() => {
    if (user?.id) {
      navigate("/appointment");
    }
  }, [user]);

  const reset = () => {
    if (password === passwordConfirm) {
      setLoading(true);
      resetPassword({ password }, token).then((res) => {
        if (res.status === 200) {
          dispatch(
            callSnackbar({ message: "Password updated", type: "success" })
          );
          setLoading(false);
          navigate("/login", { replace: true });
        }
      });
    } else {
      setErrorPass(true);
    }
  };

  return (
    <>
      {!user?.id && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            backgroundColor: "#F9F8FF",
            minHeight: "100vh",
            overflow: "hidden",
            px: { md: "50px", xs: "10px" },
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flex: 1,
              flexDirection: "column",
              width: "100%",
              height: "100vh",
            }}
          >
            <Grid align="center" my={6}>
              <img src={UpnizeLogo} alt="Profile" width="200px" />
            </Grid>
            <div>
              <Container
                component="section"
                maxWidth="lg"
                style={{ padding: 0 }}
              >
                <Grid
                  lg={12}
                  item
                  container
                  alignItems="stretch"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    maxWidth: "400px",
                  }}
                >
                  <div
                    style={{
                      boxShadow: "1px 2px 9px #C0C0C0",
                      padding: "2em",
                      backgroundColor: "white",
                      width: "100%",
                    }}
                  >
                    <Typography fontWeight="bold" sx={{ fontSize: "2rem" }}>
                      Enter new password
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ marginBottom: "5%", marginTop: "5px" }}
                      fontSize={"14px"}
                    >
                      Please enter your new password in the fields below.
                      Passwords are case sensitive and need to be at least 8
                      characters.
                    </Typography>
                    <Grid sx={{ marginBottom: "2%" }}>
                      <CustomtextField
                        customData={{ label: "Password" }}
                        type="password"
                        error={errorPass}
                        helperText={""}
                        onChange={(event) => {
                          setPassword(event.target.value);
                        }}
                        style={{ marginBottom: "1%" }}
                      />
                      <PasswordStrengthMeter password={password} />
                    </Grid>

                    <CustomtextField
                      customData={{ label: "Retype Password" }}
                      type="password"
                      onChange={(event) => {
                        setPasswordConfirm(event.target.value);
                      }}
                      error={errorPass}
                      helperText={
                        errorPass
                          ? "Password must be match"
                          : passwordConfirm == ""
                          ? "You need to retype the Password"
                          : " "
                      }
                      style={{ marginBottom: "5%" }}
                    />

                    {loading ? (
                      <CustomProgress />
                    ) : (
                      <CustomButton label="Reset Password" onClick={reset} />
                    )}
                  </div>
                </Grid>
              </Container>
            </div>
          </div>
          <Footer />
        </Box>
      )}
    </>
  );
}
export default ResetPassword;
