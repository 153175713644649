import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';
import { useState } from 'react';
import ServiceDetails from './serviceDetails';
import Staff from './Staff';
import Setting from './Setting';
import Extras from './Extras';
import TimeSheet from './TimeSheet';
import DisableSectionWrapper from '../../../components/DisableSectionWrapper';
import { ServiceStaff } from './ServiceStaff';
import { ServiceLocations } from './ServiceLocation';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const StyledTab = styled(Tab)({
  fontSize: '12px',
  fontWeight: 'bold',
  padding: 0,
});

export default function ServiceTab({
  categories,
  serviceData,
  setServiceData,
  // staff,
  // setStaff,
  // selectedStaff,
  // setSelectedStaff,
  image,
  setImage,
  timesheets,
  setTimesheets,
  spts,
  setSpts,
  extraService,
  setExtraService,
  errors,
  setErrors,
  durationOptions,
  timeSlotOptions,
  locations,
  setLocations,
  staffs,
  setStaffs,
}) {
  const [setting, setSetting] = useState({ isOpenPanel: false, method: [] });

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mx: 3, mt: 2 }}>
        <Box
          sx={{
            maxWidth: '500px',
            overflow: 'scroll',
            ' ::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          <Tabs
            sx={{ fontSize: '10px', width: '500px' }}
            value={value}
            onChange={handleChange}
          >
            <StyledTab label="Service Details" {...a11yProps(0)} />
            <StyledTab label="Staff" {...a11yProps(1)} />
            <StyledTab label="Location" {...a11yProps(2)} />
            {/* TODO:REMOVE FUNCTIONALITY DEPRECATED TAB */}
            {/* <StyledTab label="Staff" {...a11yProps(3)} /> */}
            <StyledTab label="Time Sheet" {...a11yProps(3)} />
            <DisableSectionWrapper>
              <StyledTab label="Extras" {...a11yProps(3)} />
            </DisableSectionWrapper>
            <DisableSectionWrapper>
              <StyledTab label="Settings" {...a11yProps(4)} />
            </DisableSectionWrapper>
          </Tabs>
        </Box>
      </Box>
      <TabPanel value={value} index={0}>
        <ServiceDetails
          serviceData={serviceData}
          setServiceData={setServiceData}
          categories={categories}
          image={image}
          setImage={setImage}
          errors={errors}
          setErrors={setErrors}
          durationOptions={durationOptions}
          timeSlot={timeSlotOptions}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ServiceStaff staffs={staffs} setStaffs={setStaffs} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ServiceLocations locations={locations} setLocations={setLocations} />
      </TabPanel>
      {/* TODO:REMOVE FUNCTIONALITY DEPRECATED TAB */}
      {/* <TabPanel value={value} index={3}>
        <Staff
          staff={staff}
          setStaff={setStaff}
          selectedStaff={selectedStaff}
          setSelectedStaff={setSelectedStaff}
          errors={errors}
        />
      </TabPanel> */}
      <TabPanel value={value} index={3}>
        <TimeSheet
          timesheets={timesheets}
          setTimesheets={setTimesheets}
          spts={spts}
          setSpts={setSpts}
          errors={errors}
        />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Extras
          extraService={extraService}
          setExtraService={setExtraService}
          errors={errors}
        />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Setting setting={setting} setSetting={setSetting} />
      </TabPanel>
    </Box>
  );
}
