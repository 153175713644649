import { useEffect, useState } from 'react';
import { getCategoryList } from '../Api/tenant/categories';

const useCategory = () => {
  const [categoriesList, setCategoriesList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  useEffect(() => {
    const fetchCategoriesNames = async () => {
      getCategoryList().then((res) => {
        setCategoriesList(res.data);
        setCategories(res.data.filter((cat) => cat.parentId === null && cat.level));
        setSubCategories(res.data.filter((cat) => cat.parentId || !cat.level));
      });
    };
    fetchCategoriesNames();
  }, []);

  return { categories, subCategories, categoriesList };
};
export default useCategory;
