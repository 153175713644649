import axios from 'axios';
import { deleteDelay } from '../providers/SnackbarProvider';
import { callSnackbar } from '../redux/slices/snackbar.slice';
import { store } from '../redux/store';
import { sleep } from '../utils/sleep';

let headersList = {
  Accept: '*/*',
};

export let controller;

export const abortRequest = () => {
  const newController = new AbortController();
  controller = newController;
  return newController;
};

// Common instance
export const UpnizePublicBackend = axios.create({
  baseURL: `${process.env.REACT_APP_UPNIZE_BACKEND_URL}/public`,
  headers: headersList,
  withCredentials: process.env.DEPLOY_TARGET === 'production' ? true : false,
});

UpnizePublicBackend.interceptors.request.use(
  async (req) => {
    const user = JSON.parse(localStorage.getItem('user'));
    console.log(user?.TenantId);

    return {
      ...req,
      headers: {
        ...req.headers,
        TenantId: user?.TenantId,
        UserId: user?.id,
      },
    };
  },
  function (error) {
    console.log('error', error);

    store.dispatch(callSnackbar({ message: error.message, type: 'error' }));

    return Promise.reject(error);
  }
);

// Tenant instance
export const UpnizeBackend = axios.create({
  baseURL: `${process.env.REACT_APP_UPNIZE_BACKEND_URL}/tenant`,
  headers: headersList,
  withCredentials: process.env.DEPLOY_TARGET === 'production' ? true : false,
});

export const UpnizeAdminBackend = axios.create({
  baseURL: `${process.env.REACT_APP_UPNIZE_BACKEND_URL}/admin`,
  headers: headersList,
  withCredentials: process.env.DEPLOY_TARGET === 'production' ? true : false,
});

// Customer instance
export const UpnizeCustomerBackend = axios.create({
  baseURL: `${process.env.REACT_APP_UPNIZE_BACKEND_URL}/customer`,
  headers: headersList,
  withCredentials: process.env.DEPLOY_TARGET === 'production' ? true : false,
});

UpnizeCustomerBackend.interceptors.request.use(
  async (req) => {
    const user = JSON.parse(localStorage.getItem('user'));

    if (req.method === 'delete') {
      store.dispatch(
        callSnackbar({
          message: 'Deleting record',
          type: 'warning',
        })
      );
      await sleep(deleteDelay);
    }
    console.log(user?.TenantId);

    return controller
      ? {
          ...req,
          signal: controller.signal,
          headers: {
            ...req.headers,
            TenantId: user?.TenantId,
            UserId: user?.id,
          },
        }
      : {
          ...req,
          headers: {
            ...req.headers,
            TenantId: user?.TenantId,
            UserId: user?.id,
          },
        };
  },
  function (error) {
    console.log('error', error);

    store.dispatch(callSnackbar({ message: error.message, type: 'error' }));

    return Promise.reject(error);
  }
);

UpnizeBackend.interceptors.request.use(
  async (req) => {
    const user = JSON.parse(localStorage.getItem('user'));

    // if (req.method === 'delete') {
    //   store.dispatch(
    //     callSnackbar({
    //       message: 'Deleting record',
    //       type: 'warning',
    //     })
    //   );
    //   await sleep(deleteDelay);
    // }
    console.log(user?.TenantId);

    return controller
      ? {
          ...req,
          signal: controller.signal,
          headers: {
            ...req.headers,
            TenantId: user?.TenantId,
            UserId: user?.id,
          },
        }
      : {
          ...req,
          headers: {
            ...req.headers,
            TenantId: user?.TenantId,
            UserId: user?.id,
          },
        };
  },
  function (error) {
    console.log('error', error);

    store.dispatch(callSnackbar({ message: error.message, type: 'error' }));

    return Promise.reject(error);
  }
);

UpnizeBackend.interceptors.response.use(
  (res) => {
    if (res.config.method !== 'get' && typeof res.data == 'string') {
      store.dispatch(
        store.dispatch(callSnackbar({ message: res?.data, type: 'success' }))
      );
    }
    return res;
  },
  function (error) {
    console.log('error', error);
    if (error.code === 'ERR_CANCELED') {
      store.dispatch(callSnackbar({ message: 'Canceled', type: 'success' }));
      controller = null;
    } else if (error.response.status === 401) {
      localStorage.removeItem('user');
      window.location.replace('/login');
    } else {
      store.dispatch(callSnackbar({ message: error.message, type: 'error' }));
    }
    return Promise.reject(error);
  }
);

//test
