import Drawer from '../../../components/Drawer';
import DrawerFooter from '../../../components/DrawerFooter';
import Grid from '@mui/material/Grid';
import { useMediaQuery, useTheme } from '@mui/material';
import ServiceTab from '../../Services/Tabs';
import CustomTextButton from '../../../components/CustomButtons/CustomTextButton';
import useServiceDrawer from './useServiceDrawer';
import { crudTypes } from '../../../constants/crudTypes';
import { timeSlotOptions } from '../../../constants/timeSlotOptions';

const ServiceDrawer = ({title, crudType, submitTitle, data=null, refetchData=null}) => {
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('sm'));

  const {
    categories,
    durationOptions,
    staff,
    errors,
    serviceData,
    image,
    extraService,
    selectedStaff,
    timesheets,
    spts,
    toggleDrawer,
    setImage,
    setStaff,
    setSelectedStaff,
    setTimesheets,
    setSpts,
    setServiceData,
    setExtraService,
    setErrors,
    handleSubmit,
  } = useServiceDrawer({crudType, data, refetchData});

  return (
    <Grid container>
      <Grid item xs={12} sm={2}>
        <Drawer
          type={crudType}
          title={title}
          toggleDrawer={toggleDrawer}
        >
          {/* -----------FORM----- */}
          <Grid sx={{ height: '100%', overflowY: 'auto', width: '100%' }}>
            {(crudType === crudTypes.ADD || crudType === crudTypes.EDIT) && (
                <ServiceTab
                  categories={categories}
                  serviceData={serviceData}
                  setServiceData={setServiceData}
                  staff={staff}
                  setStaff={setStaff}
                  selectedStaff={selectedStaff}
                  setSelectedStaff={setSelectedStaff}
                  image={image}
                  setImage={setImage}
                  timesheets={timesheets}
                  setTimesheets={setTimesheets}
                  spts={spts}
                  setSpts={setSpts}
                  extraService={extraService}
                  setExtraService={setExtraService}
                  durationOptions={durationOptions}
                  timeSlotOptions={timeSlotOptions}
                  errors={errors}
                  setErrors={setErrors}
                />
              )}
          </Grid>
          {/* -----Button */}

          <DrawerFooter>
            <>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: isSmallScreen ? 'flex-start' : 'flex-end',
                }}
              ></div>

              <div
                style={{
                  display: 'flex',
                  gap: '15px',
                  justifyContent: isSmallScreen ? 'space-between' : 'flex-end',
                  width: isSmallScreen ? '100%' : 'auto',
                  alignItems: 'center',
                }}
              >
                <CustomTextButton
                  variant="contained"
                  size="large"
                  onClick={() => {
                    toggleDrawer();
                  }}
                >
                  Cancel
                </CustomTextButton>
                <CustomTextButton
                  type="blue"
                  variant="contained"
                  size="large"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  {submitTitle || "Save"}
                </CustomTextButton>
              </div>
            </>
          </DrawerFooter>
        </Drawer>
      </Grid>
    </Grid>
  );
};

export default ServiceDrawer;
