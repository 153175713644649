import React from "react";
import zxcvbn from "zxcvbn";
import LinearProgress from "@mui/material/LinearProgress";
import { Typography } from "@mui/material";
import Container from "@mui/material/Container";

const PasswordStrengthMeter = ({ password }) => {
  const testResult = zxcvbn(password);
  const num = (testResult.score * 100) / 4;

  const createPassLabel = () => {
    switch (testResult.score) {
      case 0:
        return "Very weak";
      case 1:
        return "Weak";
      case 2:
        return "Fear";
      case 3:
        return "Good";
      case 4:
        return "Strong";
      default:
        return "";
    }
  };

  const funcProgressColor = () => {
    switch (testResult.score) {
      case 0:
        return "#828282";
      case 1:
        return "#EA1111";
      case 2:
        return "#FFAD00";
      case 3:
        return "#9bc158";
      case 4:
        return "#00b500";
      default:
        return "none";
    }
  };

  const changePasswordColor = () => ({
    // width: `${num}`,
    background: funcProgressColor(),
    height: "8px",
  });

  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "space-between",
        padding: "0 !important",
      }}
    >
      <Container
        sx={{
          display: "flex",
          padding: "0 !important",
        }}
      >
        {password ? (
          <>
            <Typography
              variant="p"
              component="p"
              sx={{ marginRight: "5px", color: "#828282" }}
            >
              Strength:
            </Typography>
            <Typography
              sx={{ color: funcProgressColor() }}
              variant="p"
              component="p"
            >
              {createPassLabel()}
            </Typography>
          </>
        ) : (
          <div style={{ height: "21px" }}></div>
        )}
        {/* <Typography
          variant="p"
          component="p"
          sx={{ marginRight: "5px", color: "#828282" }}
        >
          Strength:
        </Typography>
        <Typography
          sx={{ color: funcProgressColor() }}
          variant="p"
          component="p"
        >
          {createPassLabel()}
        </Typography> */}
      </Container>
      <LinearProgress
        variant="determinate"
        sx={{
          width: "25%",
          marginTop: "10px",
          "&.MuiLinearProgress-colorPrimary": {
            backgroundColor: "#eee",
          },
          "& .MuiLinearProgress-bar": {
            backgroundColor: changePasswordColor(),
          },
        }}
        value={num}
      />
    </Container>
  );
};

export default PasswordStrengthMeter;
