import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { useState } from 'react';
import {
  InputAdornment,
  Menu,
  MenuItem,
  MenuList,
  TextField,
  Typography,
} from '@mui/material';
import ResponsiveTable from '../../../components/ResponsiveTable';
import CloseIcon from '@mui/icons-material/Close';
import classes from './invoices.module.css';
import StatusChip from '../../../components/StatusChip';
import NoData from '../../../components/NoData';

const Index = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const [activeTab, setActiveTab] = useState('notPaid');
  const [filterMenuList, setFilterMenuList] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [menuFilterInput, setMenuFilterInput] = useState('');
  const [filterMenuValue, setFilterMenuValue] = useState({
    eventType: '',
  });
  const [isTableLoading, setIsTableLoading] = useState(true);

  const handleCheckedMenuFilter = (value) => {
    const menuName = anchorEl.getAttribute('name');

    setFilterMenuValue({
      ...filterMenuValue,
      [menuName]: value,
    });

    handleMenuClose();
  };

  const columns = [
    {
      field: 'id',
      headerName: 'No   ',
      width: 250,
      renderCell: (params) => (
        <Grid
          container
          xs={7}
          sx={{
            marginY: { lg: 2, md: 2 },
            ml: { lg: 2, md: 2 },
          }}
        >
          <div
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {params.value}
          </div>
        </Grid>
      ),
      renderHeader: (params) => (
        <Grid
          container
          xs={7}
          sx={{ marginY: 1, ml: 2, fontWeight: 900, fontSize: 12 }}
        >
          {params.colDef.headerName}
        </Grid>
      ),
    },
    {
      field: 'name',
      headerName: 'COMPANY NAME',
      minWidth: 300,
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'STATUS',
      minWidth: 200,
      flex: 1,
      renderCell: (params) => (
        <>
          {params.value != 'paid' ? (
            <StatusChip
              color={'#EA9B7F'}
              bgColor={'#FBEEE9'}
              text={'Not paid'}
              rounded={true}
              width={'max-content'}
            />
          ) : (
            <StatusChip
              color={'#90D886'}
              bgColor={'#E9F7E8'}
              text={'Paid'}
              rounded={true}
              width={'max-content'}
            />
          )}
        </>
      ),
    },
    {
      field: 'actions',
      headerName: 'ACTIONS',
      type: 'actions',
      align: 'right',
      minWidth: 100,
      maxWidth: 100,
      getActions: (params) =>
        activeTab === 'history'
          ? [
              <GridActionsCellItem
                label="Remove"
                onClick={() => {}}
                showInMenu
              />,
              <GridActionsCellItem
                label="View PDF"
                onClick={() => {}}
                showInMenu
              />,
              <GridActionsCellItem
                label="Download PDF"
                onClick={() => {}}
                showInMenu
              />,
            ]
          : [
              <GridActionsCellItem
                label="Pay invoice"
                onClick={() => {}}
                showInMenu
              />,
              <GridActionsCellItem
                label="View PDF"
                onClick={() => {}}
                showInMenu
              />,
              <GridActionsCellItem
                label="Download PDF"
                onClick={() => {}}
                showInMenu
              />,
            ],
    },
  ];

  const row = [
    {
      id: 1,
      name: 'Tenant corp',
      date: 'Wed Sep 28 2022 04:15:53',
      service: 'Dental implants',
      price: '250',
      duration: '30m',
      status: 'Not paid',
    },
    {
      id: 2,
      name: 'Company with so so long name',
      date: 'Wed Sep 28 2022 04:15:53',
      service: 'Hair dress',
      price: '2450',
      duration: '2h',
      status: 'Not paid',
    },
    {
      id: 3,
      name: 'Someone',
      date: 'Wed Sep 28 2022 04:15:53',
      service: 'Nail',
      price: '250',
      duration: '60m',
      status: 'Not paid',
    },
    {
      id: 4,
      name: 'Soemone',
      date: 'Wed Sep 28 2022 04:15:53',
      service: 'Dental implants',
      service: 'Car wash',
      price: '2450',
      duration: '1h 45m',
      status: 'Not paid',
    },
    {
      id: 5,
      name: 'Alexander',
      date: 'Wed Sep 28 2022 04:15:53',
      service: 'Dental implants',
      price: '250',
      duration: '30m',
      status: 'Not paid',
    },
  ];

  const historyRows = [
    {
      id: 1,
      name: 'Tenant corp',
      date: 'Wed Sep 28 2022 04:15:53',
      service: 'Dental implants',
      price: '250',
      duration: '30m',
      status: 'paid',
    },
    {
      id: 2,
      name: 'Company with so so long name',
      date: 'Wed Sep 28 2022 04:15:53',
      service: 'Hair dress',
      price: '2450',
      duration: '2h',
      status: 'paid',
    },
    {
      id: 3,
      name: 'Someone',
      date: 'Wed Sep 28 2022 04:15:53',
      service: 'Nail',
      price: '250',
      duration: '60m',
      status: 'paid',
    },
    {
      id: 4,
      name: 'Soemone',
      date: 'Wed Sep 28 2022 04:15:53',
      service: 'Dental implants',
      service: 'Car wash',
      price: '2450',
      duration: '1h 45m',
      status: 'paid',
    },
    {
      id: 5,
      name: 'Alexander',
      date: 'Wed Sep 28 2022 04:15:53',
      service: 'Dental implants',
      price: '250',
      duration: '30m',
      status: 'paid',
    },
  ];

  const [rows, setRows] = useState(row);

  const openMenu = Boolean(anchorEl);

  const handleMenuClick = (event) => {
    const menuName = event.currentTarget.getAttribute('name');
    switch (menuName) {
      case 'eventType':
        setFilterMenuList([
          'New booking',
          'Booking rescheduled',
          'Booking status changed',
          'Booking starts',
          'Booking ends',
          'New customer created',
          'New rating',
        ]);
        break;
    }

    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuFilterInput('');
  };

  const handleMenuFilterInput = (e) => {
    const value = e.target.value;

    if (value.length === 0) {
      switch (anchorEl.getAttribute('name')) {
        case 'eventType':
          setFilterMenuList([
            'New booking',
            'Booking rescheduled',
            'Booking status changed',
            'Booking starts',
            'Booking ends',
            'New customer created',
            'New rating',
          ]);
          break;
      }
    } else {
      setFilterMenuList((prevState) =>
        prevState.filter((prevValue) => prevValue.startsWith(value))
      );
    }

    setMenuFilterInput(e.target.value);
  };

  return (
    <Grid
      item
      container
      sx={{
        minWidth: { xs: '100%' },
        paddingLeft: 4,
        paddingRight: 4,
        height: { lg: 'fit', xs: 'fit' },
      }}
    >
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openMenu}
        onClose={handleMenuClose}
        elevation={0}
        MenuListProps={{
          sx: {
            width: anchorEl && anchorEl.offsetWidth,
            padding: 0,
            border: '1px solid #E4EBF4',
            borderRadius: '4px',
            borderTop: 'none',
          },
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <input
            value={menuFilterInput}
            onChange={handleMenuFilterInput}
            type="text"
            style={{
              border: '1px solid #e3eaf3',
              outline: 'none',
              fontSize: '14px',
              borderRadius: '2px',
              height: '1.5rem',
              width: '95%',
            }}
          />
        </div>
        <div>
          <MenuList dense>
            {filterMenuList.map((value, index) => (
              <MenuItem
                sx={{
                  ':hover': {
                    backgroundColor: '#adbfc7',
                    color: 'white',
                  },
                }}
                key={`filter-${index}`}
                onClick={() => handleCheckedMenuFilter(value)}
              >
                {value}
              </MenuItem>
            ))}
          </MenuList>
        </div>
      </Menu>

      <Grid container item justifyContent="space-between">
        <Grid
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          {/* header */}
          <Typography sx={{ marginTop: 5, fontSize: 28 }}>Invoices</Typography>
          <Box className={classes.tabs}>
            <Box
              className={classes.tab}
              onClick={() => {
                setActiveTab('notPaid');
              }}
              sx={{
                borderBottom:
                  activeTab === 'notPaid' ? '#9387ED 2px solid' : 'none',
              }}
            >
              Not paid
            </Box>
            <Box
              className={classes.tab}
              onClick={() => {
                setActiveTab('history');
              }}
              sx={{
                borderBottom:
                  activeTab === 'history' ? '#9387ED 2px solid' : 'none',
              }}
            >
              History
            </Box>
          </Box>
          {/* search */}
          <Grid className={classes.search__container}>
            <TextField
              sx={{
                width: '100%',
                '& fieldset': { border: 'none' },
                fontSize: '14px !important',
                '::placeholder': { fontSize: '14px !important' },
              }}
              placeholder="Quick search"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              InputProps={{
                endAdornment: searchValue && (
                  <InputAdornment position="end">
                    <CloseIcon
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        setSearchValue('');
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {/* table */}
          <Grid
            xs={12}
            sx={{
              marginTop: '2rem',
            }}
          >
            <Box
              style={{ height: '100%', width: '100%' }}
              sx={{
                display: { xs: 'none', md: 'flex' },
                flexDirection: 'column',
              }}
            >
              <DataGrid
                sx={{
                  border: 0,
                  '& .MuiDataGrid-virtualScroller': {
                    minHeight: '300px !important',
                  },
                  '& .MuiDataGrid-columnHeaderTitle': {
                    fontSize: '12px',
                    fontWeight: '900 !important',
                  },
                  '& .MuiDataGrid-main, .MuiDataGrid-footerContainer': {
                    backgroundColor: 'white',
                    border: 'none',
                  },
                  '& .MuiDataGrid-cell:focus': {
                    outline: 'none',
                  },
                }}
                rows={activeTab === 'history' ? historyRows : rows}
                columns={columns}
                onSelectionModelChange={(newSelectionModel) => {
                  setSelectionModel(newSelectionModel);
                }}
                autoHeight={true}
                loading={isTableLoading}
                selectionModel={selectionModel}
                hideFooter
                components={{
                  NoRowsOverlay: NoData,
                  NoResultsOverlay: NoData,
                }}
              />
            </Box>
            <ResponsiveTable
              rows={activeTab === 'history' ? historyRows : rows}
              columns={columns}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Index;
