import Grid from '@mui/material/Grid';
import { useEffect } from 'react';
import { useState } from 'react';
import InvoiceForm from './InvoiceForm';
import InvoiceTable from './InvoiceTable';

const Invoice = () => {
  const [openForm, setOpenForm] = useState({ open: false, type: null });

  const handleOpenForm = (open, type = null) => {
    setOpenForm((prevState) => ({ ...prevState, open, type }));
  };

  return (
    <Grid
      item
      container
      sx={{
        minWidth: { xs: '100%' },
        px: '20px',
      }}
    >
      {!openForm.open && <InvoiceTable handleOpenForm={handleOpenForm} />}
      {openForm.open && <InvoiceForm handleOpenForm={handleOpenForm} />}
    </Grid>
  );
};

export default Invoice;
