import { useMemo, useState, useLayoutEffect, useEffect } from 'react';
import { Grid, Switch, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { ReactSortable } from 'react-sortablejs';
import { styled } from '@mui/material/styles';
import BookingStepDisplay from './BookingStepsDisplay';
import CustomSettingsTitle from '../../CustomSettingsTitle/CustomSettingsTitle';
import SaveSlideBar from '../../SaveSlideBar';
import { useDispatch } from 'react-redux';
import { editSettings } from '../../../Api/tenant/settings';
import { fetchUser } from '../../../redux/slices/user.slice.js';

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 36,
  height: 20,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 1.5,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 17,
    height: 17,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const BookingSteps = ({ fetchCurrentSettings, currentSettings }) => {
  const [isSaveBar, setIsSaveBar] = useState(false);
  // worest state managment ever created in history
  // location
  const [hideAddressOfLocation, setHideAddressOfLocation] = useState(false);
  // service
  const [collapseServiceUnderACategory, setCollapseServiceUnderACategory] =
    useState(false);
  // ServiceExtras
  const [
    ifServiceDoesntHaveExtraSkipStep,
    setIfServiceDoesntHaveExtraSkipStep,
  ] = useState(false);
  // date and time
  const [timeFormatForBookingForm, setTimeFormatForBookingForm] = useState(
    'Show both Start and End time (e.g.: 10:00 - 11:00)'
  );
  const [startTheBookingCalendarFrom, setStartTheBookingCalendarForm] =
    useState('Current month');
  const [hideTheNumberOfAvailableSlots, setHideTheNumberOfAvailableSlots] =
    useState(false);
  //number of people
  const [
    addMaxNumberOfPeopleCustomerCanBook,
    setAddMaxNumberOfPeopleCustomerCanBook,
  ] = useState('');
  // payment
  const [termsAndCondition, setTermsAndCondition] = useState(false);
  const [addUrlLink, setAddUrlLink] = useState('');
  const [
    letUserAddInformationToAppointment,
    setLetUserAddInformationToAppointment,
  ] = useState(false);
  const [letcustomerPayStripe, setLetCustomerPayStripe] = useState(false);
  const [letcustomerPayPaypal, setLetCustomerPayPaypal] = useState(false);
  const [letcustomerPayLocal, setLetCustomerPayLocal] = useState(false);
  const [letcustomerPayGiftcard, setLetCustomerPayGiftcard] = useState(false);
  // staff
  const [footerTextPerStaff, setFooterTextPerStaff] = useState(
    'Show both phone number and emaill address'
  );
  const [enableAnyStaffOption, setEnableAnyStaffOption] = useState(false);
  const [autoAssignmentRule, setAutoAssignmentRule] = useState(
    'Least assigned by the day'
  );
  //finish
  const [urlOfFinishBookingButton, setUrlOfFinishBookingButton] = useState();
  const [
    hideTheAddToGoogleCalendarButton,
    setHideTheAddToGoogleCalendarButton,
  ] = useState();
  const [hideTheStartNewBookingButton, setHideTheStartNewBookingButton] =
    useState();
  const [hideConfirmationNumber, setHideConfirmationNumber] = useState();
  const [startingConfirmationNumber, setStartingConfirmationNumber] =
    useState();
  //confirm
  const [hideGiftcardSection, setHideGiftcardSection] = useState(false);
  const [
    doNotShowDiscountRowIfDiscountNotAdded,
    setDoNotShowDiscountRowIfDiscountNotAdded,
  ] = useState(false);
  const [hidePriceSection, setHidePriceSection] = useState(false);
  const [hideCouponSection, setHideCouponSection] = useState(false);
  //info
  const [separateFirstAndLastNameInputs, setSeparateFirstAndLastNameInputs] =
    useState(false);
  const [setEmailAsRequiredField, setSetEmailAsRequiredField] = useState(false);
  const [setPhoneNumberAsRequiredField, setSetPhoneNumberAsRequiredField] =
    useState(false);
  const [defaultPhoneCountryCode, setDefaultPhoneCountryCode] = useState(false);

  // react sortable state
  const [bookingStepsDraggable, setBookingStepsDraggable] = useState([
    { id: 1, label: 'Location', checked: true },
    { id: 2, label: 'Staff', checked: true },
    { id: 3, label: 'Service', checked: true },
    { id: 4, label: 'Service Extras', checked: true },
    { id: 5, label: 'Date & Time' },
    { id: 6, label: 'Number of people', checked: true },
    { id: 7, label: 'Information' },
  ]);
  const [bookingStepsUnDraggable, setBookingStepsUnDraggable] = useState([
    { id: 8, label: 'Confirmation', checked: true },
    { id: 9, label: 'Payment', checked: true },
    { id: 10, label: 'Finish' },
  ]);
  const [bookingStepId, setBookingStepId] = useState(1);
  const selectedBookingStep = useMemo(
    () =>
      [...bookingStepsDraggable, ...bookingStepsUnDraggable].find(
        (bookingStep) => bookingStep.id === bookingStepId
      ),
    [bookingStepsDraggable, bookingStepsUnDraggable, bookingStepId]
  );

  const handleCheckBookingStepDraggable = (currentBookingStepId) => {
    return (e) => {
      setIsSaveBar(true);
      setBookingStepsDraggable((prevState) =>
        prevState.map((bookingStepPrev) =>
          bookingStepPrev.id === currentBookingStepId
            ? { ...bookingStepPrev, checked: e.target.checked }
            : bookingStepPrev
        )
      );
    };
  };

  const handleCheckBookingStepUnDraggable = (currentBookingStepId) => {
    return (e) => {
      setIsSaveBar(true);
      setBookingStepsUnDraggable((prevState) =>
        prevState.map((bookingStepPrev) =>
          bookingStepPrev.id === currentBookingStepId
            ? { ...bookingStepPrev, checked: e.target.checked }
            : bookingStepPrev
        )
      );
    };
  };

  const dispatch = useDispatch();

  const setStateToDefault = () => {
    setHideAddressOfLocation(
      currentSettings.FEPanels.BookingSteps.Location.hidden
    );
    setCollapseServiceUnderACategory(
      currentSettings.FEPanels.BookingSteps.Service.collapse
    );
    setIfServiceDoesntHaveExtraSkipStep(
      currentSettings.FEPanels.BookingSteps.serviceExtra.skip
    );
    setTimeFormatForBookingForm(
      currentSettings.FEPanels.BookingSteps.dateTime.timeFormat
    );
    setStartTheBookingCalendarForm(
      currentSettings.FEPanels.BookingSteps.dateTime.start
    );
    setHideTheNumberOfAvailableSlots(
      currentSettings.FEPanels.BookingSteps.dateTime.hideAvailableSlots
    );
    setAddMaxNumberOfPeopleCustomerCanBook(
      currentSettings.FEPanels.BookingSteps.numOfPeople.max
    );
    setTermsAndCondition(currentSettings.FEPanels.BookingSteps.Payment.TC);
    // setAddUrlLink(currentSettings.FEPanels.BookingSteps.Payment.userAddInfo)
    setLetUserAddInformationToAppointment(
      currentSettings.FEPanels.BookingSteps.Payment.userAddInfo
    );
    setLetCustomerPayStripe(
      currentSettings.FEPanels.BookingSteps.Payment.stripe
    );
    setLetCustomerPayPaypal(
      currentSettings.FEPanels.BookingSteps.Payment.paypal
    );
    setLetCustomerPayLocal(currentSettings.FEPanels.BookingSteps.Payment.local);
    setLetCustomerPayGiftcard(
      currentSettings.FEPanels.BookingSteps.Payment.giftcard
    );
    setFooterTextPerStaff(
      currentSettings.FEPanels.BookingSteps.Staff.footerPerStaff
    );
    setEnableAnyStaffOption(
      currentSettings.FEPanels.BookingSteps.Staff.enablyAnyStaff
    );
    setAutoAssignmentRule(
      currentSettings.FEPanels.BookingSteps.Staff.autoAssignment
    );

    setUrlOfFinishBookingButton(
      currentSettings.FEPanels.BookingSteps.Finish.URL
    );
    setHideTheAddToGoogleCalendarButton(
      currentSettings.FEPanels.BookingSteps.Finish.hideAddtoGC
    );
    setHideTheStartNewBookingButton(
      currentSettings.FEPanels.BookingSteps.Finish.hideStartNewBooking
    );
    setHideConfirmationNumber(
      currentSettings.FEPanels.BookingSteps.Finish.hideConfirmationNum
    );
    setStartingConfirmationNumber(
      currentSettings.FEPanels.BookingSteps.Finish.startingConfirmationNum
    );

    setHideGiftcardSection(
      currentSettings.FEPanels.BookingSteps.Confirmation.hideGiftcard
    );
    setDoNotShowDiscountRowIfDiscountNotAdded(
      currentSettings.FEPanels.BookingSteps.Confirmation.hideDiscount
    );
    setHidePriceSection(
      currentSettings.FEPanels.BookingSteps.Confirmation.hidePrice
    );
    setHideCouponSection(
      currentSettings.FEPanels.BookingSteps.Confirmation.hideCoupon
    );

    setSeparateFirstAndLastNameInputs(
      currentSettings.FEPanels.BookingSteps.Information.seperateInput
    );
    setSetEmailAsRequiredField(
      currentSettings.FEPanels.BookingSteps.Information.requiredEmail
    );
    setSetPhoneNumberAsRequiredField(
      currentSettings.FEPanels.BookingSteps.Information.requiredPhone
    );
    setDefaultPhoneCountryCode(
      currentSettings.FEPanels.BookingSteps.Information.phoneNumber
    );
  };

  useLayoutEffect(() => {
    setStateToDefault();
  }, []);

  // click handlers
  const handleSave = () => {
    editSettings({
      ...currentSettings,
      FEPanels: {
        ...currentSettings.FEPanels,
        BookingSteps: {
          Location: {
            enabled: bookingStepsDraggable.filter(
              (el) => el.label == 'Location'
            ).checked,
            hidden: hideAddressOfLocation,
          },
          Staff: {
            enabled: bookingStepsDraggable.filter((el) => el.label == 'Staff')
              .checked,
            footerPerStaff: footerTextPerStaff,
            enablyAnyStaff: enableAnyStaffOption,
            autoAssignment: autoAssignmentRule,
          },
          Service: {
            enabled: bookingStepsDraggable.filter((el) => el.label == 'Service')
              .checked,
            collapse: collapseServiceUnderACategory,
          },
          serviceExtra: {
            enabled: bookingStepsDraggable.filter(
              (el) => el.label == 'Service Extras'
            ).checked,
            skip: ifServiceDoesntHaveExtraSkipStep,
          },
          dateTime: {
            timeFormat: timeFormatForBookingForm,
            start: startTheBookingCalendarFrom,
            hideAvailableSlots: hideTheNumberOfAvailableSlots,
          },
          numOfPeople: {
            enabled: bookingStepsDraggable.filter(
              (el) => el.label == 'Number of people'
            ).checked,
            max: addMaxNumberOfPeopleCustomerCanBook,
          },
          Information: {
            seperateInput: separateFirstAndLastNameInputs,
            requiredEmail: setEmailAsRequiredField,
            requiredPhone: setPhoneNumberAsRequiredField,
            phoneNumber: defaultPhoneCountryCode,
          },
          Confirmation: {
            enabled: bookingStepsUnDraggable[0].checked,
            hideGiftcard: hideGiftcardSection,
            hideDiscount: doNotShowDiscountRowIfDiscountNotAdded,
            hidePrice: hidePriceSection,
            hideCoupon: hideCouponSection,
          },
          Payment: {
            enabled: bookingStepsUnDraggable[1].checked,
            TC: termsAndCondition,
            userAddInfo: letUserAddInformationToAppointment,
            stripe: letcustomerPayStripe,
            paypal: letcustomerPayPaypal,
            local: letcustomerPayLocal,
            giftcard: letcustomerPayGiftcard,
          },
          Finish: {
            URL: urlOfFinishBookingButton,
            hideAddtoGC: hideTheAddToGoogleCalendarButton,
            hideStartNewBooking: hideTheStartNewBookingButton,
            hideConfirmationNum: hideConfirmationNumber,
            startingConfirmationNum: startingConfirmationNumber,
          },
        },
      },
    }).then((res) => {
      if (res.status === 200) {
        fetchCurrentSettings();
        dispatch(fetchUser())
          .unwrap()
          .then((res) => {
            setIsSaveBar(false);
          });
      }
    });
  };

  const handleCancel = () => {
    setStateToDefault();
    setIsSaveBar(false);
  };

  return (
    <>
      <Grid xs={12} style={{ backgroundColor: 'white', marginTop: '20px' }}>
        <Grid
          sx={{
            padding: { xs: '15px', md: '15px 25px' },
            borderBottom: '1px solid #e3eaf3',
          }}
        >
          <CustomSettingsTitle title="Front-end Panels" subTitle="Steps" />
        </Grid>
        <Grid
          container
          rowGap="30px"
          sx={{ padding: { xs: '15px', md: '15px 25px' } }}
        >
          <Grid
            container
            rowGap="10px"
            xs={12}
            lg={5}
            sx={{ paddingRight: { xs: '0px', lg: '30px' } }}
          >
            <ReactSortable
              list={bookingStepsDraggable}
              setList={setBookingStepsDraggable}
              onChange={() => {
                setIsSaveBar(true);
              }}
              animation={200}
              delayOnTouchStart={true}
              delay={2}
              handle=".dragable-icon"
              style={{
                display: 'flex',
                rowGap: '10px',
                flexWrap: 'wrap',
                width: '100%',
              }}
            >
              {bookingStepsDraggable.map((bookingStep, index) => (
                <Grid
                  key={index}
                  onClick={() => setBookingStepId(bookingStep.id)}
                  xs={12}
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    width: '100%',
                    border: '1px solid #e3eaf3',
                    padding: '11px 15px',
                    borderRadius: '2px',
                    cursor: 'pointer',
                    background:
                      bookingStepId === bookingStep.id
                        ? 'rgba(108,112,220,.1)'
                        : 'none',
                  }}
                >
                  <Grid container alignItems="center" xs={10}>
                    <MenuIcon
                      className="dragable-icon"
                      sx={{
                        cursor: 'move',
                        color:
                          bookingStepId === bookingStep.id
                            ? '#6C70DC'
                            : '#ADBFC7',
                        opacity: 1,
                      }}
                    />
                    <Typography sx={{ marginLeft: '10px', fontSize: '14px' }}>
                      {bookingStep.label}
                    </Typography>
                  </Grid>
                  {bookingStep.hasOwnProperty('checked') && (
                    <IOSSwitch
                      checked={bookingStep.checked}
                      onChange={handleCheckBookingStepDraggable(bookingStep.id)}
                    />
                  )}
                </Grid>
              ))}
            </ReactSortable>

            <Grid
              style={{
                display: 'flex',
                rowGap: '10px',
                flexWrap: 'wrap',
                width: '100%',
              }}
            >
              {bookingStepsUnDraggable.map((bookingStep, index) => (
                <Grid
                  key={index}
                  onClick={() => setBookingStepId(bookingStep.id)}
                  xs={12}
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    width: '100%',
                    border: '1px solid #e3eaf3',
                    padding: '11px 15px',
                    borderRadius: '2px',
                    cursor: 'pointer',
                    background:
                      bookingStepId === bookingStep.id
                        ? 'rgba(108,112,220,.1)'
                        : 'none',
                  }}
                >
                  <Grid container alignItems="center" xs={10}>
                    <MenuIcon
                      sx={{
                        cursor: 'not-allowed',
                        color:
                          bookingStepId === bookingStep.id
                            ? '#6C70DC'
                            : '#ADBFC7',
                        opacity: 0.4,
                      }}
                    />
                    <Typography sx={{ marginLeft: '10px', fontSize: '14px' }}>
                      {bookingStep.label}
                    </Typography>
                  </Grid>
                  {bookingStep.hasOwnProperty('checked') && (
                    <IOSSwitch
                      checked={bookingStep.checked}
                      onChange={handleCheckBookingStepUnDraggable(
                        bookingStep.id
                      )}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid
            container
            xs={12}
            lg={7}
            sx={{
              padding: '20px 50px',
              background:
                'linear-gradient(to right,#c0d6df 50%,transparent 0%),linear-gradient(#c0d6df 50%,transparent 0%),linear-gradient(to right,#c0d6df 50%,transparent 0%),linear-gradient(#c0d6df 50%,transparent 0%)',
              backgroundPosition: 'top,right,bottom,left',
              backgroundRepeat: 'repeat-x,repeat-y',
              backgroundSize: '8px 1px,1px 8px',
            }}
          >
            <BookingStepDisplay
              page={selectedBookingStep.label}
              hideAddressOfLocation={hideAddressOfLocation}
              setHideAddressOfLocation={setHideAddressOfLocation}
              collapseServiceUnderACategory={collapseServiceUnderACategory}
              setCollapseServiceUnderACategory={
                setCollapseServiceUnderACategory
              }
              ifServiceDoesntHaveExtraSkipStep={
                ifServiceDoesntHaveExtraSkipStep
              }
              setIfServiceDoesntHaveExtraSkipStep={
                setIfServiceDoesntHaveExtraSkipStep
              }
              timeFormatForBookingForm={timeFormatForBookingForm}
              setTimeFormatForBookingForm={setTimeFormatForBookingForm}
              startTheBookingCalendarFrom={startTheBookingCalendarFrom}
              setStartTheBookingCalendarForm={setStartTheBookingCalendarForm}
              hideTheNumberOfAvailableSlots={hideTheNumberOfAvailableSlots}
              setHideTheNumberOfAvailableSlots={
                setHideTheNumberOfAvailableSlots
              }
              addMaxNumberOfPeopleCustomerCanBook={
                addMaxNumberOfPeopleCustomerCanBook
              }
              setAddMaxNumberOfPeopleCustomerCanBook={
                setAddMaxNumberOfPeopleCustomerCanBook
              }
              termsAndCondition={termsAndCondition}
              setTermsAndCondition={setTermsAndCondition}
              addUrlLink={addUrlLink}
              setAddUrlLink={setAddUrlLink}
              letUserAddInformationToAppointment={
                letUserAddInformationToAppointment
              }
              setLetUserAddInformationToAppointment={
                setLetUserAddInformationToAppointment
              }
              letcustomerPayStripe={letcustomerPayStripe}
              setLetCustomerPayStripe={setLetCustomerPayStripe}
              letcustomerPayPaypal={letcustomerPayPaypal}
              setLetCustomerPayPaypal={setLetCustomerPayPaypal}
              letcustomerPayLocal={letcustomerPayLocal}
              setLetCustomerPayLocal={setLetCustomerPayLocal}
              letcustomerPayGiftcard={letcustomerPayGiftcard}
              setLetCustomerPayGiftcard={setLetCustomerPayGiftcard}
              footerTextPerStaff={footerTextPerStaff}
              setFooterTextPerStaff={setFooterTextPerStaff}
              enableAnyStaffOption={enableAnyStaffOption}
              setEnableAnyStaffOption={setEnableAnyStaffOption}
              autoAssignmentRule={autoAssignmentRule}
              setAutoAssignmentRule={setAutoAssignmentRule}
              separateFirstAndLastNameInputs={separateFirstAndLastNameInputs}
              setSeparateFirstAndLastNameInputs={
                setSeparateFirstAndLastNameInputs
              }
              setEmailAsRequiredField={setEmailAsRequiredField}
              setSetEmailAsRequiredField={setSetEmailAsRequiredField}
              setPhoneNumberAsRequiredField={setPhoneNumberAsRequiredField}
              setSetPhoneNumberAsRequiredField={
                setSetPhoneNumberAsRequiredField
              }
              defaultPhoneCountryCode={defaultPhoneCountryCode}
              setDefaultPhoneCountryCode={setDefaultPhoneCountryCode}
              hideGiftcardSection={hideGiftcardSection}
              setHideGiftcardSection={setHideGiftcardSection}
              doNotShowDiscountRowIfDiscountNotAdded={
                doNotShowDiscountRowIfDiscountNotAdded
              }
              setDoNotShowDiscountRowIfDiscountNotAdded={
                setDoNotShowDiscountRowIfDiscountNotAdded
              }
              hidePriceSection={hidePriceSection}
              setHidePriceSection={setHidePriceSection}
              hideCouponSection={hideCouponSection}
              setHideCouponSection={setHideCouponSection}
              urlOfFinishBookingButton={urlOfFinishBookingButton}
              setUrlOfFinishBookingButton={setUrlOfFinishBookingButton}
              hideTheAddToGoogleCalendarButton={
                hideTheAddToGoogleCalendarButton
              }
              setHideTheAddToGoogleCalendarButton={
                setHideTheAddToGoogleCalendarButton
              }
              hideTheStartNewBookingButton={hideTheStartNewBookingButton}
              setHideTheStartNewBookingButton={setHideTheStartNewBookingButton}
              hideConfirmationNumber={hideConfirmationNumber}
              setHideConfirmationNumber={setHideConfirmationNumber}
              startingConfirmationNumber={startingConfirmationNumber}
              setStartingConfirmationNumber={setStartingConfirmationNumber}
              setIsSaveBar={setIsSaveBar}
            />
          </Grid>
        </Grid>
      </Grid>
      <SaveSlideBar
        handleCancel={handleCancel}
        handleSave={handleSave}
        isSaveBar={isSaveBar}
        setIsSaveBar={setIsSaveBar}
      />
    </>
  );
};

export default BookingSteps;
