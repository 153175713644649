import { UpnizeBackend } from '../Axios';

export const getTaxList = async (rows, page, queries) => {
  let response = await UpnizeBackend.get(
    `/taxes?rows=${rows}&page=${page}${queries ? queries : ''}`
  );
  return response;
};

export const getTaxById = async (id) => {
  let response = await UpnizeBackend.get(`/taxes/byId/${id}`);
  return response;
};

export const addTax = async (body) => {
  let response = await UpnizeBackend.post(`/taxes/add`, body);
  return response;
};

export const editTaxById = async (body, id) => {
  let response = await UpnizeBackend.put(`/taxes/edit/${id}`, body);
  return response;
};

export const deleteTaxById = async (id) => {
  let response = await UpnizeBackend.delete(`/taxes/delete`, {
    data: { ids: id },
  });
  return response;
};
