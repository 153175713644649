import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Checkbox, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { addTask, deleteTaskById, editTaskById } from '../../Api/tenant/tasks';
import CustomTextButton from '../../components/CustomButtons/CustomTextButton';

function YourTasksWidget({ tasks, refetchDashboardData }) {
  const [taskValue, setTaskValue] = useState('');
  const [error, setError] = useState(null)

  const handleCompleteTask = async (id, value, prevTaskObject) => {
    editTaskById({ ...prevTaskObject, completed: value }, id).then(() => {
      refetchDashboardData();
    });
  };
  const handleAddTask = async () => {
    setError(null)
    if(!taskValue) {
      setError("Task Required")
      return
    } 
    try {
      await addTask({
        title: taskValue,
        completed: 'false',
        description: 'No description',
        status: 'In progress',
      });
      setTaskValue('');
      refetchDashboardData();
    } catch (err) {
      console.log(err);
    }
  };
  const handleDeleteTask = async (id) => {
    try {
      await deleteTaskById(id);
      refetchDashboardData();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Grid
      item
      xs={12}
      sm={6}
      lg={3}
      sx={{
        mb: '20px',
        height: '368px',
      }}
    >
      <Card
        sx={{
          borderRadius: '0px',
          height: '368px',
          boxShadow: 'rgba(58, 58, 80, 0.1) 0px 0px 15px',
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            padding: '0',
            height: '368px',
          }}
        >
          <Typography
            sx={{
              fontWeight: 'bold',
              mb: '16px',
              px: '20px',
              pt: '16px',
              color: '#3A3A50',
            }}
          >
            My Tasks
          </Typography>
          <Grid
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '12px',
              mb: '16px',
              px: '20px',
            }}
          >
            <TextField
              value={taskValue}
              onChange={(e) => setTaskValue(e.target.value)}
              placeholder="Write your task here..."
              variant="standard"
              sx={{
                width: '100%',
              }}
              error={error}
              helperText={error && error}
            />
            <CustomTextButton
              sx={{
                margin: 0,
                fontSize: "14px",
                width: {
                  sx: "fit-content"
                }
              }}
              text="+ Add"
              onClick={handleAddTask}
            />
          </Grid>
          <Grid
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '4px',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
              pb: '4px',
            }}
          >
            {tasks &&
              tasks.map((task, index) => (
                <Box
                  sx={{
                    // width: '100%',
                    backgroundColor: '#F6FAFF',
                    py: '10px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    px: '20px',
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      color: '#3A3A50',
                      textDecoration: task.completed ? 'line-through' : 'auto',
                    }}
                    onClick={() =>
                      handleCompleteTask(task.id, !tasks[index].completed, task)
                    }
                  >
                    {task.title}
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '5px',
                    }}
                  >
                    <Checkbox
                      sx={{
                        padding: '0',
                      }}
                      checked={task.completed}
                      onChange={(e) =>
                        handleCompleteTask(task.id, e.target.checked, task)
                      }
                    />
                    <DeleteIcon
                      onClick={() => {
                        handleDeleteTask(task.id);
                      }}
                      sx={{
                        cursor: 'pointer',
                        color: 'red',
                      }}
                    />
                  </Box>
                </Box>
              ))}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default YourTasksWidget;
