import { Box } from '@mui/material';
import React from 'react';
import CustomTextButton from '../CustomButtons/CustomTextButton';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location !== this.props.location) {
      this.setState({ hasError: false });
    }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    console.log(error);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            my: 'auto',
            flexDirection: 'column',
          }}
        >
          <h3>
            Oops! Something went wrong. Please try again later. If the issue
            persists, please contact the administrator for assistance.
          </h3>
          <CustomTextButton
            sx={{ mt: 2 }}
            onClick={() => {
              window.location.reload();
            }}
            text="Refresh the page"
          />
        </Box>
      );
    }

    return this.props.children;
  }
}
