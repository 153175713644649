import React, { useEffect, useState } from "react";
import Drawer from "../../components/Drawer";
import DrawerFooter from "../../components/DrawerFooter";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Checkbox, useMediaQuery, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ServiceTab from "./Tabs";
import { Box } from "@mui/material";

const AddAppointment = ({
  toggleAddAppointmentDrawer,
  drawerType,
  drawerData = null,
  categoryies,
  setCategoryies,
  handelHide,
}) => {
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down("sm"));

  const buttonProps = {
    variant: "contained",
    size: "large",
  };

  const [categoryData, setCategoryData] = useState("");
  const handelCategory = () => {
    const category = { label: categoryData };
    setCategoryies([...categoryies, category]);
    setCategoryData("");
  };
  const handelHidedata = () => {
    handelHide(drawerData.id);
    toggleAddAppointmentDrawer();
  };
  return (
    <Grid container>
      <Grid item xs={12} sm={2}>
        <Drawer
          type={drawerType === "addAppointment" ? "ADD" : "EDIT"}
          title={
            drawerType === "addAppointment"
              ? "New Appointment"
              : "Edit Appointment"
          }
          toggleDrawer={toggleAddAppointmentDrawer}
        >
          {/* -----------FORM----- */}
          <Grid
            sx={{
              height: "100%",
              overflowY: "auto",
              width: "100%",
            }}
          >
            {drawerType === "add" && (
              <Grid sm={12} xs={12}>
                <Grid sx={{ px: 5, pt: 5 }}>
                  <Typography variant="subtitle2" sx={{ my: 1 }}>
                    Parent category<sapn style={{ color: "red" }}>*</sapn>
                  </Typography>
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={categoryOption}
                    value="Root category"
                    sx={{
                      marginBottom: "1rem",
                      width: "100%",
                      fontSize: "14px",
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Grid>
                <Grid sx={{ padding: 5, pt: 1 }}>
                  <Typography variant="subtitle2" sx={{ my: 1 }}>
                    Category name<sapn style={{ color: "red" }}>*</sapn>
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    value={categoryData}
                    onChange={(e) => setCategoryData(e.target.value)}
                  />
                </Grid>
              </Grid>
            )}
            {drawerType !== "add" && (
              <ServiceTab drawerType={drawerType} categoryies={categoryies} />
            )}
          </Grid>
          {/* -----Button */}
          <DrawerFooter>
            {drawerType === "addAppointment" && (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: isSmallScreen ? "flex-start" : "normal",
                  }}
                >
                  <Checkbox
                    size="small"
                    checked={true}
                    color="success"
                    sx={{
                      marginBottom: { xs: 1, sm: 0 },
                    }}
                  />
                  <Typography
                    variant="subtitle2"
                    sx={{
                      marginLeft: { sm: 0, xs: -1 },
                      marginBottom: { xs: 1, sm: 0 },
                    }}
                  >
                    Run workflows on save
                  </Typography>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: isSmallScreen
                      ? "space-between"
                      : "flex-end",
                    width: "100%",
                  }}
                >
                  <Button
                    {...buttonProps}
                    sx={{
                      width: "auto",
                      backgroundColor: "WHITE",
                      color: "GREY",
                      ":hover": { backgroundColor: "GREY", color: "WHITE" },
                      width: isSmallScreen ? "48%" : "7rem",
                    }}
                    onClick={toggleAddAppointmentDrawer}
                  >
                    Cancel
                  </Button>
                  <Button
                    {...buttonProps}
                    sx={{
                      width: "auto",
                      width: isSmallScreen ? "48%" : "7rem",
                      marginLeft: { xs: 1, sm: 2 },
                    }}
                    // onClick={drawerType === "add" && handelCategory}
                  >
                    add
                  </Button>
                </div>
              </>
            )}
            {drawerType === "edit" && (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: isSmallScreen ? "flex-start" : "normal",
                  }}
                >
                  <Checkbox
                    size="small"
                    checked={true}
                    color="success"
                    sx={{
                      marginBottom: { xs: 1, sm: 0 },
                    }}
                  />
                  <Typography
                    variant="subtitle2"
                    sx={{
                      marginLeft: { sm: 0, xs: -1 },
                      marginBottom: { xs: 1, sm: 0 },
                    }}
                  >
                    Run workflows on save
                  </Typography>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: isSmallScreen
                      ? "space-between"
                      : "flex-end",
                    width: "100%",
                  }}
                >
                  <Button
                    {...buttonProps}
                    sx={{
                      width: "auto",
                      backgroundColor: "WHITE",
                      color: "GREY",
                      ":hover": { backgroundColor: "GREY", color: "WHITE" },
                      width: isSmallScreen ? "48%" : "7rem",
                    }}
                    onClick={toggleAddAppointmentDrawer}
                  >
                    Cancel
                  </Button>
                  <Button
                    {...buttonProps}
                    sx={{
                      width: "auto",
                      width: isSmallScreen ? "48%" : "7rem",
                      marginLeft: { xs: 1, sm: 2 },
                    }}
                    // onClick={drawerType === "add" && handelCategory}
                  >
                    SAVE
                  </Button>
                </div>
              </>
            )}
          </DrawerFooter>
        </Drawer>
      </Grid>
    </Grid>
  );
};

const categoryOption = [
  { label: "Root category" },
  { label: "Dental Clinic" },
  { label: "Gynecology" },
  { label: "Cosmetic Surgery" },
  { label: "Body" },
  { label: "Skin & Hear" },
];

export default AddAppointment;
