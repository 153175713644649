import { Grid, Typography } from "@mui/material";
import React from "react";

const Rating = () => {
  return (
    <Grid
      sx={{
        boxShadow: "rgb(149 157 165 / 20%) 0px 0px 10px",
        padding: "2rem",
        margin: "1rem",
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      <Grid xs={12} md={6} sx={{ marginTop: "1rem", display: "flex" }}>
        <Grid xs={4}>
          <Typography>Rated:</Typography>
        </Grid>

        <span> - </span>
      </Grid>

      <Grid xs={12} md={6} sx={{ marginTop: "1rem", display: "flex" }}>
        <Grid xs={4}>
          <Typography>Comment:</Typography>
        </Grid>
        <span> - </span>
      </Grid>
    </Grid>
  );
};

export default Rating;
