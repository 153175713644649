import React from 'react';
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Switch,
  TextareaAutosize,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import styled from '@emotion/styled';
import ProfileImageField from '../../../components/ProfileImageField';
import CustomTextField from '../../../components/CustomTextField';
import SelectCategory from '../../../components/AysncAutoComplete/SelectCategory';
import DisableSectionWrapper from '../../../components/DisableSectionWrapper';
import { IOSSwitch } from '../../../components/CustomCheckbox';

const ServiceDetails = ({
  serviceData,
  setServiceData,
  image,
  setImage,
  categories,
  errors,
  durationOptions,
  timeSlot,
  setErrors,
}) => {
  const handleChange = (name, value) => {
    setServiceData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handelChecked = (name, checked) => {
    setServiceData({ ...serviceData, [name]: checked });
  };

  const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))`
    & .MuiTooltip-tooltip {
      background: #2f284d;
      padding: 0.6rem;
      font-size: 0.8rem;
    }
  `;

  return (
    <Grid container>
      <Grid container>
        <Grid xs={12} sx={{ paddingRight: { sm: 2 }, marginTop: 1 }}>
          <ProfileImageField image={image} setImage={setImage} />
        </Grid>
      </Grid>

      <Grid container>
        <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 }, marginTop: 1 }}>
          <Grid item xs={12} sx={{ marginY: 1 }}>
            <Typography variant="subtitle2">
              Service Name <span style={{ color: 'red' }}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              size="small"
              variant="outlined"
              sx={{
                width: '100%',
                fontSize: '14px',
              }}
              onChange={(e) => {
                handleChange('name', e.target.value);
                setErrors({ ...errors, name: false });
              }}
              value={serviceData.name}
              error={errors.name}
            />
          </Grid>
        </Grid>
        <Grid xs={12} sm={6} sx={{ paddingLeft: { sm: 1 }, marginTop: 1 }}>
          <Grid item xs={12} sx={{ marginY: 1 }}>
            <Typography variant="subtitle2">
              Category <span style={{ color: 'red' }}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <SelectCategory
              type="service"
              value={serviceData.categoryId}
              onChange={(e, v) => {
                handleChange('categoryId', v?.id);
                handleChange('category', v);
                setErrors({ ...errors, category: false });
              }}
              addNewEntity
            />
          </Grid>
        </Grid>
        <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1, marginTop: 16 } }}>
          <Grid item xs={12} sx={{ marginY: 1 }}>
            <Typography variant="subtitle2">
              Price ($) <span style={{ color: 'red' }}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <CustomTextField
              size="small"
              placeholder="0.00"
              type={'number'}
              onChange={(e) => {
                handleChange('price', e.target.value);
                setErrors({ ...errors, price: false });
              }}
              value={serviceData.price}
              sx={{
                width: '100%',
                fontSize: '14px',
              }}
              error={errors.price}
              helperText={errors.price ? 'Value is missing' : ' '}
            />
          </Grid>
        </Grid>

        <Grid xs={12} sm={6} sx={{ paddingLeft: { sm: 1 } }}>
          <Grid
            id="once-per-person"
            container
            alignItems="center"
            item
            xs={12}
            sx={{
              marginTop: 4.8,
              height: 40,
              cursor: 'pointer',
            }}
            onClick={() =>
              handelChecked('enableDeposit', !serviceData.enableDeposit)
            }
          >
            <Checkbox
              size="small"
              sx={{ color: '#8b97a1' }}
              checked={serviceData.enableDeposit}
            />
            <Typography
              variant="subtitle2"
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              Enable Deposit
              <StyledTooltip
                title="Let customers make an appointment by paying the particular part of the amount"
                placement="top"
              >
                <InfoIcon
                  sx={{
                    color: '#8b97a1',
                    cursor: 'pointer',
                    fontSize: '20px',
                    marginLeft: '.8rem',
                  }}
                />
              </StyledTooltip>
            </Typography>
          </Grid>
        </Grid>
        {serviceData.enableDeposit && (
          <Grid xs={12} sm={12}>
            <Grid item xs={12} sm={6} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">
                Deposit <span style={{ color: 'red' }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                size="small"
                required
                variant="outlined"
                type={'number'}
                onChange={(e) => {
                  handleChange('deposit', e.target.value);
                  setErrors({ ...errors, deposit: false });
                }}
                value={serviceData.deposit}
                sx={{
                  width: '50%',
                  fontSize: '14px',
                  '& fieldset': {
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  },
                }}
                helperText={errors.deposit ? 'Value is missing' : ' '}
                error={errors.deposit}
              />
              <select
                style={{
                  width: '50%',
                  height: 40,
                  border: '1px solid rgba(0, 0, 0, 0.23)',
                  outline: 'none',
                  borderTopRightRadius: '4px',
                  borderBottomRightRadius: '4px',
                  fontSize: '14px',
                  color: '#292D32',
                  paddingLeft: 6,
                }}
                onChange={(e) => {
                  handleChange('depositType', e.target.value);
                }}
                value={serviceData.depositType}
              >
                <option value="%">%</option>
                <option value="$">$</option>
              </select>
            </Grid>
          </Grid>
        )}

        <Grid xs={12} sm={3} sx={{ pr: { sm: 1 } }}>
          <Grid item xs={12} sx={{ marginY: 1 }}>
            <Typography variant="subtitle2">
              Duration <span style={{ color: 'red' }}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              size="small"
              disablePortal
              options={durationOptions.map((option) => option)}
              getOptionLabel={(option) => option.label}
              onChange={(e, d) => {
                handleChange('duration', d.duration);
                setErrors({ ...errors, duration: false });
              }}
              value={
                durationOptions.filter(
                  (opt) => opt.duration == serviceData.duration
                )[0]
              }
              sx={{
                width: '100%',
                fontSize: '14px',
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select..."
                  error={errors.duration}
                  helperText={errors.duration ? 'Value is missing' : ' '}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid xs={12} sm={3} sx={{ px: { sm: 1 } }}>
          <Grid item xs={12} sx={{ marginY: 1 }}>
            <Typography variant="subtitle2">
              Time slot length <span style={{ color: 'red' }}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              size="small"
              disablePortal
              options={timeSlot.map((option) => option)}
              getOptionLabel={(option) => option.label}
              sx={{
                width: '100%',
                fontSize: '14px',
              }}
              onChange={(e, d) => {
                handleChange('slotLength', d.duration);
                setErrors({ ...errors, slotLength: false });
              }}
              value={
                timeSlot.filter(
                  (opt) => opt.duration == serviceData.slotLength
                )[0]
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select..."
                  error={errors.slotLength}
                  helperText={errors.slotLength ? 'Value is missing' : ' '}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid xs={12} sm={3} sx={{ px: { sm: 1 } }}>
          <Grid item xs={12} sx={{ marginY: 1 }}>
            <Typography variant="subtitle2">Buffer Time Before</Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              size="small"
              disablePortal
              options={durationOptions.map((option) => option)}
              getOptionLabel={(option) => option.label}
              sx={{
                width: '100%',
                fontSize: '14px',
              }}
              onChange={(e, d) => {
                handleChange('BTB', d.duration);
                setErrors({ ...errors, BTB: false });
              }}
              value={
                durationOptions.filter(
                  (opt) => opt.duration == serviceData.BTB
                )[0]
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="0h 0m"
                  error={errors.BTB}
                  helperText={errors.BTB ? 'Value is missing' : ' '}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid xs={12} sm={3} sx={{ paddingLeft: { sm: 1 } }}>
          <Grid item xs={12} sx={{ marginY: 1 }}>
            <Typography variant="subtitle2">Buffer Time After</Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              size="small"
              disablePortal
              options={durationOptions.map((option) => option)}
              getOptionLabel={(option) => option.label}
              sx={{
                width: '100%',
                fontSize: '14px',
              }}
              onChange={(e, d) => {
                handleChange('BTA', d.duration);
                setErrors({ ...errors, BTA: false });
              }}
              value={
                durationOptions.filter(
                  (opt) => opt.duration == serviceData.BTA
                )[0]
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="0h 0m"
                  error={errors.BTA}
                  helperText={errors.BTA ? 'Value is missing' : ' '}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
          <Grid
            container
            alignItems="center"
            item
            xs={12}
            sx={{
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
              height: 40,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              marginY: 3,
            }}
          >
            <Typography variant="subtitle2">
              Hide price in booking panel:
            </Typography>
            <FormControlLabel
              control={
                <IOSSwitch
                  onChange={(e, data) => {
                    handleChange('hidePrice', data);
                  }}
                  checked={serviceData.hidePrice}
                />
              }
            />
          </Grid>
        </Grid>
        <Grid xs={12} sm={6} sx={{ paddingLeft: { sm: 1 } }}>
          <Grid
            container
            alignItems="center"
            item
            xs={12}
            sx={{
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
              height: 40,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              marginY: 3,
            }}
          >
            <Typography variant="subtitle2">
              Hide duration in booking panel:
            </Typography>
            <FormControlLabel
              control={
                <IOSSwitch
                  onChange={(e, d) => {
                    handleChange('hideDur', d);
                  }}
                  checked={serviceData.hideDur}
                />
              }
            />
          </Grid>
        </Grid>
        <DisableSectionWrapper>
          <Grid xs={12} sm={12}>
            <Grid
              container
              alignItems="center"
              item
              xs={12}
              sm={6}
              sx={{
                height: 40,
                cursor: 'pointer',
                marginY: 2,
              }}
              onClick={() => handelChecked('recurring', !serviceData.recurring)}
            >
              <Checkbox
                size="small"
                checked={serviceData.recurring}
                sx={{ color: '#8b97a1' }}
              />
              <Typography
                variant="subtitle2"
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                Recurring
                <StyledTooltip
                  title="Provide the opportunity to your customers to choose multiple days while making appointments"
                  placement="right"
                >
                  <InfoIcon
                    sx={{
                      color: '#8b97a1',
                      cursor: 'pointer',
                      fontSize: '20px',
                      marginLeft: '.8rem',
                    }}
                  />
                </StyledTooltip>
              </Typography>
            </Grid>
            {serviceData.recurring && (
              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  border: '1px solid #ddd',
                  padding: '1.5rem',
                }}
              >
                <Grid xs={12} sm={12} sx={{ paddingRight: { sm: 1 } }}>
                  <Grid item xs={12} sm={6} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">Repeat</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Select
                      size="small"
                      placeholder="0.00"
                      sx={{
                        width: '100%',
                        fontSize: '14px',
                      }}
                      defaultValue="daily"
                    >
                      <MenuItem value="daily">Daily</MenuItem>
                      <MenuItem value="weekly">Weekly</MenuItem>
                      <MenuItem value="monthly">Monthly</MenuItem>
                    </Select>
                  </Grid>
                </Grid>

                <Grid xs={12} sm={12} container>
                  <Grid xs={12} sm={6}>
                    <Grid
                      container
                      alignItems="center"
                      item
                      xs={12}
                      sx={{
                        cursor: 'pointer',
                        border: '1px solid rgba(0, 0, 0, 0.23)',
                        borderRadius: '4px',
                        marginTop: 2,
                        height: 40,
                        paddingLeft: '.7rem',
                      }}
                      onClick={() =>
                        handelChecked('isFullPeriod', !serviceData.isFullPeriod)
                      }
                    >
                      <Checkbox
                        size="small"
                        checked={serviceData.isFullPeriod}
                        sx={{ color: '#8b97a1' }}
                      />
                      <Typography
                        variant="subtitle2"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          color: '#8b97a1',
                        }}
                      >
                        Fixed full period
                        <StyledTooltip
                          title="Select how many times the timeslot can be repeated"
                          placement="top"
                        >
                          <InfoIcon
                            sx={{
                              color: '#8b97a1',
                              cursor: 'pointer',
                              fontSize: '20px',
                              marginLeft: '.8rem',
                            }}
                          />
                        </StyledTooltip>
                      </Typography>
                    </Grid>
                  </Grid>
                  {serviceData.isFullPeriod && (
                    <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
                      <Grid
                        id="once-per-person"
                        container
                        alignItems="center"
                        item
                        xs={12}
                        sx={{
                          cursor: 'pointer',
                          borderRadius: '4px',
                          marginTop: 2,
                          height: 40,
                          display: 'flex',
                          justifyContent: 'space-between',
                          paddingLeft: { xs: 0, sm: '.7rem' },
                        }}
                      >
                        <TextField
                          size="small"
                          required
                          variant="outlined"
                          onChange={(e) => {
                            handleChange('fixedPeriod', e.target.value);
                          }}
                          value={serviceData.fixedPeriod}
                          placeholder="0"
                          type={'number'}
                          inputProps={{ style: { textAlign: 'center' } }}
                          sx={{
                            width: '50%',
                            fontSize: '14px',
                            '& fieldset': {
                              borderTopRightRadius: 0,
                              borderBottomRightRadius: 0,
                            },
                          }}
                        />
                        <select
                          style={{
                            width: '50%',
                            height: 40,
                            border: '1px solid rgba(0, 0, 0, 0.23)',
                            outline: 'none',
                            borderTopRightRadius: '4px',
                            borderBottomRightRadius: '4px',
                            fontSize: '14px',
                            color: '#292D32',
                            paddingLeft: 6,
                            marginTop: '-17px',
                          }}
                          onChange={(e) => {
                            handleChange('fixedPeriodType', e.target.value);
                          }}
                          value={serviceData.fixedPeriodType}
                        >
                          <option value="month">month</option>
                          <option value="week">week</option>
                          <option value="day">day</option>
                          <option value="time">time(s)</option>
                        </select>
                      </Grid>
                    </Grid>
                  )}
                </Grid>

                <Grid xs={12} sm={12} container>
                  <Grid xs={12} sm={6}>
                    <Grid
                      container
                      alignItems="center"
                      item
                      xs={12}
                      sx={{
                        cursor: 'pointer',
                        border: '1px solid rgba(0, 0, 0, 0.23)',
                        borderRadius: '4px',
                        marginTop: 2,
                        height: 40,
                        paddingLeft: '.7rem',
                      }}
                      onClick={() =>
                        handelChecked(
                          'isFixedFrequency',
                          !serviceData.isFixedFrequency
                        )
                      }
                    >
                      <Checkbox
                        size="small"
                        checked={serviceData.isFixedFrequency}
                        sx={{ color: '#8b97a1' }}
                      />
                      <Typography
                        variant="subtitle2"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          color: '#8b97a1',
                        }}
                      >
                        Fixed frequency
                        <StyledTooltip
                          title="Select the frequency of the repeated timeslot"
                          placement="top"
                        >
                          <InfoIcon
                            sx={{
                              color: '#8b97a1',
                              cursor: 'pointer',
                              fontSize: '20px',
                              marginLeft: '.8rem',
                            }}
                          />
                        </StyledTooltip>
                      </Typography>
                    </Grid>
                  </Grid>
                  {serviceData.isFixedFrequency && (
                    <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          mt: '1rem',
                          paddingLeft: { sm: '.7rem', xs: 0 },
                        }}
                      >
                        <TextField
                          placeholder="0"
                          size="small"
                          sx={{ width: '50%' }}
                          type={'number'}
                          onChange={(e) => {
                            handleChange('fixedFreq', e.target.value);
                          }}
                          value={serviceData.fixedFreq}
                        />
                        <Typography
                          sx={{
                            border: '1px solid #ddd',
                            width: '50%',
                            height: '38px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '14px',
                            paddingLeft: '5px',
                          }}
                        >
                          time(s) per month
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} sx={{ marginTop: 3 }}>
                  <Typography variant="subtitle2">Payment</Typography>
                  <Select
                    size="small"
                    defaultValue="The Customer pays separately for each appointment"
                    sx={{ width: '100%', marginTop: '.5rem' }}
                  >
                    <MenuItem value="The Customer pays separately for each appointment">
                      {' '}
                      The Customer pays separately for each appointment
                    </MenuItem>
                    <MenuItem value="The Customer pays full amount of recurring appointments">
                      The Customer pays full amount of recurring appointments
                    </MenuItem>
                  </Select>
                </Grid>
              </Grid>
            )}
          </Grid>
        </DisableSectionWrapper>

        <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
          <Grid item xs={12} sm={12} sx={{ marginY: 1 }}>
            <Typography variant="subtitle2">
              Capacity <span style={{ color: 'red' }}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Select
              size="small"
              required
              onChange={(e) => {
                handleChange('capacity', e.target.value === 'alone' ? 1 : 2);
              }}
              value={serviceData.capacity === 1 ? 'alone' : 'group'}
              fullWidth
              error={errors.capacity}
            >
              <MenuItem value="alone">Alone</MenuItem>
              <MenuItem value="group">Group</MenuItem>
            </Select>
            <FormHelperText error>
              {errors.capacity ? 'Value is missing' : ' '}
            </FormHelperText>
          </Grid>
        </Grid>

        {serviceData.capacity > 1 && (
          <Grid xs={12} sm={6} sx={{ paddingLeft: { sm: 1 } }}>
            <Grid item xs={12} sm={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">max. capacity</Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <CustomTextField
                size="small"
                type={'number'}
                onChange={(e) => {
                  handleChange('capacity', e.target.value);
                }}
                value={serviceData.capacity}
                sx={{
                  width: '100%',
                  fontSize: '14px',
                }}
                error={errors.capacity}
              />
            </Grid>
          </Grid>
        )}

        <Grid xs={12} sm={12}>
          <Grid item xs={12} sm={12} sx={{ marginY: 1 }}>
            <Typography variant="subtitle2">Description</Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextareaAutosize
              style={{
                width: '99.5%',
                height: '150px',
                padding: '0px',
                border: '1px solid #9d9b9b',
                fontSize: '16px',
              }}
              onChange={(e) => {
                handleChange('note', e.target.value);
              }}
              value={serviceData.note}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ServiceDetails;
