import { Avatar } from "@mui/material";

const AvatarComp = ({ username, img = null, ...props }) => {
  const firstLetter = username.split(" ")[0][0].toUpperCase();
  const secondLetter =
    (username.split(" ")[1] && username.split(" ")[1][0].toUpperCase()) || "";

  return (
    <>
      {img && (
        <Avatar
          src={process.env.REACT_APP_UPNIZE_BACKEND_URL + "/" + img}
          {...props}
        />
      )}
      {!img && (
        <Avatar {...props}>
          {firstLetter}
          {secondLetter}
        </Avatar>
      )}
    </>
  );
};

export default AvatarComp;
