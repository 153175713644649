import { UpnizePublicBackend } from '../Axios';

export const getUserById = async (id) => {
  let response = await UpnizePublicBackend.get(`/users/byId/${id}`);
  return response;
};

export const addUser = async (body) => {
  let response = await UpnizePublicBackend.post(`/users/add`, body);
  return response;
};

export const registerUser = async (body) => {
  let response = await UpnizePublicBackend.post(`/general/tenant/add`, body);
  return response;
};

export const editUserById = async (body, id) => {
  let response = await UpnizePublicBackend.put(`/users/edit/${id}`, body);
  return response;
};

export const welcome = async (body, id) => {
  let response = await UpnizePublicBackend.put(`/users/edit/${id}`, body);
  return response;
};

export const login = async (body) => {
  let response = await UpnizePublicBackend.post(`/users/login/tenant`, body);
  return response;
};

export const forgotPassword = async (body) => {
  let response = await UpnizePublicBackend.post(
    `/users/requestPasswordReset`,
    body
  );
  return response;
};

export const resetPassword = async (body, resetToken) => {
  let response = await UpnizePublicBackend.post(
    `/users/updatePassword/${resetToken}`,
    body
  );
  return response;
};

export const logout = async () => {
  let response = await UpnizePublicBackend.get(`/users/logout`);
  return response;
};

export const getDashboardData = async (body) => {
  let response = await UpnizePublicBackend.post(`/users/dashboard`, {
    to: body?.to?.format('YYYY-MM-DD') || new Date(),
    from: body?.from?.format('YYYY-MM-DD') || new Date(),
  });
  return response;
};

export const getInitialGuideProgress = async (userId) => {
  let response = await UpnizePublicBackend.post(`/users/initial-guide`, {
    userId
  });
  return response;
};