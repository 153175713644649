import * as d3 from "d3";
import { useEffect, useRef } from "react";

const BarChart = ({ data }) => {
  const svgRef = useRef(null);

  console.log(data)

  useEffect(() => {
    // set the dimensions and margins of the graph
    const margin = { top: 5, right: 30, bottom: 50, left: 60 },
      width =
        svgRef.current.parentElement.offsetWidth - margin.left - margin.right,
      height = 315 - margin.top - margin.bottom;

    // append the svg object to the body of the page
    const svg = d3
      .select(svgRef.current)
      .attr("viewBox", `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

      // X axis
      const x = d3
        .scaleBand()
        .range([0, width])
        .domain(data.map((d) => d.xValue))
        .padding(0.2);
      svg
        .append("g")
        .attr("transform", `translate(0, ${height})`)
        .call(d3.axisBottom(x))
        .selectAll("text")
        .attr("transform", "translate(-10,0)rotate(-45)")
        .style("text-anchor", "end");

      // Add Y axis
      const y = d3.scaleLinear().domain([0, Math.max(...data.map(el => el.yValue))]).range([height, 0]);
      svg.append("g").call(d3.axisLeft(y));


        // Bars
  svg.selectAll("mybar")
  .data(data)
  .enter()
  .append("rect")
    .attr("x", (d) => x(d.xValue))
    .attr("y", (d) => y(d.yValue))
    .attr("width", x.bandwidth())
    .attr("height", (d) => height - y(d.yValue))
    .attr("fill", "#69b3a2")

  }, []);

  return <svg ref={svgRef} />;
};

export default BarChart;
