import { Autocomplete, Box, Grid, MenuItem, TextField, TextareaAutosize, Typography, useMediaQuery, useTheme } from '@mui/material';
import Drawer from '../../../components/Drawer';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DrawerFooter from '../../../components/DrawerFooter';
import CustomTextButton from '../../../components/CustomButtons/CustomTextButton';
import AvatarComp from '../../../components/Avatar';
import ProfileImageField from '../../../components/ProfileImageField';
import CustomTextField from '../../../components/CustomTextField';
import countrydata from '../../Locations/Tabs/Countrydata.json';
import { useState } from 'react';
import useCustomerDrawer from './useCustomerDrawer';
import { MuiTelInput } from 'mui-tel-input';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { crudTypes } from '../../../constants/crudTypes';



const CustomerDrawer = ({title, crudType, submitTitle, data=null, refetchData=null}) => {
  const [isOpenDatePiker, setIsOpenDatePiker] = useState(false);
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('sm'));
  
  const {
    errors,
    states,
    formData,
    datevalue,
    value,
    image,
    handelDate,
    handleFormData,
    handleChange,
    handleSubmit,
    toggleDrawer,
    setErrors,
    setImage
  } = useCustomerDrawer({crudType, data, refetchData})

  return (
        <Drawer
          type={crudType}
          title={title}
          toggleDrawer={toggleDrawer}
        >
          {/* -----------FORM----- */}
          <Grid
            sx={{
              height: '100%',
              overflowY: 'auto',
              width: '100%',
            }}
          >
            {/* -----------FORM----- */}
            {crudType === crudTypes.INFO ? (
              // USER INFO
              <Grid
                sx={{
                  paddingX: 3,
                  paddingTop: 3,
                  height: '100%',
                  overflowY: 'auto',
                }}
              >
                <Grid
                  container
                  sx={{
                    margin: '0rem',
                    boxShadow: '2',
                    padding: '1rem 2rem',
                    paddingBottom: '2rem',
                  }}
                >
                  <Grid
                    xs={12}
                    md={2.5}
                    sx={{
                      paddingRight: { sm: 2 },
                      marginTop: 1,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        width: { xs: '40%', md: '100%' },
                      }}
                    >
                      <AvatarComp
                        img={data?.User?.image || null}
                        username={`${data.User.firstName} ${data.User.lastName}`}
                        sx={{ width: 100, height: 100 }}
                      />
                    </Box>
                  </Grid>
                  <Grid
                    xs={12}
                    md={9.5}
                    sx={{
                      paddingRight: { sm: 2 },
                      paddingBottom: { xs: 5, md: 0 },
                      marginTop: 1,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: { xs: 'center', md: 'start' },
                    }}
                  >
                    <Typography variant="h2" fontSize="20px" fontWeight="bold">
                      {data?.User?.firstName +
                        ' ' +
                        data?.User.lastName}
                    </Typography>
                  </Grid>

                  <Grid container sx={{ marginTop: '1rem' }}>
                    <Grid
                      xs={12}
                      sm={6}
                      sx={{
                        paddingRight: { sm: 2 },
                        marginTop: 1,
                        display: 'flex',
                        flexWrap: 'wrap',
                      }}
                    >
                      <Grid item xs={5} md={12} sx={{ marginY: { md: 1 } }}>
                        <Typography
                          variant="h2"
                          fontSize="18px"
                          fontWeight="bold"
                        >
                          Email
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={12}>
                        <Typography variant="p" color="#828F9A" fontSize="14px">
                          {data?.User?.email}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      xs={12}
                      sm={6}
                      sx={{
                        paddingRight: { sm: 2 },
                        marginTop: 1,
                        display: 'flex',
                        flexWrap: 'wrap',
                      }}
                    >
                      <Grid item xs={5} md={12} sx={{ marginY: 1 }}>
                        <Typography
                          variant="h2"
                          fontSize="18px"
                          fontWeight="bold"
                        >
                          Phone
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        sm={12}
                        sx={{ marginTop: { xs: 1, sm: 0 } }}
                      >
                        <Typography color="#828F9A" fontSize="14px">
                          {data.User.phone}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container sx={{ marginTop: { sm: '2rem' } }}>
                    <Grid
                      xs={12}
                      sm={6}
                      sx={{
                        paddingRight: { sm: 2 },
                        display: 'flex',
                        flexWrap: 'wrap',
                      }}
                    >
                      <Grid item xs={5} md={12} sx={{ marginY: 1 }}>
                        <Typography
                          variant="h2"
                          fontSize="18px"
                          fontWeight="bold"
                        >
                          Gender
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={12}
                        sx={{ marginTop: { xs: 1, sm: 0 } }}
                      >
                        <Typography variant="p" color="#828F9A" fontSize="14px">
                          {data.User.gender}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      xs={12}
                      sm={6}
                      sx={{
                        paddingRight: { sm: 2 },
                        display: 'flex',
                        flexWrap: 'wrap',
                      }}
                    >
                      <Grid item xs={5} md={12} sx={{ marginY: 1 }}>
                        <Typography
                          variant="h2"
                          fontSize="18px"
                          fontWeight="bold"
                        >
                          Birthday
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        sm={12}
                        sx={{ marginTop: { xs: 1, sm: 0 } }}
                      >
                        <Typography color="#828F9A" fontSize="14px">
                          {data.User.dob}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid
                sx={{
                  paddingX: 3,
                  paddingTop: 3,
                  height: '100%',
                  overflowY: 'auto',
                  paddingRight: { sm: '5px' },
                  width: '100%',
                }}
              >
                <Grid container>
                  <Grid xs={12} sx={{ paddingRight: { sm: 2 } }}>
                    <ProfileImageField image={image} setImage={setImage} />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 2 } }}>
                    <Grid item xs={12} sx={{ marginY: 1 }}>
                      <Typography variant="subtitle2">
                        First Name <span style={{ color: 'red' }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTextField
                        size="small"
                        variant="outlined"
                        value={formData.firstName}
                        onChange={(e) => {
                          handleFormData('firstName', e.target.value);
                          setErrors({ ...errors, firstName: false });
                        }}
                        error={errors.firstName}
                        sx={{
                          width: '100%',
                          fontSize: '14px',
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 2 } }}>
                    <Grid item xs={12} sx={{ marginY: 1 }}>
                      <Typography variant="subtitle2">
                        Last Name <span style={{ color: 'red' }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTextField
                        size="small"
                        variant="outlined"
                        sx={{
                          width: '100%',
                          fontSize: '14px',
                        }}
                        error={errors.lastName}
                        onChange={(e) => {
                          handleFormData('lastName', e.target.value);
                          setErrors({ ...errors, lastName: false });
                        }}
                        value={formData.lastName}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 2 } }}>
                    <Grid item xs={12} sx={{ marginY: 1 }}>
                      <Typography variant="subtitle2">
                        Email <span style={{ color: 'red' }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTextField
                        size="small"
                        variant="outlined"
                        error={errors.email}
                        sx={{
                          width: '100%',
                          fontSize: '14px',
                        }}
                        onChange={(e) => {
                          handleFormData('email', e.target.value);
                          setErrors({ ...errors, email: false });
                        }}
                        helperText={
                          errors?.email?.message ? errors?.email?.message : null
                        }
                        value={formData.email}
                      />
                    </Grid>
                  </Grid>
                  <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 2 } }}>
                    <Grid item xs={12} sx={{ marginY: 1 }}>
                      <Typography variant="subtitle2">Phone</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <MuiTelInput
                        InputProps={{
                          style: {
                            height: '40px',
                            padding: '0 10px',
                          },
                        }}
                        error={errors.phone}
                        sx={{ width: '100%' }}
                        value={value}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 2 } }}>
                    <Grid item xs={12} sx={{ marginY: 1 }}>
                      <Typography variant="subtitle2">Gender</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTextField
                        size="small"
                        variant="outlined"
                        sx={{
                          width: '100%',
                          fontSize: '14px',
                        }}
                        defaultValue="other"
                        select
                        onChange={(e) => {
                          handleFormData('gender', e.target.value);
                          setErrors({ ...errors, gender: false });
                        }}
                        value={formData.gender}
                      >
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="female">Female</MenuItem>
                        <MenuItem value="other">Other</MenuItem>
                      </CustomTextField>
                    </Grid>
                  </Grid>
                  <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 2 } }}>
                    <Grid item xs={12} sx={{ marginY: 1 }}>
                      <Typography variant="subtitle2">Date of birth</Typography>
                    </Grid>
                    <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        open={isOpenDatePiker}
                        onOpen={() => setIsOpenDatePiker(!isOpenDatePiker)}
                        onClose={() => setIsOpenDatePiker(!isOpenDatePiker)}
                        value={datevalue}
                        onChange={(newValue) => {
                          handelDate(newValue);
                        }}
                        InputProps={{
                          style: {
                            flexDirection: 'row-reverse',
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            {...params}
                            onClick={() => setIsOpenDatePiker(!isOpenDatePiker)}
                            onTouchStart={() =>
                              setIsOpenDatePiker(!isOpenDatePiker)
                            }
                            fullWidth
                          />
                        )}
                      />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 2 } }}>
                    <Grid item xs={12} sx={{ marginY: 1 }}>
                      <Typography variant="subtitle2">Country</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        disablePortal
                        options={countrydata.map(
                          (getcountry, index) => getcountry.country_name
                        )}
                        value={formData.country}
                        onSelect={(e) => {
                          handleFormData('country', e.target.value.toString());
                          setErrors({ ...errors, country: false });
                        }}
                        sx={{ marginBottom: '1rem', fontSize: '14px' }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            placeholder="Choose Country..."
                            helperText={
                              errors.country ? 'Value is missing' : ' '
                            }
                            error={errors.country}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 2 } }}>
                    <Grid item xs={12} sx={{ marginY: 1 }}>
                      <Typography variant="subtitle2">City</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        disablePortal
                        options={states.map(
                          (getstate, index) => getstate.state_name
                        )}
                        value={formData.city}
                        onSelect={(e) => {
                          handleFormData('city', e.target.value.toString());
                          setErrors({ ...errors, city: false });
                        }}
                        sx={{ marginBottom: '1rem', fontSize: '14px' }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            placeholder="Choose City..."
                            helperText={errors.city ? 'Value is missing' : ' '}
                            error={errors.city}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid xs={12} sx={{ paddingRight: { sm: 2 } }}>
                    <Grid item xs={12} sx={{ marginY: 1 }}>
                      <Typography variant="subtitle2">Note</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextareaAutosize
                        size="small"
                        variant="outlined"
                        style={{
                          width: '97.5%',
                          minHeight: '120px',
                          padding: '5px',
                          fontSize: '16px',
                        }}
                        onChange={(e) => {
                          handleFormData('note', e.target.value);
                        }}
                        value={formData.note}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          {/* -----Button */}
          <DrawerFooter>
            <>
              <div
                style={{
                  display: 'flex',
                  gap: '15px',
                  justifyContent: isSmallScreen ? 'space-between' : 'flex-end',
                  width: isSmallScreen ? '100%' : 'auto',
                  alignItems: 'center',
                }}
              >
                <CustomTextButton
                  variant="contained"
                  size="large"
                  onClick={toggleDrawer}
                >
                  Cancel
                </CustomTextButton>
                <CustomTextButton
                  type="blue"
                  variant="contained"
                  size="large"
                  onClick={() => {
                   handleSubmit()
                  }}
                >
                  {submitTitle || 'Save'}
                </CustomTextButton>
              </div>
            </>
          </DrawerFooter>
        </Drawer>
  )
}

export default CustomerDrawer