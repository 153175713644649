import { Grid, Switch, TextField, Typography } from '@mui/material';
import { useState } from 'react';

const Finish = ({
  urlOfFinishBookingButton,
  setUrlOfFinishBookingButton,
  hideTheAddToGoogleCalendarButton,
  setHideTheAddToGoogleCalendarButton,
  hideTheStartNewBookingButton,
  setHideTheStartNewBookingButton,
  hideConfirmationNumber,
  setHideConfirmationNumber,
  startingConfirmationNumber,
  setStartingConfirmationNumber,
  setIsSaveBar,
}) => {
  return (
    <>
      <Grid xs={12}>
        <Grid item xs={12} sx={{ marginY: 1 }}>
          <Typography variant="subtitle2">
            URL of "FINISH BOOKING" button
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            placeholder="Default: Reload current page."
            value={urlOfFinishBookingButton}
            onChange={(e) => {
              setUrlOfFinishBookingButton(e.target.value);
              setIsSaveBar(true);
            }}
            id="outlined-basic"
            size="small"
            required
            variant="outlined"
            sx={{
              marginBottom: '2rem',
              width: '100%',
              fontSize: '14px',
            }}
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} sx={{ marginY: 1 }}>
        <Grid
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setHideTheAddToGoogleCalendarButton(
              !hideTheAddToGoogleCalendarButton
            );
            setIsSaveBar(true);
          }}
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            paddingX: 2,
            paddingY: 1,
            height: '40px',
          }}
        >
          <Typography variant="subtitle2" sx={{ lineHeight: '1.2 !important' }}>
            Hide the "ADD TO GOOGLE CALENDAR" button
          </Typography>
          <Switch
            size="small"
            defaultChecked
            sx={{ marginLeft: 2 }}
            onChange={(e) => {
              setHideTheAddToGoogleCalendarButton(e.target.checked);
              setIsSaveBar(true);
            }}
            checked={hideTheAddToGoogleCalendarButton}
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} sx={{ marginY: 1 }}>
        <Grid
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setHideTheStartNewBookingButton(!hideTheStartNewBookingButton);
            setIsSaveBar(true);
          }}
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            paddingX: 2,
            paddingY: 1,
            height: '40px',
          }}
        >
          <Typography variant="subtitle2" sx={{ lineHeight: '1.2 !important' }}>
            Hide the "START NEW BOOKING" button
          </Typography>
          <Switch
            size="small"
            defaultChecked
            sx={{ marginLeft: 2 }}
            onChange={(e) => {
              setHideTheStartNewBookingButton(e.target.checked);
              setIsSaveBar(true);
            }}
            checked={hideTheStartNewBookingButton}
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} sx={{ marginY: 1 }}>
        <Grid
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setHideConfirmationNumber(!hideConfirmationNumber);
            setIsSaveBar(true);
          }}
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            paddingX: 2,
            paddingY: 1,
            height: '40px',
          }}
        >
          <Typography variant="subtitle2" sx={{ lineHeight: '1.2 !important' }}>
            Hide a confirmation number
          </Typography>
          <Switch
            size="small"
            defaultChecked
            sx={{ marginLeft: 2 }}
            onChange={(e) => {
              setHideConfirmationNumber(e.target.checked);
              setIsSaveBar(true);
            }}
            checked={hideConfirmationNumber}
          />
        </Grid>
      </Grid>

      <Grid xs={12} sx={{ marginTop: 2 }}>
        <Grid item xs={12} sx={{ marginY: 1 }}>
          <Typography variant="subtitle2">
            Starting confirmation number
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={startingConfirmationNumber}
            onChange={(e) => {
              setStartingConfirmationNumber(e.target.value);
              setIsSaveBar(true);
            }}
            id="outlined-basic"
            size="small"
            required
            variant="outlined"
            sx={{
              marginBottom: '2rem',
              width: '100%',
              fontSize: '14px',
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Finish;
