import {
  Box,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import CustomTextButton from '../../CustomButtons/CustomTextButton';
import { inviteCustomersByEmail } from '../../../Api/tenant/customers';
import { useDispatch, useSelector } from 'react-redux';
import { callSnackbar } from '../../../redux/slices/snackbar.slice';
import AddIcon from '@mui/icons-material/Add';
import { emailRegex } from '../../../../Regex';
import { selectUser } from '../../../redux/slices/user.slice.js';
import useInitialGuide from '../../../hooks/useInitialGuide';
import { initialGuideAttributes } from '../../../constants/initialGuideAttributes';

const InviteCustomerModal = ({ tenantPanel = false, handleOpenModal }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState([]);
  const [currValue, setCurrValue] = useState('');
  const [failedInvites, setFailedInvites] = useState([]);
  const [error, setError] = useState('');
  const { user } = useSelector(selectUser);
  const { initialGuideData, fetchInitialGuide } = useInitialGuide();

  const handleChange = (e) => {
    setCurrValue(e.target.value);
  };

  const handleDeleteItem = (item, index) => {
    let arr = [...values];
    arr.splice(index, 1);
    console.log(item);
    setValues(arr);
  };

  const addEmailToList = () => {
    if (error) {
      setError('');
    }

    if (!emailRegex.test(currValue)) {
      setError('Invalid Email');
      return;
    }
    setValues((oldState) => [...oldState, currValue]);
    setCurrValue('');
  };

  const handleSubmit = async () => {
    try {
      const result = await inviteCustomersByEmail({ emails: values, userId: user.id });

      if (result.status === 200) {
        const resultData = result.data;

        if (resultData.failed.length > 0) {
          setFailedInvites(resultData.failed);
        } else {
          dispatch(
            callSnackbar({
              message: 'Customers Invited Successfully',
              type: 'success',
            })
          );
          handleOpenModal();
          if(!initialGuideData[initialGuideAttributes?.invitedCustomer]) fetchInitialGuide()
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Grid sm={12} xs={12}>
        {tenantPanel && (
          <Box>
            <Typography
              sx={{
                mt: 2,
                color: '#3A3A50',
              }}
            >
              Sending an invitation to a user's email allows them to access the
              customer dashboard. Once they log in, they will be registered on
              your customers page.
            </Typography>
          </Box>
        )}
        {failedInvites.length > 0 && (
          <div
            style={{
              backgroundColor: '#e54141',
              borderRadius: '5px',
              color: 'white',
              padding: '10px',
              overflowY: 'auto',
              maxHeight: '150px',
            }}
          >
            <p style={{ margin: '0' }}>failed to invite the following emails</p>
            <ul style={{ marginBottom: '0' }}>
              {failedInvites.map((email, i) => (
                <li key={`failed-email-invite-${i}`}>{email}</li>
              ))}
            </ul>
          </div>
        )}
        {error && (
          <div
            style={{
              backgroundColor: '#e54141',
              borderRadius: '5px',
              color: 'white',
              padding: '10px',
            }}
          >
            <p style={{ margin: '0' }}>{error}</p>
          </div>
        )}

        <Grid sx={{ paddingY: 4 }}>
          <OutlinedInput
            sx={{
              width: '100%',
            }}
            fullWidth
            size="small"
            placeholder={`Add ${
              tenantPanel ? "customer's" : "friend's"
            } email address`}
            value={currValue}
            onChange={handleChange}
            onKeyUp={(e) => e.key === 'Enter' && addEmailToList()}
            endAdornment={
              <InputAdornment position="end">
                {currValue && (
                  <Tooltip title="add email to invite list" placement="top">
                    <IconButton onClick={addEmailToList} edge="end">
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </InputAdornment>
            }
          />
          <p>{tenantPanel ? 'Customers' : 'Friends'} To be Invited:</p>
          {values.length > 0 && (
            <div>
              {values.map((item, index) => (
                <Chip
                  style={{ margin: '2px' }}
                  size="small"
                  onDelete={() => handleDeleteItem(item, index)}
                  label={item}
                />
              ))}
            </div>
          )}
          {values.length <= 0 && <p style={{ color: 'grey' }}>Empty</p>}
        </Grid>
      </Grid>
      <Grid
        container
        sx={{ display: 'flex', justifyContent: 'end', gap: '15px' }}
      >
        <CustomTextButton text="Cancel" onClick={handleOpenModal} />
        <CustomTextButton
          disabled={values.length <= 0}
          text="Send"
          type="blue"
          onClick={handleSubmit}
        />
      </Grid>
    </>
  );
};

export default InviteCustomerModal;
