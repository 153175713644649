import './index.css';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridMoreVertIcon } from '@mui/x-data-grid';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { styled } from '@mui/material/styles';
import { gridClasses } from '@mui/x-data-grid';
import SaveSlideBar from '../../SaveSlideBar';
import { editSettings } from '../../../Api/tenant/settings';
import CustomTableFooter from '../../CustomTableFooter';
import Modal from '../../Modal';
import {
  deleteCategoryById,
  editCategoryById,
  getCategoryList,
} from '../../../Api/tenant/categories';
import CustomSettingsTitle from '../../CustomSettingsTitle/CustomSettingsTitle';
import AvatarComp from '../../Avatar';
import CustomTextButton from '../../CustomButtons/CustomTextButton';

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    // backgroundColor: theme.palette.grey[200],
    color: theme.palette.grey[500],
  },
}));

const CategorySettings = ({ fetchCurrentSettings, currentSettings }) => {
  const typeRef = useRef();
  const [catName, setCatName] = useState('');
  const [catType, setCatType] = useState('');

  const [isSaveBar, setIsSaveBar] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [categories, setCategories] = useState(null);
  const [subCategories, setSubCategories] = useState(null);

  const [context, setContext] = useState({
    timeSlotLength: '',
    sLengthSD: '',
    mtrptb: '',
    lbd: '',
    weekStart: '',
    dFormat: '',
    tFormat: '',
    das: '',
    showTimeSlots: true,
    linkExpire: '',
    timezone: '',
    adminBook: false,
    registredBook: true,
  });

  const fetchCategories = useCallback(async () => {
    setTableLoading(true);
    try {
      const { data } = await getCategoryList(100, 1);
      setSubCategories(data.filter((cat) => cat.parentId || cat.level));
      setCategories(data.filter((cat) => cat.parentId === null && cat.level));
      setTableLoading(false);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);
  // update table rows on fetch
  useEffect(() => {
    if (categories) {
      setRows(categories);
      setTableLoading(false);
    }
  }, [categories]);

  const deleteCategory = async (id) => {
    deleteCategoryById(id).then((res) => {
      fetchCategories();
    });
  };
  const editCategory = async (catName, id, type = null) => {
    editCategoryById(catName, id).then((res) => {
      fetchCategories();
      // console.log(res);
    });
  };

  const pages = ['service', 'product', 'restaurant'];

  useLayoutEffect(() => {
    setContext(currentSettings.GeneralSettings);
  }, []);
  const columns = [
    {
      field: 'name',
      headerName: 'GATEGORY NAME',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'type',
      headerName: 'PAGE',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <Box sx={{ width: '100%' }}>
          <Autocomplete
            ref={typeRef}
            sx={{
              width: '100%',
              '& fieldset': { borderRadius: 1.5 },
              '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                border: '0px solid #eee !important',
              },
              '& .MuiAutocomplete-input': {
                paddingLeft: '0px !important',
              },
              '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
                paddingLeft: '0px !important',
              },
            }}
            size="small"
            // disablePortal
            id="combo-box-demo"
            options={pages}
            value={params.row.type}
            onChange={(e, v) => {
              typeRef.current = v;
              if (!typeRef.current) return;
              editCategory({ type: typeRef.current }, params.row.id);
            }}
            renderInput={(params) => (
              <TextField {...params} placeholder="Select page" />
            )}
          />
        </Box>
      ),
    },
    /*
    {
      field: 'usage',
      headerName: 'USAGE',
      flex: 1,
      minWidth: 90,
    },
    */
    {
      field: 'UserId',
      headerName: 'CREATED BY',
      flex: 1,
      minWidth: 230,
      renderCell: (params) => {
        const user = params.row.User;

        if (!user) return <></>;

        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <AvatarComp
              img={user?.image}
              username={`${user?.firstName} ${user?.lastName}`}
            />
            <Typography fontSize="14px" marginLeft={'10px'} color="inherit">
              {`${user?.firstName} ${user?.lastName}`}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'createdAt',
      headerName: 'CREATED',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        let d = new Date(params.row.createdAt),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [year, month, day].join('-');
      },
    },
    {
      field: 'actions',
      headerName: 'ACTIONS',
      type: 'actions',
      align: 'right',
      minWidth: 120,
      getActions: (params) => {
        if (params.row.isHidden) {
          return [
            <GridActionsCellItem
              icon={<VisibilityOffIcon />}
              label="Hide Section"
            />,
            <GridActionsCellItem
              label="Edit"
              onClick={() => {
                setCatName(params.row.name);
                // setDrawerType('edit');
                setOpenModal({
                  open: true,
                  type: 'editCat-modal',
                  id: params.id,
                  // catName: params.row.type,
                });
                // toggleAddCouponDrawer();
              }}
              showInMenu
            />,

            <GridActionsCellItem
              label="Remove"
              onClick={() => {
                const hasChildren = subCategories.includes(
                  (subCategory) => subCategory.parentId === params.id
                );
                if (hasChildren) {
                  setOpenModal({
                    open: true,
                    type: 'has-children-modal',
                    id: params.id,
                  });
                  return;
                }
                setOpenModal({
                  open: true,
                  type: 'delete-modal',
                  id: params.id,
                });
              }}
              showInMenu
            />,
          ];
        }
        return [
          <GridActionsCellItem
            label="Edit"
            onClick={() => {
              setCatName(params.row.name);
              setCatType(params.row.type);
              setOpenModal({
                open: true,
                type: 'editCat-modal',
                id: params.id,
              });
            }}
            showInMenu
          />,
          <GridActionsCellItem
            label="Remove"
            onClick={() => {
              const hasChildren = subCategories.some(
                (subCategory) => subCategory.parentId === params.id
              ); // equal to includes function but for identifying on attribute level
              if (hasChildren) {
                setOpenModal({
                  open: true,
                  type: 'has-children-modal',
                  id: params.id,
                });
                return;
              }
              setOpenModal({ open: true, type: 'delete-modal', id: params.id });
            }}
            showInMenu
          />,
        ];
      },
    },
  ];
  const [selectionModel, setSelectionModel] = useState([]);
  const [openModal, setOpenModal] = useState({ open: false, type: null });

  const handleOpenModal = () => {
    setOpenModal({ open: false, type: null });
  };
  const handleDeleteOpenModal = () => {
    setOpenModal({ open: true, type: 'delete-modal' });
  };

  // click handlers
  const handleSave = () => {
    editSettings({ ...currentSettings, GeneralSettings: context }).then(
      (res) => {
        if (res.status === 200) {
          fetchCurrentSettings();
          setIsSaveBar(false);
        }
      }
    );
  };

  const handleCancel = () => {
    setContext(currentSettings.GeneralSettings);
    setIsSaveBar(false);
  };

  return (
    <>
      <Grid
        xs={12}
        sx={{
          marginTop: { xs: 9, sm: 14 },
          backgroundColor: 'white',
          pb: 1,
          mb: 5,
        }}
      >
        {openModal.type === 'has-children-modal' && (
          <Modal
            handleOpenModal={handleOpenModal}
            type={'HAS_CHILDREN_MODAL'}
            open={openModal.open}
            id={openModal.id}
          />
        )}
        {openModal.type === 'delete-modal' && (
          <Modal
            handleOpenModal={handleOpenModal}
            type={'DELETE'}
            open={openModal.open}
            id={openModal.id}
            handleDelete={deleteCategory}
          />
        )}
        {openModal.type === 'addCat-modal' && (
          <Modal
            handleOpenModal={handleOpenModal}
            type={'ADDCAT'}
            open={openModal.open}
            id={openModal.id}
            fetchCategories={fetchCategories}
          />
        )}
        {openModal.type === 'editCat-modal' && (
          <Modal
            handleOpenModal={handleOpenModal}
            type={'EDITCAT'}
            open={openModal.open}
            id={openModal.id}
            handleEdit={editCategory}
            fetchCategories={fetchCategories}
            catName={catName}
            catType={catType}
          />
        )}
        <Grid
          sx={{
            padding: { xs: '15px', md: '15px 25px' },
            borderBottom: '1px solid #e3eaf3',
          }}
        >
          <CustomSettingsTitle title="Process" subTitle="Category" />
        </Grid>
        {/* here */}
        <Box sx={{ mt: 2, ml: 2 }}>
          <CustomTextButton
            text="+ category"
            onClick={() => {
              setOpenModal({ open: true, type: 'addCat-modal' });
            }}
          />
        </Box>
        <Grid
          container
          item
          justifyContent="space-between"
          sx={{ marginTop: '20px' }}
        >
          <div style={{ width: '100%' }}>
            <StripedDataGrid
              disableSelectionOnClick
              sx={{
                border: 0,
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontSize: '12px',
                  fontWeight: '900 !important',
                },
                '& .MuiDataGrid-main, .MuiDataGrid-footerContainer': {
                  backgroundColor: 'white',
                  border: 'none',
                },
                '& .MuiDataGrid-columnHeader:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                minHeight: '60vh',
              }}
              getRowClassName={(params) => {
                return params.row.isHidden ? 'even' : 'odd';
              }}
              rows={rows}
              columns={columns}
              loading={tableLoading}
              checkboxSelection
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              selectionModel={selectionModel}
              autoHeight
              components={{
                Footer: () => (
                  <CustomTableFooter
                    selectionModel={selectionModel}
                    handleDeleteOpenModal={handleDeleteOpenModal}
                  />
                ),
                MoreActionsIcon: () => (
                  <GridMoreVertIcon
                    sx={{
                      backgroundColor: 'white',
                      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
                      borderRadius: '4px',
                      padding: '3px',
                      cursor: 'pointer',
                    }}
                  />
                ),
              }}
            />
          </div>
        </Grid>
      </Grid>
      <SaveSlideBar
        handleCancel={handleCancel}
        handleSave={handleSave}
        isSaveBar={isSaveBar}
        setIsSaveBar={setIsSaveBar}
      />
    </>
  );
};

export default CategorySettings;
