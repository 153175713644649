import React from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  OutlinedInput,
  Switch,
  TextareaAutosize,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { Theme, useTheme } from '@mui/material/styles';
import InfoIcon from "@mui/icons-material/Info";
import { useState } from "react";
import { useRef } from "react";
import styled from "@emotion/styled";

const RoleDetails = ({ RoleData, setRoleData, categoryies }) => {
  const [userImage, setUserImage] = useState("");
  const image = useRef();

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const [staffName, setStaffName] = useState();
const handleChange = (event) => {
  const {
    target: { value },
  } = event;
  setStaffName(
    // On autofill we get a stringified value.
    typeof value === 'string' ? value.split(',') : value,
  );
};

const theme = useTheme();
function getStyles(name, staffName, theme) {
  return {
    fontWeight:
    staffName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

  const categories = [
    { label: "None", value: "1" },
    { label: "All", value: "2" },
    { label: "My", value: "3" }
  ];

  const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))`
    & .MuiTooltip-tooltip {
      background: #929da6;
      padding: 0.6rem;
      font-size: 0.8rem;
    }
  `;
  return (
    <Grid container>
  
      <Grid container>
        <Grid xs={12} sm={12} sx={{ paddingRight: { sm: 1 }, marginTop: 1 }}>
          <Grid item xs={12} sx={{ marginY: 1 }}>
            <Typography variant="subtitle2">
              Name <span style={{ color: "red" }}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-basic"
              size="small"
              required
              variant="outlined"
              sx={{
                marginBottom: "1rem",
                width: "100%",
                fontSize: "14px",
              }}
            />
          </Grid>
        </Grid>
        <Grid xs={12} sm={12} sx={{ paddingRight: { sm: 1 }, marginTop: 1 }}>
          <Grid item xs={12} sx={{ marginY: 1 }}>
            <Typography variant="subtitle2">
              Staff <span style={{ color: "red" }}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
          <Autocomplete
          multiple
          size="small"
          id="multiple-limit-tags"
          options={categories}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField {...params}  placeholder="Select..." />
          )}
          fullWidth
        />
          </Grid>
        </Grid>
       

        <Grid xs={12} sm={12} sx={{ paddingRight: { sm: 1 }, marginTop: 1 }}>
          <Grid item xs={12} sm={12} sx={{ marginY: 1 }}>
            <Typography variant="subtitle2">Note</Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextareaAutosize
              style={{
                width: "96%",
                height: "150px",
                padding: "10px",
                border: "1px solid #9d9b9b",
              }}
            />
            
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RoleDetails;
