import { Box, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import NoPhotographyRoundedIcon from '@mui/icons-material/NoPhotographyRounded';

function ProfileImageField({
  image,
  setImage,
  setIsSaveBar,
  title,
  justifyContent,
}) {
  const uploadedFile = useRef();
  const [isOnHover, setIsOnHover] = useState(false);
  const [currentImage, setCurrentImge] = useState();

  const handleUpload = () => {
    uploadedFile.current.click();
  };

  const readURL = (file) => {
    return new Promise((res, rej) => {
      const reader = new FileReader();
      reader.onload = (e) => res(e.target.result);
      reader.onerror = (e) => rej(e);
      reader.readAsDataURL(file);
    });
  };

  const handleChangeImage = async () => {
    if (image) {
      if (typeof image === 'string') {
        setCurrentImge(image);
      } else {
        const url = await readURL(image);
        setCurrentImge(url);
        setIsSaveBar && setIsSaveBar(true);
      }
    } else {
      setCurrentImge(image);
    }
  };

  useEffect(() => {
    handleChangeImage();
  }, [image]);

  const handleUploadedFile = async (e) => {
    setImage(e.target.files[0]);
  };

  return (
    <Grid
      marginTop="1.5rem"
      sx={{
        height: '200px',
        display: 'flex',
        justifyContent: justifyContent ? justifyContent : 'center',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '144px',
          width: '144px',
          border: '1px solid #EDEDFF',
          borderRadius: '99999px',
          overflow: 'hidden',
          backgroundColor: '#F4F8FF',
          cursor: 'pointer',
          position: 'sticky',
        }}
        onClick={handleUpload}
        onMouseEnter={() => {
          setIsOnHover(true);
        }}
        onMouseLeave={() => {
          setIsOnHover(false);
        }}
      >
        {currentImage && (
          <img
            src={
              currentImage.includes('base64')
                ? currentImage
                : process.env.REACT_APP_UPNIZE_BACKEND_URL + '/' + currentImage
            }
            alt=""
            style={{ objectFit: 'fill', height: '100%', width: '100%' }}
          />
        )}
        {!currentImage && (
          <>
            <Box
              sx={{
                position: 'absolute',
                background: 'white',
                opacity: 0.5,
                width: '144px',
                height: '144px',
              }}
            ></Box>
            <Typography
              sx={{
                position: 'absolute',
                left: 0,
                right: 0,
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: 'auto',
                marginBottom: 'auto',
                display: 'flex',
                flexDirection: 'column',
                fontSize: '14px',
                alignItems: 'center',
                fontWeight: 700,
              }}
            >
              <CameraAltIcon sx={{ fontSize: '50px' }} />
              {title ? title : 'Upload Image'}
            </Typography>

            <TextField
              required
              sx={{
                marginBottom: '1rem',
                width: '100%',
                fontSize: '14px',
                display: 'none',
              }}
              onChange={(e) => handleUploadedFile(e)}
              inputRef={uploadedFile}
              type="file"
            />
          </>
        )}
        {isOnHover && currentImage && (
          <>
            <Box
              sx={{
                position: 'absolute',
                background: 'white',
                opacity: 0.5,
                width: '144px',
                height: '144px',
              }}
              onClick={() => {
                setImage(null);
              }}
            ></Box>
            <Typography
              sx={{
                position: 'absolute',
                left: 0,
                right: 0,
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: 'auto',
                marginBottom: 'auto',
                display: 'flex',
                flexDirection: 'column',
                fontSize: '14px',
                alignItems: 'center',
                fontWeight: 700,
              }}
              onClick={() => {
                setImage(null);
              }}
            >
              <NoPhotographyRoundedIcon sx={{ fontSize: '50px' }} />
              Remove Image
            </Typography>
          </>
        )}
      </Box>
    </Grid>
  );
}

export default ProfileImageField;
