import React, { useState, useLayoutEffect, useEffect } from 'react';
import Drawer from '../../components/Drawer';
import DrawerFooter from '../../components/DrawerFooter';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  TextareaAutosize,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ProfileImageField from '../../components/ProfileImageField';
import CustomTextButton from '../../components/CustomButtons/CustomTextButton';
import {
  addFoodItem,
  editFoodItemById,
  editMenu,
  getFoodItemList,
  getMenu,
} from '../../Api/tenant/restaurant';
import SelectCategory from '../../components/AysncAutoComplete/SelectCategory';

const days = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

const AddFoodMenu = ({
  toggleDrawer,
  drawerType,
  drawerData = null,
  fetchFoodItems,
}) => {
  //  handling state
  //  menus
  const [daysCheckboxState, setDaysCheckboxState] = useState({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });
  const [selectedmenuItems, setSelectedmenuItems] = useState({
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
  });
  // items
  const [showAll, setShowAll] = useState(false);
  const [image, setImage] = useState(null);
  const [formData, setFormData] = useState({
    title: null,
    category: null,
    description: null,
    lPrice: null,
    price: null,
  });
  const [foodItemsOptions, setFoodItemsOptions] = useState([]);

  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('sm'));

  const handleFormData = (fieldName, value) => {
    setFormData((prev) => {
      return { ...prev, [fieldName]: value };
    });
  };

  useLayoutEffect(() => {
    if (drawerType === 'edit') {
      const { title, category, description, lPrice, price } = drawerData;
      setFormData({ title, category: category.id, description, lPrice, price });
      setImage(drawerData.image);
    } else if (drawerType === 'lunch') {
      getMenu().then((res) => {
        setSelectedmenuItems(res.data[0].foods);
        setShowAll(res.data[0].enabled);
      });
      getFoodItemList(100, 1).then((res) => {
        setFoodItemsOptions(res.data);
      });
    }
  }, [drawerData, drawerType]);

  // API
  // error handling
  const [errors, setErrors] = useState({});
  const requiredFields = [
    'title',
    'category',
    'description',
    'price',
    'lPrice',
  ];

  const handleAddFoodItem = () => {
    let data = formData;
    let err = {};
    for (const key in data) {
      if (!data[key] && requiredFields.includes(key)) {
        err = { ...err, [key]: true };
        console.log('s', data[key], data);
      }
      if (
        Array.isArray(data[key]) &&
        data[key].length === 0 &&
        requiredFields.includes(key)
      ) {
        console.log(data[key]);
        err = { ...err, [key]: true };
      }
    }
    setErrors({ ...err });
    if (Object.keys(err).length === 0) {
    }
    let formdata = new FormData();
    for (const el in formData) {
      formdata.append(el, formData[el]);
    }
    formdata.append('image', image);
    formdata.append('categoryId', formData.category);

    addFoodItem(formdata).then((res) => {
      toggleDrawer();
      fetchFoodItems();
    });
  };

  const handleEditMenu = () => {
    let data = formData;
    let err = {};
    for (const key in data) {
      if (!data[key] && requiredFields.includes(key)) {
        err = { ...err, [key]: true };
        console.log('s', data[key], data);
      }
      if (
        Array.isArray(data[key]) &&
        data[key].length === 0 &&
        requiredFields.includes(key)
      ) {
        console.log(data[key]);
        err = { ...err, [key]: true };
      }
    }
    setErrors({ ...err });
    if (Object.keys(err).length === 0) {
      let normalizedMenu = {};
      for (const day in selectedmenuItems) {
        const foodItemIds = selectedmenuItems[day].map(
          (foodItem) => foodItem.id
        );
        normalizedMenu[day] = foodItemIds;
      }

      const reqBody = {
        ...normalizedMenu,
        enabled: showAll,
      };
      editMenu(reqBody).then((res) => {
        toggleDrawer();
      });
    }
  };

  const handleEditFooditem = () => {
    let formdata = new FormData();
    for (const el in formData) {
      if (formData[el]) {
        formdata.append(el, formData[el]);
      }
    }
    formdata.append('image', image);
    formdata.append('categoryId', formData.category);

    editFoodItemById(formdata, drawerData.id).then((res) => {
      toggleDrawer();
      fetchFoodItems();
    });
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={2}>
        <Drawer
          type={
            drawerType === 'add'
              ? 'ADD'
              : drawerType === 'addFoodMenu'
              ? 'ADD'
              : drawerType === 'lunch'
              ? 'LUNCH'
              : 'EDIT'
          }
          title={
            drawerType === 'addFoodMenu'
              ? 'Add food menu'
              : drawerType === 'lunch'
              ? 'Lunch Menu'
              : 'Edit food menu'
          }
          toggleDrawer={toggleDrawer}
        >
          {/* -----------FORM----- */}
          <Grid sx={{ height: '100%', overflowY: 'auto', width: '100%' }}>
            {(drawerType === 'addFoodMenu' || drawerType === 'edit') && (
              <Grid
                sx={{
                  paddingX: 3,
                  paddingTop: 3,
                  height: '100%',
                  overflowY: 'auto',
                  paddingRight: { sm: '5px' },
                  width: '100%',
                }}
              >
                <Grid container>
                  <Grid xs={12} sx={{ paddingRight: { sm: 2 }, marginTop: 1 }}>
                    <ProfileImageField image={image} setImage={setImage} />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid
                    xs={12}
                    sm={6}
                    sx={{ paddingRight: { sm: 2 }, marginTop: 1 }}
                  >
                    <Grid item xs={12} sx={{ marginY: 1 }}>
                      <Typography variant="subtitle2">
                        Title <span style={{ color: 'red' }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        size="small"
                        required
                        variant="outlined"
                        value={formData.title}
                        onChange={(e) => {
                          handleFormData('title', e.target.value);
                          setErrors({ ...errors, title: false });
                        }}
                        sx={{
                          marginBottom: '1rem',
                          width: '100%',
                          fontSize: '14px',
                        }}
                        helperText={errors.title ? 'Value is missing' : ' '}
                        error={errors.title}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    xs={12}
                    sm={6}
                    sx={{ paddingRight: { sm: 2 }, marginTop: 1 }}
                  >
                    <Grid item xs={12} sx={{ marginY: 1 }}>
                      <Typography variant="subtitle2">
                        Category <span style={{ color: 'red' }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <SelectCategory
                        type="restaurant"
                        value={formData.category}
                        onChange={(e,v) => {
                          handleFormData('category', v?.id);
                        }}
                        sx={{ marginBottom: '1rem' }}
                        helperText={errors.category ? 'Value is missing' : ' '}
                        error={errors.category}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid xs={12} sx={{ paddingRight: { sm: 2 }, marginTop: 1 }}>
                    <Grid item xs={12} sx={{ marginY: 1 }}>
                      <Typography variant="subtitle2">
                        Description <span style={{ color: 'red' }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        width: '100%',
                      }}
                    >
                      <TextField
                        InputProps={{
                          inputComponent: TextareaAutosize,
                          inputProps: {
                            style: {
                              resize: 'auto',
                              height: '120px',
                            },
                          },
                        }}
                        size="small"
                        required
                        variant="outlined"
                        placeholder="Describe your food menu here..."
                        style={{
                          minHeight: '120px',
                          fontSize: '16px',
                          width: '100%',
                        }}
                        onChange={(e) => {
                          handleFormData('description', e.target.value);
                          setErrors({ ...errors, description: false });
                        }}
                        value={formData.description}
                        // helperText={
                        //   errors.description ? 'Value is missing' : ' '
                        // }
                        error={errors.description}
                      />
                      <FormHelperText error>
                        {errors.description ? 'Value is missing' : ' '}
                      </FormHelperText>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid
                    xs={12}
                    sm={6}
                    sx={{ paddingRight: { sm: 2 }, marginTop: 1 }}
                  >
                    <Grid item xs={12} sx={{ marginY: 1 }}>
                      <Typography variant="subtitle2">
                        Standard price <span style={{ color: 'red' }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        size="small"
                        required
                        variant="outlined"
                        sx={{
                          marginBottom: '1rem',
                          width: '100%',
                          fontSize: '14px',
                        }}
                        onChange={(e) => {
                          handleFormData('price', e.target.value);
                          setErrors({ ...errors, price: false });
                        }}
                        value={formData.price}
                        helperText={errors.price ? 'Value is missing' : ' '}
                        error={errors.price}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    xs={12}
                    sm={6}
                    sx={{ paddingRight: { sm: 2 }, marginTop: 1 }}
                  >
                    <Grid item xs={12} sx={{ marginY: 1 }}>
                      <Typography variant="subtitle2">
                        Lunch price <span style={{ color: 'red' }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        size="small"
                        required
                        variant="outlined"
                        sx={{
                          marginBottom: '1rem',
                          width: '100%',
                          fontSize: '14px',
                        }}
                        onChange={(e) => {
                          handleFormData('lPrice', e.target.value);
                          setErrors({ ...errors, lPrice: false });
                        }}
                        value={formData.lPrice}
                        helperText={errors.lPrice ? 'Value is missing' : ' '}
                        error={errors.lPrice}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {drawerType === 'lunch' && (
              <Grid
                sx={{
                  paddingX: 3,
                  paddingTop: 3,
                  height: '100%',
                  overflowY: 'auto',
                  // paddingRight: { sm: "5px" },
                  width: '100%',
                }}
              >
                <Box sx={{ marginBottom: '20px' }}>
                  <Grid>
                    <FormControlLabel
                      sx={{
                        '& .MuiFormControlLabel-label': {
                          fontWeight: 'bold',
                          marginTop: '5px',
                        },
                      }}
                      control={
                        <Checkbox
                          onChange={() => {
                            setShowAll((prev) => !prev);
                          }}
                          // defaultChecked
                          sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
                        />
                      }
                      label="Enable weekly lunch menu"
                    />
                  </Grid>
                  <Grid>
                    <Typography
                      sx={{
                        marginLeft: '37px',
                        fontSize: '14px',
                      }}
                    >
                      Uncheck this option will not make your lunch menu visible
                      in Upnize network.
                    </Typography>
                  </Grid>
                </Box>
                {showAll && (
                  <>
                    {days.map((dayLabel) => {
                      return (
                        <Box sx={{ marginBottom: '20px' }}>
                          <Grid>
                            <FormControlLabel
                              sx={{
                                '& .MuiFormControlLabel-label': {
                                  fontWeight: 'bold',
                                  marginTop: '5px',
                                },
                              }}
                              control={
                                <Checkbox
                                  onChange={() => {
                                    setDaysCheckboxState({
                                      ...daysCheckboxState,
                                      [dayLabel]: !daysCheckboxState[dayLabel],
                                    });
                                  }}
                                  sx={{
                                    '& .MuiSvgIcon-root': { fontSize: 30 },
                                  }}
                                />
                              }
                              label={
                                dayLabel[0].toUpperCase() +
                                dayLabel.substring(1).toLowerCase()
                              }
                            />
                          </Grid>
                          {daysCheckboxState[dayLabel] && (
                            <>
                              <Grid sx={{ marginBottom: '10px' }}>
                                <Typography
                                  sx={{
                                    fontWeight: 'bold',
                                  }}
                                >
                                  Select lunch menu for Monday
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Autocomplete
                                  multiple
                                  size="small"
                                  options={foodItemsOptions}
                                  getOptionLabel={(option) => option.title}
                                  isOptionEqualToValue={(opt, val) =>
                                    opt.id === val.id
                                  }
                                  value={selectedmenuItems[dayLabel]}
                                  onChange={(e, v) => {
                                    setSelectedmenuItems({
                                      ...selectedmenuItems,
                                      [dayLabel]: v,
                                    });
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder="Select..."
                                    />
                                  )}
                                  sx={{ marginBottom: '1rem' }}
                                  fullWidth
                                />
                              </Grid>
                            </>
                          )}
                        </Box>
                      );
                    })}
                  </>
                )}
              </Grid>
            )}
          </Grid>
          {/* -----Button */}
          <DrawerFooter>
            <>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: isSmallScreen ? 'flex-start' : 'flex-end',
                }}
              ></div>
              <div
                style={{
                  display: 'flex',
                  gap: '15px',
                  justifyContent: isSmallScreen ? 'space-between' : 'flex-end',
                  width: isSmallScreen ? '100%' : 'auto',
                  alignItems: 'center',
                }}
              >
                <CustomTextButton
                  variant="contained"
                  size="large"
                  onClick={() => {
                    toggleDrawer();
                  }}
                >
                  Cancel
                </CustomTextButton>
                <CustomTextButton
                  type="blue"
                  variant="contained"
                  size="large"
                  onClick={() => {
                    if (drawerType === 'addFoodMenu') {
                      handleAddFoodItem();
                    } else if (drawerType === 'lunch') {
                      handleEditMenu();
                    } else {
                      handleEditFooditem();
                    }
                  }}
                >
                  {drawerType === 'add' ? 'Save' : 'Save'}
                </CustomTextButton>
              </div>
            </>
          </DrawerFooter>
        </Drawer>
      </Grid>
    </Grid>
  );
};

export default AddFoodMenu;
