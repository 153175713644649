import { Grid, Typography, Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomUploadImg from '../../../components/CustomUploadImg/CustomUploadImg';

const NetworkImages = ({
  interiorList,
  setInteriorList,
  exteriorList,
  setExteriorList,
  interiorCurrentList,
  exteriorCurrentList,
  image,
  setImage,

  setUploadedInteriorCurrentList,
  setUploadedExteriorCurrentList,
  uploadedInteriorCurrentList,
  uploadedExteriorCurrentList,
}) => {
  const [hoverInterior, setHoverInterior] = useState({});
  const [hoverExterior, setHoverExterior] = useState({});

  const deletefromInterior = (img, i) => {
    if (img.includes('base64')) {
      // if the image have a file in interiorList s name
      // setInteriorCurrentList((prev) =>
      //   prev.filter((item, index) => index !== i)
      // );
      setInteriorList((prev) =>
        prev.filter(
          (item, index) => index !== i - uploadedInteriorCurrentList.length
        )
      );
      console.log(interiorList);
    } else {
      // if already uploaded (a backend URL)
      setUploadedInteriorCurrentList((prev) =>
        prev.filter((item, index) => index !== i)
      );
    }
  };

  const deletefromExterior = (img, i) => {
    if (img.includes('base64')) {
      // if the image have a file in exteriorList s name

      setExteriorList((prev) =>
        prev.filter(
          (item, index) => index !== i - uploadedExteriorCurrentList.length
        )
      );
    } else {
      // if already uploaded (a backend URL)
      setUploadedExteriorCurrentList((prev) =>
        prev.filter((item, index) => index !== i)
      );
    }
  };

  const mouseOverInterior = (event, index) => {
    setHoverInterior((c) => {
      return {
        ...c,
        [index]: true,
      };
    });
  };

  const mouseOutInterior = (event, index) => {
    setHoverInterior((c) => {
      return {
        ...c,
        [index]: false,
      };
    });
  };

  const mouseOverExterior = (event, index) => {
    setHoverExterior((c) => {
      return {
        ...c,
        [index]: true,
      };
    });
  };

  const mouseOutExterior = (event, index) => {
    setHoverExterior((c) => {
      return {
        ...c,
        [index]: false,
      };
    });
  };

  return (
    <Grid
      sx={{
        // paddingX: "24px",
        paddingTop: 3,
        height: '100%',
        overflowY: 'auto',
        padding: '0px',
      }}
    >
      <Grid container sx={{ marginBottom: '24px' }}>
        <Grid xs={12} sx={{ marginTop: 1 }}>
          <Typography sx={{ marginBottom: '5px', fontSize: '14px' }}>
            Upload a header image
          </Typography>
          <CustomUploadImg
            aspectRatio={`64 / 15`}
            image={image}
            setImage={setImage}
            text="1920 x 450 px"
          />
        </Grid>
      </Grid>
      <Grid container sx={{ marginBottom: '24px' }}>
        <Grid xs={12} sx={{ marginTop: 1, display: 'flex' }}>
          <Grid sx={{ flex: 1, marginRight: '15px' }}>
            <Typography sx={{ marginBottom: '5px', fontSize: '14px' }}>
              Upload images for interior
            </Typography>
            <CustomUploadImg
              h="180px"
              w="140px"
              text="350 x 450 px"
              imagesList={interiorList}
              setImagesList={setInteriorList}
            />
          </Grid>
          <Grid
            sx={{
              flex: 2,
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-end',
              overflowX: 'scroll',
              overflowY: 'hidden',
              '::-webkit-scrollbar': {
                display: 'none',
              },
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
            }}
          >
            {interiorCurrentList.map((img, index) => (
              <Box
                key={index}
                sx={{
                  position: 'relative',
                  height: '180px',
                  width: '140px',
                  marginRight: '6px',
                }}
                onMouseOver={(e) => {
                  mouseOverInterior(e, index);
                }}
                onMouseLeave={(e) => {
                  mouseOutInterior(e, index);
                }}
              >
                <img
                  style={{
                    height: '180px',
                    width: '140px',
                    marginRight: '6px',
                    objectFit: 'cover',
                  }}
                  src={
                    img.includes('base64')
                      ? img
                      : process.env.REACT_APP_UPNIZE_BACKEND_URL + '/' + img
                  }
                  alt=""
                />
                {hoverInterior[index] && (
                  <Button
                    onClick={() => {
                      deletefromInterior(img, index);
                    }}
                    variant="contained"
                    color="error"
                    sx={{
                      position: 'absolute',
                      zIndex: '1',
                      top: '50%',
                      right: '50%',
                      transform: 'translate(50%,-50%)',
                      color: '#fff',
                      // backgroundColor: "#F62600",
                      width: '60px',
                      height: '30px',
                      textTransform: 'capitalize',
                      padding: '5px',
                      border: 'none',
                      borderRadius: '0',
                      outline: 'none',
                      cursor: 'pointer',
                    }}
                  >
                    Delete
                  </Button>
                )}
              </Box>
            ))}
          </Grid>
        </Grid>
      </Grid>

      <Grid container sx={{ marginBottom: '24px' }}>
        <Grid xs={12} sx={{ marginTop: 1, display: 'flex' }}>
          <Grid sx={{ flex: 1, marginRight: '15px' }}>
            <Typography sx={{ marginBottom: '5px', fontSize: '14px' }}>
              Upload images for exterior
            </Typography>
            <CustomUploadImg
              h="180px"
              w="140px"
              text="350 x 450 px"
              imagesList={exteriorList}
              setImagesList={setExteriorList}
            />
          </Grid>
          <Grid
            sx={{
              flex: 2,
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-end',
              overflowX: 'scroll',
              overflowY: 'hidden',
              '::-webkit-scrollbar': {
                display: 'none',
              },
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
            }}
          >
            {exteriorCurrentList.map((img, index) => (
              <Box
                key={index}
                sx={{
                  position: 'relative',
                  height: '180px',
                  width: '140px',
                  marginRight: '6px',
                }}
                onMouseOver={(e) => {
                  mouseOverExterior(e, index);
                }}
                onMouseLeave={(e) => {
                  mouseOutExterior(e, index);
                }}
              >
                <img
                  style={{
                    height: '180px',
                    width: '140px',
                    marginRight: '6px',
                    objectFit: 'cover',
                  }}
                  src={
                    img.includes('base64')
                      ? img
                      : process.env.REACT_APP_UPNIZE_BACKEND_URL + '/' + img
                  }
                  alt=""
                />
                {hoverExterior[index] && (
                  <Button
                    onClick={() => {
                      deletefromExterior(img, index);
                    }}
                    variant="contained"
                    color="error"
                    sx={{
                      position: 'absolute',
                      zIndex: '1',
                      top: '50%',
                      right: '50%',
                      transform: 'translate(50%,-50%)',
                      color: '#fff',
                      // backgroundColor: "#F62600",
                      width: '60px',
                      height: '30px',
                      textTransform: 'capitalize',
                      padding: '5px',
                      border: 'none',
                      borderRadius: '0',
                      outline: 'none',
                      cursor: 'pointer',
                    }}
                  >
                    Delete
                  </Button>
                )}
              </Box>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NetworkImages;
