import { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Grid,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import axios from "axios";
import { SERVER_URL } from "../../../pages/Billing/configs";

const mailGatewayValues = ["Wordpress Mail", "SMTP", "Gmail SMTP"];
const smtpSecureValues = ["TLS", "SSL", "Disabled ( not recommended )"];

const EmailSettings = () => {
  const [planId, setPlanId] = useState("");
  const [allPlans, setAllPlans] = useState([]);
  const [mailGateway, setMailGatway] = useState("Wordpress Mail");

  const mediaQuery1238 = useMediaQuery("(min-width:1238px)");

  useEffect(() => {
    fetchPlans();
    setPlanId("");
  }, []);

  const fetchPlans = async () => {
    const response = await axios.post(SERVER_URL + "/get-plan");
    const plansData = response.data.data.map((plan) => {
      plan.label = plan.name;
      return plan;
    });
    setAllPlans(plansData);
  };

  const handlePlan = (e, selectedData) => {
    if (selectedData && e) setPlanId(selectedData.id);
  };

  return (
    <>
      <Grid container xs={12} style={{ justifyContent: "flex-end" }}>
        <Button
          sx={{
            width: { xs: "100%", sm: "auto" },
            marginTop: { xs: 9, sm: 5 },
            marginBottom: 1,
            marginLeft: { xs: 0, sm: 1 },
            backgroundColor: "#4abf60",
            height: "45px",
            borderRadius: "2px",
            fontSize: "12px",
            fontWeight: 600,
          }}
          variant="contained"
          color="success"
          onClick={() => {}}
        >
          <CheckIcon sx={{ marginRight: 1, fontSize: "20px" }} />
          Save Changes
        </Button>
      </Grid>

      <Grid xs={12} style={{ backgroundColor: "white", marginTop: "20px" }}>
        <Grid
          sx={{
            padding: { xs: "15px", md: "15px 25px" },
            borderBottom: "1px solid #e3eaf3",
          }}
        >
          <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>
            Email Settings
          </Typography>
        </Grid>
        <Grid container sx={{ padding: { xs: "15px", md: "15px 25px" } }}>
          <Grid container xs={12}>
            <Grid
              xs={12}
              sm={mediaQuery1238 ? 6 : 12}
              sx={{ paddingRight: { sm: 1 } }}
            >
              <Grid item xs={12} sx={{ marginY: 1 }}>
                <Typography variant="subtitle2">Mail Gatway</Typography>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={mailGatewayValues}
                  value={mailGateway}
                  sx={{ marginBottom: "2rem", fontSize: "14px" }}
                  onChange={(e, data) => setMailGatway(data)}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>

          {mailGateway === "SMTP" && (
            <Grid
              container
              xs={12}
              sx={{
                background:
                  "linear-gradient(to right,#c0d6df 50%,transparent 0%),linear-gradient(#c0d6df 50%,transparent 0%),linear-gradient(to right,#c0d6df 50%,transparent 0%),linear-gradient(#c0d6df 50%,transparent 0%)",
                backgroundPosition: "top,right,bottom,left",
                backgroundRepeat: "repeat-x,repeat-y",
                backgroundSize: "8px 1px,1px 8px",
                paddingX: "20px",
                paddingTop: "20px",
                marginBottom: "15px",
              }}
            >
              <Grid xs={12} sm={6} sx={{ paddingRight: 1 }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">SMTP Hostname:</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value=""
                    onChange={(e) => {
                      /*handleSelectedValue("newPassword", e.target.value)*/
                    }}
                    id="outlined-basic"
                    size="small"
                    required
                    variant="outlined"
                    sx={{
                      marginBottom: "2rem",
                      width: "100%",
                      fontSize: "14px",
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container xs={12} sm={mediaQuery1238 ? 6 : 12}>
                <Grid xs={12} sm={6} sx={{ paddingRight: 1 }}>
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">SMTP Port:</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      value=""
                      onChange={(e) => {
                        /*handleSelectedValue("newPassword", e.target.value)*/
                      }}
                      id="outlined-basic"
                      size="small"
                      required
                      variant="outlined"
                      sx={{
                        marginBottom: "2rem",
                        width: "100%",
                        fontSize: "14px",
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid xs={12} sm={mediaQuery1238 ? 6 : 12}>
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">SMTP Secure:</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={smtpSecureValues}
                      value="TLS"
                      sx={{ marginBottom: "2rem", fontSize: "14px" }}
                      onChange={(e, data) => {
                        handlePlan(e, data);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Select" size="small" />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid xs={12} sm={6} sx={{ paddingRight: 1 }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">Username:</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value=""
                    onChange={(e) => {
                      /*handleSelectedValue("newPassword", e.target.value)*/
                    }}
                    id="outlined-basic"
                    size="small"
                    required
                    variant="outlined"
                    sx={{
                      marginBottom: "2rem",
                      width: "100%",
                      fontSize: "14px",
                    }}
                  />
                </Grid>
              </Grid>

              <Grid xs={12} sm={6} sx={{ paddingRight: 1 }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">Password:</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value=""
                    onChange={(e) => {
                      /*handleSelectedValue("newPassword", e.target.value)*/
                    }}
                    id="outlined-basic"
                    size="small"
                    required
                    variant="outlined"
                    sx={{
                      marginBottom: "2rem",
                      width: "100%",
                      fontSize: "14px",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          {mailGateway === "Gmail SMTP" && (
            <Grid
              container
              xs={12}
              sx={{
                background:
                  "linear-gradient(to right,#c0d6df 50%,transparent 0%),linear-gradient(#c0d6df 50%,transparent 0%),linear-gradient(to right,#c0d6df 50%,transparent 0%),linear-gradient(#c0d6df 50%,transparent 0%)",
                backgroundPosition: "top,right,bottom,left",
                backgroundRepeat: "repeat-x,repeat-y",
                backgroundSize: "8px 1px,1px 8px",
                padding: "20px",
                marginBottom: "15px",
              }}
            >
              <Grid xs={12} sm={6} sx={{ paddingRight: 1 }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">Client ID:</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value=""
                    onChange={(e) => {
                      /*handleSelectedValue("newPassword", e.target.value)*/
                    }}
                    id="outlined-basic"
                    size="small"
                    required
                    variant="outlined"
                    sx={{
                      marginBottom: "2rem",
                      width: "100%",
                      fontSize: "14px",
                    }}
                  />
                </Grid>
              </Grid>

              <Grid xs={12} sm={6}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">Client secret:</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    value=""
                    onChange={(e) => {
                      /*handleSelectedValue("newPassword", e.target.value)*/
                    }}
                    id="outlined-basic"
                    size="small"
                    required
                    variant="outlined"
                    sx={{
                      marginBottom: "2rem",
                      width: "100%",
                      fontSize: "14px",
                    }}
                  />
                </Grid>
              </Grid>

              <Grid xs={12}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">Redirect URL:</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled
                    value="https://upnize.com/admin?gmail_smtp=true"
                    onChange={(e) => {
                      /*handleSelectedValue("newPassword", e.target.value)*/
                    }}
                    id="outlined-basic"
                    size="small"
                    required
                    variant="outlined"
                    sx={{
                      marginBottom: "2rem",
                      width: "100%",
                      fontSize: "14px",
                    }}
                  />
                </Grid>
              </Grid>

              <Grid xs={12} sx={{ paddingRight: 1 }}>
                <Button variant="contained">Authorize</Button>
              </Grid>
            </Grid>
          )}

          <Grid xs={12} sm={6} sx={{ paddingRight: 1 }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">Sender E-mail</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value=""
                onChange={(e) => {
                  /*handleSelectedValue("newPassword", e.target.value)*/
                }}
                id="outlined-basic"
                size="small"
                required
                variant="outlined"
                sx={{ marginBottom: "2rem", width: "100%", fontSize: "14px" }}
              />
            </Grid>
          </Grid>

          <Grid xs={12} sm={6}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">Sender Name</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value=""
                onChange={(e) => {
                  /*handleSelectedValue("newPassword", e.target.value)*/
                }}
                id="outlined-basic"
                size="small"
                required
                variant="outlined"
                sx={{ marginBottom: "2rem", width: "100%", fontSize: "14px" }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default EmailSettings;
