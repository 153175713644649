import React, { useEffect, useState } from 'react';
import Drawer from '../../components/Drawer';
import DrawerFooter from '../../components/DrawerFooter';
import Grid from '@mui/material/Grid';
import { useMediaQuery, useTheme } from '@mui/material';
import { useLayoutEffect } from 'react';
import CustomTextButton from '../../components/CustomButtons/CustomTextButton';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import { Typography, TextareaAutosize, Autocomplete } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { addTask } from '../../Api/tenant/tasks';
import { getLocationList } from '../../Api/tenant/locations';
import { getStaffList } from '../../Api/tenant/staff';
import { editTaskById } from '../../Api/tenant/tasks';
import { getCustomerList } from '../../Api/tenant/customers';
import { selectUser } from '../../redux/slices/user.slice.js';
import { useSelector } from 'react-redux';

const statuslist = [
  'To-Do',
  'In Progress',
  'On Hold',
  'Completed',
  'Overdue',
  'Deferred',
  'Cancelled',
  'Waiting',
  'Scheduled',
  'Blocked',
];

const requiredFields = ['title', 'status', 'priority'];

const prioritylist = ['Critical', 'High', 'Medium', 'Low'];

const hardCodedRelatedOptions = [
  { name: 'staff', id: 1 },
  { name: 'customer', id: 2 },
];

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB

// Add Attachment
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const AddTask = ({
  toggleAddTaskDrawer,
  drawerType,
  drawerData = null,
  handelHide,
  fetchTasks,
}) => {
  const [formData, setFormData] = useState({
    title: null,
    description: null,
    status: 'To-Do',
    relatedTo: null,
    startDate: new Date(),
    endDate: new Date(),
    selectedLocation: null,
    priority: 'Medium',
    referenceId: null,
    orderId: null,
    relatedTo: null,
    relatedToValue: null,
  });
  const { user } = useSelector(selectUser);
  const [loading, setLoading] = useState(true);
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [attachments, setAttachments] = useState([]);

  //data picker
  const [isStartDateOpen, setIsStartDateOpen] = useState(false);
  const [isEndDateOpen, setIsEndDateOpen] = useState(false);

  // options for select
  const [locations, setLocations] = useState([]);
  const [staff, setStaff] = useState([]);
  const [customers, setCustomers] = useState([]);

  // error handling
  const [errors, setErrors] = useState({ endDate: false });

  useLayoutEffect(() => {
    if (drawerType === 'edit') {
      // Here we will find selectedLocation with locatinId we have and then populating formData
      let foundLocation;
      if (locations.length !== 0) {
        foundLocation = locations.filter(
          (loc) => loc.id == drawerData.locationId
        );
      }

      setFormData({
        ...drawerData,
        relatedToValue: drawerData.relatedToUser,
        selectedLocation: foundLocation ? foundLocation[0] : null,
      });

      setSelectedStaff(drawerData.responsibility);
    }
  }, [drawerType, drawerData, locations.length]);

  //Fetch locations function
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch locations
        const locationResponse = await getLocationList();
        if (
          locationResponse.status === 200 &&
          Array.isArray(locationResponse.data.rows)
        ) {
          setLocations(locationResponse.data.rows);
        } else {
          console.error(
            'Error fetching locations:',
            locationResponse.statusText
          );
        }

        // Fetch staff
        const staffResponse = await getStaffList();
        if (
          staffResponse.status === 200 &&
          Array.isArray(staffResponse.data.rows)
        ) {
          setStaff(staffResponse.data.rows);
          if (drawerType === 'ADD') {
            setSelectedStaff(
              staffResponse.data.rows.filter((i) => i.UserId === user.id)[0]
            );
          }
        } else {
          console.error('Error fetching staff:', staffResponse.statusText);
        }

        // Fetch customers
        const customersResponse = await getCustomerList(100, 1);
        if (
          customersResponse.status === 200 &&
          Array.isArray(customersResponse.data)
        ) {
          setCustomers(customersResponse.data);
        } else {
          console.error('Error fetching staff:', customersResponse.statusText);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  //show attachment link
  const handleAttachmentChange = (event) => {
    const files = event.target.files;
    if (files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file.size <= MAX_FILE_SIZE) {
          const newAttachment = file;
          setAttachments((prevAttachments) => [
            ...prevAttachments,
            newAttachment,
          ]);
        } else {
          alert(
            `File is to big. Max size is ${MAX_FILE_SIZE / (1024 * 1024)} MB.`
          );
        }
      }
    }
  };

  //delete atachment
  const handleDeleteAttachment = (index) => {
    const updatedAttachments = [...attachments];
    updatedAttachments.splice(index, 1);
    setAttachments(updatedAttachments);
  };

  // API
  const handleSelectData = (dataOrSetter, fieldName) => {
    setFormData((prevState) => {
      if (typeof dataOrSetter === 'function') {
        return dataOrSetter(prevState);
      } else {
        return {
          ...prevState,
          [fieldName]: dataOrSetter,
        };
      }
    });
  };

  const handleAddTask = async () => {
    // Validation handling
    let err = {};

    for (const key in formData) {
      if (!formData[key] && requiredFields.includes(key)) {
        err = { ...err, [key]: true };
      }
    }

    setErrors({ ...err });

    if (Object.keys(err).length === 0) {
      let formdata = new FormData();
      try {
        formdata.append('title', formData.title);
        formdata.append('description', formData.description);
        formdata.append('status', formData.status);
        formdata.append('priority', formData.priority);
        formData.startDate && formdata.append('startDate', formData.startDate);
        formData.endDate && formdata.append('endDate', formData.endDate);
        formdata.append('referenceId', formData.referenceId);
        formdata.append('orderId', formData.orderId);
        formData.relatedTo && formdata.append('relatedTo', formData.relatedTo);
        formData.relatedToValue &&
          formdata.append('relatedToId', formData.relatedToValue.UserId);
        formdata.append('public', true);
        selectedStaff && formdata.append('responsible', selectedStaff?.id);
        formData?.selectedLocation &&
          formdata.append('locationId', formData?.selectedLocation?.id);
        formdata.append(
          'creator',
          JSON.stringify({
            firstName: formData.selectedStaff?.firstName,
            image: formData.selectedStaff?.image,
          })
        );
        attachments.map((attachment) => {
          formdata.append('attachments', attachment);
        });
      } catch {}

      try {
        const response = await addTask(formdata);
        if (response.status === 200) {
          const newTaskData = response.data; // Assuming response.data contains the new task data
          fetchTasks();
          setFormData([newTaskData, ...Object.values(formData)]); // Prepend the new task data to the array
          toggleAddTaskDrawer();
        } else {
          console.error('Failed to add task:', response);
        }
      } catch (error) {
        console.error('Error adding task:', error);
      }
    }
  };

  const handleEditTask = () => {
    setLoading(true);
    const {
      id,
      description,
      title,
      status,
      priority,
      startDate,
      endDate,
      referenceId,
      orderId,
      image,
      attachment,
    } = formData;

    if (!id) {
      setLoading(false);
      return;
    }

    let formdata = new FormData();
    formdata.append('id', id);
    formdata.append('title', title);
    description && formdata.append('description', description);
    status && formdata.append('status', status);
    priority && formdata.append('priority', priority);
    startDate && formdata.append('startDate', startDate);
    endDate && formdata.append('endDate', endDate);
    referenceId && formdata.append('referenceId', referenceId);
    orderId && formdata.append('orderId', orderId);
    selectedStaff && formdata.append('responsible', selectedStaff?.id);
    formData.relatedTo && formdata.append('relatedTo', formData.relatedTo);
    formData.relatedToValue &&
      formdata.append(
        'relatedToId',
        formData.relatedToValue.UserId
          ? formData.relatedToValue.UserId
          : formData.relatedToValue.id
      );
    formdata.append('public', true);

    formdata.append(
      'creator',
      JSON.stringify({
        firstName: selectedStaff?.firstName,
        image: selectedStaff?.image,
      })
    );

    if (attachments && attachments.length > 0) {
      attachments.forEach((attachment) => {
        formdata.append('attachments', attachment);
      });
    }

    editTaskById(formdata, id)
      .then((res) => {
        toggleAddTaskDrawer();
        fetchTasks();
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error editing task: ', error);
        setLoading(false);
      });
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      /*   style={{ padding: '20px' }} */
    >
      <Grid item xs={12} sm={2}>
        <Drawer
          type={drawerType}
          title={drawerType === 'ADD' ? 'Add Task' : 'Edit Task'}
          toggleDrawer={toggleAddTaskDrawer}
        >
          {/* -----------FORM----- */}
          <Grid
            sx={{
              height: '100%',
              overflowY: 'auto',
              width: '100%',
              padding: '20px',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                justifyContent: isSmallScreen ? 'flex-start' : 'flex-start',
              }}
            >
              <CustomTextButton
                component="label"
                role={undefined}
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
              >
                Add attachment
                <VisuallyHiddenInput
                  type="file"
                  onChange={handleAttachmentChange}
                />
              </CustomTextButton>
            </div>
            {/* -----------------Attachments added here----------------------- */}
            {attachments &&
              attachments.map((attachment, index) => (
                <div
                  key={index}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  {/*   <a
                    href={attachment}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                </a> */}
                  {attachment.name}
                  <CloseRoundedIcon
                    onClick={() => handleDeleteAttachment(index)}
                  />
                </div>
              ))}

            {/* ---------------------Title------------------------------- */}
            <Grid container>
              <Grid
                xs={12}
                sm={12}
                sx={{ paddingRight: { sm: 0 }, marginTop: 1 }}
              >
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">
                    Title <span style={{ color: 'red' }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-basic"
                    size="small"
                    required
                    variant="outlined"
                    sx={{
                      marginBottom: '1rem',
                      width: '100%',
                      fontSize: '14px',
                    }}
                    type="text"
                    onChange={(e) => {
                      handleSelectData(e.target.value, 'title');
                      setErrors({ ...errors, name: false });
                    }}
                    value={formData.title}
                    helperText={errors.title ? 'Value is missing' : ' '}
                    error={errors.title}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* ----------------Description------------------- */}
            <Grid container>
              <Grid xs={12} sm={12} sx={{ paddingRight: { sm: 0 } }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">Description</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    InputProps={{
                      inputComponent: TextareaAutosize,
                      inputProps: {
                        style: {
                          resize: 'auto',
                          height: '120px',
                        },
                      },
                    }}
                    size="small"
                    required
                    variant="outlined"
                    placeholder="Describe task"
                    style={{
                      minHeight: '120px',
                      fontSize: '16px',
                      width: '100%',
                    }}
                    onChange={(e) => {
                      handleSelectData(e.target.value, 'description');
                      setErrors({ ...errors, description: false });
                    }}
                    value={formData.description}
                    error={errors.description}
                  />
                  <FormHelperText error>
                    {errors.description ? 'Value is missing' : ' '}
                  </FormHelperText>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              {/* ------------------Status---------------- */}
              <Grid item xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">
                    Status <span style={{ color: 'red' }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    size="small"
                    onChange={(event, data) => handleSelectData(data, 'status')}
                    value={formData.status}
                    disablePortal
                    options={statuslist}
                    getOptionLabel={(option) => option}
                    sx={{
                      width: '100%',
                      fontSize: '14px',
                    }}
                    renderInput={(params) => (
                      //errormessage
                      <TextField
                        {...params}
                        placeholder={statuslist[0]}
                        helperText={errors.status ? 'Value is missing' : ' '}
                        error={errors.status}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              {/* -------------Responsible-------------- */}

              <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">Responsible</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    size="small"
                    options={staff}
                    value={selectedStaff}
                    onChange={(event, newValue) => setSelectedStaff(newValue)}
                    getOptionLabel={(option) =>
                      option?.User?.firstName
                        ? option.User.firstName
                        : option.firstName || ''
                    }
                    sx={{
                      width: '100%',
                      fontSize: '14px',
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select..."
                        helperText={
                          errors.responsible ? 'Value is missing' : ' '
                        }
                        error={errors.responsible}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* ---------------------Related to -------------------- */}
            <Grid container>
              <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">Related To</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    size="small"
                    onChange={(event, data) => {
                      handleSelectData(data.name, 'relatedTo');
                      handleSelectData(null, 'relatedToValue');
                    }}
                    value={
                      hardCodedRelatedOptions.filter(
                        (i) => i.name == formData.relatedTo
                      )[0]
                    }
                    disablePortal
                    options={hardCodedRelatedOptions}
                    getOptionLabel={(option) => option.name}
                    sx={{
                      width: '100%',
                      fontSize: '14px',
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select..."
                        value={formData.relatedTo}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              {formData.relatedTo !== null && (
                <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">
                      {formData.relatedTo === 'customer'
                        ? 'Customers'
                        : 'Staff'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      size="small"
                      onChange={(event, data) =>
                        handleSelectData(data, 'relatedToValue')
                      }
                      value={formData.relatedToValue}
                      disablePortal
                      options={
                        formData.relatedTo === 'staff' ? staff : customers
                      }
                      getOptionLabel={(option) =>
                        option.User?.firstName
                          ? option.User?.firstName
                          : option.firstName
                      }
                      sx={{
                        width: '100%',
                        fontSize: '14px',
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select..."
                          value={formData.relatedTo}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>

            {/* ----------------StartDate---------------------- */}
            <Grid container>
              <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">Start date</Typography>
                </Grid>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      open={isStartDateOpen}
                      onOpen={() => setIsStartDateOpen(!isStartDateOpen)}
                      onClose={() => setIsStartDateOpen(!isStartDateOpen)}
                      value={formData.startDate}
                      onChange={(newValue) => {
                        handleSelectData(newValue, 'startDate');
                        setErrors({ ...errors, date: false });
                      }}
                      InputProps={{
                        style: {
                          flexDirection: 'row-reverse',
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                          onClick={() => setIsStartDateOpen(!isStartDateOpen)}
                          onTouchStart={() =>
                            setIsStartDateOpen(!isStartDateOpen)
                          }
                          fullWidth
                          error={errors.date}
                          helperText={errors.date ? 'Value is missing' : ' '}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>

              {/* -------------DueDate-------------- */}
              <Grid xs={12} sm={6} sx={{ paddingLeft: { sm: 1 } }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">Due date</Typography>
                </Grid>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      open={isEndDateOpen}
                      onOpen={() => setIsEndDateOpen(!isEndDateOpen)}
                      onClose={() => setIsEndDateOpen(!isEndDateOpen)}
                      value={formData.endDate}
                      onChange={(newValue) => {
                        handleSelectData(newValue, 'endDate');
                        setErrors({ ...errors, endDate: false });
                      }}
                      InputProps={{
                        style: {
                          flexDirection: 'row-reverse',
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                          onClick={() => setIsEndDateOpen(!isEndDateOpen)}
                          onTouchStart={() => setIsEndDateOpen(!isEndDateOpen)}
                          fullWidth
                          error={errors.endDate}
                          helperText={errors.endDate ? 'Value is missing' : ' '}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>

            {/* ----------------Location---------------------- */}
            <Grid container>
              <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">Location</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    size="small"
                    options={locations}
                    loading={loading}
                    value={formData.selectedLocation}
                    onChange={(event, newValue) =>
                      handleSelectData(newValue, 'selectedLocation')
                    }
                    getOptionLabel={(option) => option.name} // Assuming each location object has a 'name' property
                    sx={{
                      width: '100%',
                      fontSize: '14px',
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select..."
                        helperText={errors.location ? 'Value is missing' : ' '}
                      />
                    )}
                  />
                </Grid>
              </Grid>

              {/* -------------Priority-------------- */}
              <Grid xs={12} sm={6} sx={{ paddingLeft: { sm: 1 } }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">
                    Priority<span style={{ color: 'red' }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    size="small"
                    onChange={(event, data) =>
                      handleSelectData(data, 'priority')
                    }
                    value={formData.priority}
                    disablePortal
                    options={prioritylist}
                    getOptionLabel={(option) => option}
                    sx={{
                      width: '100%',
                      fontSize: '14px',
                    }}
                    renderInput={(params) => (
                      //errormessage
                      <TextField
                        {...params}
                        placeholder={prioritylist[1]}
                        value={formData.priority}
                        error={errors.priority}
                        helperText={errors.priority ? 'Value is missing' : ' '}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* ----------------ReferenceID---------------------- */}
            <Grid container>
              <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">Reference ID</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-basic"
                    size="small"
                    required
                    variant="outlined"
                    sx={{
                      marginBottom: '1rem',
                      width: '100%',
                      fontSize: '14px',
                    }}
                    type="text"
                    onChange={(e) => {
                      handleSelectData(e.target.value, 'referenceId');
                      setErrors({ ...errors, name: false });
                    }}
                    value={formData.referenceId}
                    helperText={errors.referenceId ? 'Value is missing' : ' '}
                    error={errors.referenceId}
                  />
                </Grid>
              </Grid>

              {/* -------------OrderID-------------- */}
              <Grid xs={12} sm={6} sx={{ paddingLeft: { sm: 1 } }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">Order ID</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-basic"
                    size="small"
                    required
                    variant="outlined"
                    sx={{
                      marginBottom: '1rem',
                      width: '100%',
                      fontSize: '14px',
                    }}
                    type="text"
                    onChange={(e) => {
                      handleSelectData(e.target.value, 'orderId');
                      setErrors({ ...errors, name: false });
                    }}
                    value={formData.orderId}
                    helperText={errors.referenceId ? 'Value is missing' : ' '}
                    error={errors.referenceId}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* ----------Footer------------ */}
          <DrawerFooter>
            <>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: isSmallScreen ? 'flex-start' : 'normal',
                }}
              ></div>

              <div
                style={{
                  display: 'flex',
                  gap: '15px',
                  justifyContent: isSmallScreen ? 'space-between' : 'flex-end',
                  width: isSmallScreen ? '100%' : 'auto',
                  alignItems: 'center',
                }}
              >
                <CustomTextButton
                  variant="contained"
                  size="large"
                  onClick={toggleAddTaskDrawer}
                >
                  Cancel
                </CustomTextButton>
                <CustomTextButton
                  type="blue"
                  variant="contained"
                  size="large"
                  onClick={() => {
                    drawerType === 'ADD' ? handleAddTask() : handleEditTask();
                  }}
                >
                  Save
                </CustomTextButton>
              </div>
            </>
          </DrawerFooter>
        </Drawer>
      </Grid>
    </Grid>
  );
};

export default AddTask;
