import { Grid, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

function Drawerfooter({ children }) {
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down("sm"));

  return (
    <Grid
      item
      container
      sx={{
        display: "flex",
        justifyContent: "space-between",
        boxShadow: "5px -2px 10px -5px #e4ebf4",
        borderTop: "1px solid #E4EBF4",
        paddingY: 1,
        backgroundColor: "white",
        paddingX: "24px",
        flexDirection: { xs: "column", sm: "row" },
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: isSmallScreen ? "column" : "row",
          paddingY: "10px",
        }}
      >
        {children}
      </Grid>
    </Grid>
  );
}

export default Drawerfooter;
