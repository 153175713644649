export const convertTime24to12 = (time24h) => {
  const time12h = new Date('1970-01-01T' + time24h + 'Z').toLocaleTimeString(
    'en-US',
    {
      timeZone: 'UTC',
      hour12: true,
      hour: 'numeric',
      minute: 'numeric',
    }
  );

  // return `${time12h}`;
  return `${time24h}`;
};
