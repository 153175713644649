import { Grid, Pagination, PaginationItem, Typography } from '@mui/material';

export const CustomPagination = ({ rowCount, page, onPageChange }) => {
  const pageSize = 10; // Number of rows per page
  const pageCount = Math.ceil(rowCount / pageSize);

  const handleChange = (event, value) => {
    onPageChange(value);
  };

  return (
    <Grid
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Typography
        sx={{
          color: '#1976D2',
          paddingLeft: '10px',
          width: '40%',
        }}
        variant="body2"
      >{`${Math.min(
        page * pageSize,
        rowCount
      )} of ${rowCount} rows`}</Typography>
      <Pagination
        count={pageCount}
        page={page}
        onChange={handleChange}
        variant="outlined"
        shape="rounded"
        renderItem={(item) => {
          if (item.type === 'previous') {
            return (
              <PaginationItem
                {...item}
                type="previous"
                onClick={() => onPageChange(page - 1)}
                sx={{ border: 'none', color: '#1976D2' }}
              >
                {'< Previous'}
              </PaginationItem>
            );
          }
          if (item.type === 'next') {
            return (
              <PaginationItem
                {...item}
                type="next"
                onClick={() => onPageChange(page + 1)}
                sx={{ border: 'none', color: '#1976D2' }}
              >
                {'Next >'}
              </PaginationItem>
            );
          }
          return (
            <PaginationItem
              {...item}
              sx={{
                backgroundColor: item.selected ? '#fff !important' : '#fff',
                color: item.selected ? '#1976D2' : '#1976D2',
                borderColor: item.selected ? '#1976D2' : '#f4effe',
                borderRadius: '50%',
              }}
              onClick={() => onPageChange(item.page)}
            />
          );
        }}
      />
    </Grid>
  );
};
