import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteModal from '../../components/DeleteModal';
import { Divider, Modal, useMediaQuery, useTheme } from '@mui/material';
import PaymentData from './Payment';
import UsageHistory from './UsageHistory';
import 'react-datepicker/dist/react-datepicker.css';
import PaymentTable from '../../components/PaymentTable';
import { CSVLink } from 'react-csv';
import CustomIconButton from '../../components/CustomButtons/CustomIconButton';
import useFilterBar from '../../hooks/useFilterBar';
import CustomTextButton from '../../components/CustomButtons/CustomTextButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';

const filters = [
  {
    id: 1,
    title: 'Quick search',
    type: 'search',
    queryLabel: 'keyword',
  },
  {
    id: 2,
    title: 'Select date',
    type: 'date',
    queryLabel: 'date',
  },
  {
    id: 3,
    title: 'Service',
    type: 'select',
    options: ['Loading'],
    isOpen: false,
    search: '',
    queryLabel: 'service',
  },
  {
    id: 4,
    title: 'Customer',
    type: 'select',
    options: ['Loading'],
    isOpen: false,
    search: '',
    queryLabel: 'customer',
  },
  {
    id: 5,
    title: 'Staff',
    type: 'select',
    options: ['Loading'],
    isOpen: false,
    search: '',
    queryLabel: 'staff',
  },
  {
    id: 6,
    title: 'Status',
    type: 'select',
    options: ['Pending', 'Paid', 'Canceled', 'Not Paid'],
    isOpen: false,
    search: '',
    queryLabel: 'status',
  },
];

const Payment = () => {
  const [tableData, settableData] = useState([]);
  const [addCouponDrawer, setAddCouponDrawer] = useState(false);
  const [drawerType, setDrawerType] = useState('add');
  const [drawerData, setDrawerData] = useState(null);
  const [openModal, setOpenModal] = useState({ open: false, type: null });
  const [selectionModel, setSelectionModel] = useState([]);
  const [drawer, setDrawer] = useState(null);
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('lg'));
  const { FilterBar, state, FilterButton } = useFilterBar({
    filters,
  });
  const [customerId, setCustomerId] = useState(null);

  const toggleAddCouponDrawer = () => {
    setAddCouponDrawer(!addCouponDrawer);
  };

  const handleOpenModal = () => {
    setOpenModal({ open: false, type: null });
  };

  const handleDeleteOpenModal = () => {
    setOpenModal({ open: true, type: 'delete-modal' });
  };

  const toggleUsageHistoryModal = (e) => {
    e.stopPropagation();
    setDrawer((prevState) =>
      prevState !== 'usage-history' ? 'usage-history' : ''
    );
  };

  return (
    <Grid
      item
      container
      sx={{
        minWidth: { xs: '100%' },
        // paddingLeft: 4,
        // paddingRight: 4,
        px: '20px',
      }}
    >
      <Modal open={openModal.open}>
        <>
          {openModal.type === 'delete-modal' && (
            <DeleteModal handleOpenModal={handleOpenModal} />
          )}
        </>
      </Modal>
      {addCouponDrawer && (
        <PaymentData
          customerId={customerId}
          toggleAddCouponDrawer={toggleAddCouponDrawer}
          drawerType={drawerType}
          drawerData={drawerData}
        />
      )}
      {drawer === 'usage-history' && (
        <UsageHistory toggleDrawer={toggleUsageHistoryModal} />
      )}
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          // alignItems: 'flex-end',
          flexWrap: 'wrap',
        }}
      >
        <Typography
          sx={{
            marginTop: 2.5,
            marginBottom: 1,
            fontSize: 24,
            color: '#3A3A50',
          }}
        >
          Payment
        </Typography>
      </Grid>
      <Grid
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Box sx={{ display: 'flex', gap: '10px', ml: '10px' }}>
          <CustomIconButton Icon={UploadIcon} toolTipText="EXPORT TO CSV" />
          {FilterButton}
        </Box>
      </Grid>
      {/* --------------------Filter Options------------------------------- */}
      {FilterBar}
      {/* --------------------TABLE--------------------------------- */}
      <Divider light sx={{ width: '100%', marginTop: '30px' }} />
      <Grid
        container
        item
        justifyContent="space-between"
        sx={{ marginTop: '20px', zIndex: 0 }}
      >
        <Box
          sx={{
            width: '100%',
            // overflowX: { xs: "scroll", md: "hidden" },
          }}
        >
          <PaymentTable
            sortInfo={state}
            setCustomerId={setCustomerId}
            setDrawerType={setDrawerType}
            toggleAddCouponDrawer={toggleAddCouponDrawer}
            tableData={tableData}
            settableData={settableData}
            handleDeleteOpenModal={handleDeleteOpenModal}
            selectionModel={selectionModel}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Payment;
