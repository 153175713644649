import { UpnizeBackend } from '../Axios';

export const getFoodItemList = async (rows, page, queries) => {
  let response = await UpnizeBackend.get(
    `/restaurant/items?rows=${rows}&page=${page}${queries ? queries : ''}`
  );
  return response;
};

export const getFoodItemById = async (id) => {
  let response = await UpnizeBackend.get(`/restaurant/items/byId/${id}`);
  return response;
};

export const addFoodItem = async (body) => {
  let response = await UpnizeBackend.post(`/restaurant/items/add`, body);
  return response;
};

export const getMenu = async () => {
  let response = await UpnizeBackend.get(`/restaurant/menus`);
  return response;
};

export const addMenu = async (body) => {
  let response = await UpnizeBackend.post(`/restaurant/menus/add`, body);
  return response;
};

export const editMenu = async (body) => {
  let response = await UpnizeBackend.put(`/restaurant/menus/edit`, body);
  return response;
};

export const editFoodItemById = async (body, id) => {
  let response = await UpnizeBackend.put(`/restaurant/items/edit/${id}`, body);
  return response;
};

export const deleteFoodItemById = async (id) => {
  let response = await UpnizeBackend.delete('/restaurant/items/delete', {
    data: { ids: id },
  });
  return response;
};
