import { Button, Grid, useMediaQuery } from "@mui/material";
import React from "react";
import Drawer from "../../../components/Drawer";
import AppointmentInfoTab from "./Tabs";
import { useTheme } from "@mui/system";
import DrawerFooter from "../../../components/DrawerFooter";

const Info = ({
  toggleAddAppointmentInfoDrawer,
  drawerType,
  drawerData = null,
  handelEdit,
  handleDeleteOpenModal,
}) => {
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down("sm"));
  const handelInfoInEdit = (id) => {
    handelEdit(id);
    toggleAddAppointmentInfoDrawer();
  };
  return (
    <Grid container>
      {/* sx={{ width: "100vw"}} */}
      <Grid item xs={12} sm={2}>
        <Drawer
          toggleDrawer={toggleAddAppointmentInfoDrawer}
          title={"Appointment info"}
          type="INFO"
        >
          {/* -----------FORM----- */}
          <Grid
            sx={{
              height: "100%",
              overflowY: "auto",
              width: "100%",
            }}
          >
            <AppointmentInfoTab />
          </Grid>
          {/* -----Button */}
          <DrawerFooter>
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: isSmallScreen ? "flex-start" : "flex-end",
                }}
              >
                <Button
                  variant="contained"
                  color="error"
                  size="large"
                  sx={{
                    width: isSmallScreen ? "100%" : "7rem",
                    marginBottom: { xs: 1, sm: 0 },
                  }}
                  onClick={handleDeleteOpenModal}
                >
                  Delete
                </Button>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: isSmallScreen ? "space-between" : "flex-end",
                  width: isSmallScreen ? "100%" : "auto",
                }}
              >
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    width: isSmallScreen ? "48%" : "7rem",
                    marginLeft: { xs: 0, sm: 2 },
                  }}
                  onClick={handelInfoInEdit}
                >
                  Edit
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    width: isSmallScreen ? "48%" : "7rem",

                    marginLeft: { xs: 1, sm: 2 },
                    backgroundColor: "WHITE",
                    color: "GREY",
                    ":hover": { backgroundColor: "GREY", color: "WHITE" },
                  }}
                  onClick={toggleAddAppointmentInfoDrawer}
                >
                  Cancel
                </Button>
              </div>
            </>
          </DrawerFooter>
        </Drawer>
      </Grid>
    </Grid>
  );
};

export default Info;
