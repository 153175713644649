import { Box, Grid } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import SpecialDays from './SpecialDays';
import WeeklySchedule from './WeeklySchedule';

const TimeSheet = ({ timesheets, setTimesheets, spts, setSpts }) => {
  const [activeService, setActiveService] = useState(1);
  const services = [
    { id: 1, serviceName: ' WEEKLY-SCHEDULE' },
    // { id: 2, serviceName: " SPECIAL DAYS" },
  ];

  return (
    <Box>
      <Grid marginTop={2} container>
        {services.map((service) => (
          <Grid
            key={service.id}
            xs={6}
            sm={6}
            sx={{
              padding: '10px',
              border: '1px solid #adbfc7',
              fontSize: '12px',
              fontWeight: 'bold',
              textAlign: 'center',
              cursor: 'pointer',
            }}
            onClick={() => setActiveService(service.id)}
            style={
              activeService === service.id
                ? { background: '#adbfc7', color: '#fff' }
                : { background: '#fff', color: '#828f9a' }
            }
            item
          >
            {service.serviceName}
          </Grid>
        ))}
      </Grid>
      <Grid container>
        {activeService === 1 ? (
          <WeeklySchedule
            timesheets={timesheets}
            setTimesheets={setTimesheets}
            spts={spts}
            setSpts={setSpts}
          />
        ) : (
          ''
          // <SpecialDays />
        )}
      </Grid>
    </Box>
  );
};

export default TimeSheet;
