import * as React from 'react';
import Stack from '@mui/material/Stack';
import service from "../../assets/images/service.png";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CustomTextButton from '../../components/CustomButtons/CustomTextButton';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';

const UpsellService = () => {
  return (  
    <React.Fragment>
      <Container maxWidth="sm">
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center', 
          marginTop: '8%',
          paddingBottom: '5%',
          textAlign: 'center',
  
          '& img': {
            width: '100%',
            maxWidth: '70%',
            height: 'auto',
            padding: '10px', 
          },
          '@media (max-width: 600px)': {
            '& img': {
              padding: '10px',
            },
          },
        }}>
          <img
            src={service}
            alt="persons image"
          />
          <h1>Service management</h1>
          <Typography >Unlock enhanced functionality with our premium service package.
           Seamlessly create and oversee company services, with even more advanced features on the horizon.
           Upgrade now to stay ahead of the curve.</Typography>

          <Stack spacing={2} direction="row" marginTop={4}>
            <Link to="https://upnize.com/help-center/service-management">
            
            <CustomTextButton sx={{textTransform: 'capitalize'}}
                  variant="contained"
                  size="large"
                >
                  Learn more
                </CustomTextButton>
            </Link>
            <Link to="/subscription">
                <CustomTextButton sx={{textTransform: 'capitalize'}}
                  type="blue"
                  variant="contained"
                  size="large" >
                    Upgrade
                  </CustomTextButton>
            </Link>
          </Stack>
        </Box>
      </Container>
    </React.Fragment>
  );
}

export default UpsellService;
