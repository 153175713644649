import { useLayoutEffect, useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CircleIcon from '@mui/icons-material/Circle';
import MenuIcon from '@mui/icons-material/Menu';
import { ReactSortable } from 'react-sortablejs';
import { styled } from '@mui/material/styles';
import PaymentMethodsDisplay from './PaymentMethodsDisplay';
import SaveSlideBar from '../../SaveSlideBar';
import { editSettings } from '../../../Api/tenant/settings';
import CustomSettingsTitle from '../../CustomSettingsTitle/CustomSettingsTitle';

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 36,
  height: 20,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 1.5,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 17,
    height: 17,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const PaymentMethods = ({ fetchCurrentSettings, currentSettings }) => {
  const [isSaveBar, setIsSaveBar] = useState(false);
  const [paymentMethodsDraggable, setPaymentMethodsDraggable] = useState([]);
  const [paymentMethodsUnDraggable, setPaymentMethodsUnDraggable] = useState(
    []
  );
  const [paymentMethodId, setPaymentMethodId] = useState(1);
  const selectedBookingStep = useMemo(
    () =>
      [...paymentMethodsDraggable, ...paymentMethodsUnDraggable].find(
        (paymentMethod) => paymentMethod.id === paymentMethodId
      ),
    [paymentMethodsDraggable, paymentMethodsUnDraggable, paymentMethodId]
  );

  const handleCheckPaymentMethodDraggable = (currentBookingStepId) => {
    return (e) =>
      setPaymentMethodsDraggable((prevState) =>
        prevState.map((bookingStepPrev) =>
          bookingStepPrev.id === currentBookingStepId
            ? { ...bookingStepPrev, checked: e.target.checked }
            : bookingStepPrev
        )
      );
  };

  const handleCheckPaymentMethodUnDraggable = (currentBookingStepId) => {
    return (e) => {
      setIsSaveBar(true);
      setPaymentMethodsUnDraggable((prevState) =>
        prevState.map((bookingStepPrev) =>
          bookingStepPrev.id === currentBookingStepId
            ? { ...bookingStepPrev, checked: e.target.checked }
            : bookingStepPrev
        )
      );
    };
  };

  const handleChangePaymentMethodUnDraggable = (id, key, value) => {
    setIsSaveBar(true);
    setPaymentMethodsUnDraggable((prevState) =>
      prevState.map((bookingStepPrev) =>
        bookingStepPrev.id === id
          ? { ...bookingStepPrev, [key]: value }
          : bookingStepPrev
      )
    );
  };

  useLayoutEffect(() => {
    setPaymentMethodsUnDraggable(
      currentSettings?.PaymentSettings?.PMethods?.length
        ? currentSettings?.PaymentSettings?.PMethods
        : [
            {
              id: 1,
              label: 'Stripe',
              checked: true,
              pubKey: '',
              secretKey: '',
            },
            {
              id: 2,
              label: 'Paypal',
              checked: true,
              mode: '',
              clientId: '',
              clientSecret: '',
            },
            { id: 3, label: 'Local payment', checked: true },
          ]
    );
  }, []);

  // click handlers
  const handleSave = () => {
    editSettings({
      ...currentSettings,

      PaymentSettings: {
        ...currentSettings.PaymentSettings,
        PMethods: paymentMethodsUnDraggable,
      },
    }).then((res) => {
      if (res.status === 200) {
        fetchCurrentSettings();
        setIsSaveBar(false);
      }
    });
  };

  const handleCancel = () => {
    setPaymentMethodsUnDraggable(
      currentSettings.paymentMethods.PaymentSettings.PMethods
    );
    setIsSaveBar(false);
  };

  return (
    <>
      <Grid xs={12} style={{ backgroundColor: 'white', marginTop: '20px' }}>
        <Grid
          sx={{
            padding: { xs: '15px', md: '15px 25px' },
            borderBottom: '1px solid #e3eaf3',
            display: 'flex',
            justifyContent: 'start',
          }}
        >
          {/* <Typography sx={{ fontSize: "18px", fontWeight: 500 }}>
            Payment{" "}
            <span style={{ color: "#c1c1c1" }}>
              <CircleIcon sx={{ fontSize: "5px", marginX: "3px" }} /> Payment
              Methods
            </span>
          </Typography> */}
          <CustomSettingsTitle
            title="General Settings"
            subTitle="Payment Methods"
          />
        </Grid>
        <Grid
          container
          rowGap="30px"
          sx={{ padding: { xs: '15px', md: '15px 25px' } }}
        >
          <Grid
            container
            rowGap="10px"
            xs={12}
            lg={5}
            sx={{ paddingRight: { xs: '0px', lg: '30px' } }}
          >
            {/* <ReactSortable
              list={paymentMethodsDraggable}
              setList={setPaymentMethodsDraggable}
              animation={200}
              delayOnTouchStart={true}
              delay={2}
              handle=".dragable-icon"
              style={{
                display: "flex",
                rowGap: "10px",
                flexDirection: "column",
                width: "100%",
              }}
            >
              {paymentMethodsDraggable.map((paymentMethod, index) => (
                <Grid
                  key={index}
                  onClick={() => setPaymentMethodId(paymentMethod.id)}
                  xs={12}
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    width: "100%",
                    border: "1px solid #e3eaf3",
                    padding: "11px 15px",
                    borderRadius: "2px",
                    cursor: "pointer",
                    maxHeight: "48px",
                    background:
                      paymentMethodId === paymentMethod.id
                        ? "rgba(108,112,220,.1)"
                        : "none",
                  }}
                >
                  <Grid container alignItems="center" xs={10}>
                    <MenuIcon
                      className="dragable-icon"
                      sx={{
                        cursor: "move",
                        color:
                          paymentMethodId === paymentMethod.id
                            ? "#6C70DC"
                            : "#ADBFC7",
                        opacity: 1,
                      }}
                    />
                    <Typography sx={{ marginLeft: "10px", fontSize: "14px" }}>
                      {paymentMethod.label}
                    </Typography>
                  </Grid>
                  {paymentMethod.hasOwnProperty("checked") && (
                    <IOSSwitch
                      checked={paymentMethod.checked}
                      onChange={handleCheckPaymentMethodDraggable(
                        paymentMethod.id
                      )}
                    />
                  )}
                </Grid>
              ))}
            </ReactSortable> */}

            <Grid
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'start',
                alignItems: 'start',
                rowGap: '10px',
                width: '100%',
              }}
            >
              {paymentMethodsUnDraggable.map((paymentMethod, index) => (
                <Grid
                  key={index}
                  onClick={() => setPaymentMethodId(paymentMethod.id)}
                  xs={12}
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{
                    width: '100%',
                    border: '1px solid #e3eaf3',
                    padding: '11px 15px',
                    borderRadius: '2px',
                    cursor: 'pointer',
                    maxHeight: '48px',
                    background:
                      paymentMethodId === paymentMethod.id
                        ? 'rgba(108,112,220,.1)'
                        : 'none',
                  }}
                >
                  <Grid container alignItems="center" xs={10}>
                    <MenuIcon
                      sx={{
                        cursor: 'not-allowed',
                        color:
                          paymentMethodId === paymentMethod.id
                            ? '#6C70DC'
                            : '#ADBFC7',
                        opacity: 0.4,
                      }}
                    />
                    <Typography sx={{ marginLeft: '10px', fontSize: '14px' }}>
                      {paymentMethod.label}
                    </Typography>
                  </Grid>
                  {paymentMethod.hasOwnProperty('checked') && (
                    <IOSSwitch
                      checked={paymentMethod.checked}
                      onChange={handleCheckPaymentMethodUnDraggable(
                        paymentMethod.id
                      )}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid
            container
            xs={12}
            lg={7}
            sx={{
              padding: { xs: '20px 25px', md: '20px 50px' },
              background:
                'linear-gradient(to right,#c0d6df 50%,transparent 0%),linear-gradient(#c0d6df 50%,transparent 0%),linear-gradient(to right,#c0d6df 50%,transparent 0%),linear-gradient(#c0d6df 50%,transparent 0%)',
              backgroundPosition: 'top,right,bottom,left',
              backgroundRepeat: 'repeat-x,repeat-y',
              backgroundSize: '8px 1px,1px 8px',
            }}
          >
            <PaymentMethodsDisplay
              page={selectedBookingStep}
              handleChangePaymentMethodUnDraggable={
                handleChangePaymentMethodUnDraggable
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <SaveSlideBar
        handleCancel={handleCancel}
        handleSave={handleSave}
        isSaveBar={isSaveBar}
        setIsSaveBar={setIsSaveBar}
      />
    </>
  );
};

export default PaymentMethods;
