import { Grid, Typography, useMediaQuery } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { useState } from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ScheduleIcon from '@mui/icons-material/Schedule';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import classes from './settingsSidebar.module.css';
import TabSettings from './TabSettings';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CategoryIcon from '@mui/icons-material/Category';
import MonitorIcon from '@mui/icons-material/Monitor';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { disableSectionFunc } from '../../utils/disableSectionFunc';
import DisableSectionWrapper from '../DisableSectionWrapper';

const SettingsSidebar = ({
  activeTab,
  handleActiveTab,
  openMenu,
  handleOpenMenu,
  mediaQuery1250,
  setOpenMenu,
}) => {
  const mediaQuery1020 = useMediaQuery('(min-width:1020px)');

  return (
    <>
      {!openMenu && (
        <Grid
          onClick={handleOpenMenu}
          sx={{
            position: 'fixed',
            top: '105px',
            display: { xs: 'flex', md: 'none' },
            height: '46px',
            width: '46px',
            backgroundColor: '#1976D2',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '0px 2px 2px 0px',
            zIndex: 99,
          }}
        >
          <KeyboardArrowLeftIcon style={{ fontSize: '26px', color: 'white' }} />
        </Grid>
      )}
      {openMenu && (
        <Grid
          onClick={handleOpenMenu}
          sx={{
            position: 'fixed',
            top: '105px',
            right: '0px',
            display: { xs: 'flex', md: 'none' },
            height: '46px',
            width: '46px',
            backgroundColor: '#1976D2',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '0px 2px 2px 0px',
            zIndex: 99,
          }}
        >
          <KeyboardArrowLeftIcon style={{ fontSize: '26px', color: 'white' }} />
        </Grid>
      )}
      <Grid sm={12} md={mediaQuery1250 ? 2.5 : 3.5}></Grid>

      <Grid
        sm={12}
        md={mediaQuery1250 ? 2.1 : 2.6}
        sx={{
          position: 'fixed',
          display: openMenu ? 'block' : { xs: 'none', md: 'block' },
          boxShadow: '0 0 30px 0 rgb(0 0 0 / 5%)',
          backgroundColor: 'white',
          height: '90vh',
          padding: mediaQuery1020 ? '40px 0' : '20px 0',
          overflow: 'auto',
          zIndex: '2',
          overflow: 'scroll',
          ' ::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <Typography
          sx={{
            display: { xs: 'none', sm: 'block' },
            // marginTop: 2.5,
            marginBottom: 1,
            fontSize: 24,
            color: '#3A3A50',
            pl: '18px',
          }}
        >
          Settings
        </Typography>
        <Grid
          className={classes.settingsSidebarContainer}
          sm={12}
          sx={{
            marginTop: '20px',
          }}
        >
          <TabSettings
            tabLabel="General settings"
            tabName="general-settings"
            icon={<SettingsIcon sx={{ color: '#303439' }} />}
            handleActiveTab={handleActiveTab}
            activeTab={activeTab}
            setOpenMenu={setOpenMenu}
            subTabs={[
              {
                subTabLabel: 'General',
                subTabName: 'general-settings',
              },
              disableSectionFunc({
                subTabLabel: 'Waiting List',
                subTabName: 'waiting-list',
              }),
            ]}
          />
          <TabSettings
            tabLabel="Account settings"
            tabName="account-settings"
            icon={<ManageAccountsIcon sx={{ color: '#303439' }} />}
            handleActiveTab={handleActiveTab}
            activeTab={activeTab}
            setOpenMenu={setOpenMenu}
            subTabs={[
              {
                subTabLabel: 'Profile Settings',
                subTabName: 'profile-settings',
              },
              {
                subTabLabel: 'Change Password',
                subTabName: 'change-password',
              },
              {
                subTabLabel: 'Notifications',
                subTabName: 'notifications',
              },
            ]}
          />
          <DisableSectionWrapper disableFromAll>
            <TabSettings
              tabLabel="Front-end Panels"
              tabName="front-end-panels"
              icon={<CalendarMonthIcon sx={{ color: '#303439' }} />}
              iconColor="#FC427B"
              description="You can customize booking and customer panel and change labels from here"
              handleActiveTab={handleActiveTab}
              activeTab={activeTab}
              setOpenMenu={setOpenMenu}
              subTabs={[
                {
                  subTabLabel: 'Booking widget steps',
                  subTabName: 'booking-steps',
                },
                {
                  subTabLabel: 'Widget Translation',
                  subTabName: 'booking-widget-translation',
                },
                {
                  subTabLabel: 'Customer dashboard',
                  subTabName: 'customer-panel',
                },
                {
                  subTabLabel: 'Rating form',
                  subTabName: 'rating-form',
                },
              ]}
            />
          </DisableSectionWrapper>
          <TabSettings
            tabLabel="Process"
            tabName="process"
            icon={<CategoryIcon sx={{ color: '#303439' }} />}
            handleActiveTab={handleActiveTab}
            activeTab={activeTab}
            setOpenMenu={setOpenMenu}
            subTabs={[
              {
                subTabLabel: 'Category',
                subTabName: 'category',
              },
              {
                subTabLabel: 'Subcategory',
                subTabName: 'sub-category',
              },
              // not sure if we will need this feature or it's completely deprecated
              // {
              //   subTabLabel: 'Appointment status',
              //   subTabName: 'appointment-statuses',
              // },
            ]}
          />

          <TabSettings
            tabLabel="Company"
            tabName="company"
            icon={<ApartmentIcon sx={{ color: '#303439' }} />}
            handleActiveTab={handleActiveTab}
            activeTab={activeTab}
            setOpenMenu={setOpenMenu}
            subTabs={[
              {
                subTabLabel: 'Company details',
                subTabName: 'company-details',
              },
              {
                subTabLabel: 'Business hours',
                subTabName: 'business-hours',
              },
              {
                subTabLabel: 'Holidays',
                subTabName: 'holidays',
              },
            ]}
          />
          <DisableSectionWrapper disableFromAll>
            <TabSettings
              tabLabel="Payment settings"
              tabName="payment-settings"
              icon={<AccountBalanceWalletIcon sx={{ color: '#303439' }} />}
              iconColor="#26C0D6"
              description="Currency, price format , general settings about payment , paypal, stripe and so on"
              handleActiveTab={handleActiveTab}
              activeTab={activeTab}
              setOpenMenu={setOpenMenu}
              subTabs={[
                {
                  subTabLabel: 'General',
                  subTabName: 'general',
                },
                {
                  subTabLabel: 'Payment Methods',
                  subTabName: 'payment-methods',
                },
              ]}
            />
          </DisableSectionWrapper>
          {/* <TabSettings
            tabLabel="Company details"
            tabName="company-details"
            icon={
              <LightbulbIcon
                sx={{ color: 'white', transform: 'rotate(45deg)' }}
              />
            }
            setOpenMenu={setOpenMenu}
            handleActiveTab={handleActiveTab}
            activeTab={activeTab}
          />

          <TabSettings
            tabLabel="Business Hours"
            tabName="business-hours"
            icon={<ScheduleIcon sx={{ color: 'white' }} />}
            iconColor="#7EB6A1"
            description="You will be able to co-ordinate your company's overall work schedule"
            handleActiveTab={handleActiveTab}
            activeTab={activeTab}
            setOpenMenu={setOpenMenu}
          />

          <TabSettings
            tabLabel="Holidays"
            tabName="holidays"
            icon={<EventAvailableIcon sx={{ color: 'white' }} />}
            iconColor="#FF7676"
            description="You can customize Holidays about booking from here"
            handleActiveTab={handleActiveTab}
            activeTab={activeTab}
            setOpenMenu={setOpenMenu}
          /> */}
          <DisableSectionWrapper disableFromAll>
            <TabSettings
              tabLabel="Email Settings"
              tabName="email-settings"
              icon={<EmailOutlinedIcon sx={{ color: '#303439' }} />}
              iconColor="#95AFC0"
              description="You must set this settings for email workflow action ( wp_mail or SMTP settings )"
              handleActiveTab={handleActiveTab}
              activeTab={activeTab}
              setOpenMenu={setOpenMenu}
            />
          </DisableSectionWrapper>

          <DisableSectionWrapper disableFromAll>
            <TabSettings
              tabLabel="Integrations Settings"
              tabName="integrations-settings"
              icon={
                <QrCode2Icon
                  sx={{ color: '#303439', transform: 'rotate(180deg)' }}
                />
              }
              iconColor="#425A64"
              description="You can change settings for integrated services from here."
              handleActiveTab={handleActiveTab}
              activeTab={activeTab}
              subTabs={[
                {
                  subTabLabel: 'SMS Twilio',
                  subTabName: 'sms-twilio',
                },
                {
                  subTabLabel: 'Google Calendar',
                  subTabName: 'google-calendar',
                },
              ]}
              setOpenMenu={setOpenMenu}
            />
          </DisableSectionWrapper>
        </Grid>
      </Grid>
    </>
  );
};

export default SettingsSidebar;
