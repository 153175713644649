import * as d3 from "d3";
import { useEffect, useRef } from "react";

const DonutChart = ({data}) => {
  const svgRef = useRef(null);

  useEffect(() => {

    const width = 380,
    height = 380,
    margin = 40;

// The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
const radius = Math.min(width, height) / 2 - margin

// append the svg object to the div
const svg = d3.select(svgRef.current)
    .attr("width", width)
    .attr("height", height)
    .attr("viewBox", `0 0 ${height} ${width}`)
  .append("g")
    .attr("transform", `translate(${width / 2},${height / 2})`);

// set the color scale
const color = d3.scaleOrdinal()
  .range(data.map(elm => elm.color))

// Compute the position of each group on the pie:
const pie = d3.pie()
  .value(d=>d[1])
const data_ready = pie(data.map(elm => [elm.id, elm.value]))

// Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
svg
  .selectAll('whatever')
  .data(data_ready)
  .join('path')
  .attr('d', d3.arc()
    .innerRadius(100)         // This is the size of the donut hole
    .outerRadius(radius)
  )
  .attr('fill', d => color(d.data[0]))
  .attr("stroke", "white")
  .style("stroke-width", "2px")
  .style("opacity", 0.7)
  }, []);

  return <svg ref={svgRef} />;
};

export default DonutChart;
