import { UpnizeBackend } from '../Axios';

export const getAppointmentList = async (rows, page, queries) => {
  let response = await UpnizeBackend.get(
    `/appointments/?rows=${rows}&page=${page}${queries ? queries : ''}`
  );
  return response;
};

export const getAppointmentById = async (id) => {
  let response = await UpnizeBackend.get(`/appointments/byId/${id}`);
  return response;
};

export const addAppointment = async (body) => {
  let response = await UpnizeBackend.post(`/appointments/add`, body);
  return response;
};

export const addEasyAppointment = async (body) => {
  let response = await UpnizeBackend.post(`/appointments/add`, body);
  return response;
};

export const editAppointmentById = async (body, id) => {
  let response = await UpnizeBackend.put(`/appointments/edit/${id}`, body);
  return response;
};

export const updateAppointmentStatusAPI = async (id, body) => {
  let response = await UpnizeBackend.put(
    `/appointments/update-status/${id}`,
    body
  );
  return response;
};

export const deleteAppointmentById = async (id) => {
  let response = await UpnizeBackend.delete(`/appointments/delete`, {
    data: { ids: id },
  });
  return response;
};
