import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {
  DataGrid,
  GridActionsCellItem,
  GridMoreVertIcon,
  gridStringOrNumberComparator,
} from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';
import CustomTableFooter from '../../components/CustomTableFooter';
import Modal from '../../components/Modal';
import UsageHistory from '../../components/UsageHistory';
import { useMemo } from 'react';
import { debounce } from 'lodash';
import { deleteReviewById, getReviewList } from '../../Api/tenant/reviews';
import { Link } from 'react-router-dom';
import AvatarComp from '../../components/Avatar';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import useFilterBar from '../../hooks/useFilterBar';
import { getQueries } from '../../utils/GetQueries';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import CustomTextButton from '../../components/CustomButtons/CustomTextButton';
import NoData from '../../components/NoData';

const row = [
  {
    id: 1,
    comment: 'Review 1',
    stars: 3,
    customer: 'Alexander',
    staff: 'first last',
    location: 'some location',
  },
  {
    id: 2,
    comment: 'Review 2',
    stars: 5,
    customer: 'Alexander',
    staff: 'frist last',
    location: 'some location',
  },
];

const filters = [
  {
    id: 1,
    title: 'Quick search',
    type: 'search',
    queryLabel: 'keyword',
  },
];

const Review = () => {
  const [addReviewDrawer, setAddReviewDrawer] = useState(false);
  const [drawerData, setDrawerData] = useState(null);
  const [openModal, setOpenModal] = useState({ open: false, type: null });
  const [selectionModel, setSelectionModel] = useState([]);
  const [drawer, setDrawer] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [rows, setRows] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('lg'));
  const { FilterBar, state, FilterButton } = useFilterBar({
    filters,
  });

  const toggleAddReviewDrawer = () => {
    setAddReviewDrawer(!addReviewDrawer);
  };

  const handleOpenModal = () => {
    setOpenModal({ open: false, type: null });
  };

  const handleDeleteOpenModal = () => {
    setOpenModal({ open: true, type: 'delete-modal' });
  };

  const toggleUsageHistoryModal = (e) => {
    e.stopPropagation();
    setDrawer((prevState) =>
      prevState !== 'usage-history' ? 'usage-history' : ''
    );
  };

  // API
  const fetchReviews = () => {
    setTableLoading(true);
    getReviewList(100, 1).then((res) => {
      setReviews(res.data);
    });
  };

  // fetch on render
  useEffect(() => {
    fetchReviews();
  }, []);

  // fetch on search
  useEffect(() => {
    getQueries(state).then((queries) => {
      setTableLoading(true);
      getReviewList(100, 1, queries).then((res) => {
        setReviews(res.data);
      });
    });
  }, [state]);

  // update table rows on fetch
  useEffect(() => {
    if (reviews) {
      setRows(reviews);
      setTableLoading(false);
    }
  }, [reviews]);

  const deleteReview = (id) => {
    deleteReviewById(id).then((res) => {
      fetchReviews();
    });
  };

  //  Table Columns
  const columns = [
    {
      field: 'title',
      headerName: 'COMMENT',
      minWidth: 250,
      flex: 1,
    },
    {
      field: 'stars',
      headerName: 'STARS',
      minWidth: 180,
      renderCell: (params) => {
        return (
          <>
            {[...Array(params.row.rating)].map((numb) => (
              <StarRateRoundedIcon key={numb} style={{ color: '#ffbb44' }} />
            ))}
          </>
        );
      },
    },
    {
      field: 'customer',
      headerName: 'CUSTOMER',
      minWidth: 200,
      flex: 1,
      valueGetter: (params) => {
        return (
          params.row.customer?.User?.firstName +
            params.row.customer?.User?.lastName || '-'
        );
      },
      sortComparator: (v1, v2) =>
        gridStringOrNumberComparator(v1.name, v2.name),
      renderCell: (params) => {
        return (
          <Box
            sx={{
              width: '100%',
            }}
          >
            {params.row.customer?.User ? (
              <Box display="flex">
                <AvatarComp
                  img={params.row.customer.User.image}
                  username={params.row.customer.User.firstName}
                  sx={{ mr: 1 }}
                />
                <Box>
                  <Typography fontSize="14px" color="#292d32">
                    <Link
                      to={`/customer/${params.row.customer.userId}`}
                      style={{
                        color: '#292d32',
                        textDecoration: 'none',
                      }}
                    >
                      {params.row.customer.User.firstName}
                    </Link>
                  </Typography>
                  <Typography color="#95a0a9" fontSize="12px">
                    {params.row.customer.User.email}
                  </Typography>
                </Box>
              </Box>
            ) : (
              '-'
            )}
          </Box>
        );
      },
    },
    {
      field: 'staff',
      headerName: 'STAFF',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'service',
      headerName: 'SERVICE',
      minWidth: 160,
      flex: 1,

      renderCell: (params) => (
        <Grid
          container
          alignItems="center"
          sx={{ width: '100%', height: '100%' }}
          onClick={(e) => {
            setDrawerData(params.row);
            toggleUsageHistoryModal(e);
          }}
        >
          <ReceiptOutlinedIcon sx={{ color: '#adbfc7', cursor: 'pointer' }} />
        </Grid>
      ),
    },
    {
      field: 'location',
      headerName: 'LOCATION',
      minWidth: 200,
    },
    {
      field: 'actions',
      headerName: 'ACTIONS',
      type: 'actions',
      align: 'right',
      minWidth: 100,
      maxWidth: 100,
      getActions: (params) => [
        <GridActionsCellItem
          label="Delete"
          onClick={() => {
            setOpenModal({ open: true, type: 'delete-modal' });
          }}
          showInMenu
        />,
      ],
    },
  ];

  return (
    <Grid
      item
      container
      sx={{
        minWidth: { xs: '100%' },
        px: '20px',
      }}
    >
      {openModal.type === 'delete-modal' && (
        <Modal
          handleOpenModal={handleOpenModal}
          type={'DELETE'}
          open={openModal.open}
        />
      )}
      {drawer === 'usage-history' && (
        <UsageHistory
          toggleDrawer={toggleUsageHistoryModal}
          data={drawerData}
        />
      )}
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          // alignItems: 'flex-end',
          flexWrap: 'wrap',
        }}
      >
        <Typography
          sx={{
            marginTop: 2.5,
            marginBottom: 1,
            fontSize: 24,
            color: '#3A3A50',
          }}
        >
          Review
        </Typography>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div></div>
          <Box sx={{ display: 'flex', gap: '10px', ml: '10px' }}>
            {FilterButton}
          </Box>
        </Box>
      </Grid>
      {/* --------------------SEARCH------------------------------- */}
      {FilterBar}
      {/* --------------------TABLE--------------------------------- */}
      <Grid
        container
        item
        justifyContent="space-between"
        sx={{ marginTop: '20px' }}
      >
        <div style={{ width: '100%' }}>
          <DataGrid
            sx={{
              border: 0,
              '& .MuiDataGrid-virtualScroller': {
                minHeight: '300px !important',
              },
              '& .MuiDataGrid-columnHeader': {
                backgroundColor: '#EFF1FC',
                color: '#3A3A50',
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                fontSize: '12px',
                fontWeight: '900 !important',
              },
              '& .MuiDataGrid-main, .MuiDataGrid-footerContainer': {
                backgroundColor: 'white',
                border: 'none',
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              minHeight: '60vh',
            }}
            rows={rows}
            columns={columns}
            // checkboxSelection
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            autoHeight
            selectionModel={selectionModel}
            components={{
              NoRowsOverlay: NoData,
              NoResultsOverlay: NoData,
              Footer: () => (
                <CustomTableFooter
                  selectionModel={selectionModel}
                  handleDeleteOpenModal={handleDeleteOpenModal}
                />
              ),
              MoreActionsIcon: () => (
                <GridMoreVertIcon
                  sx={{
                    backgroundColor: 'white',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
                    borderRadius: '4px',
                    padding: '3px',
                    cursor: 'pointer',
                  }}
                />
              ),
              NoRowsOverlay: () => {
                return (
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'Center',
                      gap: '20px',
                    }}
                  >
                    <img
                      src="https://user-images.githubusercontent.com/109676648/254145031-57027cd6-fecd-4ce2-96fc-9d891c8de53a.svg"
                      alt="no data"
                      style={{
                        width: '100px',
                        height: '100px',
                      }}
                    />
                    No data at the moment 😔
                  </Box>
                );
              },
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default Review;
