import React, { useState } from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  MenuItem,
  Select,
  Switch,
  TextareaAutosize,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import BedtimeIcon from "@mui/icons-material/Bedtime";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import styled from "@emotion/styled";
import DoneIcon from "@mui/icons-material/Done";
import PersonIcon from "@mui/icons-material/Person";
import AddCustomer from "../AddCustomer";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";

const AppointmentDetails = ({ serviceData, setServiceData }) => {
  const [isOpenDatePiker, setIsOpenDatePiker] = useState(false);
  const [addCustomerDrawer, setAddCustomerDrawer] = useState(false);
  const [CustomerdrawerType, setCustomerDrawerType] = useState();
  const toggleAddCustomerDrawer = () => {
    setAddCustomerDrawer(!addCustomerDrawer);
  };

  const timeSlot = [
    {
      duration: "1m",
    },
    {
      duration: "2m",
    },
    {
      duration: "3m",
    },
    {
      duration: "4m",
    },
    {
      duration: "5m",
    },
    {
      duration: "10m",
    },
    {
      duration: "12m",
    },
    {
      duration: "15m",
    },
    {
      duration: "20m",
    },
    {
      duration: "25m",
    },
    {
      duration: "30m",
    },
    {
      duration: "40m",
    },
    {
      duration: "50m",
    },
    {
      duration: "1h",
    },
  ];
  const duration = [
    {
      duration: "0",
    },
    {
      duration: "15m",
    },
    {
      duration: "30m",
    },
    {
      duration: "45m",
    },
    {
      duration: "1h",
    },
    {
      duration: "1h 15m",
    },
    {
      duration: "1h 30m",
    },
    {
      duration: "1h 45m",
    },
    {
      duration: "2h",
    },
    {
      duration: "2h 15m",
    },
  ];
  const [value, setValue] = React.useState();

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const statusData = [
    {
      id: 1,
      value: "Pending",
      icon: <AccessTimeIcon sx={{ color: "#fd9b78" }} />,
    },
    {
      id: 2,
      value: "Approved",
      icon: <DoneIcon sx={{ color: "#53d56c" }} />,
    },
    {
      id: 3,
      value: "Canceled",
      icon: <CloseIcon sx={{ color: "#fb3e6e" }} />,
    },
    {
      id: 4,
      value: "Rejected",
      icon: <CloseIcon sx={{ color: "#8f9ca7" }} />,
    },
    {
      id: 5,
      value: "Completed",
      icon: <EventAvailableIcon sx={{ color: "#8e61f0" }} />,
    },
    {
      id: 6,
      value: "No show",
      icon: <BedtimeIcon sx={{ color: "#f0912c" }} />,
    },
    {
      id: 7,
      value: "Waiting for payment",
      icon: <AccessTimeIcon sx={{ color: "#f0912c" }} />,
    },
  ];
  const locationOpt = [
    { option: "California" },
    { option: "Los Angeles" },
    { option: "New Yourk" },
  ];
  const categoryData = [
    { option: "Dental Clinic" },
    { option: "Gynecology" },
    { option: "Cosmetic Surgery" },
  ];
  const serviceOpt = [
    { option: "Oral hygiene" },
    { option: "Tooth whitening" },
    { option: "Implants" },
    { option: "3D/4D Ultrasound" },
    { option: "OB/GYN" },
    { option: "Maternity" },
    { option: "Buttock Lift" },
    { option: "Liposuction" },
    { option: "Fat Transfer" },
  ];
  const staffData = [
    { option: "Roland Aigbobo" },
    { option: "Mark S. Borchert, MD" },
    { option: "Joseph Church, MD" },
    { option: "Thomas Coates, MD" },
    { option: "Rima Jubran, MD" },
    { option: "David Freyer, DO" },
    { option: "Dennis R. Maceri, MD" },
  ];
  const Times = [
    { label: "12.00 AM" },
    { label: "12.30 AM" },
    { label: "01.00 AM" },
    { label: "01.30 AM" },
    { label: "02.00 AM" },
    { label: "02.30 AM" },
    { label: "03.00 AM" },
    { label: "03.30 AM" },
    { label: "04.00 AM" },
    { label: "04.30 AM" },
    { label: "05.00 AM" },
    { label: "05.30 AM" },
    { label: "06.00 AM" },
    { label: "06.30 AM" },
    { label: "07.00 AM" },
    { label: "07.30 AM" },
    { label: "08.00 AM" },
    { label: "08.30 AM" },
    { label: "09.00 AM" },
    { label: "09.30 AM" },
    { label: "10.00 AM" },
    { label: "10.30 AM" },
    { label: "11.00 AM" },
    { label: "11.30 AM" },
    { label: "12.00 PM" },
    { label: "12.30 PM" },
    { label: "01.00 PM" },
    { label: "01.30 PM" },
    { label: "02.00 PM" },
    { label: "02.30 PM" },
    { label: "03.00 PM" },
    { label: "03.30 PM" },
    { label: "04.00 PM" },
    { label: "04.30 PM" },
    { label: "05.00 PM" },
    { label: "05.30 PM" },
    { label: "06.00 PM" },
    { label: "06.30 PM" },
    { label: "07.00 PM" },
    { label: "07.30 PM" },
    { label: "08.00 PM" },
    { label: "08.30 PM" },
    { label: "09.00 PM" },
    { label: "09.30 PM" },
    { label: "10.00 PM" },
    { label: "10.30 PM" },
    { label: "11.00 PM" },
    { label: "11.30 PM" },
  ];
  const [appointMentData, setAppointMentData] = useState({
    location: "",
    category: "",
    service: "",
    staff: "",
    time: "",
    customer: "",
    status: "",
  });

  const handleSelectData = ({ target }, fieldName) => {
    const { value } = target;
    const selectData = {...appointMentData}
    selectData[fieldName] = value
    setAppointMentData(selectData);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container>
        {addCustomerDrawer && (
          <AddCustomer
            toggleAddCustomerDrawer={toggleAddCustomerDrawer}
            CustomerdrawerType={CustomerdrawerType}
          />
        )}
        <Grid container>
          <Grid xs={12} sm={12} sx={{ paddingRight: { sm: 0 }, marginTop: 1 }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">
                Location <span style={{ color: "red" }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                onSelect={(event) => handleSelectData(event, "location")}
                size="small"
                disablePortal
                id="combo-box-demo"
                options={locationOpt.map((location) => location.option)}
                sx={{
                  marginBottom: "1rem",
                  width: "100%",
                  fontSize: "14px",
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select..." />
                )}
              />
            </Grid>
          </Grid>
          <Grid xs={12} sm={12} sx={{ marginTop: 1 }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">
                Category <span style={{ color: "red" }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                size="small"
                disablePortal
                id="combo-box-demo"
                onSelect={(event) => handleSelectData(event, "category")}
                options={categoryData.map((option) => option.option)}
                sx={{
                  marginBottom: "1rem",
                  width: "100%",
                  fontSize: "14px",
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select..." />
                )}
              />
            </Grid>
          </Grid>
          <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 }, marginTop: 1 }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">
                Service <span style={{ color: "red" }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                size="small"
                disablePortal
                id="combo-box-demo"
                onSelect={(event) => handleSelectData(event, "service")}
                options={serviceOpt.map((service) => service.option)}
                sx={{
                  marginBottom: "1rem",
                  width: "100%",
                  fontSize: "14px",
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select..." />
                )}
              />
            </Grid>
          </Grid>
          <Grid xs={12} sm={6} sx={{ paddingLeft: { sm: 1 }, marginTop: 1 }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">
                Staff <span style={{ color: "red" }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                size="small"
                disablePortal
                id="combo-box-demo"
                onSelect={(event) => handleSelectData(event, "staff")}
                options={staffData.map((staff) => staff.option)}
                sx={{
                  marginBottom: "1rem",
                  width: "100%",
                  fontSize: "14px",
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select..." />
                )}
              />
            </Grid>
          </Grid>
{appointMentData.staff && <>  <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 }, marginTop: 1 }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">
                Date <span style={{ color: "red" }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                open={isOpenDatePiker}
                onOpen={() => setIsOpenDatePiker(!isOpenDatePiker)}
                onClose={() => setIsOpenDatePiker(!isOpenDatePiker)}
                toolbarPlaceholder="Basic example"
                value={value}
                onChange={(newValue) => {
                  handleChange(newValue);
                }}
                InputProps={{
                  style: {
                    flexDirection: "row-reverse",
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    onClick={() => setIsOpenDatePiker(!isOpenDatePiker)}
                    onTouchStart={() => setIsOpenDatePiker(!isOpenDatePiker)}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid xs={12} sm={6} sx={{ paddingLeft: { sm: 1 }, marginTop: 1 }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">
                Time <span style={{ color: "red" }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                size="small"
                disablePortal
                id="combo-box-demo"
                onSelect={(event) => handleSelectData(event, "time")}
                options={Times.map((time) => time.label)}
                sx={{
                  marginBottom: "1rem",
                  width: "100%",
                  fontSize: "14px",
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select..." />
                )}
              />
            </Grid>
          </Grid></>}
        

          <Grid xs={12} sx={{ paddingRight: { sm: 1 }, marginTop: 1 }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">
                Customer <span style={{ color: "red" }}>*</span>
              </Typography>
            </Grid>
            <Grid sx={{ width: "101%" }} container>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{ paddingRight: { sm: 1 }, display: "flex" }}
                container
              >
                {/* <Grid item xs={10.4}> */}
                <Autocomplete
                  size="small"
                  disablePortal
                  id="combo-box-demo"
                  onSelect={(event) => handleSelectData(event, "customer")}
                  options={staffData.map((staff) => staff.option)}
                  sx={{
                    marginBottom: "1rem",
                    fontSize: "14px",
                    flex: 1,
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select..." />
                  )}
                />
                <Icon
                  sx={{
                    width: "40px",
                    height: "39px",
                    border: "1px solid #ddd",
                    borderRadius: "5px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <AddIcon
                    onClick={() => {
                      setCustomerDrawerType("addCustomer");
                      toggleAddCustomerDrawer();
                    }}
                  />
                </Icon>
              </Grid>
              <Grid item xs={12} sm={6} container>
                <Grid item xs={7} sx={{ paddingLeft: { sm: 1, xs: 0 } }}>
                  <Select
                    fullWidth
                    size="small"
                    sx={{ display: "flex", alignItems: "center" }}
                    defaultValue={statusData[1].value}
                    onChange={(event) => handleSelectData(event, "status")}
                    MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
                  >
                    {statusData.map((status) => (
                      <MenuItem key={status.id} value={status.value}>
                        {status.value}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={4.8} sx={{ paddingLeft: { sm: 1 } }}>
                  <Box
                    sx={{
                      border: "1px solid #ddd",
                      padding: "5px",
                      color: "#6c757d",
                      display: "flex",
                      justifyContent: "space-between",
                      width: "97%",
                      height: "1.8rem",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <PersonIcon />
                      <Typography variant="subtitle2">1</Typography>
                    </div>
                    <ArrowDropDownIcon />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} sm={12} sx={{ paddingRight: { sm: 1 }, marginTop: 1 }}>
            <Grid item xs={12} sm={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">Note</Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextareaAutosize
                id="outlined-basic"
                size="small"
                required
                variant="outlined"
                style={{
                  marginBottom: "1rem",
                  width: "99%",
                  minHeight: "120px",
                  padding: "5px",
                  fontSize: "16px",
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export default AppointmentDetails;
