import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import Modal from '../../components/Modal';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CheckIcon from '@mui/icons-material/Check';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Chip,
  Fab,
  Icon,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import ShareModal from '../../components/ShareModal';
import AddAppointment from './AddAppointment';
import Info from './info';
import PaymentInfo from './PaymentInfo';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import TableData from './TableData';
import { useEffect } from 'react';
import AvatarComp from '../../components/Avatar';

const StatusChip = ({ value }) => {
  const bgStyles = {
    received: '#84FFDE ',
    approved: '#E9F7E8',
    cancelled: '#F9E0E7',
    pending: '#FBEEE9',
    completed: '#EAE5FB',
    rejected: '#EDEEF0',
  };
  const textStyles = {
    received: '#509F8A',
    approved: '#82D376',
    cancelled: '#DF3E70',
    pending: '#EA9B7F',
    completed: '#8467E9',
    rejected: '#929DA6',
  };
  const StatusLabel = value[0].toUpperCase() + value.substring(1);
  return (
    <Chip
      size="small"
      label={StatusLabel}
      style={{
        background: bgStyles[value],
        color: textStyles[value],
        width: '80px',
        textAlign: 'center',
        fontSize: '12px',
        height: '25px',
      }}
    />
  );
};

const Calendar = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const [categoryies, setCategoryies] = useState([
    { label: 'Dental Clinic' },
    { label: 'Gynecology' },
    { label: 'Body' },
    { label: 'Shin & Hear' },
  ]);
  const [addAppointment, setAddAppointmentDrawer] = useState(false);
  const [addAppointmentInfo, setAddAppointmentInfoDawer] = useState(false);
  const [paymentInfo, setPaymentInfoDawer] = useState(false);
  const [drawerType, setDrawerType] = useState('add');
  const [drawerData, setDrawerData] = useState(null);
  const [openModal, setOpenModal] = useState({ open: false, type: null });
  const [SortInfo, setSortInfo] = useState({
    staff: [],
    location: '',
    service: '',
  });
  const [staffAnsInf, setStaffAnsInf] = useState([]);
  const [staffsDataInfo, setStaffsDataInfo] = useState([]);
  const [calendarData, setCalendarData] = useState([]);

  const handelSort = (name, { target }) => {
    const value = target.value;
    const sortData = SortInfo;
    sortData[name] = value;
    setSortInfo(sortData);
  };

  const handelStaffSort = (value) => {
    const sortStaffData = SortInfo.staff.find((staff) => staff === value);

    const sortData = SortInfo;
    if (!sortStaffData) {
      sortData['staff'] = [...sortData.staff, value];
      setStaffAnsInf([...sortData.staff, value]);
    } else {
      const FilterStaff = SortInfo.staff.filter((staff) => staff !== value);
      sortData['staff'] = [...FilterStaff];
      setStaffAnsInf([...FilterStaff]);
    }
    setSortInfo(sortData);
  };

  // delete child
  const handleDeleteOpenModal = () => {
    setOpenModal({ open: true, type: 'delete-modal' });
  };

  const toggleAddAppointmentDrawer = () => {
    setAddAppointmentDrawer(!addAppointment);
  };
  const toggleAddAppointmentInfoDrawer = () => {
    setAddAppointmentInfoDawer(!addAppointmentInfo);
  };
  const togglePaymentInfoDrawer = () => {
    setPaymentInfoDawer(!paymentInfo);
  };

  const handleOpenModal = () => {
    setOpenModal({ open: false, type: null });
  };
  // delete child

  const handelEdit = (id) => {
    setDrawerType('edit');
    setDrawerData({
      id: id,
    });
    toggleAddAppointmentDrawer();
  };

  const handelInfo = (id) => {
    setDrawerType('edit');
    setDrawerData({
      id: id,
    });
    toggleAddAppointmentInfoDrawer();
  };

  const CustomButtonGroupAsArrows = ({ next, previous }) => {
    return (
      <div
        style={{
          textAlign: 'center',
        }}
      >
        <Box
          onClick={previous}
          sx={{
            cursor: 'pointer',
            borderRadius: '50%',
            background: '#b5b5b5',
            height: '35px',
            width: '35px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: '20px',
            marginLeft: '10px',
            position: 'absolute',
            left: 0,
            top: { sm: 20, xs: 20 },
          }}
        >
          <ArrowBackIosNewIcon sx={{ fontSize: '14px', color: 'white' }} />
        </Box>
        <Box
          onClick={next}
          sx={{
            position: 'absolute',
            right: 0,
            top: { sm: 20, xs: 23 },
            borderRadius: '50%',
            background: '#b5b5b5',
            height: '35px',
            width: '35px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: '20px',
            marginRight: '10px',
            cursor: 'pointer',
          }}
        >
          <ArrowForwardIosIcon sx={{ fontSize: '14px', color: 'white' }} />
        </Box>
      </div>
    );
  };

  const handelCheckedTableData = () => {
    const calanderInfo = [...calendarData];
    const calendarSort = data.filter((info) =>
      info.appointment.map((data) => staffAnsInf.includes(data.staff.name))
    );
    console.log(calendarSort);
  };

  useEffect(() => {
    handelCheckedTableData();
    setCalendarData(data);
    setStaffsDataInfo(staffData);
  }, [staffAnsInf]);

  return (
    <>
      <Grid
        item
        container
        sx={{
          minWidth: { xs: '100%' },
          // paddingLeft: 4,
          // paddingRight: 4,
          px: '20px',
          marginBottom: '4rem',
        }}
      >
        {openModal.type === 'delete-modal' && (
          <Modal
            handleOpenModal={handleOpenModal}
            type={'DELETE'}
            open={openModal.open}
          />
        )}

        {addAppointment && (
          <AddAppointment
            toggleAddAppointmentDrawer={toggleAddAppointmentDrawer}
            drawerType={drawerType}
            drawerData={drawerData}
            categoryies={categoryies}
            setCategoryies={setCategoryies}
            // handelHide={handelHide}
          />
        )}
        {paymentInfo && (
          <PaymentInfo
            togglePaymentInfoDrawer={togglePaymentInfoDrawer}
            drawerType={drawerType}
            drawerData={drawerData}
          />
        )}
        {addAppointmentInfo && (
          <Info
            toggleAddAppointmentInfoDrawer={toggleAddAppointmentInfoDrawer}
            drawerType={drawerType}
            drawerData={drawerData}
            handelEdit={handelEdit}
            handleDeleteOpenModal={handleDeleteOpenModal}
          />
        )}

        <Grid
          item
          xs={12}
          sm={12}
          sx={{ my: 6, alignItems: 'center', margin: '35px 0px' }}
          container
        >
          <Grid xs={12} sm={1.8} item>
            <Typography
              sx={{
                // marginTop: 2.5,
                marginBottom: 1,
                fontSize: 24,
                color: '#3A3A50',
              }}
            >
              Calendar
            </Typography>
          </Grid>
          <Grid
            xs={12}
            sm={6.2}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              px: 7,
            }}
            item
          >
            <Carousel
              ssr
              renderButtonGroupOutside={true}
              customButtonGroup={<CustomButtonGroupAsArrows />}
              arrows={false}
              deviceType={'desktop'}
              itemClass="image-item"
              responsive={responsive}
            >
              {staffsDataInfo.map((staff) => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginLeft: '0.5rem',
                      marginRight: '1.5rem',
                      cursor: 'pointer',
                      minWidth: '170px',
                      maxWidth: '170px',
                    }}
                    onClick={() => handelStaffSort(staff.name)}
                    key={staff.id}
                  >
                    {SortInfo.staff?.find((info) => info === staff.name) ? (
                      <div
                        style={{
                          minWidth: '40px',
                          minHeight: '40px',
                          borderRadius: '50%',
                          background: '#82ed86',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <CheckIcon sx={{ color: 'white', fontSize: '18px' }} />
                      </div>
                    ) : (
                      <AvatarComp
                        username={staff.name}
                        img={staff.image}
                        alt="text"
                        style={{
                          width: '40px',
                          height: '40px',
                          borderRadius: '50%',
                        }}
                      />
                    )}
                    <p
                      style={{
                        marginLeft: '.7rem',
                        marginRight: '.5rem',
                      }}
                    >
                      {staff.name}
                    </p>
                  </div>
                );
              })}
            </Carousel>
          </Grid>
          <Grid xs={12} sm={4} container item>
            <Grid xs={6}>
              <Autocomplete
                size="small"
                disablePortal
                id="combo-box-demo"
                options={LocationData.map((location) => location.label)}
                sx={{
                  background: 'white',
                  marginLeft: { sm: 2, xs: 0 },
                  '& fieldset': { border: 'none' },
                }}
                defaultValue="Location Filter"
                onSelect={(e) => handelSort('location', e)}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
            <Grid xs={6}>
              <Autocomplete
                size="small"
                disablePortal
                id="combo-box-demo"
                options={serviceOptions.map((service) => service.title)}
                sx={{
                  background: 'white',
                  marginLeft: { sm: 2, xs: 0 },
                  '& fieldset': { border: 'none' },
                }}
                defaultValue="Service Filter"
                onSelect={(e) => handelSort('service', e)}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid xs={6} sm={4} md={3.5} item>
            <Box
              sx={{
                background: 'white',
                padding: '6px 10px',
                display: 'inline-block',
                borderRadius: '3px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Icon
                  sx={{ mx: { sm: 1, xs: 0 }, p: '5px', cursor: 'pointer' }}
                >
                  <ArrowBackIosNewIcon sx={{ color: '#656565' }} />
                </Icon>
                <Button size="small" variant="contained">
                  Today
                </Button>
                <Icon
                  sx={{ mx: { sm: 1, xs: 0 }, p: '5px', cursor: 'pointer' }}
                >
                  <ArrowForwardIosIcon sx={{ color: '#656565' }} />
                </Icon>
              </Box>
            </Box>
          </Grid>
          <Grid
            xs={6}
            sm={6}
            md={5}
            sx={{
              my: { sm: 0, xs: 0 },
              display: 'flex',
              justifyContent: { xs: 'end', sm: 'center' },
              alignItems: 'center',
            }}
            item
          >
            {matches ? (
              <Typography variant="h5">02/10/2022 - 08/10/2022</Typography>
            ) : (
              <ButtonGroup
                sx={{
                  background: 'white',
                  padding: '6px 10px',
                  display: 'inline-block',
                  borderRadius: '3px',
                }}
                aria-label="outlined primary button group"
              >
                <Button variant="contained" sx={{ height: '2rem' }}>
                  List
                </Button>
              </ButtonGroup>
            )}
          </Grid>
          <Grid
            xs={12}
            sm={2}
            md={3.5}
            sx={{
              display: 'flex',
              justifyContent: { xs: 'center', sm: 'end' },
            }}
            item
          >
            {!matches ? (
              <Typography variant="h5" sx={{ my: 3 }}>
                02/10/2022 - 08/10/2022
              </Typography>
            ) : (
              <ButtonGroup
                sx={{
                  background: 'white',
                  padding: '6px 10px',
                  display: 'inline-block',
                  borderRadius: '3px',
                }}
                aria-label="outlined primary button group"
              >
                <Button
                  variant="contained"
                  sx={{ height: '2rem', alignItems: 'center' }}
                >
                  List
                </Button>
              </ButtonGroup>
            )}
          </Grid>
        </Grid>
        <Grid sx={{ width: '100%', background: 'white', mt: 3 }}>
          {matches ? (
            calendarData.map((data) => (
              <>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow
                        sx={{
                          backgroundColor: '#ADBFC7',
                        }}
                      >
                        <TableCell
                          align="left"
                          sx={{ fontWeight: 'bold', color: 'white' }}
                          colSpan={2}
                        >
                          {data.day}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ fontWeight: 'bold', color: 'white' }}
                          colSpan={4}
                        >
                          {data.date}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.appointment.map((appointmentData) => (
                        <TableRow
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                          key={appointmentData.id}
                          onClick={() => handelInfo(appointmentData.id)}
                        >
                          <TableCell>{appointmentData.time}</TableCell>

                          <TableCell
                            align="left"
                            sx={{ display: 'flex', flexDirection: 'inherit' }}
                          >
                            <AvatarComp
                              username={appointmentData.staff.name}
                              img={appointmentData.staff.image}
                              sx={{ width: 26, height: 26, marginRight: '5px' }}
                            />
                            <Typography
                              varinat="subtitle2"
                              fontSize="14px"
                              sx={{ marginTop: '3px' }}
                            >
                              {appointmentData.staff.name}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            {appointmentData.service}
                          </TableCell>
                          <TableCell align="left">
                            {' '}
                            {appointmentData.customer}
                          </TableCell>
                          <TableCell align="left">
                            <StatusChip
                              value={appointmentData.status}
                              handelInfo={handelInfo}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ))
          ) : (
            <TableData data={calendarData} handelInfo={handelInfo} />
          )}
        </Grid>
      </Grid>
      <Fab
        aria-label="add"
        sx={{
          position: 'fixed',
          bottom: '3.3rem',
          right: '8rem',
          background: '#6c70dc',
          color: 'white',
          '&:hover': { background: '#6b6fe2', color: 'white' },
        }}
        onClick={() => {
          setDrawerType('addAppointment');
          toggleAddAppointmentDrawer();
        }}
      >
        <AddIcon />
      </Fab>
    </>
  );
};

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1260 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 1260, min: 1000 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1000, min: 800 },
    items: 1.5,
  },
  mobile: {
    breakpoint: { max: 800, min: 600 },
    items: 1,
  },
};

const data = [
  {
    id: 1,
    day: 'TUE',
    date: '04/10/2022',
    appointment: [
      {
        id: 1,
        time: '8.00am - 9.00am',
        service: 'Maternity',
        staff: {
          image:
            'https://demo.booknetic.com/wp-content/uploads/booknetic/staff/c3a8c34011d9577203ca648f091de5f4.png',
          name: 'Roland Aigbobo, MD',
        },
        customer: 'Jennifer Landr',
        status: 'approved',
      },
      {
        id: 2,
        time: '10.00am - 11.00am',
        service: 'Maternity',
        staff: {
          image:
            'https://demo.booknetic.com/wp-content/uploads/booknetic/staff/6efb55c10b6af7538493011990cde849.jpg',
          name: 'Thomas Coates, MD',
        },
        customer: 'Jennifer Landr',
        status: 'received',
      },
    ],
  },
  {
    id: 660,
    day: 'WED',
    date: '05/10/2022',
    appointment: [
      {
        id: 155,
        time: '8.00am - 9.00am',
        service: 'Maternity',
        staff: {
          image:
            'https://demo.booknetic.com/wp-content/uploads/booknetic/staff/c3a8c34011d9577203ca648f091de5f4.png',
          name: 'Roland Aigbobo, MD',
        },
        customer: 'Jennifer Landr',
        status: 'approved',
      },
      {
        id: 222,
        time: '10.00am - 11.00am',
        service: 'Maternity',
        staff: {
          image:
            'https://demo.booknetic.com/wp-content/uploads/booknetic/staff/6efb55c10b6af7538493011990cde849.jpg',
          name: 'Thomas Coates, MD',
        },
        customer: 'Jennifer Landr',
        status: 'received',
      },
    ],
  },
  {
    id: 7640,
    day: 'THU',
    date: '06/10/2022',
    appointment: [
      {
        id: 155,
        time: '8.00am - 9.00am',
        service: 'Maternity',
        staff: {
          image:
            'https://demo.booknetic.com/wp-content/uploads/booknetic/staff/c3a8c34011d9577203ca648f091de5f4.png',
          name: 'Roland Aigbobo, MD',
        },
        customer: 'Jennifer Landr',
        status: 'approved',
      },
      {
        id: 2222,
        time: '10.00am - 11.00am',
        service: 'Maternity',
        staff: {
          image:
            'https://demo.booknetic.com/wp-content/uploads/booknetic/staff/6efb55c10b6af7538493011990cde849.jpg',
          name: 'Thomas Coates, MD',
        },
        customer: 'Jennifer Landr',
        status: 'received',
      },
    ],
  },
  {
    id: 76055,
    day: 'FRI',
    date: '07/10/2022',
    appointment: [
      {
        id: 51155,
        time: '8.00am - 9.00am',
        service: 'Maternity',
        staff: {
          image:
            'https://demo.booknetic.com/wp-content/uploads/booknetic/staff/c3a8c34011d9577203ca648f091de5f4.png',
          name: 'Roland Aigbobo, MD',
        },
        customer: 'Jennifer Landr',
        status: 'approved',
      },
      {
        id: 541,
        time: '10.00am - 11.00am',
        service: 'Maternity',
        staff: {
          image:
            'https://demo.booknetic.com/wp-content/uploads/booknetic/staff/6efb55c10b6af7538493011990cde849.jpg',
          name: 'Thomas Coates, MD',
        },
        customer: 'Jennifer Landr',
        status: 'received',
      },
    ],
  },
  {
    id: 760,
    day: 'SAT',
    date: '08/10/2022',
    appointment: [
      {
        id: 11545,
        time: '8.00am - 9.00am',
        service: 'Maternity',
        staff: {
          image:
            'https://demo.booknetic.com/wp-content/uploads/booknetic/staff/c3a8c34011d9577203ca648f091de5f4.png',
          name: 'Roland Aigbobo, MD',
        },
        customer: 'Jennifer Landr',
        status: 'approved',
      },
      {
        id: 2122422,
        time: '10.00am - 11.00am',
        service: 'Maternity',
        staff: {
          image:
            'https://demo.booknetic.com/wp-content/uploads/booknetic/staff/6efb55c10b6af7538493011990cde849.jpg',
          name: 'Thomas Coates, MD',
        },
        customer: 'Jennifer Landr',
        status: 'received',
      },
    ],
  },
  // {
  //   id: 2,
  //   day: "THU",
  //   date: "07/10/2022",
  //   appointment:
  //    [
  //     {
  //       id: 55,
  //       time: "8.00am - 9.00am",
  //       service: "Maternity",
  //       staff: {
  //         name: "Rima Jubran, MD",
  //         image:
  //           "https://demo.booknetic.com/wp-content/uploads/booknetic/staff/c9b60919a4e9a5b93a890a65e4777ad4.jpg",
  //       },
  //       customer: "Jennifer Landr",
  //       status: "approved",
  //     },
  //     {
  //       id: 45,
  //       time: "10.00am - 11.00am",
  //       service: "Maternity",
  //       staff: {
  //         name: "Rima Jubran, MD",
  //         image:
  //           "https://demo.booknetic.com/wp-content/uploads/booknetic/staff/c9b60919a4e9a5b93a890a65e4777ad4.jpg",
  //       },
  //       customer: "Jennifer Landr",
  //       status: "received",
  //     },
  //     {
  //       id: 51,
  //       time: "10.00am - 11.00am",
  //       service: "Maternity",
  //       staff: {
  //         image:
  //           "https://demo.booknetic.com/wp-content/uploads/booknetic/staff/c37f73df1c7942421a2b6a971b5d9645.jpg",
  //         name: "Dennis R. Maceri",
  //       },
  //       customer: "Jennifer Landr",
  //       status: "received",
  //     },
  //   ],
  // }
];
const staffData = [
  {
    id: 1,
    image:
      'https://demo.booknetic.com/wp-content/uploads/booknetic/staff/c3a8c34011d9577203ca648f091de5f4.png',
    name: 'Roland Aigbobo, MD',
  },
  {
    id: 2,
    image:
      'https://demo.booknetic.com/wp-content/uploads/booknetic/staff/2b3fc7e69a1b24479476b0b5844739d5.jpg',
    name: 'Mark S. Borchert, MD',
  },
  {
    id: 3,
    image:
      'https://demo.booknetic.com/wp-content/uploads/booknetic/staff/f3eeb00446b52926beea1450245a0aa0.jpg',
    name: 'Joseph Churchu, MD',
  },
  {
    id: 4,
    image:
      'https://demo.booknetic.com/wp-content/uploads/booknetic/staff/6efb55c10b6af7538493011990cde849.jpg',
    name: 'Thomas Coates, MD',
  },
  {
    id: 5,
    image:
      'https://demo.booknetic.com/wp-content/uploads/booknetic/staff/c9b60919a4e9a5b93a890a65e4777ad4.jpg',
    name: 'Rima Jubran , Staff MD',
  },
  {
    id: 6,
    image:
      'https://demo.booknetic.com/wp-content/uploads/booknetic/staff/14badcfa823b4a8c6bf0993e9f258026.jpg',
    name: 'David Freyer John, DO',
  },
  {
    id: 7,
    image:
      'https://demo.booknetic.com/wp-content/uploads/booknetic/staff/c37f73df1c7942421a2b6a971b5d9645.jpg',
    name: 'Dennis R. Maceri, MD',
  },
];
const LocationData = [
  { label: 'California' },
  { label: 'Los Angeles' },
  { label: 'New York' },
];
const serviceOptions = [
  { title: 'Oral hygiene' },
  { title: 'Tooth Whitening' },
  { title: 'Impalants' },
  { title: '3D/4D Ultrasound' },
  { title: 'OB/GYN' },
  { title: 'Maternity' },
  { title: 'Buttock Lift' },
  { title: 'Liposuction' },
  { title: 'Fat Tansfar' },
];

export default Calendar;
