import { Grid, Typography, Box } from '@mui/material';
import Footer from '../../components/footer';
import CustomtextField from '../../components/CustomTextField/index';
import CustomButton from '../../components/CustomButton';
import { React, useState } from 'react';
import CustomProgress from '../../components/ProgressBar';
import { login } from '../../Api/common/users';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUser } from '../../redux/slices/user.slice.js';
import UpnizeLogo from '../../assets/images/UpnizeLogo.png';
import { useSearchParams } from 'react-router-dom';
import { callSnackbar } from '../../redux/slices/snackbar.slice';
import { useEffect } from 'react';

function Login() {
  const dispatch = useDispatch();
  let regexEmail = /^[\w+]+([\.-]?[\w+]+)*@[\w+]+([\.-]?[\w+]+)*(\.\w{2,3})+$/;
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(true);
  const [validPass, setValidPass] = useState(true);
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const isConfirmRegister = searchParams.get('confirmRegister');
  const user = JSON.parse(localStorage.getItem('user'));

  // API

  useEffect(() => {
    if (user?.id) {
      navigate('/appointment');
    }
  }, [user]);

  const handleLogin = () => {
    if (password.length === 0) setValidPass(false);
    else setValidPass(true);

    if (email.match(regexEmail)) {
      setLoading(true);
      setValidEmail(true);

      if (!validEmail && !validPass) return;
      console.log(email, password);

      login({ email, password }).then(
        (res) => {
          if (res.status === 200 && res.data.id) {
            dispatch(setUser({ ...res.data }));
            if (res.data.isWelcome) {
              navigate('/welcome');
              return;
            }
            navigate('/');
            return;
          }
        },
        (rej) => {
          setLoading(false);
          console.log(rej);
          dispatch(callSnackbar({ message: rej.response.data, type: 'error' }));
          return;
        }
      );
    } else {
      setValidEmail(false);
    }
  };

  return (
    <>
      {!user?.id && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: '#F9F8FF',
            minHeight: '100vh',
            height: '100%',
            px: { md: '50px', xs: '10px' },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              maxWidth: '400px',
              width: '100%',
            }}
          >
            <Grid align="center" sx={{ my: 6 }}>
              <img src={UpnizeLogo} alt="Profile" width="200px" />
            </Grid>
            <Box
              sx={{
                width: '100%',
              }}
            >
              <Grid
                lg={12}
                item
                container
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    boxShadow: '1px 2px 9px #C0C0C0',
                    padding: '2em',
                    backgroundColor: 'white',
                    width: '100%',
                  }}
                >
                  <Typography
                    fontWeight="bold"
                    sx={{
                      fontSize: '2rem',
                      mb: 3,
                    }}
                  >
                    Log in
                  </Typography>
                  <CustomtextField
                    e2e="email"
                    customData={{ label: 'Email' }}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    error={!validEmail}
                    helperText={
                      !validEmail && email == ''
                        ? 'Please enter an email'
                        : !validEmail
                        ? 'Invalid Email Format'
                        : ' '
                    }
                    style={{ marginBottom: '2%' }}
                  />
                  <Grid sx={{ marginBottom: '16px' }}>
                    <CustomtextField
                      customData={{ label: 'Password' }}
                      type="password"
                      e2e="password"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      error={!validPass}
                      helperText={!validPass ? 'Please enter a password' : ' '}
                      style={{ marginBottom: '2%' }}
                    />
                    <Link
                      to="/forgot-password"
                      style={{ textDecoration: 'none' }}
                    >
                      <Typography
                        sx={{
                          fontSize: '14px',
                          fontWeight: 'bold',
                          color: '#1976D2',
                          display: 'flex',
                          justifyContent: 'flex-end',
                          marginTop: '-10px',
                        }}
                      >
                        Forgot password
                      </Typography>
                    </Link>
                  </Grid>
                  {loading ? (
                    <CustomProgress />
                  ) : (
                    <CustomButton
                      e2e="sign-in"
                      label="Sign in"
                      onClick={handleLogin}
                    />
                  )}
                  {!isConfirmRegister && (
                    <Typography
                      sx={{
                        textAlign: 'center',
                        marginTop: '55px',
                      }}
                    >
                      You can{' '}
                      <Link
                        to="/register"
                        style={{
                          color: '#1976D2',
                          textDecoration: 'none',
                        }}
                      >
                        register your company
                      </Link>{' '}
                      here!
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Box>
          </Box>
          <div dir="ltr">
            <Footer />
          </div>
        </Box>
      )}
    </>
  );
}

export default Login;
