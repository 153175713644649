import React, { useState } from 'react';
import Drawer from '../Drawer';
import DrawerFooter from '../DrawerFooter';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
  Alert,
  Box,
  Snackbar,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';

const AddCustomer = ({
  toggleAddCouponDrawer,
  drawerType,
  drawerData = null,
}) => {
  const [IsOpen, setIsOpen] = useState(false);
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [state, setState] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'right',
  });
  const { vertical, horizontal, open } = state;

  const handleClick = () => {
    navigator.clipboard.writeText(
      "<iframe src='https://demo.booknetic.com/saas/tenant?iframe=l' style='max-width:1001px;height:600px;width:100%;'> </iframe>"
    );
    setState({ ...state, open: true });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };
  return (
    <Grid container>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        autoHideDuration={1000}
        key={vertical + horizontal}
      >
        <Alert severity="success">Link Copied !!</Alert>
      </Snackbar>
      <Grid item xs={12} sm={2}>
        <Drawer
          type="SHARE"
          title="Share your page"
          toggleDrawer={toggleAddCouponDrawer}
        >
          {/* -----------FORM----- */}

          <Grid
            sx={{
              paddingX: 3,
              paddingTop: 3,
              height: '100%',
              overflowY: 'auto',
              width: '100%',
            }}
          >
            <Box>
              <Typography sx={{ my: '10px' }} variant="subtitle2">
                Your page URL
              </Typography>
              <Box sx={{ p: 1.5, background: '#F6F8F9', mb: 1.5 }}>
                <Typography>https://demo.booknetic.com/saas/tenant</Typography>
              </Box>
              {IsOpen && (
                <Box sx={{ mb: 3 }}>
                  <Typography sx={{ my: '10px' }} variant="subtitle2">
                    Email <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <TextField size="small" type="email" fullWidth />
                </Box>
              )}

              <Button
                variant="contained"
                sx={{ textTransform: 'inherit', background: '#6D74D5' }}
                onClick={() => setIsOpen(true)}
              >
                Send link via Email
              </Button>
            </Box>
            <Box marginTop={7}>
              <Typography variant="subtitle2" sx={{ my: '10px' }}>
                Add to your Website
              </Typography>
              <Box sx={{ p: 1.5, background: '#F6F8F9', mb: 1.5 }}>
                <Typography>
                  {
                    " <!-- Booknetic iframe --> <iframe src='https://demo.booknetic.com/saas/tenant?iframe=l' style='max-width:1001px;height:600px;width:100%;'> </iframe> <!-- Booknetic iframe --> "
                  }
                </Typography>
              </Box>
              <Button
                variant="contained"
                sx={{ textTransform: 'inherit', background: '#548cf6' }}
                onClick={handleClick}
              >
                Copy
              </Button>
            </Box>
          </Grid>

          {/* -----Button */}

          <DrawerFooter>
            <div
              style={{
                display: 'flex',
                justifyContent: isSmallScreen ? 'space-between' : 'flex-end',
                width: '100%',
              }}
            >
              <Button
                variant="contained"
                size="large"
                sx={{
                  width: isSmallScreen ? '48%' : '7rem',
                  backgroundColor: 'WHITE',
                  color: 'GREY',
                  ':hover': { backgroundColor: 'GREY', color: 'WHITE' },
                }}
                onClick={toggleAddCouponDrawer}
              >
                Close
              </Button>
            </div>
          </DrawerFooter>
        </Drawer>
      </Grid>
    </Grid>
  );
};

export default AddCustomer;
