import React, { useState } from "react";

import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { paymentTableData } from "../../utils/PaymentData";

import { Avatar, Box, Icon, MenuItem, TextField } from "@mui/material";
import AvatarComp from "../../components/Avatar";

const PaymentInfo = ({
  togglePaymentInfoDrawer,
  drawerType,
  drawerData = null,
}) => {
  const [data, setData] = useState(paymentTableData.coloum);
  const user = data.find((info) => info.id === drawerData);
  const [isEdit, setIsEdit] = useState(false);
  // const matches = useMediaQuery(useTheme().breakpoints.up("sm"));

  const CalcTotal = (service, vat, discount) => {
    return service + vat - discount;
  };

  return (
    <Grid container>
      {/* sx={{ width: "100vw"}} */}
      <Grid item xs={12} sm={2}>
        <Drawer
          variant="temporary"
          anchor="right"
          open={true}
          onClose={() => {}}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: { xs: "100vw", md: "50vw" },
              backgroundColor: "white",
            },
          }}
        >
          <Grid item container>
            {/* -----------HEADING */}
            <Grid
              item
              container
              sx={{ borderBottom: "1px solid #e3eaf3", paddingX: 3 }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContents: "space-between",
                  alignItems: "center",
                  height: "81px",
                }}
              >
                <Grid
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContents: "space-between",
                    alignItems: "center",
                  }}
                >
                  <img
                    src="https://demo.booknetic.com/saas/wp-content/plugins/booknetic/app/Backend/Base/assets/icons/add-employee.svg"
                    alt=""
                  />
                  <Typography
                    sx={{
                      fontSize: 22,
                      fontWeight: 500,
                      marginLeft: 2,
                      color: "#292D32",
                    }}
                  >
                    {drawerType === "add" ? "Payment" : "Edit"}
                  </Typography>
                </Grid>

                <CloseIcon
                  sx={{ color: "#ADBFC7", cursor: "pointer" }}
                  onClick={togglePaymentInfoDrawer}
                />
              </Grid>
            </Grid>
            {/* -----------FORM----- */}
            {/* <Box sx={{ overflowY:'scroll'}}> */}
            {isEdit ? (
              <>
                <Box
                  container
                  sx={{
                    height: "89%",
                    width: "100%",
                    margin: "30px",
                    padding: "10px",
                  }}
                >
                  <Box>
                    <Box marginBottom="10px">
                      <p style={{ fontWeight: "500", margin: "10px 0px" }}>
                        Service Price <span style={{ color: "red" }}>*</span>
                      </p>
                      <TextField
                        variant="outlined"
                        sx={{ width: "100%" }}
                        TextField
                        value={user.servicePrice}
                        inputProps={{
                          style: {
                            height: "7px",
                          },
                        }}
                      />
                    </Box>
                    <Box marginBottom="10px">
                      <p style={{ fontWeight: "500", margin: "10px 0px" }}>
                        Discount <span style={{ color: "red" }}>*</span>
                      </p>
                      <TextField
                        variant="outlined"
                        sx={{ width: "100%" }}
                        value={user.discount}
                        inputProps={{
                          style: {
                            height: "7px",
                          },
                        }}
                      />
                    </Box>
                    <Box marginBottom="10px">
                      <p style={{ fontWeight: "500", margin: "10px 0px" }}>
                        VAT<span style={{ color: "red" }}>*</span>
                      </p>
                      <TextField
                        variant="outlined"
                        sx={{ width: "100%" }}
                        value={user.vat}
                        inputProps={{
                          style: {
                            height: "7px",
                          },
                        }}
                      />
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      sx={{ flexDirection: { xs: "column", md: "row" } }}
                    >
                      <Box
                        marginBottom="10px"
                        width="100%"
                        sx={{ width: { xs: "100%", md: "49%" } }}
                      >
                        <p style={{ fontWeight: "500", margin: "10px 0px" }}>
                          Paid Amount <span style={{ color: "red" }}>*</span>
                        </p>
                        <TextField
                          variant="outlined"
                          sx={{ width: "100%" }}
                          inputProps={{
                            style: {
                              height: "7px",
                            },
                          }}
                        />
                      </Box>
                      <Box
                        marginBottom="10px"
                        sx={{ width: { xs: "100%", md: "49%" } }}
                      >
                        <p style={{ fontWeight: "500", margin: "10px 0px" }}>
                          Payment Status <span style={{ color: "red" }}>*</span>
                        </p>
                        <TextField
                          variant="outlined"
                          sx={{ width: "100%" }}
                          inputProps={{
                            style: {
                              padding: "9px",
                            },
                          }}
                          value="Paid"
                          onChange=""
                          name="Status"
                          select
                          size="small"
                        >
                          <MenuItem value="Paid">Paid</MenuItem>
                          <MenuItem value="Not Paid">Not Paid</MenuItem>
                          <MenuItem nalue="Pending">Pending</MenuItem>
                          <MenuItem nalue="Pending">Canceled</MenuItem>
                        </TextField>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </>
            ) : (
              <>
                <Grid
                  container
                  sx={{
                    boxShadow: 3,
                    width: "100%",
                    margin: "30px",
                    padding: "30px",
                  }}
                >
                  <Grid item xs={12} md={3}>
                    <Typography
                      variant="h3"
                      fontSize="16px"
                      fontWeight="500"
                      marginBottom="5px"
                    >
                      Staff:
                    </Typography>
                    <Typography variant="p" fontSize="14px" color="#6c70dc">
                      {user.staff}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography
                      variant="h3"
                      fontSize="16px"
                      fontWeight="500"
                      marginBottom="5px"
                    >
                      Location:
                    </Typography>
                    <Typography variant="p" fontSize="14px" color="#828F9A">
                      {user.location}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography
                      variant="h3"
                      fontSize="16px"
                      fontWeight="500"
                      marginBottom="5px"
                    >
                      Service:
                    </Typography>
                    <Typography variant="p" fontSize="14px" color="#828F9A">
                      {user.service}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography
                      variant="h3"
                      fontSize="16px"
                      fontWeight="500"
                      marginBottom="5px"
                    >
                      Date, time:
                    </Typography>
                    <Typography variant="p" fontSize="14px" color="#828F9A">
                      {user.appointDate}
                    </Typography>
                  </Grid>
                </Grid>

                <Box sx={{ width: "100%", marginX: "30px", boxShadow: 2 }}>
                  <Box
                    sx={{
                      display: "flex",
                      color: "#fff",
                      background: "#adbfc7",
                      padding: "10px 0px 10px 20px",

                      textTransform: "uppercase",
                    }}
                  >
                    <Typography flex={2} fontSize="12px" fontWeight="bold">
                      Customers
                    </Typography>
                    <Typography flex={1} fontSize="12px" fontWeight="bold">
                      Method
                    </Typography>
                    <Typography flex={1} fontSize="12px" fontWeight="bold">
                      Status
                    </Typography>
                  </Box>

                  <Box sx={{ padding: "20px", display: "flex" }}>
                    <Box sx={{ flex: 2.5, display: "flex" }}>
                      <AvatarComp username={user.customer.name} img ={user.customer.avatar} />
                      <div style={{ marginLeft: "7px" }}>
                        <Typography fontSize="14px" color="#292D32">
                          {user.customer.name}
                        </Typography>
                        <Typography fontSize="12px" color="#95A0A9">
                          {user.customer.email}
                        </Typography>
                      </div>
                    </Box>
                    <Typography flex={1.3}>{user.method}</Typography>
                    <Box flex={1}>
                      <Icon
                        sx={{
                          background: "#ddd",
                          color: "white",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <CloseIcon />
                      </Icon>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    minWidth: "340px",
                    border: "1px solid #ddd",
                    margin: "30px",
                    marginLeft: "auto",
                    padding: "24px 24px 32px 24px",
                  }}
                >
                  <Typography
                    marginBottom="24px"
                    fontWeight="500"
                    variant="h6"
                    color="#000"
                  >
                    Payment details
                  </Typography>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    marginY="15px"
                  >
                    <Typography variant="p" color="#212529" fontSize="16px">
                      Service price
                    </Typography>
                    <Typography variant="p" fontWeight="500" color="black">
                      ${user.servicePrice}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    marginY="15px"
                  >
                    <Typography variant="p" color="#212529" fontSize="16px">
                      Discount
                    </Typography>
                    <Typography variant="p" fontWeight="500" color="black">
                      ${user.discount}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    marginY="15px"
                  >
                    <Typography variant="p" color="#212529" fontSize="16px">
                      Vat
                    </Typography>
                    <Typography variant="p" fontWeight="500" color="black">
                      ${user.vat}
                    </Typography>
                  </Box>
                  <hr />
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    marginY="15px"
                  >
                    <Typography
                      variant="p"
                      color="#00060c"
                      fontWeight="500"
                      fontSize="16px"
                    >
                      Total
                    </Typography>
                    <Typography
                      variant="p"
                      fontWeight="500"
                      fontSize="16px"
                      color="#000"
                    >
                      ${CalcTotal(user.servicePrice, user.vat, user.discount)}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    marginY="15px"
                  >
                    <Typography
                      variant="p"
                      color="#000"
                      fontWeight="500"
                      fontSize="16px"
                    >
                      Paid
                    </Typography>
                    <Typography
                      variant="p"
                      fontWeight="500"
                      color="#26D090"
                      fontSize="20px"
                    >
                      $
                      {CalcTotal(user.servicePrice, user.vat, user.discount) -
                        user.dueAmount}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    marginY="15px"
                  >
                    <Typography
                      variant="p"
                      fontWeight="500"
                      color="#000000"
                      fontSize="16px"
                    >
                      Due
                    </Typography>
                    <Typography
                      variant="p"
                      fontWeight="500"
                      color="#FB3E6E"
                      fontSize="20px"
                    >
                      ${user.dueAmount}
                    </Typography>
                  </Box>
                </Box>
                {/* </Box> */}
                {/* -----Button */}
              </>
            )}
            <Grid
              item
              container
              sx={{
                display: "flex",
                justifyContent: "space-between",
                boxShadow: "5px -2px 10px -5px #e4ebf4",
                borderTop: "1px solid #E4EBF4",
                bottom: 5,
                paddingTop: 1,
                backgroundColor: "white",
                paddingX: 2,
                position: "sticky",
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  flexDirection: { xs: "column", sm: "row" },
                }}
              >
                {!isEdit && (
                  <Button
                    variant="contained"
                    size="large"
                    sx={{
                      width: { xs: "100%", sm: "auto" },

                      padding: "13px 16px",
                      fontSize: "12px",
                      background: "#53d56c",
                      marginX: 1,
                    }}
                  >
                    Complete Payment
                  </Button>
                )}

                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    width: { xs: "100%", sm: "auto" },
                    margin: 1,
                    padding: "13px 16px",
                    fontSize: "12px",
                  }}
                  onClick={() => setIsEdit(true)}
                >
                  {isEdit ? "Save" : "Edit"}
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    width: { xs: "100%", sm: "auto" },

                    padding: "13px 16px",
                    fontSize: "12px",
                    backgroundColor: "WHITE",
                    color: "GREY",
                    margin: 1,
                    ":hover": { backgroundColor: "GREY", color: "WHITE" },
                  }}
                  onClick={togglePaymentInfoDrawer}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Drawer>
      </Grid>
    </Grid>
  );
};

export default PaymentInfo;
