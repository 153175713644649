import React from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";

Quill.register("modules/imageResize", ImageResize);

function QuillEditor({ onChange, value, quillRef, onBlur }) {
  const handleOnChange = (content) => {
    if (onChange) {
      onChange({
        html: content,
      });
    }
  };

  return (
    <ReactQuill
      ref={quillRef}
      modules={{
        toolbar: [
          [{ header: [] }, { font: [] }],
          [{ size: [] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link", "image", "video"],
          ["clean"],
        ],
        clipboard: {
          // toggle to add extra line breaks when pasting HTML:
          matchVisual: false,
        },
        imageResize: {
          displayStyles: {
            backgroundColor: "black",
            border: "none",
            color: "white",
          },
          modules: ["Resize", "DisplaySize", "Toolbar"],
        },
      }}
      value={value}
      onChange={handleOnChange}
      onBlur={onBlur}
    />
  );
}
export default QuillEditor;
