import React, { useEffect, useRef, useState } from 'react';
import Drawer from '../../components/Drawer';
import DrawerFooter from '../../components/DrawerFooter';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { SERVER_URL } from './configs';
import {
  Box,
  Chip,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import QuillEditor from '../../components/QuillEditor';
import CustomTextButton from '../../components/CustomButtons/CustomTextButton';

const whenThisHappens = [
  'New booking',
  'Booking rescheduled',
  'Booking status changed',
  'Booking Starts',
  'Booking Ends',
  'New customer created',
  'New rating',
];

const doThis = ['Send Email'];

const AddWorkflow = ({
  toggleAddWorkflowDrawer,
  drawerType,
  drawerData = null,
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [planId, setPlanId] = useState('');
  const [expiry, setExpiry] = useState(new Date());
  const [allPlans, setAllPlans] = useState([]);
  const [isEnabled, setIsEnabled] = useState(false);
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('sm'));
  const matches = useMediaQuery(useTheme().breakpoints.up('sm'));
  const [quillHtml, setQuillHtml] = useState('');
  const [quillRange, setQuillRange] = useState(0);
  const quillRef = useRef();
  const [formData, setFormData] = useState({
    whenThisHappens: [],
    doThis: [],
  });
  const handleFormData = (fieldName, value) => {
    setFormData({
      ...formData,
      [fieldName]: typeof value === 'string' ? value.split(',') : value,
    });
  };

  const fetchPlans = async () => {
    const response = await axios.post(SERVER_URL + '/get-plan');
    const plansData = response.data.data.map((plan) => {
      plan.label = plan.name;
      return plan;
    });
    setAllPlans(plansData);
  };

  const addWorkflow = async () => {
    if (email && password && fullName && planId && expiry) {
      const data = {
        email: email,
        password: password,
        fname: fullName,
        plan: planId,
        expiry: expiry,
      };
      const response = await axios.post(SERVER_URL + '/add-workflow', data);
      toggleAddWorkflowDrawer();
    }
  };

  const updateWorkflow = async () => {
    if (email && fullName && planId && expiry && drawerData.id) {
      const data = {
        workflow_id: drawerData.id,
        email: email,
        password: password,
        fname: fullName,
        plan: planId,
        expiry: expiry,
      };
      const response = await axios.post(SERVER_URL + '/update-workflow', data);
      toggleAddWorkflowDrawer();
    }
  };

  const getPlanNameFromId = (id) => {
    let name = '';
    allPlans.forEach((plan) => {
      if (plan.id === id) {
        name = plan.name;
      }
    });
    return name;
  };

  const getPlanIdFromName = (name) => {
    let id = '';
    allPlans.forEach((plan) => {
      if (plan.name === name) {
        id = plan.id;
      }
    });
    return id;
  };

  useEffect(() => {
    fetchPlans();
    setEmail(drawerType !== 'add' ? drawerData.email : '');
    setPassword('');
    setFullName(drawerType !== 'add' ? drawerData.fullName : '');
    setExpiry(drawerType !== 'add' ? drawerData.expiry : new Date());
    setPlanId('');
  }, []);

  useEffect(() => {
    if (drawerType !== 'add') {
      setPlanId(drawerType !== 'add' ? getPlanIdFromName(drawerData.plan) : '');
    }
  }, [allPlans]);

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
  };
  const handleFullName = (e) => {
    setFullName(e.target.value);
  };
  const handlePlan = (e, selectedData) => {
    if (selectedData && e) {
      setPlanId(selectedData.id);
    }
  };
  const handleExpiry = (e) => {
    setExpiry(e);
  };
  return (
    <Grid container>
      <Grid item xs={12} sm={2}>
        <Drawer
          title={drawerType === 'add' ? 'Add Workflow' : 'Edit Workflow'}
          type={drawerType === 'add' ? 'ADD' : 'EDIT'}
          toggleDrawer={toggleAddWorkflowDrawer}
        >
          {/* -----------FORM----- */}
          <Grid
            sx={{
              paddingX: 3,
              paddingTop: 3,
              height: '100%',
              overflowY: 'auto',
              width: '100%',
            }}
          >
            <Grid container>
              <Grid xs={12}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">Workflow Name</Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="outlined-basic"
                    size="small"
                    required
                    variant="outlined"
                    sx={{
                      marginBottom: '1rem',
                      width: '100%',
                      fontSize: '14px',
                    }}
                    value={email}
                    type="email"
                    onChange={handleEmail}
                  />
                </Grid>
              </Grid>

              <Grid xs={12}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">When This Happens</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Select
                    multiple
                    value={formData.whenThisHappens}
                    xs={12}
                    sx={{
                      marginBottom: '1rem',
                      fontSize: '14px',
                      width: matches ? '98.5%' : '100%',
                    }}
                    onChange={(e) => {
                      handleFormData('whenThisHappens', e.target.value);
                    }}
                    input={<OutlinedInput size="small" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                  >
                    {whenThisHappens.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox
                          checked={formData.whenThisHappens.indexOf(name) > -1}
                        />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>

              <Grid xs={12}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">Do This</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Select
                    multiple
                    value={formData.doThis}
                    xs={12}
                    sx={{
                      marginBottom: '1rem',
                      fontSize: '14px',
                      width: matches ? '98.5%' : '100%',
                    }}
                    onChange={(e) => {
                      handleFormData('doThis', e.target.value);
                    }}
                    input={<OutlinedInput size="small" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                  >
                    {doThis.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox
                          checked={formData.doThis.indexOf(name) > -1}
                        />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
              <QuillEditor
                quillRef={quillRef}
                onChange={({ html }) => {
                  setQuillHtml(html);
                }}
                onBlur={(prevSelection) => {
                  setQuillRange(prevSelection);
                }}
                value={quillHtml}
              />
            </Grid>
          </Grid>
          {/* -----Button */}
          <DrawerFooter>
            <>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: isSmallScreen ? 'flex-start' : 'normal',
                }}
              >
                <Checkbox
                  size="small"
                  checked={true}
                  color="success"
                  sx={{
                    marginBottom: { xs: 1, sm: 0 },
                  }}
                />
                <Typography
                  variant="subtitle2"
                  sx={{
                    marginLeft: { sm: 0, xs: -1 },
                    marginBottom: { xs: 1, sm: 0 },
                  }}
                >
                  Enabled
                </Typography>
              </div>

              <div
                style={{
                  display: 'flex',
                  gap: '15px',
                  justifyContent: isSmallScreen ? 'space-between' : 'flex-end',
                  width: '100%',
                }}
              >
                <CustomTextButton
                  variant="contained"
                  size="large"
                  onClick={toggleAddWorkflowDrawer}
                >
                  Cancel
                </CustomTextButton>
                <CustomTextButton
                  type="blue"
                  variant="contained"
                  size="large"
                  onClick={() => {
                    if (drawerType === 'add') {
                      addWorkflow();
                    } else {
                      updateWorkflow();
                    }
                  }}
                >
                  {drawerType === 'add' ? 'Save' : 'Save'}
                </CustomTextButton>
              </div>
            </>
          </DrawerFooter>
        </Drawer>
      </Grid>
    </Grid>
  );
};

export default AddWorkflow;
