import { Box } from '@mui/material';
import React from 'react';
import { statusTypes } from '../../constants/statusList';

function StatusChip({
  color,
  bgColor,
  text,
  boxShadow = '',
  type = 'Pending Approval',
  rounded,
  width,
  opacity,
  onClick = () => { },
}) {
  if (type) {
    return (
      <Box
        onClick={onClick}
        sx={{
          color: statusTypes[type]?.color || '#509F8A',
          backgroundColor: statusTypes[type]?.bgColor || '#84FFDE',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '25px',
          fontSize: '14px',
          padding: '0 .8rem',
          minWidth: '24px',
          lineHeight: '14px',
          borderRadius: '15px',
          width: width ? width : 'fit',
          opacity,
        }}
      >
        {text ? text : statusTypes[type]?.text}
      </Box>
    );
  } else {
    return (
      <Box
        onClick={onClick}
        sx={{
          color,
          cursor: 'pointer',
          backgroundColor: bgColor,
          boxShadow: boxShadow,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '25px',
          fontSize: '14px',
          padding: '0 .8rem',
          minWidth: '24px',
          lineHeight: '14px',
          borderRadius: rounded ? '15px' : '0',
          width: width ? width : 'fit',
          opacity,
        }}
      >
        {text}
      </Box>
    );
  }
}

export default StatusChip;
