import { Box, Grid } from '@mui/material';
import React from 'react';
import WeeklySchedule from './WeeklySchedule';

const TimeSheet = ({ timesheets, setTimesheets, spts, setSpts }) => {
  return (
    <Box>
      <Grid container>
        <WeeklySchedule
          timesheets={timesheets}
          setTimesheets={setTimesheets}
          spts={spts}
          setSpts={setSpts}
        />
      </Grid>
    </Box>
  );
};

export default TimeSheet;
