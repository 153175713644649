import { Avatar, Box, Typography } from '@mui/material';
import React from 'react';
import { paymentTableData } from '../../utils/PaymentData';
import { useState } from 'react';
import { useEffect } from 'react';
import moment from 'moment/moment';
import {
  DataGrid,
  GridActionsCellItem,
  GridMoreVertIcon,
  gridStringOrNumberComparator,
} from '@mui/x-data-grid';
import CustomTableFooter from '../CustomTableFooter';
import StatusChip from '../StatusChip';
import PriceFormatter from '../../utils/PriceFormatter';
import AvatarComp from '../Avatar';
import { Link } from 'react-router-dom';
import NoData from '../NoData';

const PaymentTable = ({
  sortInfo,
  setDrawerType,
  toggleAddCouponDrawer,
  setCustomerId,
  tableData,
  settableData,
  handleDeleteOpenModal,
  selectionModel,
}) => {
  const [sortedData, setSortedData] = useState([]);

  const searchData = () => {
    let tempData = [...sortedData];
    sortInfo.forEach((item) => {
      if (item.id === 1) {
        const filterValue = tempData.filter((data) =>
          data.customer.name.toLowerCase().includes(item.ans.toLowerCase())
        );
        tempData = filterValue;
        settableData(filterValue);
      }
      if (item.id === 2) {
        const filterValue = tempData.filter((data) => {
          console.log(data.appointmentDate);
          return (
            data.appointmentDate?.slice(0, 10) ===
            moment(item.ans).format('DD/MM/YYYY')
          );
        });
        tempData = filterValue;
        settableData(filterValue);
      }
      if (item.id === 3) {
        const filterValue = tempData.filter(
          (data) => data.service.toLowerCase() === item.ans.toLowerCase()
        );
        tempData = filterValue;
        settableData(filterValue);
      }
      if (item.id === 4) {
        const filterValue = tempData.filter(
          (data) => data.customer.name.toLowerCase() === item.ans.toLowerCase()
        );
        tempData = filterValue;
        settableData(filterValue);
      }
      if (item.id === 5) {
        const filterValue = tempData.filter(
          (data) => data.staff.toLowerCase() === item.ans.toLowerCase()
        );
        tempData = filterValue;
        settableData(filterValue);
      }
      if (item.id === 6) {
        const filterValue = tempData.filter(
          (data) => data.status.toLowerCase() === item.ans.toLowerCase()
        );
        tempData = filterValue;
        settableData(filterValue);
      }
    });
  };

  useEffect(() => {
    settableData(paymentTableData.coloum);
    setSortedData(paymentTableData.coloum);
    searchData();
  }, [sortInfo]);

  const handelClick = (id) => {
    setDrawerType('add');
    toggleAddCouponDrawer();
    setCustomerId(id);
  };

  const header = [
    {
      field: 'appointDate',
      headerName: 'APPOINTMENT DATE',
      minWidth: 135,
      flex: 1.5,
    },
    {
      field: 'customer',
      headerName: 'CUSTOMER',
      flex: 1.5,
      minWidth: 210,
      sortComparator: (v1, v2) =>
        gridStringOrNumberComparator(v1.name, v2.name),
      renderCell: (params) => (
        <Box sx={{ display: 'flex' }}>
          <AvatarComp
            img={params.value.avatar}
            username={params.value.name}
            sx={{ marginRight: '10px' }}
          />
          <Box>
            <Typography fontSize="14px" color="#292d32">
              <Link
                to={`/customer/${params.value.id}`}
                style={{
                  color: '#292d32',
                  textDecoration: 'none',
                }}
              >
                {params.value.name}
              </Link>
            </Typography>
            <Typography color="#95a0a9" fontSize="12px">
              {params.value.email}
            </Typography>
          </Box>
        </Box>
      ),
    },
    { field: 'staff', flex: 1, headerName: 'STAFF', minWidth: 110 },
    { field: 'service', flex: 1, headerName: 'SERVICE', minWidth: 100 },
    { field: 'method', headerName: 'METHOD', minWidth: 80 },
    { field: 'location', headerName: 'LOCATION', minWidth: 90 },

    {
      field: 'totalamount',
      headerName: 'TOTAL AMOUNT',
      sortable: false,
      minWidth: 85,
      valueGetter: (params) =>
        params.row.servicePrice + params.row.vat - params.row.discount,
      renderCell: (params) => <>{PriceFormatter.format(params.value)}</>,
    },
    {
      field: 'paidamount',
      headerName: 'PAID AMOUNT',
      minWidth: 85,
      renderCell: (params) => <>{PriceFormatter.format(params.value)}</>,
    },

    {
      field: 'dueamount',
      headerName: 'DUE AMOUNT',
      minWidth: 85,
      renderCell: (params) => <>{PriceFormatter.format(params.value)}</>,
    },
    {
      field: 'status',
      headerName: 'STATUS',
      minWidth: 150,
      renderCell: (params) => (
        <>
          {params.value === 'Paid' ? (
            <StatusChip
              text={params.value}
              color={'#8467E9'}
              bgColor={'#EAE5FB'}
              rounded={true}
            />
          ) : params.value === 'Not Paid' ? (
            <StatusChip
              text={'NotPaid'}
              color={'#FFFFFF'}
              bgColor={'#C8CFD8'}
              rounded={true}
            />
          ) : params.value === 'Pending' ? (
            <StatusChip
              text={params.value}
              color={'#EA9B7F'}
              bgColor={'#FBEEE9'}
              rounded={true}
            />
          ) : (
            <StatusChip
              text={params.value}
              color={'#DF3E70'}
              bgColor={'#F9E0E7'}
              rounded={true}
            />
          )}
        </>
      ),
    },
    {
      field: 'actions',
      headerName: 'ACTIONS',
      type: 'actions',
      align: 'right',
      maxWidth: 100,
      minWidth: 100,
      flex: 1,
      getActions: (params) => [
        <GridActionsCellItem
          label="Info"
          onClick={() => handelClick(params.id)}
          showInMenu
        />,
      ],
    },
  ];

  return (
    <Box sx={{ width: '100%' }}>
      <DataGrid
        sx={{
          border: 0,
          '& .MuiDataGrid-virtualScroller': {
            minHeight: '300px !important',
          },
          '& .MuiDataGrid-columnHeader': {
            backgroundColor: '#EFF1FC',
            color: '#3A3A50',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: '12px',
            fontWeight: '900 !important',
            textOverflow: 'clip',
            whiteSpace: 'break-spaces',
            lineHeight: 1,
          },
          '& .MuiDataGrid-main, .MuiDataGrid-footerContainer': {
            backgroundColor: 'white',
            border: 'none',
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          minHeight: '60vh',
        }}
        rows={tableData}
        columns={header}
        pageSize={5}
        rowsPerPageOptions={[5]}
        // checkboxSelection
        autoHeight
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        components={{
          NoRowsOverlay: NoData,
          NoResultsOverlay: NoData,
          Footer: () => (
            <CustomTableFooter
              selectionModel={selectionModel}
              handleDeleteOpenModal={handleDeleteOpenModal}
            />
          ),
          MoreActionsIcon: () => (
            <GridMoreVertIcon
              sx={{
                backgroundColor: 'white',
                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
                borderRadius: '4px',
                padding: '3px',
                cursor: 'pointer',
              }}
            />
          ),
          NoRowsOverlay: () => {
            return (
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'Center',
                  gap: '20px',
                }}
              >
                <img
                  src="https://user-images.githubusercontent.com/109676648/254145031-57027cd6-fecd-4ce2-96fc-9d891c8de53a.svg"
                  alt="no data"
                  style={{
                    width: '100px',
                    height: '100px',
                  }}
                />
                No data at the moment 😔
              </Box>
            );
          },
        }}
      />
    </Box>
  );
};

export default PaymentTable;
