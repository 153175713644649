import { UpnizeBackend } from '../Axios';

export const getReviewList = async (rows, page, searchQuery) => {
  let response = await UpnizeBackend.get(
    `/Reviews?rows=${rows}&page=${page}${
      searchQuery ? `&keyword=${searchQuery}` : ''
    }`
  );
  return response;
};

export const getReviewById = async (id) => {
  let response = await UpnizeBackend.get(`/Reviews/byId/${id}`);
  return response;
};

export const addReview = async (body) => {
  let response = await UpnizeBackend.post(`/Reviews/add`, body);
  return response;
};

export const editReviewById = async (body, id) => {
  let response = await UpnizeBackend.put(`/Reviews/edit/${id}`, body);
  return response;
};

export const deleteReviewById = async (id) => {
  let response = await UpnizeBackend.delete(`/Reviews/delete`, {
    data: { ids: id },
  });
  return response;
};
