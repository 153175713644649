import * as d3 from "d3";
import { useEffect, useRef } from "react";

const AreaLineChart = ({ initData }) => {
    const svgRef = useRef(null);

    useEffect(() => {
// set the dimensions and margins of the graph
const margin = {top: 10, right: 30, bottom: 30, left: 50},
      width = svgRef.current.parentElement.offsetWidth - margin.left - margin.right,
      height = 315 - margin.top - margin.bottom;

      const data = initData.map((d) => ({ xValue : d3.timeParse("%Y-%m-%d")(d.xValue), yValue : d.yValue }))

// append the svg object to the body of the page
const svg = d3
.select(svgRef.current)
    .attr("viewBox", `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`)
  .append("g")
    .attr("transform",`translate(${margin.left},${margin.top})`);

/*
  // When reading the csv, I must format variables:
  d => {
    return { date : d3.timeParse("%Y-%m-%d")(d.date), value : d.value }
  }).then(
    */

    // Add X axis --> it is a date format
    const x = d3.scaleTime()
      .domain(d3.extent(data, d => d.xValue))
      .range([ 0, width ]);
    svg.append("g")
      .attr("transform", `translate(0,  ${height+5})`)
      .call(d3.axisBottom(x).ticks(10).tickSizeOuter(0));

    // Add Y axis
    const y = d3.scaleLinear()
      .domain( d3.extent(data, d => +d.yValue))
      .range([ height, 0 ]);
    svg.append("g")
      .attr("transform", "translate(-5,0)")
      .call(d3.axisLeft(y).tickSizeOuter(0));

    // Add the area
    svg.append("path")
      .datum(data)
      .attr("fill", "#EEFBF1")
      .attr("fill-opacity", .3)
      .attr("stroke", "none")
      .attr("d", d3.area()
        .x(d => x(d.xValue))
        .y0( height )
        .y1(d => y(d.yValue))
        )

    // Add the line
    svg.append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "#5CD775")
      .attr("stroke-width", 4)
      .attr("d", d3.line()
        .x(d => x(d.xValue))
        .y(d => y(d.yValue))
        )

    // Add the line
    svg.selectAll("myCircles")
      .data(data)
      .join("circle")
        .attr("fill", "#5CD775")
        .attr("stroke", "#5CD775")
        .attr("cx", d => x(d.xValue))
        .attr("cy", d => y(d.yValue))
        .attr("r", 5)
    }, []);
  
    return <svg ref={svgRef} />;
}

export default AreaLineChart