import React, { useLayoutEffect, useState } from 'react';
import Drawer from '../../components/Drawer';
import DrawerFooter from '../../components/DrawerFooter';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  Autocomplete,
  Box,
  MenuItem,
  TextareaAutosize,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { MuiTelInput } from 'mui-tel-input';
import { DatePicker } from '@mui/x-date-pickers';
import ProfileImageField from '../../components/ProfileImageField';
import { addCustomer, editCustomer } from '../../Api/tenant/customers';
import { addUser, editUserById } from '../../Api/common/users';
import AvatarComp from '../../components/Avatar';
import CustomTextField from '../../components/CustomTextField';
import CustomTextButton from '../../components/CustomButtons/CustomTextButton';
import countrydata from '../Locations/Tabs/Countrydata.json';
import { useSelector } from 'react-redux';
import { selectUser } from '../../redux/slices/user.slice.js';

const requiredFields = ['firstName', 'lastName', 'email'];

const AddCustomer = ({
  toggleDrawer,
  drawerType,
  drawerData = null,
  fetchCustomers,
}) => {
  const [isOpenDatePiker, setIsOpenDatePiker] = useState(false);
  const [value, setValue] = useState('+46');
  const [datevalue, setDateValue] = useState(null);
  const [image, setImage] = useState(null);
  const [errors, setErrors] = useState({});
  const { user, isUserLoading } = useSelector(selectUser);
  const [formData, setFormData] = useState({
    firstName: null,
    lastName: null,
    email: null,
    gender: null,
    country: null,
    city: null,
    note: null,
  });
  const [states, setStates] = useState([]);
  const [loading, setLoading] = useState(false);
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('sm'));
  const handleFormData = (fieldName, value) => {
    if (fieldName === 'country') {
      const getStates = countrydata.find(
        (country) => country.country_name === value
      ).states;
      setStates(getStates);
    }
    setFormData({
      ...formData,
      [fieldName]: typeof value === 'string' ? value.split(',') : value,
    });
  };

  const handelDate = (value) => {
    setDateValue(value);
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  useLayoutEffect(() => {
    if (drawerType === 'edit') {
      setFormData({
        firstName: drawerData.User.firstName,
        lastName: drawerData.User.lastName,
        email: drawerData.User.email,
        gender: drawerData.User.gender,
        country: drawerData.User.country,
        city: drawerData.User.city,
        note: drawerData.note,
      });
      setImage(drawerData.User.image);
      setValue(drawerData.User.phone || '+46');
      setDateValue(drawerData.User.dob);
    }
  }, []);

  //  API

  const handleAddCustomer = () => {
    setLoading(true);
    let err = {};

    for (const key in formData) {
      if (!formData[key] && requiredFields.includes(key)) {
        err = { ...err, [key]: true };
      }
    }
    setErrors({ ...err });

    if (Object.keys(err).length === 0) {
      const { firstName, lastName, city, country, email, gender, note } =
        formData;

      let formdata = new FormData();

      firstName && formdata.append('firstName', firstName);
      lastName && formdata.append('lastName', lastName);
      city && formdata.append('city', city);
      country && formdata.append('country', country);
      email && formdata.append('email', email);
      gender && formdata.append('gender', gender);
      value && formdata.append('phone', value);
      datevalue && formdata.append('dob', datevalue);
      formdata.append('type', 'customer');
      formdata.append('belongsTo', 'barbaros');
      formdata.append('active', 'true');
      note && formdata.append('note', note);
      image && formdata.append('image', image);
      formdata.append('userId', user?.id);

      addCustomer(formdata).then(
        (res) => {
          toggleDrawer();
          fetchCustomers();
          setLoading(false);
        },
        (rej) => {
          rej?.response.status === 409 &&
            setErrors({
              email: { message: 'Email already exists', isError: true },
            });
        }
      );
    }
    setLoading(false);
  };

  const handleEditCustomer = () => {
    setLoading(true);
    const { firstName, lastName, city, country, email, gender, note } =
      formData;
    let formdata = new FormData();

    firstName && formdata.append('firstName', firstName);
    lastName && formdata.append('lastName', lastName);
    city && formdata.append('city', city);
    country && formdata.append('country', country);
    email && formdata.append('email', email);
    gender && formdata.append('gender', gender);
    value.length > 4 && formdata.append('phone', value);
    datevalue && formdata.append('dob', datevalue);
    formdata.append('active', 'true');
    note && formdata.append('note', note);
    image && formdata.append('image', image);


    editUserById(formdata, drawerData.UserId).then((res) => {
      toggleDrawer();
      fetchCustomers();
      setLoading(false);
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container>
        <Grid item xs={12} sm={2}>
          <Drawer
            title={
              drawerType === 'add'
                ? 'Add Customer'
                : drawerType === 'info'
                ? 'Customer info'
                : 'Edit Customer'
            }
            type={
              drawerType === 'add'
                ? 'ADD'
                : drawerType === 'info'
                ? 'INFO'
                : 'EDIT'
            }
            toggleDrawer={toggleDrawer}
          >
            {/* -----------FORM----- */}
            {drawerType === 'info' ? (
              // USER INFO
              <Grid
                sx={{
                  paddingX: 3,
                  paddingTop: 3,
                  height: '100%',
                  overflowY: 'auto',
                }}
              >
                <Grid
                  container
                  sx={{
                    margin: '0rem',
                    boxShadow: '2',
                    padding: '1rem 2rem',
                    paddingBottom: '2rem',
                  }}
                >
                  <Grid
                    xs={12}
                    md={2.5}
                    sx={{
                      paddingRight: { sm: 2 },
                      marginTop: 1,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Box
                      sx={{
                        width: { xs: '40%', md: '100%' },
                      }}
                    >
                      <AvatarComp
                        img={drawerData?.User?.image || null}
                        username={`${drawerData.User.firstName} ${drawerData.User.lastName}`}
                        sx={{ width: 100, height: 100 }}
                      />
                    </Box>
                  </Grid>
                  <Grid
                    xs={12}
                    md={9.5}
                    sx={{
                      paddingRight: { sm: 2 },
                      paddingBottom: { xs: 5, md: 0 },
                      marginTop: 1,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: { xs: 'center', md: 'start' },
                    }}
                  >
                    <Typography variant="h2" fontSize="20px" fontWeight="bold">
                      {drawerData?.User?.firstName +
                        ' ' +
                        drawerData?.User.lastName}
                    </Typography>
                  </Grid>

                  <Grid container sx={{ marginTop: '1rem' }}>
                    <Grid
                      xs={12}
                      sm={6}
                      sx={{
                        paddingRight: { sm: 2 },
                        marginTop: 1,
                        display: 'flex',
                        flexWrap: 'wrap',
                      }}
                    >
                      <Grid item xs={5} md={12} sx={{ marginY: { md: 1 } }}>
                        <Typography
                          variant="h2"
                          fontSize="18px"
                          fontWeight="bold"
                        >
                          Email
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={12}>
                        <Typography variant="p" color="#828F9A" fontSize="14px">
                          {drawerData?.User?.email}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      xs={12}
                      sm={6}
                      sx={{
                        paddingRight: { sm: 2 },
                        marginTop: 1,
                        display: 'flex',
                        flexWrap: 'wrap',
                      }}
                    >
                      <Grid item xs={5} md={12} sx={{ marginY: 1 }}>
                        <Typography
                          variant="h2"
                          fontSize="18px"
                          fontWeight="bold"
                        >
                          Phone
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        sm={12}
                        sx={{ marginTop: { xs: 1, sm: 0 } }}
                      >
                        <Typography color="#828F9A" fontSize="14px">
                          {drawerData.User.phone}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container sx={{ marginTop: { sm: '2rem' } }}>
                    <Grid
                      xs={12}
                      sm={6}
                      sx={{
                        paddingRight: { sm: 2 },
                        display: 'flex',
                        flexWrap: 'wrap',
                      }}
                    >
                      <Grid item xs={5} md={12} sx={{ marginY: 1 }}>
                        <Typography
                          variant="h2"
                          fontSize="18px"
                          fontWeight="bold"
                        >
                          Gender
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={12}
                        sx={{ marginTop: { xs: 1, sm: 0 } }}
                      >
                        <Typography variant="p" color="#828F9A" fontSize="14px">
                          {drawerData.User.gender}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      xs={12}
                      sm={6}
                      sx={{
                        paddingRight: { sm: 2 },
                        display: 'flex',
                        flexWrap: 'wrap',
                      }}
                    >
                      <Grid item xs={5} md={12} sx={{ marginY: 1 }}>
                        <Typography
                          variant="h2"
                          fontSize="18px"
                          fontWeight="bold"
                        >
                          Birthday
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        sm={12}
                        sx={{ marginTop: { xs: 1, sm: 0 } }}
                      >
                        <Typography color="#828F9A" fontSize="14px">
                          {drawerData.User.dob}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid
                sx={{
                  paddingX: 3,
                  paddingTop: 3,
                  height: '100%',
                  overflowY: 'auto',
                  paddingRight: { sm: '5px' },
                  width: '100%',
                }}
              >
                <Grid container>
                  <Grid xs={12} sx={{ paddingRight: { sm: 2 } }}>
                    <ProfileImageField image={image} setImage={setImage} />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 2 } }}>
                    <Grid item xs={12} sx={{ marginY: 1 }}>
                      <Typography variant="subtitle2">
                        First Name <span style={{ color: 'red' }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTextField
                        size="small"
                        variant="outlined"
                        value={formData.firstName}
                        onChange={(e) => {
                          handleFormData('firstName', e.target.value);
                          setErrors({ ...errors, firstName: false });
                        }}
                        error={errors.firstName}
                        sx={{
                          width: '100%',
                          fontSize: '14px',
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 2 } }}>
                    <Grid item xs={12} sx={{ marginY: 1 }}>
                      <Typography variant="subtitle2">
                        Last Name <span style={{ color: 'red' }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTextField
                        size="small"
                        variant="outlined"
                        sx={{
                          width: '100%',
                          fontSize: '14px',
                        }}
                        error={errors.lastName}
                        onChange={(e) => {
                          handleFormData('lastName', e.target.value);
                          setErrors({ ...errors, lastName: false });
                        }}
                        value={formData.lastName}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 2 } }}>
                    <Grid item xs={12} sx={{ marginY: 1 }}>
                      <Typography variant="subtitle2">
                        Email <span style={{ color: 'red' }}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTextField
                        size="small"
                        variant="outlined"
                        error={errors.email}
                        sx={{
                          width: '100%',
                          fontSize: '14px',
                        }}
                        onChange={(e) => {
                          handleFormData('email', e.target.value);
                          setErrors({ ...errors, email: false });
                        }}
                        helperText={
                          errors?.email?.message ? errors?.email?.message : null
                        }
                        value={formData.email}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    xs={12}
                    sm={6}
                    sx={{ paddingRight: { sm: 2 }, marginBottom: 2 }}
                  >
                    <Grid item xs={12} sx={{ marginY: 1 }}>
                      <Typography variant="subtitle2">Phone</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <MuiTelInput
                        InputProps={{
                          style: {
                            height: '40px',
                            padding: '0 10px',
                          },
                        }}
                        error={errors.phone}
                        sx={{ width: '100%' }}
                        value={value}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 2 } }}>
                    <Grid item xs={12} sx={{ marginY: 1 }}>
                      <Typography variant="subtitle2">Gender</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTextField
                        size="small"
                        variant="outlined"
                        sx={{
                          width: '100%',
                          fontSize: '14px',
                        }}
                        defaultValue="other"
                        select
                        onChange={(e) => {
                          handleFormData('gender', e.target.value);
                          setErrors({ ...errors, gender: false });
                        }}
                        value={formData.gender}
                      >
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="female">Female</MenuItem>
                        <MenuItem value="other">Other</MenuItem>
                      </CustomTextField>
                    </Grid>
                  </Grid>
                  <Grid
                    xs={12}
                    sm={6}
                    sx={{ paddingRight: { sm: 2 }, marginBottom: 2 }}
                  >
                    <Grid item xs={12} sx={{ marginY: 1 }}>
                      <Typography variant="subtitle2">Date of birth</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <DatePicker
                        open={isOpenDatePiker}
                        onOpen={() => setIsOpenDatePiker(!isOpenDatePiker)}
                        onClose={() => setIsOpenDatePiker(!isOpenDatePiker)}
                        value={datevalue}
                        onChange={(newValue) => {
                          handelDate(newValue);
                        }}
                        InputProps={{
                          style: {
                            flexDirection: 'row-reverse',
                          },
                        }}
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            {...params}
                            onClick={() => setIsOpenDatePiker(!isOpenDatePiker)}
                            onTouchStart={() =>
                              setIsOpenDatePiker(!isOpenDatePiker)
                            }
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 2 } }}>
                    <Grid item xs={12} sx={{ marginY: 1 }}>
                      <Typography variant="subtitle2">Country</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        disablePortal
                        options={countrydata.map(
                          (getcountry, index) => getcountry.country_name
                        )}
                        value={formData.country}
                        onSelect={(e) => {
                          handleFormData('country', e.target.value.toString());
                          setErrors({ ...errors, country: false });
                        }}
                        sx={{ fontSize: '14px' }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            placeholder="Choose Country..."
                            helperText={
                              errors.country ? 'Value is missing' : ' '
                            }
                            error={errors.country}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 2 } }}>
                    <Grid item xs={12} sx={{ marginY: 1 }}>
                      <Typography variant="subtitle2">City</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        disablePortal
                        options={states.map(
                          (getstate, index) => getstate.state_name
                        )}
                        value={formData.city}
                        onSelect={(e) => {
                          handleFormData('city', e.target.value.toString());
                          setErrors({ ...errors, city: false });
                        }}
                        sx={{ fontSize: '14px' }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            placeholder="Choose City..."
                            helperText={errors.city ? 'Value is missing' : ' '}
                            error={errors.city}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid
                    xs={12}
                    sx={{
                      paddingRight: { sm: 1 },
                      marginBottom: 4,
                      marginRight: 0.5,
                    }}
                  >
                    <Grid item xs={12} sx={{ marginY: 1 }}>
                      <Typography variant="subtitle2">Note</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextareaAutosize
                        size="small"
                        variant="outlined"
                        style={{
                          width: '98%',
                          minHeight: '120px',
                          padding: '5px',
                          fontSize: '16px',
                          paddingRight: '4px',
                        }}
                        onChange={(e) => {
                          handleFormData('note', e.target.value);
                        }}
                        value={formData.note}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {/* -----Button */}
            <DrawerFooter>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: isSmallScreen ? 'flex-start' : 'normal',
                }}
              ></div>
              <div
                style={{
                  display: 'flex',
                  gap: '15px',
                  justifyContent: isSmallScreen ? 'space-between' : 'flex-end',
                  width: isSmallScreen ? '100%' : 'auto',
                  alignItems: 'center',
                }}
              >
                <CustomTextButton
                  variant="contained"
                  size="large"
                  onClick={toggleDrawer}
                >
                  Cancel
                </CustomTextButton>
                <CustomTextButton
                  type="blue"
                  variant="contained"
                  size="large"
                  loading={loading}
                  onClick={() => {
                    if (loading) return;
                    if (drawerType === 'add') {
                      handleAddCustomer();
                    } else {
                      handleEditCustomer();
                    }
                  }}
                >
                  {drawerType === 'add' ? 'Save' : 'Save'}
                </CustomTextButton>
              </div>
            </DrawerFooter>
          </Drawer>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export default AddCustomer;
