import { useState, useLayoutEffect } from 'react';
import { Autocomplete, Grid, TextField, useMediaQuery } from '@mui/material';
import CustomSettingsTitle from '../../CustomSettingsTitle/CustomSettingsTitle';
import SaveSlideBar from '../../SaveSlideBar';
import { useDispatch } from 'react-redux';
import { fetchUser } from '../../../redux/slices/user.slice.js';
import { editSettings } from '../../../Api/tenant/settings';

const translationOptions = [
  'English',
  'العربيه',
  'Swedish Svenska',
  'French',
  'Spanish',
];

const BookingWidgetTranslation = ({
  fetchCurrentSettings,
  currentSettings,
}) => {
  const [translationValue, setTranslationValue] = useState('English');
  const mediaQuery1238 = useMediaQuery('(min-width:1238px)');
  const [isSaveBar, setIsSaveBar] = useState(false);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    setTranslationValue(currentSettings.FEPanels.BWTranslation);
  }, []);

  // click handlers
  const handleSave = () => {
    editSettings({
      ...currentSettings,
      FEPanels: {
        ...currentSettings.FEPanels,
        BWTranslation: translationValue,
      },
    }).then((res) => {
      if (res.status === 200) {
        fetchCurrentSettings();
        dispatch(fetchUser())
          .unwrap()
          .then((res) => {
            setIsSaveBar(false);
          });
      }
    });
  };

  const handleCancel = () => {
    setTranslationValue(currentSettings.FEPanels.BWTranslation);
    setIsSaveBar(false);
  };
  return (
    <>
      <Grid
        xs={12}
        sx={{
          marginTop: { xs: 9, sm: 14 },
          backgroundColor: 'white',
          pb: 1,
          mb: 5,
        }}
      >
        <Grid
          sx={{
            padding: { xs: '15px', md: '15px 25px' },
            borderBottom: '1px solid #e3eaf3',
          }}
        >
          <CustomSettingsTitle
            title="Front-end Panels"
            subTitle="Widget Translation"
          />
        </Grid>
        <Grid xs={12} style={{ backgroundColor: 'white', marginTop: '20px' }}>
          <Grid container sx={{ padding: { xs: '15px', md: '15px 25px' } }}>
            <Grid container xs={12}>
              <Grid xs={12} sm={mediaQuery1238 ? 4 : 12}>
                <Grid item xs={12} sx={{ marginY: 1 }}></Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    disablePortal
                    options={translationOptions}
                    value={translationValue}
                    sx={{ marginBottom: '2rem', fontSize: '14px' }}
                    onChange={(e, data) => {
                      setTranslationValue(data);
                      setIsSaveBar(true);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} size="small" />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>{' '}
      <SaveSlideBar
        handleCancel={handleCancel}
        handleSave={handleSave}
        isSaveBar={isSaveBar}
        setIsSaveBar={setIsSaveBar}
      />
    </>
  );
};

export default BookingWidgetTranslation;
