export const GET_ALL_DATA = 'GET_ALL_DATA';
export const GET_TODAY_DATA = 'GET_TODAY_DATA';
export const GET_YESTERDAY_DATA = 'GET_YESTERDAY_DATA';
export const GET_TOMORROW_DATA = 'GET_TOMORROW_DATA';
export const GET_THIS_WEEK_DATA = 'GET_THIS_WEEK_DATA';
export const GET_LAST_WEEK_DATA = 'GET_LAST_WEEK_DATA';
export const GET_THIS_MONTH_DATA = 'GET_THIS_MONTH_DATA';
export const GET_THIS_YEAR_DATA = 'GET_THIS_YEAR_DATA';
export const GET_CUSTOM_RANGE_DATA = 'GET_CUSTOM_RANGE_DATA';
export const SET_ACTIVE_STATUS_TAB = 'SET_ACTIVE_STATUS_TAB';
export const SET_FILTER_DATA = 'SET_FILTER_DATA';
export const SET_ACTIVE_DATE = 'SET_ACTIVE_DATE';
