import { Grid, TextField, Typography } from '@mui/material';
import { useState } from 'react';

const NumberOfPeople = ({
  addMaxNumberOfPeopleCustomerCanBook,
  setAddMaxNumberOfPeopleCustomerCanBook,
  setIsSaveBar,
}) => {
  return (
    <>
      <Grid xs={12} sx={{ marginTop: 2 }}>
        <Grid item xs={12} sx={{ marginY: 1 }}>
          <Typography variant="subtitle2">
            Add maximum number of people that a customer can book
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={addMaxNumberOfPeopleCustomerCanBook}
            onChange={(e) => {
              setAddMaxNumberOfPeopleCustomerCanBook(e.target.value);
              setIsSaveBar(true);
            }}
            type="number"
            size="small"
            required
            variant="outlined"
            sx={{
              marginBottom: '2rem',
              width: '100%',
              fontSize: '14px',
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default NumberOfPeople;
