import {
  Autocomplete,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';

const timeFormatForBookingFormOptions = [
  'Show both Start and End time (e.g.: 10:00 - 11:00)',
  'Show only Start time (e.g.: 10:00)',
];
const startTheBookingCalendarFromOption = [
  'Current month',
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const DateAndTime = ({
  timeFormatForBookingForm,
  setTimeFormatForBookingForm,
  startTheBookingCalendarFrom,
  setStartTheBookingCalendarForm,
  hideTheNumberOfAvailableSlots,
  setHideTheNumberOfAvailableSlots,
  setIsSaveBar,
}) => {
  return (
    <>
      <Grid xs={12}>
        <Grid item xs={12} sx={{ marginY: 1 }}>
          <Typography variant="subtitle2">
            The time format for the booking form
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={timeFormatForBookingFormOptions}
            value={timeFormatForBookingForm}
            sx={{ marginBottom: '2rem', fontSize: '14px' }}
            onChange={(e, data) => {
              setTimeFormatForBookingForm(data);
              setIsSaveBar(true);
            }}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
        </Grid>
      </Grid>

      <Grid xs={12}>
        <Grid item xs={12} sx={{ marginY: 1 }}>
          <Typography variant="subtitle2">
            Start the booking calendar from
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={startTheBookingCalendarFromOption}
            value={startTheBookingCalendarFrom}
            sx={{ marginBottom: '2rem', fontSize: '14px' }}
            onChange={(e, data) => {
              setStartTheBookingCalendarForm(data);
              setIsSaveBar(true);
            }}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} sx={{ marginY: 1 }}>
        <Grid
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setHideTheNumberOfAvailableSlots(!hideTheNumberOfAvailableSlots);
            setIsSaveBar(true);
          }}
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            paddingX: 2,
            paddingY: 1,
            height: '40px',
          }}
        >
          <Typography variant="subtitle2" sx={{ lineHeight: '1.2 !important' }}>
            Hide the number of available slots
          </Typography>
          <Switch
            size="small"
            defaultChecked
            sx={{ marginLeft: 2 }}
            onChange={(e) => {
              setHideTheNumberOfAvailableSlots(e.target.checked);
              setIsSaveBar(true);
            }}
            checked={hideTheNumberOfAvailableSlots}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DateAndTime;
