import React, { useEffect, useLayoutEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Drawer from '../../components/Drawer';
import DrawerFooter from '../../components/DrawerFooter';
import { Autocomplete, useMediaQuery, useTheme } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { addTax, editTaxById } from '../../Api/tenant/taxes';
import { getLocationList } from '../../Api/tenant/locations';
import { getServiceList } from '../../Api/tenant/services';
import CustomTextButton from '../../components/CustomButtons/CustomTextButton';

const AddTax = ({
    toggleAddTaxDrawer,
    drawerType,
    drawerData = null,
    fetchTaxes,
}) => {
    const [formData, setFormData] = useState({
        name: '',
        value: '',
        type: '%',
        locations: [],
        services: [],
        enabled: true,
    });
    const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('sm'));
    const matches = useMediaQuery(useTheme().breakpoints.up('sm'));
    const [taxesOptions, setTaxesOptions] = useState({
        locations: [],
        services: [],
    });

    const handleFormData = (fieldName, value) => {
        setFormData((prev) => {
            return {
                ...prev,
                [fieldName]: value,
            };
        });
    };

    useLayoutEffect(() => {
        if (
            drawerType === 'edit' &&
            taxesOptions.locations.length > 0 &&
            taxesOptions.services.length > 0
        ) {
            setFormData({
                ...drawerData,
                locations: taxesOptions.locations.filter((loc) =>
                    drawerData.locations.split(',').includes(`${loc.id}`)
                ),

                services: taxesOptions.services.filter((service) =>
                    drawerData.services.split(',').includes(`${service.id}`)
                ),
            });
        }
    }, [taxesOptions]);

    // API
    // error handling
    const [errors, setErrors] = useState({});
    const requiredFields = ['name', 'value', 'type', 'locations'];

    const fetchRequiredData = async () => {
        const locations = await getLocationList(100, 1);
        const services = await getServiceList(100, 1);

        return {
            locations: locations.data,
            services: services.data,
        };
    };

    useEffect(() => {
        fetchRequiredData().then((data) => {
            setTaxesOptions(data);
        });
    }, []);

    const handleAddTax = async () => {
        let data = formData;
        let err = {};
        for (const key in data) {
            if (!data[key] && requiredFields.includes(key)) {
                err = { ...err, [key]: true };
                console.log('s', data[key], data);
            }
            if (
                Array.isArray(data[key]) &&
                data[key].length === 0 &&
                requiredFields.includes(key)
            ) {
                console.log(data[key]);
                err = { ...err, [key]: true };
            }
        }
        setErrors({ ...err });
        if (Object.keys(err).length === 0) {
            addTax({
                ...formData,
                services: formData.services.map((serv) => serv.id).join(','),
                locations: formData.locations.map((loc) => loc.id).join(','),
            }).then((res) => {
                toggleAddTaxDrawer();
                fetchTaxes();
            });
        }
    };

    const handleEditTax = async () => {
        let data = formData;
        let err = {};
        for (const key in data) {
            if (!data[key] && requiredFields.includes(key)) {
                err = { ...err, [key]: true };
                console.log('s', data[key], data);
            }
            if (
                Array.isArray(data[key]) &&
                data[key].length === 0 &&
                requiredFields.includes(key)
            ) {
                console.log(data[key]);
                err = { ...err, [key]: true };
            }
        }
        setErrors({ ...err });
        if (Object.keys(err).length === 0) {
            editTaxById(
                {
                    ...formData,
                    services: formData.services.map((serv) => serv.id).join(','),
                    locations: formData.locations.map((loc) => loc.id).join(','),
                },
                drawerData.id
            ).then((res) => {
                toggleAddTaxDrawer();
                fetchTaxes();
            });
        }
    };

    return (
        <Grid container>
            {/* sx={{ width: "100vw"}} */}
            <Grid item xs={12} sm={2}>
                <Drawer
                    title={drawerType === 'add' ? 'Add Tax' : 'Edit Tax'}
                    type={drawerType === 'add' ? 'ADD' : 'EDIT'}
                    toggleDrawer={toggleAddTaxDrawer}
                >
                    {/* -----------FORM----- */}
                    <Grid
                        sx={{
                            paddingX: '24px',
                            paddingTop: 3,
                            height: '100%',
                            overflowY: 'auto',
                        }}
                    >
                        <Grid container>
                            <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
                                <Grid item xs={12} sx={{ marginY: 1 }}>
                                    <Typography variant="subtitle2">
                                        Name <span style={{ color: 'red' }}>*</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        size="small"
                                        required
                                        variant="outlined"
                                        sx={{
                                            marginBottom: '0rem',
                                            width: '100%',
                                            fontSize: '14px',
                                        }}
                                        onChange={(e) => {
                                            handleFormData('name', e.target.value);
                                            setErrors({ ...errors, name: false });
                                        }}
                                        value={formData.name}
                                        helperText={errors.name ? 'Value is missing' : ' '}
                                        error={errors.name}
                                    />
                                </Grid>
                            </Grid>
                            <Grid xs={12} sm={6}>
                                <Grid item xs={12} sx={{ marginY: 1 }}>
                                    <Typography variant="subtitle2">
                                        Amount <span style={{ color: 'red' }}>*</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        size="small"
                                        required
                                        variant="outlined"
                                        sx={{
                                            width: '50%',
                                            marginBottom: '0rem',
                                            fontSize: '14px',
                                            '& fieldset': {
                                                borderTopRightRadius: 0,
                                                borderBottomRightRadius: 0,
                                            },
                                        }}
                                        disabled={drawerType !== 'add' ? true : false}
                                        onChange={(e) => {
                                            handleFormData('value', e.target.value);
                                            setErrors({ ...errors, value: false });
                                        }}
                                        value={formData.value}
                                        helperText={errors.value ? 'Value is missing' : ' '}
                                        error={errors.value}
                                    />
                                    <select
                                        style={{
                                            width: matches ? '47%' : '50%',
                                            height: 40,
                                            border: '1px solid rgba(0, 0, 0, 0.23)',
                                            outline: 'none',
                                            borderTopRightRadius: '4px',
                                            borderBottomRightRadius: '4px',
                                            fontSize: '14px',
                                            color: '#292D32',
                                            paddingLeft: 6,
                                        }}
                                        onChange={(e) => {
                                            handleFormData('type', e.target.value);
                                        }}
                                        value={formData.type}
                                    >
                                        <option value="%">%</option>
                                        <option value="$">$</option>
                                    </select>
                                </Grid>
                            </Grid>

                            <Grid xs={12}>
                                <Grid item xs={12} sx={{ marginY: 1 }}>
                                    <Typography variant="subtitle2">
                                        Location filter <span style={{ color: 'red' }}>*</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        multiple
                                        size="small"
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="Select..."
                                                helperText={errors.locations ? 'Value is missing' : ' '}
                                                error={errors.locations}
                                            />
                                        )}
                                        options={taxesOptions.locations.map((option) => option)}
                                        getOptionLabel={(option) => {
                                            return option.name;
                                        }}
                                        isOptionEqualToValue={(o, v) => o == v}
                                        sx={{ marginBottom: '0rem' }}
                                        fullWidth
                                        onChange={(e, v) => {
                                            handleFormData('locations', v);
                                            setErrors({ ...errors, locations: false });
                                        }}
                                        value={formData.locations}
                                    />
                                </Grid>
                            </Grid>

                            <Grid xs={12}>
                                <Grid item xs={12} sx={{ marginY: 1 }}>
                                    <Typography variant="subtitle2">Services filter</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        multiple
                                        size="small"
                                        options={taxesOptions.services.map((option) => option)}
                                        getOptionLabel={(option) => {
                                            return option.name;
                                        }}
                                        isOptionEqualToValue={(o, v) => o == v}
                                        renderInput={(params) => (
                                            <TextField {...params} placeholder="Select..." />
                                        )}
                                        sx={{ marginBottom: '0rem' }}
                                        fullWidth
                                        onChange={(e, v) => {
                                            handleFormData('services', v);
                                        }}
                                        value={formData.services}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* -----Button */}
                    <DrawerFooter>
                        <>
                            <div
                                style={{
                                    display: 'flex',
                                    gap: '15px',
                                    justifyContent: isSmallScreen ? 'space-between' : 'flex-end',
                                    width: '100%',
                                }}
                            >
                                <CustomTextButton
                                    variant="contained"
                                    size="large"
                                    onClick={toggleAddTaxDrawer}
                                >
                                    Cancel
                                </CustomTextButton>
                                <CustomTextButton
                                    type="blue"
                                    variant="contained"
                                    size="large"
                                    onClick={() => {
                                        if (drawerType === 'add') {
                                            handleAddTax();
                                        } else {
                                            handleEditTax();
                                        }
                                    }}
                                >
                                    {drawerType === 'add' ? 'Save' : 'Save'}
                                </CustomTextButton>
                            </div>
                        </>
                    </DrawerFooter>
                </Drawer>
            </Grid>
        </Grid>
    );
};

export default AddTax;