import { Grid } from "@mui/material";
import ChangePasswordSettings from "./ChangePasswordSettings";
import ProfileSettings from "./ProfileSettings";
import Notifications from "./Notifications";

const AccountSettings = ({ subTab, fetchCurrentSettings, currentSettings }) => {
  console.log(subTab);

  return (
    <>
      {subTab === "profile-settings" && (
        <ProfileSettings
          fetchCurrentSettings={fetchCurrentSettings}
          currentSettings={currentSettings}
        />
      )}
      {subTab === "change-password" && (
        <ChangePasswordSettings
          fetchCurrentSettings={fetchCurrentSettings}
          currentSettings={currentSettings}
        />
      )}
      {subTab === "notifications" && (
        <Notifications
          fetchCurrentSettings={fetchCurrentSettings}
          currentSettings={currentSettings}
        />
      )}
    </>
  );
};

export default AccountSettings;
