import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import SellIcon from '@mui/icons-material/Sell';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import Drawer from '../../components/Drawer';
import DrawerFooter from '../../components/DrawerFooter';
import { SERVER_URL } from './configs';
import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  OutlinedInput,
  Select,
  Switch,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { data } from './tagsData';
import CustomTextButton from '../../components/CustomButtons/CustomTextButton';

// event filters
const statusFilter = [
  'Pending',
  'Approved',
  'Canceled',
  'Rejected',
  'Completed',
  'No show',
  'Waiting for payment',
];
const locationFilter = ['California', 'Los Angeles', 'New York'];
const serviceFilter = [
  'Oral hygiene',
  'Tooth whitening',
  'Implants',
  '3D/4D UltraSound',
  'OB/GYN',
  'OB/GYN',
  'Maternity',
  'Buttock Lift',
  'Liposuction',
  'Fat transfer',
];
const staffFilter = [
  'Ronald Aigbobo',
  'Mark',
  'Joseph',
  'Thomas',
  'Rihma',
  'David',
  'Dennis',
];

const localeFilter = ['AR', 'EN'];

// action filter

const toFilter = ['Customer email', 'Staff email'];
const attachmentFilter = ['Invoice:Invoice [URL]', 'Invoice:Invoice [PATH]'];

const EditWorkflow = ({
  toggleEditWorkflowDrawer,
  drawerType,
  drawerData = null,
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [planId, setPlanId] = useState('');
  const [expiry, setExpiry] = useState(new Date());
  const [allPlans, setAllPlans] = useState([]);
  const [triggerCheckbox, setTriggerCheckbox] = useState(false); // trigger for each customer checkbox
  // menu
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterMenuList, setFilterMenuList] = useState([]);
  const [menuFilterInput, setMenuFilterInput] = useState('');
  const openMenu = Boolean(anchorEl);
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [formData, setFormData] = useState({
    statusFilter: [],
    locationFilter: [],
    staffFilter: [],
    serviceFilter: [],
    localeFilter: [],
    bookingOffset: {
      offsetPosition: 'Before',
      offset: 2,
      offsetType: 'Hour',
    },
    toFilter: [],
    subject: '',
    body: '',
    attachmentFilter: [],
  });

  const [isEnabled, setIsEnabled] = useState(false);

  const matches = useMediaQuery(useTheme().breakpoints.up('sm'));

  const handleFormData = (fieldName, value) => {
    setFormData({
      ...formData,
      [fieldName]: typeof value === 'string' ? value.split(',') : value,
    });
  };

  const fetchPlans = async () => {
    const response = await axios.post(SERVER_URL + '/get-plan');
    const plansData = response.data.data.map((plan) => {
      plan.label = plan.name;
      return plan;
    });
    setAllPlans(plansData);
  };

  // const addWorkflow = async () => {
  //   if (email && password && fullName && planId && expiry) {
  //     const data = {
  //       email: email,
  //       password: password,
  //       fname: fullName,
  //       plan: planId,
  //       expiry: expiry,
  //     };
  //     const response = await axios.post(SERVER_URL + "/add-workflow", data);
  //     toggleEditWorkflowDrawer();
  //   }
  // };

  // const updateWorkflow = async () => {
  //   if (email && fullName && planId && expiry && drawerData.id) {
  //     const data = {
  //       workflow_id: drawerData.id,
  //       email: email,
  //       password: password,
  //       fname: fullName,
  //       plan: planId,
  //       expiry: expiry,
  //     };
  //     const response = await axios.post(SERVER_URL + "/update-workflow", data);
  //     toggleEditWorkflowDrawer();
  //   }
  // };

  // const getPlanNameFromId = (id) => {
  //   let name = "";
  //   allPlans.forEach((plan) => {
  //     if (plan.id === id) {
  //       name = plan.name;
  //     }
  //   });
  //   return name;
  // };

  const getPlanIdFromName = (name) => {
    let id = '';
    allPlans.forEach((plan) => {
      if (plan.name === name) {
        id = plan.id;
      }
    });
    return id;
  };

  useEffect(() => {
    fetchPlans();
    setEmail(drawerType.type !== 'add' ? drawerData.email : '');
    setPassword('');
    setFullName(drawerType.type !== 'add' ? drawerData.fullName : '');
    setExpiry(drawerType.type !== 'add' ? drawerData.expiry : new Date());
    setPlanId('');
  }, []);

  useEffect(() => {
    if (drawerType.type !== 'add') {
      setPlanId(
        drawerType.type !== 'add' ? getPlanIdFromName(drawerData.plan) : ''
      );
    }
  }, [allPlans]);

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  // const handlePassword = (e) => {
  //   setPassword(e.target.value);
  // };
  // const handleFullName = (e) => {
  //   setFullName(e.target.value);
  // };
  // const handlePlan = (e, selectedData) => {
  //   if (selectedData && e) {
  //     setPlanId(selectedData.id);
  //   }
  // };
  // const handleExpiry = (e) => {
  //   setExpiry(e);
  // };

  // ----------- menu ------------

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuFilterInput('');
  };

  const handleMenuFilterInput = (e) => {
    const value = e.target.value;

    if (value.length === 0) {
      switch (anchorEl.getAttribute('name')) {
        case 'Keywords':
          setFilterMenuList(data);
          break;
      }
    } else {
      setFilterMenuList((prevState) =>
        prevState.filter((prevValue) => prevValue.value.startsWith(value))
      );
    }

    setMenuFilterInput(e.target.value);
  };

  const handleMenuItemClick = (value) => {
    const menuName = anchorEl.getAttribute('name');

    setFormData({ ...formData, subject: formData.subject + ' ' + value });

    handleMenuClose();
  };

  const handleMenuClick = (event) => {
    const menuName = event.currentTarget.getAttribute('name');
    console.log(menuName);
    switch (menuName) {
      case 'Keywords':
        setFilterMenuList(data);
        break;
    }

    setAnchorEl(event.currentTarget);
  };

  // ------------ backend handlers -------------

  const updateEvent = () => {
    // triggerCheckbox
    // formData
  };

  const updateAction = () => {
    // triggerCheckbox
    // formData
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={2}>
        <Drawer
          title={drawerType.type === 'EVENT' ? 'Edit event' : 'Edit Action'}
          type={'EDIT'}
          toggleDrawer={toggleEditWorkflowDrawer}
        >
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={openMenu}
            onClose={handleMenuClose}
            elevation={0}
            MenuListProps={{
              sx: {
                width: '440px',
                height: '350px',
                overflowY: 'scroll',
                padding: 0,
                border: '1px solid #E4EBF4',
                borderRadius: '4px',
                paddingTop: '10px',
              },
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <input
                value={menuFilterInput}
                onChange={handleMenuFilterInput}
                type="text"
                style={{
                  border: '1px solid #e3eaf3',
                  outline: 'none',
                  fontSize: '14px',
                  borderRadius: '2px',
                  height: '1.5rem',
                  width: '95%',
                }}
              />
            </div>
            <div>
              <MenuList dense>
                {filterMenuList.map(({ value, name }, index) => (
                  <MenuItem
                    sx={{
                      ':hover': {
                        backgroundColor: '#adbfc7',
                        color: 'white',
                      },
                    }}
                    key={`filter-${index}`}
                    onClick={() => handleMenuItemClick(value)}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '440px',
                      }}
                    >
                      <Typography fontSize={12} sx={{ wordBreak: 'break-all' }}>
                        {name.split(',')[0]} <br /> {name.split(',')[1]}
                      </Typography>
                      <Typography fontSize={12}>{value}</Typography>
                    </Box>
                  </MenuItem>
                ))}
              </MenuList>
            </div>
          </Menu>

          {/* -----------FORM----- */}
          <Grid
            sx={{
              paddingX: '24px',
              paddingTop: 3,
              height: '100%',
              overflowY: 'auto',
              width: '100%',
            }}
          >
            {/* only booking event type */}
            {drawerType.type === 'EVENT' &&
              drawerType.eventType === 'BOOKING' && (
                <Grid container sx={{ mb: '16px' }}>
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">Offset</Typography>
                  </Grid>
                  <Grid xs={12} sx={{ display: 'flex' }}>
                    <Grid xs={4}>
                      <FormControl
                        sx={{
                          m: 0,
                          minWidth: 120,
                          width: '100%',
                        }}
                      >
                        <Select
                          sx={{ height: '40px' }}
                          value={formData.bookingOffset.offsetPosition}
                          onChange={(e) => {
                            handleFormData('bookingOffset', {
                              ...formData.bookingOffset,
                              offsetPosition: e.target.value,
                            });
                          }}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                        >
                          <MenuItem value={'Before'}>Before</MenuItem>
                          <MenuItem value={'After'}>After</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                      <TextField
                        type="number"
                        id="outlined-basic"
                        size="small"
                        variant="outlined"
                        sx={{
                          m: 0,
                          width: '100%',
                          fontSize: '14px',
                          height: '40px',
                        }}
                        inputProps={{
                          min: 0,
                          style: {
                            height: '23px',
                            p: 0,
                            m: 0,
                          },
                        }}
                        value={formData.bookingOffset.offset}
                        onChange={(e) => {
                          handleFormData('bookingOffset', {
                            ...formData.bookingOffset,
                            offset: e.target.value,
                          });
                        }}
                      />
                    </Grid>
                    <Grid xs={4}>
                      <FormControl sx={{ m: 0, minWidth: 120, width: '100%' }}>
                        <Select
                          sx={{ height: '40px' }}
                          value={formData.bookingOffset.offsetType}
                          onChange={(e) => {
                            handleFormData('bookingOffset', {
                              ...formData.bookingOffset,
                              offsetType: e.target.value,
                            });
                          }}
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                        >
                          <MenuItem value={'Minut'}>Minut</MenuItem>
                          <MenuItem value={'Hour'}>Hour</MenuItem>
                          <MenuItem value={'Day'}>Day</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            {/* all events drawer type */}
            {drawerType.type === 'EVENT' && (
              <Grid container>
                <Grid xs={12}>
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">Status filter</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Select
                      multiple
                      value={formData.statusFilter}
                      xs={12}
                      sx={{
                        marginBottom: '1rem',
                        fontSize: '14px',
                        width: matches ? '98.5%' : '100%',
                      }}
                      onChange={(e) => {
                        handleFormData('statusFilter', e.target.value);
                      }}
                      input={<OutlinedInput size="small" />}
                      renderValue={(selected) => (
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 0.5,
                          }}
                        >
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                    >
                      {statusFilter.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox
                            checked={formData.statusFilter.indexOf(name) > -1}
                          />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>

                <Grid xs={12}>
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">
                      Locations filter
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Select
                      multiple
                      value={formData.locationFilter}
                      xs={12}
                      sx={{
                        marginBottom: '1rem',
                        fontSize: '14px',
                        width: matches ? '98.5%' : '100%',
                      }}
                      onChange={(e) => {
                        handleFormData('locationFilter', e.target.value);
                      }}
                      input={<OutlinedInput size="small" />}
                      renderValue={(selected) => (
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 0.5,
                          }}
                        >
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                    >
                      {locationFilter.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox
                            checked={formData.locationFilter.indexOf(name) > -1}
                          />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
                <Grid xs={12}>
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">Service filter</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Select
                      multiple
                      value={formData.serviceFilter}
                      xs={12}
                      sx={{
                        marginBottom: '1rem',
                        fontSize: '14px',
                        width: matches ? '98.5%' : '100%',
                      }}
                      onChange={(e) => {
                        handleFormData('serviceFilter', e.target.value);
                      }}
                      input={<OutlinedInput size="small" />}
                      renderValue={(selected) => (
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 0.5,
                          }}
                        >
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                    >
                      {serviceFilter.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox
                            checked={formData.serviceFilter.indexOf(name) > -1}
                          />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
                <Grid xs={12}>
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">Staff filter</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Select
                      multiple
                      value={formData.staffFilter}
                      xs={12}
                      sx={{
                        marginBottom: '1rem',
                        fontSize: '14px',
                        width: matches ? '98.5%' : '100%',
                      }}
                      onChange={(e) => {
                        handleFormData('staffFilter', e.target.value);
                      }}
                      input={<OutlinedInput size="small" />}
                      renderValue={(selected) => (
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 0.5,
                          }}
                        >
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                    >
                      {staffFilter.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox
                            checked={formData.staffFilter.indexOf(name) > -1}
                          />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
                <Grid xs={12}>
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">Locale filter</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Select
                      multiple
                      value={formData.localeFilter}
                      xs={12}
                      sx={{
                        marginBottom: '1rem',
                        fontSize: '14px',
                        width: matches ? '98.5%' : '100%',
                      }}
                      onChange={(e) => {
                        handleFormData('localeFilter', e.target.value);
                      }}
                      input={<OutlinedInput size="small" />}
                      renderValue={(selected) => (
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 0.5,
                          }}
                        >
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                    >
                      {localeFilter.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox
                            checked={formData.localeFilter.indexOf(name) > -1}
                          />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {drawerType.type === 'EVENT' &&
              drawerType.eventType === 'BOOKING' && (
                <Grid container>
                  <Grid xs={12}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) => {
                              setTriggerCheckbox(e.target.checked);
                            }}
                            checked={triggerCheckbox}
                          />
                        }
                        label="Trigger for each customer (in group booking) "
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              )}
            {/* if Action */}
            {drawerType.type === 'ACTION' && (
              <Grid>
                <Grid xs={12}>
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">To</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Select
                      multiple
                      value={formData.toFilter}
                      xs={12}
                      sx={{
                        marginBottom: '1rem',
                        fontSize: '14px',
                        width: matches ? '98.5%' : '100%',
                      }}
                      onChange={(e) => {
                        handleFormData('toFilter', e.target.value);
                      }}
                      input={<OutlinedInput size="small" />}
                      renderValue={(selected) => (
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 0.5,
                          }}
                        >
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                    >
                      {toFilter.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox
                            checked={formData.toFilter.indexOf(name) > -1}
                          />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
                <Grid xs={12}>
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">Subject</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-basic"
                      size="small"
                      required
                      variant="outlined"
                      sx={{
                        marginBottom: '1rem',
                        width: '100%',
                        fontSize: '14px',
                      }}
                      value={formData.subject}
                      type="email"
                      onChange={(e) => {
                        handleFormData('subject', e.target.value);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            sx={{ cursor: 'pointer' }}
                            onClick={handleMenuClick}
                            name={'Keywords'}
                          >
                            <SellIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid xs={12}>
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">{`Attachment(s)`}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Select
                      multiple
                      value={formData.attachmentFilter}
                      xs={12}
                      sx={{
                        marginBottom: '1rem',
                        fontSize: '14px',
                        width: matches ? '98.5%' : '100%',
                      }}
                      onChange={(e) => {
                        handleFormData('attachmentFilter', e.target.value);
                      }}
                      input={<OutlinedInput size="small" />}
                      renderValue={(selected) => (
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 0.5,
                          }}
                        >
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                    >
                      {attachmentFilter.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox
                            checked={
                              formData.attachmentFilter.indexOf(name) > -1
                            }
                          />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>

          {/* -----Button */}
          <DrawerFooter>
            <>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: isSmallScreen ? 'flex-start' : 'normal',
                }}
              >
                <Checkbox
                  size="small"
                  checked={true}
                  color="success"
                  sx={{
                    marginBottom: { xs: 1, sm: 0 },
                  }}
                />
                <Typography
                  variant="subtitle2"
                  sx={{
                    marginLeft: { sm: 0, xs: -1 },
                    marginBottom: { xs: 1, sm: 0 },
                  }}
                >
                  Enabled
                </Typography>
              </div>

              <div
                style={{
                  display: 'flex',
                  gap: '15px',
                  justifyContent: isSmallScreen ? 'space-between' : 'flex-end',
                  width: isSmallScreen ? '100%' : 'auto',
                  alignItems: 'center',
                }}
              >
                <CustomTextButton
                  variant="contained"
                  size="large"
                  onClick={toggleEditWorkflowDrawer}
                >
                  Cancel
                </CustomTextButton>
                <CustomTextButton
                  type="blue"
                  variant="contained"
                  size="large"
                  onClick={() => {
                    if (drawerType.type === 'EVENT') {
                      updateEvent();
                    } else {
                      updateAction();
                    }
                  }}
                >
                  Save
                </CustomTextButton>
              </div>
            </>
          </DrawerFooter>
        </Drawer>
      </Grid>
    </Grid>
  );
};

export default EditWorkflow;
