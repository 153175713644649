import GoogleCalendar from "./GoogleCalendar";
import SMSTwilio from "./SMSTwilio";

const IntegrationsSettings = ({
  subTab,
  fetchCurrentSettings,
  currentSettings,
}) => {
  return (
    <>
      {subTab === "sms-twilio" && (
        <SMSTwilio
          fetchCurrentSettings={fetchCurrentSettings}
          currentSettings={currentSettings}
        />
      )}
      {subTab === "google-calendar" && (
        <GoogleCalendar
          fetchCurrentSettings={fetchCurrentSettings}
          currentSettings={currentSettings}
        />
      )}
    </>
  );
};

export default IntegrationsSettings;
