import { useEffect, useState } from "react";
import { getUserById } from "../Api/common/users";

const useUser = () => {
  const [userObject, setUserObject] = useState({});
  const [isUserLoading, setIsUserLoading] = useState(true);

  useEffect(() => {
    function checkUserData() {
      const user = localStorage.getItem("user");

      setUserObject(JSON.parse(user));
    }

    checkUserData();

    window.addEventListener("storage", checkUserData);

    return () => {
      window.removeEventListener("storage", checkUserData);
    };
  }, []);

  useEffect(() => {
    setIsUserLoading(false);
  }, [userObject]);

  const refetchUser = () => {
    getUserById(userObject?.id).then((newUserObject) => {
      localStorage.setItem("user", JSON.stringify(newUserObject.data));
      window.dispatchEvent(new Event("storage"));
    });
  };

  return { user: userObject, refetchUser, isUserLoading };
};
export default useUser;
