import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {
  DataGrid,
  GridActionsCellItem,
  GridMoreVertIcon,
} from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import axios from 'axios';
import SearchIcon from '@mui/icons-material/Search';
import { SERVER_URL } from './configs';
import CustomTableFooter from '../../components/CustomTableFooter';
import Modal from '../../components/Modal';
import UsageHistory from './UsageHistory';
import AddRoles from './AddRoles';
import ShareModal from '../../components/ShareModal';
import CustomTextButton from '../../components/CustomButtons/CustomTextButton';
import useFilterBar from '../../hooks/useFilterBar';
import { getQueries } from '../../utils/GetQueries';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { useMediaQuery, useTheme } from '@mui/material';
import NoData from '../../components/NoData';

const filters = [
  {
    id: 1,
    title: 'Quick search',
    type: 'search',
    queryLabel: 'keyword',
  },
];

const Roles = () => {
  const [categoryies, setCategoryies] = useState([
    { label: 'Dental Clinic' },
    { label: 'Gynecology' },
    { label: 'Body' },
    { label: 'Shin & Hear' },
  ]);
  const [addCouponDrawer, setAddCouponDrawer] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [drawerType, setDrawerType] = useState('add');
  const [drawerData, setDrawerData] = useState(null);
  const [openModal, setOpenModal] = useState({ open: false, type: null });
  const [selectionModel, setSelectionModel] = useState([]);
  const [drawer, setDrawer] = useState(null);
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('lg'));
  const { FilterBar, state, FilterButton } = useFilterBar({
    filters,
  });

  const toggleAddCouponDrawer = () => {
    setAddCouponDrawer(!addCouponDrawer);
  };

  const fetchCoupons = async () => {
    try {
      const response = await axios.post(SERVER_URL + '/get-coupon');
      //setCoupons(response.data.data);
    } catch (error) {}
  };

  const deleteCoupon = async (id) => {
    try {
      const response = await axios.post(SERVER_URL + '/delete-coupon', {
        coupon_id: id,
      });
      fetchCoupons();
    } catch (error) {}
  };

  const handleOpenModal = () => {
    setOpenModal({ open: false, type: null });
  };
  // delete child
  const handleDeleteOpenModal = () => {
    setOpenModal({ open: true, type: 'delete-modal' });
  };

  const toggleUsageHistoryModal = (e) => {
    e.stopPropagation();
    setDrawer((prevState) =>
      prevState !== 'usage-history' ? 'usage-history' : ''
    );
  };

  const columns = [
    {
      field: 'name',
      headerName: 'NAME',
      minWidth: 280,
      flex: 3,
    },
    {
      field: 'actions',
      headerName: 'ACTIONS',
      type: 'actions',
      align: 'right',
      minWidth: 100,
      maxWidth: 100,

      flex: 1,
      getActions: (params) => [
        <GridActionsCellItem
          label="Edit"
          onClick={() => {
            setDrawerType('edit');
            setDrawerData({
              id: params.row.id,
              fullName: params.row.full_name,
              email: params.row.email,
              plan: params.row.plan,
              expiry: params.row.expiry,
            });
            toggleAddCouponDrawer();
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Delete"
          onClick={() => {
            setOpenModal({ open: true, type: 'delete-modal' });
            deleteCoupon(params.id);
          }}
          showInMenu
        />,
      ],
    },
  ];

  const row = [
    {
      id: 1,
      name: 'Fat Transfer',
    },
    {
      id: 2,
      name: 'Brain Test',
    },
  ];
  const [rows, setRows] = useState(row);
  return (
    <Grid
      item
      container
      sx={{
        minWidth: { xs: '100%' },
        px: '20px',
      }}
    >
      {openModal.type === 'delete-modal' && (
        <Modal
          handleOpenModal={handleOpenModal}
          type={'DELETE'}
          open={openModal.open}
        />
      )}

      {addCouponDrawer && (
        <AddRoles
          toggleAddCouponDrawer={toggleAddCouponDrawer}
          drawerType={drawerType}
          drawerData={drawerData}
          categoryies={categoryies}
          setCategoryies={setCategoryies}
        />
      )}
      {drawer === 'usage-history' && (
        <UsageHistory toggleDrawer={toggleUsageHistoryModal} />
      )}
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          // alignItems: 'flex-end',
          flexWrap: 'wrap',
        }}
      >
        <Typography
          sx={{
            marginTop: 2.5,
            marginBottom: 1,
            fontSize: 24,
            color: '#3A3A50',
          }}
        >
          Roles
        </Typography>
        {/* <Box sx={{ width: { xs: '100%', sm: 'auto' } }}>
          <Button
            size="large"
            sx={{
              width: { xs: '100%', sm: 'auto' },
              marginTop: { xs: 1, sm: 5 },
              marginBottom: 1,
              marginLeft: { xs: 0, sm: 2 },
            }}
            variant="contained"
            onClick={() => {
              setDrawerType('addRole');
              toggleAddCouponDrawer();
            }}
          >
            + ADD Role
          </Button>
        </Box> */}
      </Grid>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <CustomTextButton
          text="+ Add role"
          onClick={() => {
            setDrawerType('addRole');
            toggleAddCouponDrawer();
          }}
        />
        <Box sx={{ display: 'flex', gap: '10px', ml: '10px' }}>
          {FilterButton}
        </Box>
      </Box>
      {/* --------------------SEARCH------------------------------- */}
      {FilterBar}
      {/* --------------------TABLE--------------------------------- */}
      <Grid
        container
        item
        justifyContent="space-between"
        sx={{ marginTop: '20px' }}
      >
        <div style={{ width: '100%' }}>
          <DataGrid
            sx={{
              border: 0,
              '& .MuiDataGrid-virtualScroller': {
                minHeight: '300px !important',
              },
              '& .MuiDataGrid-columnHeader': {
                backgroundColor: '#EFF1FC',
                color: '#3A3A50',
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                fontSize: '12px',
                fontWeight: '900 !important',
              },
              '& .MuiDataGrid-main, .MuiDataGrid-footerContainer': {
                backgroundColor: 'white',
                border: 'none',
              },
              minHeight: '60vh',
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
            }}
            rows={rows}
            columns={columns}
            // checkboxSelection
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            autoHeight
            selectionModel={selectionModel}
            components={{
              NoRowsOverlay: NoData,
              NoResultsOverlay: NoData,
              Footer: () => (
                <CustomTableFooter
                  selectionModel={selectionModel}
                  handleDeleteOpenModal={handleDeleteOpenModal}
                />
              ),
              MoreActionsIcon: () => (
                <GridMoreVertIcon
                  sx={{
                    backgroundColor: 'white',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
                    borderRadius: '4px',
                    padding: '3px',
                    cursor: 'pointer',
                  }}
                />
              ),
              NoRowsOverlay: () => {
                return (
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'Center',
                      gap: '20px',
                    }}
                  >
                    <img
                      src="https://user-images.githubusercontent.com/109676648/254145031-57027cd6-fecd-4ce2-96fc-9d891c8de53a.svg"
                      alt="no data"
                      style={{
                        width: '100px',
                        height: '100px',
                      }}
                    />
                    No data at the moment 😔
                  </Box>
                );
              },
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default Roles;
