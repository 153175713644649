import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import noPhotoUser from '../../assets/images/no-photo-user.png';
import { useState } from 'react';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';

const createData = (customer, usedAmount, service, date, info) => {
  return { customer, usedAmount, service, date, info };
};

const UsageHistoryTable = ({ data }) => {
  const [rows, setRows] = useState([data]);

  return (
    <Grid sx={{ overflowX: 'auto' }}>
      <TableContainer sx={{ minWidth: '700px' }} component={Paper}>
        <Table
          aria-label="simple table"
          sx={{
            background:
              'linear-gradient(to right,#c0d6df 50%,transparent 0%),linear-gradient(#c0d6df 50%,transparent 0%),linear-gradient(to right,#c0d6df 50%,transparent 0%),linear-gradient(#c0d6df 50%,transparent 0%)',
            backgroundPosition: 'top,right,bottom,left',
            backgroundRepeat: 'repeat-x,repeat-y',
            backgroundSize: '8px 1px,1px 8px',
          }}
        >
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: '#adbfc7',
                padding: '10px 0 10px 20px',
                fontSize: '12px',
                fontWeight: '600',
              }}
            >
              <TableCell
                sx={{
                  color: '#fff',
                  fontSize: '12px',
                  paddingY: '10px',
                  fontWeight: '600',
                }}
              >
                CUSTOMER
              </TableCell>
              <TableCell
                sx={{
                  color: '#fff',
                  fontSize: '12px',
                  paddingY: '10px',
                  fontWeight: '600',
                }}
                align="center"
              >
                USED AMOUNT
              </TableCell>
              <TableCell
                sx={{
                  color: '#fff',
                  fontSize: '12px',
                  paddingY: '10px',
                  fontWeight: '600',
                }}
                align="center"
              >
                SERVICE
              </TableCell>
              <TableCell
                sx={{
                  color: '#fff',
                  fontSize: '12px',
                  paddingY: '10px',
                  fontWeight: '600',
                }}
                align="center"
              >
                DATE
              </TableCell>
              <TableCell
                sx={{
                  color: '#fff',
                  fontSize: '12px',
                  paddingY: '10px',
                  fontWeight: '600',
                }}
                align="center"
              >
                INFO
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ fontSize: '14px', paddingY: '20px' }}
                >
                  <Grid container>
                    <Grid
                      sx={{
                        borderRadius: '50%',
                        width: '42px',
                        height: '42px',
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        background: 'rgba(255,255,255,.8)',
                        flexShrink: '0',
                      }}
                    >
                      <img
                        src={row.customer.User.image || noPhotoUser}
                        style={{
                          minWidth: '100%',
                          minHeight: '100%',
                          objectFit: 'cover',
                          verticalAlign: 'middle',
                          borderStyle: 'none',
                        }}
                      />
                    </Grid>

                    <Grid ml={1}>
                      <Typography variant="subtitle2" color="#292d32">
                        {row.customer.User.firstName}
                      </Typography>
                      <Typography variant="caption" color="#95a0a9">
                        {row.customer.User.email}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: '14px', paddingY: '20px' }}
                >
                  {row.usedAmount}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: '14px', paddingY: '20px' }}
                >
                  {row.appointment.service.name}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: '14px', paddingY: '20px' }}
                >
                  {row.appointment.createdAt}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: '14px', paddingY: '20px' }}
                >
                  <ReceiptOutlinedIcon
                    sx={{ color: '#adbfc7', cursor: 'pointer' }}
                    onClick={() => row.info}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default UsageHistoryTable;
