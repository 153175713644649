import dayjs from "dayjs";
import { uniqueId } from 'lodash';

export const useTimesheet = ({timesheets, setTimesheets, handleSaveBar = null}) => {

    const handleChange = (value, dayId, fieldName) => {
        setTimesheets((prev) =>
          prev.map((day) => {
            if (day.id !== dayId) return day;
    
            day.breaks = day.breaks.map(breakTime => {
              const breakTo = dayjs(value, "mm:ss") > dayjs(breakTime.to, "mm:ss") ? value : breakTime.to
              if(fieldName === "from" && dayjs(breakTime.from, "mm:ss") < dayjs(value, "mm:ss"))  return {...breakTime, from: value, to: breakTo}
              if(fieldName === "to" && dayjs(breakTime.to, "mm:ss") > dayjs(value, "mm:ss")) return {...breakTime, to: value}
              else return breakTime
            })
    
            if (
              fieldName === 'from' &&
              dayjs(value, 'mm:ss') > dayjs(day.to, 'mm:ss')
            )
              return { ...day, from: value, to: value };
    
            return { ...day, [fieldName]: value };
          })
        );
        if(handleSaveBar) handleSaveBar(true);
      };

      const handleDayOff = (dayId) => {
        setTimesheets((prev) =>
          prev.map((day) => {
            if (day.id !== dayId) return day;
    
            return { ...day, dayOff: !day.dayOff };
          })
        );
        if(handleSaveBar) handleSaveBar(true);
      };

      const addBreakToDayData = (dayId) => {
        const getDayData = timesheets.find(day => day.id === dayId)
        const newBreak = {
          id: uniqueId('breaks-'),
          from: getDayData.from,
          to: getDayData.to,
        };
        setTimesheets((prev) =>
          prev.map((day) => {
            if (day.id !== dayId) return day;
    
            return { ...day, breaks: [...day.breaks, newBreak] };
          })
        );
        if(handleSaveBar) handleSaveBar(true);
      };


      const handleUpdateBreakTimes = (attr, breakId, dayId) => (e, v) => {
        setTimesheets((prev) => prev.map((day) => {
            if (day.id !== dayId) return day;
            return {
              ...day,
              breaks: day.breaks.map((breakTime) => {
                if (breakTime.id !== breakId) return breakTime;
    
                if (
                  attr === 'from' &&
                  dayjs(v, 'mm:ss') > dayjs(breakTime.to, 'mm:ss')
                )
                  return { ...breakTime, from: v, to: v };
    
                return { ...breakTime, [attr]: v };
              }),
            };
          }),
        );
        if(handleSaveBar) handleSaveBar(true);
      };

      const deleteBreak = (dayId, breakId) => {
        setTimesheets((prev) => prev.map((day) => {
            if (day.id !== dayId) return day;
    
            return {
              ...day,
              breaks: day?.breaks?.filter((breakTime) => breakTime.id !== breakId),
            };
          }),
        );
        if(handleSaveBar) handleSaveBar(true);
      };

    return {
        handleChange,
        handleDayOff,
        addBreakToDayData,
        handleUpdateBreakTimes,
        deleteBreak
    }
}