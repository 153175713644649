import {
  Autocomplete,
  Box,
  Checkbox,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';

import {} from '@mui/material';
import React, { useLayoutEffect, useState } from 'react';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SaveSlideBar from '../../SaveSlideBar';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from 'react-redux';
import CustomSettingsTitle from '../../CustomSettingsTitle/CustomSettingsTitle';
import { uniqueId } from 'lodash';
import { timesList } from '../../../constants/timesList';
import dayjs from 'dayjs';
import { useTimesheet } from '../../../hooks/useTimesheet';
import { editSettings } from '../../../Api/tenant/settings';
import { fetchUser } from '../../../redux/slices/user.slice.js';

/*
dayObj
  {
    id: 1,
    day: 'Sunday',
    startTime: '',
    endTime: '',
    dayOff: false,
    breaks: [{
      id: 1,
      breakFrom: "",
      breakTo: ""
  }]
  }
*/

const dayList = [
  {
    id: uniqueId('day-info-'),
    day: 'Sunday',
    from: '01:00',
    to: '02:30',
    dayOff: false,
    breaks: [],
  },
  {
    id: uniqueId('day-info-'),
    day: 'Monday',
    from: '01:00',
    to: '02:30',
    dayOff: false,
    breaks: [],
  },
  {
    id: uniqueId('day-info-'),
    day: 'Tuesday',
    from: '01:00',
    to: '02:30',
    dayOff: false,
    breaks: [],
  },
  {
    id: uniqueId('day-info-'),
    day: 'Wednesday',
    from: '01:00',
    to: '02:30',
    dayOff: false,
    breaks: [],
  },
  {
    id: uniqueId('day-info-'),
    day: 'Thursday',
    from: '01:00',
    to: '02:30',
    dayOff: false,
    breaks: [],
  },
  {
    id: uniqueId('day-info-'),
    day: 'Friday',
    from: '01:00',
    to: '02:30',
    dayOff: false,
    breaks: [],
  },
  {
    id: uniqueId('day-info-'),
    day: 'Saturday',
    from: '01:00',
    to: '02:30',
    dayOff: false,
    breaks: [],
  },
];

const BusinessHours = ({ fetchCurrentSettings, currentSettings }) => {
  const dispatch = useDispatch()
  const [timesheets, setTimesheets] = useState(currentSettings?.timesheets);
  const [isSaveBar, setIsSaveBar] = useState(false);
  const {
    handleChange,
    handleDayOff,
    addBreakToDayData,
    handleUpdateBreakTimes,
    deleteBreak,
  } = useTimesheet({ timesheets, setTimesheets, handleSaveBar: setIsSaveBar });

  // API

  useLayoutEffect(() => {
    // will fetch timesheet by id
    /*
    getTimesheetById(currentSettings.TimesheetId).then((res) => {
      const fetchedTimesheet = res.data;

      const splitedFromValues = fetchedTimesheet.from.split('-');
      const splitedToValues = fetchedTimesheet.to.split('-');

      let newDayList = [...dayList];

      const timesheetObj = newDayList.map((day, i) => {
        if (splitedFromValues[i].split('/')[1] !== 'DF') {
          console.log({
            ...day,
            startTime: splitedFromValues[i].split('/')[1],
            endTime: splitedToValues[i].split('/')[1],
          });
          return {
            ...day,
            startTime: convertTime24to12(splitedFromValues[i].split('/')[1]),
            endTime: convertTime24to12(splitedToValues[i].split('/')[1]),
          };
        } else {
          return {
            ...day,
            startTime: 'day off',
            endTime: 'day off',
            isDayOff:
              splitedFromValues[i].split('/')[1] === 'DF' ? true : false,
          };
        }
      });
      console.log(timesheetObj);
      setWeeklyData({ timesheet: true, dayData: [...timesheetObj] });
    });
    */
  }, [currentSettings.TimesheetId]);

  // click handlers
  const handleSave = async () => {

    const newTimesheets = timesheets.map(timesheet => {  
      timesheet.breaks = timesheet.breaks.map((breakTime) => {
        if(breakTime?.id?.toString().includes("breaks")) delete breakTime.id;
        return breakTime
      })
      if(timesheet?.id?.toString().includes("day-info-")) delete timesheet.id;
      return timesheet;
    })
    
    editSettings({
      ...currentSettings,
      timesheets: newTimesheets,
    }).then((res) => {
      if (res.status === 200) {
        fetchCurrentSettings();
        dispatch(fetchUser())
          .unwrap()
          .then((res) => {
            setIsSaveBar(false);
          });
      }
    });
  };

  const handleCancel = () => {
    setTimesheets(currentSettings.timesheets)
    setIsSaveBar(false);
  };

  return (
    <>
      <Grid
        xs={12}
        sx={{
          marginTop: { xs: 9, sm: 14 },
          backgroundColor: 'white',
          pb: 1,
          mb: 5,
        }}
      >
        <Grid
          sx={{
            padding: { xs: '15px', md: '15px 25px' },
            borderBottom: '1px solid #e3eaf3',
          }}
        >
          <CustomSettingsTitle title="Company" subTitle="Business hours" />
        </Grid>

        <Grid xs={12} sm={12} sx={{ py: { sm: 2 } }}>
          {timesheets &&
            timesheets.map((data, i) => (
              <Grid
                container
                xs={12}
                sm={12}
                key={data.id}
                item
                sx={
                  i !== 0
                    ? {
                        borderTop: '1px solid #e3eaf3',
                        paddingY: '20px',
                        paddingX: '45px',
                      }
                    : { paddingBottom: '20px', paddingX: '45px' }
                }
              >
                <Typography
                  variant="subtitle1"
                  color="#585cba"
                  fontSize="14px"
                  fontWeight="bold"
                  marginBottom="10px"
                  marginTop="1rem"
                >
                  {i + 1}. {data.day}
                </Typography>
                <Grid xs={12} sm={12} container>
                  <Grid
                    xs={12}
                    sm={3.8}
                    sx={{ marginTop: { xs: '10px', sm: '0' } }}
                    backgroundColor={data.dayOff ? '#e3eaf3' : ''}
                    item
                  >
                    <Autocomplete
                      disabled={data.dayOff}
                      size="small"
                      disablePortal
                      disableClearable
                      options={timesList.map((option) => option.label)}
                      sx={{
                        width: '100%',
                        fontSize: '14px',
                      }}
                      onChange={(e, value) => {
                        handleChange(value, data.id, 'from');
                      }}
                      value={dayjs(data?.from, "mm:ss").format("mm:ss") || null}
                      renderInput={(parPMs) => (
                        <TextField
                          {...parPMs}
                          InputProps={{
                            ...parPMs.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <AccessAlarmsIcon />
                              </InputAdornment>
                            ),
                          }}
                          placeholder="Select..."
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    xs={12}
                    sm={3.8}
                    backgroundColor={data.dayOff ? '#e3eaf3' : ''}
                    item
                    sx={{
                      marginRight: { xs: 0, sm: '16px' },
                      marginTop: { xs: '10px', sm: '0' },
                    }}
                  >
                    <Autocomplete
                      size="small"
                      disabled={data.dayOff}
                      disableClearable
                      options={timesList.map((option) => option.label)}
                      sx={{
                        width: '100%',
                        fontSize: '14px',
                      }}
                      onChange={(e, value) => {
                        handleChange(value, data.id, 'to');
                      }}
                      getOptionDisabled={(option) =>
                        dayjs(option, 'mm:ss') < dayjs(data.from, 'mm:ss')
                      }
                      value={dayjs(data?.to, "mm:ss").format("mm:ss") || null}
                      renderInput={(parPMs) => (
                        <TextField
                          {...parPMs}
                          InputProps={{
                            ...parPMs.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <AccessAlarmsIcon />
                              </InputAdornment>
                            ),
                          }}
                          placeholder="Select..."
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    id="once-per-person"
                    container
                    alignItems="center"
                    item
                    xs={12}
                    sm={4}
                    sx={{
                      cursor: 'pointer',
                      border: '1px solid rgba(0, 0, 0, 0.23)',
                      borderRadius: '4px',
                      height: 40,
                      marginTop: { xs: '10px', sm: '0' },
                    }}
                    onClick={() => handleDayOff(data.id)}
                  >
                    <Checkbox
                      size="small"
                      checked={data.dayOff}
                      sx={{ color: '#8b97a1' }}
                    />
                    <Typography
                      variant="subtitle2"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: '#8b97a1',
                      }}
                    >
                      Add day off
                    </Typography>
                  </Grid>
                </Grid>
                {!data.dayOff && (
                  <>
                    {data.breaks.length !== 0 && (
                      <Typography
                        variant="subtitle1"
                        color="#fd9b78"
                        fontSize="14px"
                        fontWeight="bold"
                        sx={{ my: 3 }}
                      >
                        Breaks
                      </Typography>
                    )}
                    <Grid xs={12} sm={12} container>
                      {data.breaks.length !== 0 &&
                        data.breaks.map((breakTime) => (
                          <Grid container marginBottom={1}>
                            <Grid xs={5.5} item>
                              <Autocomplete
                                options={timesList.map(
                                  (option) => option.label
                                )}
                                size="small"
                                disablePortal
                                disableClearable
                                sx={{
                                  width: '100%',
                                  fontSize: '14px',
                                }}
                                getOptionDisabled={(option) =>
                                  dayjs(option, 'mm:ss') <
                                    dayjs(data.from, 'mm:ss') ||
                                  dayjs(option, 'mm:ss') > dayjs(data.to, 'mm:ss')
                                }
                                value={dayjs(breakTime?.from, "mm:ss").format("mm:ss") || null}
                                onChange={handleUpdateBreakTimes(
                                  'from',
                                  breakTime.id,
                                  data.id
                                )}
                                renderInput={(parPMs) => (
                                  <TextField
                                    {...parPMs}
                                    InputProps={{
                                      ...parPMs.InputProps,
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <AccessAlarmsIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                    placeholder="Select..."
                                  />
                                )}
                              />
                            </Grid>
                            <Grid xs={5.5} item>
                              <Autocomplete
                                options={timesList.map(
                                  (option) => option.label
                                )}
                                size="small"
                                disablePortal
                                disableClearable
                                sx={{
                                  width: '100%',
                                  fontSize: '14px',
                                }}
                                value={dayjs(breakTime?.to, "mm:ss").format("mm:ss") || null}
                                getOptionDisabled={(option) =>
                                  dayjs(option, 'mm:ss') <
                                    dayjs(data.from, 'mm:ss') ||
                                  dayjs(option, 'mm:ss') <
                                    dayjs(breakTime.from, 'mm:ss') ||
                                  dayjs(option, 'mm:ss') > dayjs(data.to, 'mm:ss')
                                }
                                onChange={handleUpdateBreakTimes(
                                  'to',
                                  breakTime.id,
                                  data.id
                                )}
                                renderInput={(parPMs) => (
                                  <TextField
                                    {...parPMs}
                                    InputProps={{
                                      ...parPMs.InputProps,
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <AccessAlarmsIcon />
                                        </InputAdornment>
                                      ),
                                    }}
                                    placeholder="Select..."
                                  />
                                )}
                              />
                            </Grid>
                            <Grid
                              id="once-per-person"
                              container
                              alignItems="center"
                              item
                              xs={1}
                              sm={1}
                              sx={{
                                cursor: 'pointer',
                                height: 40,
                                display: 'flex',
                                justifyContent: 'center',
                                color: '#fd9b78',
                              }}
                            >
                              <DeleteIcon
                                onClick={() =>
                                  deleteBreak(data.id, breakTime.id)
                                }
                              />
                            </Grid>
                          </Grid>
                        ))}

                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="left"
                        sx={{ cursor: 'pointer', my: 3 }}
                        onClick={() => addBreakToDayData(data.id)}
                      >
                        <AddCircleIcon sx={{ color: '#828f9a' }} />
                        <Typography
                          variant="subtitle2"
                          color="#828f9a"
                          marginLeft="5px"
                        >
                          Add Break
                        </Typography>
                      </Box>
                    </Grid>
                  </>
                )}
              </Grid>
            ))}
        </Grid>
      </Grid>
      <SaveSlideBar
        handleCancel={handleCancel}
        handleSave={handleSave}
        isSaveBar={isSaveBar}
        setIsSaveBar={setIsSaveBar}
      />
    </>
  );
};

export default BusinessHours;
