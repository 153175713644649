import React, { useEffect, useState } from "react";

import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import CloseIcon from "@mui/icons-material/Close";

import {
  Box,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextareaAutosize,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useRef } from "react";
import { MuiTelInput } from "mui-tel-input";
import styled from "@emotion/styled";
import { DatePicker } from "@mui/x-date-pickers";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const StyledTab = styled(Tab)({
  fontSize: "12px",
  fontWeight: "bold",
  padding: 0,
});

const AddCustomer = ({ toggleAddCustomerDrawer, CustomerdrawerType }) => {
  const [isOpenDatePiker, setIsOpenDatePiker] = useState(false);
  const [value, setValue] = useState(0);
  const [formData, setFormData] = useState({
    servicesFilter: [],
    staffFilter: [],
  });
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down("sm"));
  const [datevalue, setDateValue] = useState();
const handelDate = (value) =>{
  setDateValue(value)
};
  const buttonProps = {
    variant: "contained",
    size: isSmallScreen ? "small" : "large",
  };

  const handleFormData = (fieldName, value) => {
    setFormData({
      ...formData,
      [fieldName]: typeof value === "string" ? value.split(",") : value,
    });
  };

  const image = useRef();
  const handelClick = () => {
    image.current.click();
  };
  const [mobile, setMobile] = React.useState("+46");

  const handleChange = (newValue) => {
    setMobile(newValue);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Grid container>
      {/* sx={{ width: "100vw"}} */}
      <Grid item xs={12} sm={2}>
        <Drawer
          variant="temporary"
          anchor="right"
          open={true}
          onClose={() => { }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: { xs: "100vw", md: "550px" },
              backgroundColor: "white",
            },
          }}
        >
          <Grid item container>
            {/* -----------HEADING */}
            <Grid
              item
              container
              sx={{ borderBottom: "1px solid #e3eaf3", paddingX: 3 }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContents: "space-between",
                  alignItems: "center",
                  height: "81px",
                }}
              >
                <Grid
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContents: "space-between",
                    alignItems: "center",
                  }}
                >
                  <img
                    src="https://demo.booknetic.com/saas/wp-content/plugins/booknetic/app/Backend/Base/assets/icons/add-employee.svg"
                    alt=""
                  />
                  <Typography
                    sx={{
                      fontSize: 22,
                      fontWeight: 500,
                      marginLeft: 2,
                      color: "#292D32",
                    }}
                  >
                    {CustomerdrawerType === "addCustomer"
                      ? "Add Customer"
                      : CustomerdrawerType === "info"
                        ? "Customer info"
                        : "Edit Customer"}
                  </Typography>
                </Grid>
                <CloseIcon
                  sx={{ color: "#ADBFC7", cursor: "pointer" }}
                  onClick={toggleAddCustomerDrawer}
                />
              </Grid>
            </Grid>
            {/* Tabs  */}
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{ borderBottom: 1, borderColor: "divider", mx: 3, mt: 2 }}
              >
                <Tabs
                  sx={{ fontSize: "10px" }}
                  value={value}
                  onChange={handleChange}
                >
                  <StyledTab label="Appointment details" {...a11yProps(0)} />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <Grid
                  sx={{
                    height: "77vh",
                    overflowY: "auto",
                    width: "100%",
                  }}
                >
                  <Grid container>
                    <Grid
                      xs={12}
                      sm={6}
                      sx={{ paddingRight: { xs: 0, sm: 2 }, marginTop: 0 }}
                    >
                      <Grid item xs={12} sx={{ marginY: 1 }}>
                        <Typography variant="subtitle2">
                          First Name <span style={{ color: "red" }}>*</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-basic"
                          size="small"
                          required
                          variant="outlined"
                          sx={{
                            marginBottom: "1rem",
                            width: "100%",
                            fontSize: "14px",
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid xs={12} sm={6}>
                      <Grid item xs={12} sx={{ marginY: 1 }}>
                        <Typography variant="subtitle2">
                          Last Name <span style={{ color: "red" }}>*</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-basic"
                          size="small"
                          required
                          variant="outlined"
                          sx={{
                            marginBottom: "1rem",
                            width: "100%",
                            fontSize: "14px",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid
                      xs={12}
                      sm={6}
                      sx={{ paddingRight: { sm: 2, xs: 0 }, marginTop: 0 }}
                    >
                      <Grid item xs={12} sx={{ marginY: 1 }}>
                        <Typography variant="subtitle2">
                          Email <span style={{ color: "red" }}>*</span>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-basic"
                          size="small"
                          required
                          variant="outlined"
                          sx={{
                            marginBottom: "1rem",
                            width: "100%",
                            fontSize: "14px",
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid xs={12} sm={6} sx={{ marginTop: 0 }}>
                      <Grid item xs={12} sx={{ marginY: 1 }}>
                        <Typography variant="subtitle2">Phone</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <MuiTelInput
                          InputProps={{
                            style: {
                              height: "40px",
                              padding: "0 10px",
                            },
                          }}
                          sx={{ width: "100%" }}
                          value={mobile}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid xs={12} sx={{ marginTop: 0 }}>
                      <Grid item xs={12} sx={{ marginY: 1 }}>
                        <Typography variant="subtitle2">Image</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            px: "1rem",
                            py: "5px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            border: "1px solid #ddd",
                            ":hover": { border: "1px solid #000" },
                            borderRadius: "5px",
                            marginBottom: "1rem",
                            cursor: "pointer",
                          }}
                          onClick={handelClick}
                        >
                          <Typography variant="p" color="#848181">
                            (PNG,JPG,max 800x800 to 5mb)
                          </Typography>
                          <Button
                            variant="contained"
                            size="small"
                            style={{ background: "gray" }}
                          >
                            Browse
                          </Button>
                        </Box>
                        <TextField
                          id="outlined-basic"
                          size="small"
                          required
                          variant="outlined"
                          sx={{
                            marginBottom: "1rem",
                            width: "100%",
                            fontSize: "14px",
                            display: "none",
                          }}
                          inputRef={image}
                          type="file"
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid
                      xs={12}
                      sm={6}
                      sx={{ paddingRight: { sm: 2, xs: 0 }, marginTop: 0 }}
                    >
                      <Grid item xs={12} sx={{ marginY: 1 }}>
                        <Typography variant="subtitle2">Gender</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-basic"
                          size="small"
                          required
                          variant="outlined"
                          sx={{
                            marginBottom: "1rem",
                            width: "100%",
                            fontSize: "14px",
                          }}
                          defaultValue="other"
                        select
                        >
                          <MenuItem value="male">Male</MenuItem>
                          <MenuItem value="female">Female</MenuItem>
                          <MenuItem value="other">Other</MenuItem>
                        </TextField>
                      </Grid>
                    </Grid>
                    <Grid
                      xs={12}
                      sm={6}
                      sx={{ paddingRight: { sm: 0, xs: 0 }, marginTop: 0 }}
                    >
                      <Grid item xs={12} sx={{ marginY: 1 }}>
                        <Typography variant="subtitle2">
                          Date of birth
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                      <DatePicker
                open={isOpenDatePiker}
                onOpen={() => setIsOpenDatePiker(!isOpenDatePiker)}
                onClose={() => setIsOpenDatePiker(!isOpenDatePiker)}
                // toolbarPlaceholder="Basic example"
                value={datevalue}
                onChange={(newValue) => {
                  handelDate(newValue);
                }}
                InputProps={{
                  style: {
                    flexDirection: "row-reverse",
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    onClick={() => setIsOpenDatePiker(!isOpenDatePiker)}
                    onTouchStart={() => setIsOpenDatePiker(!isOpenDatePiker)}
                    fullWidth
                  />
                )}
              />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid xs={12} sx={{ marginTop: 0 }}>
                      <Grid item xs={12} sx={{ marginY: 1 }}>
                        <Typography variant="subtitle2">Note</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextareaAutosize
                          id="outlined-basic"
                          size="small"
                          required
                          variant="outlined"
                          style={{
                            marginBottom: "1rem",
                            width: "97.5%",
                            minHeight: "120px",
                            padding: "5px",
                            fontSize: "16px",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </TabPanel>
            </Box>

            {/* -----------FORM----- */}

            {/* -----Button */}
            <Grid
              item
              container
              sx={{
                display: "flex",
                justifyContent: "space-between",
                boxShadow: "5px -2px 10px -5px #e4ebf4",
                borderTop: "1px solid #E4EBF4",
                bottom: 5,
                paddingTop: 1,
                backgroundColor: "white",
                paddingX: 2,
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    width: { xs: "100%", sm: "auto" },
                    marginTop: 2,
                    marginBottom: 1,
                    backgroundColor: "WHITE",
                    color: "GREY",
                    ":hover": { backgroundColor: "GREY", color: "WHITE" },
                  }}
                  onClick={toggleAddCustomerDrawer}
                >
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    width: { xs: "100%", sm: "auto" },
                    marginTop: 2,
                    marginBottom: 1,
                    marginLeft: 2,
                  }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Drawer>
      </Grid>
    </Grid></LocalizationProvider>
  );
};

export default AddCustomer;
