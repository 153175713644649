import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { getStaffList } from '../../../Api/tenant/staff';

export default function SelectStaff({
  selectedValue,
  setSelectedValue,
  sx,
  multiple,
}) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  const handleOpen = () => {
    getStaffList(100, 1)
      .then((res) => res.data)
      .then((ser) => {
        setOptions(ser);
      });
  };

  React.useEffect(() => {
    if (!open) {
      // setOptions([]);
    } else {
      handleOpen();
    }
  }, [open]);

  return (
    <Autocomplete
      sx={{ width: '100%', ...sx }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => {
        return option.id == value || option.id == value?.id;
      }}
      getOptionLabel={(option) =>
        option.User.firstName + ' ' + option.User.lastName
      }
      options={options}
      loading={loading}
      multiple={multiple}
      size="small"
      value={selectedValue}
      onChange={(e, v) => {
        setSelectedValue(v);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select staff"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
