import React from 'react';
import { Box, Modal as ModalT, Typography } from '@mui/material';
import CustomTextButton from '../CustomButtons/CustomTextButton';
import { activateTrial } from '../../Api/common/stripe';
import { useSelector } from 'react-redux';
import { selectUser } from '../../redux/slices/user.slice.js';

function TrialModal({ open, handleClose }) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '100%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 10,
    px: 5,
    pb: 5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '20px',
  };
  const { user } = useSelector(selectUser);

  const handleStartTrial = () => {
    activateTrial(user.TenantId).then(() => {
      handleClose();
    });
  };
  return (
    <ModalT
      sx={{
        width: { xs: '90%', sm: 'auto' },
      }}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h4"
            component="h2"
            sx={{
              color: '#303439',
              fontWeight: 'bold',
            }}
          >
            Start with a{' '}
            <span
              style={{
                color: '#39D581',
                textAlign: 'center',
              }}
            >
              14-day trial
            </span>
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2, textAlign: 'center', color: '#626c76' }}
          >
            Choose modules that best match your business needs.
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mb: 4, textAlign: 'center', color: '#626c76' }}
          >
            No credit card needs to be added.
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            justifyContent: 'start',
            gap: '10px',
            color: '#626c76',
            mb: '30px',
          }}
        >
          <Typography>
            A concise overview for quick insights and data visualization.
          </Typography>
          <Typography>
            organize and schedule events, tasks, and appointments efficiently.
          </Typography>
          <Typography>
            Easily share URLs from your service or staff and incorporate them on
            your website.
          </Typography>
          <Typography>
            Manage all your locations from one application
          </Typography>
          <Typography>
            Sell your product on the network by adding a Payment gateway
          </Typography>
          <Typography>
            Valuable data analysis, enabling informed decision-making
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: '15px',
            justifyContent: 'end',
          }}
        >
          <CustomTextButton text="Cancel" onClick={handleClose} />
          <CustomTextButton
            text="Start trial"
            type="blue"
            onClick={() => {
              handleStartTrial();
            }}
          />
        </Box>
      </Box>
    </ModalT>
  );
}

export default TrialModal;
