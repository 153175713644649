import React from "react";
import { Box, Card, Grid, IconButton, Menu, Typography } from "@mui/material";
import classes from "./ResponsiveTable.module.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";

function ResponsiveTable({ columns, rows }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [actions, setActions] = React.useState(null);
  const open = Boolean(anchorEl);
  const ITEM_HEIGHT = 48;
  const handleClick = (event) => {};
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: { xs: "inline", md: "none" } }}>
      {/* actions menu */}
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "25ch",
          },
        }}
      >
        {actions &&
          actions.map((action) => {
            if (action.props.showInMenu) return action;
          })}
      </Menu>
      {/* cards */}
      {rows &&
        rows.map((row) => {
          return (
            <Card className={classes.card}>
              <Box className={classes.card__cotainer}>
                {columns.map((col, i) => {
                  if (col && row[col.field]) {
                    return (
                      <Box className={classes.card__row}>
                        <Grid xs={5}>
                          <Typography sx={{ fontWeight: 700, fontSize: 14 }}>
                            {col.headerName}
                          </Typography>
                        </Grid>
                        <Grid xs={6}>
                          <Typography sx={{ fontSize: 14 }}>
                            {col.renderCell
                              ? col.renderCell({
                                  value: row[col.field],
                                  row,
                                  id: row.id,
                                })
                              : row[col.field]}
                          </Typography>
                        </Grid>
                        {i == 0 && columns[columns.length - 1].getActions && (
                          <Grid
                            xs={1}
                            className={classes.card__action__container}
                          >
                            {columns[columns.length - 1].getActions &&
                              columns[columns.length - 1]
                                .getActions({
                                  value: row[col.field],
                                  row,
                                  id: row.id,
                                })
                                .filter(
                                  (item) => !item.props.showInMenu && item
                                )}
                            <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-controls={open ? "long-menu" : undefined}
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={(e) => {
                                columns[columns.length - 1].getActions &&
                                  setActions(
                                    columns[columns.length - 1].getActions({
                                      value: row[col.field],
                                      row,
                                      id: row.id,
                                    })
                                  );
                                setAnchorEl(e.currentTarget);
                              }}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          </Grid>
                        )}
                      </Box>
                    );
                  }
                })}
              </Box>
            </Card>
          );
        })}
    </Box>
  );
}

export default ResponsiveTable;
