import React, { useEffect, useState } from "react";
import Drawer from "../../components/Drawer";
import DrawerFooter from "../../components/DrawerFooter";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { useMediaQuery, useTheme } from "@mui/material";

const UsageHistory = ({ toggleDrawer }) => {
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down("sm"));

  return (
    <Grid container>
      {/* sx={{ width: "100vw"}} */}
      <Grid item xs={12} sm={2}>
        <Drawer
          title={"Usage history"}
          toggleDrawer={toggleDrawer}
          type={"INFO"}
        >
          {/* -----------FORM----- */}
          <Grid
            sx={{
              paddingX: "24px",
              paddingTop: 3,
              height: "100%",
              overflowY: "auto",
            }}
          >
            <Grid container>
              <Typography variant="subtitle1">
                Coupon is not used yet!
              </Typography>
            </Grid>
          </Grid>
          {/* -----Button */}

          <DrawerFooter>
            <div
              style={{
                display: "flex",
                justifyContent: isSmallScreen ? "space-between" : "flex-end",
                width: "100%",
              }}
            >
              <Button
                variant="contained"
                size="large"
                sx={{
                  width: isSmallScreen ? "48%" : "7rem",

                  backgroundColor: "WHITE",
                  color: "GREY",
                  ":hover": { backgroundColor: "GREY", color: "WHITE" },
                }}
                onClick={toggleDrawer}
              >
                Close
              </Button>
            </div>
          </DrawerFooter>
        </Drawer>
      </Grid>
    </Grid>
  );
};

export default UsageHistory;
