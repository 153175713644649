import { Grid, Typography } from '@mui/material'
import React from 'react'

const Extras = () => {
  return (
    <Grid sx={{display:'flex',justifyContent:'center'}} container>
    <Typography variant="subtitle2" color="#828f9a">No extras found</Typography>
  </Grid>
  )
}

export default Extras