import React from 'react';
import { Grid, Switch, Typography } from '@mui/material';

import { useState } from 'react';
import { IOSSwitch } from '../../../components/CustomCheckbox';

const Permission = ({ permissionData, setpermissionData, categoryies }) => {
  const [appointment, setAppointment] = useState(false);
  const handleAppointment = () => {
    setAppointment(!appointment);
  };
  const [customer, setCustomer] = useState(false);
  const handleCustomer = () => {
    setCustomer(!customer);
  };
  const [location, setLocation] = useState(false);
  const handleLocation = () => {
    setLocation(!location);
  };
  const [payment, setPayment] = useState(false);
  const handlePayment = () => {
    setPayment(!payment);
  };

  const [workflow, setWorkflow] = useState(false);
  const handleWorkflow = () => {
    setWorkflow(!workflow);
  };
  const [service, setService] = useState(false);
  const handleService = () => {
    setService(!service);
  };
  const [staff, setStaff] = useState(false);
  const handleStaff = () => {
    setStaff(!staff);
  };
  const [role, setRole] = useState(false);
  const handleRole = () => {
    setRole(!role);
  };
  const [setting, setSetting] = useState(false);
  const handleSetting = () => {
    setSetting(!setting);
  };
  const [frontend, setFrontend] = useState(false);
  const handleFrontend = () => {
    setFrontend(!frontend);
  };
  const [coupon, setCoupon] = useState(false);
  const handleCoupon = () => {
    setCoupon(!coupon);
  };
  const [giftcard, setGiftcard] = useState(false);
  const handleGiftcard = () => {
    setGiftcard(!giftcard);
  };
  const [invoice, setInvoice] = useState(false);
  const handleInvoice = () => {
    setInvoice(!invoice);
  };
  const [tax, setTax] = useState(false);
  const handleTax = () => {
    setTax(!tax);
  };

  return (
    <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
      <Grid xs={12} sm={12} sx={{ marginTop: 1 }}>
        <Grid
          id="once-per-person"
          container
          alignItems="center"
          item
          xs={12}
          sx={{
            cursor: 'pointer',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            marginTop: 1.0,
            height: 50,
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: '.7rem',
          }}
        >
          <Typography variant="subtitle2">Dashboard module</Typography>
        </Grid>
      </Grid>
      <Grid xs={12} sm={12} sx={{ marginTop: 1 }}>
        <Grid
          id="once-per-person"
          container
          alignItems="center"
          item
          xs={12}
          sx={{
            cursor: 'pointer',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            marginTop: 1.0,
            height: 50,
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: '.7rem',
          }}
        >
          <Typography variant="subtitle2">Appointments module</Typography>
          <IOSSwitch onChange={handleAppointment} />
        </Grid>
        <Grid
          container
          alignItems="center"
          item
          xs={12}
          sx={{
            marginTop: 1.0,

            display: 'flex',

            justifyContent: 'flex-end',
          }}
        >
          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              height: 50,
              marginTop: 1.0,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Add new</Typography>
            <IOSSwitch checked={appointment} />
          </Grid>

          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              marginTop: 1.0,
              height: 50,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Edit</Typography>
            <IOSSwitch checked={appointment} />
          </Grid>
          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              marginTop: 1.0,
              height: 50,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Delete</Typography>
            <IOSSwitch checked={appointment} />
          </Grid>
          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              marginTop: 1.0,
              height: 50,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Coupons tab</Typography>
            <IOSSwitch checked={appointment} />
          </Grid>
          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              marginTop: 1.0,
              height: 50,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Custom Forms Tab</Typography>
            <IOSSwitch checked={appointment} />
          </Grid>
          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              marginTop: 1.0,
              height: 50,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Appointment Rating</Typography>
            <IOSSwitch checked={appointment} />
          </Grid>
        </Grid>
        <Grid
          id="once-per-person"
          container
          alignItems="center"
          item
          xs={12}
          sx={{
            cursor: 'pointer',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            marginTop: 1.0,
            height: 50,
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: '.7rem',
          }}
        >
          <Typography variant="subtitle2">Calendar module</Typography>
          <IOSSwitch onChange={handleAppointment} />
        </Grid>
        <Grid
          id="once-per-person"
          container
          alignItems="center"
          item
          xs={12}
          sx={{
            cursor: 'pointer',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            marginTop: 1.0,
            height: 50,
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: '.7rem',
          }}
        >
          <Typography variant="subtitle2">Customers module</Typography>
          <IOSSwitch onChange={handleCustomer} />
        </Grid>
        <Grid
          container
          alignItems="center"
          item
          xs={12}
          sx={{
            marginTop: 1.0,

            display: 'flex',

            justifyContent: 'flex-end',
          }}
        >
          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              height: 50,
              marginTop: 1.0,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Add new</Typography>
            <IOSSwitch checked={customer} />
          </Grid>

          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              marginTop: 1.0,
              height: 50,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Edit</Typography>
            <IOSSwitch checked={customer} />
          </Grid>
          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              marginTop: 1.0,
              height: 50,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Delete</Typography>
            <IOSSwitch checked={customer} />
          </Grid>
          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              marginTop: 1.0,
              height: 50,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Export & Import</Typography>
            <IOSSwitch checked={customer} />
          </Grid>
        </Grid>
        <Grid
          id="once-per-person"
          container
          alignItems="center"
          item
          xs={12}
          sx={{
            cursor: 'pointer',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            marginTop: 1.0,
            height: 50,
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: '.7rem',
          }}
        >
          <Typography variant="subtitle2">Locations module</Typography>
          <IOSSwitch onChange={handleLocation} />
        </Grid>
        <Grid
          container
          alignItems="center"
          item
          xs={12}
          sx={{
            marginTop: 1.0,

            display: 'flex',

            justifyContent: 'flex-end',
          }}
        >
          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              height: 50,
              marginTop: 1.0,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Add new</Typography>
            <IOSSwitch checked={location} />
          </Grid>

          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              marginTop: 1.0,
              height: 50,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Edit</Typography>
            <IOSSwitch checked={location} />
          </Grid>
          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              marginTop: 1.0,
              height: 50,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Delete</Typography>
            <IOSSwitch checked={location} />
          </Grid>
        </Grid>
        <Grid
          id="once-per-person"
          container
          alignItems="center"
          item
          xs={12}
          sx={{
            cursor: 'pointer',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            marginTop: 1.0,
            height: 50,
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: '.7rem',
          }}
        >
          <Typography variant="subtitle2">Payments module</Typography>
          <IOSSwitch onChange={handlePayment} />
        </Grid>
        <Grid
          container
          alignItems="center"
          item
          xs={12}
          sx={{
            marginTop: 1.0,

            display: 'flex',

            justifyContent: 'flex-end',
          }}
        >
          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              marginTop: 1.0,
              height: 50,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Edit</Typography>
            <IOSSwitch checked={payment} />
          </Grid>
        </Grid>

        <Grid
          id="once-per-person"
          container
          alignItems="center"
          item
          xs={12}
          sx={{
            cursor: 'pointer',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            marginTop: 1.0,
            height: 50,
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: '.7rem',
          }}
        >
          <Typography variant="subtitle2">Workflow module</Typography>
          <IOSSwitch onChange={handleWorkflow} />
        </Grid>
        <Grid
          container
          alignItems="center"
          item
          xs={12}
          sx={{
            marginTop: 1.0,

            display: 'flex',

            justifyContent: 'flex-end',
          }}
        >
          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              height: 50,
              marginTop: 1.0,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Add new</Typography>
            <IOSSwitch checked={workflow} />
          </Grid>

          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              marginTop: 1.0,
              height: 50,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Edit</Typography>
            <IOSSwitch checked={workflow} />
          </Grid>
          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              marginTop: 1.0,
              height: 50,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Delete</Typography>
            <IOSSwitch checked={workflow} />
          </Grid>
        </Grid>

        <Grid
          id="once-per-person"
          container
          alignItems="center"
          item
          xs={12}
          sx={{
            cursor: 'pointer',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            marginTop: 1.0,
            height: 50,
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: '.7rem',
          }}
        >
          <Typography variant="subtitle2">Services module</Typography>
          <IOSSwitch onChange={handleService} />
        </Grid>
        <Grid
          container
          alignItems="center"
          item
          xs={12}
          sx={{
            marginTop: 1.0,

            display: 'flex',

            justifyContent: 'flex-end',
          }}
        >
          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              height: 50,
              marginTop: 1.0,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Add new</Typography>
            <IOSSwitch checked={service} />
          </Grid>
          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              marginTop: 1.0,
              height: 50,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Edit</Typography>
            <IOSSwitch checked={service} />
          </Grid>
          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              marginTop: 1.0,
              height: 50,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Delete</Typography>
            <IOSSwitch checked={service} />
          </Grid>
          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              height: 50,
              marginTop: 1.0,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Add new category</Typography>
            <IOSSwitch checked={service} />
          </Grid>
          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              marginTop: 1.0,
              height: 50,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Edit category</Typography>
            <IOSSwitch checked={service} />
          </Grid>
          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              marginTop: 1.0,
              height: 50,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Delete category</Typography>
            <IOSSwitch checked={service} />
          </Grid>
          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              height: 50,
              marginTop: 1.0,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Add new extra</Typography>
            <IOSSwitch checked={service} />
          </Grid>
          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              marginTop: 1.0,
              height: 50,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Edit extra</Typography>
            <IOSSwitch checked={service} />
          </Grid>
          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              marginTop: 1.0,
              height: 50,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Delete extra</Typography>
            <IOSSwitch checked={service} />
          </Grid>
        </Grid>

        <Grid
          id="once-per-person"
          container
          alignItems="center"
          item
          xs={12}
          sx={{
            cursor: 'pointer',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            marginTop: 1.0,
            height: 50,
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: '.7rem',
          }}
        >
          <Typography variant="subtitle2">Staff module</Typography>
          <IOSSwitch onChange={handleStaff} />
        </Grid>
        <Grid
          container
          alignItems="center"
          item
          xs={12}
          sx={{
            marginTop: 1.0,

            display: 'flex',

            justifyContent: 'flex-end',
          }}
        >
          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              height: 50,
              marginTop: 1.0,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Add new</Typography>
            <IOSSwitch checked={staff} />
          </Grid>

          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              marginTop: 1.0,
              height: 50,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Edit</Typography>
            <IOSSwitch checked={staff} />
          </Grid>
          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              marginTop: 1.0,
              height: 50,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Delete</Typography>
            <IOSSwitch checked={staff} />
          </Grid>
        </Grid>

        <Grid
          id="once-per-person"
          container
          alignItems="center"
          item
          xs={12}
          sx={{
            cursor: 'pointer',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            marginTop: 1.0,
            height: 50,
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: '.7rem',
          }}
        >
          <Typography variant="subtitle2">Roles module</Typography>
          <IOSSwitch onChange={handleRole} />
        </Grid>
        <Grid
          container
          alignItems="center"
          item
          xs={12}
          sx={{
            marginTop: 1.0,

            display: 'flex',

            justifyContent: 'flex-end',
          }}
        >
          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              height: 50,
              marginTop: 1.0,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Add new</Typography>
            <IOSSwitch checked={role} />
          </Grid>

          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              marginTop: 1.0,
              height: 50,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Edit</Typography>
            <IOSSwitch checked={role} />
          </Grid>
          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              marginTop: 1.0,
              height: 50,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Delete</Typography>
            <IOSSwitch checked={role} />
          </Grid>
        </Grid>

        <Grid
          id="once-per-person"
          container
          alignItems="center"
          item
          xs={12}
          sx={{
            cursor: 'pointer',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            marginTop: 1.0,
            height: 50,
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: '.7rem',
          }}
        >
          <Typography variant="subtitle2">Settings</Typography>
          <IOSSwitch onChange={handleSetting} />
        </Grid>
        <Grid
          container
          alignItems="center"
          item
          xs={12}
          sx={{
            marginTop: 1.0,

            display: 'flex',

            justifyContent: 'flex-end',
          }}
        >
          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              height: 50,
              marginTop: 1.0,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">General settings</Typography>
            <IOSSwitch checked={setting} />
          </Grid>

          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              marginTop: 1.0,
              height: 50,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Appointment status</Typography>
            <IOSSwitch checked={setting} />
          </Grid>
          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              marginTop: 1.0,
              height: 50,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Waiting list</Typography>
            <IOSSwitch checked={setting} />
          </Grid>
        </Grid>

        <Grid
          id="once-per-person"
          container
          alignItems="center"
          item
          xs={12}
          sx={{
            cursor: 'pointer',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            marginTop: 1.0,
            height: 50,
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: '.7rem',
          }}
        >
          <Typography variant="subtitle2">Frontend settings</Typography>
          <IOSSwitch onChange={handleFrontend} />
        </Grid>
        <Grid
          container
          alignItems="center"
          item
          xs={12}
          sx={{
            marginTop: 1.0,

            display: 'flex',

            justifyContent: 'flex-end',
          }}
        >
          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              height: 50,
              marginTop: 1.0,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Booking Steps</Typography>
            <IOSSwitch checked={frontend} />
          </Grid>

          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              marginTop: 1.0,
              height: 50,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">
              Booking Widget Translation
            </Typography>
            <IOSSwitch checked={frontend} />
          </Grid>
          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              marginTop: 1.0,
              height: 50,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Customer Panel</Typography>
            <IOSSwitch checked={frontend} />
          </Grid>
          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              marginTop: 1.0,
              height: 50,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Rating Form</Typography>
            <IOSSwitch checked={frontend} />
          </Grid>
        </Grid>

        <Grid
          id="once-per-person"
          container
          alignItems="center"
          item
          xs={12}
          sx={{
            cursor: 'pointer',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            marginTop: 1.0,
            height: 50,
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: '.7rem',
          }}
        >
          <Typography variant="subtitle2">Payment settings</Typography>
          <IOSSwitch />
        </Grid>
        <Grid
          id="once-per-person"
          container
          alignItems="center"
          item
          xs={12}
          sx={{
            cursor: 'pointer',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            marginTop: 1.0,
            height: 50,
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: '.7rem',
          }}
        >
          <Typography variant="subtitle2">Company details</Typography>
          <IOSSwitch onChange={handleAppointment} />
        </Grid>
        <Grid
          id="once-per-person"
          container
          alignItems="center"
          item
          xs={12}
          sx={{
            cursor: 'pointer',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            marginTop: 1.0,
            height: 50,
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: '.7rem',
          }}
        >
          <Typography variant="subtitle2">Business Hours</Typography>
          <IOSSwitch onChange={handleAppointment} />
        </Grid>
        <Grid
          id="once-per-person"
          container
          alignItems="center"
          item
          xs={12}
          sx={{
            cursor: 'pointer',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            marginTop: 1.0,
            height: 50,
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: '.7rem',
          }}
        >
          <Typography variant="subtitle2">Holidays</Typography>
          <IOSSwitch onChange={handleAppointment} />
        </Grid>

        <Grid
          id="once-per-person"
          container
          alignItems="center"
          item
          xs={12}
          sx={{
            cursor: 'pointer',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            marginTop: 1.0,
            height: 50,
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: '.7rem',
          }}
        >
          <Typography variant="subtitle2">Coupons module</Typography>
          <IOSSwitch onChange={handleCoupon} />
        </Grid>
        <Grid
          container
          alignItems="center"
          item
          xs={12}
          sx={{
            marginTop: 1.0,

            display: 'flex',

            justifyContent: 'flex-end',
          }}
        >
          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              height: 50,
              marginTop: 1.0,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Add new</Typography>
            <IOSSwitch checked={coupon} />
          </Grid>

          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              marginTop: 1.0,
              height: 50,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Edit</Typography>
            <IOSSwitch checked={coupon} />
          </Grid>
          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              marginTop: 1.0,
              height: 50,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Delete</Typography>
            <IOSSwitch checked={coupon} />
          </Grid>
        </Grid>

        <Grid
          id="once-per-person"
          container
          alignItems="center"
          item
          xs={12}
          sx={{
            cursor: 'pointer',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            marginTop: 1.0,
            height: 50,
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: '.7rem',
          }}
        >
          <Typography variant="subtitle2">Giftcards</Typography>
          <IOSSwitch onChange={handleGiftcard} />
        </Grid>
        <Grid
          container
          alignItems="center"
          item
          xs={12}
          sx={{
            marginTop: 1.0,

            display: 'flex',

            justifyContent: 'flex-end',
          }}
        >
          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              height: 50,
              marginTop: 1.0,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Add new</Typography>
            <IOSSwitch checked={giftcard} />
          </Grid>

          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              marginTop: 1.0,
              height: 50,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Edit</Typography>
            <IOSSwitch checked={giftcard} />
          </Grid>
          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              marginTop: 1.0,
              height: 50,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Delete</Typography>
            <IOSSwitch checked={giftcard} />
          </Grid>
          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              marginTop: 1.0,
              height: 50,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Usage history</Typography>
            <IOSSwitch checked={giftcard} />
          </Grid>
        </Grid>

        <Grid
          id="once-per-person"
          container
          alignItems="center"
          item
          xs={12}
          sx={{
            cursor: 'pointer',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            marginTop: 1.0,
            height: 50,
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: '.7rem',
          }}
        >
          <Typography variant="subtitle2">Invoices</Typography>
          <IOSSwitch onChange={handleInvoice} />
        </Grid>
        <Grid
          container
          alignItems="center"
          item
          xs={12}
          sx={{
            marginTop: 1.0,

            display: 'flex',

            justifyContent: 'flex-end',
          }}
        >
          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              height: 50,
              marginTop: 1.0,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Add new</Typography>
            <IOSSwitch checked={invoice} />
          </Grid>

          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              marginTop: 1.0,
              height: 50,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Edit</Typography>
            <IOSSwitch checked={invoice} />
          </Grid>
          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              marginTop: 1.0,
              height: 50,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Delete</Typography>
            <IOSSwitch checked={invoice} />
          </Grid>
          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              marginTop: 1.0,
              height: 50,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Reports</Typography>
            <IOSSwitch checked={appointment} />
          </Grid>
        </Grid>

        <Grid
          id="once-per-person"
          container
          alignItems="center"
          item
          xs={12}
          sx={{
            cursor: 'pointer',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            marginTop: 1.0,
            height: 50,
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: '.7rem',
          }}
        >
          <Typography variant="subtitle2">Tax</Typography>
          <IOSSwitch onChange={handleTax} />
        </Grid>
        <Grid
          container
          alignItems="center"
          item
          xs={12}
          sx={{
            marginTop: 1.0,

            display: 'flex',

            justifyContent: 'flex-end',
          }}
        >
          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              height: 50,
              marginTop: 1.0,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Add new</Typography>
            <IOSSwitch checked={tax} />
          </Grid>

          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              marginTop: 1.0,
              height: 50,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Edit</Typography>
            <IOSSwitch checked={tax} />
          </Grid>
          <Grid
            alignItems="center"
            item
            xs={11}
            sx={{
              cursor: 'pointer',
              marginTop: 1.0,
              height: 50,
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '.7rem',
              cursor: 'pointer',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
            }}
          >
            <Typography variant="subtitle2">Delete</Typography>
            <IOSSwitch checked={tax} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Permission;
