import { Grid } from '@mui/material';
import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { getCouponList } from '../../../Api/tenant/coupon';

function SelectCoupons({
  selectedValue,
  setSelectedValue,
  sx,
  multiple,
  formData,
}) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  const handleOpen = () => {
    getCouponList(
      100,
      1,
      formData?.staff?.id && formData?.service?.id
        ? `staffId=${formData?.staff?.id}&serviceId=${formData?.service?.id}`
        : null
    )
      .then((res) => res.data)
      .then((ser) => {
        setOptions(ser);
      });
  };

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    } else {
      handleOpen();
    }
  }, [open, formData?.service, formData?.staff]);

  return (
    <Autocomplete
      sx={{ width: '100%', ...sx }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      multiple={multiple}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.code}
      options={options}
      loading={loading}
      value={selectedValue}
      onChange={(e, v) => {
        setSelectedValue(v);
      }}
      size="small"
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select Coupon"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}

const Coupon = ({ coupons, setCoupons }) => {
  return (
    <Grid
      sx={{ boxShadow: 'rgb(149 157 165 / 20%) 0px 0px 10px', padding: '2rem' }}
    >
      <SelectCoupons selectedValue={coupons} setSelectedValue={setCoupons} />
    </Grid>
  );
};

export default Coupon;
