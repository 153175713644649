import { UpnizeBackend } from '../Axios';

export const getProductList = async (rows, page, queries) => {
  let response = await UpnizeBackend.get(
    `/products?rows=${rows}&page=${page}${queries ? queries : ''}`
  );
  return response;
};

export const getProductById = async (id) => {
  let response = await UpnizeBackend.get(`/products/byId/${id}`);
  return response;
};

export const addProduct = async (body) => {
  let response = await UpnizeBackend.post(`/products/add`, body);
  return response;
};

export const editProductById = async (body, id) => {
  let response = await UpnizeBackend.put(`/products/edit/${id}`, body);
  return response;
};

export const deleteProductById = async (id) => {
  let response = await UpnizeBackend.delete('/products/delete', {
    data: {
      ids: id,
    },
  });
  return response;
};
