export const timeSlotOptions = [
    {
      label: 'set slot length as service duration',
      duration: 'duration',
    },
    {
      label: 'set slot length as default',
      duration: 'default',
    },
    {
      label: '1m',
      duration: '1',
    },
    {
      label: '2m',
      duration: '2',
    },
    {
      label: '3m',
      duration: '3',
    },
    {
      label: '4m',
      duration: '4',
    },
    {
      label: '5m',
      duration: '5',
    },
    {
      label: '10m',
      duration: '10',
    },
    {
      label: '12m',
      duration: '12',
    },
    {
      label: '15m',
      duration: '15',
    },
    {
      label: '20m',
      duration: '20',
    },
    {
      label: '25m',
      duration: '25',
    },
    {
      label: '30m',
      duration: '30',
    },
    {
      label: '35m',
      duration: '35',
    },
    {
      label: '40m',
      duration: '40',
    },
    {
      label: '50m',
      duration: '50',
    },
    {
      label: '55m',
      duration: '55',
    },
    {
      label: '1h',
      duration: '60',
    },
    {
      label: '1h 30m',
      duration: '90',
    },
    {
      label: '2h',
      duration: '120',
    },
    {
      label: '2h 30m',
      duration: '150',
    },
    {
      label: '3h',
      duration: '180',
    },
    {
      label: '3h 30m',
      duration: '210',
    },
    {
      label: '4h',
      duration: '240',
    },
    {
      label: '4h 30m',
      duration: '270',
    },
    {
      label: '5h',
      duration: '300',
    },
  ];