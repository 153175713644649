import * as React from 'react';
import Stack from '@mui/material/Stack';
import upsell from "../../assets/images/upsell.png";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CustomTextButton from '../../components/CustomButtons/CustomTextButton';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';

const UpsellTask = () => {
  return (  
    <React.Fragment>
      <Container maxWidth="sm">
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center', 
          marginTop: '10%',
          textAlign: 'center',
  
          '& img': {
            width: '100%',
            maxWidth: '100%',
            height: 'auto',
            padding: '10px', 
          },
          '@media (max-width: 600px)': {
            '& img': {
              padding: '10px',
            },
          },
        }}>
          <img
            src={upsell}
            alt="persons image"
          />
          <h1>Task management</h1>
          <Typography >Take charge of your tasks with our comprehensive task management system.
          Seamlessly organize private, public, and staff member tasks all in one place.
          Upgrade now to streamline your workflow and boost productivity across your team.</Typography>

          <Stack spacing={2} direction="row" marginTop={4}>
            <Link to="https://upnize.com/help-center/task-management">
            
            <CustomTextButton sx={{textTransform: 'capitalize'}}
                  variant="contained"
                  size="large"
                >
                  Learn more
                </CustomTextButton>
            </Link>
            <Link to="/subscription">
                <CustomTextButton sx={{textTransform: 'capitalize'}}
                  type="blue"
                  variant="contained"
                  size="large" >
                    Upgrade
                  </CustomTextButton>
            </Link>
          </Stack>
        </Box>
      </Container>
    </React.Fragment>
  );
}

export default UpsellTask;
