import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomTypography from '../CustomTypography';
import CustomTextButton from '../CustomButtons/CustomTextButton';
import {
  deleteTaskById,
  editTaskById,
  getDropdownTaskList,
} from '../../Api/tenant/tasks';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useDispatch } from 'react-redux';
import { drawerTypes } from '../../constants/drawerTypes';
import { crudTypes } from '../../constants/crudTypes';
import { drawersActions } from '../../redux/slices/drawers.slice';
import StatusControllerComp from '../StatusControllerComp';
import { callSnackbar } from '../../redux/slices/snackbar.slice';

function TasksDropDown() {
  const [tasks, setTasks] = useState([]);
  const [actionsAnchorEl, setActionsAnchorEl] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const open = Boolean(actionsAnchorEl);
  const dispatch = useDispatch();

  const fetchTasks = async () => {
    getDropdownTaskList(100, 1).then((res) => {
      setTasks(res.data);
    });
  };

  useEffect(() => {
    fetchTasks();
  }, []);

  const handleToggleActions = (event) => {
    if (actionsAnchorEl) {
      setActionsAnchorEl(null);
    } else {
      setActionsAnchorEl(event.currentTarget);
    }
  };

  const handleDeleteTask = () => {
    try {
      deleteTaskById([selectedTask.id]).then((res) => {
        fetchTasks();
        handleToggleActions();
      });
    } catch (err) {
      console.log(err);
    }
  };

  const updateStatus = async (inf, status, handleClose) => {
    try {
      const {
        id,
        title,
        description,
        priority,
        orderId,
        referenceId,
        public: pub,
        startDate,
        endDate,
        creator,
      } = inf;
      const data = {
        id,
        title,
        description,
        status,
        priority,
        orderId,
        referenceId,
        public: pub,
        status: status.name,
        creator: JSON.stringify(creator),
      };

      if (startDate) {
        data.startDate = startDate;
      }

      if (endDate) {
        data.endDate = endDate;
      }

      let formdata = new FormData();

      for (const el in data) {
        formdata.append(el, data[el]);
      }

      const response = await editTaskById(formdata, inf.id);

      if (response.status >= 200 && response.status < 300) {
        dispatch(
          callSnackbar({
            message: response.data.msg,
            type: 'success',
          })
        );
        handleClose();
        fetchTasks();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Menu
        elevation={1}
        anchorEl={actionsAnchorEl}
        open={open}
        onClose={handleToggleActions}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={(e) => {
            dispatch(
              drawersActions.openDrawer({
                type: drawerTypes.TASK,
                title: 'Edit TASK',
                crudType: crudTypes.EDIT,
                submitTitle: crudTypes.EDIT,
                data: selectedTask,
                refetchData: () => {
                  fetchTasks();
                },
              })
            );
            handleToggleActions(e);
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleDeleteTask();
          }}
        >
          Delete
        </MenuItem>
      </Menu>
      <Box
        sx={{
          width: '25rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: 2,
            borderBottom: 1,
            borderColor: 'divider',
          }}
        >
          <CustomTypography sx={{ fontWeight: 700 }}>Tasks</CustomTypography>
          <CustomTextButton
            onClick={() => {
              dispatch(
                drawersActions.openDrawer({
                  type: drawerTypes.TASK,
                  title: 'Add TASK',
                  crudType: crudTypes.ADD,
                  submitTitle: crudTypes.ADD,
                  refetchData: () => {
                    fetchTasks();
                  },
                })
              );
            }}
            text={'+ Add task'}
            sx={{ height: '2rem', my: 1.5 }}
          />
        </Box>

        <Box sx={{ height: '40rem', overflowY: 'scroll' }}>
          {tasks.map((t) => (
            <Box
              sx={{
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 2,
                py: 1,
                borderBottom: 1,
                borderColor: 'divider',
              }}
            >
              <Box sx={{ my: 0.6 }}>
                <CustomTypography
                  sx={{
                    fontWeight: 700,
                    fontSize: '16px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  {t.title}
                </CustomTypography>
              </Box>
              <Box sx={{ display: 'flex', my: 0.6 }}>
                <CustomTypography sx={{ fontSize: '14px' }}>
                  {t.public ? 'Public' : 'Private'}
                </CustomTypography>
                <CustomTypography sx={{ mx: 3, fontSize: '14px' }}>
                  Priority: {t.priority}
                </CustomTypography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  my: 0.6,
                }}
              >
                <StatusControllerComp
                  inf={t}
                  updateStatus={updateStatus}
                  tasks
                />
                {/* <StatusChip type={t.status} width={'max-content'} /> */}
                <IconButton
                  sx={{
                    boxShadow: '0px 0px 5px -2px',
                    padding: 0.3,
                    borderRadius: '5px',
                  }}
                  onClick={(e) => {
                    setSelectedTask(t);
                    handleToggleActions(e);
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
}

export default TasksDropDown;
