import { UpnizeCustomerBackend } from '../Axios';

export const getCustomerAppointmentList = async (rows, page, queries) => {
  let response = await UpnizeCustomerBackend.get(
    `/appointments/?rows=${rows}&page=${page}${queries ? queries : ''}`
  );
  return response;
};

export const getCustomerAppointmentById = async (id) => {
  let response = await UpnizeCustomerBackend.get(`/appointments/byId/${id}`);
  return response;
};

export const cancelCustomerAppointmentById = async (id) => {
  let response = await UpnizeCustomerBackend.put(`/appointments/cancel/${id}`);
  return response;
};

export const addCustomerAppointment = async (body) => {
  let response = await UpnizeCustomerBackend.post(`/appointments/add`, body);
  return response;
};
