import { Grid, Switch, Typography } from "@mui/material";
import { useState } from "react";

const LocalPayment = () => {
  const [
    ifServiceDoesntHaveExtraSkipStep,
    setIfServiceDoesntHaveExtraSkipStep,
  ] = useState(false);

  return (
    <>
      <Grid
        container
        item
        xs={12}
        sx={{
          marginY: 5,
        }}
      >
        <Grid
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "4px",
            paddingX: 2,
            paddingY: 1,
            height: "40px",
          }}
        >
          <Typography variant="subtitle2" color={"#C0D6DF"}>
            No settings found for this step
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default LocalPayment;
