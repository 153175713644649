import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {
  DataGrid,
  GridActionsCellItem,
  GridMoreVertIcon,
  gridClasses,
} from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import CustomTableFooter from '../../components/CustomTableFooter';
import DownloadIcon from '@mui/icons-material/Download';
import Modal from '../../components/Modal';
import AddGiftcard from './AddGiftcard';
import UsageHistory from '../../components/UsageHistory';
import CustomTextButton from '../../components/CustomButtons/CustomTextButton';
import CustomIconButton from '../../components/CustomButtons/CustomIconButton';
import {
  deleteGiftCardById,
  editGiftCardById,
  getGiftCardList,
} from '../../Api/tenant/giftCrads';
import { useMemo } from 'react';
import { debounce } from 'lodash';
import { defaultPageSizeOptions } from '../../constants';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CustomTooltip from '../../components/CustomTooltip';
import styled from '@emotion/styled';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useFilterBar from '../../hooks/useFilterBar';
import { getQueries } from '../../utils/GetQueries';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { useMediaQuery, useTheme } from '@mui/material';
import NoData from '../../components/NoData';

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    color: theme.palette.grey[500],
  },
}));

const filters = [
  {
    id: 1,
    title: 'Quick search',
    type: 'search',
    queryLabel: 'keyword',
  },
];

const Giftcard = () => {
  const [addGiftcardDrawer, setAddGiftcardDrawer] = useState(false);
  const [giftcards, setGiftcards] = useState([]);
  const [drawerType, setDrawerType] = useState('add');
  const [drawerData, setDrawerData] = useState(null);
  const [openModal, setOpenModal] = useState({ open: false, type: null });
  const [selectionModel, setSelectionModel] = useState([]);
  const [drawer, setDrawer] = useState(null);
  const [giftCards, setGiftCards] = useState(null);
  const [rows, setRows] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('lg'));
  const { FilterBar, state, FilterButton } = useFilterBar({
    filters,
  });

  const toggleAddGiftcardDrawer = () => {
    setAddGiftcardDrawer(!addGiftcardDrawer);
  };

  const handleOpenModal = () => {
    setOpenModal({ open: false, type: null });
  };

  const handleDeleteOpenModal = () => {
    setOpenModal({ open: true, type: 'delete-modal' });
  };

  const toggleUsageHistoryModal = (e) => {
    e.stopPropagation();
    setDrawer((prevState) =>
      prevState !== 'usage-history' ? 'usage-history' : ''
    );
  };

  // API
  const fetchGiftCards = () => {
    setTableLoading(true);
    getGiftCardList(pageSize, pageCount).then((res) => {
      setGiftCards(res.data);
    });
  };

  // fetch on render
  useEffect(() => {
    fetchGiftCards();
  }, []);

  // fetch on search
  useEffect(() => {
    getQueries(state).then((queries) => {
      setTableLoading(true);
      getGiftCardList(pageSize, pageCount, queries).then((res) => {
        setGiftCards(res.data);
      });
    });
  }, [state, pageSize, pageCount]);

  // update table rows on fetch
  useEffect(() => {
    if (giftCards) {
      setRows(giftCards);
      setTableLoading(false);
    }
  }, [giftCards]);

  const handleDeleteGiftCard = (id) => {
    deleteGiftCardById(id).then((res) => {
      fetchGiftCards();
    });
  };

  const handelHideRow = async (body, id) => {
    await editGiftCardById(body, id);
    fetchGiftCards();
  };

  //  Table Columns
  const columns = [
    {
      field: 'code',
      headerName: 'CODE',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'amount',
      headerName: 'BALANCE',
      minWidth: 180,
      flex: 1,
    },
    {
      field: 'spent',
      headerName: 'SPENT',
      minWidth: 180,
      flex: 1,
    },
    {
      field: 'leftover',
      headerName: 'LEFTOVER',
      minWidth: 180,
      flex: 1,
    },
    {
      field: 'usageHistory',
      headerName: 'USAGE HISTORY',
      minWidth: 150,
      renderCell: (params) => (
        <Grid
          container
          alignItems="center"
          sx={{ width: '100%', height: '100%' }}
          onClick={toggleUsageHistoryModal}
        >
          <ReceiptOutlinedIcon
            sx={{ color: '#adbfc7', cursor: 'pointer' }}
            onClick={() => params.row.id}
          />
        </Grid>
      ),
    },
    {
      field: 'actions',
      headerName: 'ACTIONS',
      type: 'actions',
      align: 'right',
      minWidth: 100,

      getActions: (params) => [
        <GridActionsCellItem
          label="hide"
          icon={
            params.row.visible ? (
              <CustomTooltip
                arrow
                placement="top"
                title="Disable item may not be available in other pages and network"
              >
                <Box
                  sx={{
                    backgroundColor: '#fff',
                    padding: '3px 5px',
                    borderRadius: '5px',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
                  }}
                >
                  <VisibilityIcon />
                </Box>
              </CustomTooltip>
            ) : (
              <Box
                sx={{
                  backgroundColor: '#fff',
                  padding: '3px 5px',
                  borderRadius: '5px',
                  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
                }}
              >
                <VisibilityOffIcon />
              </Box>
            )
          }
          onClick={() =>
            handelHideRow(
              {
                visible: !params.row.visible,
              },
              params.row.id
            )
          }
        />,
        <GridActionsCellItem
          label="Edit"
          onClick={() => {
            setDrawerType('edit');
            setDrawerData(params.row);
            toggleAddGiftcardDrawer();
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Delete"
          onClick={() => {
            setOpenModal({
              open: true,
              type: 'delete-modal',
              id: params.id,
            });
          }}
          showInMenu
        />,
      ],
    },
  ];

  return (
    <Grid
      item
      container
      sx={{
        minWidth: { xs: '100%' },
        px: '20px',
      }}
    >
      {openModal.type === 'delete-modal' ? (
        <Modal
          handleOpenModal={handleOpenModal}
          type={'DELETE'}
          open={openModal}
          handleDelete={handleDeleteGiftCard}
        />
      ) : (
        <Modal
          handleOpenModal={handleOpenModal}
          type={'UPLOAD'}
          open={openModal.open}
        />
      )}

      {addGiftcardDrawer && (
        <AddGiftcard
          toggleDrawer={toggleAddGiftcardDrawer}
          drawerType={drawerType}
          drawerData={drawerData}
          fetchGiftCards={fetchGiftCards}
        />
      )}
      {drawer === 'usage-history' && (
        <UsageHistory toggleDrawer={toggleUsageHistoryModal} />
      )}
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          // alignItems: 'flex-end',
          flexWrap: 'wrap',
        }}
      >
        <Typography
          sx={{
            marginTop: 2.5,
            marginBottom: 1,
            fontSize: 24,
            color: '#3A3A50',
          }}
        >
          Giftcard
        </Typography>
      </Grid>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <CustomTextButton
          text="+ Add giftcard"
          onClick={() => {
            setDrawerType('add');
            toggleAddGiftcardDrawer();
          }}
        />
        <Box sx={{ display: 'flex', gap: '10px', ml: '10px' }}>
          <CustomIconButton
            Icon={DownloadIcon}
            toolTipText="EXPORT TO CSV"
            onClick={() => setOpenModal({ open: true, type: 'upload' })}
          />
          {FilterButton}
        </Box>
      </Box>
      {/* --------------------SEARCH------------------------------- */}
      {FilterBar}
      {/* --------------------TABLE--------------------------------- */}
      <Grid
        container
        item
        justifyContent="space-between"
        sx={{ marginTop: '20px' }}
      >
        <div style={{ width: '100%' }}>
          <StripedDataGrid
            sx={{
              border: 0,
              '& .MuiDataGrid-virtualScroller': {
                minHeight: '300px !important',
              },
              '& .MuiDataGrid-columnHeader': {
                backgroundColor: '#EFF1FC',
                color: '#3A3A50',
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                fontSize: '12px',
                fontWeight: '900 !important',
              },
              '& .MuiDataGrid-main, .MuiDataGrid-footerContainer': {
                backgroundColor: 'white',
                border: 'none',
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
            }}
            rows={rows}
            columns={columns}
            autoHeight
            getRowClassName={(params) => {
              return !params.row.visible ? 'even' : 'odd';
            }}
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            loading={tableLoading}
            selectionModel={selectionModel}
            rowCount={1000}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => {
              setPageSize(newPageSize);
            }}
            rowsPerPageOptions={defaultPageSizeOptions}
            page={pageCount - 1}
            onPageChange={(newPage) => {
              setPageCount(newPage + 1);
            }}
            paginationMode="server"
            components={{
              NoRowsOverlay: NoData,
              NoResultsOverlay: NoData,
              MoreActionsIcon: () => (
                <Box
                  sx={{
                    backgroundColor: '#fff',
                    padding: '3px 5px',
                    borderRadius: '5px',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
                  }}
                >
                  <MoreVertIcon sx={{}} />
                </Box>
              ),
              Footer: () => (
                <CustomTableFooter
                  selectionModel={selectionModel}
                  handleDeleteOpenModal={handleDeleteOpenModal}
                />
              ),
              MoreActionsIcon: () => (
                <GridMoreVertIcon
                  sx={{
                    backgroundColor: 'white',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
                    borderRadius: '4px',
                    padding: '3px',
                    cursor: 'pointer',
                  }}
                />
              ),
              NoRowsOverlay: () => {
                return (
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'Center',
                      gap: '20px',
                    }}
                  >
                    <img
                      src="https://user-images.githubusercontent.com/109676648/254145031-57027cd6-fecd-4ce2-96fc-9d891c8de53a.svg"
                      alt="no data"
                      style={{
                        width: '100px',
                        height: '100px',
                      }}
                    />
                    No data at the moment 😔
                  </Box>
                );
              },
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default Giftcard;
