
import React from "react";
import { Box } from "@mui/system";
import { Calendar } from "react-multi-date-picker";

const HoliDays = ({ holidays, setHolidays }) => {
  return (
    <Box>
      <Calendar
        multiple
        fullYear
        value={holidays}
        format="DD-MM-YYYY"
        onChange={(selectedDates) => {
          setHolidays(selectedDates.map((date) => date.format("DD-MM-YYYY")))
        }}
      />
    </Box>
  );
};

export default HoliDays;
