import { Grid, useMediaQuery } from '@mui/material';
import { useEffect } from 'react';
import { useState } from 'react';
import ProfileSettingsParent from '../../../components/CustomerSettings/ProfileSettingsParent';
import ConnectSettings from '../../../components/CustomerSettings/ConnectSettings';
import InvoiceDetails from '../../../components/CustomerSettings/InvoiceDetails';
import SettingsSidebar from '../../../components/CustomerSettingsSidebar';
import { useSearchParams } from 'react-router-dom';
import { getCustomerSettings } from '../../../Api/customer/settings';
import { useDispatch } from 'react-redux';
import { fetchUser } from '../../../redux/slices/user.slice.js';
import DisableSectionWrapper from '../../../components/DisableSectionWrapper';

const Settings = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const queryMain = searchParams.get('main');
  const querySub = searchParams.get('sub');
  const [activeTab, setActiveTab] = useState({
    main: queryMain ? queryMain : 'profile-settings',
    sub: querySub ? querySub : 'profile-settings',
  });
  const [openMenu, setOpenMenu] = useState(false);
  const [currentSettings, setCurrentSettings] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (queryMain || querySub) {
      setActiveTab({
        main: queryMain && queryMain,
        sub: querySub && querySub,
      });
    }
    setSearchParams();
  }, [searchParams]);

  const mediaQuery1250 = useMediaQuery('(min-width:1250px)');

  const handleOpenMenu = () => {
    setOpenMenu(!openMenu);
  };

  const handleActiveTab = (activeTab, activeSubTab = null) => {
    setActiveTab({ main: activeTab, sub: activeSubTab });
  };

  useEffect(() => {
    setOpenMenu(false);
  }, [activeTab]);

  // API

  const fetchCurrentSettings = async () => {
    getCustomerSettings().then((res) => {
      if (!currentSettings) {
        dispatch(fetchUser())
          .unwrap()
          .then(() => {
            setCurrentSettings(res.data);
          });
      }
    });
  };

  useEffect(() => {
    fetchCurrentSettings();
  }, []);

  return (
    <Grid container xs={12} sx={{ position: 'relative' }}>
      <SettingsSidebar
        mediaQuery1250={mediaQuery1250}
        activeTab={activeTab}
        handleActiveTab={handleActiveTab}
        openMenu={openMenu}
        handleOpenMenu={handleOpenMenu}
      />
      <Grid
        sm={12}
        md={mediaQuery1250 ? 8.5 : 7.5}
        sx={{
          padding: { xs: '0 32px', md: '0 32px' },
          width: '100%',
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        {activeTab.main === 'profile-settings' && (
          <ProfileSettingsParent
            subTab={activeTab.sub}
            fetchCurrentSettings={fetchCurrentSettings}
            currentSettings={currentSettings}
          />
        )}

        {activeTab.main === 'connect-settings' && (
          <DisableSectionWrapper>
            <ConnectSettings
              subTab={activeTab.sub}
              fetchCurrentSettings={fetchCurrentSettings}
              currentSettings={currentSettings}
            />
          </DisableSectionWrapper>
        )}

        {activeTab.main === 'invoice-settings' && (
          <InvoiceDetails
            fetchCurrentSettings={fetchCurrentSettings}
            currentSettings={currentSettings}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Settings;
