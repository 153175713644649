import React, { useLayoutEffect, useState } from 'react';
import Drawer from '../../components/Drawer';
import DrawerFooter from '../../components/DrawerFooter';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import {
  FormHelperText,
  TextareaAutosize,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ProfileImageField from '../../components/ProfileImageField';
import CustomTextButton from '../../components/CustomButtons/CustomTextButton';
import { addProduct, editProductById } from '../../Api/tenant/products';
import SelectCategory from '../../components/AysncAutoComplete/SelectCategory';

const AddProduct = ({
  toggleDrawer,
  drawerType,
  drawerData = null,
  fetchProducts,
}) => {
  const [image, setImage] = useState(null);
  const [formData, setFormData] = useState({
    title: null,
    category: null,
    description: null,
    offerPrice: null,
    salePrice: null,
    tax: null,
    unit: null,
    quantity: null,
    supplier: null,
    sku: null,
    itemNo: null,
    purchasePrice: null,
  });
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('sm'));

  const handleFormData = (fieldName, value) => {
    setFormData((prev) => {
      return { ...prev, [fieldName]: value };
    });
  };

  // API

  useLayoutEffect(() => {
    if (drawerType !== 'add') {
      const { title, category, description, offerPrice, salePrice } =
        drawerData;
      setFormData({
        title,
        category: category.id,
        description,
        offerPrice,
        salePrice,
      });
      setImage(drawerData.image);
    }
  }, [drawerData, drawerType]);

  // error handling
  const [errors, setErrors] = useState({});
  const requiredFields = [
    'title',
    'category',
    'description',
    'offerPrice',
    'salePrice',
    'tax',
    'unit',
    'quantity',
    'supplier',
    'sku',
  ];

  const handleAddProduct = () => {
    let data = formData;

    let err = {};
    for (const key in data) {
      if (!data[key] && requiredFields.includes(key)) {
        err = { ...err, [key]: true };
        console.log('s', data[key], data);
      }
      if (
        Array.isArray(data[key]) &&
        data[key].length === 0 &&
        requiredFields.includes(key)
      ) {
        console.log(data[key]);
        err = { ...err, [key]: true };
      }
    }
    setErrors({ ...err });

    if (Object.keys(err).length === 0) {
      let formdata = new FormData();
      for (const el in formData) {
        formdata.append(el, formData[el]);
      }
      image && formdata.append('image', image);
      formdata.append('categoryId', formData.category);

      // TODO: delete this on new modal
      // no input fields for this yet so we are going to add a placeholder
      // formdata.append('supplier', formData.offerPrice);
      // formdata.append('sku', formData.offerPrice);
      // formdata.append('unit', formData.offerPrice);
      // formdata.append('tax', formData.offerPrice);
      // formdata.append('quantity', formData.offerPrice);

      addProduct(formdata).then((res) => {
        toggleDrawer();
        fetchProducts();
      });
    }
  };

  const handleEditProduct = () => {
    let formdata = new FormData();
    for (const el in formData) {
      formdata.append(el, formData[el]);
    }
    image && formdata.append('image', image);
    formdata.append('categoryId', formData.category);

    editProductById(formdata, drawerData.id).then((res) => {
      toggleDrawer();
      fetchProducts();
    });
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={2}>
        <Drawer
          type={drawerType === 'add' ? 'ADD' : 'EDIT'}
          title={drawerType === 'add' ? 'Add Product' : 'Edit Product'}
          toggleDrawer={toggleDrawer}
        >
          {/* -----------FORM----- */}
          <Grid sx={{ height: '100%', overflowY: 'auto', width: '100%' }}>
            <Grid
              sx={{
                paddingX: 3,
                paddingTop: 3,
                height: '100%',
                overflowY: 'auto',
                paddingRight: { sm: '5px' },
                width: '100%',
              }}
            >
              <Grid container>
                <Grid xs={12} sx={{ paddingRight: { sm: 2 }, marginTop: 1 }}>
                  <ProfileImageField image={image} setImage={setImage} />
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  xs={12}
                  sm={6}
                  sx={{ paddingRight: { sm: 2 }, marginTop: 1 }}
                >
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">
                      Title <span style={{ color: 'red' }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      required
                      variant="outlined"
                      value={formData.title}
                      onChange={(e) => {
                        handleFormData('title', e.target.value);
                        setErrors({ ...errors, title: false });
                      }}
                      sx={{
                        marginBottom: '1rem',
                        width: '100%',
                        fontSize: '14px',
                      }}
                      helperText={errors.title ? 'Value is missing' : ' '}
                      error={errors.title}
                    />
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  sm={6}
                  sx={{ paddingRight: { sm: 2 }, marginTop: 1 }}
                >
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">
                      Category <span style={{ color: 'red' }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <SelectCategory
                      type="product"
                      value={formData.category}
                      onChange={(e, v) => {
                        handleFormData('category', v?.id);
                      }}
                      sx={{ marginBottom: '1rem' }}
                      helperText={errors.category ? 'Value is missing' : ' '}
                      error={errors.category}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container>
                <Grid xs={12} sx={{ paddingRight: { sm: 2 }, marginTop: 1 }}>
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">
                      Description <span style={{ color: 'red' }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      InputProps={{
                        inputComponent: TextareaAutosize,
                        inputProps: {
                          style: {
                            resize: 'auto',
                            height: '120px',
                          },
                        },
                      }}
                      size="small"
                      required
                      variant="outlined"
                      placeholder="Describe your food menu here..."
                      style={{
                        minHeight: '120px',
                        fontSize: '16px',
                        width: '100%',
                      }}
                      onChange={(e) => {
                        handleFormData('description', e.target.value);
                        setErrors({ ...errors, description: false });
                      }}
                      value={formData.description}
                      // helperText={
                      //   errors.description ? 'Value is missing' : ' '
                      // }
                      error={errors.description}
                    />
                    <FormHelperText error>
                      {errors.description ? 'Value is missing' : ' '}
                    </FormHelperText>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container>
                <Grid
                  xs={12}
                  sm={4}
                  sx={{ paddingRight: { sm: 2 }, marginTop: 1 }}
                >
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">
                      Standard price <span style={{ color: 'red' }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      required
                      variant="outlined"
                      sx={{
                        marginBottom: '1rem',
                        width: '100%',
                        fontSize: '14px',
                      }}
                      onChange={(e) => {
                        handleFormData('salePrice', e.target.value);
                        setErrors({ ...errors, salePrice: false });
                      }}
                      value={formData.salePrice}
                      helperText={errors.salePrice ? 'Value is missing' : ' '}
                      error={errors.salePrice}
                    />
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  sm={4}
                  sx={{ paddingRight: { sm: 2 }, marginTop: 1 }}
                >
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">
                      Sale price <span style={{ color: 'red' }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      required
                      variant="outlined"
                      sx={{
                        marginBottom: '1rem',
                        width: '100%',
                        fontSize: '14px',
                      }}
                      onChange={(e) => {
                        handleFormData('offerPrice', e.target.value);
                        setErrors({ ...errors, offerPrice: false });
                      }}
                      value={formData.offerPrice}
                      helperText={errors.offerPrice ? 'Value is missing' : ' '}
                      error={errors.offerPrice}
                    />
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  sm={4}
                  sx={{ paddingRight: { sm: 2 }, marginTop: 1 }}
                >
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">Tax</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      required
                      variant="outlined"
                      sx={{
                        marginBottom: '1rem',
                        width: '100%',
                        fontSize: '14px',
                      }}
                      onChange={(e) => {
                        handleFormData('tax', e.target.value);
                        setErrors({ ...errors, tax: false });
                      }}
                      value={formData.tax}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container>
                <Grid
                  xs={12}
                  sm={4}
                  sx={{ paddingRight: { sm: 2 }, marginTop: 1 }}
                >
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">Unit</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      required
                      variant="outlined"
                      sx={{
                        marginBottom: '1rem',
                        width: '100%',
                        fontSize: '14px',
                      }}
                      onChange={(e) => {
                        handleFormData('unit', e.target.value);
                      }}
                      value={formData.unit}
                    />
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  sm={4}
                  sx={{ paddingRight: { sm: 2 }, marginTop: 1 }}
                >
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">Quantity</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      required
                      variant="outlined"
                      sx={{
                        marginBottom: '1rem',
                        width: '100%',
                        fontSize: '14px',
                      }}
                      onChange={(e) => {
                        handleFormData('quantity', e.target.value);
                      }}
                      value={formData.quantity}
                    />
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  sm={4}
                  sx={{ paddingRight: { sm: 2 }, marginTop: 1 }}
                >
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">Item No</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      required
                      variant="outlined"
                      sx={{
                        marginBottom: '1rem',
                        width: '100%',
                        fontSize: '14px',
                      }}
                      onChange={(e) => {
                        handleFormData('tax', e.target.value);
                        setErrors({ ...errors, tax: false });
                      }}
                      value={formData.itemNo}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container>
                <Grid
                  xs={12}
                  sm={4}
                  sx={{ paddingRight: { sm: 2 }, marginTop: 1 }}
                >
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">Supplier</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      required
                      variant="outlined"
                      sx={{
                        marginBottom: '1rem',
                        width: '100%',
                        fontSize: '14px',
                      }}
                      onChange={(e) => {
                        handleFormData('supplier', e.target.value);
                      }}
                      value={formData.supplier}
                    />
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  sm={4}
                  sx={{ paddingRight: { sm: 2 }, marginTop: 1 }}
                >
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">SKU</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      required
                      variant="outlined"
                      sx={{
                        marginBottom: '1rem',
                        width: '100%',
                        fontSize: '14px',
                      }}
                      onChange={(e) => {
                        handleFormData('sku', e.target.value);
                      }}
                      value={formData.sku}
                    />
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  sm={4}
                  sx={{ paddingRight: { sm: 2 }, marginTop: 1 }}
                >
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">Purchase price</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      required
                      variant="outlined"
                      sx={{
                        marginBottom: '1rem',
                        width: '100%',
                        fontSize: '14px',
                      }}
                      onChange={(e) => {
                        handleFormData('purchasePrice', e.target.value);
                      }}
                      value={formData.purchasePrice}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* -----Button */}

          <DrawerFooter>
            <>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: isSmallScreen ? 'flex-start' : 'flex-end',
                }}
              ></div>
              <div
                style={{
                  display: 'flex',
                  gap: '15px',
                  justifyContent: isSmallScreen ? 'space-between' : 'flex-end',
                  width: isSmallScreen ? '100%' : 'auto',
                  alignItems: 'center',
                }}
              >
                <CustomTextButton
                  variant="contained"
                  size="large"
                  onClick={() => {
                    toggleDrawer();
                  }}
                >
                  Cancel
                </CustomTextButton>
                <CustomTextButton
                  type="blue"
                  variant="contained"
                  size="large"
                  onClick={() => {
                    if (drawerType === 'add') {
                      handleAddProduct();
                    } else {
                      handleEditProduct();
                    }
                  }}
                >
                  {drawerType === 'add' ? 'Add' : 'Save'}
                </CustomTextButton>
              </div>
            </>
          </DrawerFooter>
        </Drawer>
      </Grid>
    </Grid>
  );
};

export default AddProduct;
