import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {
  DataGrid,
  GridActionsCellItem,
  GridMoreVertIcon,
  gridClasses,
} from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import CustomTableFooter from '../../components/CustomTableFooter';
import Modal from '../../components/Modal';
import AddCoupon from './AddCoupon';
import UsageHistory from './UsageHistory';
import StatusChip from '../../components/StatusChip';
import CustomTextButton from '../../components/CustomButtons/CustomTextButton';
import {
  deleteCouponById,
  editCouponById,
  getCouponList,
} from '../../Api/tenant/coupon';
import { defaultPageSizeOptions } from '../../constants';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CustomTooltip from '../../components/CustomTooltip';
import styled from '@emotion/styled';
import { Box, Popper, useMediaQuery, useTheme } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useFilterBar from '../../hooks/useFilterBar';
import { getQueries } from '../../utils/GetQueries';
import NoData from '../../components/NoData';

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    color: theme.palette.grey[500],
  },
}));

const filters = [
  {
    id: 1,
    title: 'Quick search',
    type: 'search',
    queryLabel: 'keyword',
  },
];

const Coupon = () => {
  const [addCouponDrawer, setAddCouponDrawer] = useState(false);
  const [coupons, setCoupons] = useState(null);
  const [rows, setRows] = useState([]);
  const [drawerType, setDrawerType] = useState('add');
  const [drawerData, setDrawerData] = useState(null);
  const [openModal, setOpenModal] = useState({ open: false, type: null });
  const [selectionModel, setSelectionModel] = useState([]);
  const [drawer, setDrawer] = useState(null);
  const [tableLoading, setTableLoading] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('lg'));
  const { FilterBar, state, FilterButton } = useFilterBar({
    filters,
  });
  // clear filter menu states
  const [anchorEl, setAnchorEl] = useState(null);
  const isClearFiltersMenu = Boolean(anchorEl);
  const id = isClearFiltersMenu ? 'simple-popper' : undefined;

  const toggleDrawer = () => {
    setAddCouponDrawer(!addCouponDrawer);
  };

  const handleOpenModal = () => {
    setOpenModal({ open: false, type: null });
  };

  const handleDeleteOpenModal = () => {
    setOpenModal({ open: true, type: 'delete-modal' });
  };

  const toggleUsageHistoryModal = (e) => {
    e.stopPropagation();
    setDrawer((prevState) =>
      prevState !== 'usage-history' ? 'usage-history' : ''
    );
  };

  // API
  const fetchCoupons = async () => {
    setTableLoading(true);
    getCouponList(pageSize, pageCount).then((res) => {
      setCoupons(res.data);
    });
  };

  // fetch on render
  useEffect(() => {
    fetchCoupons();
  }, []);

  // fetch on search
  useEffect(() => {
    getQueries(state).then((queries) => {
      setTableLoading(true);
      getCouponList(pageSize, pageCount, queries).then((res) => {
        setCoupons(res.data);
      });
    });
  }, [state, pageSize, pageCount]);

  // update table rows on fetch
  useEffect(() => {
    if (coupons) {
      setRows(coupons);
      setTableLoading(false);
    }
  }, [coupons]);

  const handelHideRow = async (body, id) => {
    await editCouponById(body, id);
    fetchCoupons();
  };

  const handleDeleteCoupons = async (id) => {
    deleteCouponById(id).then((res) => {
      fetchCoupons();
    });
  };

  //  Table Columns
  const columns = [
    {
      field: 'code',
      headerName: 'CODE',
      flex: 1,
      minWidth: 180,
    },
    {
      field: 'dValue',
      headerName: 'DISCOUNT',
      minWidth: 160,
      flex: 1,
      valueGetter: (params) =>
        params.row.dValue + (' ' + (params.row.dType ? '$' : '%')),
    },
    {
      field: 'limit',
      headerName: 'USAGE LIMIT',
      minWidth: 160,
      flex: 1,
    },
    {
      field: 'usage',
      headerName: 'TIMES USED',
      minWidth: 160,
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'STATUS',
      type: 'singleSelect',
      valueOptions: ['Active', 'Expired'],
      flex: 1,
      minWidth: 120,
      renderCell: (params) => (
        <strong>
          {params.value == 'Active' ? (
            <StatusChip type="active" />
          ) : (
            <StatusChip type="inActive" />
          )}
        </strong>
      ),
    },
    {
      field: 'usageHistory',
      headerName: 'USAGE HISTORY',

      minWidth: 160,
      renderCell: (params) => (
        <Grid
          container
          alignItems="center"
          sx={{ width: '100%', height: '100%' }}
          onClick={toggleUsageHistoryModal}
        >
          <ReceiptOutlinedIcon
            sx={{ color: '#adbfc7', cursor: 'pointer' }}
            onClick={() => params.row.id}
          />
        </Grid>
      ),
    },
    {
      field: 'actions',
      headerName: 'ACTIONS',
      type: 'actions',
      align: 'right',
      minWidth: 100,
      maxWidth: 100,
      getActions: (params) => [
        <GridActionsCellItem
          label="hide"
          icon={
            params.row.visible ? (
              <CustomTooltip
                arrow
                placement="top"
                title="Disable item may not be available in other pages and network"
              >
                <Box
                  sx={{
                    backgroundColor: '#fff',
                    padding: '3px 5px',
                    borderRadius: '5px',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
                  }}
                >
                  <VisibilityIcon />
                </Box>
              </CustomTooltip>
            ) : (
              <Box
                sx={{
                  backgroundColor: '#fff',
                  padding: '3px 5px',
                  borderRadius: '5px',
                  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
                }}
              >
                <VisibilityOffIcon />
              </Box>
            )
          }
          onClick={() =>
            handelHideRow(
              {
                visible: !params.row.visible,
                status: params.row.visible ? 'Inactive' : 'Active',
              },
              params.row.id
            )
          }
        />,
        <GridActionsCellItem
          label="Edit"
          onClick={() => {
            setDrawerType('edit');
            setDrawerData(params.row);
            toggleDrawer();
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Delete"
          onClick={() => {
            setOpenModal({
              open: true,
              type: 'delete-modal',
              id: params.id,
            });
          }}
          showInMenu
        />,
      ],
    },
  ];
  return (
    <Grid
      item
      container
      sx={{
        minWidth: { xs: '100%' },
        px: '20px',
      }}
    >
      {openModal.type === 'delete-modal' && (
        <Modal
          handleOpenModal={handleOpenModal}
          type={'DELETE'}
          open={openModal}
          handleDelete={handleDeleteCoupons}
        />
      )}

      {addCouponDrawer && (
        <AddCoupon
          toggleDrawer={toggleDrawer}
          drawerType={drawerType}
          drawerData={drawerData}
          fetchCoupons={fetchCoupons}
        />
      )}
      {drawer === 'usage-history' && (
        <UsageHistory toggleDrawer={toggleUsageHistoryModal} />
      )}
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          // alignItems: 'flex-end',
          flexWrap: 'wrap',
        }}
      >
        <Typography
          sx={{
            marginTop: 2.5,
            marginBottom: 1,
            fontSize: 24,
            color: '#3A3A50',
          }}
        >
          Coupon
        </Typography>
        {/* <Button
          size="large"
          sx={{
            width: { xs: '100%', sm: 'auto' },
            marginTop: 5,
            marginBottom: 1,
          }}
          variant="contained"
          onClick={() => {
            setDrawerType('add');
            toggleDrawer();
          }}
        >
          + ADD COUPON
        </Button> */}
      </Grid>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <CustomTextButton
          text="+ Add coupon"
          onClick={() => {
            setDrawerType('add');
            toggleDrawer();
          }}
        />

        <Box sx={{ display: 'flex', gap: '10px', ml: '10px' }}>
          {FilterButton}
        </Box>
      </Box>
      {/* --------------------SEARCH------------------------------- */}
      {FilterBar}
      {/* --------------------TABLE--------------------------------- */}
      <Grid
        container
        item
        justifyContent="space-between"
        sx={{ marginTop: '20px' }}
      >
        <div style={{ width: '100%' }}>
          <StripedDataGrid
            sx={{
              border: 0,
              '& .MuiDataGrid-virtualScroller': {
                minHeight: '300px !important',
              },
              '& .MuiDataGrid-columnHeader': {
                backgroundColor: '#EFF1FC',
                color: '#3A3A50',
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                fontSize: '12px',
                fontWeight: '900 !important',
              },
              '& .MuiDataGrid-main, .MuiDataGrid-footerContainer': {
                backgroundColor: 'white',
                border: 'none',
              },
              minHeight: '60vh',
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
            }}
            columns={columns}
            rows={rows}
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            autoHeight
            getRowClassName={(params) => {
              return !params.row.visible ? 'even' : 'odd';
            }}
            loading={tableLoading}
            selectionModel={selectionModel}
            rowCount={1000}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => {
              setPageSize(newPageSize);
            }}
            rowsPerPageOptions={defaultPageSizeOptions}
            page={pageCount - 1}
            onPageChange={(newPage) => {
              setPageCount(newPage + 1);
            }}
            paginationMode="server"
            components={{
              MoreActionsIcon: () => (
                <Box
                  sx={{
                    backgroundColor: '#fff',
                    padding: '3px 5px',
                    borderRadius: '5px',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
                  }}
                >
                  <MoreVertIcon sx={{}} />
                </Box>
              ),
              NoRowsOverlay: NoData,
              NoResultsOverlay: NoData,
              Footer: () => (
                <CustomTableFooter
                  selectionModel={selectionModel}
                  handleDeleteOpenModal={handleDeleteOpenModal}
                />
              ),
              MoreActionsIcon: () => (
                <GridMoreVertIcon
                  sx={{
                    backgroundColor: 'white',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
                    borderRadius: '4px',
                    padding: '3px',
                    cursor: 'pointer',
                  }}
                />
              ),
              NoRowsOverlay: () => {
                return (
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'Center',
                      gap: '20px',
                    }}
                  >
                    <img
                      src="https://user-images.githubusercontent.com/109676648/254145031-57027cd6-fecd-4ce2-96fc-9d891c8de53a.svg"
                      alt="no data"
                      style={{
                        width: '100px',
                        height: '100px',
                      }}
                    />
                    No data at the moment 😔
                  </Box>
                );
              },
            }}
            dataSet={'Commodity'}
            rowLength={100}
            maxColumns={6}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default Coupon;
