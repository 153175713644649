import React from 'react';
import PhoneInput from 'react-phone-number-input';
import { useSelector } from 'react-redux';
import { selectUser } from '../../redux/slices/user.slice.js';

function CustomPhoneInput({ value, onChange, sx,style, ...props }) {
  const { user, isUserLoading } = useSelector(selectUser);

  return (
    !isUserLoading && (
      <PhoneInput
        sx={{
          ...sx,
        }}
        style={{
          ...style,
        }}
        placeholder="Enter phone number"
        value={value}
        onChange={onChange}
        defaultCountry={user?.country}
        {...props}
      />
    )
  );
}

export default CustomPhoneInput;
