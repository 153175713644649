import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {
  DataGrid,
  GridActionsCellItem,
  GridMoreVertIcon,
} from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import axios from 'axios';
import SearchIcon from '@mui/icons-material/Search';
import { SERVER_URL } from './configs';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CustomTableFooter from '../../components/CustomTableFooter';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Card,
  CardContent,
  FormControlLabel,
  IconButton,
  Link,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  Switch,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import AddWorkflow from './AddWorkflow';
import EditWorkflow from './EditWorkflow';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Modal from '../../components/Modal';
import CustomTextButton from '../../components/CustomButtons/CustomTextButton';
import useFilterBar from '../../hooks/useFilterBar';
import { getQueries } from '../../utils/GetQueries';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';

const filters = [
  {
    id: 1,
    title: 'Quick search',
    type: 'search',
    queryLabel: 'keyword',
  },
  {
    id: 2,
    title: 'Event type',
    type: 'select',
    options: [
      'New booking',
      'Booking rescheduled',
      'Booking status changed',
      'Booking starts',
      'Booking ends',
      'New customer created',
      'New rating',
    ],
    isOpen: false,
    search: '',
    queryLabel: 'type',
  },
];

const row = [
  {
    id: 1,
    name: 'New booking to Customer & staff',
    event: 'New booking',
    isEnabled: true,
    actions: [
      {
        name: 'email',
        value: 'Send Email',
        actions: [
          { to: 's', from: 's', body: 's' },
          { to: 's', from: 's', body: 's' },
          { to: 's', from: 's', body: 's' },
        ],
      },
    ],
  },
  {
    id: 2,
    name: 'Booking is Approved',
    event: 'Booking status changed',
    isEnabled: true,
    actions: [
      {
        name: 'email',
        value: 'Send Email',
        actions: [
          { to: 's', from: 's', body: 's' },
          { to: 's', from: 's', body: 's' },
          { to: 's', from: 's', body: 's' },
        ],
      },
      {
        name: 'booking',
        value: 'Set Booking Status',
        actions: [
          { to: 's', from: 's', body: 's' },
          { to: 's', from: 's', body: 's' },
        ],
      },
    ],
  },
  {
    id: 3,
    name: 'Reminder before Appointment starts',
    event: 'Booking starts',
    isEnabled: true,
    actions: [
      {
        name: 'email',
        value: 'Send Email',
        actions: [{ to: 's', from: 's', body: 's' }],
      },
    ],
  },
  {
    id: 4,
    name: 'Reminder after Appointment ends',
    event: 'Booking starts',
    isEnabled: true,
    actions: [
      {
        name: 'email',
        value: 'Send Email',
        actions: [{ to: 's', from: 's', body: 's' }],
      },
    ],
  },
  {
    id: 5,
    name: 'Customer details',
    event: 'New customer created',
    isEnabled: true,
    actions: [
      {
        name: 'email',
        value: 'Send Email',
        actions: [{ to: 's', from: 's', body: 's' }],
      },
    ],
  },
];

const ActionSection = ({ isRowEnabled, handlers }) => {
  const { handleToggle, handleDelete, handleEdit } = handlers;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggleFunc = () => {
    handleToggle();
    handleClose();
  };

  return (
    <Grid sx={{ marginLeft: '5px' }}>
      <Grid container alignItems="center">
        <Grid container xs={12} justifyContent="flex-end" alignItems="center">
          <IconButton onClick={handleClick}>
            <GridMoreVertIcon
              sx={{
                backgroundColor: 'white',
                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
                borderRadius: '4px',
                padding: '3px',
                cursor: 'pointer',
              }}
            />
          </IconButton>
        </Grid>
      </Grid>

      <Menu
        id="long-menu"
        elevation={1}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {!isRowEnabled && (
          <MenuItem onClick={handleToggleFunc}>Enable</MenuItem>
        )}
        {isRowEnabled && (
          <MenuItem onClick={handleToggleFunc}>Disable</MenuItem>
        )}
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
    </Grid>
  );
};

const Workflow = () => {
  const [addWorkflowDrawer, setAddWorkflowDrawer] = useState(false);
  const [editWorkflowDrawer, setEditWorkflowDrawer] = useState(false);
  const [workflows, setWorkflows] = useState([]);
  const [editWorkflowDrawerType, setEditWorkflowDrawerType] = useState({
    type: 'EVENT',
    eventType: 'BOOKING',
  });
  const [drawerType, setDrawerType] = useState('add');
  const [isEditSubpage, setIsEditSubpage] = useState(); // null or data
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterMenuList, setFilterMenuList] = useState([]);
  const [editWorkflowDrawerData, setEditWorkflowDrawerData] = useState(null);
  const [drawerData, setDrawerData] = useState(null);
  const [openModal, setOpenModal] = useState({ open: false, type: null });
  const [selectionModel, setSelectionModel] = useState([]);
  const [drawer, setDrawer] = useState(null);
  const [menuFilterInput, setMenuFilterInput] = useState('');
  const [editNameValue, setEditNameValue] = useState();
  const [editActivatedValue, setEditActivatedValue] = useState(true);
  const [filterMenuValue, setFilterMenuValue] = useState({
    eventType: '',
  });
  const [rows, setRows] = useState(row);
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('lg'));
  const { FilterBar, state, FilterButton } = useFilterBar({
    filters,
  });

  const handleCheckedMenuFilter = (value) => {
    const menuName = anchorEl.getAttribute('name');

    setFilterMenuValue({
      ...filterMenuValue,
      [menuName]: value,
    });

    handleMenuClose();
  };

  const toggleAddWorkflowDrawer = () => {
    setAddWorkflowDrawer(!addWorkflowDrawer);
  };

  const toggleEditWorkflowDrawer = () => {
    setEditWorkflowDrawer(!editWorkflowDrawer);
  };

  const fetchWorkflows = async () => {
    try {
      const response = await axios.post(SERVER_URL + '/get-workflow');
      //setWorkflows(response.data.data);
    } catch (error) {}
  };

  const deleteWorkflow = async (id) => {
    try {
      const response = await axios.post(SERVER_URL + '/delete-workflow', {
        workflow_id: id,
      });
      fetchWorkflows();
    } catch (error) {}
  };
  useEffect(() => {
    fetchWorkflows();
  }, []);

  const handleOpenModal = () => {
    setOpenModal({ open: false, type: null });
  };

  const handleDeleteOpenModal = () => {
    setOpenModal({ open: true, type: 'delete-modal' });
  };

  const toggleUsageHistoryModal = (e) => {
    e.stopPropagation();
    setDrawer((prevState) =>
      prevState !== 'usage-history' ? 'usage-history' : ''
    );
  };

  const handleToggleAction = (id) => {
    setRows((prevState) => {
      return prevState.map((row) => {
        if (row.id !== id) return row;
        return {
          ...row,
          isEnabled: !row.isEnabled,
        };
      });
    });
  };

  const handleDeleteAction = (params) => {
    setOpenModal({ open: true, type: 'delete-modal' });
    deleteWorkflow(params.id);
  };

  const handleEditAction = (params) => {
    setIsEditSubpage(params);
    console.log(params);
  };

  // edit subpage handlers

  const handleEditName = (e) => {
    setEditNameValue(e.target.value);
  };

  const handleEditActivated = (e) => {
    setEditActivatedValue(e.target.checked);
  };

  const handleEditSave = (e) => {
    // TODO: api
    // editActivatedValue; // string
    // editNameValue; // bool
  };

  const columns = [
    {
      field: 'name',
      headerName: 'NAME',
      flex: 1,
      minWidth: 300,
    },
    {
      field: 'event',
      headerName: 'EVENT',
      flex: 1,
      minWidth: 270,
    },
    {
      field: 'actions',
      headerName: 'ACTIONS',
      type: 'actions',
      align: 'right',
      minWidth: 300,
      renderCell: (params) => (
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid container xs={7} flexDirection="column" sx={{ marginY: 1 }}>
            {params.value &&
              params.value.map((action) => (
                <div>
                  <Typography variant="subtitle2" my={0.5}>
                    {action.value}
                    {action.actions.length === 1 ? (
                      ''
                    ) : (
                      <span
                        name="times-span"
                        style={{
                          background: '#ffeac6',
                          color: '#dd9311',
                          padding: '.25rem .4rem',
                          height: '24px',
                          minWidth: '24px',
                          lineHeight: '14px',
                          cursor: 'default',
                          fontSize: '12px',
                          fontWeight: 600,
                          borderRadius: '2px',
                          marginLeft: '5px',
                        }}
                      >
                        x{action.actions.length}
                      </span>
                    )}
                  </Typography>
                </div>
              ))}
          </Grid>
          <Grid
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            {!params.row.isEnabled && (
              <VisibilityOffIcon sx={{ fontSize: '20px', color: '#757575' }} />
            )}
            <ActionSection
              isRowEnabled={params.row.isEnabled}
              handlers={{
                handleEdit: () => handleEditAction(params),
                handleDelete: () => handleDeleteAction(),
                handleToggle: () => handleToggleAction(params.id),
              }}
            />
          </Grid>
        </Grid>
      ),
    },
  ];

  const openMenu = Boolean(anchorEl);

  const handleMenuClick = (event) => {
    const menuName = event.currentTarget.getAttribute('name');
    switch (menuName) {
      case 'eventType':
        setFilterMenuList([
          'New booking',
          'Booking rescheduled',
          'Booking status changed',
          'Booking starts',
          'Booking ends',
          'New customer created',
          'New rating',
        ]);
        break;
    }

    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuFilterInput('');
  };

  const handleMenuFilterInput = (e) => {
    const value = e.target.value;

    if (value.length === 0) {
      switch (anchorEl.getAttribute('name')) {
        case 'eventType':
          setFilterMenuList([
            'New booking',
            'Booking rescheduled',
            'Booking status changed',
            'Booking starts',
            'Booking ends',
            'New customer created',
            'New rating',
          ]);
          break;
      }
    } else {
      setFilterMenuList((prevState) =>
        prevState.filter((prevValue) => prevValue.startsWith(value))
      );
    }

    setMenuFilterInput(e.target.value);
  };

  return (
    <Grid
      item
      container
      sx={{
        minWidth: { xs: '100%' },
        px: '20px',
      }}
    >
      {openModal.type === 'delete-modal' && (
        <Modal
          handleOpenModal={handleOpenModal}
          type={'DELETE'}
          open={openModal.open}
        />
      )}
      {addWorkflowDrawer && (
        <AddWorkflow
          toggleAddWorkflowDrawer={toggleAddWorkflowDrawer}
          drawerType={drawerType}
          drawerData={drawerData}
        />
      )}
      {editWorkflowDrawer && (
        <EditWorkflow
          toggleEditWorkflowDrawer={toggleEditWorkflowDrawer}
          drawerType={editWorkflowDrawerType}
          drawerData={editWorkflowDrawerData}
        />
      )}
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openMenu}
        onClose={handleMenuClose}
        elevation={0}
        MenuListProps={{
          sx: {
            width: anchorEl && anchorEl.offsetWidth,
            padding: 0,
            border: '1px solid #E4EBF4',
            borderRadius: '4px',
            borderTop: 'none',
          },
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <input
            className="quickSearch"
            value={menuFilterInput}
            onChange={handleMenuFilterInput}
            type="text"
            style={{
              border: '1px solid #e3eaf3',
              outline: 'none',
              fontSize: '14px',
              borderRadius: '2px',
              height: '1.5rem',
              width: '95%',
              color: '#495057',
            }}
          />
        </div>
        <div>
          <MenuList dense>
            {filterMenuList.map((value, index) => (
              <MenuItem
                sx={{
                  ':hover': {
                    backgroundColor: '#adbfc7',
                    color: 'white',
                  },
                }}
                key={`filter-${index}`}
                onClick={() => handleCheckedMenuFilter(value)}
              >
                {value}
              </MenuItem>
            ))}
          </MenuList>
        </div>
      </Menu>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          // alignItems: 'flex-end',
          flexWrap: 'wrap',
        }}
      >
        <Typography
          sx={{
            marginTop: 2.5,
            marginBottom: 1,
            fontSize: 24,
            color: '#3A3A50',
          }}
        >
          {isEditSubpage ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Link
                onClick={() => {
                  setIsEditSubpage();
                  setEditNameValue();
                }}
                href="#"
                underline="hover"
                sx={{
                  marginRight: '10px',
                }}
              >
                Workflows
              </Link>
              <ChevronRightIcon
                sx={{
                  marginRight: '5px',
                }}
              />
              <p>{` Edit Workflow`}</p>
            </div>
          ) : (
            'Workflow'
          )}
        </Typography>
        {/* {!isEditSubpage && (
          <Button
            size="large"
            sx={{
              width: { xs: '100%', sm: 'auto' },
              marginTop: 5,
              marginBottom: 1,
            }}
            variant="contained"
            onClick={() => {
              setDrawerType('add');
              toggleAddWorkflowDrawer();
            }}
          >
            + ADD WORKFLOW
          </Button>
        )} */}
      </Grid>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <CustomTextButton
          text="+ Add workflow"
          onClick={() => {
            setDrawerType('add');
            toggleAddWorkflowDrawer();
          }}
        />

        <Box sx={{ display: 'flex', gap: '10px', ml: '10px' }}>
          {FilterButton}
        </Box>
      </Box>

      {/* --------------------SEARCH------------------------------- */}

      {!isEditSubpage && FilterBar}
      <div
        style={{
          width: '100%',
          borderBottom: '1px solid #e3eaf3',
        }}
      ></div>
      {isEditSubpage ? (
        /* --------------------EDIT WORKFLOW--------------------------------- */
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            flexDirection: 'column',
            mt: '10px',
          }}
        >
          {/* workflow name */}
          <Card sx={{ minWidth: { lg: 600, xs: 400 }, mt: '24px', p: '18px' }}>
            <CardContent>
              <Typography sx={{ fontWeight: 700, mb: '10px' }} gutterBottom>
                Workflow name
              </Typography>
              <TextField
                id="outlined-basic"
                size="small"
                required
                variant="outlined"
                sx={{
                  marginBottom: '1rem',
                  width: '100%',
                  fontSize: '14px',
                }}
                value={editNameValue ? editNameValue : isEditSubpage.row.name}
                type="email"
                onChange={handleEditName}
              />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: '1rem',
                }}
              >
                <FormControlLabel
                  value="start"
                  control={
                    <Switch
                      color="primary"
                      checked={editActivatedValue}
                      onChange={handleEditActivated}
                    />
                  }
                  label={
                    <Typography fontWeight={700} fontSize={14}>
                      Activated
                    </Typography>
                  }
                  sx={{ pl: 0, ml: 0 }}
                  labelPlacement="start"
                />
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#4abf60',
                    ':hover': { backgroundColor: '#218838' },
                  }}
                  onClick={() => {
                    handleEditSave();
                  }}
                >
                  Save
                </Button>
              </Box>
            </CardContent>
          </Card>

          {/* when */}

          <Card sx={{ minWidth: { lg: 600, xs: 400 }, mt: '24px', p: '18px' }}>
            <CardContent>
              <Typography sx={{ fontWeight: 700, mb: '10px' }} gutterBottom>
                When
              </Typography>
              <Box
                sx={{
                  background: '#F5F7FA',
                  p: '0.7rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  border: '1px solid #EDF1F6',
                }}
              >
                <Typography sx={{ ml: '0.3rem', fontSize: '14px' }}>
                  {isEditSubpage.row.event}
                </Typography>
                <Link
                  underline="always"
                  sx={{
                    marginRight: '0.3rem',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setEditWorkflowDrawerType({
                      type: 'EVENT',
                      eventType: 'BOOKING',
                    });
                    setEditWorkflowDrawerData({
                      id: isEditSubpage.row.id,
                      fullName: isEditSubpage.row.full_name,
                      email: isEditSubpage.row.email,
                      plan: isEditSubpage.row.plan,
                      expiry: isEditSubpage.row.expiry,
                    });

                    toggleEditWorkflowDrawer();
                  }}
                >
                  Edit
                </Link>
              </Box>
            </CardContent>
          </Card>

          {/* do this */}

          <Card sx={{ minWidth: { lg: 600, xs: 400 }, mt: '24px', p: '18px' }}>
            <CardContent>
              <Typography sx={{ fontWeight: 700, mb: '10px' }} gutterBottom>
                Do this
              </Typography>
              {isEditSubpage.row.actions.map((action) => {
                return action.actions.map((actionData) => {
                  return (
                    <Box
                      sx={{
                        background: '#F5F7FA',
                        p: '0.7rem',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        border: '1px solid #EDF1F6',
                        mb: '1rem',
                      }}
                    >
                      <Typography sx={{ ml: '0.3rem', fontSize: '14px' }}>
                        {action.value}
                      </Typography>
                      <Link
                        underline="always"
                        onClick={() => {
                          setEditWorkflowDrawerType({
                            type: 'ACTION',
                          });
                          setEditWorkflowDrawerData({
                            id: isEditSubpage.row.id,
                            fullName: isEditSubpage.row.full_name,
                            email: isEditSubpage.row.email,
                            plan: isEditSubpage.row.plan,
                            expiry: isEditSubpage.row.expiry,
                          });

                          toggleEditWorkflowDrawer();
                        }}
                        sx={{
                          marginRight: '0.3rem',
                          cursor: 'pointer',
                        }}
                      >
                        Edit
                      </Link>
                    </Box>
                  );
                });
              })}
            </CardContent>
          </Card>
        </Box>
      ) : (
        /* --------------------TABLE--------------------------------- */

        <Grid
          container
          item
          justifyContent="space-between"
          sx={{ marginTop: '20px' }}
        >
          <div style={{ width: '100%' }}>
            <DataGrid
              sx={{
                border: 0,
                '& .MuiDataGrid-columnHeader': {
                  backgroundColor: '#EFF1FC',
                  color: '#3A3A50',
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontSize: '12px',
                  fontWeight: '900 !important',
                },
                '& .MuiDataGrid-main, .MuiDataGrid-footerContainer': {
                  backgroundColor: 'white',
                  border: 'none',
                },
                minHeight: '60vh',
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
              }}
              getRowHeight={() => 'auto'}
              getRowClassName={(params) =>
                !params.row.isEnabled && 'disabled-row'
              }
              rows={rows}
              columns={columns}
              // checkboxSelection
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              autoHeight
              selectionModel={selectionModel}
              components={{
                Footer: () => (
                  <CustomTableFooter
                    selectionModel={selectionModel}
                    handleDeleteOpenModal={handleDeleteOpenModal}
                  />
                ),
                MoreActionsIcon: () => (
                  <GridMoreVertIcon
                    sx={{
                      backgroundColor: 'white',
                      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
                      borderRadius: '4px',
                      padding: '3px',
                      cursor: 'pointer',
                    }}
                  />
                ),
                NoRowsOverlay: () => {
                  return (
                    <Box
                      sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'Center',
                        gap: '20px',
                      }}
                    >
                      <img
                        src="https://user-images.githubusercontent.com/109676648/254145031-57027cd6-fecd-4ce2-96fc-9d891c8de53a.svg"
                        alt="no data"
                        style={{
                          width: '100px',
                          height: '100px',
                        }}
                      />
                      No data at the moment 😔
                    </Box>
                  );
                },
              }}
            />
          </div>
        </Grid>
      )}
    </Grid>
  );
};

export default Workflow;
