import { UpnizeBackend } from '../Axios';

export const getLocationList = async (rows, page, queries) => {
  let response = await UpnizeBackend.get(
    `/locations?rows=${rows}&page=${page}${queries ? queries : ''}`
  );
  return response;
};

export const getLocationById = async (id) => {
  let response = await UpnizeBackend.get(`/locations/byId/${id}`);
  return response;
};

export const addLocation = async (body) => {
  let response = await UpnizeBackend.post(`/locations/add`, body);
  return response;
};

export const editLocationById = async (body, id) => {
  let response = await UpnizeBackend.put(`/locations/edit/${id}`, body);
  return response;
};

export const deleteLocationById = async (id) => {
  let response = await UpnizeBackend.delete(`/locations/delete`, {
    data: { ids: id },
  });
  return response;
};
