import { Grid, Switch, TextField, Typography } from '@mui/material';
import { useState } from 'react';

const Payment = ({
  termsAndCondition,
  setTermsAndCondition,
  addUrlLink,
  setAddUrlLink,
  letUserAddInformationToAppointment,
  setLetUserAddInformationToAppointment,
  letcustomerPayStripe,
  setLetCustomerPayStripe,
  letcustomerPayPaypal,
  setLetCustomerPayPaypal,
  letcustomerPayLocal,
  setLetCustomerPayLocal,
  letcustomerPayGiftcard,
  setLetCustomerPayGiftcard,
  setIsSaveBar,
}) => {
  return (
    <>
      <Grid container item xs={12} sx={{ marginY: 1 }}>
        <Grid
          style={{ cursor: 'pointer' }}
          onClick={() => {
            {
              setTermsAndCondition(!termsAndCondition);
              setIsSaveBar(true);
            }
          }}
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            paddingX: 2,
            paddingY: 1,
            height: '40px',
          }}
        >
          <Typography variant="subtitle2" sx={{ lineHeight: '1.2 !important' }}>
            Terms {`&`} Condition
          </Typography>
          <Switch
            size="small"
            defaultChecked
            sx={{ marginLeft: 2 }}
            onChange={(e) => {
              setTermsAndCondition(e.target.checked);
              setIsSaveBar(true);
            }}
            checked={termsAndCondition}
          />
        </Grid>
      </Grid>

      {termsAndCondition && (
        <Grid xs={12}>
          <Grid item xs={12} sx={{ marginY: 1 }}>
            <Typography variant="subtitle2">Add URL Link</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={addUrlLink}
              onChange={(e) => {
                setAddUrlLink(e.target.value);
                setIsSaveBar(true);
              }}
              id="outlined-basic"
              size="small"
              required
              variant="outlined"
              sx={{
                marginBottom: '2rem',
                width: '100%',
                fontSize: '14px',
              }}
            />
          </Grid>
        </Grid>
      )}

      <Grid container item xs={12} sx={{ marginY: 1 }}>
        <Grid
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setLetUserAddInformationToAppointment(
              !letUserAddInformationToAppointment
            );
            setIsSaveBar(true);
          }}
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            paddingX: 2,
            paddingY: 1,
            height: '40px',
          }}
        >
          <Typography variant="subtitle2" sx={{ lineHeight: '1.2 !important' }}>
            Let user add information to appointment
          </Typography>
          <Switch
            size="small"
            defaultChecked
            sx={{ marginLeft: 2 }}
            onChange={(e) => {
              setLetUserAddInformationToAppointment(e.target.checked);
              setIsSaveBar(true);
            }}
            checked={letUserAddInformationToAppointment}
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} sx={{ marginY: 1 }}>
        <Grid
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setLetCustomerPayStripe(!letcustomerPayStripe);
            setIsSaveBar(true);
          }}
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            paddingX: 2,
            paddingY: 1,
            height: '40px',
          }}
        >
          <Typography variant="subtitle2" sx={{ lineHeight: '1.2 !important' }}>
            Let customer pay with stripe
          </Typography>
          <Switch
            size="small"
            defaultChecked
            sx={{ marginLeft: 2 }}
            onChange={(e) => {
              setLetCustomerPayStripe(e.target.checked);
              setIsSaveBar(true);
            }}
            checked={letcustomerPayStripe}
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} sx={{ marginY: 1 }}>
        <Grid
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setLetCustomerPayPaypal(!letcustomerPayPaypal);
            setIsSaveBar(true);
          }}
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            paddingX: 2,
            paddingY: 1,
            height: '40px',
          }}
        >
          <Typography variant="subtitle2" sx={{ lineHeight: '1.2 !important' }}>
            Let customer pay with Paypal
          </Typography>
          <Switch
            size="small"
            defaultChecked
            sx={{ marginLeft: 2 }}
            onChange={(e) => {
              setLetCustomerPayPaypal(e.target.checked);
              setIsSaveBar(true);
            }}
            checked={letcustomerPayPaypal}
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} sx={{ marginY: 1 }}>
        <Grid
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setLetCustomerPayLocal(!letcustomerPayLocal);
            setIsSaveBar(true);
          }}
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            paddingX: 2,
            paddingY: 1,
            height: '40px',
          }}
        >
          <Typography variant="subtitle2" sx={{ lineHeight: '1.2 !important' }}>
            Let customer pay with Local
          </Typography>
          <Switch
            size="small"
            defaultChecked
            sx={{ marginLeft: 2 }}
            onChange={(e) => {
              setLetCustomerPayLocal(e.target.checked);
              setIsSaveBar(true);
            }}
            checked={letcustomerPayLocal}
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} sx={{ marginY: 1 }}>
        <Grid
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setLetCustomerPayGiftcard(!letcustomerPayGiftcard);
            setIsSaveBar(true);
          }}
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            paddingX: 2,
            paddingY: 1,
            height: '40px',
          }}
        >
          <Typography variant="subtitle2" sx={{ lineHeight: '1.2 !important' }}>
            Let customer pay with Giftcard
          </Typography>
          <Switch
            size="small"
            defaultChecked
            sx={{ marginLeft: 2 }}
            onChange={(e) => {
              setLetCustomerPayGiftcard(e.target.checked);
              setIsSaveBar(true);
            }}
            checked={letcustomerPayGiftcard}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Payment;
