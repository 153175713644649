import Drawer from '../../../components/Drawer';
import DrawerFooter from '../../../components/DrawerFooter';
import Grid from '@mui/material/Grid';
import { Autocomplete, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import CustomTextButton from '../../../components/CustomButtons/CustomTextButton';
import { crudTypes } from '../../../constants/crudTypes';
import useCategoryDrawer from './useCategoryDrawer';
import { customHelperTextErr } from '../../../utils/CustomHelperTextErr';

const CategoryDrawer = ({
  title,
  crudType,
  submitTitle,
  data = null,
  refetchData = null,
}) => {
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('sm'));

  const {
    selectedParentCategory,
    categoryOptions,
    setSelectedParentCategory,
    categoryName,
    toggleDrawer,
    setCategoryName,
    errors,
    handleSubmit,
  } = useCategoryDrawer({ crudType, data, refetchData });

  return (
    <Grid container>
      <Grid item xs={12} sm={2}>
        <Drawer type={crudType} title={title} toggleDrawer={toggleDrawer}>
          {/* -----------FORM----- */}
          <Grid sx={{ height: '100%', overflowY: 'auto', width: '100%' }}>
            {(crudType === crudTypes.ADD || crudType === crudTypes.EDIT) && (
              <Grid sm={12} xs={12}>
                <Grid sx={{ px: 5, pt: 5 }}>
                  <Typography variant="subtitle2" sx={{ my: 1 }}>
                    Parent category<sapn style={{ color: 'red' }}>*</sapn>
                  </Typography>
                  <Autocomplete
                    size="small"
                    disablePortal
                    options={categoryOptions}
                    getOptionLabel={(option) => option.name}
                    value={selectedParentCategory || null}
                    onChange={(e, data) => {
                      setSelectedParentCategory(data);
                    }}
                    sx={{
                      marginBottom: '1rem',
                      width: '100%',
                      fontSize: '14px',
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Grid>
                <Grid sx={{ padding: 5, pt: 1 }}>
                  <Typography variant="subtitle2" sx={{ my: 1 }}>
                    Category name<sapn style={{ color: 'red' }}>*</sapn>
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    value={categoryName}
                    onChange={(e) => setCategoryName(e.target.value)}
                    error={errors.name.length > 0}
                    helperText={
                      errors.name.length > 0 ? customHelperTextErr(errors.name) : ''
                    }
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          {/* -----Button */}

          <DrawerFooter>
            <>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: isSmallScreen ? 'flex-start' : 'flex-end',
                }}
              ></div>

              <div
                style={{
                  display: 'flex',
                  gap: '15px',
                  justifyContent: isSmallScreen ? 'space-between' : 'flex-end',
                  width: isSmallScreen ? '100%' : 'auto',
                  alignItems: 'center',
                }}
              >
                <CustomTextButton
                  variant="contained"
                  size="large"
                  onClick={() => {
                    toggleDrawer();
                  }}
                >
                  Cancel
                </CustomTextButton>
                <CustomTextButton
                  type="blue"
                  variant="contained"
                  size="large"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  {submitTitle || 'Save'}
                </CustomTextButton>
              </div>
            </>
          </DrawerFooter>
        </Drawer>
      </Grid>
    </Grid>
  );
};

export default CategoryDrawer;
