export const appointmentTags = [
  "{appointment_id}",
  "{appointment_date}",
  "{appointment_date_time}",
  "{appointment_start_time}",
  "{appointment_end_time}",
  "{appointment_duration}",
  "{appointment_buffer_before}",
  "{appointment_buffer_after}",
  "{appointment_status}",
  "{appointment_service_price}",
  "{appointment_extras_price}",
  "{appointment_extras_list}",
  "{appointment_discount_price}",
  "{appointment_sum_price}",
  "{appointment_paid_price}",
  "{appointment_payment_method}",
  "{appointment_tax_amount}",
  "{appointment_custom_field_ID}",
  "{appointment_created_date}",
  "{appointment_created_time}",
];

export const serviceTags = [
  "{service_name}",
  "{service_price}",
  "{service_duration}",
  "{service_notes}",
  "{service_color}",
  "{service_image_url}",
  "{service_category_name}",
];
export const customerTags = [
  "{customer_full_name}",
  "{customer_first_name}",
  "{customer_last_name}",
  "{customer_phone}",
  "{customer_email}",
  "{customer_birthday}",
  "{customer_notes}",
  "{customer_profile_image_url}",
  "{customer_panel_url}",
  "{customer_panel_password}",
];
export const staffTags = [
  "{staff_name}",
  "{staff_email}",
  "{staff_phone}",
  "{staff_about}",
  "{staff_profile_image_url}",
];
export const locationTags = [
  "{location_name}",
  "{location_address}",
  "{location_image_url}",
  "{location_phone_number}",
  "{location_notes}",
];
export const companyTags = [
  "{company_name}",
  "{company_image_url}",
  "{company_website}",
  "{company_phone}",
  "{company_address}",
];
