import { Grid, Switch, TextField, Typography } from "@mui/material";
import { useState } from "react";

const Stripe = ({ page, handleChangePaymentMethodUnDraggable }) => {
  return (
    <>
      <Grid container item xs={12} sx={{ marginY: 1 }}>
        <Grid xs={12}>
          <Grid item xs={12} sx={{ marginY: 1 }}>
            <Typography variant="subtitle2">Publishable key</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={page.pubKey}
              onChange={(e) => {
                handleChangePaymentMethodUnDraggable(
                  page.id,
                  "pubKey",
                  e.target.value
                );
              }}
              size="small"
              required
              variant="outlined"
              sx={{
                marginBottom: "2rem",
                width: "100%",
                fontSize: "14px",
              }}
            />
          </Grid>
        </Grid>
        <Grid xs={12}>
          <Grid item xs={12}>
            <Typography variant="subtitle2">Secret key</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={page.secretKey}
              onChange={(e) => {
                handleChangePaymentMethodUnDraggable(
                  page.id,
                  "secretKey",
                  e.target.value
                );
              }}
              size="small"
              required
              variant="outlined"
              sx={{
                marginBottom: "2rem",
                width: "100%",
                fontSize: "14px",
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Stripe;
