import { Box, Grid, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import CustomTextButton from '../../CustomButtons/CustomTextButton';
import { getCategoryById } from '../../../Api/tenant/categories';

const HasChildrenModal = ({ id, handleOpenModal }) => {
  const [category, setCategory] = useState(null);

  console.log(category);

  const fetchCategory = useCallback(async () => {
    try {
        const { data } = await getCategoryById(id)

        setCategory(data);
    } catch(err) {
        console.log(err)
    }
  }, [id]);

  useEffect(() => {
    fetchCategory()
  }, [fetchCategory]);

  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '200px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography>
          Can't Delete <span style={{fontWeight: "bold"}}>{category?.name}</span> category because it is a parent for subcategories
        </Typography>
      </Box>
      <Grid container sx={{ justifyContent: 'end', gap: '15px' }}>
        <CustomTextButton
          variant="contained"
          size="large"
          onClick={handleOpenModal}
        >
          Cancel
        </CustomTextButton>
      </Grid>
    </>
  );
};

export default HasChildrenModal;
