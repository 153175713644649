import { useDispatch } from 'react-redux';
import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { TableFooter, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import AvatarComp from '../Avatar';
import { gridStringOrNumberComparator } from '@mui/x-data-grid';
import { formatDate } from '../../utils/formatDate';
import { updateAppointmentStatusAPI } from '../../Api/tenant/appointments';
import { callSnackbar } from '../../redux/slices/snackbar.slice';
import { durationFormater } from '../../utils/durationFormater';
import StatusControllerComp from '../StatusControllerComp';

const headCells = [
  {
    id: 1,
    numeric: false,
    disablePadding: true,
    label: 'STAFF',
  },
  {
    id: 2,
    numeric: true,
    disablePadding: false,
    label: 'CUSTOMER',
    sortComparator: (v1, v2) => gridStringOrNumberComparator(v1.name, v2.name),
    renderCell: (params) => {
      return (
        <Box
          sx={{
            width: '100%',
          }}
        >
          <Box display="flex">
            <AvatarComp
              img={params.value.User.image}
              username={params.value.User.firstName}
              sx={{ mr: 1 }}
            />
            <Box
              onClick={() => {
                console.log('params.row.customer', params.row.customer);
              }}
            >
              <Typography fontSize="14px" color="#292d32">
                <Link
                  to={`/customer/${params?.row?.customer?.id}`}
                  style={{
                    color: '#292d32',
                    textDecoration: 'none',
                  }}
                >
                  {params.value.User.firstName}
                </Link>
              </Typography>
              <Typography color="#95a0a9" fontSize="12px">
                {params.value.User.email}
              </Typography>
            </Box>
          </Box>
        </Box>
      );
    },
  },
  {
    id: 3,
    numeric: true,
    disablePadding: false,
    label: 'DATE',
  },
  {
    id: 4,
    numeric: true,
    disablePadding: false,
    label: 'SERVICE',
  },
  {
    id: 5,
    numeric: true,
    disablePadding: false,
    label: 'DURATION',
  },
  {
    id: 6,
    numeric: true,
    disablePadding: false,
    label: 'STATUS',
  },
  {
    id: 7,
    numeric: true,
    disablePadding: false,
    label: 'CREATED BY',
  },
];

function EnhancedTableHead() {
  return (
    <TableHead sx={{ margin: '10px', backgroundColor: '#EFF1FC' }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={'left'}>
            <TableSortLabel
              sx={{
                fontWeight: 'bold',
                whiteSpace: 'nowrap',
                fontSize: '12px',
              }}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export const FilterItem = [
  {
    id: 1,
    title: 'Quick search',
    type: 'search',
  },
  {
    id: 2,
    title: 'Select date',
    type: 'date',
  },
  {
    id: 3,
    title: 'Service',
    type: 'select',
    options: [
      'Oral hygiene',
      'Tooth whitening',
      'Implants',
      '3d/4d Ultrasound',
      'OB/GYN',
      'Maternity',
      'Buttock Lift',
      'Liposuction',
      'Fat Transfer',
    ],
    isOpen: false,
    search: '',
  },
  {
    id: 4,
    title: 'Customer',
    type: 'select',
    options: [
      'Shakil Ahmed',
      'Kristian Dawns',
      ' Jennifer Landr',
      ' Jennifer Landry',
      ' Hellen Ballar',
      'Emille Burhess',
      ' Albert Swift',
      ' Diana Martin',
      'Ava Gilmore',
      'Axel Juarez',
      'Charlie Deyer',
      'Francisco Jefferson',
    ],
    isOpen: false,
    search: '',
  },
  {
    id: 5,
    title: 'Staff',
    type: 'select',
    options: [
      'Roland Mark',
      'Mark S.Brochet,MD',
      'Joseph Church, MD',
      'Thomas Coates,MD',
      'Rima Jurban,MD',
      ' David Freyer,Do',
      'Dennis R. Maceri',
      'MD44',
    ],
    isOpen: false,
    search: '',
  },
  {
    id: 6,
    title: 'Status',
    type: 'select',
    options: ['Pending', 'Paid', 'Canceled', 'Not Paid'],
    isOpen: false,
    search: '',
  },
];

const data = [
  {
    id: 1,
    employee: 'ali',
    date: '09/07/2022 04:00 AM',
    customer: {
      id: 1,
      avatar: null,
      email: 'AlexenderInci@gmail.com',
      name: 'Alexender Inci',
    },
    service: '4D Ultrsound',
    duration: 100,
    status: 'Pending',
    created: '09/07/2022 04:00 AM',
  },
  {
    id: 2,
    employee: 'ali',
    date: '09/07/2022 04:00 AM',
    customer: {
      id: 2,
      avatar: null,
      email: 'AlexenderInci@gmail.com',
      name: 'Alexender Inci',
    },
    service: '4D Ultrsound',
    duration: 100,
    status: 'Pending',
    created: '09/07/2022 04:00 AM',
  },
  {
    id: 3,
    employee: 'ali',
    date: '09/07/2022 04:00 AM',
    customer: {
      id: 3,
      avatar: null,
      email: 'AlexenderInci@gmail.com',
      name: 'Alexender Inci',
    },
    service: '4D Ultrsound',
    duration: 100,
    status: 'Pending',
    created: '09/07/2022 04:00 AM',
  },
];

export default function DashboardTable({
  activeTab,
  data,
  refetchDashboardData,
}) {
  const dispatch = useDispatch();

  const TableData = () =>
    data &&
    data[activeTab]?.map((inf) => {
      return (
        <TableRow hover key={inf.id}>
          {/* <TableCell /> */}
          <TableCell
            component="th"
            scope="row"
            padding="none"
            sx={{
              pl: '20px',
              minWidth: '230px',
              maxWidth: '300px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {inf.staff?.User?.firstName && inf.staff?.User?.lastName
              ? inf.staff.User.firstName + ' ' + inf.staff.User.lastName
              : '-'}
          </TableCell>
          <TableCell
            align="left"
            sx={{
              width: '230px',
              maxWidth: '300px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {/* {inf.customer.name} */}
            <Box
              sx={{
                width: '100%',
              }}
            >
              <Box display="flex">
                <Box>
                  <Typography fontSize="14px" color="#292d32" fontWeight="500">
                    <Link
                      to={`/customer/${inf.customer.id}`}
                      style={{
                        color: '#292d32',
                        textDecoration: 'none',
                      }}
                    >
                      {inf.customer.User.firstName +
                        ' ' +
                        inf.customer.User.lastName}
                    </Link>
                  </Typography>
                  <Typography color="#95a0a9" fontSize="12px">
                    {inf.customer.email}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </TableCell>
          <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
            {inf.date + ' | ' + inf.time}
          </TableCell>
          <TableCell
            align="left"
            sx={{
              minWidth: '150px',
              maxWidth: '200px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {inf.service.name}
          </TableCell>
          <TableCell align="left">
            {durationFormater(inf.service.duration)}
          </TableCell>
          <TableCell align="left">
            <>
              <StatusControllerComp
                inf={inf}
                updateStatus={updateAppointmentStatus}
              />
            </>
          </TableCell>
          <TableCell align="left" sx={{ whiteSpace: 'nowrap' }}>
            {formatDate(inf.createdAt)}
          </TableCell>
        </TableRow>
      );
    });

  const updateAppointmentStatus =
    (inf, selectedStatus, handleClose) => async () => {
      try {
        const response = await updateAppointmentStatusAPI(inf.id, {
          status: selectedStatus.name,
        });

        if (response.status >= 200 && response.status < 300) {
          dispatch(
            callSnackbar({
              message: response.data.msg,
              type: 'success',
            })
          );
          handleClose();
          refetchDashboardData();
        }
      } catch (err) {
        console.log(err);
      }
    };

  return (
    <Box sx={{ width: '100%', zIndex: 0 }}>
      <Paper
        sx={{
          width: '100%',
          mb: 2,
          fontSize: '14px',
          shadow: 'none !important',
        }}
      >
        <TableContainer
          sx={{
            shadow: 'none !important',
          }}
        >
          <Table
            sx={{ minWidth: 750, shadow: 'none !important' }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead />
            <TableBody
              sx={{
                shadow: 'none !important',
              }}
            >
              {data && data[activeTab].length !== 0 ? <TableData /> : null}
            </TableBody>
            <TableFooter
              sx={{
                shadow: 'none !important',
              }}
            >
              {data && data[activeTab].length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={7}
                    sx={{
                      py: '50px',
                    }}
                  >
                    <Box
                      sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'Center',
                        gap: '20px',
                      }}
                    >
                      <img
                        src="https://user-images.githubusercontent.com/109676648/254145031-57027cd6-fecd-4ce2-96fc-9d891c8de53a.svg"
                        alt="no data"
                        style={{
                          width: '100px',
                          height: '100px',
                        }}
                      />
                      No data at the moment 😔
                    </Box>
                  </TableCell>
                </TableRow>
              ) : null}
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
