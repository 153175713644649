import {
  Alert,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Grid,
  Icon,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import PersonIcon from '@mui/icons-material/Person';
import { useState } from 'react';
import StatusChip from '../../../components/StatusChip';

const tempstatus = [
  { status: 'Received' },
  { status: 'Approved' },
  {
    status: 'Custom',
    statusColor: '#ffffff',
    statusBgColor: 'gray',
    statusText: "that's custom",
  },
  { status: 'Completed' },
];

const AppointmentDetiles = ({ drawerData }) => {
  const [paymentMethod, setPaymentMethod] = useState('Paypal');
  const [link, setLink] = useState('');
  const [open, setOpen] = useState(false);

  const handleMethod = (value) => {
    setPaymentMethod(value);
  };

  const handelSubmit = () => {
    if (paymentMethod === 'Paypal') {
      setLink(
        ' https://www.sandbox.paypal.com/cgi-bin/webscr?cmd=_express-checkout&token=EC-4N363055CF137861Y'
      );
    } else {
      setLink('');
    }
  };
  const handelCopy = () => {
    try {
      navigator.clipboard.writeText(link);
      setOpen(true);
    } catch (error) {
      setOpen(false);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Grid container>
      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert severity="success" onClose={handleClose}>
          Dear User LInk Copyied
        </Alert>
      </Snackbar>
      <Grid sm={12} container>
        <Grid item xs={12} sm={4} sx={{ my: 3 }}>
          <Typography fontWeight="bold">Location</Typography>
          <Typography color="#828F9A">{drawerData?.location?.name}</Typography>
        </Grid>
        <Grid item xs={12} sm={4} sx={{ my: 3 }}>
          <Typography fontWeight="bold">Service</Typography>
          <Typography color="#828F9A">{drawerData?.service?.name}</Typography>
        </Grid>
        <Grid item xs={12} sm={4} sx={{ my: 3 }}>
          <Typography fontWeight="bold">Date, time</Typography>
          <Typography color="#828F9A">
            {drawerData?.date + ' ' + drawerData?.time}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        sm={12}
        sx={{
          borderBottom: '1px solid #E3EAF3',
          height: '7rem',
          width: '100%',
          mb: '30px',
          mt: '20px',
        }}
      >
        <Typography fontWeight="bold">Note</Typography>
        <Typography>{drawerData?.note ? drawerData?.note : '-'}</Typography>
      </Grid>
      <Grid sm={12} container>
        <Grid sm={6} xs={12} sx={{ mb: 4 }} item>
          <Typography color="#6c70dc " fontWeight="bold">
            Staff
          </Typography>
          <Grid xs={12} sx={{ display: 'flex', my: 3 }} item>
            <Avatar
              src={
                drawerData?.staff?.User?.image
                  ? process.env.REACT_APP_UPNIZE_BACKEND_URL +
                    '/' +
                    drawerData?.staff?.User?.image
                  : ''
              }
              sx={{ marginRight: '10px' }}
            />
            <div>
              <Typography variant="subtitle2" fontSize="16px">
                {drawerData?.staff?.User?.firstName +
                  ' ' +
                  drawerData?.staff?.User?.lastName}
              </Typography>
              <Typography
                variant="subtitle2"
                color="#95A0A9 "
                fontSize={'12px'}
              >
                {drawerData?.staff?.User?.email}
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid sm={6} xs={12} sx={{ mb: 4 }} item>
          <Typography color="#53D56C " fontWeight="bold">
            Customer
          </Typography>
          <Grid xs={12} sx={{ display: 'flex', my: 3 }} item>
            <Avatar
              src={
                drawerData?.customer?.User?.image
                  ? process.env.REACT_APP_UPNIZE_BACKEND_URL +
                    '/' +
                    drawerData?.customer?.User?.image
                  : ''
              }
              sx={{ marginRight: '10px' }}
            />
            <div>
              <Typography variant="subtitle2" fontSize="16px">
                {drawerData?.customer?.User?.firstName +
                  ' ' +
                  drawerData?.customer?.User?.lastName}
              </Typography>
              <Typography
                variant="subtitle2"
                color="#95A0A9 "
                fontSize={'12px'}
              >
                {drawerData?.customer?.User?.email}
              </Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'end' }}>
              <Icon sx={{ margin: '7px' }}>
                <PersonIcon sx={{ color: '#adbfc7' }} />
              </Icon>
              <Typography
                color="#abdfc7"
                fontWeight="500"
                marginBottom="3px"
                marginLeft="-5px"
              >
                {drawerData?.attendees}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: 'flex',
          paddingBottom: '30px',
          borderBottom: '1px solid #E3EAF3',
          width: '100%',
        }}
      >
        {tempstatus.map(
          ({ status, statusBgColor, statusColor, statusText }, i) => {
            return status === 'Custom' ? (
              <Box paddingRight={1}>
                <StatusChip
                  color={statusColor}
                  bgColor={statusBgColor}
                  text={statusText}
                  rounded={true}
                  width={'max-content'}
                  opacity={tempstatus.length - 1 === i ? 1 : 0.3}
                />
              </Box>
            ) : (
              <Box paddingRight={1}>
                <StatusChip
                  type={'appointment' + status}
                  width={'max-content'}
                  opacity={tempstatus.length - 1 === i ? 1 : 0.3}
                />
              </Box>
            );
          }
        )}
      </Box>

      <Grid sm={12} sx={{ width: '100%', marginTop: '30px' }}>
        <Typography variant="subtitle1" fontWeight="bold">
          Create Payment Link
        </Typography>
        <Grid container spacing={1.5}>
          <Grid xs={12} sm={6} item>
            <Autocomplete
              fullWidth
              size="small"
              disablePortal
              id="combo-box-demo"
              value={paymentMethod}
              sx={{
                //   marginBottom: "1rem",
                fontSize: '14px',
              }}
              onSelect={(event) => handleMethod(event.target.value)}
              options={methods.map((method) => method.label)}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
          <Grid xs={12} sm={6} item>
            <Button
              // size="small"
              variant="contained"
              sx={{ marginLeft: { xs: '0', sm: '10px' } }}
              onClick={handelSubmit}
            >
              Create Link
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid sm={12} marginTop="2rem">
        {link && (
          <>
            <Typography
              variant="subtitle1"
              padding="10px"
              backgroundColor="#f3f3f3"
              color="#000"
            >
              {link}
            </Typography>
            <Button
              size="medium"
              variant="contained"
              onClick={handelCopy}
              // sx={{ marginLeft: { xs: "0", sm: "10px" } }}
            >
              Copy url
            </Button>
          </>
        )}
      </Grid>
    </Grid>
  );
};

const methods = [
  // { label: "Local" },
  { label: 'Paypal' },
  { label: 'Credit Card' },
];

export default AppointmentDetiles;
