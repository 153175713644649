import { Button, Grid, TextField, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { useRef, useState } from 'react';
import DraftTagButton from '../../components/DraftTagButton';
import SaveSlideBar from '../../components/SaveSlideBar';
import QuillEditor from '../../components/QuillEditor';

const InvoiceForm = ({ handleOpenForm }) => {
  const [isSaveBar, setIsSaveBar] = useState(true);
  const [quillHtml, setQuillHtml] = useState('');
  const [quillRange, setQuillRange] = useState(0);
  const quillRef = useRef();

  console.log('invoice form render');

  const addTagHandler = (tag) => {
    // setEditorState((prevState) => {
    //   const currentContent = prevState.getCurrentContent(),
    //     currentSelection = prevState.getSelection();
    //   const newContent = Modifier.replaceText(
    //     currentContent,
    //     currentSelection,
    //     tag
    //   );
    //   const newEditorState = EditorState.push(
    //     prevState,
    //     newContent,
    //     "insert-characters"
    //   );
    //   return EditorState.forceSelection(
    //     newEditorState,
    //     newContent.getSelectionAfter()
    //   );
    // });
  };

  // handlers

  const handleSave = () => {};

  const handleCancel = () => {};

  return (
    <>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          flexWrap: 'wrap',
          paddingBottom: '30px',
          borderBottom: { xs: '1px solid #e3eaf3' },
        }}
      >
        <Typography sx={{ marginTop: 5, marginBottom: 1, fontSize: 28 }}>
          Invoice
        </Typography>
        <Grid>
          <Button
            size="large"
            sx={{
              width: { xs: '100%', sm: 'auto' },
              marginTop: 5,
              marginBottom: 1,
              background: 'transparent !important',
              color: '#8F9CA7 !important',
              height: '47px',
              borderRadius: '2px',
              fontSize: '12px',
              fontWeight: 600,
              boxShadow: 'none',
              border: '1px solid #E3EAF3 !important',
            }}
            variant="contained"
          >
            <DownloadIcon sx={{ marginRight: 1, fontSize: '20px' }} />
            SAVE {`&`} DOWNLOAD PREVIEW
          </Button>
        </Grid>
      </Grid>
      {/* --------------------BODY--------------------------------- */}
      <Grid container sx={{ marginTop: '20px', backgroundColor: 'white' }}>
        <Grid
          container
          alignItems="center"
          sx={{ height: '57px', borderBottom: '1px solid #e3eaf3' }}
        >
          <Typography
            fontSize={12}
            color="#828f9a"
            fontWeight={600}
            sx={{ padding: '23px 25px 15px' }}
          >
            INVOICE
          </Typography>
        </Grid>
        <Grid container sx={{ padding: '15px 25px' }}>
          <Grid item xs={12} sm={4}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">PDF Name</Typography>
            </Grid>
            <Grid container item xs={12}>
              <TextField
                id="outlined-basic"
                size="small"
                required
                variant="outlined"
                sx={{
                  marginBottom: '1rem',
                  width: 'calc(100% - 44px)',
                  fontSize: '14px',
                  '& fieldset': {
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  },
                }}
              />
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{
                  backgroundColor: '#eaf1fa',
                  border: '1px solid rgba(0, 0, 0, 0.23)',
                  width: '44px',
                  height: '40px',
                  borderTopRightRadius: '4px',
                  borderBottomRightRadius: '4px',
                }}
              >
                <Typography fontSize={12}>.pdf</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid xs={12}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">Description</Typography>
            </Grid>
            <Grid item xs={12}>
              <QuillEditor
                quillRef={quillRef}
                onChange={({ html }) => {
                  setQuillHtml(html);
                  setIsSaveBar(true);
                }}
                onBlur={(prevSelection) => {
                  setQuillRange(prevSelection);
                }}
                value={quillHtml}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SaveSlideBar
        handleCancel={handleCancel}
        handleSave={handleSave}
        isSaveBar={isSaveBar}
        setIsSaveBar={setIsSaveBar}
        type={'INVOICE'}
      />
    </>
  );
};

export default InvoiceForm;
