import { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Card,
  Grid,
  TextField,
  Typography,
  ClickAwayListener,
  Popper,
  Paper,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CalendarPicker } from '@mui/x-date-pickers';

import CustomTextButton from './../../../components/CustomButtons/CustomTextButton';
import { getLocationList } from './../../../Api/tenant/locations';
import { getServiceList } from './../../../Api/tenant/services';
import { bookingDashboard } from './../../../Api/customer/dashboard';
import { addCustomerAppointment } from './../../../Api/customer/appointments';
import useUser from './../../../hooks/useUser';
import { callSnackbar } from '../../../redux/slices/snackbar.slice';
import { store } from './../../../redux/store';

const requiredFields = ['location', 'service', 'date'];

const BookAppointmnt = ({ width, height }) => {
  const { user } = useUser();

  const [selectedDate, setSelectedDate] = useState(null);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [errors, setErrors] = useState({});
  const [appointmentData, setAppointmentData] = useState({
    location: null,
    service: null,
    date: new Date(),
  });
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);
  const [disable, setDisable] = useState(true);
  const [data, setData] = useState({
    locations: [],
    services: [],
  });

  const booking = async (appointmentData) => {
    const res = await bookingDashboard({
      locationId: appointmentData.location.id,
      serviceId: appointmentData.service.id,
      date: selectedDate,
    });
    setTimeSlots(res.data);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  function formatDate(date) {
    // Create a new Date object from the provided date
    let d = new Date(date);

    // Get the year, month, and day from the date object
    let year = d.getFullYear();
    let month = (d.getMonth() + 1).toString().padStart(2, '0'); // pad month with leading zero if needed
    let day = d.getDate().toString().padStart(2, '0'); // pad day with leading zero if needed

    // Return the formatted date string
    return `${year}-${month}-${day}`;
  }

  const handleSelect = (i) => {
    setSelectedTime(i);
    setDisable(false);
  };

  const handleReset = () => {
    setSelectedTime(null);
    setDisable(true);
    setAppointmentData({
      location: null,
      service: null,
      date: new Date(),
    });
    setSelectedDate(formatDate(appointmentData.date, 'yy MMMM d'));
    setTimeSlots([]);
  };

  const handleAddAppointment = async () => {
    try {
      // validation handling
      let err = {};

      for (const key in appointmentData) {
        if (!appointmentData[key] && requiredFields.includes(key)) {
          err = { ...err, [key]: true };
        }
      }
      setErrors({ ...err });

      if (Object.keys(err).length === 0) {
        await addCustomerAppointment({
          locationId: appointmentData.location?.id,
          serviceId: appointmentData.service?.id,
          time: `${selectedTime.from} - ${selectedTime.to}`,
          date: selectedDate,
          customerId: user.TenantId,
        }).then((res) => {
          console.log(res);
          store.dispatch(callSnackbar({ message: res.data, type: 'success' }));
        });
      }
      handleReset();
    } catch (error) {
      store.dispatch(callSnackbar({ message: error.message, type: 'error' }));

      console.log(error);
    }
  };

  const handleFormData = (fieldName, value) => {
    setAppointmentData({
      ...appointmentData,
      [fieldName]: value,
    });
  };

  // API

  const fetchRequiredData = async () => {
    const locations = await getLocationList(100, 1);
    const services = await getServiceList(100, 1);

    console.log(locations);

    return {
      locations: locations.data.rows,
      services: services.data.rows,
    };
  };

  useEffect(() => {
    fetchRequiredData().then((data) => {
      setData(data);
    });
  }, []);

  useEffect(() => {
    if ((appointmentData.location, appointmentData.service)) {
      booking(appointmentData);
    }
  }, [selectedDate, appointmentData.location, appointmentData.service]);

  return (
    <Card
      sx={{
        height: height ? height : 450,
        width: width ? width : 400,
        boxShadow: 'none',
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          backgroundColor: 'white',
          borderBottom: 1,
          borderBottomColor: '#F5F7FA',
          p: 1.6,
          pl: 2,
          position: 'sticky',
          top: '0',
          right: '0',
          zIndex: 1000,
        }}
      >
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 700,
            width: '100%',
          }}
        >
          Book Appointment
        </Typography>
      </Box>
      <Box
        sx={{
          p: 1.6,
          pl: 2,
        }}
      >
        <Grid
          xs={12}
          sx={{
            marginTop: 1,
          }}
        >
          <Grid item xs={12} sx={{ marginY: 1 }}>
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: 14,
              }}
            >
              Provider (Company) <span style={{ color: 'red' }}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              value={appointmentData.location}
              onChange={(e, v) => {
                handleFormData('location', v);
                setErrors({ ...errors, location: false });
              }}
              size="small"
              options={data.locations.map((option) => option)}
              getOptionLabel={(option) => {
                return option.name;
              }}
              isOptionEqualToValue={(o, v) => o === v}
              // disablePortal
              sx={{
                width: '100%',
                fontSize: '14px',
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select company"
                  error={errors.location}
                  helperText={errors.location ? 'Value is missing' : ' '}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid
          xs={12}
          sx={{
            marginTop: 1,
          }}
        >
          <Grid item xs={12} sx={{ marginY: 1 }}>
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: 14,
              }}
            >
              Service <span style={{ color: 'red' }}>*</span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              value={appointmentData.service}
              options={data.services.map((option) => option)}
              getOptionLabel={(option) => {
                return option.name;
              }}
              isOptionEqualToValue={(o, v) => o === v}
              onChange={(e, v) => {
                handleFormData('service', v);
                setErrors({ ...errors, service: false });
              }}
              size="small"
              disablePortal
              sx={{
                width: '100%',
                fontSize: '14px',
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select service"
                  error={errors.service}
                  helperText={errors.service ? 'Value is missing' : ' '}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          // p: 1.6,
          pl: 2,
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box
            display="flex"
            alignItems="center"
            sx={{
              gap: '5px',
            }}
          >
            <Typography variant="h6" sx={{ fontSize: 14 }}>
              Pick Appointment Time For
            </Typography>
            <Grid
              onClick={handleClick}
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                color: 'blue',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: 14,
                  }}
                >
                  {selectedDate === null ? 'Select date' : selectedDate}
                </Typography>
                <KeyboardArrowDownIcon />
              </Box>
            </Grid>
            <Popper
              sx={{ zIndex: 10000 }}
              open={open}
              anchorEl={anchorEl}
              placement="bottom-start"
              modifiers={[
                {
                  name: 'offset',
                  options: {
                    offset: [0, 10],
                  },
                },
              ]}
            >
              <ClickAwayListener onClickAway={handleClickAway}>
                <Paper>
                  <CalendarPicker
                    disablePast={true}
                    date={appointmentData.date}
                    value={appointmentData.date}
                    onChange={(e, v) => {
                      handleFormData('date', e);
                      setSelectedDate(formatDate(e));
                      setOpen(false);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        required
                        variant="outlined"
                        sx={{
                          marginBottom: '2rem',
                          width: '100%',
                          fontSize: '14px',
                        }}
                      />
                    )}
                  />
                </Paper>
              </ClickAwayListener>
            </Popper>
          </Box>
        </LocalizationProvider>
      </Box>
      <Box
        container
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          width: '100%',
          p: 1.6,
          pl: 2,
          gap: '20px',
          boxSizing: 'border-box !important',
        }}
      >
        {Array.isArray(timeSlots) && timeSlots.length !== 0 ? (
          timeSlots.map((t, i) => (
            <Box
              xs={6}
              onClick={() => handleSelect(t)}
              sx={{
                pointerEvents: !t.available ? 'none' : '',
                backgroundColor: `${
                  selectedTime === t
                    ? '#1976d2'
                    : !t.available
                    ? '#FBFBFD'
                    : '#F2F5F9'
                }`,
                color: `${
                  selectedTime === t
                    ? '#fff'
                    : !t.available
                    ? '#CFCFCF'
                    : '#676767'
                }`,
                width: 'calc(50% - 10px)',
                padding: '10px',
                cursor: 'pointer',
                borderRadius: '4px',
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: '12px',
                boxSizing: 'border-box !important',
              }}
            >
              {t.from} - {t.to}
            </Box>
          ))
        ) : (
          <Box
            sx={{
              fontSize: '12px',
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {!appointmentData.location || !appointmentData.service
              ? 'Select Provider and service to continue'
              : 'No time available for this date'}
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: '20px',
          p: 1.6,
          pl: 2,
          borderTop: 1,
          borderTopColor: '#F5F7FA',
          position: 'sticky',
          bottom: '0',
          backgroundColor: '#fff',
        }}
      >
        <CustomTextButton
          onClick={handleReset}
          text="Cancel"
          style={{
            width: '50%',
          }}
        />
        <CustomTextButton
          onClick={handleAddAppointment}
          text="Save"
          type={`${!disable && 'blue'}`}
          disabled={disable}
          style={{
            width: '50%',
          }}
        />
      </Box>
    </Card>
  );
};

export default BookAppointmnt;
