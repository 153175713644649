import {
  Avatar,
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';
import AvatarComp from '../../../components/Avatar';
import {
  addComment,
  getAllComments,
} from '../../../Api/tenant/comments';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../redux/slices/user.slice.js';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

const Comments = ({ /*comments*/ handleAddComment, drawerData }) => {
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState([]);
  const { user, isUserLoading } = useSelector(selectUser);

  const getCommentsAPI = useCallback(async () => {
    try {
      const result = await getAllComments(drawerData?.id);
      const data = result?.data
      data?.reverse()
      setComments(data || []);
    } catch (err) {
      console.log(err);
    }
  }, [drawerData?.id]);

  useEffect(() => {
    getCommentsAPI();
  }, [getCommentsAPI]);

  const handleNewComment = (e) => {
    setComment(e.target.value);
  };

  const submitComment = async () => {
    if (!comment || !drawerData?.id || !user?.id) return;
    const commentBody = {
      text: comment,
      appointmentId: drawerData?.id,
      userId: user?.id,
    };
    try {
      const result = await addComment(commentBody);

      if (result.status === 200) {
        setComment('');
        setComments(prev => [result.data, ...prev])
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Box
      sx={{
        height: '100%',
        boxSizing: 'border-box',
      }}
    >
      <Grid
        sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}
      >
        <TextField
          value={comment}
          placeholder="Write a comment..."
          onChange={handleNewComment}
          size="small"
          sx={{ width: '90%', paddingRight: '10px' }}
        />{' '}
        <Button onClick={submitComment} variant="contained">
          Send
        </Button>
      </Grid>
      <Grid sx={{ overflowY: 'auto', height: '100%' }}>
        {comments.map((comment) => (
          <Box
            sx={{
              my: 2,
              boxShadow: '0 0 25px 0 rgb(0 0 0 / 5%)',
              background:
                'linear-gradient(to right,#c0d6df 50%,transparent 0%),linear-gradient(#c0d6df 50%,transparent 0%),linear-gradient(to right,#c0d6df 50%,transparent 0%),linear-gradient(#c0d6df 50%,transparent 0%)',
              backgroundPosition: 'top,right,bottom,left',
              backgroundRepeat: 'repeat-x,repeat-y',
              backgroundSize: '8px 1px,1px 8px',
              padding: '10px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <AvatarComp username={`${comment.User.firstName[0].toUpperCase()} ${comment.User.lastName[0].toUpperCase()}`} img={null} />
              <Box
                sx={{
                  pl: 1,
                  color: '#ADADAD',
                  fontSize: '14px',
                  fontWeight: '700',
                }}
              >
                {comment.User.firstName} {comment.User.lastName}
                <Typography sx={{ color: '#ADADAD', fontSize: '14px' }}>
                  {comment.company && comment.company + ', '}
                  {dayjs(comment.createdAt).fromNow()}
                </Typography>
              </Box>
            </Box>
            <Typography sx={{ ml: 1, fontSize: '14px' }}>
              {comment.text}
            </Typography>
          </Box>
        ))}
      </Grid>
    </Box>
  );
};

export default Comments;
