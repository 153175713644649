import { UpnizeBackend } from '../Axios';

export const getCouponList = async (rows, page, queries) => {
  let response = await UpnizeBackend.get(
    `/coupons?rows=${rows}&page=${page}${queries ? queries : ''}`
  );
  return response;
};

export const getCouponUsageList = async (id) => {
  let response = await UpnizeBackend.get(`/coupons/couponUsage/${id}`);
  return response;
};

export const getCouponById = async (id) => {
  let response = await UpnizeBackend.get(`/coupons/byId/${id}`);
  return response;
};

export const addCoupon = async (body) => {
  let response = await UpnizeBackend.post(`/coupons/add`, body);
  return response;
};

export const editCouponById = async (body, id) => {
  let response = await UpnizeBackend.put(`/coupons/edit/${id}`, body);
  return response;
};

export const deleteCouponById = async (id) => {
  let response = await UpnizeBackend.delete(`/coupons/delete`, {
    data: { ids: id },
  });
  return response;
};
