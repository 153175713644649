import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import AvatarComp from '../../../../components/Avatar';
import classes from './Details.module.css';
import { addComment, getAllComments } from '../../../../Api/tenant/comments';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../redux/slices/user.slice.js';

const CommentSection = ({ appointmentId }) => {
  const [newComment, setNewComment] = useState('');
  const [comments, setComments] = useState([]);
  const { user } = useSelector(selectUser);

  const getComments = useCallback(async () => {
    try {
      const res = await getAllComments(appointmentId);
      const data = res?.data
      data?.reverse()
      setComments(data || []);
    } catch (err) {
      console.log(err);
    }
  }, [appointmentId]);

  useEffect(() => {
    getComments();
  }, [getComments]);

  const handleNewComment = (e) => {
    setNewComment(e.target.value);
  };

  const submitNewComment = async () => {
    if (!newComment || !appointmentId || !user?.id) return;
    const commentBody = {
      text: newComment,
      appointmentId,
      userId: user?.id,
    };
    try {
      const result = await addComment(commentBody);

      if (result.status === 200) {
        setNewComment('');
        setComments(prev => [result.data, ...prev])
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box>
      <Typography className={classes.info__title}>Comment</Typography>
      <Grid
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '20px',
          marginTop: '10px',
        }}
      >
        <TextField
          value={newComment}
          placeholder="Write a comment..."
          onChange={handleNewComment}
          size="small"
          sx={{ width: '90%', paddingRight: '10px' }}
        />{' '}
        <Button onClick={submitNewComment} variant="contained">
          Send
        </Button>
      </Grid>
      {comments.map((comment) => {
        return (
          <Box
            sx={{
              my: 2,
              boxShadow: '0 0 25px 0 rgb(0 0 0 / 5%)',
              background:
                'linear-gradient(to right,#c0d6df 50%,transparent 0%),linear-gradient(#c0d6df 50%,transparent 0%),linear-gradient(to right,#c0d6df 50%,transparent 0%),linear-gradient(#c0d6df 50%,transparent 0%)',
              backgroundPosition: 'top,right,bottom,left',
              backgroundRepeat: 'repeat-x,repeat-y',
              backgroundSize: '8px 1px,1px 8px',
              padding: '10px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <AvatarComp username={`${comment.User.firstName[0].toUpperCase()} ${comment.User.lastName[0].toUpperCase()}`} img={null} />
              <Box
                sx={{
                  pl: 1,
                  color: '#ADADAD',
                  fontSize: '14px',
                  fontWeight: '700',
                }}
              >
                {comment.User.firstName} {comment.User.lastName}
                <Typography sx={{ color: '#ADADAD', fontSize: '14px' }}>
                  {comment.company && comment.company + ', '}
                  {dayjs(comment.createdAt).fromNow()}
                </Typography>
              </Box>
            </Box>
            <Typography sx={{ ml: 1, fontSize: '14px' }}>{comment.text}</Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default CommentSection;
