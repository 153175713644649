import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  DataGrid,
  GridMoreVertIcon,
  GridActionsCellItem,
  gridStringOrNumberComparator,
} from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import CustomTableFooter from '../../components/CustomTableFooter';
import { styled } from '@mui/material/styles';
import { gridClasses } from '@mui/x-data-grid';
import CustomTextButton from '../../components/CustomButtons/CustomTextButton';
import { useDateFormat } from '../../../../../libs/common-ui/src/index';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../redux/slices/user.slice.js';
import { defaultPageSizeOptions } from '../../constants';
import useFilterBar from '../../hooks/useFilterBar';
import { getQueries } from '../../utils/GetQueries';
import NoData from '../../components/NoData';
import AddTask from './AddTask';
import { getTaskList } from '../../Api/tenant/tasks';
import { deleteTaskById } from '../../Api/tenant/tasks';
import Modal from '../../components/Modal';
import AvatarComp from '../../components/Avatar';
import StatusControllerComp from '../../components/StatusControllerComp';
import { editTaskById } from '../../Api/tenant/tasks';
import { callSnackbar } from '../../redux/slices/snackbar.slice';

const TaskManagement = () => {
  const [rows, setRows] = useState([]);
  const [addTask, setAddTaskDrawer] = useState(false);
  const [tasks, setTasks] = useState(null);
  const [tableLoading, setTableLoading] = useState(true);
  const [drawerType, setDrawerType] = useState('add');
  const [drawerData, setDrawerData] = useState(null);
  const [openModal, setOpenModal] = useState({ open: false, type: null });
  const [selectionModel, setSelectionModel] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const dispatch = useDispatch();

  //FilterBar
  const { user } = useSelector(selectUser);
  const { FilterBar, state, FilterButton } = useFilterBar({
    filters: [
      {
        id: 1,
        title: 'Search',
        type: 'search',
        queryLabel: 'keyword',
      },
      {
        id: 2,
        title: 'Status',
        type: 'select',
        options: ['Pending', 'Done', 'On hold'],
        isOpen: false,
        search: '',
        queryLabel: 'status',
      },
      {
        id: 3,
        title: 'Responsible',
        type: 'select',
        options: ['Loading'],
        isOpen: false,
        search: '',
        queryLabel: 'responsible',
      },
      {
        id: 4,
        title: 'Location',
        type: 'select',
        options: ['Loading'],
        isOpen: false,
        search: '',
        queryLabel: 'location',
      },
      {
        id: 5,
        title: 'Created by',
        type: 'select',
        options: ['Loading'],
        isOpen: false,
        search: '',
        queryLabel: 'createdBy',
      },
      {
        id: 6,
        title: 'Date period',
        type: 'dateRange',
        queryLabel: 'date',
      },
      {
        id: 7,
        title: 'Priority',
        type: 'select',
        options: ['Low', 'Medium', 'High'],
        fetchOptions: () => {},
        isOpen: false,
        search: '',
        queryLabel: 'priority',
      },
    ],
  });

  //Task Table

  const headCells = [
    {
      field: 'referenceId',
      disablePadding: true,
      headerName: 'REFERENCE',
      minWidth: 80,
      flex: 0.5,
      valueGetter: (params) => (params.value == 'null' ? '-' : params.value),
    },
    {
      field: 'title',
      numeric: true,
      disablePadding: false,
      headerName: 'TITLE',
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'creator',
      headerName: 'CREATED BY',
      sortComparator: (v1, v2) =>
        gridStringOrNumberComparator(v1?.name, v2?.name),
      valueGetter: (params) => params.row.creator,
      renderCell: (params) => {
        const creator = params.row.creator;
        if (!creator) {
          return 'No responsible user';
        }

        return (
          <Box sx={{ width: '100%' }}>
            <Box display="flex" sx={{ alignItems: 'center' }}>
              <AvatarComp
                img={creator.image}
                username={`${creator.firstName}`}
                sx={{ mr: 1 }}
              />
              <Typography color="#95a0a9" fontSize="14px">
                {creator.firstName}
              </Typography>
            </Box>
          </Box>
        );
      },
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'responsibility',
      numeric: true,
      disablePadding: false,
      headerName: 'RESPONSIBLE',
      minWidth: 120,
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            {params.row.responsibility && params.row.responsibility.firstName
              ? params.row.responsibility.firstName
              : 'No responsible user'}{' '}
          </div>
          //TODO: remove check from responsible field
        );
      },
    },
    {
      field: 'dateRange',
      headerName: 'DATE',
      renderCell: (params) => {
        const startDate = getDateFormat(params.row.startDate);
        const endDate = getDateFormat(params.row.endDate);
        return (
          <div>
            {!params.row.startDate && !params.row.endDate
              ? '-'
              : `${startDate}  ${endDate}`}
          </div>
        );
      },
    },
    {
      field: 'status',
      numeric: true,
      align: 'center',
      disablePadding: false,
      headerName: 'STATUS',
      minWidth: 120,

      renderCell: (params) => {
        return (
          <StatusControllerComp
            inf={params.row}
            updateStatus={updateStatus}
            tasks
          />
        );
      },
    },
    {
      field: 'priority',
      numeric: true,
      disablePadding: false,
      headerName: 'PRIORITY',
    },
    {
      field: 'relatedToUser',
      numeric: true,
      disablePadding: false,
      headerName: 'RELATED TO',
      valueGetter: (params) => (params.value ? params.value.firstName : '-'),
      minWidth: 120,
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'ACTIONS',
      type: 'actions',
      align: 'right',
      maxWidth: 100,
      minWidth: 100,
      getActions: (params) => [
        /*   <GridActionsCellItem
        label="Overview"
        onClick={() => navigate(`/tasks/${params?.row?.id}`)}
        showInMenu
      />, */
        <GridActionsCellItem
          label="Edit"
          onClick={() => {
            setDrawerType('edit');
            setDrawerData(params.row);
            toggleAddTaskDrawer();
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Delete"
          onClick={() => handleDeleteOpenModal(params.id)}
          showInMenu
        />,
      ],
    },
  ];

  const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      // backgroundColor: theme.palette.grey[200],
      color: theme.palette.grey[500],
    },
    [`& .${gridClasses.columnHeader}`]: {
      backgroundColor: '#EFF1FC',
      color: '#3A3A50',
    },
  }));

  const toggleAddTaskDrawer = () => {
    setAddTaskDrawer(!addTask);
  };

  // delete child
  const handleDeleteOpenModal = (id) => {
    setOpenModal({ open: true, type: 'delete-modal', id });
  };
  const handleOpenModal = () => {
    setOpenModal({ open: false, type: null, id: null });
  };

  // handling date formatting
  const { getDateFormat } = useDateFormat(
    user?.settings?.GeneralSettings?.dFormat
  );

  const fetchTasks = async () => {
    setTableLoading(true);
    getTaskList(pageSize, pageCount).then((res) => {
      setTasks(res.data);
    });
  };

  const handleDeleteTask = (id) => {
    const ids = Array.isArray(id) ? id : [id];

    deleteTaskById(ids)
      .then((res) => {
        fetchTasks(); // Refresh tasks after deletion
        handleOpenModal(); // Close the modal after deletion
      })
      .catch((error) => {
        console.error('Error deleting task:', error); // Debug: Handle any errors
      });
  };

  // fetch on search & page change
  useEffect(() => {
    getQueries(state).then((queries) => {
      setTableLoading(false);
      getTaskList(pageSize, pageCount, queries).then((res) => {
        setTasks(res.data);
      });
    });
  }, [state, pageSize, pageCount]);

  // fetch on render
  useEffect(() => {
    fetchTasks();
  }, []);

  // update table rows on fetch
  useEffect(() => {
    if (tasks) {
      setRows(tasks);
      setTableLoading(false);
    }
  }, [tasks]);
  /*  useEffect(() => {
    fetchTasks();
  }); */

  const updateStatus = async (inf, status, handleClose) => {
    try {
      const {
        id,
        title,
        description,
        priority,
        orderId,
        referenceId,
        public: pub,
        startDate,
        endDate,
        creator,
      } = inf;
      const data = {
        id,
        title,
        description,
        status,
        priority,
        orderId,
        referenceId,
        public: pub,
        startDate,
        endDate,
        status: status.name,
        creator: JSON.stringify(creator),
      };

      let formdata = new FormData();

      for (const el in data) {
        formdata.append(el, data[el]);
      }

      const response = await editTaskById(formdata, inf.id);

      if (response.status >= 200 && response.status < 300) {
        dispatch(
          callSnackbar({
            message: response.data.msg,
            type: 'success',
          })
        );
        handleClose();
        fetchTasks();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Grid
      item
      container
      sx={{
        minWidth: { xs: '100%' },
        // paddingLeft: 4,
        // paddingRight: 4,
        px: '20px',
      }}
    >
      {openModal.type === 'delete-modal' && (
        <Modal
          handleOpenModal={handleOpenModal}
          type={'DELETE'}
          open={openModal.open}
          id={openModal.id}
          handleDelete={handleDeleteTask}
        />
      )}

      {
        // add tasks drawer
        addTask && (
          <AddTask
            toggleAddTaskDrawer={toggleAddTaskDrawer}
            drawerType={drawerType}
            drawerData={drawerData}
            fetchTasks={fetchTasks}
            // handelHide={handelHide}
          />
        )
      }

      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          flexWrap: 'wrap',
        }}
      >
        <Typography
          sx={{
            marginTop: 2.5,
            marginBottom: 1,
            fontSize: 24,
            color: '#3A3A50',
          }}
        >
          Task management
        </Typography>
      </Grid>
      <Box
        sx={{
          width: '100%',
          // width: { xs: '100%', sm: 'fit-content' },
          display: 'flex',
          // alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <CustomTextButton
          text="+ Add task"
          onClick={() => {
            setDrawerType('ADD');
            toggleAddTaskDrawer();
          }}
        />

        <Box sx={{ display: 'flex', gap: '10px', ml: '10px' }}>
          {FilterButton}
        </Box>
      </Box>
      {/* --------------------SEARCHBAR------------------------------- */}

      {FilterBar}

      {/* --------------------TABLE--------------------------------- */}
      <Grid
        container
        item
        justifyContent="space-between"
        sx={{ marginTop: '20px', height: '100%' }}
      >
        <div style={{ height: '100%', width: '100%' }}>
          <StripedDataGrid
            sx={{
              minHeight: '60vh',
              border: 0,
              '& .MuiDataGrid-virtualScroller': {
                minHeight: '300px !important',
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                fontSize: '12px',
                fontWeight: '900 !important',
              },
              '& .MuiDataGrid-main, .MuiDataGrid-footerContainer': {
                backgroundColor: 'white',
                border: 'none',
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
            }}
            getRowClassName={(params) => {
              return params.row.isHidden ? 'even' : 'odd';
            }}
            rows={rows}
            columns={headCells}
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            autoHeight
            loading={tableLoading}
            selectionModel={selectionModel}
            rowCount={1000}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => {
              setPageSize(newPageSize);
            }}
            rowsPerPageOptions={defaultPageSizeOptions}
            page={pageCount - 1}
            onPageChange={(newPage) => {
              setPageCount(newPage + 1);
            }}
            paginationMode="server"
            components={{
              NoRowsOverlay: NoData,
              NoResultsOverlay: NoData,
              Footer: () => (
                <CustomTableFooter
                  selectionModel={selectionModel}
                  handleDeleteOpenModal={handleDeleteOpenModal}
                />
              ),
              MoreActionsIcon: () => (
                <GridMoreVertIcon
                  sx={{
                    backgroundColor: 'white',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
                    borderRadius: '4px',
                    padding: '3px',
                    cursor: 'pointer',
                  }}
                />
              ),
              NoRowsOverlay: () => {
                return (
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'Center',
                      gap: '20px',
                    }}
                  >
                    <img
                      src="https://user-images.githubusercontent.com/109676648/254145031-57027cd6-fecd-4ce2-96fc-9d891c8de53a.svg"
                      alt="no data"
                      style={{
                        width: '100px',
                        height: '100px',
                      }}
                    />
                    No data at the moment 😔
                  </Box>
                );
              },
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default TaskManagement;
