import React, { useEffect, useRef, useState } from 'react';
import CustomTooltip from '../CustomTooltip';
import { Button, Menu, Box } from '@mui/material';
import CustomTypography from '../CustomTypography';
import { useNavigate } from 'react-router-dom';
import { selectUser } from '../../redux/slices/user.slice.js';
import { useSelector } from 'react-redux';

function TrialButton({ handleOpenModalTrial }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorWidth, setAnchorWidth] = useState(null);
  const [daysLeft, setDaysLeft] = useState(-1);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const { user } = useSelector(selectUser);
  const [isTrial, setIsTrial] = useState(
    user?.expiryDate &&
      user.expiryDate !== 'expired' &&
      user.trialStatus === 'Active'
  );

  useEffect(() => {
    if (anchorEl) {
      setAnchorWidth(anchorEl?.offsetWidth);
    }
  }, [anchorEl]);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const calculateDaysLeft = () => {
    if (!user?.expiryDate) return;

    const today = new Date(new Date().toISOString().split('T')[0]);
    const expiry = new Date(user.expiryDate);
    const Difference_In_Time = expiry.getTime() - today.getTime();
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    setDaysLeft(Difference_In_Days);
    return Difference_In_Days;
  };

  useEffect(() => {
    calculateDaysLeft();
  }, []);

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleCloseMenu}
        elevation={1}
        MenuListProps={{
          sx: {
            border: '1px solid #e3eaf3',
            borderRadius: '0px',
            width: { xs: anchorWidth, md: '200px' },
          },
        }}
      >
        <Box sx={{ p: 1 }}>
          <CustomTypography sx={{ fontSize: '14px', mb: 2 }}>
            Expires: {user.expiryDate}
          </CustomTypography>
          <Button
            onClick={(e) => {
              navigate('/subscription');
            }}
            sx={{
              color: '#fff',
              backgroundColor: '#32313f',
              textTransform: 'capitalize',
              '&:hover': {
                backgroundColor: '#32313f',
                shadow: 'none',
              },
              width: '100%',
            }}
          >
            Upgrade
          </Button>
        </Box>
      </Menu>
      {(user.trialStatus === 'Unavailable' || daysLeft < 0) &&
        !daysLeft > 0 && (
          <Button
            onClick={(e) => {
              navigate('/subscription');
            }}
            sx={{
              color: '#fff',
              backgroundColor: '#32313f',
              textTransform: 'capitalize',
              '&:hover': {
                backgroundColor: '#32313f',
                shadow: 'none',
              },
              width: '100%',
            }}
          >
            Upgrade
          </Button>
        )}

      {isTrial && (
        <CustomTooltip arrow title={`Start ${daysLeft} days trial`}>
          <Button
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
            }}
            sx={{
              color: '#fff',
              backgroundColor: '#39D581',
              height: '40px',
              textTransform: 'capitalize',
              '&:hover': {
                backgroundColor: '#39D581',
                shadow: 'none',
              },
              width: { xs: '100%', md: 'auto' },
            }}
          >
            Trial{' '}
            <CustomTypography sx={{ fontSize: '14px', color: '#fff', ml: 3 }}>
              {daysLeft} days left
            </CustomTypography>
          </Button>
        </CustomTooltip>
      )}
      {user.trialStatus === 'Available' && (
        <CustomTooltip arrow title={`Start ${daysLeft} days trial`}>
          <Button
            onClick={handleOpenModalTrial}
            sx={{
              color: '#fff',
              backgroundColor: '#39D581',
              height: '40px',
              textTransform: 'capitalize',
              '&:hover': {
                backgroundColor: '#39D581',
                shadow: 'none',
              },
              width: { xs: '100%', md: 'auto' },
            }}
          >
            Start Trial
          </Button>
        </CustomTooltip>
      )}
    </>
  );
}

export default TrialButton;
