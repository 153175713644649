import React, { useLayoutEffect, useState } from 'react';
import Drawer from '../../Drawer';
import DrawerFooter from '../../DrawerFooter';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useMediaQuery, useTheme } from '@mui/material';
import {
  addBreak,
  addService,
  addSubService,
  addTimesheet,
  editServiceById,
  editTimesheetById,
  getTimesheetById,
} from '../../../Api/tenant/services';
import { getCategoryList, addCategory } from '../../../Api/tenant/categories';
import useUser from '../../../hooks/useUser';
import { getStaffList } from '../../../Api/tenant/staff';
import { convertTime24to12 } from '../../../utils/ConvertTime24to12';
import { convertTime12to24 } from '../../../utils/ConvertTime12to24';
// import ServiceTab from './../../../pages/Appointment/Tabs/index';

const dayList = [
  {
    id: 0,
    day: 'Sunday',
    startTime: '',
    endTime: '',
    isDayOff: false,
    endTimeError: false,
    startTimeError: false,
  },
  {
    id: 1,
    day: 'Monday',
    startTime: '',
    endTime: '',
    isDayOff: false,
    endTimeError: false,
    startTimeError: false,
  },
  {
    id: 2,
    day: 'Tuesday',
    startTime: '',
    endTime: '',
    isDayOff: false,
    endTimeError: false,
    startTimeError: false,
  },
  {
    id: 3,
    day: 'Wednesday',
    startTime: '',
    endTime: '',
    isDayOff: false,
    endTimeError: false,
    startTimeError: false,
  },
  {
    id: 4,
    day: 'Thursday',
    startTime: '',
    endTime: '',
    isDayOff: false,
    endTimeError: false,
    startTimeError: false,
  },
  {
    id: 5,
    day: 'Friday',
    startTime: '',
    endTime: '',
    isDayOff: false,
    endTimeError: false,
    startTimeError: false,
  },
  {
    id: 6,
    day: 'Saturday',
    startTime: '',
    endTime: '',
    isDayOff: false,
    endTimeError: false,
    startTimeError: false,
  },
];

const requiredFields = [
  'name',
  'categoryId',
  'price',
  'duration',
  'slotLength',
];

const AddCategories = ({
  toggleDrawer,
  drawerType,
  drawerData = null,

  handelHide,
  fetchServices,
}) => {
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('sm'));
  const { user, refetchUser } = useUser();

  //  service details tab
  const [image, setImage] = useState(null);
  const [serviceData, setServiceData] = useState({
    name: null,
    categoryId: null,
    price: null,
    duration: null,
    slotLength: null,
    BTB: null,
    BTA: null,
    note: null,
    enableDeposit: false,
    deposit: 20,
    depositType: true,
    hideDur: false,
    hidePrice: false,
    capacity: 'alone',
    recurring: false,
    isFullPeriod: false,
    fixedPeriod: '3', // delete after fn is complete
    fixedPeriodType: 'week', // delete
    isFixedFrequency: false,
    fixedFreq: '2', // delete
    // delete
    repeat: false, //same as recurring
    payment: true,
  });
  const [categories, setcategories] = useState([]);

  // staff tab
  const [staff, setStaff] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState([]);

  // timesheet tab
  const [breaks, setBreaks] = useState([]);
  const [weeklyData, setWeeklyData] = useState({
    timesheet: false,
    dayData: dayList,
  });

  // extraService tab
  const [extraService, setExtraService] = useState([]);

  //category drawer
  const [categoryName, setCategoryName] = useState('');
  const [selectedParentCategory, setSelectedParentCategory] = useState({
    name: 'Root category',
    id: 0,
  });
  const [categoryOptions, setCategoryOptions] = useState([
    { name: 'Root category', id: 0 },
  ]);

  // error handling
  const [errors, setErrors] = useState({});

  const handelHidedata = () => {
    handelHide(drawerData.id);
    toggleDrawer();
  };

  // API

  const fetchServiceOptions = async () => {
    const categories = await getCategoryList(100, 1);
    const staff = await getStaffList(100, 1);
    return {
      categories: categories.data,
      staff: staff.data,
    };
  };

  useLayoutEffect(() => {
    if (drawerType === 'add') {
      getCategoryList(100, 1).then(({ data }) => {
        setCategoryOptions([{ name: 'Root category', id: 0 }, ...data]);
      });
    }

    if (drawerType === 'addService' || drawerType === 'edit') {
      fetchServiceOptions().then(({ categories, staff }) => {
        setcategories(categories);
        setStaff(staff);
      });
    }

    if (drawerType === 'edit') {
      setServiceData(drawerData);
      setImage(drawerData.image);

      if (drawerData.timesheetId) {
        getTimesheetById(drawerData.timesheetId).then((res) => {
          const fetchedTimesheet = res.data;

          const splitedFromValues = fetchedTimesheet.from.split('-');
          const splitedToValues = fetchedTimesheet.to.split('-');

          let newDayList = [...dayList];

          const timesheetObj = newDayList.map((day, i) => {
            if (splitedFromValues[i].split('/')[1] !== 'DF') {
              return {
                ...day,
                startTime: convertTime24to12(
                  splitedFromValues[i].split('/')[1]
                ),
                endTime: convertTime24to12(splitedToValues[i].split('/')[1]),
              };
            } else {
              return {
                ...day,
                startTime: 'day off',
                endTime: 'day off',
                isDayOff:
                  splitedFromValues[i].split('/')[1] === 'DF' ? true : false,
              };
            }
          });
          console.log(timesheetObj);
          setWeeklyData({ timesheet: true, dayData: [...timesheetObj] });
        });
      }
    }
  }, []);

  const handleAddExtra = () => {
    let extraArrayPromises = [];

    extraService.map(
      ({
        serviceName,
        image,
        maxQuantity,
        minQuantity,
        isEnabled,
        price,
        hidePrice,
        hideDuration,
        duration,
      }) => {
        const promise = addSubService({
          duration,
          hideDur: hideDuration,
          hidePrice,
          image,
          isEnabled,
          maxQty: maxQuantity,
          minQty: minQuantity,
          price,
          name: serviceName,
        });
        extraArrayPromises = [...extraArrayPromises, promise];
      }
    );

    return extraArrayPromises;
  };

  /**
   * @func
   * formating breaks for the request
   *
   * @example
   *  {"sun": "[[14:00,14:30]/ [15:30,16:30]]","mon": "[[12:00,14:00]]" }
   *
   */
  const normalizeBreaks = () => {
    let normalizedBreaks = {};

    breaks.map((b) => {
      const breakKey = b.day.slice(0, 3).toLowerCase();

      if (normalizedBreaks[breakKey]) {
        normalizedBreaks[breakKey] =
          `${normalizedBreaks[breakKey]}` +
          '/' +
          `[${[convertTime12to24(b.startTime), convertTime12to24(b.endTime)]}]`;
      } else {
        normalizedBreaks[breakKey] = `[${[
          convertTime12to24(b.startTime),
          convertTime12to24(b.endTime),
        ]}]`;
      }
    });

    // adding additional array brackets
    for (const key in normalizedBreaks) {
      normalizedBreaks[key] = `[${normalizedBreaks[key]}]`;
    }

    return normalizedBreaks;
  };

  const handleAddBreak = async () => {
    const normalizedBreaks = normalizeBreaks();
    console.log(normalizedBreaks);
    const res = await addBreak(normalizedBreaks);

    return res.data.BreaksId;
  };

  /**
   * @func
   * formating weekly-schedule for the request
   *
   * @example
   *  {
   * "from": "1/12:30-2/12:30-3/14:30-4/DF-5/16:00-6/DF-7/18:30",
   * "to": "1/16:30-2/17:30-3/18:30-4/DF-5/17:00-6/DF-7/23:30"}
   *
   */
  const normalizeTimesheet = () => {
    let from = '';
    let to = '';
    weeklyData.dayData.map((day, i) => {
      from =
        from +
        `${day.id}/${day.isDayOff ? 'DF' : convertTime12to24(day.startTime)}${
          i === weeklyData.dayData.length - 1 ? '' : '-'
        }`;
      to =
        to +
        `${day.id}/${day.isDayOff ? 'DF' : convertTime12to24(day.endTime)}${
          i === weeklyData.dayData.length - 1 ? '' : '-'
        }`;
    });

    return { from, to };
  };

  const handleAddTimesheet = async () => {
    const normalizedTimesheet = normalizeTimesheet();
    // add breaks
    const breaksId = await handleAddBreak();
    console.log('BreaksId', breaksId);

    const res = await addTimesheet({ ...normalizedTimesheet, breaksId });

    return res.data;
  };

  // adding service logic
  const handleAddService = async () => {
    // error/validation handling
    let err = {};
    let data = { ...serviceData };

    for (const key in data) {
      if (!data[key] && requiredFields.includes(key)) {
        err = { ...err, [key]: true };
      }
      if (
        Array.isArray(data[key]) &&
        data[key].length === 0 &&
        requiredFields.includes(key)
      ) {
        console.log(data[key]);
        err = { ...err, [key]: true };
      }
    }
    // timesheet validation
    const weeklyDataWithError = weeklyData.dayData.map((day) => {
      if (
        (!day.startTime && weeklyData.timesheet && !day.isDayOff) ||
        (!day.endTime && weeklyData.timesheet && !day.isDayOff)
      )
        err = { ...err, timesheetErr: true };
      return {
        ...day,
        startTimeError:
          (day.startTime && weeklyData.timesheet) || day.isDayOff
            ? false
            : true,
        endTimeError:
          (day.endTime && weeklyData.timesheet) || day.isDayOff ? false : true,
      };
    });

    setWeeklyData((prev) => {
      return { ...prev, dayData: weeklyDataWithError };
    });
    setErrors({ ...err });

    // request logic

    if (Object.keys(err).length === 0) {
      // add extras
      // format "id-id-id..."
      const extraArrayPromises = handleAddExtra();
      const extraServiceIds = await Promise.all(extraArrayPromises).then(
        (resArray) => resArray.map((res) => res.data['Service Id'])
      );
      const extraId = extraServiceIds.join('-');
      console.log(extraId);

      // add timesheet
      const timesheetId = await handleAddTimesheet();
      console.log('timesheetId', timesheetId);

      // staff members for the service
      // format "id-id-id..."
      // format "sp-0-1-0-1..."
      // format "staffPrice%-45$-45%..."
      const staffId = selectedStaff.map((staff) => staff.id).join('-');
      const staffSP = selectedStaff
        .map((staff) => (staff.Isprice ? 1 : 0))
        .join('-');
      const staffPrice = selectedStaff
        .map((staff) => staff.price + staff.method)
        .join('-');

      // service body
      let formData = new FormData();
      for (const el in serviceData) {
        formData.append(el, serviceData[el]);
      }
      extraId && formData.append('subServiceId', extraId);
      formData.append('Staff', staffId);
      formData.append('staffSP', staffSP);
      formData.append('staffPrice', staffPrice);

      weeklyData.timesheet && formData.append('timesheetId', timesheetId);
      formData.append('image', image);

      // calling api
      addService(formData).then((res) => {
        if (res.status === 200) {
          refetchUser();
          toggleDrawer();
          fetchServices();
        }
      });
    }
  };

  //  add category
  const handleAddCategory = () => {
    addCategory({
      parentId:
        selectedParentCategory.id === 0 ? null : selectedParentCategory.id,
      name: categoryName,
    });
  };

  // editing service

  const handleEditTimesheet = async () => {
    const normalizedTimesheet = normalizeTimesheet();
    // add breaks
    const breaksId = await handleAddBreak();
    console.log('BreaksId', breaksId);

    const res = await editTimesheetById(
      { ...normalizedTimesheet, breaksId },
      drawerData.timesheetId
    );

    return res.data;
  };

  const handleEditService = async () => {
    // add extras
    // format "id-id-id..."

    // const extraArrayPromises = handleAddExtra();
    // const extraServiceIds = await Promise.all(extraArrayPromises).then(
    //   (resArray) => resArray.map((res) => res.data["Service Id"])
    // );
    // const extraId = extraServiceIds.join("-");
    // console.log(extraId);

    // edit or add timesheet
    let timesheetId = null;
    if (drawerData.timesheetId) {
      console.log(drawerData.timesheetId);
      await handleEditTimesheet();
      timesheetId = drawerData.timesheetId;
    } else if (!drawerData.timesheetId && weeklyData.timesheet) {
      timesheetId = await handleAddTimesheet();
    }
    // staff members for the service
    // format "id-id-id..."
    // format "sp-0-1-0-1..."
    // format "staffPrice%-45$-45%..."
    const staffId = selectedStaff.map((staff) => staff.id).join('-');
    const staffSP = selectedStaff
      .map((staff) => (staff.Isprice ? 1 : 0))
      .join('-');
    const staffPrice = selectedStaff
      .map((staff) => staff.price + staff.method)
      .join('-');

    // service body
    let formData = new FormData();
    for (const el in serviceData) {
      formData.append(el, serviceData[el]);
    }

    // extra service
    // extraId && formData.append("subServiceId", extraId);

    //staff
    selectedStaff.length !== 0 && formData.append('Staff', staffId);
    selectedStaff.length !== 0 && formData.append('staffSP', staffSP);
    selectedStaff.length !== 0 && formData.append('staffPrice', staffPrice);

    formData.append('image', image);

    // timesheet
    // formData.set("timesheetId", timesheetId);
    formData.delete('timesheetId');

    // calling api
    editServiceById(formData, drawerData.id).then((res) => {
      if (res.status === 200) {
        toggleDrawer();
        fetchServices();
      }
    });
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={2}>
        <Drawer
          type={
            drawerType === 'add'
              ? 'ADD'
              : drawerType === 'addSub'
              ? 'ADD'
              : 'EDIT'
          }
          title={
            drawerType === 'add'
              ? 'Add Category'
              : drawerType === 'addSub'
              ? 'Add Subcategory'
              : drawerType === 'editCat'
              ? 'Edit Category'
              : 'Edit Subcategory'
          }
          toggleDrawer={toggleDrawer}
        >
          {/* -----------FORM----- */}
          <Grid sx={{ height: '100%', overflowY: 'auto', width: '100%' }}>
            {drawerType === 'add' && (
              <Grid sm={12} xs={12}>
                <Grid sx={{ px: 5, pt: 5 }}>
                  <Typography variant="subtitle2" sx={{ my: 1 }}>
                    Parent category<sapn style={{ color: 'red' }}>*</sapn>
                  </Typography>
                  <Autocomplete
                    size="small"
                    disablePortal
                    options={categoryOptions}
                    getOptionLabel={(option) => option.name}
                    value={selectedParentCategory}
                    onChange={(e, data) => {
                      setSelectedParentCategory(data);
                    }}
                    sx={{
                      marginBottom: '1rem',
                      width: '100%',
                      fontSize: '14px',
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Grid>
                <Grid sx={{ padding: 5, pt: 1 }}>
                  <Typography variant="subtitle2" sx={{ my: 1 }}>
                    Category name<sapn style={{ color: 'red' }}>*</sapn>
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    value={categoryName}
                    onChange={(e) => setCategoryName(e.target.value)}
                  />
                </Grid>
              </Grid>
            )}
            {drawerType === 'editCat' && (
              <Grid sm={12} xs={12}>
                <Grid sx={{ px: 5, pt: 5 }}>
                  <Typography variant="subtitle2" sx={{ my: 1 }}>
                    Parent category<sapn style={{ color: 'red' }}>*</sapn>
                  </Typography>
                  <Autocomplete
                    size="small"
                    disablePortal
                    options={categoryOptions}
                    getOptionLabel={(option) => option.name}
                    value={selectedParentCategory}
                    onChange={(e, data) => {
                      setSelectedParentCategory(data);
                    }}
                    sx={{
                      marginBottom: '1rem',
                      width: '100%',
                      fontSize: '14px',
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Grid>
                <Grid sx={{ padding: 5, pt: 1 }}>
                  <Typography variant="subtitle2" sx={{ my: 1 }}>
                    Category name<sapn style={{ color: 'red' }}>*</sapn>
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    value={categoryName}
                    onChange={(e) => setCategoryName(e.target.value)}
                  />
                </Grid>
              </Grid>
            )}

            {drawerType === 'addSub' && (
              <Grid sm={12} xs={12}>
                <Grid sx={{ px: 5, pt: 5 }}>
                  <Typography variant="subtitle2" sx={{ my: 1 }}>
                    Subcategory name<sapn style={{ color: 'red' }}>*</sapn>
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    value={categoryName}
                    onChange={(e) => setCategoryName(e.target.value)}
                  />
                </Grid>
                <Grid sx={{ padding: 5, pt: 1 }}>
                  <Typography variant="subtitle2" sx={{ my: 1 }}>
                    Category name<sapn style={{ color: 'red' }}>*</sapn>
                  </Typography>
                  <Autocomplete
                    size="small"
                    disablePortal
                    options={categoryOptions}
                    getOptionLabel={(option) => option.name}
                    value={selectedParentCategory}
                    onChange={(e, data) => {
                      setSelectedParentCategory(data);
                    }}
                    sx={{
                      marginBottom: '1rem',
                      width: '100%',
                      fontSize: '14px',
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Grid>
              </Grid>
            )}
            {drawerType === 'edit' && (
              <Grid sm={12} xs={12}>
                <Grid sx={{ px: 5, pt: 5 }}>
                  <Typography variant="subtitle2" sx={{ my: 1 }}>
                    Subcategory name<sapn style={{ color: 'red' }}>*</sapn>
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    value={categoryName}
                    onChange={(e) => setCategoryName(e.target.value)}
                  />
                </Grid>
                <Grid sx={{ padding: 5, pt: 1 }}>
                  <Typography variant="subtitle2" sx={{ my: 1 }}>
                    Category name<sapn style={{ color: 'red' }}>*</sapn>
                  </Typography>
                  <Autocomplete
                    size="small"
                    disablePortal
                    options={categoryOptions}
                    getOptionLabel={(option) => option.name}
                    value={selectedParentCategory}
                    onChange={(e, data) => {
                      setSelectedParentCategory(data);
                    }}
                    sx={{
                      marginBottom: '1rem',
                      width: '100%',
                      fontSize: '14px',
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Grid>
              </Grid>
            )}
            {/* {drawerType !== 'add' && (
              <ServiceTab
                categories={categories}
                serviceData={serviceData}
                setServiceData={setServiceData}
                staff={staff}
                setStaff={setStaff}
                selectedStaff={selectedStaff}
                setSelectedStaff={setSelectedStaff}
                // image={image}
                // setImage={setImage}
                // breaks={breaks}
                // setBreaks={setBreaks}
                // weeklyData={weeklyData}
                // setWeeklyData={setWeeklyData}
                // extraService={extraService}
                // setExtraService={setExtraService}
                errors={errors}
              />
            )} */}
          </Grid>
          {/* -----Button */}

          <DrawerFooter>
            <>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: isSmallScreen ? 'flex-start' : 'flex-end',
                }}
              >
                {/* {drawerType === "edit" && (
                  <Button
                    variant="contained"
                    size="large"
                    sx={{
                      width: { xs: "100%", sm: "auto" },
                      marginBottom: { xs: 1, sm: 0 },
                      background: "#f7f8fb",
                      color: "#8F9CA7",
                      ":hover": {
                        backgroundColor: "#dadada",
                        color: "#898e92",
                      },
                    }}
                    onClick={handelHidedata}
                  >
                    {drawerData.isHidden ? "UNHIDE SERVICE" : " HIDE SERVICE"}
                  </Button>
                )} */}
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: isSmallScreen ? 'space-between' : 'flex-end',
                  width: isSmallScreen ? '100%' : 'auto',
                  alignItems: 'center',
                }}
              >
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    width: isSmallScreen ? '48%' : '7rem',
                    marginLeft: { xs: 0, sm: 2 },
                    backgroundColor: 'WHITE',
                    color: 'GREY',
                    ':hover': { backgroundColor: 'GREY', color: 'WHITE' },
                  }}
                  onClick={() => {
                    toggleDrawer();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    width: isSmallScreen ? '48%' : '7rem',
                    marginLeft: { xs: 0, sm: 2 },
                  }}
                  onClick={() => {
                    if (drawerType === 'add') {
                      handleAddCategory();
                    } else if (drawerType === 'addService') {
                      handleAddService();
                    } else {
                      handleEditService();
                    }
                  }}
                >
                  {drawerType === 'add'
                    ? 'Add'
                    : drawerType === 'addSub'
                    ? 'Add'
                    : 'Save'}
                </Button>
              </div>
            </>
          </DrawerFooter>
        </Drawer>
      </Grid>
    </Grid>
  );
};

export default AddCategories;
