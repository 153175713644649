export const statusList = [
  {
    name: 'Pending Approval',
    backgroundColor: 'white',
    color: '#1E1A4D',
    boxShadow:
      'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
  },
  { name: 'Confirmed', backgroundColor: '#25D08E', color: 'white' },
  { name: 'In Progress', backgroundColor: '#25D08E', color: 'white' },
  { name: 'Rescheduled', backgroundColor: '#25D08E', color: 'white' },
  { name: 'On Hold', backgroundColor: '#FF6C6C', color: 'white' },
  { name: 'Completed', backgroundColor: '#3E79CB', color: 'white' },
  { name: 'Await Payment', backgroundColor: '#1E1A4D', color: 'white' },
  { name: 'No Show', backgroundColor: '#FF6C6C', color: 'white' },
  { name: 'Cancelled', backgroundColor: '#FF6C6C', color: 'white' },
  { name: 'Paid', backgroundColor: '#3E79CB', color: 'white' },
  { name: 'Rejected', backgroundColor: '#FF6C6C', color: 'white' },
];

export const tasksStatusList = [
  { name: 'In Progress', backgroundColor: '#25D08E', color: 'white' },
  { name: 'To-Do', backgroundColor: '#25D08E', color: 'white' },
  { name: 'Overdue', backgroundColor: '#25D08E', color: 'white' },
  { name: 'Scheduled', backgroundColor: '#25D08E', color: 'white' },
  { name: 'On Hold', backgroundColor: '#FF6C6C', color: 'white' },
  { name: 'Cancelled', backgroundColor: '#FF6C6C', color: 'white' },
  { name: 'Blocked', backgroundColor: '#FF6C6C', color: 'white' },
  { name: 'Deferred', backgroundColor: '#FF6C6C', color: 'white' },
  { name: 'Completed', backgroundColor: '#3E79CB', color: 'white' },
];

export const statusTypes = {
  Received: {
    color: '#509F8A',
    bgColor: '#84FFDE',
    text: 'Received',
  },
  Confirmed: {
    color: 'white',
    bgColor: '#25D08E',
    text: 'Confirmed',
  },
  'On Hold': {
    color: 'white',
    bgColor: '#FF6C6C',
    text: 'On hold',
  },
  Cancelled: {
    color: 'white',
    bgColor: '#FF6C6C',
    text: 'Cancelled',
  },
  Rescheduled: {
    color: 'white',
    bgColor: '#25D08E',
    text: 'Rescheduled',
  },
  'Pending Approval': {
    color: '#1E1A4D',
    bgColor: 'white',
    text: 'Pending Approval',
  },
  'In Progress': {
    color: 'white',
    bgColor: '#25D08E',
    text: 'In Progress',
  },
  Completed: {
    color: 'white',
    bgColor: '#3E79CB',
    text: 'Completed',
  },
  Rejected: {
    color: '#FFFFFF',
    bgColor: '#FF6C6C',
    text: 'Rejected',
  },
  'No Show': { color: 'white', bgColor: '#FF6C6C', text: 'No Show' },
  'Await Payment': {
    color: 'white',
    bgColor: '#1E1A4D',
    text: 'Await Payment',
  },
  Paid: {
    color: 'white',
    bgColor: '#3E79CB',
    text: 'Paid',
  },
  Notpaid: {
    color: '#FFFFFF',
    bgColor: '#C8CFD8',
    text: 'Not paid',
  },
  active: {
    color: '#FFFFFF',
    bgColor: '#25D08E',
    text: 'Active',
  },
  inActive: {
    color: '#FFFFFF',
    bgColor: '#FF6C6C',
    text: 'Inactive',
  },
  // tasks related
  'To-Do': {
    color: '#FFFFFF',
    bgColor: '#25D08E',
    text: 'To-Do',
  },
  Overdue: {
    color: '#FFFFFF',
    bgColor: '#25D08E',
    text: 'Overdue',
  },
  Scheduled: {
    color: '#FFFFFF',
    bgColor: '#25D08E',
    text: 'Scheduled',
  },
  Deferred: {
    color: '#FFFFFF',
    bgColor: '#FF6C6C',
    text: 'Deferred',
  },
  Blocked: {
    color: '#FFFFFF',
    bgColor: '#FF6C6C',
    text: 'Blocked',
  },
};
