import * as React from 'react';
import Stack from '@mui/material/Stack';
import Appointment from "../../assets/images/appointment.png";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CustomTextButton from '../../components/CustomButtons/CustomTextButton';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';

const UpsellAppointment = () => {
  return (  
    <React.Fragment>
      <Container maxWidth="sm">
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center', 
          marginTop: '7%',
          paddingBottom: '5%',
          textAlign: 'center',
  
          '& img': {
            width: '100%',
            maxWidth: '80%',
            height: 'auto',
            padding: '10px', 
          },
          '@media (max-width: 600px)': {
            '& img': {
              padding: '10px',
            },
          },
        }}>
          <img
            src={Appointment}
            alt="persons image"
          />
          <h1>Appointment management</h1>
          <Typography >Elevate your appointment management experience for your company’s users.
           Take control of scheduling with our intuitive platform, and stay tuned for upcoming feature enhancements.
           Upgrade today to access the latest innovations.</Typography>

          <Stack spacing={2} direction="row" marginTop={4}>
            <Link to="https://upnize.com/help-center/appointment-management">
            
            <CustomTextButton sx={{textTransform: 'capitalize'}}
                  variant="contained"
                  size="large"
                >
                  Learn more
                </CustomTextButton>
            </Link>
            <Link to="/subscription">
                <CustomTextButton sx={{textTransform: 'capitalize'}}
                  type="blue"
                  variant="contained"
                  size="large" >
                    Upgrade
                  </CustomTextButton>
            </Link>
          </Stack>
        </Box>
      </Container>
    </React.Fragment>
  );
}

export default UpsellAppointment;
