import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import Drawer from '../../components/Drawer';
import DrawerFooter from '../../components/DrawerFooter';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useMediaQuery, useTheme } from '@mui/material';
import ServiceTab from './Tabs';
import {
  addService,
  addSubService,
  editServiceById,
  getServiceById,
} from '../../Api/tenant/services';
import { getCategoryList, addCategory } from '../../Api/tenant/categories';
import { getStaffList } from '../../Api/tenant/staff';
import CustomTextButton from '../../components/CustomButtons/CustomTextButton';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser, selectUser } from '../../redux/slices/user.slice.js';
import { uniqueId } from 'lodash';
import { getLocationList } from '../../Api/tenant/locations';

// Do not change the order
const dayList = [
  {
    id: uniqueId('day-info-'),
    day: 'Sunday',
    from: '01:00',
    to: '02:30',
    dayOff: false,
    breaks: [],
  },
  {
    id: uniqueId('day-info-'),
    day: 'Monday',
    from: '01:00',
    to: '02:30',
    dayOff: false,
    breaks: [],
  },
  {
    id: uniqueId('day-info-'),
    day: 'Tuesday',
    from: '01:00',
    to: '02:30',
    dayOff: false,
    breaks: [],
  },
  {
    id: uniqueId('day-info-'),
    day: 'Wednesday',
    from: '01:00',
    to: '02:30',
    dayOff: false,
    breaks: [],
  },
  {
    id: uniqueId('day-info-'),
    day: 'Thursday',
    from: '01:00',
    to: '02:30',
    dayOff: false,
    breaks: [],
  },
  {
    id: uniqueId('day-info-'),
    day: 'Friday',
    from: '01:00',
    to: '02:30',
    dayOff: false,
    breaks: [],
  },
  {
    id: uniqueId('day-info-'),
    day: 'Saturday',
    from: '01:00',
    to: '02:30',
    dayOff: false,
    breaks: [],
  },
];

const timeSlotOptions = [
  {
    label: 'set slot length as service duration',
    duration: 'duration',
  },
  {
    label: 'set slot length as default',
    duration: 'default',
  },
  {
    label: '1m',
    duration: '1',
  },
  {
    label: '2m',
    duration: '2',
  },
  {
    label: '3m',
    duration: '3',
  },
  {
    label: '4m',
    duration: '4',
  },
  {
    label: '5m',
    duration: '5',
  },
  {
    label: '10m',
    duration: '10',
  },
  {
    label: '12m',
    duration: '12',
  },
  {
    label: '15m',
    duration: '15',
  },
  {
    label: '20m',
    duration: '20',
  },
  {
    label: '25m',
    duration: '25',
  },
  {
    label: '30m',
    duration: '30',
  },
  {
    label: '35m',
    duration: '35',
  },
  {
    label: '40m',
    duration: '40',
  },
  {
    label: '50m',
    duration: '50',
  },
  {
    label: '55m',
    duration: '55',
  },
  {
    label: '1h',
    duration: '60',
  },
  {
    label: '1h 30m',
    duration: '90',
  },
  {
    label: '2h',
    duration: '120',
  },
  {
    label: '2h 30m',
    duration: '150',
  },
  {
    label: '3h',
    duration: '180',
  },
  {
    label: '3h 30m',
    duration: '210',
  },
  {
    label: '4h',
    duration: '240',
  },
  {
    label: '4h 30m',
    duration: '270',
  },
  {
    label: '5h',
    duration: '300',
  },
];

const requiredFields = [
  'name',
  'categoryId',
  'price',
  'duration',
  'slotLength',
  'deposit',
  'capacity',
];

const fixedDurationOptions = [
  {
    label: '1d',
    duration: 1440,
  },
  {
    label: '2d',
    duration: 2880,
  },
  {
    label: '3d',
    duration: 4320,
  },
  {
    label: '4d',
    duration: 5760,
  },
  {
    label: '5d',
    duration: 7200,
  },
  {
    label: '6d',
    duration: 8640,
  },
  {
    label: '1w',
    duration: 10080,
  },
  {
    label: '8d',
    duration: 11520,
  },
  {
    label: '9d',
    duration: 12960,
  },
  {
    label: '10d',
    duration: 14400,
  },
  {
    label: '11d',
    duration: 15840,
  },
  {
    label: '12d',
    duration: 17280,
  },
  {
    label: '13d',
    duration: 18720,
  },
  {
    label: '2w',
    duration: 20160,
  },
  {
    label: '15d',
    duration: 21600,
  },
  {
    label: '16d',
    duration: 23040,
  },
  {
    label: '17d',
    duration: 24480,
  },
  {
    label: '18d',
    duration: 25920,
  },
  {
    label: '19d',
    duration: 27360,
  },
  {
    label: '20d',
    duration: 28800,
  },
  {
    label: '3w',
    duration: 30240,
  },
  {
    label: '22d',
    duration: 31680,
  },
  {
    label: '23d',
    duration: 33120,
  },
  {
    label: '24d',
    duration: 34560,
  },
  {
    label: '25d',
    duration: 36000,
  },
  {
    label: '26d',
    duration: 37440,
  },
  {
    label: '27d',
    duration: 38880,
  },
  {
    label: '4w',
    duration: 40320,
  },
  {
    label: '29d',
    duration: 41760,
  },
  {
    label: '30d',
    duration: 43200,
  },
  {
    label: '31d',
    duration: 44640,
  },
];

const AddServices = ({
  toggleDrawer,
  drawerType,
  drawerData = null,
  handelHide,
  fetchServices,
}) => {
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('sm'));
  const dispatch = useDispatch();
  const { user, isUserLoading } = useSelector(selectUser);
  //  service details tab
  const [image, setImage] = useState(null);
  const [serviceData, setServiceData] = useState({
    name: null,
    categoryId: null,
    price: null,
    duration: null,
    slotLength: null,
    BTB: null,
    BTA: null,
    note: '',
    enableDeposit: false,
    deposit: 20,
    depositType: true,
    hideDur: false,
    hidePrice: false,
    capacity: 1,
    recurring: false,
    isFullPeriod: false,
    fixedPeriod: '3', // delete after fn is complete
    fixedPeriodType: 'week', // delete
    isFixedFrequency: false,
    fixedFreq: '2', // delete
    // delete
    repeat: false, //same as recurring
    payment: true,
  });
  const [categories, setcategories] = useState([]);
  const [durationOptions, setDurationOptions] = useState([]);

  // linked staff - staff tab
  const [selectedStaff, setSelectedStaff] = useState([]);

  // linked locations - location tab
  const [locations, setLocations] = useState([]);

  // timesheet tab
  const [timesheets, setTimesheets] = useState(dayList);
  // Marking service to have seperate timesheet enabled
  const [spts, setSpts] = useState(false);

  // extraService tab
  const [extraService, setExtraService] = useState([]);

  //category drawer
  const [categoryName, setCategoryName] = useState('');
  const [selectedParentCategory, setSelectedParentCategory] = useState({
    name: 'Root category',
    id: 0,
  });
  const [categoryOptions, setCategoryOptions] = useState([
    { name: 'Root category', id: 0 },
  ]);

  // error handling
  const [errors, setErrors] = useState({});

  // API
  const fetchServiceData = useCallback(async () => {
    try {
      const response = await getServiceById(drawerData?.id);
      if (response.status === 200) {
        const result = response.data;
        console.log(result);
        setTimesheets(result.timesheets);
        setSpts(result.spts);
        setServiceData(result);
        setSelectedStaff(result.staff);
        setLocations(result.locations);
        setImage(result.image);
      }
    } catch (err) {
      console.log(err);
    }
  }, [drawerData?.id]);

  const fetchServiceOptions = async () => {
    const categories = await getCategoryList(100, 1);
    return {
      categories: categories.data,
    };
  };

  const calculateDurationOptions = () => {
    let date = new Date('August 19, 1975 00:00:00');
    let minutesToAdd = user?.settings?.GeneralSettings?.timeSlotLength;
    let endDate = new Date(date.getTime());

    let result = [];
    // calculating dybamic duration options based on timeslot length in user settings
    while (endDate.getDate() === date.getDate()) {
      let hours = endDate.getHours();
      let minutes = endDate.getMinutes();
      result.push({
        label: hours + 'h' + ' ' + minutes + 'm',
        duration: hours * 60 + minutes,
      });
      endDate = new Date(endDate.getTime() + minutesToAdd * 60000);
    }

    // then adding fixed options
    result.push(...fixedDurationOptions);

    setDurationOptions(result);
  };

  useLayoutEffect(() => {
    if (drawerType === 'add') {
      getCategoryList(100, 1).then(({ data }) => {
        setCategoryOptions([{ name: 'Root category', id: 0 }, ...data]);
      });
    }

    if (drawerType === 'addService') {
      getStaffList(1000, 1).then((res) => {
        if (res.data?.rows) {
          setSelectedStaff(res.data.rows.map((st) => ({ id: st.id })));
        }
      });
      getLocationList(1000, 1).then((res) => {
        if (res.data?.rows) {
          setLocations(res.data.rows.map((loc) => ({ id: loc.id })));
        }
      });
    }

    if (drawerType === 'addService' || drawerType === 'edit') {
      fetchServiceOptions().then(({ categories }) => {
        setcategories(categories);
      });

      // setting default timeslot (from settings)
      setServiceData({
        ...serviceData,
        slotLength: 'default',
      });

      // get duration options based on time slot length
      calculateDurationOptions();
    }

    if (drawerType === 'edit' && drawerData?.id) {
      fetchServiceData();
    }
  }, [user]);

  const handleAddExtra = () => {
    let extraArrayPromises = [];

    extraService.map(
      ({
        serviceName,
        image,
        maxQuantity,
        minQuantity,
        isEnabled,
        price,
        hidePrice,
        hideDuration,
        duration,
      }) => {
        const promise = addSubService({
          duration,
          hideDur: hideDuration,
          hidePrice,
          image,
          isEnabled,
          maxQty: maxQuantity,
          minQty: minQuantity,
          price,
          name: serviceName,
        });
        extraArrayPromises = [...extraArrayPromises, promise];
      }
    );

    return extraArrayPromises;
  };

  // adding service logic
  const handleAddService = async () => {
    // error/validation handling
    let err = {};
    let data = { ...serviceData };

    for (const key in data) {
      if (!data[key] && requiredFields.includes(key)) {
        err = { ...err, [key]: true };
      }
      if (
        Array.isArray(data[key]) &&
        data[key].length === 0 &&
        requiredFields.includes(key)
      ) {
        console.log(data[key]);
        err = { ...err, [key]: true };
      }
    }
    // timesheet validation

    setErrors({ ...err });

    // request logic

    if (Object.keys(err).length === 0) {
      // add extras
      // format "id-id-id..."
      const extraArrayPromises = handleAddExtra();
      const extraServiceIds = await Promise.all(extraArrayPromises).then(
        (resArray) => resArray.map((res) => res.data['Service Id'])
      );
      const extraId = extraServiceIds.join('-');
      console.log(extraId);

      // add timesheet

      const specificStaff = selectedStaff.map((staff) => {
        return {
          id: staff.id,
          isSpecificPrice: staff.isSpecificPrice,
          price: staff.price,
          priceType: staff.priceType,
          deposit: staff.deposit,
        };
      });

      // service body
      let formData = new FormData();
      for (const el in serviceData) {
        if (el === 'slotLength' && serviceData[el] === 'duration') {
          formData.append(el, serviceData['duration']);
        } else if (serviceData[el] === 'default') {
          formData.append(el, user?.settings?.GeneralSettings?.timeSlotLength);
        } else {
          formData.append(el, serviceData[el]);
        }
      }
      extraId && formData.append('subServiceId', extraId);
      formData.append('specificStaff', JSON.stringify(specificStaff));

      formData.append('timesheets', JSON.stringify(timesheets));

      selectedStaff.map((st) => {
        formData.append('staff', JSON.stringify(st));
      });
      locations.map((lc) => {
        formData.append('locations', JSON.stringify(lc));
      });
      formData.append('spts', spts);
      formData.append('image', image);
      formData.append('userId', user?.id);
      // formData.append('capacity', serviceData.capacity);
      // formData.append('BTA', serviceData.BTA);
      // formData.append('BTB', serviceData.BTB);
      // formData.append('slotLength', serviceData.slotLength);

      for (var pair of formData.entries()) {
        console.log(pair[0], pair[1]);
      }

      // calling api
      addService(formData).then((res) => {
        if (res.status === 200) {
          dispatch(fetchUser())
            .unwrap()
            .then((res) => {
              toggleDrawer();
            });

          fetchServices();
        }
      });
    }
  };

  //  add category
  const handleAddCategory = () => {
    addCategory({
      parentId:
        selectedParentCategory.id === 0 ? null : selectedParentCategory.id,
      name: categoryName,
    });
  };

  // editing service
  const handleEditService = async () => {
    // add extras
    // format "id-id-id..."

    // const extraArrayPromises = handleAddExtra();
    // const extraServiceIds = await Promise.all(extraArrayPromises).then(
    //   (resArray) => resArray.map((res) => res.data["Service Id"])
    // );
    // const extraId = extraServiceIds.join("-");
    // console.log(extraId);

    // staff members for the service
    const specificStaff = selectedStaff.map((staff) => {
      return {
        id: staff.id,
        isSpecificPrice: staff.isSpecificPrice,
        deposit: staff.deposit,
        priceType: staff.priceType,
      };
    });

    // service body
    let formData = new FormData();
    for (const el in serviceData) {

      if (el == 'spts') {
        formData.append('spts', spts);
        continue;
      }

      if (
        el !== 'timesheets' &&
        el !== 'locations' &&
        el !== 'staff' &&
        el !== 'image'
      ) {
        formData.append(el, serviceData[el]);
      }
    }

    // extra service
    // extraId && formData.append("subServiceId", extraId);

    image && formData.append('image', image);

    const newTimesheets = timesheets.map((timesheet) => {
      timesheet.breaks = timesheet.breaks.map((breakTime) => {
        if (breakTime?.id?.toString().includes('breaks')) delete breakTime.id;
        return breakTime;
      });
      return timesheet;
    });

    // staff and locations links
    selectedStaff.map((st) => {
      formData.append('staff', JSON.stringify(st));
    });
    locations.map((lc) => {
      formData.append('locations', JSON.stringify(lc));
    });

    // timesheet
    // formData.set("timesheetId", timesheetId);
    formData.append('timesheets', JSON.stringify(newTimesheets));

    // calling api
    editServiceById(formData, drawerData.id).then((res) => {
      if (res.status === 200) {
        toggleDrawer();
        fetchServices();
      }
    });
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={2}>
        <Drawer
          type={
            drawerType === 'add'
              ? 'ADD'
              : drawerType === 'addService'
              ? 'ADD'
              : 'EDIT'
          }
          title={
            drawerType === 'add'
              ? 'Add Category'
              : drawerType === 'addService'
              ? 'Add Service'
              : 'Edit Service'
          }
          toggleDrawer={toggleDrawer}
        >
          {/* -----------FORM----- */}
          <Grid sx={{ height: '100%', overflowY: 'auto', width: '100%' }}>
            {drawerType === 'add' && (
              <Grid sm={12} xs={12}>
                <Grid sx={{ px: 5, pt: 5 }}>
                  <Typography variant="subtitle2" sx={{ my: 1 }}>
                    Parent category<sapn style={{ color: 'red' }}>*</sapn>
                  </Typography>
                  <Autocomplete
                    size="small"
                    disablePortal
                    options={categoryOptions}
                    getOptionLabel={(option) => option.name}
                    value={selectedParentCategory}
                    onChange={(e, data) => {
                      setSelectedParentCategory(data);
                    }}
                    sx={{
                      marginBottom: '1rem',
                      width: '100%',
                      fontSize: '14px',
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Grid>
                <Grid sx={{ padding: 5, pt: 1 }}>
                  <Typography variant="subtitle2" sx={{ my: 1 }}>
                    Category name<sapn style={{ color: 'red' }}>*</sapn>
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    value={categoryName}
                    onChange={(e) => setCategoryName(e.target.value)}
                  />
                </Grid>
              </Grid>
            )}
            {(serviceData.name || drawerType !== 'edit') &&
              drawerType !== 'add' && (
                <ServiceTab
                  categories={categories}
                  serviceData={serviceData}
                  setServiceData={setServiceData}
                  // staff={staff}
                  // setStaff={setStaff}
                  // selectedStaff={selectedStaff}
                  // setSelectedStaff={setSelectedStaff}
                  image={image}
                  setImage={setImage}
                  timesheets={timesheets}
                  setTimesheets={setTimesheets}
                  spts={spts}
                  setSpts={setSpts}
                  extraService={extraService}
                  setExtraService={setExtraService}
                  durationOptions={durationOptions}
                  timeSlotOptions={timeSlotOptions}
                  errors={errors}
                  setErrors={setErrors}
                  locations={locations}
                  setLocations={setLocations}
                  staffs={selectedStaff}
                  setStaffs={setSelectedStaff}
                  drawerType={drawerType}
                />
              )}
          </Grid>
          {/* -----Button */}

          <DrawerFooter>
            <>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: isSmallScreen ? 'flex-start' : 'flex-end',
                }}
              ></div>

              <div
                style={{
                  display: 'flex',
                  gap: '15px',
                  justifyContent: isSmallScreen ? 'space-between' : 'flex-end',
                  width: isSmallScreen ? '100%' : 'auto',
                  alignItems: 'center',
                }}
              >
                <CustomTextButton
                  variant="contained"
                  size="large"
                  onClick={() => {
                    toggleDrawer();
                  }}
                >
                  Cancel
                </CustomTextButton>
                <CustomTextButton
                  type="blue"
                  variant="contained"
                  size="large"
                  onClick={() => {
                    if (drawerType === 'add') {
                      handleAddCategory();
                    } else if (drawerType === 'addService') {
                      handleAddService();
                    } else {
                      handleEditService();
                    }
                  }}
                >
                  {drawerType === 'add' ? 'Save' : 'Save'}
                </CustomTextButton>
              </div>
            </>
          </DrawerFooter>
        </Drawer>
      </Grid>
    </Grid>
  );
};

export default AddServices;
