import { Button, Grid, Switch, TextField, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useState } from 'react';
import SaveSlideBar from '../../SaveSlideBar';
import CustomTooltip from '../../CustomTooltip';

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 36,
  height: 20,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 1.5,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        // backgroundColor: theme.palette.mode === "dark" ? "#8468FA" : "#8468FA",
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 17,
    height: 17,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const ConnectInvite = ({ fetchCurrentSettings, currentSettings }) => {
  const [isSaveBar, setIsSaveBar] = useState(false);
  const [newCustomerData, setNewCustomerData] = useState({
    isSubCustomer: false,
    firstName: '',
    lastName: '',
    email: '',
  });

  const handleCustomerData = (key, selectedValue) => {
    setNewCustomerData((prev) => {
      return { ...prev, [key]: selectedValue };
    });
    setIsSaveBar(true);
  };

  const handleSave = () => {
    // const userFormData = new FormData();
    // for (const el in formData) {
    //   userFormData.append(el, formData[el]);
    // }
    // userFormData.append("image", image);
    // userFormData.append("displayCompanyLogo", displayCompanyLogo);
    // editSettings(userFormData).then((res) => {
    //   if (res.status === 200) {
    //     fetchCurrentSettings();
    //     setIsSaveBar(false);
    //   }
    // });
  };

  const handleCancel = () => {
    // setFormData(currentSettings.CompanyDetails);
    // setDisplayCompanyLogo(currentSettings.CompanyDetails.displayCompanyLogo);
    // setImage(currentSettings.CompanyDetails.companyImage || null);
    // setIsSaveBar(false);
  };

  return (
    <>
      <Grid
        xs={12}
        sx={{
          marginTop: { xs: 9, sm: 14 },
          backgroundColor: 'white',
          pb: 1,
          mb: 5,
        }}
      >
        <Grid
          sx={{
            padding: { xs: '15px', md: '15px 25px' },
            borderBottom: '1px solid #e3eaf3',
          }}
        >
          <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>
            Connect
            <span style={{ color: '#c1c1c1' }}>
              <CircleIcon sx={{ fontSize: '7px', marginX: '3px' }} /> Invite
            </span>
          </Typography>
        </Grid>
        <Grid
          container
          rowGap="30px"
          sx={{ padding: { xs: '15px', md: '15px 25px' } }}
        >
          <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                Switch on if you want to invite a sub customer.
                <CustomTooltip
                  arrow
                  title="Sub customer can be a family member or company filial where you can view his appointments and invoices"
                >
                  <HelpOutlineIcon
                    sx={{ width: 20, height: 20, pl: 1, position: 'absolute' }}
                  />
                </CustomTooltip>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  border: '1px solid rgba(0, 0, 0, 0.23)',
                  borderRadius: '4px',
                  paddingX: 2,
                  paddingY: 1,
                  height: '40px',
                }}
                onClick={() => {
                  handleCustomerData(
                    'isSubCustomer',
                    !newCustomerData.isSubCustomer
                  );
                }}
              >
                <Typography variant="subtitle2">Invite sub customer</Typography>
                <IOSSwitch
                  size="small"
                  sx={{ marginLeft: 2 }}
                  checked={newCustomerData.isSubCustomer}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}></Grid>
          <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                First Name
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                size="small"
                required
                variant="outlined"
                sx={{ width: '100%', fontSize: '14px' }}
                onChange={(e) => {
                  handleCustomerData('firstName', e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                Last Name
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                size="small"
                required
                variant="outlined"
                sx={{ width: '100%', fontSize: '14px' }}
                onChange={(e) => {
                  handleCustomerData('lastName', e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                Email
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-basic"
                size="small"
                required
                variant="outlined"
                sx={{ marginBottom: '2rem', width: '100%', fontSize: '14px' }}
                onChange={(e) => {
                  handleCustomerData('email', e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          sx={{
            margin: '0 0 5px 25px',
            padding: '10px 40px',
          }}
        >
          Invite
        </Button>
        <Typography
          sx={{ margin: '0 0 0px 25px', padding: '10px 0', fontSize: '14px' }}
        >
          Great job, invitation have been sent to user email.
        </Typography>
      </Grid>
      <SaveSlideBar
        handleCancel={handleCancel}
        handleSave={handleSave}
        isSaveBar={isSaveBar}
        setIsSaveBar={setIsSaveBar}
      />
    </>
  );
};

export default ConnectInvite;
