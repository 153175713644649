import { Grid, MenuItem, Select, Typography } from "@mui/material";
import React from "react";

const Extras = () => {
  const isExtra = true;
  return (
    <>
      {isExtra ? (
        <Grid
          sx={{
            boxShadow: " rgba(149, 157, 165, 0.2) 0px 8px 24px",
            padding: "2rem",
          }}
        >
          <Select fullWidth defaultValue={"select"} size="small">
            <MenuItem value="select">Select</MenuItem>
            <MenuItem value="coupon50%">COUPON50%</MenuItem>
            <MenuItem value="coupon20$">COUPON20$</MenuItem>
          </Select>
        </Grid>
      ) : (
        <Grid sx={{ display: "flex", justifyContent: "center" }} container>
          <Typography variant="subtitle2" color="#828f9a">
            No extras found
          </Typography>
        </Grid>
      )}
    </>
  );
};

export default Extras;
