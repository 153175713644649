import React from "react";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CustomTypography from "../../components/CustomTypography/index";
import countries from "../../assets/lists/countries.json";
import timezones from "../../assets/lists/timezones.json";
import { Typography } from "@mui/material";

function Page1({ data, setData }) {

  const setLanguage = (event) => {
    setData({
      ...data,
      language: event.target.value,
    });
  };
  const setCountry = (country) => {
    setData({
      ...data,
      country: country,
    });
  };
  const setTimezone = (timezone) => {
    setData({
      ...data,
      timezone: timezone,
    });
  };

  return (
    <Grid container sx={{ width: "100%" }}>
      <Grid item mt={1}>
        <Typography sx={{ color: "#4473cf ", fontSize: "14px", mb: "5px" }}>
          Select Language
        </Typography>
        <FormControl>
          <RadioGroup row value={data.language} onChange={setLanguage}>
            <FormControlLabel
              value="English"
              control={
                <Radio
                  sx={{
                    "& .MuiSvgIcon-root": { fontSize: 14 },
                  }}
                />
              }
              label="English"
            />
            <FormControlLabel
              value="Español"
              control={
                <Radio sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }} />
              }
              label="Español"
            />
            <FormControlLabel
              value="Dutch"
              control={
                <Radio sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }} />
              }
              label="Dutch"
            />
            <FormControlLabel
              value="Svenska  "
              control={
                <Radio sx={{ "& .MuiSvgIcon-root": { fontSize: 14 } }} />
              }
              label="Svenska  "
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item mt={1} md={12} sm={12} xs={12}>
        <CustomTypography
          sx={{ color: "#4473cf ", fontSize: "14px", mb: "5px" }}
        >
          Select Country
        </CustomTypography>
        <Autocomplete
          fullWidth
          onChange={(event, value) => setCountry(value.Code)}
          options={countries}
          defaultValue={countries.find(country => country.Code === "SE")}
          sx={{
            width: "100%",
            "& fieldset": { borderRadius: 1.5 },
          }}
          size="small"
          getOptionLabel={(option) => option.Name}
          renderOption={(props, option) => (
            <Box component="li" sx={{ "& > img": { mr: 2 } }} {...props}>
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.Code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.Code.toLowerCase()}.png 2x`}
                alt={option.Name}
              />
              {option.Name}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              style={{ marginBottom: "2.8%" }}
              {...params}
              fullWidth
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-country", // disable autocomplete and autofill
              }}
            />
          )}
        />
      </Grid>
      <Grid item mt={1} md={12} sm={12} xs={12}>
        <CustomTypography
          sx={{ color: "#4473cf ", fontSize: "14px", mb: "5px" }}
        >
          Select Timezone
        </CustomTypography>
        <Autocomplete
          fullWidth
          onChange={(event, value) => setTimezone(value.text)}
          defaultValue={timezones.find(timezone => timezone.value === "Central European Standard Time")}
          options={timezones}
          sx={{
            width: "100%",
            "& fieldset": { borderRadius: 1.5 },
          }}
          size="small"
          getOptionLabel={(option) => option.text}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-country", // disable autocomplete and autofill
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}

export default Page1;
