import React, { useEffect, useState } from 'react';
import Drawer from '../../components/Drawer';
import DrawerFooter from '../../components/DrawerFooter';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Checkbox, useMediaQuery, useTheme } from '@mui/material';
import ServiceTab from './Tabs';
import {
  addAppointment,
  editAppointmentById,
} from '../../Api/tenant/appointments';
import { useLayoutEffect } from 'react';
import { getCustomerList } from '../../Api/tenant/customers';
import { getCategoryList } from '../../Api/tenant/categories';
import { getBookingData } from '../../Api/common/general';
import CustomTextButton from '../../components/CustomButtons/CustomTextButton';
import { useSelector } from 'react-redux';
import { selectUser } from '../../redux/slices/user.slice.js';
import DisableSectionWrapper from '../../components/DisableSectionWrapper';
import useCategory from '../../hooks/useCategory';

const requiredFields = [
  'location',
  'category',
  'staff',
  'time',
  'service',
  'customer',
  'status',
];

const AddAppointment = ({
  toggleAddAppointmentDrawer,
  drawerType,
  drawerData = null,
  handelHide,
  fetchAppointments,
}) => {
  const { user, isUserLoading } = useSelector(selectUser);
  const { categoriesList } = useCategory();
  const [formData, setFormData] = useState({
    location: null,
    category: null,
    service: null,
    staff: null,
    time: null,
    customer: null,
    status: user?.settings?.GeneralSettings?.das,
    date: null,
    note: null,
    subService: null,
  });
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('sm'));
  const buttonProps = {
    variant: 'contained',
    size: 'large',
  };
  const [appointmentOptions, setAppointmentOptions] = useState({
    locations: [],
    categories: [],
    services: [],
    staff: [],
    customers: [],
    subServices: [],
    status: '',
  });

  const [coupons, setCoupons] = useState(null);

  // error handling
  const [errors, setErrors] = useState({});

  const handelHidedata = () => {
    handelHide(drawerData.id);
    toggleAddAppointmentDrawer();
  };

  // API

  useLayoutEffect(() => {
    if (drawerType === 'edit') {
      if (drawerData.category) {
        const appointmentCategory = categoriesList.find(
          (category) => category.name === drawerData.category.name
        );
        setFormData({ ...drawerData, category: appointmentCategory });
      } else {
        setFormData({ ...drawerData });
      }
      setCoupons(drawerData.coupons);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriesList]);

  // fetching conditional data
  const fetchCustomers = async () => {
    const res = await getCustomerList(100, 1);
    return res;
  };

  const fetchRequiredData = async (q) => {
    const res = await getBookingData(q);
    const customers = await fetchCustomers();
    const cates = await getCategoryList(100, 1);

    return {
      locations: res.data.Locations || [],
      services: res.data.Services || [],
      staff: res.data.Staff || [],
      subServices: res.data.subServices || [],
      times:
        !res.data.Locations &&
        !res.data.Services &&
        !res.data.Staff &&
        !res.data.subServices
          ? Array.isArray(res.data) &&
            Array.from(
              { length: res.data.length },
              (_, i) =>
                res.data[i].available &&
                res.data[i].from + ' - ' + res.data[i].to
            )
          : [],

      categories: cates.data,
      customers: customers.data,
    };
  };

  useEffect(() => {
    const queries = getQueries();
    fetchRequiredData(queries).then((data) => {
      setAppointmentOptions(data);
    });
  }, [
    formData.location,
    formData.category,
    formData.service,
    formData.staff,
    formData.subService,
    formData.date,
    formData.note,
    formData.status,
  ]);

  const getQueries = () => {
    let queryText = null;

    for (const key in formData) {
      if (formData[key] && requiredFields.includes(key) && key !== 'status') {
        queryText = `${queryText === null ? '' : queryText + '&'}${key}Id=${
          formData[key].id
        }`;
      }
      if (!formData[key] && key === 'subService') {
        queryText = `${queryText === null ? '' : queryText + '&'}${key}Id=-1`;
      }
      if (formData[key] && key === 'date') {
        queryText = `${
          queryText === null ? '' : queryText + '&'
        }${key}=${new Date(formData[key])
          .toJSON()
          .slice(0, 10)
          .split('-')
          .join('-')}`;
      }
    }
    return queryText;
  };

  // Handlers

  const handleAddAppointment = () => {
    // validation handling
    let err = {};

    for (const key in formData) {
      if (!formData[key] && requiredFields.includes(key)) {
        err = { ...err, [key]: true };
      }
      //  TODO handling for extra serrvice

      //  if (
      //    Array.isArray(data[key]) &&
      //    data[key].length === 0 &&
      //    requiredFields.includes(key)
      //  ) {
      //    console.log(data[key]);
      //    err = { ...err, [key]: true };
      //  }
    }
    setErrors({ ...err });

    if (Object.keys(err).length === 0) {
      const {
        location,
        category,
        service,
        staff,
        time,
        customer,
        status,
        date,
        note,
      } = formData;

      addAppointment({
        locationId: location?.id,
        categoryId: category?.id,
        serviceId: service?.id,
        staffId: staff.id,
        time,
        date,
        customerId: customer.id,
        status,
        attendees: 1,
        note,
        //couponId: coupons.id,
      }).then((res) => {
        if (res.status === 200) {
          fetchAppointments();
          toggleAddAppointmentDrawer();
        }
      });
    }
  };

  const handleEditAppointment = () => {
    const {
      location,
      category,
      service,
      staff,
      time,
      customer,
      status,
      date,
      note,
    } = formData;

    editAppointmentById(
      {
        locationId: location?.id,
        categoryId: category?.id,
        serviceId: service?.id,
        staffId: staff.id,
        time,
        date,
        customerId: customer.id,
        status,
        attendees: 1,
        note: note || '',
        couponId: coupons?.id,
      },
      drawerData.id
    ).then((res) => {
      if (res.status === 200) {
        fetchAppointments();
        toggleAddAppointmentDrawer();
      }
    });
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={2}>
        <Drawer
          type={drawerType === 'addAppointment' ? 'ADD' : 'EDIT'}
          title={
            drawerType === 'addAppointment'
              ? 'New Appointment'
              : 'Edit Appointment'
          }
          toggleDrawer={toggleAddAppointmentDrawer}
        >
          {/* -----------FORM----- */}
          <Grid
            sx={{
              height: '100%',
              overflowY: 'auto',
              width: '100%',
            }}
          >
            {drawerType !== 'add' && (
              <ServiceTab
                drawerType={drawerType}
                formData={formData}
                setFormData={setFormData}
                appointmentOptions={appointmentOptions}
                fetchCustomers={fetchRequiredData}
                coupons={coupons}
                setCoupons={setCoupons}
                errors={errors}
                setErrors={setErrors}
              />
            )}
          </Grid>
          {/* -----Button */}
          <DrawerFooter>
            <>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: isSmallScreen ? 'flex-start' : 'normal',
                }}
              >
                <DisableSectionWrapper>
                  <Checkbox
                    size="small"
                    checked={true}
                    color="success"
                    sx={{
                      marginBottom: { xs: 1, sm: 0 },
                    }}
                  />
                  <Typography
                    variant="subtitle2"
                    sx={{
                      marginLeft: { sm: 0, xs: -1 },
                      marginBottom: { xs: 1, sm: 0 },
                    }}
                  >
                    Run workflows on save
                  </Typography>
                </DisableSectionWrapper>
              </div>

              <div
                style={{
                  display: 'flex',
                  gap: '15px',
                  justifyContent: isSmallScreen ? 'space-between' : 'flex-end',
                  width: isSmallScreen ? '100%' : 'auto',
                  alignItems: 'center',
                }}
              >
                <CustomTextButton
                  variant="contained"
                  size="large"
                  onClick={toggleAddAppointmentDrawer}
                >
                  Cancel
                </CustomTextButton>
                <CustomTextButton
                  type="blue"
                  variant="contained"
                  size="large"
                  onClick={() => {
                    drawerType === 'addAppointment'
                      ? handleAddAppointment()
                      : handleEditAppointment();
                  }}
                >
                  {drawerType === 'add' ? 'Save' : 'Save'}
                </CustomTextButton>
              </div>
            </>
          </DrawerFooter>
        </Drawer>
      </Grid>
    </Grid>
  );
};

export default AddAppointment;
