export const data = [
  { value: "{appointment_id}", name: "{appointment_id}" },
  { value: "{appointment_date}", name: "{appointment_date}" },
  { value: "{appointment_date_time}", name: "{appointment_date_time}" },
  { value: "{appointment_start_time}", name: "{appointment_start_time}" },
  { value: "{appointment_end_time}", name: "{appointment_end_time}" },
  { value: "{appointment_duration}", name: "{appointment_duration}" },
  { value: "{appointment_buffer_before}", name: "{appointment_buffer_before}" },
  { value: "{appointment_buffer_after}", name: "{appointment_buffer_after}" },
  { value: "{appointment_status}", name: "{appointment_status}" },
  { value: "{appointment_service_price}", name: "{appointment_service_price}" },
  { value: "{appointment_extras_price}", name: "{appointment_extras_price}" },
  { value: "{appointment_extras_list}", name: "{appointment_extras_list}" },
  {
    value: "{appointment_discount_price}",
    name: "{appointment_discount_price}",
  },
  { value: "{appointment_sum_price}", name: "{appointment_sum_price}" },
  { value: "{appointment_paid_price}", name: "{appointment_paid_price}" },
  {
    value: "{appointment_payment_method}",
    name: "{appointment_payment_method}",
  },
  { value: "{appointment_tax_amount}", name: "{appointment_tax_amount}" },
  {
    value: "{appointment_custom_field_ID}",
    name: "{appointment_custom_field_ID}",
  },
  { value: "{appointment_created_date}", name: "{appointment_created_date}" },
  { value: "{appointment_created_time}", name: "{appointment_created_time}" },
  { value: "{service_name}", name: "{service_name}" },
  { value: "{service_price}", name: "{service_price}" },
  { value: "{service_duration}", name: "{service_duration}" },
  { value: "{service_notes}", name: "{service_notes}" },
  { value: "{service_color}", name: "{service_color}" },
  { value: "{service_image_url}", name: "{service_image_url}" },
  { value: "{service_category_name}", name: "{service_category_name}" },
  { value: "{customer_full_name}", name: "{customer_full_name}" },
  { value: "{customer_first_name}", name: "{customer_first_name}" },
  { value: "{customer_last_name}", name: "{customer_last_name}" },
  { value: "{customer_phone}", name: "{customer_phone}" },
  { value: "{customer_email}", name: "{customer_email}" },
  { value: "{customer_birthday}", name: "{customer_birthday}" },
  { value: "{customer_notes}", name: "{customer_notes}" },
  {
    value: "{customer_profile_image_url}",
    name: "{customer_profile_image_url}",
  },
  { value: "{customer_panel_url}", name: "{customer_panel_url}" },
  { value: "{customer_panel_password}", name: "{customer_panel_password}" },
  { value: "{staff_name}", name: "{staff_name}" },
  { value: "{staff_email}", name: "{staff_email}" },
  { value: "{staff_phone}", name: "{staff_phone}" },
  { value: "{staff_about}", name: "{staff_about}" },
  { value: "{staff_profile_image_url}", name: "{staff_profile_image_url}" },
  { value: "{location_name}", name: "{location_name}" },
  { value: "{location_address}", name: "{location_address}" },
  { value: "{location_image_url}", name: "{location_image_url}" },
  { value: "{location_phone_number}", name: "{location_phone_number}" },
  { value: "{location_notes}", name: "{location_notes}" },
  { value: "{company_name}", name: "{company_name}" },
  { value: "{company_image_url}", name: "{company_image_url}" },
  { value: "{company_website}", name: "{company_website}" },
  { value: "{company_phone}", name: "{company_phone}" },
  { value: "{company_address}", name: "{company_address}" },
];
