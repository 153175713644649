export   const fixedDurationOptions = [
    {
      label: '1d',
      duration: 1440,
    },
    {
      label: '2d',
      duration: 2880,
    },
    {
      label: '3d',
      duration: 4320,
    },
    {
      label: '4d',
      duration: 5760,
    },
    {
      label: '5d',
      duration: 7200,
    },
    {
      label: '6d',
      duration: 8640,
    },
    {
      label: '1w',
      duration: 10080,
    },
    {
      label: '8d',
      duration: 11520,
    },
    {
      label: '9d',
      duration: 12960,
    },
    {
      label: '10d',
      duration: 14400,
    },
    {
      label: '11d',
      duration: 15840,
    },
    {
      label: '12d',
      duration: 17280,
    },
    {
      label: '13d',
      duration: 18720,
    },
    {
      label: '2w',
      duration: 20160,
    },
    {
      label: '15d',
      duration: 21600,
    },
    {
      label: '16d',
      duration: 23040,
    },
    {
      label: '17d',
      duration: 24480,
    },
    {
      label: '18d',
      duration: 25920,
    },
    {
      label: '19d',
      duration: 27360,
    },
    {
      label: '20d',
      duration: 28800,
    },
    {
      label: '3w',
      duration: 30240,
    },
    {
      label: '22d',
      duration: 31680,
    },
    {
      label: '23d',
      duration: 33120,
    },
    {
      label: '24d',
      duration: 34560,
    },
    {
      label: '25d',
      duration: 36000,
    },
    {
      label: '26d',
      duration: 37440,
    },
    {
      label: '27d',
      duration: 38880,
    },
    {
      label: '4w',
      duration: 40320,
    },
    {
      label: '29d',
      duration: 41760,
    },
    {
      label: '30d',
      duration: 43200,
    },
    {
      label: '31d',
      duration: 44640,
    },
  ];