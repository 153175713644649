import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AvatarComp from '../../components/Avatar';
import DisableSectionWrapper from '../../components/DisableSectionWrapper';

function createData(date, text, status, name) {
  return { date, text, status, name };
}

const rows = [
  createData(
    '2022-12-22 11:45 AM',
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    'Completed',
    'Youssef Mohammed Sabry'
  ),
  createData(
    '2022-12-22 11:45 AM',
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    'Completed',
    'Youssef Mohammed Sabry'
  ),
  createData(
    '2022-12-22 11:45 AM',
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    'Completed',
    'Youssef Mohammed Sabry'
  ),
  createData(
    '2022-12-22 11:45 AM',
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    'Completed',
    'Youssef Mohammed Sabry'
  ),
  createData(
    '2022-12-22 11:45 AM',
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    'Completed',
    'Youssef Mohammed Sabry'
  ),
];

function BasicTable() {
  return (
    <TableContainer
      component={Paper}
      sx={{
        // overflowY: 'scroll',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        height: '100%',
      }}
    >
      <Table
        sx={{
          minWidth: 650,
          // overflowY: 'scroll',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          scrollbarWidth: 'none',
          height: '100%',
          msOverflowStyle: 'none',
        }}
        aria-label="simple table"
      >
        <TableBody
          sx={{
            // overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            height: '100%',
          }}
        >
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
              }}
            >
              <TableCell
                align="left"
                sx={{
                  color: '#3A3A50',
                  pl: '20px',
                }}
              >
                {row.date}
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  color: '#3A3A50',
                }}
              >
                <Box
                  sx={{
                    display: 'block',
                    width: '200px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {row.text}
                </Box>
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  color: '#3A3A50',
                }}
              >
                {row.status}
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  color: '#3A3A50',
                }}
              >
                {row.name}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const Overview = ({ customerData, loading }) => {

  // useEffect(() => {
  //   setCustomerData(state.cData);
  // }, [state.cData]);

  const [tasks, setTasks] = useState([
    {
      text: 'Task 1',
      isCompleted: false,
    },
    {
      text: 'Task 2',
      isCompleted: false,
    },
    {
      text: 'Task 3',
      isCompleted: false,
    },
    {
      text: 'Task 4',
      isCompleted: false,
    },
    {
      text: 'Task 5',
      isCompleted: false,
    },
    {
      text: 'Task 6',
      isCompleted: false,
    },
  ]);

  const completeTask = (index) => {
    const newTasks = [...tasks];
    newTasks[index].isCompleted = !newTasks[index].isCompleted;
    setTasks(newTasks);
  };
  const addTodo = (text) => {
    if (text) {
      const newTasks = [...tasks, { text, isCompleted: false }];
      setTasks(newTasks);
      setTaskValue('');
    }
  };
  const removeTodo = (index) => {
    const newTasks = [...tasks];
    newTasks.splice(index, 1);
    setTasks(newTasks);
  };

  const [taskValue, setTaskValue] = useState('');
  return (
    <>
      <Grid
        container
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          // alignItems: { xs: 'center', lg: 'flex-start' },
        }}
      >
        <Grid item xs={12} md={4}>
          {loading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '200px',
                height: '200px',
              }}
            >
              <CircularProgress color="inherit" size={50} />
            </Box>
          ) : customerData?.image === '' && !loading ? (
            <Box display="flex">
              <AvatarComp
                img={customerData?.image}
                username={`${customerData?.firstName} ${customerData?.lastName}`}
                sx={{
                  height: '200px',
                  width: '200px',
                  fontSize: '50px',
                }}
              />
            </Box>
          ) : (
            <img
              src={`https://t-backend.upnize.com/${customerData?.image}`}
              alt="customer"
              style={{
                borderRadius: '50%',
                height: '200px',
                width: '200px',
                objectFit: 'cover',
              }}
            />
          )}

          {/* {loading && <CircularProgress color="inherit" size={20} />}
          <img
            src={`https://t-backend.upnize.com/${customerData?.image}`}
            alt="customer"
            style={{
              borderRadius: '50%',
              height: '200px',
              width: '200px',
              objectFit: 'cover',
            }}
          /> */}

          {/* : (
            <Box display="flex">
              <AvatarComp
                img={customerData?.image}
                username={`${customerData?.firstName} ${customerData?.lastName}`}
                sx={{
                  height: '200px',
                  width: '200px',
                  fontSize: '50px',
                }}
              />
            </Box>
          ) */}
        </Grid>
        <Grid
          xs={12}
          lg={4}
          item
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <Typography
            sx={{
              fontWeight: 'bold',
              color: '#3A3A50',
            }}
          >
            Customer info
          </Typography>
          <Typography
            sx={{
              fontWeight: 'bold',
              color: '#3A3A50',
              fontSize: '14px',
            }}
          >
            {customerData?.firstName} {customerData?.lastName}
          </Typography>
          <Typography
            sx={{
              fontWeight: 'bold',
              color: '#1976d2',
              fontSize: '14px',
            }}
          >
            {customerData?.email}
          </Typography>
          <Typography
            sx={{
              fontWeight: 'bold',
              color: '#1976d2',
              fontSize: '14px',
            }}
          >
            {customerData?.phone}
          </Typography>
          <Typography
            sx={{
              fontWeight: 'bold',
              color: '#1976d2',
              fontSize: '14px',
            }}
          >
            {customerData?.city}, {customerData?.country}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          lg={4}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            // justifyContent: 'space-between',
          }}
        >
          <Box>
            <Typography
              sx={{
                fontWeight: 'bold',
                color: '#3A3A50',
              }}
            >
              Gender
            </Typography>
            <Typography
              sx={{
                color: '#3A3A50',
                fontSize: '14px',
              }}
            >
              {customerData?.gender}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: 'bold',
                color: '#3A3A50',
              }}
            >
              Birthday
            </Typography>
            <Typography
              sx={{
                color: '#3A3A50',
                fontSize: '14px',
              }}
            >
              {customerData?.dob}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <DisableSectionWrapper>
        <Grid
          container
          spacing={'20px'}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mt: '100px',
            // width: '100%',
          }}
        >
          <Grid
            item
            sm={12}
            lg={6}
            sx={{
              width: '100%',
              minHeight: '400px',

              // paddingTop: 1,
              // paddingRight: { xs: 0, md: 2 },
              // paddingBottom: 1,
              // height: '368px',
              // width: '50%',
              // boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 15px',
            }}
          >
            <Card
              sx={{
                // border: '1px solid #C7C7C7',
                borderRadius: '0px',
                // height: '368px',
                height: '100%',
                // overflowY: 'scroll',
                // '&::-webkit-scrollbar': {
                //   display: 'none',
                // },
                // scrollbarWidth: 'none',
                // msOverflowStyle: 'none',
                // boxShadow: 'none',
                boxShadow: 'rgba(58, 58, 80, 0.1) 0px 0px 15px',
              }}
            >
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '4px',
                  padding: '0',
                  // height: '368px',
                  height: '100%',
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    mb: '16px',
                    px: '20px',
                    pt: '16px',
                    color: '#3A3A50',
                  }}
                >
                  5 Latest appointmet
                </Typography>
                <BasicTable />
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            sm={12}
            lg={6}
            sx={{
              width: '100%',
              minHeight: '400px',

              // paddingTop: 1,
              // paddingRight: { xs: 0, md: 2 },
              // paddingBottom: 1,
              // height: '368px',
              // width: '50%',
            }}
          >
            <Card
              sx={{
                // border: '1px solid #C7C7C7',
                borderRadius: '0px',
                // height: '368px',
                // overflowY: 'scroll',
                // '&::-webkit-scrollbar': {
                //   display: 'none',
                // },
                // scrollbarWidth: 'none',
                // msOverflowStyle: 'none',
                // boxShadow: 'none',
                boxShadow: 'rgba(58, 58, 80, 0.1) 0px 0px 15px',
              }}
            >
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '4px',
                  padding: '0',
                  height: '368px',
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    mb: '16px',
                    px: '20px',
                    pt: '16px',
                    color: '#3A3A50',
                  }}
                >
                  Your task
                </Typography>
                <Grid
                  sx={{
                    display: 'flex',
                    alignItems: 'end',
                    gap: '24px',
                    mb: '16px',
                    px: '20px',
                  }}
                >
                  <TextField
                    value={taskValue}
                    onChange={(e) => setTaskValue(e.target.value)}
                    placeholder="Write your task here..."
                    variant="standard"
                    sx={{
                      width: '100%',
                    }}
                  />
                  <DoneOutlinedIcon
                    onClick={() => addTodo(taskValue)}
                    sx={{
                      backgroundColor: '#00E180',
                      borderRadius: '50%',
                      color: '#fff',
                    }}
                  />
                </Grid>
                <Grid
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '4px',
                    overflowY: 'scroll',
                    '&::-webkit-scrollbar': {
                      display: 'none',
                    },
                    scrollbarWidth: 'none',
                    msOverflowStyle: 'none',
                    pb: '4px',
                  }}
                >
                  {tasks.map((task, index) => (
                    <Box
                      sx={{
                        // width: '100%',
                        backgroundColor: '#F6FAFF',
                        py: '10px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        px: '20px',
                        mx: '20px',
                      }}
                    >
                      <Box
                        sx={{
                          color: '#3A3A50',
                          fontSize: '14px',
                          width: '100%',
                          textDecoration: task.isCompleted
                            ? 'line-through'
                            : 'auto',
                        }}
                        onClick={() => completeTask(index)}
                      >
                        {task.text}
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '5px',
                        }}
                      >
                        <Checkbox
                          sx={{
                            padding: '0',
                          }}
                          checked={task.isCompleted}
                          onChange={() => completeTask(index)}
                        />
                        <DeleteIcon
                          onClick={() => {
                            removeTodo(index);
                          }}
                          sx={{
                            color: 'red',
                          }}
                        />
                      </Box>
                    </Box>
                  ))}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </DisableSectionWrapper>

    </>
  );
};

export default Overview;
