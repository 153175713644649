import { useState, useCallback, useEffect, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { getServiceList } from '../../../Api/tenant/services';
import CustomNoOption from '../../CustomNoOption';
import { useDispatch } from 'react-redux';
import { drawersActions } from '../../../redux/slices/drawers.slice';
import { drawerTypes } from '../../../constants/drawerTypes';
import { crudTypes } from '../../../constants/crudTypes';
import useInitialGuide from '../../../hooks/useInitialGuide';
import { initialGuideAttributes } from '../../../constants/initialGuideAttributes';

export default function SelectService({
  value = null, // will be either a single id value or an array of ids depending on the multiple props if it is true or false
  onChange, // the function that will have the event and values
  sx,
  multiple,
  helperText,
  error,
  addNewEntity = false,
}) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading =
    (open && options?.length === 0) ||
    (!((multiple && value?.length === 0) || (multiple && !value)) &&
      options?.length === 0);
  const { initialGuideData, fetchInitialGuide } = useInitialGuide();

  const handleOpen = useCallback(async () => {
    if (!loading) return;
    try {
      const { data } = await getServiceList(100, 1);
      setOptions(data.rows || []);
    } catch (err) {
      console.log(err);
    }
  }, [loading]);

  useEffect(() => {
    handleOpen();
  }, [handleOpen]);

  const selectedValueObj = useMemo(() => {
    if (!multiple)
      return options?.find((option) => option.id === value) || null;

    return options?.filter((option) => value?.find((v) => v === option.id));
  }, [multiple, options, value]);

  const handleDrawerOpen = () => {
    dispatch(
      drawersActions.openDrawer({
        type: drawerTypes.SERVICE,
        title: 'Add Service',
        crudType: crudTypes.ADD,
        submitTitle: crudTypes.ADD,
        refetchData: () => {
          setOptions([]);
          if (!initialGuideData[initialGuideAttributes?.createdService])
            fetchInitialGuide();
        },
      })
    );
  };

  return (
    <Autocomplete
      sx={{ width: '100%', ...sx }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      multiple={multiple}
      getOptionLabel={(option) => option?.name}
      options={options}
      loading={loading}
      value={selectedValueObj}
      onChange={(e, v) =>
        onChange(e, multiple ? v?.map((service) => service?.id) : v?.id)
      }
      size="small"
      noOptionsText={
        addNewEntity ? (
          <CustomNoOption onClick={handleDrawerOpen}>
            Add Service
          </CustomNoOption>
        ) : (
          'No options'
        )
      }
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select service"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          helperText={helperText}
          error={error}
        />
      )}
    />
  );
}
