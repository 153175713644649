export const priorTimeConstant = [
  {
    label: '0h 0m',
    duration: 0,
  },
  {
    label: '0h 30m',
    duration: 30,
  },
  {
    label: '1h 0m',
    duration: 60,
  },
  {
    label: '1h 30m',
    duration: 90,
  },
  {
    label: '2h 0m',
    duration: 120,
  },
  {
    label: '2h 30m',
    duration: 150,
  },
  {
    label: '3h 0m',
    duration: 180,
  },
  {
    label: '3h 30m',
    duration: 210,
  },
  {
    label: '4h 0m',
    duration: 240,
  },
  {
    label: '4h 30m',
    duration: 270,
  },
  {
    label: '5h 0m',
    duration: 300,
  },
  {
    label: '5h 30m',
    duration: 330,
  },
  {
    label: '6h 0m',
    duration: 360,
  },
  {
    label: '6h 30m',
    duration: 390,
  },
  {
    label: '7h 0m',
    duration: 420,
  },
  {
    label: '7h 30m',
    duration: 450,
  },
  {
    label: '8h 0m',
    duration: 480,
  },
  {
    label: '8h 30m',
    duration: 510,
  },
  {
    label: '9h 0m',
    duration: 540,
  },
  {
    label: '9h 30m',
    duration: 570,
  },
  {
    label: '10h 0m',
    duration: 600,
  },
  {
    label: '10h 30m',
    duration: 630,
  },
  {
    label: '11h 0m',
    duration: 660,
  },
  {
    label: '11h 30m',
    duration: 690,
  },
  {
    label: '12h 0m',
    duration: 720,
  },
  {
    label: '12h 30m',
    duration: 750,
  },
  {
    label: '13h 0m',
    duration: 780,
  },
  {
    label: '13h 30m',
    duration: 810,
  },
  {
    label: '14h 0m',
    duration: 840,
  },
  {
    label: '14h 30m',
    duration: 870,
  },
  {
    label: '15h 0m',
    duration: 900,
  },
  {
    label: '15h 30m',
    duration: 930,
  },
  {
    label: '16h 0m',
    duration: 960,
  },
  {
    label: '16h 30m',
    duration: 990,
  },
  {
    label: '17h 0m',
    duration: 1020,
  },
  {
    label: '17h 30m',
    duration: 1050,
  },
  {
    label: '18h 0m',
    duration: 1080,
  },
  {
    label: '18h 30m',
    duration: 1110,
  },
  {
    label: '19h 0m',
    duration: 1140,
  },
  {
    label: '19h 30m',
    duration: 1170,
  },
  {
    label: '20h 0m',
    duration: 1200,
  },
  {
    label: '20h 30m',
    duration: 1230,
  },
  {
    label: '21h 0m',
    duration: 1260,
  },
  {
    label: '21h 30m',
    duration: 1290,
  },
  {
    label: '22h 0m',
    duration: 1320,
  },
  {
    label: '22h 30m',
    duration: 1350,
  },
  {
    label: '23h 0m',
    duration: 1380,
  },
  {
    label: '23h 30m',
    duration: 1410,
  },
  {
    label: '1d',
    duration: 1440,
  },
  {
    label: '2d',
    duration: 2880,
  },
  {
    label: '3d',
    duration: 4320,
  },
  {
    label: '4d',
    duration: 5760,
  },
  {
    label: '5d',
    duration: 7200,
  },
  {
    label: '6d',
    duration: 8640,
  },
  {
    label: '1w',
    duration: 10080,
  },
  {
    label: '8d',
    duration: 11520,
  },
  {
    label: '9d',
    duration: 12960,
  },
  {
    label: '10d',
    duration: 14400,
  },
  {
    label: '11d',
    duration: 15840,
  },
  {
    label: '12d',
    duration: 17280,
  },
  {
    label: '13d',
    duration: 18720,
  },
  {
    label: '2w',
    duration: 20160,
  },
  {
    label: '15d',
    duration: 21600,
  },
  {
    label: '16d',
    duration: 23040,
  },
  {
    label: '17d',
    duration: 24480,
  },
  {
    label: '18d',
    duration: 25920,
  },
  {
    label: '19d',
    duration: 27360,
  },
  {
    label: '20d',
    duration: 28800,
  },
  {
    label: '3w',
    duration: 30240,
  },
  {
    label: '22d',
    duration: 31680,
  },
  {
    label: '23d',
    duration: 33120,
  },
  {
    label: '24d',
    duration: 34560,
  },
  {
    label: '25d',
    duration: 36000,
  },
  {
    label: '26d',
    duration: 37440,
  },
  {
    label: '27d',
    duration: 38880,
  },
  {
    label: '4w',
    duration: 40320,
  },
  {
    label: '29d',
    duration: 41760,
  },
  {
    label: '30d',
    duration: 43200,
  },
  {
    label: '31d',
    duration: 44640,
  },
];
