import {
  Box,
  Button,
  Grid,
  Typography,
  Modal as MUIModal,
  FormHelperText,
  TextField,
  Autocomplete,
} from '@mui/material';
import { useEffect, useState } from 'react';
import CustomTextField from '../CustomTextField';
import CustomPhoneInput from '../CustomPhoneInput';
import countrydata from '../../pages/Locations/Tabs/Countrydata.json';
import ProfileImageField from '../ProfileImageField';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser, selectUser } from '../../redux/slices/user.slice.js';
import { editUserById } from '../../Api/common/users';

const requiredFields = ['firstName', 'lastName', 'email'];

const WelcomeModal = ({}) => {
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    country: '',
    city: '',
    gender: '',
    dob: null,
    image: null,
  });
  const [states, setStates] = useState([]);
  const { user, isUserLoading } = useSelector(selectUser);
  const dispatch = useDispatch();

  useEffect(() => {
    const getStates = countrydata.find(
      (country) => country.sortname === formData.country
    )?.states;
    setStates(getStates || []);
  }, [formData.country]);

  const setImage = (image) => {
    setFormData((prev) => {
      return { ...formData, image };
    });
  };

  const handleFormData = (fieldName, value) => {
    if (fieldName === 'country') {
      const getStates = countrydata.find(
        (country) => country.country_name === value
      ).states;
      setStates(getStates);
    }
    setFormData({ ...formData, [fieldName]: value });
  };

  // click handlers
  const handleSave = () => {
    // error/validation handling
    let err = {};
    let data = { ...formData };

    for (const key in data) {
      if (!data[key] && requiredFields.includes(key)) {
        err = { ...err, [key]: true };
      }
      if (
        Array.isArray(data[key]) &&
        data[key].length === 0 &&
        requiredFields.includes(key)
      ) {
        console.log(data[key]);
        err = { ...err, [key]: true };
      }
    }

    setErrors({ ...err });
    if (Object.keys(err).length === 0) {
      const formdata = new FormData();

      for (const key in formData) {
        formdata.append(key, formData[key]);
      }

      editUserById(formdata, user.id).then((res) => {
        if (res.status === 200) {
          dispatch(fetchUser())
            .unwrap()
            .then((res) => {
              // console.log('success');
            });
        }
      });
    }
  };

  return (
    <MUIModal open={true}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { md: 800, xs: '100%' },
          bgcolor: 'background.paper',
          boxShadow: 24,
          maxHeight: '90vh',
          overflow: 'auto',
        }}
      >
        {/* HEADER */}
        <Box
          borderBottom="1px solid #b7b7b7"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: '16px 20px',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h4" fontSize="20px" fontWeight="bold">
              Warmly welcome! We are glad you're joining us.
            </Typography>
          </Box>
        </Box>

        {/* body */}

        <Grid
          sx={{
            p: '0px 20px 10px 20px',
          }}
        >
          <Grid marginBottom="1.5rem" marginTop="1.5rem">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <Grid container>
                <Grid xs={12} sx={{ paddingRight: { sm: 2 }, marginTop: 1 }}>
                  <ProfileImageField
                    justifyContent={'start'}
                    image={formData.image}
                    setImage={setImage}
                  />
                </Grid>
              </Grid>

              <Grid container>
                <Grid xs={12} sm={6}>
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2" sx={{ mb: 1 }}>
                      First name <span style={{ color: 'red' }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      required
                      variant="outlined"
                      sx={{
                        marginBottom: '1rem',
                        width: '100%',
                        fontSize: '14px',
                      }}
                      onChange={(e) => {
                        handleFormData('firstName', e.target.value);
                        setErrors({ ...errors, firstName: false });
                      }}
                      value={formData.firstName}
                      helperText={errors.firstName ? 'Value is missing' : ' '}
                      error={errors.firstName}
                    />
                  </Grid>
                </Grid>
                <Grid xs={12} sm={6} sx={{ paddingLeft: { sm: 1 } }}>
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2" sx={{ mb: 1 }}>
                      Last name <span style={{ color: 'red' }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      required
                      variant="outlined"
                      sx={{
                        marginBottom: '1rem',
                        width: '100%',
                        fontSize: '14px',
                      }}
                      onChange={(e) => {
                        handleFormData('lastName', e.target.value);
                        setErrors({ ...errors, lastName: false });
                      }}
                      value={formData.lastName}
                      helperText={errors.lastName ? 'Value is missing' : ' '}
                      error={errors.lastName}
                    />
                  </Grid>
                </Grid>

                <Grid xs={12} sm={6}>
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">
                      Email <span style={{ color: 'red' }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-basic"
                      size="small"
                      required
                      variant="outlined"
                      sx={{
                        marginBottom: '1rem',
                        width: '100%',
                        fontSize: '14px',
                      }}
                      onChange={(e) => {
                        handleFormData('email', e.target.value);
                        setErrors({ ...errors, email: false });
                      }}
                      value={formData.email}
                      helperText={errors.email ? 'Value is missing' : ' '}
                      error={errors.email}
                    />
                  </Grid>
                </Grid>

                <Grid xs={12} sm={6} sx={{ paddingLeft: { sm: 1 } }}>
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">Phone number</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <CustomPhoneInput
                      sx={{
                        marginBottom: '1rem',
                      }}
                      style={{
                        borderColor: errors.phone ? '#d32f2f' : '',
                      }}
                      onChange={(e) => {
                        handleFormData('phone', e);
                        setErrors({ ...errors, phone: false });
                      }}
                      value={formData.phone}
                      helperText={errors.phone ? 'Value is missing' : ' '}
                      error={errors.phone}
                    />
                    <FormHelperText error>
                      {errors.phone ? 'Value is missing' : ' '}
                    </FormHelperText>
                  </Grid>
                </Grid>

                <Grid xs={12} sm={6}>
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">Country</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      disablePortal
                      options={countrydata.map(
                        (getcountry, index) => getcountry.country_name
                      )}
                      value={formData.country}
                      onSelect={(e) => {
                        handleFormData('country', e.target.value.toString());
                        setErrors({ ...errors, country: false });
                      }}
                      sx={{ marginBottom: '1rem', fontSize: '14px' }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          placeholder="Choose Country..."
                          helperText={errors.country ? 'Value is missing' : ' '}
                          error={errors.country}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid xs={12} sm={6} sx={{ paddingLeft: { sm: 1 } }}>
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">City</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      disablePortal
                      options={states.map(
                        (getstate, index) => getstate.state_name
                      )}
                      value={formData.city}
                      onSelect={(e) => {
                        handleFormData('city', e.target.value.toString());
                        setErrors({ ...errors, city: false });
                      }}
                      sx={{ marginBottom: '1rem', fontSize: '14px' }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          placeholder="Choose City..."
                          helperText={errors.city ? 'Value is missing' : ' '}
                          error={errors.city}
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                <Grid xs={12} sm={6}>
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">Gender</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-basic"
                      size="small"
                      required
                      variant="outlined"
                      sx={{
                        marginBottom: '1rem',
                        width: '100%',
                        fontSize: '14px',
                      }}
                      onChange={(e) => {
                        handleFormData('gender', e.target.value);
                        setErrors({ ...errors, gender: false });
                      }}
                      value={formData.gender}
                      helperText={errors.gender ? 'Value is missing' : ' '}
                      error={errors.gender}
                    />
                  </Grid>
                </Grid>
                <Grid xs={12} sm={6} sx={{ paddingLeft: { sm: 1 } }}>
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">Birthday</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        value={formData.dob}
                        onChange={(e) =>
                          handleFormData('dob', moment(e).format('YYYY-MM-DD'))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            sx={{
                              marginBottom: '2rem',
                              width: '100%',
                              fontSize: '14px',
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid container sx={{ justifyContent: 'end' }}>
            <Button
              sx={{
                width: { xs: '100%', sm: 130 },
                marginBottom: 1,
                marginLeft: { xs: 0, sm: 2 },
                height: '45px',
              }}
              variant="contained"
              onClick={() => {
                handleSave();
              }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </MUIModal>
  );
};

export default WelcomeModal;
