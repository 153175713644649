import {
  Autocomplete,
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import Modal from "../../../components/Modal"

/*
  special Days Obj
  {
      id: uniqueId("special-day-"),
      date: moment().format("DD-MM-YYYY"),
      from: "HH:MM",
      to: "HH:MM",
      breaks: [],
  }

  breaks obj
  {
    id: uniqueId("breaks-"),
    "from": "HH:MM",
    "to": "HH:MM"
  }
 */

import DeleteIcon from '@mui/icons-material/Delete';
import { uniqueId } from 'lodash';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';



const SpecialDays = ({ specialDays, setSpecialDays }) => {
  const [openModal, setOpenModal] = useState({ id: null, open: false, type: null });

  const createNewSpecialDay = () => {
    const newSpecialDay = {
      id: uniqueId('special-day-'),
      date: dayjs().add(1, 'day').format('YYYY-MM-DD'),
      from: "12:00",
      to: "13:00",
      breaks: [],
    }

    setSpecialDays((prev) => [
      ...prev,
      newSpecialDay
    ]);
  };

  const addBreakToSpecialDay = (specialDayId) => {
    const getSpecialDay = specialDays.find(specialDay => specialDay.id === specialDayId)
    const newBreak = {
      id: uniqueId('breaks-'),
      from: getSpecialDay.from,
      to: getSpecialDay.to,
    };
    setSpecialDays((prev) =>
      prev.map((special) => {
        if (special.id !== specialDayId) return special;

        return { ...special, breaks: [...special.breaks, newBreak] };
      })
    );
  };

  const handleUpdateSpecialDayDate = (specialDayId) => (v) => {
    setSpecialDays((prev) => prev.map((special) => {
      if (special.id !== specialDayId) return special;

      return { ...special, date: dayjs(v).format('YYYY-MM-DD') };
    }))
  }

  const handleUpdateSpecialDayTimes = (attr,specialDayId) => (_,v) => {
    setSpecialDays((prev) => prev.map((special) => {
      if (special.id !== specialDayId) return special;

      special.breaks = special.breaks.map((breakTime) => {
        const breakTo = dayjs(v, "mm:ss") > dayjs(breakTime.to, "mm:ss") ? v : breakTime.to
        if(attr === "from" && dayjs(breakTime.from, "mm:ss") < dayjs(v, "mm:ss"))  return {...breakTime, from: v, to: breakTo}
        if(attr === "to" && dayjs(breakTime.to, "mm:ss") > dayjs(v, "mm:ss")) return {...breakTime, to: v}
        else return breakTime
      })

      if(attr === "from" && dayjs(v, "mm:ss") > dayjs(special.to, "mm:ss")) return { ...special, from: v, to: v}

      return { ...special, [attr]: v};
    }))
  }

  const handleUpdateBreakTimes = (attr, breakId, specialDayId) => (e, v) => {
    setSpecialDays((prev) => prev.map((special) => {
      if (special.id !== specialDayId) return special;

      return { ...special, breaks: special?.breaks.map((breakTime) => {
        if (breakTime.id !== breakId) return breakTime;

        if (
          attr === 'from' &&
          dayjs(v, 'mm:ss') > dayjs(breakTime.to, 'mm:ss')
        )
          return { ...breakTime, from: v, to: v };

        return {...breakTime, [attr]: v}
      }) };
    }))
  }

  const deleteSpecialDay = (specialDayId) => {
    setSpecialDays((prev) => prev.filter(special => special.id !== specialDayId))
  }

  const deleteBreak = (specialDayId, breakId) => {
    setSpecialDays((prev) => prev.map(special => {
      if (special.id !== specialDayId) return special;

      return {
        ...special, breaks: special?.breaks?.filter(breakTime => breakTime.id !== breakId)
      }
    }))
  }


  const handleOpenModal = () => {
    setOpenModal({ open: false, type: null });
  };
  return (
    <Box marginTop="1rem" width="100%">
          {openModal.type === 'delete-modal' && (
        <Modal
        handleOpenModal={handleOpenModal}
        type={'DELETE'}
        open={openModal.open}
        id={openModal.id}
        handleDelete={deleteSpecialDay}
      />
          )}
      {specialDays.map((specialDay) => (
        <Grid container spacing={2} key={specialDay?.id}>
          <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack spacing={3}>
                <DesktopDatePicker
                  minDate={dayjs().add(1, 'day')}
                  InputProps={{
                    style: {
                      flexDirection: 'row-reverse',
                    },
                  }}
                  inputFormat='DD-MM-YYYY'
                  value={specialDay?.date ? dayjs(specialDay?.date ,'YYYY-MM-DD') : dayjs().add(1, 'day').format('YYYY-MM-DD')}
                  onChange={handleUpdateSpecialDayDate(specialDay?.id)}
                  renderInput={(params) => (
                    <TextField size="small" {...params} />
                  )}
                />
              </Stack>
              </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              <Autocomplete
                size="small"
                disablePortal
                disableClearable
                id="combo-box-demo"
                onChange={handleUpdateSpecialDayTimes("from", specialDay.id)}
                value={dayjs(specialDay?.from, "mm:ss").format("mm:ss") || null}
                options={Times.map((option) => option.label)}
                sx={{
                  marginBottom: '1rem',
                  width: '100%',
                  fontSize: '14px',
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select..." />
                )}
              />
              <Autocomplete
                size="small"
                disablePortal
                disableClearable
                id="combo-box-demo"
                onChange={handleUpdateSpecialDayTimes("to", specialDay.id)}
                value={dayjs(specialDay?.to, "mm:ss").format("mm:ss") || null}
                getOptionDisabled={(option) =>
                  dayjs(option, 'mm:ss') < dayjs(specialDay.from, 'mm:ss')
                }
                options={Times.map((option) => option.label)}
                sx={{
                  marginBottom: '1rem',
                  width: '100%',
                  fontSize: '14px',
                }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select..." />
                )}
              />
            </Box>
          </Grid>
          {specialDay.breaks.map((breakTime) => (
              <Grid key={breakTime.id} container marginBottom={1} marginLeft={'1rem'}>
                <Grid xs={5.5} item>
                  <Autocomplete
                    size="small"
                    disablePortal
                    disableClearable
                    id="combo-box-demo"
                    options={Times.map((option) => option.label)}
                    sx={{
                      width: '100%',
                      fontSize: '14px',
                    }}
                    value={dayjs(breakTime?.from, "mm:ss").format("mm:ss") || null}
                    onChange={handleUpdateBreakTimes("from", breakTime.id, specialDay.id)}
                    getOptionDisabled={(option) => 
                      dayjs(option, 'mm:ss') < dayjs(specialDay.from, 'mm:ss') || dayjs(option, 'mm:ss') > dayjs(specialDay.to, 'mm:ss')}
                    renderInput={(parPMs) => (
                      <TextField
                        {...parPMs}
                        InputProps={{
                          ...parPMs.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <AccessAlarmsIcon />
                            </InputAdornment>
                          ),
                        }}
                        placeholder="Select..."
                      />
                    )}
                  />
                </Grid>
                <Grid xs={5.5} item>
                  <Autocomplete
                    size="small"
                    disablePortal
                    disableClearable
                    id="combo-box-demo"
                    options={Times.map((option) => option.label)}
                    onChange={handleUpdateBreakTimes("to", breakTime.id, specialDay.id)}
                    value={dayjs(breakTime?.to, "mm:ss").format("mm:ss") || null}
                    sx={{
                      width: '100%',
                      fontSize: '14px',
                    }}
                    getOptionDisabled={(option) =>
                      dayjs(option, 'mm:ss') < dayjs(specialDay.from, 'mm:ss') || dayjs(option, 'mm:ss') < dayjs(breakTime.from, 'mm:ss') || dayjs(option, 'mm:ss') > dayjs(specialDay.to, 'mm:ss')
                    }
                    renderInput={(parPMs) => (
                      <TextField
                        {...parPMs}
                        InputProps={{
                          ...parPMs.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <AccessAlarmsIcon />
                            </InputAdornment>
                          ),
                        }}
                        placeholder="Select..."
                      />
                    )}
                  />
                </Grid>
                <Grid
                  id="once-per-person"
                  container
                  alignItems="center"
                  item
                  xs={1}
                  sm={1}
                  sx={{
                    cursor: 'pointer',
                    height: 40,
                    display: 'flex',
                    justifyContent: 'center',
                    color: '#fd9b78',
                  }}
                >
                  <DeleteIcon onClick={() => deleteBreak(specialDay.id, breakTime.id)} />
                </Grid>
              </Grid>
            ))}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="left"
            // marginTop="1rem"
            sx={{
              cursor: 'pointer',
              marginLeft: '1rem',
              marginBottom: '0.5rem',
              justifyContent: 'space-between',
              width: '100%',
            }}
            // onClick={() => addToBreak(day.id, "12.00 AM", "02.00 AM")}
          >
            <Box
              display="flex"
              onClick={() => addBreakToSpecialDay(specialDay.id)}
              sx={{ my: 2 }}
            >
              <AddCircleIcon sx={{ color: '#828f9a' }} />
              <Typography variant="subtitle2" color="#828f9a" marginLeft="5px">
                Add Break
              </Typography>
            </Box>
            <Box
              sx={{
                cursor: 'pointer',
                display: 'flex',
              }}
              onClick={() => setOpenModal({ id: specialDay.id, open: true, type: 'delete-modal' })}
            >
              <Typography color="#828F9A">Remove special day</Typography>
              <DeleteIcon sx={{ color: '#828F9A' }} />
            </Box>
          </Box>
        </Grid>
      ))}

      <Button
        variant="contained"
        sx={{ fontSize: '12px', fontWeight: 'bold' }}
        onClick={createNewSpecialDay}
      >
        ADD SPECIAL DAY
      </Button>
    </Box>
  );
};

const Times = [
  { label: '00:00' },
  { label: '00:30' },
  { label: '01:00' },
  { label: '01:30' },
  { label: '02:00' },
  { label: '02:30' },
  { label: '03:00' },
  { label: '03:30' },
  { label: '04:00' },
  { label: '04:30' },
  { label: '05:00' },
  { label: '05:30' },
  { label: '06:00' },
  { label: '06:30' },
  { label: '07:00' },
  { label: '07:30' },
  { label: '08:00' },
  { label: '08:30' },
  { label: '09:00' },
  { label: '09:30' },
  { label: '10:00' },
  { label: '10:30' },
  { label: '11:00' },
  { label: '11:30' },
  { label: '12:00' },
  { label: '12:30' },
  { label: '13:00' },
  { label: '13:30' },
  { label: '14:00' },
  { label: '14:30' },
  { label: '15:00' },
  { label: '15:30' },
  { label: '16:00' },
  { label: '16:30' },
  { label: '17:00' },
  { label: '17:30' },
  { label: '18:00' },
  { label: '18:30' },
  { label: '19:00' },
  { label: '19:30' },
  { label: '20:00' },
  { label: '20:30' },
  { label: '21:00' },
  { label: '21:30' },
  { label: '22:00' },
  { label: '22:30' },
  { label: '23:00' },
  { label: '23:30' },
];
export default SpecialDays;
