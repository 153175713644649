import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  DataGrid,
  GridActionsCellItem,
  GridMoreVertIcon,
  gridStringOrNumberComparator,
} from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CustomTableFooter from '../../components/CustomTableFooter';
import UsageHistory from './UsageHistory';
import AddStaff from './AddStaff';
import { styled, useTheme } from '@mui/material/styles';
import { gridClasses } from '@mui/x-data-grid';
import Modal from '../../components/Modal';
import AvatarComp from '../../components/Avatar';
import {
  deleteStaffById,
  editStaffById,
  getStaffList,
} from '../../Api/tenant/staff';
import CustomTextButton from '../../components/CustomButtons/CustomTextButton';
import { defaultPageSizeOptions } from '../../constants';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CustomTooltip from '../../components/CustomTooltip';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useFilterBar from '../../hooks/useFilterBar';
import { getQueries } from '../../utils/GetQueries';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { useMediaQuery } from '@mui/material';
import NoData from '../../components/NoData';

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    color: theme.palette.grey[500],
  },
  [`& .${gridClasses.columnHeader}`]: {
    backgroundColor: '#EFF1FC',
    color: '#3A3A50',
  },
}));

const filters = [
  {
    id: 1,
    title: 'Quick search',
    type: 'search',
    queryLabel: 'keyword',
  },
];

const Staff = () => {
  const [addCouponDrawer, setAddCouponDrawer] = useState(false);
  const [drawerType, setDrawerType] = useState('add');
  const [drawerData, setDrawerData] = useState(null);
  const [openModal, setOpenModal] = useState({ open: false, type: null });
  const [selectionModel, setSelectionModel] = useState([]);
  const [drawer, setDrawer] = useState(null);
  const [staff, setStaff] = useState(null);
  const [rows, setRows] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('lg'));
  const { FilterBar, state, FilterButton } = useFilterBar({
    filters,
  });

  const handelHide = async (body, id) => {
    await editStaffById(body, id);
    fetchStaff();
  };

  const toggleAddCouponDrawer = () => {
    setAddCouponDrawer(!addCouponDrawer);
  };

  const handleOpenModal = () => {
    setOpenModal({ open: false, type: null });
  };

  const handleDeleteOpenModal = () => {
    setOpenModal({ open: true, type: 'delete-modal' });
  };

  const toggleUsageHistoryModal = (e) => {
    e.stopPropagation();
    setDrawer((prevState) =>
      prevState !== 'usage-history' ? 'usage-history' : ''
    );
  };

  // API

  const fetchStaff = () => {
    setTableLoading(true);
    getStaffList(pageSize, pageCount).then((res) => {
      setStaff(res.data.rows);
    });
  };

  // fetch on render
  useEffect(() => {
    fetchStaff();
  }, []);

  // fetch on search
  useEffect(() => {
    getQueries(state).then((queries) => {
      setTableLoading(true);
      getStaffList(pageSize, pageCount, queries).then((res) => {
        setStaff(res.data.rows);
      });
    });
  }, [state, pageSize, pageCount]);

  // update table rows on fetch
  useEffect(() => {
    if (staff) {
      setRows(staff);
      setTableLoading(false);
    }
  }, [staff]);

  const deleteStaff = (id) => {
    deleteStaffById(id).then((res) => {
      fetchStaff();
    });
  };

  // table columns

  const columns = [
    {
      field: 'id',
      headerName: 'STAFF NAME',
      sortComparator: (v1, v2) =>
        gridStringOrNumberComparator(v1.name, v2.name),
      renderCell: (params) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <AvatarComp
            img={params?.row?.User?.image}
            username={params.row.User.firstName}
          />

          <Typography fontSize="14px" marginLeft={'10px'} color="inherit">
            {params.row.User.firstName} {params.row.User.lastName}
          </Typography>
        </Box>
      ),
      flex: 1,
      minWidth: 330,
    },
    {
      field: 'email',
      headerName: 'EMAIL',
      valueGetter: (params) => params.row.User.email,
      flex: 1,
      minWidth: 320,
    },
    {
      field: 'phone',
      headerName: 'PHONE',
      flex: 1,
      valueGetter: (params) => params.row.User.phone,

      minWidth: 180,
    },
    {
      field: 'actions',
      headerName: 'ACTIONS',
      type: 'actions',
      align: 'right',
      minWidth: 100,
      maxWidth: 100,

      getActions: (params) => {
        if (params.row.isHidden) {
          return [
            <GridActionsCellItem
              icon={<VisibilityOffIcon />}
              label="Hide Section"
            />,
            <GridActionsCellItem
              label="Edit"
              onClick={() => {
                setDrawerType('edit');
                setDrawerData(params.row);
                toggleAddCouponDrawer();
              }}
              showInMenu
            />,
            <GridActionsCellItem
              label="Delete"
              onClick={() => {
                setOpenModal({
                  open: true,
                  type: 'delete-modal',
                  id: params.id,
                });
              }}
              showInMenu
            />,
            <GridActionsCellItem
              label="Share"
              onClick={() => {
                setOpenModal({ open: true, type: 'share-modal' });
              }}
              showInMenu
            />,
          ];
        }
        return [
          <GridActionsCellItem
            label="hide"
            icon={
              params.row.visible ? (
                <CustomTooltip
                  arrow
                  placement="top"
                  title="Disable item may not be available in other pages and network"
                >
                  <Box
                    sx={{
                      backgroundColor: '#fff',
                      padding: '3px 5px',
                      borderRadius: '5px',
                      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
                    }}
                  >
                    <VisibilityIcon />
                  </Box>
                </CustomTooltip>
              ) : (
                <Box
                  sx={{
                    backgroundColor: '#fff',
                    padding: '3px 5px',
                    borderRadius: '5px',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
                  }}
                >
                  <VisibilityOffIcon />
                </Box>
              )
            }
            onClick={() =>
              handelHide(
                {
                  visible: !params.row.visible,
                },
                params.row.id
              )
            }
          />,
          <GridActionsCellItem
            label="Edit"
            onClick={() => {
              setDrawerType('edit');
              setDrawerData(params.row);
              toggleAddCouponDrawer();
            }}
            showInMenu
          />,
          <GridActionsCellItem
            label="Delete"
            onClick={() => {
              setOpenModal({ open: true, type: 'delete-modal', id: params.id });
            }}
            showInMenu
          />,
          <GridActionsCellItem
            label="Share"
            onClick={() => {
              setOpenModal({ open: true, type: 'share-modal' });
            }}
            showInMenu
          />,
        ];
      },
    },
  ];

  return (
    <Grid
      item
      container
      sx={{
        minWidth: { xs: '100%' },
        px: '20px',
      }}
    >
      <>
        {openModal.type === 'delete-modal' ? (
          <Modal
            handleOpenModal={handleOpenModal}
            type={'DELETE'}
            open={openModal.open}
            id={openModal.id}
            handleDelete={deleteStaff}
          />
        ) : (
          <Modal
            handleOpenModal={handleOpenModal}
            type={'SHARE'}
            open={openModal.open}
          />
        )}
      </>

      {addCouponDrawer && (
        <AddStaff
          toggleAddCouponDrawer={toggleAddCouponDrawer}
          drawerType={drawerType}
          drawerData={drawerData}
          handelHide={handelHide}
          fetchStaff={fetchStaff}
        />
      )}
      {drawer === 'usage-history' && (
        <UsageHistory toggleDrawer={toggleUsageHistoryModal} />
      )}
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <Typography
          sx={{
            marginTop: 2.5,
            marginBottom: 1,
            fontSize: 24,
            color: '#3A3A50',
          }}
        >
          Staff
        </Typography>
      </Grid>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <CustomTextButton
          text="+ Add staff"
          onClick={() => {
            setDrawerType('add');
            toggleAddCouponDrawer();
          }}
        />
        <Box sx={{ display: 'flex', gap: '10px', ml: '10px' }}>
          {FilterButton}
        </Box>
      </Box>
      {/* --------------------SEARCH------------------------------- */}
      {FilterBar}
      {/* --------------------TABLE--------------------------------- */}
      <Grid
        container
        item
        justifyContent="space-between"
        sx={{ marginTop: '20px' }}
      >
        <div style={{ width: '100%' }}>
          <StripedDataGrid
            sx={{
              border: 0,
              '& .MuiDataGrid-virtualScroller': {
                minHeight: '300px !important',
              },
              minHeight: '60vh',
              '& .MuiDataGrid-columnHeaderTitle': {
                fontSize: '12px',
                fontWeight: '900 !important',
              },
              '& .MuiDataGrid-main, .MuiDataGrid-footerContainer': {
                backgroundColor: 'white',
                border: 'none',
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
            }}
            getRowClassName={(params) => {
              return !params.row.visible ? 'even' : 'odd';
            }}
            rows={rows}
            columns={columns}
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            loading={tableLoading}
            autoHeight
            selectionModel={selectionModel}
            rowCount={1000}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => {
              setPageSize(newPageSize);
            }}
            rowsPerPageOptions={defaultPageSizeOptions}
            page={pageCount - 1}
            onPageChange={(newPage) => {
              setPageCount(newPage + 1);
            }}
            paginationMode="server"
            components={{
              MoreActionsIcon: () => (
                <Box
                  sx={{
                    backgroundColor: '#fff',
                    padding: '3px 5px',
                    borderRadius: '5px',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
                  }}
                >
                  <MoreVertIcon sx={{}} />
                </Box>
              ),
              NoRowsOverlay: NoData,
              NoResultsOverlay: NoData,
              Footer: () => (
                <CustomTableFooter
                  selectionModel={selectionModel}
                  handleDeleteOpenModal={handleDeleteOpenModal}
                />
              ),
              MoreActionsIcon: () => (
                <GridMoreVertIcon
                  sx={{
                    backgroundColor: 'white',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
                    borderRadius: '4px',
                    padding: '3px',
                    cursor: 'pointer',
                  }}
                />
              ),
              NoRowsOverlay: () => {
                return (
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'Center',
                      gap: '20px',
                    }}
                  >
                    <img
                      src="https://user-images.githubusercontent.com/109676648/254145031-57027cd6-fecd-4ce2-96fc-9d891c8de53a.svg"
                      alt="no data"
                      style={{
                        width: '100px',
                        height: '100px',
                      }}
                    />
                    No data at the moment 😔
                  </Box>
                );
              },
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default Staff;
