import { useCallback, useEffect, useMemo, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { getCategoryList } from '../../../Api/tenant/categories';
import { sortCategories } from '../../../utils/sortCategories';
import CustomNoOption from '../../CustomNoOption';
import { useDispatch } from 'react-redux';
import { drawersActions } from '../../../redux/slices/drawers.slice';
import { drawerTypes } from '../../../constants/drawerTypes';
import { crudTypes } from '../../../constants/crudTypes';
import useInitialGuide from '../../../hooks/useInitialGuide';
import { initialGuideAttributes } from '../../../constants/initialGuideAttributes';

const types = ['all', 'restaurant', 'service', 'product'];

export default function SelectCategory({
  value,
  onChange,
  type="all",
  sx,
  helperText,
  error,
  addNewEntity=false,
}) {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading =
    (open && options.length === 0) || (value && options.length === 0); // load when open the select for the first time or when the field has existing value
    const { initialGuideData, fetchInitialGuide } = useInitialGuide();

  const handleOpen = useCallback(async () => {
    if (!loading) return;
    try {
      const res = await getCategoryList(100, 1, type);

      setOptions(sortCategories(res.data));
    } catch (err) {
      console.log(err);
    }
  }, [loading, type]);

  useEffect(() => {
    handleOpen();
  }, [handleOpen]);

  const selectedValue = useMemo(
    () => options?.find((option) => option.id === value),
    [options, value]
  );

  if(!types.includes(type)) return <p style={{color: "red", fontWeight: "bold"}}>Incorrect Type for field</p>

  const handleDrawerOpen = () => {
    dispatch(drawersActions.openDrawer({
      type: drawerTypes.CATEGORY,
      title: "Add Category",
      crudType: crudTypes.ADD,
      submitTitle: crudTypes.ADD,
      refetchData: () => {
        setOptions([])
        if(!initialGuideData[initialGuideAttributes?.createdCategory]) fetchInitialGuide()
      }
    }))
  }
  return (
    <Autocomplete
      sx={{ width: '100%', ...sx }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name}
      options={options.sort((a, b) => -b?.type?.localeCompare(a?.type))}
      loading={loading}
      groupBy={(option) => type === "all" && option.type}
      value={selectedValue || null}
      onChange={(e, v) => onChange(e, v)}
      size="small"
      noOptionsText={
        addNewEntity ? <CustomNoOption onClick={handleDrawerOpen}>Add Category</CustomNoOption> : "No options"
      }
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select category"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          helperText={helperText}
          error={error}
        />
      )}
      renderOption={(props, option) => {
        const { parentId, name } = option;
        return (
          <span {...props} style={parentId && { marginLeft: '15px' }}>
            {name}
          </span>
        );
      }}
    />
  );
}
