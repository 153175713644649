import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const Coupons = () => {
  return (
    <Grid
      sx={{
        boxShadow: "rgb(149 157 165 / 20%) 0px 0px 10px",
        padding: "2rem",
        margin: "1rem",
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      <Grid xs={12} md={6} container>
        <Grid xs={6} item>
          <Typography color="#6c70dc">Coupon code</Typography>
        </Grid>
        <Box
          backgroundColor="#c7cfd9"
          color="#fff"
          padding="0.25rem"
          fontSize="14px"
          sx={{ display: "inline-block" }}
        >
          N/A
        </Box>
      </Grid>
      <Grid xs={12} md={6} container sx={{ marginTop: { xs: 1, md: 0 } }}>
        <Grid xs={6} sm={6} item>
          <Typography color="#6c70dc">Coupon code</Typography>
        </Grid>
        <Grid xs={6} sm={6} item>
          <Typography color="#c7cfd9">N/A</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Coupons;
