import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';
import { useState } from 'react';
import Rating from './Rating';
import CustomFeilds from './CustomFeilds';
import Extras from './Extras';
import AppointmentDetiles from './AppointmentDetiles';
import Coupons from './Coupons';
import Comments from './Comments';
import DisableSectionWrapper from '../../../components/DisableSectionWrapper';

const commentsInit = [
  {
    name: 'Name',
    company: '{{CompanyName}}',
    date: '3 days ago',
    body: 'Your time at 2nd April is cancelled. I could help you booking a new appointment. let me know? ',
    image: 'url',
  },
  {
    name: 'name',
    company: 'company',
    date: 'date',
    body: 'body data',
    image: 'url',
  },
  {
    name: 'name',
    company: 'company',
    date: 'date',
    body: 'body data',
    image: 'url',
  },
  {
    name: 'name',
    company: 'company',
    date: 'date',
    body: 'body data',
    image: 'url',
  },
  {
    name: 'name',
    company: 'company',
    date: 'date',
    body: 'body data',
    image: 'url',
  },
  {
    name: 'name',
    company: 'company',
    date: 'date',
    body: 'body data',
    image: 'url',
  },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const StyledTab = styled(Tab)({
  fontSize: '12px',
  fontWeight: 'bold',
  padding: 0,
});

export default function AppointmentInfoTab({
  categoryies,
  openCommentsTab,
  drawerData,
}) {
  const [value, setValue] = useState(openCommentsTab ? 4 : 0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [comments, setComments] = useState(commentsInit);

  const handleAddComment = (comment) => {
    setComments((prevState) => [
      {
        name: 'Name',
        company: '{{CompanyName}}',
        date: '3 days ago',
        body: comment,
        image: 'url',
      },
      ...prevState,
    ]);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mx: 3, mt: 2 }}>
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          sx={{ fontSize: '10px', width: '100%' }}
          value={value}
          onChange={handleChange}
        >
          <StyledTab label="Appointment details" {...a11yProps(0)} />
          <StyledTab label="Comments" {...a11yProps(1)} />
          <DisableSectionWrapper>
            <StyledTab label="Extras" {...a11yProps(2)} />
            {/* <StyledTab label="Custom fields" {...a11yProps(2)} /> */}
            <StyledTab label="Coupons" {...a11yProps(3)} />
            <StyledTab label="Rating" {...a11yProps(4)} />
          </DisableSectionWrapper>
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <AppointmentDetiles drawerData={drawerData} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Comments comments={comments} handleAddComment={handleAddComment} drawerData={drawerData} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Extras />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <CustomFeilds />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Coupons />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Rating />
      </TabPanel>

    </Box>
  );
}
