import React from 'react';
import { disableSectionFunc } from '../../utils/disableSectionFunc';

const DisableSectionWrapper = (props) => {
  const { children, ...restProps } = props;

  const clonedChildren = React.Children.map(children, (child) =>
    React.cloneElement(child, { ...restProps })
  );

  return disableSectionFunc(clonedChildren, {
    contentOnDisable: <></>,
  });
};

export default DisableSectionWrapper;
