import { Grid, Switch, TextField, Typography } from "@mui/material";
import { useState } from "react";

const Service = ({ page, handleChangePaymentMethodUnDraggable }) => {
  return (
    <>
      <Grid container item xs={12} sx={{ marginY: 1 }}>
        <Grid xs={12}>
          <Grid item xs={12} sx={{ marginY: 1 }}>
            <Typography variant="subtitle2">Mode</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={page.mode}
              onChange={(e) => {
                handleChangePaymentMethodUnDraggable(
                  page.id,
                  "mode",
                  e.target.value
                );
              }}
              size="small"
              required
              variant="outlined"
              sx={{
                marginBottom: "1rem",
                width: "100%",
                fontSize: "14px",
              }}
            />
          </Grid>
        </Grid>
        <Grid xs={12}>
          <Grid item xs={12} sx={{ marginY: 1 }}>
            <Typography variant="subtitle2">Client ID</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={page.clientId}
              onChange={(e) => {
                handleChangePaymentMethodUnDraggable(
                  page.id,
                  "clientId",
                  e.target.value
                );
              }}
              size="small"
              required
              variant="outlined"
              sx={{
                marginBottom: "1rem",
                width: "100%",
                fontSize: "14px",
              }}
            />
          </Grid>
        </Grid>
        <Grid xs={12}>
          <Grid item xs={12} sx={{ marginY: 1 }}>
            <Typography variant="subtitle2">Client secret</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={page.clientSecret}
              onChange={(e) => {
                handleChangePaymentMethodUnDraggable(
                  page.id,
                  "clientSecret",
                  e.target.value
                );
              }}
              size="small"
              required
              variant="outlined"
              sx={{
                marginBottom: "2rem",
                width: "100%",
                fontSize: "14px",
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Service;
