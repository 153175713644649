import React, { useState } from 'react';
import Drawer from '../../components/Drawer';
import DrawerFooter from '../../components/DrawerFooter';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import RoleTab from './Tabs';
import { useMediaQuery, useTheme } from '@mui/material';
import CustomTextButton from '../../components/CustomButtons/CustomTextButton';

const AddRoles = ({
  toggleAddCouponDrawer,
  drawerType,
  drawerData = null,
  categoryies,
  setCategoryies,
}) => {
  const [categoryData, setCategoryData] = useState('');
  const handelCategory = () => {
    const category = { label: categoryData };
    setCategoryies([...categoryies, category]);
    setCategoryData('');
  };
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('sm'));
  console.log(categoryies);
  return (
    <Grid container>
      <Grid item xs={12} sm={2}>
        <Drawer
          toggleDrawer={toggleAddCouponDrawer}
          type={
            drawerType === 'add'
              ? 'ADD'
              : drawerType === 'addRole'
              ? 'ADD'
              : 'EDIT'
          }
          title={
            drawerType === 'add'
              ? 'Add Category'
              : drawerType === 'addRole'
              ? 'Add Role'
              : 'Edit Role'
          }
        >
          {/* -----------FORM----- */}
          <Grid
            sx={{
              height: '100%',
              overflowY: 'auto',
              width: '100%',
            }}
          >
            {drawerType === 'add' && (
              <Grid sm={12} xs={12}>
                <Grid sx={{ px: 5, pt: 5 }}>
                  <Typography variant="subtitle2" sx={{ my: 1 }}>
                    Parent category<sapn style={{ color: 'red' }}>*</sapn>
                  </Typography>
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={top100Films}
                    value="Root category"
                    sx={{
                      marginBottom: '1rem',
                      width: '100%',
                      fontSize: '14px',
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Grid>
                <Grid sx={{ padding: 5, pt: 1 }}>
                  <Typography variant="subtitle2" sx={{ my: 1 }}>
                    Category name<sapn style={{ color: 'red' }}>*</sapn>
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    value={categoryData}
                    onChange={(e) => setCategoryData(e.target.value)}
                  />
                </Grid>
              </Grid>
            )}
            {drawerType !== 'add' && <RoleTab categoryies={categoryies} />}
          </Grid>
          {/* -----Button */}
          <DrawerFooter>
            <div
              style={{
                display: 'flex',
                gap: '15px',
                justifyContent: isSmallScreen ? 'space-between' : 'flex-end',
                width: '100%',
              }}
            >
              <CustomTextButton
                variant="contained"
                size="large"
                onClick={toggleAddCouponDrawer}
              >
                Cancel
              </CustomTextButton>
              <CustomTextButton
                type="blue"
                variant="contained"
                size="large"
                onClick={drawerType === 'add' && handelCategory}
              >
                {drawerType === 'add' ? 'Save' : 'Save'}
              </CustomTextButton>
            </div>
          </DrawerFooter>
        </Drawer>
      </Grid>
    </Grid>
  );
};

const top100Films = [
  { label: 'Root category' },
  { label: 'Dental Clinic' },
  { label: 'Gynecology' },
  { label: 'Cosmetic Surgery' },
  { label: 'Body' },
  { label: 'Skin & Hear' },
];

export default AddRoles;
