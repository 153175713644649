import {
  Autocomplete,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { addCategory } from '../../../../Api/tenant/categories';
import { useCallback, useMemo, useState } from 'react';
import useCategory from '../../../../hooks/useCategory';
import { customHelperTextErr } from '../../../../utils/CustomHelperTextErr';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../redux/slices/user.slice.js';
import CustomTextButton from '../../../CustomButtons/CustomTextButton';

const AddSubCategoryModal = ({ handleOpenModal, fetchCategories }) => {
  const { categories, subCategories } = useCategory();
  const [parentCategoryId, setParentCategoryId] = useState();
  const [categoryName, setCategoryName] = useState('');
  const [categoryType, setCategoryType] = useState('product');
  const [errors, setErrors] = useState({
    name: [],
    type: [],
    parentId: [],
  });
  const userId = useSelector(selectUser)?.user?.id;

  const handleCategoryType = (e) => {
    if(categoryType !== e.target.value) {
        setParentCategoryId(null)
        setCategoryType(e.target.value)
    }
}

  const handleSubmit = useCallback(async () => {
    const errs = {
      name: [],
      type: [],
      parentId: [],
    };

    let hasError = false;

    if (!categoryName) {
      errs.name.push('Category Name Required');
      hasError = true;
    }

    if(categories.find(category => category.name === categoryName) || subCategories.find(category => category.name === categoryName)) {
      errs.name.push("category name already exists");
      hasError = true;
    }

    if (!categoryType) {
      errs.type.push('Category Type Required');
      hasError = true;
    }

    if (!parentCategoryId) {
      errs.parentId.push('Parent Category Required');
      hasError = true;
    }

    if (hasError) {
      setErrors(errs);
      return;
    }

    try {
      await addCategory({
        name: categoryName,
        type: categoryType,
        parentId: parentCategoryId,
        userId
      });
      handleOpenModal();
      fetchCategories();
    } catch (err) {
      console.log(err);
    }
  }, [categories, categoryName, categoryType, fetchCategories, handleOpenModal, parentCategoryId, userId]);

  const selectedParent = useMemo(() => categories.find(category => category.id === parentCategoryId), [categories, parentCategoryId])

  return (
    <>
      <Grid sm={12} xs={12}>
        <Grid sx={{ px: 5, pt: 5 }}>
          <Typography variant="subtitle2" sx={{ my: 1 }}>
            Subcategory name<span style={{ color: 'red' }}>*</span>
          </Typography>
          <TextField
            fullWidth
            size="small"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
            error={errors.name.length > 0}
            helperText={
              errors.name.length > 0 ? customHelperTextErr(errors.name) : ''
            }
          />
        </Grid>
        <Grid sx={{ px: 5, pt: 3 }}>
          <Typography variant="subtitle2">
            Category Type<span style={{ color: 'red' }}>*</span>
          </Typography>
          <Select
            fullWidth
            size="small"
            value={categoryType}
            onChange={handleCategoryType}
            error={errors.type.length > 0}
            helperText={
              errors.type.length > 0 ? customHelperTextErr(errors.type) : ''
            }
          >
            <MenuItem value="product">Product</MenuItem>
            <MenuItem value="restaurant">Restaurant</MenuItem>
            <MenuItem value="service">Service</MenuItem>
          </Select>
        </Grid>
        {categoryType && (
          <Grid sx={{ padding: 5, pt: 1 }}>
            <Typography variant="subtitle2" sx={{ my: 1 }}>
              Select Parent Category<span style={{ color: 'red' }}>*</span>
            </Typography>
            <Autocomplete
              size="small"
              disablePortal
              value={selectedParent || null}
              options={categories.filter(
                (category) => category.type === categoryType
              )}
              getOptionLabel={(option) => option.name}
              onChange={(e, v) => {
                setParentCategoryId(v.id);
              }}
              sx={{
                marginBottom: '1rem',
                width: '100%',
                fontSize: '14px',
              }}
              renderInput={(params) => (
                <TextField
                  error={errors.parentId.length > 0}
                  helperText={
                    errors.parentId.length > 0
                      ? customHelperTextErr(errors.parentId)
                      : ''
                  }
                  {...params}
                />
              )}
            />
          </Grid>
        )}
      </Grid>
      <Grid container sx={{ justifyContent: 'end', gap: "15px" }}>
        <CustomTextButton
          variant="contained"
          size="large"
          onClick={handleOpenModal}
        >
          Cancel
        </CustomTextButton>
        <CustomTextButton
          type="blue"
          variant="contained"
          size="large"
          onClick={handleSubmit}
        >
          Save
        </CustomTextButton>
      </Grid>
    </>
  );
};

export default AddSubCategoryModal;
