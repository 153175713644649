import { Button, Grid, Switch, Typography, useMediaQuery } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CircleIcon from '@mui/icons-material/Circle';
import { useState } from 'react';
import CustomSettingsTitle from '../../CustomSettingsTitle/CustomSettingsTitle';
import { IOSSwitch } from '../../CustomCheckbox';

const WaitingList = () => {
  const [enableWaitingList, setEnableWaitingList] = useState(false);
  const [enableAllowLocalPay, setEnableAllowLocalPay] = useState(false);
  const mediaQuery1238 = useMediaQuery('(min-width:1238px)');

  return (
    <>
      <Grid container xs={12} style={{ justifyContent: 'flex-end' }}>
        <Button
          sx={{
            width: { xs: '100%', sm: 'auto' },
            marginTop: { xs: 9, sm: 5 },
            marginBottom: 1,
            marginLeft: { xs: 0, sm: 1 },
            backgroundColor: '#4abf60',
            height: '45px',
            borderRadius: '2px',
            fontSize: '12px',
            fontWeight: 600,
          }}
          variant="contained"
          color="success"
          onClick={() => {}}
        >
          <CheckIcon sx={{ marginRight: 1, fontSize: '20px' }} />
          Save Changes
        </Button>
      </Grid>

      <Grid xs={12} style={{ backgroundColor: 'white', marginTop: '20px' }}>
        <Grid
          sx={{
            padding: { xs: '15px', md: '15px 25px' },
            borderBottom: '1px solid #e3eaf3',
          }}
        >
          {/* <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>
            General Settings{' '}
            <span style={{ color: '#c1c1c1' }}>
              <CircleIcon sx={{ fontSize: '5px', marginX: '3px' }} /> Waiting
              List
            </span>
          </Typography> */}
          <CustomSettingsTitle
            title="General Settings"
            subTitle="Waiting List"
          />
        </Grid>
        <Grid container sx={{ padding: { xs: '15px', md: '15px 25px' } }}>
          <Grid
            container
            xs={mediaQuery1238 ? 6 : 12}
            sx={{ paddingRight: { md: 1 } }}
          >
            <Grid
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setEnableWaitingList(!enableWaitingList);
              }}
              item
              xs={12}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                border: '1px solid rgba(0, 0, 0, 0.23)',
                borderRadius: '4px',
                paddingX: 2,
                paddingY: 1,
                height: '40px',
                marginY: '7px',
              }}
            >
              <Typography variant="subtitle2">Enable Waiting List</Typography>
              <IOSSwitch
                size="small"
                sx={{ marginLeft: 2 }}
                onChange={(e) => setEnableWaitingList(e.currentTarget.checked)}
                checked={enableWaitingList}
              />
            </Grid>
          </Grid>

          {enableWaitingList && (
            <Grid
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setEnableAllowLocalPay(!enableAllowLocalPay);
              }}
              item
              xs={mediaQuery1238 ? 6 : 12}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                border: '1px solid rgba(0, 0, 0, 0.23)',
                transition: 'display 5s linear',
                borderRadius: '4px',
                paddingX: 2,
                paddingY: 1,
                height: '40px',
                marginY: '7px',
              }}
            >
              <Typography
                sx={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  width: '65%',
                }}
                variant="subtitle2"
              >
                Allow to use local payment method for waiting timeslots:
              </Typography>
              <IOSSwitch
                size="small"
                defaultChecked
                sx={{ marginLeft: 2 }}
                onChange={(e) => {
                  setEnableAllowLocalPay(e.currentTarget.checked);
                }}
                checked={enableAllowLocalPay}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default WaitingList;
