import {
  Autocomplete,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

const Setting = ({ setting, setSetting }) => {
  const handelOpenMethod = () => {
    setSetting({ ...setting, isOpenPanel: !setting.isOpenPanel });
  };
  const handelMethod = (data) => {
    setting.method = [...data];
    setSetting({ ...setting });
  };
  return (
    <div>
      <Grid container>
        <Grid xs={12} sm={12} sx={{ py: { sm: 2 } }}>
          <Grid
            id="once-per-person"
            container
            alignItems="center"
            item
            xs={12}
            sx={{
              cursor: "pointer",
              border: "1px solid rgba(0, 0, 0, 0.23)",
              borderRadius: "4px",

              height: 40,
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: ".7rem",
            }}
            onClick={handelOpenMethod}
          >
            <Typography variant="subtitle2">
            Set service specific payment methods:
            </Typography>
            <FormControlLabel
              control={<Switch checked={setting.isOpenPanel} />}
            />
          </Grid>
        </Grid>
        {setting.isOpenPanel && (
          <Grid xs={12} sm={12} sx={{ py: { sm: 1 } }}>
            <Typography marginBottom={1}>
              Payment methods <span style={{ color: "red" }}>*</span>
            </Typography>
            <Autocomplete
              multiple
              size="small"
              id="multiple-limit-tags"
              options={paymentMethod.map(option => option.title)}
              value={setting.method}
              // getOptionLabel={(option) => option.title}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select..." />
              )}
              sx={{ width: "100%" }}
              onChange={(_event, newData) => handelMethod(newData)}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const paymentMethod = [
  { title: "Local" },
  { title: "Giftcard" },
  { title: "Paypal" },
  { title: "Cradit Card" },
];
export default Setting;
