export const FilterItem = [
  {
    id: 1,
    title: 'Quick search',
    type: 'search',
  },
  {
    id: 2,
    title: 'Select date',
    type: 'date',
  },
  {
    id: 3,
    title: 'Service',
    type: 'select',
    options: [
      'Oral hygiene',
      'Tooth whitening',
      'Implants',
      '3d/4d Ultrasound',
      'OB/GYN',
      'Maternity',
      'Buttock Lift',
      'Liposuction',
      'Fat Transfer',
    ],
    isOpen: false,
    search: '',
  },
  {
    id: 4,
    title: 'Customer',
    type: 'select',
    options: [
      'Shakil Ahmed',
      'Kristian Dawns',
      ' Jennifer Landr',
      ' Jennifer Landry',
      ' Hellen Ballar',
      'Emille Burhess',
      ' Albert Swift',
      ' Diana Martin',
      'Ava Gilmore',
      'Axel Juarez',
      'Charlie Deyer',
      'Francisco Jefferson',
    ],
    isOpen: false,
    search: '',
  },
  {
    id: 5,
    title: 'Staff',
    type: 'select',
    options: [
      'Roland Mark',
      'Mark S.Brochet,MD',
      'Joseph Church, MD',
      'Thomas Coates,MD',
      'Rima Jurban,MD',
      ' David Freyer,Do',
      'Dennis R. Maceri',
      'MD44',
    ],
    isOpen: false,
    search: '',
  },
  {
    id: 6,
    title: 'Status',
    type: 'select',
    options: ['Pending', 'Paid', 'Canceled', 'Not Paid'],
    isOpen: false,
    search: '',
  },
];

export const paymentTableData = {
  coloum: [
    {
      id: 1,
      appointDate: '09/07/2022 04:00 AM',
      customer: {
        id: 1,
        avatar: null,
        email: 'AlexenderInci@gmail.com',
        name: 'Alexender Inci',
      },
      staff: 'Tanya Skinner',
      service: '4D Ultrsound',
      method: 'LOCAL',
      location: 'LONDON',
      totalamount: 255,
      paidamount: 100,
      dueamount: 852,
      status: 'Pending',
      servicePrice: 100,
      vat: 10,
      discount: 50,
      dueAmount: 40,
    },
    {
      id: 2,
      appointDate: '07/09/2022 04:00 AM',
      customer: {
        id: 2,
        avatar:
          'https://assets.materialup.com/uploads/bebad102-7f40-4941-99cd-54366113003e/avatar-08.png',
        email: 'marwansami@gmail.com',
        name: 'Marwan Sami',
      },
      staff: 'ALEXENDER',
      service: '4D Ultrsound',
      method: 'CREDIT',
      location: 'LONDON',
      totalamount: 120,
      paidamount: 100,
      dueamount: 852,
      status: 'Pending',
      servicePrice: 100,
      vat: 10,
      discount: 50,
      dueAmount: 20,
    },
    {
      id: 3,
      appointDate: '07/09/2022 04:00 AM',
      customer: {
        id: 3,
        avatar:
          'https://assets.materialup.com/uploads/bebad102-7f40-4941-99cd-54366113003e/avatar-08.png',
        email: 'hasibulpolok.bdn@gmail.com',
        name: 'Hasibul Polok',
      },
      staff: 'MARWAN SAMI',
      service: '4D Ultrsound',
      method: 'LOCAL',
      location: 'LONDON',
      totalamount: 155,
      paidamount: 100,
      dueamount: 852,
      status: 'Paid',
      servicePrice: 100,
      vat: 10,
      discount: 50,
      dueAmount: 30,
    },
    {
      id: 4,
      appointDate: '07/09/2022 04:00 AM',
      customer: {
        id: 4,
        avatar:
          'https://assets.materialup.com/uploads/bebad102-7f40-4941-99cd-54366113003e/avatar-08.png',
        email: 'hasibulpolok.bdn@gmail.com',
        name: 'Hasibul Polok',
      },
      staff: 'MARWAN SAMI',
      service: '4D Ultrsound',
      method: 'LOCAL',
      location: 'LONDON',
      totalamount: 155,
      paidamount: 100,
      dueamount: 852,
      status: 'Not Paid',
      servicePrice: 100,
      vat: 10,
      discount: 50,
      dueAmount: 50,
    },
    {
      id: 5,
      appointDate: '07/09/2022 04:00 AM',
      customer: {
        id: 5,
        avatar:
          'https://assets.materialup.com/uploads/bebad102-7f40-4941-99cd-54366113003e/avatar-08.png',
        email: 'hasibulpolok.bdn@gmail.com',
        name: 'Hasibul Polok',
      },
      staff: 'MARWAN SAMI',
      service: '4D Ultrsound',
      method: 'LOCAL',
      location: 'LONDON',
      totalamount: 155,
      paidamount: 100,
      dueamount: 852,
      status: 'Canceled',
      servicePrice: 100,
      vat: 10,
      discount: 50,
      dueAmount: 60,
    },
  ],
};
