import { Grid } from "@mui/material";

const ExtraServiceActionIcon = ({ icon, bgColor, ...props }) => {
  return (
    <Grid
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: bgColor,
        borderRadius: "50%",
        height: "25px",
        width: "25px",
        margin: "5px 0",
        cursor: "pointer"
      }}
      {...props}
    >
      {icon}
    </Grid>
  );
};

export default ExtraServiceActionIcon;
