import RatingForm from './RatingForm';
import CustomerPanel from './CustomerPanel';
import BookingWidgetTranslation from './BookingWidgetTranslation';
import BookingSteps from './BookingSteps';

const FrontEndPanels = ({ subTab, fetchCurrentSettings, currentSettings }) => {
  return (
    <>
      {subTab === 'booking-steps' && (
        <BookingSteps
          fetchCurrentSettings={fetchCurrentSettings}
          currentSettings={currentSettings}
        />
      )}
      {subTab === 'booking-widget-translation' && (
        <BookingWidgetTranslation
          fetchCurrentSettings={fetchCurrentSettings}
          currentSettings={currentSettings}
        />
      )}
      {subTab === 'customer-panel' && (
        <CustomerPanel
          fetchCurrentSettings={fetchCurrentSettings}
          currentSettings={currentSettings}
        />
      )}
      {subTab === 'rating-form' && (
        <RatingForm
          fetchCurrentSettings={fetchCurrentSettings}
          currentSettings={currentSettings}
        />
      )}
    </>
  );
};

export default FrontEndPanels;
