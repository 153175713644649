import ConnectSubCustomers from "./ConnectSubCustomers";
import ConnectInvite from "./ConnectInvite";

const ConnectSettings = ({ subTab, fetchCurrentSettings, currentSettings }) => {
  return (
    <>
      {subTab === "connect-invite" && (
        <ConnectInvite
          fetchCurrentSettings={fetchCurrentSettings}
          currentSettings={currentSettings}
        />
      )}
      {subTab === "connect-sub-customer" && (
        <ConnectSubCustomers
          fetchCurrentSettings={fetchCurrentSettings}
          currentSettings={currentSettings}
        />
      )}
    </>
  );
};

export default ConnectSettings;
