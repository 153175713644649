import * as React from 'react';
import Stack from '@mui/material/Stack';
import journal from "../../assets/images/journal.png";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CustomTextButton from '../../components/CustomButtons/CustomTextButton';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';

const UpsellJournal = () => {
  return (  
    <React.Fragment>
      <Container maxWidth="sm">
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center', 
          marginTop: '0%',
          paddingBottom: '0%',
          textAlign: 'center',
  
          '& img': {
            width: '100%',
            maxWidth: '30%',
            height: 'auto',
            padding: '10px', 
          },
          '@media (max-width: 600px)': {
            '& img': {
              padding: '10px',
            },
          },
        }}>
          <img
            src={journal}
            alt="persons image"
          />
          <h1>Journal</h1>
          <Typography >Ensure the safety of your customers’ documents and effortlessly stay on top of upcoming appointments.
           Upgrade now to streamline your workflow and enhance customer satisfaction..</Typography>

          <Stack spacing={2} direction="row" marginTop={4}>
            <Link to="https://upnize.com/help-center/journal-management">
            
            <CustomTextButton sx={{textTransform: 'capitalize'}}
                  variant="contained"
                  size="large"
                >
                  Learn more
                </CustomTextButton>
            </Link>
            <Link to="/subscription">
                <CustomTextButton sx={{textTransform: 'capitalize'}}
                  type="blue"
                  variant="contained"
                  size="large" >
                    Upgrade
                  </CustomTextButton>
            </Link>
          </Stack>
        </Box>
      </Container>
    </React.Fragment>
  );
}

export default UpsellJournal;
