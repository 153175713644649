import { Grid, Switch, Typography } from '@mui/material';
import { useState } from 'react';

const ServiceExtras = ({
  ifServiceDoesntHaveExtraSkipStep,
  setIfServiceDoesntHaveExtraSkipStep,
  setIsSaveBar,
}) => {
  return (
    <>
      <Grid container item xs={12} sx={{ marginY: 1 }}>
        <Grid
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setIfServiceDoesntHaveExtraSkipStep(
              !ifServiceDoesntHaveExtraSkipStep
            );
            setIsSaveBar(true);
          }}
          xs={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            paddingX: 2,
            paddingY: 1,
            height: '40px',
          }}
        >
          <Typography variant="subtitle2" sx={{ lineHeight: '1.2 !important' }}>
            If a Service does not have an extra skip the step
          </Typography>
          <Switch
            size="small"
            defaultChecked
            sx={{ marginLeft: 2 }}
            onChange={(e) => {
              setIfServiceDoesntHaveExtraSkipStep(e.target.checked);
              setIsSaveBar(true);
            }}
            checked={ifServiceDoesntHaveExtraSkipStep}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ServiceExtras;
