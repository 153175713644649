import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useEffect, useRef, useState } from 'react';
import Switch from '@mui/material/Switch';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import './notification.css';
import Modal from '../../components/Modal';
import {
  appointmentTags,
  serviceTags,
  customerTags,
  staffTags,
  locationTags,
  companyTags,
} from './tagsData';
import SaveSlideBar from '../../components/SaveSlideBar';
import QuillEditor from '../../components/QuillEditor';
import CustomIconButton from '../../components/CustomButtons/CustomIconButton';
import { Box } from '@mui/material';

const Notification = () => {
  const [isSaveBar, setIsSaveBar] = useState(true);
  const [isToCustomer, setIsToCustomer] = useState(true);
  const [quillHtml, setQuillHtml] = useState('');
  const [quillRange, setQuillRange] = useState(0);
  const quillRef = useRef();
  const templateHeadings = [
    'New Appointment',
    'Appointment re-scheduled',
    'Appointment approved',
    'Appointment pending',
    'Appointment rejected',
    'Appointment canceled',
    'Reminder before appointment',
    'Reminder after appointment',
    'Access to Customer Panel',
  ];

  const [templateId, setTemplateId] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  const [subjectId1, setSubjectId1] = useState('');
  const [subjectId2, setSubjectId2] = useState('');
  const [subjectId3, setSubjectId3] = useState('');
  const [subjectId4, setSubjectId4] = useState('');
  const [subjectId5, setSubjectId5] = useState('');
  const [subjectId6, setSubjectId6] = useState('');
  const [subjectId7, setSubjectId7] = useState('');
  const [subjectId8, setSubjectId8] = useState('');
  const [subjectId9, setSubjectId9] = useState('');

  const [bodyId1, setBodyId1] = useState('');
  const [bodyId2, setBodyId2] = useState('');
  const [bodyId3, setBodyId3] = useState('');
  const [bodyId4, setBodyId4] = useState('');
  const [bodyId5, setBodyId5] = useState('');

  const [enableId1, setEnableId1] = useState('');
  const [enableId2, setEnableId2] = useState('');
  const [enableId3, setEnableId3] = useState('');
  const [enableId4, setEnableId4] = useState('');
  const [enableId5, setEnableId5] = useState('');

  const [currentTemplateId, setCurrentTemplate] = useState('');
  const [currentSubject, setCurrentSubject] = useState('');
  const [currentBody, setCurrentBody] = useState('');

  const fetchNotifications = async () => {};

  const updateSubject = (e) => {
    setCurrentSubject(e.target.value);
    if (templateId == 1) {
      setSubjectId1(e.target.value);
    } else if (templateId == 2) {
      setSubjectId2(e.target.value);
    } else if (templateId == 3) {
      setSubjectId3(e.target.value);
    } else if (templateId == 4) {
      setSubjectId4(e.target.value);
    } else if (templateId == 5) {
      setSubjectId5(e.target.value);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const changeTemplateId = (id) => {
    setTemplateId(id);
  };

  const handleOpenModal = () => {
    setOpenModal(false);
  };

  // handlers

  const handleSave = () => {};

  const handleCancel = () => {};

  return (
    <>
      <Grid
        item
        container
        sx={{
          minWidth: { xs: '100%' },
          px: '20px',
        }}
      >
        <Modal
          handleOpenModal={handleOpenModal}
          open={openModal}
          type={'SEND_TEST_EMAIL'}
        />

        {/*------------------ Teanants Heading---------------------- */}
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            // alignItems: 'flex-end',
            flexWrap: 'wrap',
            paddingBottom: '30px',
            borderBottom: { xs: '1px solid #e3eaf3' },
          }}
        >
          <Typography
            sx={{
              marginTop: 2.5,
              marginBottom: 1,
              fontSize: 24,
              color: '#3A3A50',
            }}
          >
            Email Notifications
          </Typography>
          {/* <Grid>
            <Button
              size="large"
              sx={{
                width: { xs: '100%', sm: 'auto' },
                marginTop: 5,
                marginBottom: 1,
                background: 'transparent !important',
                color: '#8F9CA7 !important',
                height: '47px',
                borderRadius: '2px',
                fontSize: '12px',
                fontWeight: 600,
                boxShadow: 'none',
                border: '1px solid #E3EAF3 !important',
              }}
              variant="contained"
              onClick={() => setOpenModal(true)}
            >
              <ForwardToInboxIcon sx={{ marginRight: 1, fontSize: '20px' }} />
              Send Test Email
            </Button>
          </Grid> */}
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <CustomIconButton
              Icon={ForwardToInboxIcon}
              toolTipText="Send Test Email"
              onClick={() => setOpenModal(true)}
            />
          </Box>
        </Grid>
        {/* --------------------------------------------------------- */}
        {/* ---------MAIN BODY---------------------- */}
        <Grid container sx={{ paddingY: 4 }}>
          {/* -------Toggles------------ */}
          <Grid
            item
            xs={12}
            sm={3}
            sx={{
              backgroundColor: 'white !important',
              boxShadow: '0 0 30px 0 rgb(0 0 0 / 5%)',
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                borderBottom: '1px solid lightgrey',
                paddingX: 2,
                fontSize: 12,
                fontWeight: 600,
              }}
            >
              <Grid xs={12} sx={{ display: 'flex' }}>
                <Grid
                  xs={6}
                  sx={{
                    textAlign: 'center',
                    py: 2,
                    borderBottom: isToCustomer ? '2px #6c70dc solid' : '',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setIsToCustomer(true);
                  }}
                >
                  TO CUSTOMER
                </Grid>
                <Grid
                  xs={6}
                  sx={{
                    textAlign: 'center',
                    py: 2,
                    borderBottom: isToCustomer ? '' : '2px #6c70dc solid',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setIsToCustomer(false);
                  }}
                >
                  TO STAFF
                </Grid>
              </Grid>
            </Grid>
            <Grid sx={{ paddingX: 2 }}>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    color: '#6c70dc',
                    fontSize: '1rem',
                    paddingTop: 2,
                    paddingBottom: 1,
                  }}
                >
                  Notifications
                </Typography>
              </Grid>
              <Grid
                className={templateId == 1 ? 'notificationSelected' : ''}
                item
                xs={12}
                sx={{
                  color: '#828F9A',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  border: '1px solid #e3eaf3',
                  paddingX: 2,
                  paddingY: 1,
                  marginY: 1,
                }}
                onClick={() => {
                  changeTemplateId(1);
                  setCurrentSubject(subjectId1);
                  setCurrentBody(bodyId1);
                }}
              >
                <Typography variant="subtitle2">New Appointment</Typography>
                <Switch size="small" defaultChecked sx={{ marginLeft: 2 }} />
              </Grid>
              <Grid
                className={templateId == 2 ? 'notificationSelected' : ''}
                item
                xs={12}
                sx={{
                  color: '#828F9A',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  border: '1px solid #e3eaf3',
                  paddingX: 2,
                  paddingY: 1,
                  marginY: 1,
                }}
                onClick={() => {
                  changeTemplateId(2);
                  console.log(subjectId2);
                  setCurrentSubject(subjectId2);
                  setCurrentBody(bodyId2);
                }}
              >
                <Typography variant="subtitle2">
                  Appointment re-scheduled
                </Typography>
                <Switch
                  size="small"
                  defaultChecked
                  sx={{ marginLeft: 2, color: '#6c70dc !important' }}
                />
              </Grid>
              <Grid
                className={templateId == 3 ? 'notificationSelected' : ''}
                item
                xs={12}
                sx={{
                  color: '#828F9A',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  border: '1px solid #e3eaf3',
                  paddingX: 2,
                  paddingY: 1,
                  marginY: 1,
                }}
                onClick={() => {
                  changeTemplateId(3);
                  setCurrentSubject(subjectId3);
                  setCurrentBody(bodyId3);
                }}
              >
                <Typography variant="subtitle2">
                  Appointment approved
                </Typography>
                <Switch size="small" defaultChecked sx={{ marginLeft: 2 }} />
              </Grid>
              <Grid
                className={templateId == 4 ? 'notificationSelected' : ''}
                item
                xs={12}
                sx={{
                  color: '#828F9A',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  border: '1px solid #e3eaf3',
                  paddingX: 2,
                  paddingY: 1,
                  marginY: 1,
                }}
                onClick={() => {
                  changeTemplateId(4);
                  setCurrentSubject(subjectId4);
                  setCurrentBody(bodyId4);
                }}
              >
                <Typography variant="subtitle2">Appointment pending</Typography>
                <Switch size="small" defaultChecked sx={{ marginLeft: 2 }} />
              </Grid>
              <Grid
                className={templateId == 5 ? 'notificationSelected' : ''}
                item
                xs={12}
                sx={{
                  color: '#828F9A',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  border: '1px solid #e3eaf3',
                  paddingX: 2,
                  paddingY: 1,
                  marginY: 1,
                }}
                onClick={() => {
                  changeTemplateId(5);
                  setCurrentSubject(subjectId5);
                  setCurrentBody(bodyId5);
                }}
              >
                <Typography variant="subtitle2">
                  Appointment rejected
                </Typography>
                <Switch size="small" defaultChecked sx={{ marginLeft: 2 }} />
              </Grid>
              <Grid
                className={templateId == 6 ? 'notificationSelected' : ''}
                item
                xs={12}
                sx={{
                  color: '#828F9A',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  border: '1px solid #e3eaf3',
                  paddingX: 2,
                  paddingY: 1,
                  marginY: 1,
                }}
                onClick={() => {
                  changeTemplateId(6);
                  setCurrentSubject(subjectId5);
                  setCurrentBody(bodyId5);
                }}
              >
                <Typography variant="subtitle2">
                  Appointment canceled
                </Typography>
                <Switch size="small" defaultChecked sx={{ marginLeft: 2 }} />
              </Grid>
              <Grid
                className={templateId == 7 ? 'notificationSelected' : ''}
                item
                xs={12}
                sx={{
                  color: '#828F9A',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  border: '1px solid #e3eaf3',
                  paddingX: 2,
                  paddingY: 1,
                  marginY: 1,
                }}
                onClick={() => {
                  changeTemplateId(7);
                  setCurrentSubject(subjectId5);
                  setCurrentBody(bodyId5);
                }}
              >
                <Typography variant="subtitle2">
                  Reminder before appointment
                </Typography>
                <Switch size="small" defaultChecked sx={{ marginLeft: 2 }} />
              </Grid>
              <Grid
                className={templateId == 8 ? 'notificationSelected' : ''}
                item
                xs={12}
                sx={{
                  color: '#828F9A',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  border: '1px solid #e3eaf3',
                  paddingX: 2,
                  paddingY: 1,
                  marginY: 1,
                }}
                onClick={() => {
                  changeTemplateId(8);
                  setCurrentSubject(subjectId5);
                  setCurrentBody(bodyId5);
                }}
              >
                <Typography variant="subtitle2">
                  Reminder after appointment
                </Typography>
                <Switch size="small" defaultChecked sx={{ marginLeft: 2 }} />
              </Grid>
              <Grid
                className={templateId == 9 ? 'notificationSelected' : ''}
                item
                xs={12}
                sx={{
                  color: '#828F9A',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  border: '1px solid #e3eaf3',
                  paddingX: 2,
                  paddingY: 1,
                  marginY: 1,
                }}
                onClick={() => {
                  changeTemplateId(9);
                  setCurrentSubject(subjectId5);
                  setCurrentBody(bodyId5);
                }}
              >
                <Typography variant="subtitle2">
                  Access to Customer Panel
                </Typography>
                <Switch size="small" defaultChecked sx={{ marginLeft: 2 }} />
              </Grid>
            </Grid>
          </Grid>
          {/* -----Editor------------- */}
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              paddingX: { xs: 0, sm: 2 },
              boxShadow: '0 0 30px 0 rgb(0 0 0 / 5%)',
            }}
          >
            <Grid
              sx={{ marginTop: { xs: 2, sm: 0 }, backgroundColor: 'white' }}
            >
              <Grid
                item
                xs={12}
                sx={{ borderBottom: '1px solid lightgrey', paddingX: 2 }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ color: '#292D32', fontWeight: '600', paddingY: 2 }}
                >
                  {templateId
                    ? templateHeadings[templateId - 1]
                    : templateHeadings[0]}
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sx={{ overflowX: 'hidden', paddingX: 2 }}
              >
                <Grid item xs={12} sx={{ marginTop: 3 }}>
                  <Typography variant="subtitle2" sx={{ marginBottom: 1 }}>
                    Email Subject
                  </Typography>
                  <input
                    style={{
                      width: '100%',
                      border: '1px solid #E3EAF3',
                      height: '46px',
                      fontSize: '14px',
                      color: '#292D32',
                      outline: 'none',
                      borderRadius: '2px',
                    }}
                    value={currentSubject}
                    onChange={updateSubject}
                  ></input>
                </Grid>
                <Grid>
                  <Typography
                    variant="subtitle2"
                    sx={{ paddingTop: 3, paddingBottom: 1 }}
                  >
                    Email Body
                  </Typography>
                  <QuillEditor
                    quillRef={quillRef}
                    onChange={({ html }) => {
                      setQuillHtml(html);
                      setIsSaveBar(true);
                    }}
                    onBlur={(prevSelection) => {
                      setQuillRange(prevSelection);
                    }}
                    value={quillHtml}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* --------Right Side----------------- */}
          <Grid
            item
            xs={12}
            sm={3}
            sx={{
              backgroundColor: 'white !important',
              boxShadow: '0 0 30px 0 rgb(0 0 0 / 5%)',
            }}
          >
            <Grid
              item
              xs={12}
              sx={{ borderBottom: '1px solid lightgrey', paddingX: 2 }}
            >
              <Typography
                variant="subtitle2"
                sx={{ color: '#292D32', fontWeight: '600', paddingY: 2 }}
              >
                SHORT TAGS
              </Typography>
            </Grid>

            <Grid sx={{ paddingX: 2, overflowY: 'scroll', height: 550 }}>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    color: '#6c70dc',
                    fontSize: '1rem',
                    paddingTop: 2,
                    paddingBottom: 1,
                  }}
                >
                  Appointment Info:
                </Typography>
              </Grid>
              {appointmentTags.map((tag) => (
                <Grid
                  item
                  xs={12}
                  onClick={() => {
                    quillRef.current.editor.insertText(quillRange, tag);
                  }}
                >
                  <Typography
                    sx={{
                      color: '#9ba6af',
                      fontSize: '.875rem',
                      paddingTop: 2,
                      paddingBottom: 1,
                      cursor: 'pointer',
                      ':hover': { color: 'black' },
                    }}
                  >
                    {tag}
                  </Typography>
                </Grid>
              ))}
              <Grid item xs={12}>
                <Typography
                  sx={{
                    color: '#6c70dc',
                    fontSize: '1rem',
                    paddingTop: 2,
                    paddingBottom: 1,
                  }}
                >
                  Service Info:
                </Typography>
              </Grid>
              {serviceTags.map((tag) => (
                <Grid
                  item
                  xs={12}
                  onClick={() => {
                    quillRef.current.editor.insertText(quillRange, tag);
                  }}
                >
                  <Typography
                    sx={{
                      color: '#9ba6af',
                      fontSize: '.875rem',
                      paddingTop: 2,
                      paddingBottom: 1,
                      cursor: 'pointer',
                      ':hover': { color: 'black' },
                    }}
                  >
                    {tag}
                  </Typography>
                </Grid>
              ))}
              <Grid item xs={12}>
                <Typography
                  sx={{
                    color: '#6c70dc',
                    fontSize: '1rem',
                    paddingTop: 2,
                    paddingBottom: 1,
                  }}
                >
                  Customer Info:
                </Typography>
              </Grid>
              {customerTags.map((tag) => (
                <Grid
                  item
                  xs={12}
                  onClick={() => {
                    quillRef.current.editor.insertText(quillRange, tag);
                  }}
                >
                  <Typography
                    sx={{
                      color: '#9ba6af',
                      fontSize: '.875rem',
                      paddingTop: 2,
                      paddingBottom: 1,
                      cursor: 'pointer',
                      ':hover': { color: 'black' },
                    }}
                  >
                    {tag}
                  </Typography>
                </Grid>
              ))}
              <Grid item xs={12}>
                <Typography
                  sx={{
                    color: '#6c70dc',
                    fontSize: '1rem',
                    paddingTop: 2,
                    paddingBottom: 1,
                  }}
                >
                  Staff Info:
                </Typography>
              </Grid>
              {staffTags.map((tag) => (
                <Grid
                  item
                  xs={12}
                  onClick={() => {
                    quillRef.current.editor.insertText(quillRange, tag);
                  }}
                >
                  <Typography
                    sx={{
                      color: '#9ba6af',
                      fontSize: '.875rem',
                      paddingTop: 2,
                      paddingBottom: 1,
                      cursor: 'pointer',
                      ':hover': { color: 'black' },
                    }}
                  >
                    {tag}
                  </Typography>
                </Grid>
              ))}
              <Grid item xs={12}>
                <Typography
                  sx={{
                    color: '#6c70dc',
                    fontSize: '1rem',
                    paddingTop: 2,
                    paddingBottom: 1,
                  }}
                >
                  Location Info:
                </Typography>
              </Grid>
              {locationTags.map((tag) => (
                <Grid
                  item
                  xs={12}
                  onClick={() => {
                    quillRef.current.editor.insertText(quillRange, tag);
                  }}
                >
                  <Typography
                    sx={{
                      color: '#9ba6af',
                      fontSize: '.875rem',
                      paddingTop: 2,
                      paddingBottom: 1,
                      cursor: 'pointer',
                      ':hover': { color: 'black' },
                    }}
                  >
                    {tag}
                  </Typography>
                </Grid>
              ))}
              <Grid item xs={12}>
                <Typography
                  sx={{
                    color: '#6c70dc',
                    fontSize: '1rem',
                    paddingTop: 2,
                    paddingBottom: 1,
                  }}
                >
                  Company Info:
                </Typography>
              </Grid>
              {companyTags.map((tag) => (
                <Grid
                  item
                  xs={12}
                  onClick={() => {
                    quillRef.current.editor.insertText(quillRange, tag);
                  }}
                >
                  <Typography
                    sx={{
                      color: '#9ba6af',
                      fontSize: '.875rem',
                      paddingTop: 2,
                      paddingBottom: 1,
                      cursor: 'pointer',
                      ':hover': { color: 'black' },
                    }}
                  >
                    {tag}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SaveSlideBar
        handleCancel={handleCancel}
        handleSave={handleSave}
        isSaveBar={isSaveBar}
        setIsSaveBar={setIsSaveBar}
        type={'NOTIFICATIONS'}
      />
    </>
  );
};

export default Notification;
