const isPlural = (time) => (time > 1 ? 's' : '');

const displaySection = (time, section) => (time > 0 ? section : '');

export const durationFormater = (duration) => {
  const days = duration / 1440;

  const displayedDays = Math.floor(days);

  const leftoverMinutes = duration % 1440;

  const hours = leftoverMinutes / 60;

  const displayedHours = Math.floor(hours);

  const minutes = duration % 60;

  // Construct and return a string representing the conversion result
  return `${displaySection(
    displayedDays,
    `${displayedDays} day${isPlural(displayedDays)}`
  )} ${displaySection(
    displayedHours,
    `${displayedHours} h${isPlural(displayedHours)}`
  )} ${displaySection(minutes, `${minutes} min${isPlural(minutes)}`)}`;
};
