import React, { useCallback, useEffect, useState } from 'react';
import Drawer from '../../components/Drawer';
import DrawerFooter from '../../components/DrawerFooter';
import Grid from '@mui/material/Grid';
import StaffTab from './Tabs';
import { useMediaQuery, useTheme } from '@mui/material';
import { addStaff, editStaffById, getStaffById } from '../../Api/tenant/staff';
import { addUser, editUserById } from '../../Api/common/users';
import CustomTextButton from '../../components/CustomButtons/CustomTextButton';
import { uniqueId } from 'lodash';
import { useSelector } from 'react-redux';
import { selectUser } from '../../redux/slices/user.slice.js';
import { getServiceList } from '../../Api/tenant/services';
import { getLocationList } from '../../Api/tenant/locations';

/*
dayObj
  {
    id: 1,
    day: 'Sunday',
    to: '',
    from: '',
    dayOff: false,
    breaks: [{
      id: 1,
      breakFrom: "",
      breakTo: ""
  }]
  }

*/

const dayList = [
  {
    id: uniqueId('day-info-'),
    day: 'Sunday',
    from: '01:00',
    to: '02:30',
    dayOff: false,
    breaks: [],
  },
  {
    id: uniqueId('day-info-'),
    day: 'Monday',
    from: '01:00',
    to: '02:30',
    dayOff: false,
    breaks: [],
  },
  {
    id: uniqueId('day-info-'),
    day: 'Tuesday',
    from: '01:00',
    to: '02:30',
    dayOff: false,
    breaks: [],
  },
  {
    id: uniqueId('day-info-'),
    day: 'Wednesday',
    from: '01:00',
    to: '02:30',
    dayOff: false,
    breaks: [],
  },
  {
    id: uniqueId('day-info-'),
    day: 'Thursday',
    from: '01:00',
    to: '02:30',
    dayOff: false,
    breaks: [],
  },
  {
    id: uniqueId('day-info-'),
    day: 'Friday',
    from: '01:00',
    to: '02:30',
    dayOff: false,
    breaks: [],
  },
  {
    id: uniqueId('day-info-'),
    day: 'Saturday',
    from: '01:00',
    to: '02:30',
    dayOff: false,
    breaks: [],
  },
];

const requiredFields = ['firstName', 'lastName', 'email'];

const AddStaff = ({
  toggleAddCouponDrawer,
  drawerType,
  drawerData = null,
  handelHide,
  fetchStaff,
  rowCount,
}) => {
  const { user, isUserLoading } = useSelector(selectUser);
  // staff related
  const [staffData, setStaffData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    active: true,
    type: 'staff',
    profession: '',
    note: '',
  });
  const [specialDays, setSpecialDays] = useState([]);
  const [holidays, setHolidays] = useState([]);

  //  user related
  const [image, setImage] = useState(null);

  const [spts, setSpts] = useState(false);
  // timesheet related
  const [timesheets, setTimesheets] = useState(dayList);

  // linked locations - location tab
  const [locations, setLocations] = useState([]);

  // linked services - service tab
  const [services, setServices] = useState([]);

  // error handling
  const [errors, setErrors] = useState({});

  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('sm'));

  // API
  const fetchStaffData = useCallback(async () => {
    try {
      const response = await getStaffById(drawerData.id);
      if (response.status === 200) {
        const result = response.data;
        console.log(result);
        setTimesheets(result.timesheets);
        setHolidays(result?.holidays || []);
        setSpecialDays(result.specialDays);
        setSpts(result.spts);
        setStaffData({ ...staffData, ...result, ...result.User });
        setServices(result.services);
        setLocations(result.locations);
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    if (drawerType === 'edit' && drawerData?.id) {
      fetchStaffData();
    }

    // preselect locations and services
    if (drawerType === 'add') {
      getServiceList(1000, 1).then((res) => {
        if (res.data?.rows) {
          setServices(res.data?.rows.map((ser) => ({ id: ser.id })));
        }
      });
      getLocationList(1000, 1).then((res) => {
        if (res.data?.rows) {
          setLocations(res.data?.rows.map((loc) => ({ id: loc.id })));
        }
      });
    }
  }, [drawerType, fetchStaffData]);

  const handleAddStaff = () => {
    // error handling
    let err = {};
    let data = { ...staffData };

    for (const key in data) {
      if (!data[key] && requiredFields.includes(key)) {
        err = { ...err, [key]: true };
        console.log('s', data[key], data);
      }
      if (
        Array.isArray(data[key]) &&
        data[key].length === 0 &&
        requiredFields.includes(key)
      ) {
        console.log(data[key]);
        err = { ...err, [key]: true };
      }
    }
    setErrors({ ...err });

    // request logic
    const formdata = new FormData();

    for (const key in staffData) {
      if (key !== 'timesheets' && key !== 'services' && key !== 'locations') {
        formdata.append(key, staffData[key]);
      }
    }

    formdata.append('image', image);

    locations.map((location) => {
      formdata.append('locations', JSON.stringify({ id: location.id }));
    });

    services.map((service) => {
      formdata.append('services', JSON.stringify({ id: service.id }));
    });

    timesheets.map((timesheet) => {
      formdata.append('timesheets', JSON.stringify(timesheet));
    });

    specialDays.map((specialDay) => {
      formdata.append('specialDays', JSON.stringify(specialDay));
    });

    if (Object.keys(err).length === 0) {
      addStaff(formdata).then((res) => {
        toggleAddCouponDrawer();
        fetchStaff();
      });
    }
  };

  const handleEditStaff = () => {
    const newTimesheets = timesheets.map((timesheet) => {
      timesheet.breaks = timesheet.breaks.map((breakTime) => {
        if (breakTime?.id?.toString().includes('breaks')) delete breakTime.id;
        return breakTime;
      });
      return timesheet;
    });

    const newSpecialDays = specialDays.map((specialDay) => {
      if (specialDay?.id?.toString().includes('special-day'))
        delete specialDay.id;

      specialDay.breaks = specialDay.breaks.map((breakTime) => {
        if (breakTime?.id?.toString().includes('breaks')) delete breakTime.id;
        return breakTime;
      });

      return specialDay;
    });

    const formdata = new FormData();

    for (const key in staffData) {
      if (key !== 'timesheets' && key !== 'services' && key !== 'locations') {
        formdata.append(key, staffData[key]);
      }
    }

    locations.map((location) => {
      formdata.append('locations', JSON.stringify({ id: location.id }));
    });

    services.map((service) => {
      formdata.append('services', JSON.stringify({ id: service.id }));
    });

    newTimesheets.map((timesheet) => {
      formdata.append('timesheets', JSON.stringify(timesheet));
    });

    newSpecialDays.map((specialDay) => {
      formdata.append('specialDays', JSON.stringify(specialDay));
    });

    formdata.append('image', image);
    formdata.append('userId', user.id);

    editStaffById(formdata, drawerData.id).then((res) => {
      toggleAddCouponDrawer();
      fetchStaff();
    });
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={2}>
        <Drawer
          type={drawerType === 'add' ? 'ADD' : 'EDIT'}
          title={drawerType === 'add' ? 'Add Staff' : 'Edit Staff'}
          toggleDrawer={toggleAddCouponDrawer}
        >
          {/* -----------FORM-------- */}
          <Grid sx={{ height: '100%', overflowY: 'auto', width: '100%' }}>
            {(staffData.firstName || drawerType == 'add') && (
              <StaffTab
                // TODO:
                // !------------------TODO: MAKE THEM ONE STATE---------------------
                // * Already did the staff details
                staffData={staffData}
                setStaffData={setStaffData}
                userData={staffData}
                setUserData={setStaffData}
                drawerData={staffData}
                // !---------------------------------------------------------------
                image={image}
                setImage={setImage}
                holidays={holidays}
                setHolidays={setHolidays}
                specialDays={specialDays}
                setSpecialDays={setSpecialDays}
                timesheets={timesheets}
                setTimesheets={setTimesheets}
                spts={spts}
                setSpts={setSpts}
                drawerType={drawerType}
                errors={errors}
                setErrors={setErrors}
                locations={locations}
                setLocations={setLocations}
                services={services}
                setServices={setServices}
              />
            )}
          </Grid>

          {/* -----Footer--------*/}
          <DrawerFooter>
            <>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: isSmallScreen ? 'flex-start' : 'flex-end',
                }}
              >
                {drawerType === 'edit' && ''}
              </div>
              <div
                style={{
                  display: 'flex',
                  gap: '15px',
                  justifyContent: isSmallScreen ? 'space-between' : 'flex-end',
                  width: isSmallScreen ? '100%' : 'auto',
                  alignItems: 'center',
                }}
              >
                <CustomTextButton
                  variant="contained"
                  size="large"
                  onClick={() => {
                    toggleAddCouponDrawer();
                  }}
                >
                  Cancel
                </CustomTextButton>
                <CustomTextButton
                  type="blue"
                  variant="contained"
                  size="large"
                  onClick={() => {
                    if (drawerType === 'add') {
                      handleAddStaff();
                    } else {
                      handleEditStaff();
                    }
                  }}
                >
                  {drawerType === 'add' ? 'Save' : 'Save'}
                </CustomTextButton>
              </div>
            </>
          </DrawerFooter>
        </Drawer>
      </Grid>
    </Grid>
  );
};

export default AddStaff;
