import { useState, useCallback } from 'react';
import moment from 'moment/moment';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UseDateFormat {
  getDateFormat: (date: string) => void;
}

export function useDateFormat(format?: string): UseDateFormat {
  const getDateFormat = (date: string) => {
    const dateObj = new Date(date);
    const formatedDate = moment(dateObj).format(format || 'YYYY-MM-DD');

    return formatedDate;
  };
  return { getDateFormat };
}

export default useDateFormat;
