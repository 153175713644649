import React, { useState } from "react";
import DrawerFooter from "../../components/DrawerFooter";
import Drawer from "../../components/Drawer";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { paymentTableData } from "../../utils/PaymentData";
import {
  Avatar,
  Box,
  Icon,
  MenuItem,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const PaymentInfo = ({
  togglePaymentInfoDrawer,
  drawerType,
  drawerData = null,
}) => {
  const [data, setData] = useState(paymentTableData.coloum);
  const user = data.find((info) => info.id === drawerData);
  const [isEdit, setIsEdit] = useState(false);
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down("sm"));
  // const matches = useMediaQuery(useTheme().breakpoints.up("sm"));

  const CalcTotal = (service, vat, discount) => {
    return service + vat - discount;
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={2}>
        <Drawer
          title={"Payment"}
          type="PAYMENT"
          toggleDrawer={togglePaymentInfoDrawer}
        >
          {/* -----------FORM----- */}
          <Grid sx={{ height: "100%", overflowY: "auto", width: "100%" }}>
            {isEdit ? (
              <>
                <Box
                  container
                  sx={{
                    height: "89%",
                    margin: "30px",
                    padding: "10px",
                  }}
                >
                  <Box marginBottom="10px">
                    <p style={{ fontWeight: "500", margin: "10px 0px" }}>
                      Service Price <span style={{ color: "red" }}>*</span>
                    </p>
                    <TextField
                      variant="outlined"
                      sx={{ width: "100%" }}
                      TextField
                      value={user.servicePrice}
                      inputProps={{
                        style: {
                          height: "7px",
                        },
                      }}
                    />
                  </Box>
                  <Box marginBottom="10px">
                    <p style={{ fontWeight: "500", margin: "10px 0px" }}>
                      Discount <span style={{ color: "red" }}>*</span>
                    </p>
                    <TextField
                      variant="outlined"
                      sx={{ width: "100%" }}
                      value={user.discount}
                      inputProps={{
                        style: {
                          height: "7px",
                        },
                      }}
                    />
                  </Box>
                  <Box marginBottom="10px">
                    <p style={{ fontWeight: "500", margin: "10px 0px" }}>
                      VAT<span style={{ color: "red" }}>*</span>
                    </p>
                    <TextField
                      variant="outlined"
                      sx={{ width: "100%" }}
                      value={user.vat}
                      inputProps={{
                        style: {
                          height: "7px",
                        },
                      }}
                    />
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    sx={{ flexDirection: { xs: "column", md: "row" } }}
                  >
                    <Box
                      marginBottom="10px"
                      width="100%"
                      sx={{ width: { xs: "100%", md: "49%" } }}
                    >
                      <p style={{ fontWeight: "500", margin: "10px 0px" }}>
                        Paid Amount <span style={{ color: "red" }}>*</span>
                      </p>
                      <TextField
                        variant="outlined"
                        sx={{ width: "100%" }}
                        inputProps={{
                          style: {
                            height: "7px",
                          },
                        }}
                      />
                    </Box>
                    <Box
                      marginBottom="10px"
                      sx={{ width: { xs: "100%", md: "49%" } }}
                    >
                      <p style={{ fontWeight: "500", margin: "10px 0px" }}>
                        Payment Status <span style={{ color: "red" }}>*</span>
                      </p>
                      <TextField
                        variant="outlined"
                        sx={{ width: "100%" }}
                        inputProps={{
                          style: {
                            padding: "9px",
                          },
                        }}
                        value="Paid"
                        onChange=""
                        name="Status"
                        select
                        size="small"
                      >
                        <MenuItem value="Paid">Paid</MenuItem>
                        <MenuItem value="Not Paid">Not Paid</MenuItem>
                        <MenuItem nalue="Pending">Pending</MenuItem>
                        <MenuItem nalue="Pending">Canceled</MenuItem>
                      </TextField>
                    </Box>
                  </Box>
                </Box>
              </>
            ) : (
              <>
                <Grid
                  sx={{
                    boxShadow: 3,
                    margin: "30px",
                    padding: "30px",
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                  xs={12}
                >
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                      display: {
                        xs: "flex",
                        textAlign: "center",
                      },
                    }}
                  >
                    <Grid xs={4} md={4}>
                      <Typography
                        variant="h3"
                        fontSize="16px"
                        fontWeight="500"
                        marginBottom="5px"
                      >
                        Staff
                      </Typography>
                    </Grid>
                    <Typography
                      variant="h3"
                      fontSize="14px"
                      marginBottom="5px"
                      color="#6c70dc"
                    >
                      {user.staff}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                      display: { xs: "flex" },
                      textAlign: "center",
                    }}
                  >
                    <Grid xs={4} md={4}>
                      <Typography
                        variant="h3"
                        fontSize="16px"
                        fontWeight="500"
                        marginBottom="5px"
                      >
                        Location
                      </Typography>
                    </Grid>
                    <Typography
                      variant="h3"
                      marginBottom="5px"
                      fontSize="14px"
                      color="#828F9A"
                    >
                      {user.location}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                      display: { xs: "flex" },
                      textAlign: "center",
                    }}
                  >
                    <Grid xs={4} md={4}>
                      <Typography
                        variant="h3"
                        fontSize="16px"
                        fontWeight="500"
                        marginBottom="5px"
                      >
                        Service
                      </Typography>
                    </Grid>
                    <Typography
                      variant="h3"
                      marginBottom="5px"
                      fontSize="14px"
                      color="#828F9A"
                    >
                      {user.service}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                      display: { xs: "flex" },
                      textAlign: "center",
                    }}
                  >
                    <Grid xs={4} md={4}>
                      <Typography
                        variant="h3"
                        fontSize="16px"
                        fontWeight="500"
                        marginBottom="5px"
                      >
                        Date
                      </Typography>
                    </Grid>
                    <Typography variant="h3" fontSize="14px" color="#828F9A">
                      {user.appointDate}
                    </Typography>
                  </Grid>
                </Grid>
                <Box sx={{ marginX: "30px", boxShadow: 2 }}>
                  <Box
                    sx={{
                      display: "flex",
                      color: "#fff",
                      background: "#adbfc7",
                      padding: "10px 0px 10px 20px",

                      textTransform: "uppercase",
                    }}
                  >
                    <Typography flex={2} fontSize="12px" fontWeight="bold">
                      Customers
                    </Typography>
                    <Typography flex={1} fontSize="12px" fontWeight="bold">
                      Method
                    </Typography>
                    <Typography flex={1} fontSize="12px" fontWeight="bold">
                      Status
                    </Typography>
                  </Box>

                  <Box sx={{ padding: "20px", display: "flex" }}>
                    <Box sx={{ flex: 2.5, display: "flex" }}>
                      <Avatar src={user.customer.avatar} />
                      <div style={{ marginLeft: "7px" }}>
                        <Typography fontSize="14px" color="#292D32">
                          {user.customer.name}
                        </Typography>
                        <Typography fontSize="12px" color="#95A0A9">
                          {user.customer.email}
                        </Typography>
                      </div>
                    </Box>
                    <Typography flex={1.3}>{user.method}</Typography>
                    <Box flex={1}>
                      <Icon
                        sx={{
                          background: "#ddd",
                          color: "white",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <CloseIcon />
                      </Icon>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    minWidth: "240px",
                    border: "1px solid #ddd",
                    margin: "30px",
                    padding: "24px 24px 32px 24px",
                  }}
                >
                  <Typography
                    marginBottom="24px"
                    fontWeight="500"
                    variant="h6"
                    color="#000"
                  >
                    Payment details
                  </Typography>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    marginY="15px"
                  >
                    <Typography variant="p" color="#212529" fontSize="16px">
                      Service price
                    </Typography>
                    <Typography variant="p" fontWeight="500" color="black">
                      ${user.servicePrice}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    marginY="15px"
                  >
                    <Typography variant="p" color="#212529" fontSize="16px">
                      Discount
                    </Typography>
                    <Typography variant="p" fontWeight="500" color="black">
                      ${user.discount}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    marginY="15px"
                  >
                    <Typography variant="p" color="#212529" fontSize="16px">
                      Vat
                    </Typography>
                    <Typography variant="p" fontWeight="500" color="black">
                      ${user.vat}
                    </Typography>
                  </Box>
                  <hr />
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    marginY="15px"
                  >
                    <Typography
                      variant="p"
                      color="#00060c"
                      fontWeight="500"
                      fontSize="16px"
                    >
                      Total
                    </Typography>
                    <Typography
                      variant="p"
                      fontWeight="500"
                      fontSize="16px"
                      color="#000"
                    >
                      ${CalcTotal(user.servicePrice, user.vat, user.discount)}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    marginY="15px"
                  >
                    <Typography
                      variant="p"
                      color="#000"
                      fontWeight="500"
                      fontSize="16px"
                    >
                      Paid
                    </Typography>
                    <Typography
                      variant="p"
                      fontWeight="500"
                      color="#26D090"
                      fontSize="20px"
                    >
                      $
                      {CalcTotal(user.servicePrice, user.vat, user.discount) -
                        user.dueAmount}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    marginY="15px"
                  >
                    <Typography
                      variant="p"
                      fontWeight="500"
                      color="#000000"
                      fontSize="16px"
                    >
                      Due
                    </Typography>
                    <Typography
                      variant="p"
                      fontWeight="500"
                      color="#FB3E6E"
                      fontSize="20px"
                    >
                      ${user.dueAmount}
                    </Typography>
                  </Box>
                </Box>
              </>
            )}
          </Grid>
          {/* footer */}
          <DrawerFooter>
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: isSmallScreen ? "flex-start" : "flex-end",
                }}
              >
                {!isEdit && (
                  <Button
                    variant="contained"
                    size="large"
                    sx={{
                      width: { xs: "100%", sm: "auto" },
                      background: "#53d56c",
                      marginBottom: { xs: 1, sm: 0 },
                    }}
                  >
                    Complete Payment
                  </Button>
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: isSmallScreen ? "space-between" : "flex-end",
                  width: isSmallScreen ? "100%" : "auto",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    width: isSmallScreen ? "48%" : "7rem",
                    marginLeft: { xs: 0, sm: 2 },
                  }}
                  onClick={() => setIsEdit(true)}
                >
                  {isEdit ? "Save" : "Edit"}
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    width: isSmallScreen ? "48%" : "7rem",
                    marginLeft: { xs: 1, sm: 2 },
                    backgroundColor: "WHITE",
                    color: "GREY",
                    ":hover": { backgroundColor: "GREY", color: "WHITE" },
                  }}
                  onClick={() => {
                    setIsEdit(false);
                    togglePaymentInfoDrawer();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </>
          </DrawerFooter>
        </Drawer>
      </Grid>
    </Grid>
  );
};

export default PaymentInfo;
