import { Grid } from '@mui/material';
import Confirmation from './Confirmation';
import DateAndTime from './DateAndTime';
import Finish from './Finish';
import Information from './Information';
import Location from './Location';
import NumberOfPeople from './NumberOfPeople';
import Payment from './Payment';
import Service from './Service';
import ServiceExtras from './ServiceExtras';
import Staff from './Staff';

const BookingStepDisplay = ({
  setIsSaveBar,
  page,
  hideAddressOfLocation,
  setHideAddressOfLocation,
  collapseServiceUnderACategory,
  setCollapseServiceUnderACategory,
  ifServiceDoesntHaveExtraSkipStep,
  setIfServiceDoesntHaveExtraSkipStep,
  timeFormatForBookingForm,
  setTimeFormatForBookingForm,
  startTheBookingCalendarFrom,
  setStartTheBookingCalendarForm,
  hideTheNumberOfAvailableSlots,
  setHideTheNumberOfAvailableSlots,
  addMaxNumberOfPeopleCustomerCanBook,
  setAddMaxNumberOfPeopleCustomerCanBook,
  termsAndCondition,
  setTermsAndCondition,
  addUrlLink,
  setAddUrlLink,
  letUserAddInformationToAppointment,
  setLetUserAddInformationToAppointment,
  letcustomerPayStripe,
  setLetCustomerPayStripe,
  letcustomerPayPaypal,
  setLetCustomerPayPaypal,
  letcustomerPayLocal,
  setLetCustomerPayLocal,
  letcustomerPayGiftcard,
  setLetCustomerPayGiftcard,
  footerTextPerStaff,
  setFooterTextPerStaff,
  enableAnyStaffOption,
  setEnableAnyStaffOption,
  autoAssignmentRule,
  setAutoAssignmentRule,
  urlOfFinishBookingButton,
  setUrlOfFinishBookingButton,
  hideTheAddToGoogleCalendarButton,
  setHideTheAddToGoogleCalendarButton,
  hideTheStartNewBookingButton,
  setHideTheStartNewBookingButton,
  hideConfirmationNumber,
  setHideConfirmationNumber,
  startingConfirmationNumber,
  setStartingConfirmationNumber,
  hideGiftcardSection,
  setHideGiftcardSection,
  doNotShowDiscountRowIfDiscountNotAdded,
  setDoNotShowDiscountRowIfDiscountNotAdded,
  hidePriceSection,
  setHidePriceSection,
  hideCouponSection,
  setHideCouponSection,
  separateFirstAndLastNameInputs,
  setSeparateFirstAndLastNameInputs,
  setEmailAsRequiredField,
  setSetEmailAsRequiredField,
  setPhoneNumberAsRequiredField,
  setSetPhoneNumberAsRequiredField,
  defaultPhoneCountryCode,
  setDefaultPhoneCountryCode,
}) => {
  return (
    <Grid container xs={12} sx={{ height: 'fit-content' }}>
      {page === 'Location' && (
        <Location
          hideAddressOfLocation={hideAddressOfLocation}
          setHideAddressOfLocation={setHideAddressOfLocation}
          setIsSaveBar={setIsSaveBar}
        />
      )}
      {page === 'Service' && (
        <Service
          collapseServiceUnderACategory={collapseServiceUnderACategory}
          setCollapseServiceUnderACategory={setCollapseServiceUnderACategory}
          setIsSaveBar={setIsSaveBar}
        />
      )}
      {page === 'Service Extras' && (
        <ServiceExtras
          ifServiceDoesntHaveExtraSkipStep={ifServiceDoesntHaveExtraSkipStep}
          setIfServiceDoesntHaveExtraSkipStep={
            setIfServiceDoesntHaveExtraSkipStep
          }
          setIsSaveBar={setIsSaveBar}
        />
      )}
      {page === 'Date & Time' && (
        <DateAndTime
          timeFormatForBookingForm={timeFormatForBookingForm}
          setTimeFormatForBookingForm={setTimeFormatForBookingForm}
          startTheBookingCalendarFrom={startTheBookingCalendarFrom}
          setStartTheBookingCalendarForm={setStartTheBookingCalendarForm}
          hideTheNumberOfAvailableSlots={hideTheNumberOfAvailableSlots}
          setHideTheNumberOfAvailableSlots={setHideTheNumberOfAvailableSlots}
          setIsSaveBar={setIsSaveBar}
        />
      )}
      {page === 'Number of people' && (
        <NumberOfPeople
          addMaxNumberOfPeopleCustomerCanBook={
            addMaxNumberOfPeopleCustomerCanBook
          }
          setAddMaxNumberOfPeopleCustomerCanBook={
            setAddMaxNumberOfPeopleCustomerCanBook
          }
          setIsSaveBar={setIsSaveBar}
        />
      )}
      {page === 'Payment' && (
        <Payment
          termsAndCondition={termsAndCondition}
          setTermsAndCondition={setTermsAndCondition}
          addUrlLink={addUrlLink}
          setAddUrlLink={setAddUrlLink}
          letUserAddInformationToAppointment={
            letUserAddInformationToAppointment
          }
          setLetUserAddInformationToAppointment={
            setLetUserAddInformationToAppointment
          }
          letcustomerPayStripe={letcustomerPayStripe}
          setLetCustomerPayStripe={setLetCustomerPayStripe}
          letcustomerPayPaypal={letcustomerPayPaypal}
          setLetCustomerPayPaypal={setLetCustomerPayPaypal}
          letcustomerPayLocal={letcustomerPayLocal}
          setLetCustomerPayLocal={setLetCustomerPayLocal}
          letcustomerPayGiftcard={letcustomerPayGiftcard}
          setLetCustomerPayGiftcard={setLetCustomerPayGiftcard}
          setIsSaveBar={setIsSaveBar}
        />
      )}
      {page === 'Staff' && (
        <Staff
          footerTextPerStaff={footerTextPerStaff}
          setFooterTextPerStaff={setFooterTextPerStaff}
          enableAnyStaffOption={enableAnyStaffOption}
          setEnableAnyStaffOption={setEnableAnyStaffOption}
          autoAssignmentRule={autoAssignmentRule}
          setAutoAssignmentRule={setAutoAssignmentRule}
          setIsSaveBar={setIsSaveBar}
        />
      )}
      {page === 'Information' && (
        <Information
          separateFirstAndLastNameInputs={separateFirstAndLastNameInputs}
          setSeparateFirstAndLastNameInputs={setSeparateFirstAndLastNameInputs}
          setEmailAsRequiredField={setEmailAsRequiredField}
          setSetEmailAsRequiredField={setSetEmailAsRequiredField}
          setPhoneNumberAsRequiredField={setPhoneNumberAsRequiredField}
          setSetPhoneNumberAsRequiredField={setSetPhoneNumberAsRequiredField}
          defaultPhoneCountryCode={defaultPhoneCountryCode}
          setDefaultPhoneCountryCode={setDefaultPhoneCountryCode}
          setIsSaveBar={setIsSaveBar}
        />
      )}
      {page === 'Confirmation' && (
        <Confirmation
          hideGiftcardSection={hideGiftcardSection}
          setHideGiftcardSection={setHideGiftcardSection}
          doNotShowDiscountRowIfDiscountNotAdded={
            doNotShowDiscountRowIfDiscountNotAdded
          }
          setDoNotShowDiscountRowIfDiscountNotAdded={
            setDoNotShowDiscountRowIfDiscountNotAdded
          }
          hidePriceSection={hidePriceSection}
          setHidePriceSection={setHidePriceSection}
          hideCouponSection={hideCouponSection}
          setHideCouponSection={setHideCouponSection}
          setIsSaveBar={setIsSaveBar}
        />
      )}
      {page === 'Finish' && (
        <Finish
          urlOfFinishBookingButton={urlOfFinishBookingButton}
          setUrlOfFinishBookingButton={setUrlOfFinishBookingButton}
          hideTheAddToGoogleCalendarButton={hideTheAddToGoogleCalendarButton}
          setHideTheAddToGoogleCalendarButton={
            setHideTheAddToGoogleCalendarButton
          }
          hideTheStartNewBookingButton={hideTheStartNewBookingButton}
          setHideTheStartNewBookingButton={setHideTheStartNewBookingButton}
          hideConfirmationNumber={hideConfirmationNumber}
          setHideConfirmationNumber={setHideConfirmationNumber}
          startingConfirmationNumber={startingConfirmationNumber}
          setStartingConfirmationNumber={setStartingConfirmationNumber}
          setIsSaveBar={setIsSaveBar}
        />
      )}
    </Grid>
  );
};

export default BookingStepDisplay;
