import AddIcon from '@mui/icons-material/Add';
import classes from './CustomNoOption.module.css';

const CustomNoOption = ({ children, onClick }) => {
  return (
    <div onClick={onClick} className={classes.mainContainer}>
      <span className={classes.text}>{children}</span>
      <AddIcon color="primary" />
    </div>
  );
};

export default CustomNoOption;
