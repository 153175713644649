import { useSelector } from 'react-redux';
import { selectUser } from '../../redux/slices/user.slice.js';

/**
 * A simple wrapper for Module pages
 * @param children 2 sets of children, first one being the main component and second being the upsell frontend
 * @param code module code
 * @returns will return one child based on user access to that module
*  @example <ModuleWrapper code="SER">
             <Services />
             <UpsellService />
           </ModuleWrapper>
 */
function ModuleWrapper({ code, children }) {
  const { user } = useSelector(selectUser);
  const access = user.modules.find((module) => {
    console.log(module);
    return module.code === code;
  });
  return access ? children[0] : children[1];
}

export default ModuleWrapper;
