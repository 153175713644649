import React from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../redux/slices/user.slice.js';
import { MuiTelInput } from 'mui-tel-input';

const CustomMuiTelInput = ({ value, onChange, sx, ...props }) => {
  const { user, isUserLoading } = useSelector(selectUser);

  return (
    !isUserLoading && (
      <MuiTelInput
        placeholder="Enter phone number"
        value={value}
        onChange={onChange}
        {...(user?.country && { defaultCountry: user.country })}
        {...props}
      />
    )
  );
};

export default CustomMuiTelInput;
