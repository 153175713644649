import { Grid, Typography } from "@mui/material"
import CheckIcon from '@mui/icons-material/Check';
import classes from "./InitialGuideRow.module.css"
import { Link } from "react-router-dom";

const InitialGuideRow = ({number, title, isDone, link}) => {

    return(
        <Link className={classes.link} to={link}>
            <Grid className={classes.container}>
                <Grid className={classes.leftSideContainer}>
                    <Grid className={`${classes.numberContainer} ${isDone ? classes.doneNumberContainer : ""}`}>{number}</Grid>
                    <Typography className={classes.title}>{title}</Typography>
                </Grid>

                {
                    isDone && <CheckIcon className={classes.checkIcon} />
                }
            </Grid>
        </Link>
    )
}

export default InitialGuideRow