import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import { SERVER_URL } from "./configs";
import CustomTableFooter from "../../components/CustomTableFooter";
import Modal from "../../components/Modal";
import StatusChip from "../../components/StatusChip";

const Billing = () => {
  const [addBillingDrawer, setAddBillingDrawer] = useState(false);
  const [billings, setBillings] = useState([]);
  const [drawerType, setDrawerType] = useState("add");
  const [drawerData, setDrawerData] = useState(null);
  const [openModal, setOpenModal] = useState({ open: false, type: null });
  const [selectionModel, setSelectionModel] = useState([]);
  const [drawer, setDrawer] = useState(null);

  const toggleAddBillingDrawer = () => {
    setAddBillingDrawer(!addBillingDrawer);
  };

  const fetchBillings = async () => {
    try {
      const response = await axios.post(SERVER_URL + "/get-billing");
      //setBillings(response.data.data);
    } catch (error) {}
  };

  const deleteBilling = async (id) => {
    try {
      const response = await axios.post(SERVER_URL + "/delete-billing", {
        billing_id: id,
      });
      fetchBillings();
    } catch (error) {}
  };
  useEffect(() => {
    fetchBillings();
  }, []);

  const handleOpenModal = () => {
    setOpenModal({ open: false, type: null });
  };

  const handleDeleteOpenModal = () => {
    setOpenModal({ open: true, type: "delete-modal" });
  };

  const toggleUsageHistoryModal = (e) => {
    e.stopPropagation();
    setDrawer((prevState) =>
      prevState !== "usage-history" ? "usage-history" : ""
    );
  };

  const columns = [
    {
      field: "date",
      headerName: "DATE",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "plan",
      headerName: "PLAN",
      minWidth: 180,
      flex: 1,
    },
    {
      field: "amount",
      headerName: "AMOUNT",
      minWidth: 180,
      flex: 1,
    },
    {
      field: "paymentMethod",
      headerName: "PAYMENT METHOD",
      minWidth: 250,
      flex: 1,
    },
    {
      field: "status",
      headerName: "STATUS",
      valueOptions: ["Active", "Inactive"],
      minWidth: 150,
      flex: 1,

      renderCell: (params) => (
        <>
          {params.value === "Active" ? (
            <StatusChip
              text={params.value}
              color={"#EA9B7F"}
              bgColor={"#FBEEE9"}
              rounded={true}
            />
          ) : (
            <StatusChip
              text={params.value}
              color={"#DF3E70"}
              bgColor={"#F9E0E7"}
              rounded={true}
            />
          )}
        </>
      ),
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      type: "actions",
      align: "right",
      getActions: (params) => [
        <GridActionsCellItem
          label="Delete"
          onClick={() => {
            setOpenModal({ open: true, type: "delete-modal" });
            deleteBilling(params.id);
          }}
          showInMenu
        />,
      ],
    },
  ];

  const row = [
    {
      id: 1,
      date: "20-10-2022",
      plan: "Pro",
      amount: "$100.00",
      paymentMethod: "Credit card",
      status: "Active",
    },
    {
      id: 2,
      date: "20-1-2022",
      plan: "Ultimate",
      amount: "$200.00",
      paymentMethod: "Cash",
      status: "Inactive",
    },
  ];
  const [rows, setRows] = useState(row);
  return (
    <Grid
      item
      container
      sx={{
        minWidth: { xs: "100%" },
        paddingLeft: 4,
        paddingRight: 4,
      }}
    >
      {openModal.type === "delete-modal" && (
        <Modal
          handleOpenModal={handleOpenModal}
          type={"DELETE"}
          open={openModal.open}
        />
      )}
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          flexWrap: "wrap",
        }}
      >
        <Typography sx={{ marginTop: 5, marginBottom: 1, fontSize: 28 }}>
          Billings{" "}
          <span
            style={{
              fontSize: "14px",
              backgroundColor: "#ffbb44",
              color: "white",
              minWidth: "25px",
              height: "25px",
              verticalAlign: "middle",
              fontWeight: 500,
              textAlign: "center",
              borderRadius: "10px",
              padding: "5px 9px",
              lineHeight: "18px",
            }}
          >
            1
          </span>
        </Typography>
      </Grid>
      {/* --------------------SEARCH------------------------------- */}
      <Grid
        item
        xs={12}
        sx={{
          marginTop: { xs: "15px" },
          paddingBottom: { xs: "30px" },
          borderBottom: { xs: "1px solid #e3eaf3" },
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
            height: "60px",
            borderRadius: "2px",
            boxShadow: "0 0 30px 0 rgb(0 0 0 / 5%)",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "60px",
            }}
          >
            <SearchIcon sx={{ color: "#b0c1c9", fontSize: "28px" }} />
          </div>
          <input
            className="quickSearch"
            style={{
              height: "100%",
              width: "100%",
              fontSize: "14px",
              boxSizing: "border-box",
              border: "none",
              outline: "none",
              color: "#495057",
            }}
            type="text"
            placeholder="Quick search"
          />
        </div>
      </Grid>
      {/* --------------------TABLE--------------------------------- */}
      <Grid
        container
        item
        justifyContent="space-between"
        sx={{ marginTop: "20px" }}
      >
        <div style={{ width: "100%" }}>
          <DataGrid
            sx={{
              border: 0,
              "& .MuiDataGrid-columnHeaderTitle": {
                fontSize: "12px",
                fontWeight: "900 !important",
              },
              "& .MuiDataGrid-main, .MuiDataGrid-footerContainer": {
                backgroundColor: "white",
                border: "none",
              },
              "& .MuiDataGrid-cell:focus": {
                outline: "none",
              },
              minHeight: "60vh",
            }}
            rows={rows}
            columns={columns}
            autoHeight
            checkboxSelection
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            selectionModel={selectionModel}
            components={{
              Footer: () => (
                <CustomTableFooter
                  selectionModel={selectionModel}
                  handleDeleteOpenModal={handleDeleteOpenModal}
                />
              ),
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default Billing;
