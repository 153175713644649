import { Grid, MenuItem, Select } from '@mui/material'
import React from 'react'

const Coupon = () => {
    return (
        <Grid sx={{ boxShadow: " rgba(149, 157, 165, 0.2) 0px 8px 24px", padding: '2rem' }}>
            <Select fullWidth defaultValue={'none'} size="small">
                <MenuItem value="none">--none--</MenuItem>
                <MenuItem value="coupon50%">COUPON50%</MenuItem>
                <MenuItem value="coupon20$">COUPON20$</MenuItem>
            </Select>
        </Grid>
    )
}

export default Coupon