import React, { useState } from 'react';
import {
  Autocomplete,
  Box,
  FormHelperText,
  Grid,
  Icon,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PersonIcon from '@mui/icons-material/Person';
import AddCustomer from '../../Customer/AddCustomer';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import SelectCategory from '../../../components/AysncAutoComplete/SelectCategory';

const statusData = [
  'Pending Approval',
  'Confirmed',
  'In Progress',
  'Rescheduled',
  'On Hold',
  'Completed',
  'Cancelled',
  'Await Payment',
  'No Show',
  'Rejected',
  'Paid',
];

const AppointmentDetails = ({
  formData,
  setFormData,
  appointmentOptions,
  fetchCustomers,
  errors,
  setErrors,
}) => {
  const [isOpenDatePiker, setIsOpenDatePiker] = useState(false);
  const [addCustomerDrawer, setAddCustomerDrawer] = useState(false);
  const [CustomerdrawerType, setCustomerDrawerType] = useState();

  const toggleAddCustomerDrawer = () => {
    setAddCustomerDrawer(!addCustomerDrawer);
  };

  const handleSelectData = (data, fieldName) => {
    const selectData = { ...formData };
    selectData[fieldName] = data;
    setFormData(selectData);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container>
        {addCustomerDrawer && (
          <AddCustomer
            toggleDrawer={toggleAddCustomerDrawer}
            drawerType={'add'}
            fetchCustomers={fetchCustomers}
          />
        )}
        <Grid container>
          <Grid xs={12} sm={12} sx={{ paddingRight: { sm: 0 }, marginTop: 1 }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">
                Location <span style={{ color: 'red' }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                size="small"
                onChange={(event, data) => handleSelectData(data, 'location')}
                value={formData.location}
                disablePortal
                options={appointmentOptions.locations}
                getOptionLabel={(option) => option.name}
                sx={{
                  marginBottom: '1rem',
                  width: '100%',
                  fontSize: '14px',
                }}
                renderInput={(params) => (
                  //errormessage
                  <TextField
                    {...params}
                    placeholder="Select..."
                    // value={location}
                    error={errors.location}
                    helperText={errors.location ? 'Value is missing' : ' '}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid xs={12} sm={12} sx={{ marginTop: 0 }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">
                Category <span style={{ color: 'red' }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <SelectCategory
                value={formData?.category?.id}
                onChange={(e, v) => {
                  handleSelectData(v, 'category');
                  setErrors({ ...errors, category: false });
                }}
              />
            </Grid>
          </Grid>
          <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 }, marginTop: 3 }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">
                Service <span style={{ color: 'red' }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                size="small"
                disablePortal
                onChange={(event, data) => handleSelectData(data, 'service')}
                value={formData.service}
                options={appointmentOptions.services.map((service) => service)}
                getOptionLabel={(option) => option.name}
                sx={{
                  marginBottom: '1rem',
                  width: '100%',
                  fontSize: '14px',
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select..."
                    error={errors.service}
                    helperText={errors.service ? 'Value is missing' : ' '}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            xs={12}
            sm={6}
            sx={{ paddingLeft: { sm: 1 }, marginTop: { xs: 0, sm: 3 } }}
          >
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">
                Staff <span style={{ color: 'red' }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                size="small"
                disablePortal
                onChange={(event, data) => handleSelectData(data, 'staff')}
                value={formData.staff}
                getOptionLabel={(option) =>
                  option?.User?.firstName || 'no name available'
                }
                options={appointmentOptions.staff.map((staff) => staff)}
                sx={{
                  marginBottom: '1rem',
                  width: '100%',
                  fontSize: '14px',
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select..."
                    error={errors.staff}
                    helperText={errors.staff ? 'Value is missing' : ' '}
                  />
                )}
              />
            </Grid>
          </Grid>
          {formData.staff && (
            <>
              <Grid
                xs={12}
                sm={6}
                sx={{ paddingRight: { sm: 1 }, marginTop: 1 }}
              >
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">
                    Date <span style={{ color: 'red' }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      open={isOpenDatePiker}
                      onOpen={() => setIsOpenDatePiker(!isOpenDatePiker)}
                      onClose={() => setIsOpenDatePiker(!isOpenDatePiker)}
                      value={formData.date}
                      onChange={(newValue) => {
                        handleSelectData(newValue, 'date');
                        setErrors({ ...errors, date: false });
                      }}
                      InputProps={{
                        style: {
                          flexDirection: 'row-reverse',
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          {...params}
                          onClick={() => setIsOpenDatePiker(!isOpenDatePiker)}
                          onTouchStart={() =>
                            setIsOpenDatePiker(!isOpenDatePiker)
                          }
                          fullWidth
                          error={errors.date}
                          helperText={errors.date ? 'Value is missing' : ' '}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid
                xs={12}
                sm={6}
                sx={{ paddingLeft: { sm: 1 }, marginTop: 1 }}
              >
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">
                    Time <span style={{ color: 'red' }}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    size="small"
                    disablePortal
                    onChange={(event, data) => handleSelectData(data, 'time')}
                    options={
                      Array.isArray(appointmentOptions?.times)
                        ? appointmentOptions?.times
                        : []
                    }
                    value={formData.time}
                    sx={{
                      marginBottom: '1rem',
                      width: '100%',
                      fontSize: '14px',
                    }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select..." />
                    )}
                  />
                </Grid>
              </Grid>
            </>
          )}

          <Grid xs={12} sx={{ paddingRight: { sm: 1 }, marginTop: 1 }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">
                Customer <span style={{ color: 'red' }}>*</span>
              </Typography>
            </Grid>
            <Grid sx={{ width: '101%' }} container>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{ paddingRight: { sm: 1 }, display: 'flex' }}
                container
              >
                <Autocomplete
                  size="small"
                  disablePortal
                  onChange={(event, data) => {
                    handleSelectData(data, 'customer');
                    setErrors({ ...errors, customer: false });
                  }}
                  options={appointmentOptions.customers.map((c) => c)}
                  getOptionLabel={(o) =>
                    o?.User?.firstName + o?.User?.lastName || ''
                  }
                  sx={{
                    marginBottom: '1rem',
                    fontSize: '14px',
                    flex: 1,
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select..."
                      error={errors.customer}
                      helperText={errors.customer ? 'Value is missing' : ' '}
                    />
                  )}
                  value={formData.customer}
                />
                {/*----- + sign next to customer------- */}
                <Icon
                  sx={{
                    width: '40px',
                    height: '39px',
                    border: '1px solid #ddd',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <AddIcon
                    onClick={() => {
                      setCustomerDrawerType('addCustomer');
                      toggleAddCustomerDrawer();
                    }}
                  />
                </Icon>
                {/*---------- + sign next to customer ends------- */}
              </Grid>
              <Grid item xs={12} sm={6} container>
                <Grid item xs={7} sx={{ paddingLeft: { sm: 1, xs: 0 } }}>
                  <Select
                    fullWidth
                    size="small"
                    sx={{ display: 'flex', alignItems: 'center' }}
                    onChange={(e) => handleSelectData(e.target.value, 'status')}
                    value={formData.status}
                    MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
                    error={errors.status}
                  >
                    {statusData.map((status) => (
                      <MenuItem key={status} value={status}>
                        {status}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error>
                    {errors.status ? 'Value is missing' : ' '}
                  </FormHelperText>
                </Grid>
                <Grid item xs={4.8} sx={{ paddingLeft: { sm: 1 } }}>
                  <Box
                    sx={{
                      border: '1px solid #ddd',
                      padding: '5px',
                      color: '#6c757d',
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '97%',
                      height: '1.8rem',
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <PersonIcon />
                      <Typography variant="subtitle2">1</Typography>
                    </div>
                    <ArrowDropDownIcon />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* ----------------Note--------------------- */}
          <Grid xs={12} sm={12} sx={{ paddingRight: { sm: 1 }, marginTop: 1 }}>
            <Grid item xs={12} sm={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">Note</Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextareaAutosize
                id="outlined-basic"
                size="small"
                required
                variant="outlined"
                style={{
                  marginBottom: '1rem',
                  width: '99%',
                  minHeight: '120px',
                  paddingLeft: '0px',
                  fontSize: '16px',
                }}
                onChange={(e) => {
                  handleSelectData(e.target.value, 'note');
                }}
                value={formData.note}
                error={errors.note}
                helperText={errors.note ? 'Value is missing' : ' '}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export default AppointmentDetails;
