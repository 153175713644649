import { Grid, Typography } from "@mui/material";
import React from "react";

const CustomFeilds = () => {
    return (
        <Grid
            sx={{
                boxShadow: " rgba(149, 157, 165, 0.2) 0px 8px 24px",
                padding: "2rem",
                margin: "1rem",
            }}
        >
            <Grid sx={{ padding: "1rem", display: "flex", justifyContent: "center", borderBottom: '1px solid #e3eaf3' }}>
                <Typography>Additional information</Typography>
            </Grid>
            <Grid sx={{ my: "1rem" }}>
                <Typography>Age:</Typography>
                <Typography color="#828F9A"> 20 </Typography>
            </Grid>
            <Grid sx={{ my: "1rem" }}>
                <Typography>Gender:</Typography>
                <Typography color="#828F9A"> male </Typography>
            </Grid>
            <Grid sx={{ my: "1rem" }}>
                <Typography>Social Insurance number
                </Typography>
            </Grid>
        </Grid>
    );
};

export default CustomFeilds;
