import {
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { addCategory } from '../../../../Api/tenant/categories';
import { useCallback, useState } from 'react';
import { customHelperTextErr } from '../../../../utils/CustomHelperTextErr';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../redux/slices/user.slice.js';
import useCategory from '../../../../hooks/useCategory';
import CustomTextButton from '../../../CustomButtons/CustomTextButton';

const AddCategoryModal = ({ handleOpenModal, fetchCategories }) => {
  const { categories, subCategories } = useCategory();
  const [categoryName, setCategoryName] = useState('');
  const [categoryType, setCategoryType] = useState('product');
  const [errors, setErrors] = useState({
    name: [],
    type: [],
  });
  const userId = useSelector(selectUser)?.user?.id;

  const handleSubmit = useCallback(async () => {
    try {
      const errs = {
        name: [],
        type: [],
      };
      let hasError = false;

      if (!categoryName) {
        errs.name.push('Category Name Required');
        hasError = true;
      }

      if (categories.find((category) => category.name === categoryName) || subCategories.find((category) => category.name === categoryName)) {
        errs.name.push('category name already exists');
        hasError = true;
      }

      if (!categoryType) {
        errs.type.push('Category Type Required');
        hasError = true;
      }

      if (hasError) {
        setErrors(errs);
        return;
      }

      await addCategory({ name: categoryName, type: categoryType, userId });
      handleOpenModal();
      fetchCategories();
    } catch (err) {
      console.log(err);
    }
  }, [
    categories,
    categoryName,
    categoryType,
    fetchCategories,
    handleOpenModal,
    userId,
  ]);

  return (
    <>
      <Grid sm={12} xs={12}>
        <Grid sx={{ py: 5 }}>
          <Typography variant="subtitle2" sx={{ my: 1 }}>
            Category name<sapn style={{ color: 'red' }}>*</sapn>
          </Typography>
          <TextField
            fullWidth
            size="small"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
            error={errors.name.length > 0}
            helperText={
              errors.name.length > 0 ? customHelperTextErr(errors.name) : ''
            }
          />

          <Typography variant="subtitle2" sx={{ my: 1 }}>
            Category Type<sapn style={{ color: 'red' }}>*</sapn>
          </Typography>
          <Select
            fullWidth
            size="small"
            value={categoryType}
            onChange={(e) => setCategoryType(e.target.value)}
            error={errors.type.length > 0}
            helperText={
              errors.type.length > 0 ? customHelperTextErr(errors.type) : ''
            }
          >
            <MenuItem value="product">Product</MenuItem>
            <MenuItem value="restaurant">Restaurant</MenuItem>
            <MenuItem value="service">Service</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Grid container sx={{ justifyContent: 'end', gap: '15px' }}>
        <CustomTextButton
          variant="contained"
          size="large"
          onClick={handleOpenModal}
        >
          Cancel
        </CustomTextButton>
        <CustomTextButton
          type="blue"
          variant="contained"
          size="large"
          onClick={handleSubmit}
        >
          Save
        </CustomTextButton>
      </Grid>
    </>
  );
};

export default AddCategoryModal;
