import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Avatar, Box, Chip } from "@mui/material";
import AvatarComp from "../../components/Avatar";

const StatusChip = ({ value }) => {
  const bgStyles = {
    received: "#84FFDE ",
    approved: "#E9F7E8",
    cancelled: "#F9E0E7",
    pending: "#FBEEE9",
    completed: "#EAE5FB",
    rejected: "#EDEEF0",
  };
  const textStyles = {
    received: "#509F8A",
    approved: "#82D376",
    cancelled: "#DF3E70",
    pending: "#EA9B7F",
    completed: "#8467E9",
    rejected: "#929DA6",
  };
  const StatusLabel = value[0].toUpperCase() + value.substring(1);
  return (
    <Chip
      size="small"
      label={StatusLabel}
      style={{
        background: bgStyles[value],
        color: textStyles[value],
        width: "80px",
        textAlign: "center",
        fontSize: "13px",
        height: "25px",
      }}
    />
  );
};

const TableData = ({ data, handelInfo }) => {
  const [expanded, setExpanded] = React.useState("");

  const toggleExpand = (panel) => {
    if (expanded === panel) {
      setExpanded("");
    } else {
      setExpanded(panel);
    }
  };

  const handelInfoInEdit = (id) => {
    console.log("call handelinfo ");
    handelInfo(id);
  };
  return (
    <div>
      {data.map((userInfo, index) => (
        <>
          <Box
            key={index}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#ADBFC7",
              p: "16px",
            }}
          >
            <Typography
              sx={{ fontWeight: "500", color: "white", fontSize: "15px" }}
            >
              {userInfo.day}
            </Typography>
            <Typography
              sx={{ fontWeight: "500", color: "white", fontSize: "15px" }}
            >
              {userInfo.date}
            </Typography>
          </Box>
          {userInfo.appointment.map((data, i) => (
            <Accordion
              expanded={expanded === `panel${data.id}`}
              // expanded={true}
              key={i}
              disableGutters={true}
            >
              <AccordionSummary
                expandIcon={
                  <div onClick={() => toggleExpand(`panel${data.id}`)}>
                    <ExpandMoreIcon />
                  </div>
                }
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                sx={{ p: "4px", marginLeft: "15px" }}
              >
                <Box
                  display="flex"
                  sx={{ width: "80%" }}
                  justifyContent="space-between"
                  onClick={() => handelInfoInEdit(userInfo.id)}
                >
                  <Typography
                    sx={{ width: "49%", fontSize: "13px", flexShrink: 0 }}
                  >
                    {data.time}
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <AvatarComp
                      username={data.staff.name}
                      img={data.staff.image}
                      sx={{ width: 20, height: 20, marginRight: "5px" }}
                    />
                    <Typography varinat="subtitle2" fontSize="13px">
                      {data.staff.name}
                    </Typography>
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails
                sx={{ display: "flex", justifyContent: "space-between" }}
                onClick={() => handelInfoInEdit(1)}
              >
                <Typography fontSize={"13px"}>{data.service}</Typography>
                <Typography fontSize={"13px"}>{data.customer}</Typography>
                <StatusChip value={data.status} />
              </AccordionDetails>
            </Accordion>
          ))}
        </>
      ))}
    </div>
  );
};

export default TableData;
