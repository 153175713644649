//import { TextField, Box, Link} from '@material-ui/core';
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import React from "react";
import { colors } from "@mui/material";

class Footer extends React.Component {
  render() {
    return (
      <Box
        sx={{
          display: "flex",
          // position: "fixed",
          bottom: "0",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          color: "#656565",
        }}
      >
        <Link
          href="https://upnize.com/"
          variant="p"
          target="_blank"
          sx={{
            paddingX: "5px",
            color: "#656565",
            textDecoration: "none",
            "&:hover": {
              color: colors.blue[900],
            },
          }}
        >
          © 2022 Upnize
        </Link>
        <p style={{ fontSize: "14px" }}>|</p>
        <Link
          href="https://upnize.com/help-center"
          variant="a"
          target="_blank"
          sx={{
            paddingX: "5px",
            color: "#656565",
            textDecoration: "none",
            "&:hover": {
              color: colors.blue[900],
            },
          }}
        >
          Need help?
        </Link>
        <p>|</p>
        <Link
          href="https://upnize.com/terms-of-service"
          variant="a"
          target="_blank"
          sx={{
            paddingX: "5px",
            color: "#656565",
            textDecoration: "none",
            "&:hover": {
              color: colors.blue[900],
            },
          }}
        >
          Terms of service
        </Link>
      </Box>
    );
  }
}

export default Footer;
