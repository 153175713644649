import { useCallback, useEffect, useMemo, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { getCustomerList } from '../../../Api/tenant/customers';
import CustomNoOption from '../../CustomNoOption';
import { useDispatch } from 'react-redux';
import { drawersActions } from '../../../redux/slices/drawers.slice';
import { drawerTypes } from '../../../constants/drawerTypes';
import { crudTypes } from '../../../constants/crudTypes';
import useInitialGuide from '../../../hooks/useInitialGuide';
import { initialGuideAttributes } from '../../../constants/initialGuideAttributes';

export default function SelectCustomer({
  selectedValue,
  setSelectedValue,
  addNewEntity = false,
}) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options?.length === 0;
  const { initialGuideData, fetchInitialGuide } = useInitialGuide();

  const handleOpen = useCallback(async () => {
    if (!open) return;
    try {
      const { data } = await getCustomerList(100, 1);
      setOptions(data || []);
    } catch (err) {
      console.log(err);
    }
  }, [open]);

  useEffect(() => {
    handleOpen();
  }, [handleOpen]);

  const selectedFieldValue = useMemo(
    () => options?.find((option) => option.id === selectedValue),
    [options, selectedValue]
  );

  const handleDrawerOpen = () => {
    dispatch(
      drawersActions.openDrawer({
        type: drawerTypes.CUSTOMER,
        title: 'Add Customer',
        crudType: crudTypes.ADD,
        submitTitle: crudTypes.ADD,
        refetchData: () => {
          setOptions([]);
          if (!initialGuideData[initialGuideAttributes?.createdCustomer])
            fetchInitialGuide();
        },
      })
    );
  };

  return (
    <Autocomplete
      sx={{ width: '100%' }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(e, value) => setSelectedValue(value?.id)}
      value={selectedFieldValue || null}
      getOptionLabel={(option) =>
        `${option?.User?.firstName} ${option?.User?.lastName}`
      }
      noOptionsText={
        addNewEntity ? (
          <CustomNoOption onClick={handleDrawerOpen}>
            Add Customer
          </CustomNoOption>
        ) : (
          'No options'
        )
      }
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Select Customer"
          size="small"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
