import { Tooltip, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: 12,
    backgroundColor: '#2F284D',
    color: '#fff',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#2F284D',
  },
}));

export default CustomTooltip;
