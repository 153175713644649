import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  DataGrid,
  GridActionsCellItem,
  GridMoreVertIcon,
  gridStringOrNumberComparator,
} from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import Modal from '../../components/Modal';
import Zoom from '@mui/material/Zoom';
import NotesCard from '../../components/NotesCard';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import MessageIcon from '@mui/icons-material/Message';
import { Divider, Popover } from '@mui/material';
import CustomTableFooter from '../../components/CustomTableFooter';
import { styled } from '@mui/material/styles';
import { gridClasses } from '@mui/x-data-grid';
import UploadIcon from '@mui/icons-material/Upload';
import AddAppointment from './AddAppointment';
import Info from './info';
import PaymentInfo from './PaymentInfo';
import classes from './appointment.module.css';
import StatusChip from '../../components/StatusChip';
import AvatarComp from '../../components/Avatar';
import {
  deleteAppointmentById,
  getAppointmentList,
} from '../../Api/tenant/appointments';
import CustomIconButton from '../../components/CustomButtons/CustomIconButton';
import CustomTextButton from '../../components/CustomButtons/CustomTextButton';
import { useDateFormat } from '../../../../../libs/common-ui/src/index';
import { useSelector } from 'react-redux';
import { selectUser } from '../../redux/slices/user.slice.js';
import { defaultPageSizeOptions } from '../../constants';
import CustomTooltip from '../../components/CustomTooltip';
import { Link } from 'react-router-dom';
import useFilterBar from '../../hooks/useFilterBar';
import { getQueries } from '../../utils/GetQueries';
import NoData from '../../components/NoData';

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    // backgroundColor: theme.palette.grey[200],
    color: theme.palette.grey[500],
  },
  [`& .${gridClasses.columnHeader}`]: {
    backgroundColor: '#EFF1FC',
    color: '#3A3A50',
  },
}));

const Appointment = () => {
  const [rows, setRows] = useState([]);
  const [addAppointment, setAddAppointmentDrawer] = useState(false);
  const [addAppointmentInfo, setAddAppointmentInfoDawer] = useState(false);
  const [appointments, setAppointments] = useState(null);
  const [tableLoading, setTableLoading] = useState(true);
  const [openCommentsTab, setOpenCommentsTab] = useState(false);
  const [paymentInfo, setPaymentInfoDawer] = useState(false);
  const [drawerType, setDrawerType] = useState('add');
  const [drawerData, setDrawerData] = useState(null);
  const [openModal, setOpenModal] = useState({ open: false, type: null });
  const [selectionModel, setSelectionModel] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const [commentsAnchorEl, setCommentsAnchorEl] = useState({
    element: null,
    appointment: null,
  });

  const { user } = useSelector(selectUser);
  const { FilterBar, state, FilterButton } = useFilterBar({
    filters: [
      {
        id: 1,
        title: 'Quick search',
        type: 'search',
        queryLabel: 'keyword',
      },
      {
        id: 2,
        title: 'Select date',
        type: 'date',
        queryLabel: 'date',
      },
      {
        id: 3,
        title: 'Service',
        type: 'select',
        options: ['Loading'],
        isOpen: false,
        search: '',
        queryLabel: 'service',
      },
      {
        id: 4,
        title: 'Customer',
        type: 'select',
        options: ['Loading'],
        isOpen: false,
        search: '',
        queryLabel: 'customer',
      },
      {
        id: 5,
        title: 'Staff',
        type: 'select',
        options: ['Loading'],
        isOpen: false,
        search: '',
        queryLabel: 'staff',
      },
      {
        id: 6,
        title: 'Status',
        type: 'select',
        options: [
          'Pending approval',
          'Confirmed',
          'In Progress',
          'Rescheduled',
          'On Hold',
          'No Show',
          'Awaiting Payment',
          'Completed',
          'Paid',
          'Cancelled',
          'Rejected',
        ],
        isOpen: false,
        search: '',
        queryLabel: 'status',
      },
    ],
  });

  // handle comments display
  const handleCommentsClick = (appointment) => (event) => {
    setCommentsAnchorEl({ element: event.currentTarget, appointment });
  };

  const handleCloseComments = () => {
    setCommentsAnchorEl({ element: null, appointment: null });
  };

  const commentsOpen = Boolean(commentsAnchorEl.element);

  // handling date formatting
  const { getDateFormat } = useDateFormat(
    user?.settings?.GeneralSettings?.dFormat
  );

  const toggleAddAppointmentDrawer = () => {
    setAddAppointmentDrawer(!addAppointment);
  };
  const toggleAddAppointmentInfoDrawer = () => {
    setAddAppointmentInfoDawer(!addAppointmentInfo);
  };
  const toggleCommentsTab = (toggle) => {
    setOpenCommentsTab(toggle === 'open' ? true : false);
  };
  const openCommentsTabInfoDrawer = (id) => {
    return () => {
      setDrawerType('edit');
      setDrawerData({ id });
      toggleAddAppointmentInfoDrawer();
      toggleCommentsTab('open');
    };
  };
  const togglePaymentInfoDrawer = () => {
    setPaymentInfoDawer(!paymentInfo);
  };

  const handleOpenModal = () => {
    setOpenModal({ open: false, type: null });
  };

  // delete child
  const handleDeleteOpenModal = (id) => {
    setOpenModal({ open: true, type: 'delete-modal', id });
    if (addAppointmentInfo) {
      setAddAppointmentInfoDawer(false);
    }
  };

  const handelEdit = (id) => {
    toggleAddAppointmentDrawer();
  };

  // API

  const fetchAppointments = async () => {
    setTableLoading(true);
    getAppointmentList(pageSize, pageCount).then((res) => {
      setAppointments(res.data);
      console.log(res.data);
    });
  };

  const handleDeleteAppointment = async (id) => {
    const ids = Array.isArray(id) ? id : [id];
    deleteAppointmentById(ids).then((res) => {
      fetchAppointments();
    });
  };

  // fetch on search & page change
  useEffect(() => {
    getQueries(state).then((queries) => {
      setTableLoading(true);
      getAppointmentList(pageSize, pageCount, queries).then((res) => {
        setAppointments(res.data);
      });
    });
  }, [state, pageSize, pageCount]);

  // fetch on render
  useEffect(() => {
    fetchAppointments();
  }, []);

  // update table rows on fetch
  useEffect(() => {
    if (appointments) {
      setRows(appointments);
      console.log(appointments);
      setTableLoading(false);
    }
  }, [appointments]);

  //table columns
  const columns = [
    { field: 'date', headerName: 'DATE', minWidth: 100, flex: 1 },
    {
      field: 'customer',
      headerName: 'CUSTOMERS',
      minWidth: 250,
      flex: 1.2,
      sortComparator: (v1, v2) =>
        gridStringOrNumberComparator(v1.name, v2.name),
      renderCell: (params) => {
        return (
          <Box
            sx={{
              width: '100%',
            }}
          >
            <Box display="flex">
              <AvatarComp
                img={params.value.User.image}
                username={params.value.User.firstName}
                sx={{ mr: 1 }}
              />
              <Box
                onClick={() => {
                  console.log('params.row.customer', params.row.customer);
                }}
              >
                <Typography fontSize="14px" color="#292d32">
                  <Link
                    to={`/customer/${params?.row?.customer?.id}`}
                    style={{
                      color: '#292d32',
                      textDecoration: 'none',
                    }}
                  >
                    {params.value.User.firstName}
                  </Link>
                </Typography>
                <Typography color="#95a0a9" fontSize="12px">
                  {params.value.User.email}
                </Typography>
              </Box>
            </Box>
          </Box>
        );
      },
    },
    {
      field: 'status',
      headerName: 'STATUS',
      minWidth: 160,
      flex: 0.8,
      renderCell: (params) => (
        <>
          {params.value === 'Custom' ? (
            <StatusChip
              color={params.row.statusColor}
              bgColor={params.row.statusBgColor}
              text={params.row.statusText}
              rounded={true}
              width={'max-content'}
            />
          ) : (
            <StatusChip type={params.value} width={'max-content'} />
          )}
        </>
      ),
    },
    {
      field: 'staff',
      headerName: 'STAFF',
      minWidth: 160,
      flex: 1,
      sortComparator: (v1, v2) =>
        gridStringOrNumberComparator(v1.name, v2.name),
      renderCell: (params, i) => (
        <Box display="flex" alignItems={'center'} onClick={() => {}}>
          {params?.value?.User?.firstName ? (
            <>
              <AvatarComp
                img={params?.value?.User?.image || ''}
                username={params?.value?.User?.firstName || 'E R'}
                sx={{ marginRight: '10px' }}
              />

              <Typography fontSize="14px" color="#292d32">
                {params?.value?.User?.firstName || 'ERROR'}
              </Typography>
            </>
          ) : (
            '-'
          )}
        </Box>
      ),
    },
    {
      field: 'service',
      headerName: 'SERVICE',
      minWidth: 120,
      flex: 1,
      renderCell: (params) => params.value.name,
    },
    {
      field: 'payment',
      headerName: 'PAYMENT',
      minWidth: 120,
      sortComparator: (v1, v2) => v1 - v2,
      flex: 1,
      renderCell: (params) => {
        return (
          <div className={classes.price__container}>
            ${params.row.service.price}
            <svg
              onClick={() => {
                setDrawerType('edit');
                setDrawerData(params.row);
                toggleAddAppointmentInfoDrawer();
              }}
              style={{ paddingRight: '10px', cursor: 'pointer' }}
              width="20px"
              height="20px"
              viewBox="0 0 20 20"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Combined Shape</title>
              <desc>Created with Sketch.</desc>
              <g
                id="Page-1"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="Payments"
                  transform="translate(-1799.000000, -381.000000)"
                  fill="#ADBFC7"
                  fill-rule="nonzero"
                >
                  <path
                    d="M1816,381 C1817.7,381 1819,382.3 1819,384 L1819,398.6 L1815.6,401 L1813.3,399.4 L1811,401 L1808.7,399.4 L1806.4,401 L1803,398.7 L1803,391 L1799,391 L1799,384 C1799,382.3 1800.3,381 1802,381 L1816,381 Z M1801,389 L1803,389 L1803,384 C1803,383.4 1802.6,383 1802,383 C1801.4,383 1801,383.4 1801,384 L1801,389 Z M1817,397.6 L1817,384 C1817,383.4 1816.6,383 1816,383 L1804.8,383 C1804.9,383.3 1805,383.6 1805,384 L1805,397.6 L1806.4,398.6 L1808.7,397 L1811,398.6 L1813.3,397 L1815.6,398.6 L1817,397.6 Z M1807,385 L1815,385 L1815,387 L1807,387 L1807,385 Z M1807,389 L1815,389 L1815,391 L1807,391 L1807,389 Z M1807,393 L1811,393 L1811,395 L1807,395 L1807,393 Z"
                    id="Combined-Shape"
                  ></path>
                </g>
              </g>
            </svg>
          </div>
        );
      },
    },
    {
      field: 'duration',
      headerName: 'DURATION',
      minWidth: 90,
      flex: 0.7,
      renderCell: (params) => (
        <div
          style={{
            width: '100%',
            display: 'flex',
          }}
        >
          {params.row.service.duration == 0
            ? '-'
            : params.row.service.duration + ' m'}
        </div>
      ),
    },

    {
      field: 'createdAt',
      headerName: 'CREATED AT',
      minWidth: 100,
      flex: 1,
      renderCell: (params) => <div>{getDateFormat(params.value)}</div>,
    },
    {
      field: 'actions',
      headerName: 'ACTIONS',
      type: 'actions',
      align: 'right',
      maxWidth: 100,
      getActions: (params) => [
        <GridActionsCellItem
          label="Info"
          onClick={() => {
            setDrawerType('edit');
            setDrawerData(params.row);
            toggleAddAppointmentInfoDrawer();
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Edit"
          onClick={() => {
            setDrawerType('edit');
            setDrawerData(params.row);
            toggleAddAppointmentDrawer();
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Delete"
          onClick={() =>
            setOpenModal({ open: true, type: 'delete-modal', id: params.id })
          }
          showInMenu
        />,
        params.row.comments && params.row.comments.length > 0 ? (
          <>
            <GridActionsCellItem
              onClick={handleCommentsClick(params.row)}
              icon={
                params.row.unreadMsg ? (
                  <MarkUnreadChatAltIcon />
                ) : (
                  <>
                    {/*<MessageIcon sx={{ color: '#ADBFC7' }} />*/}
                    <MarkUnreadChatAltIcon />
                  </>
                )
              }
            />
            <Popover
              open={commentsOpen}
              anchorEl={commentsAnchorEl.element}
              onClose={handleCloseComments}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
            >
              <NotesCard
                comments={commentsAnchorEl?.appointment?.comments}
                hideHeader={true}
                width={'400px'}
                height={'400px'}
              />
            </Popover>
          </>
        ) : (
          <div></div>
        ),
      ],
    },
  ];

  return (
    <Grid
      item
      container
      sx={{
        minWidth: { xs: '100%' },
        // paddingLeft: 4,
        // paddingRight: 4,
        px: '20px',
      }}
    >
      {openModal.type === 'delete-modal' && (
        <Modal
          handleOpenModal={handleOpenModal}
          type={'DELETE'}
          open={openModal.open}
          id={openModal.id}
          handleDelete={handleDeleteAppointment}
        />
      )}

      {
        // add appointments drawer
        addAppointment && (
          <AddAppointment
            toggleAddAppointmentDrawer={toggleAddAppointmentDrawer}
            drawerType={drawerType}
            drawerData={drawerData}
            fetchAppointments={fetchAppointments}
            // handelHide={handelHide}
          />
        )
      }
      {paymentInfo && (
        <PaymentInfo
          togglePaymentInfoDrawer={togglePaymentInfoDrawer}
          drawerType={drawerType}
          drawerData={drawerData}
        />
      )}
      {addAppointmentInfo && (
        <Info
          toggleAddAppointmentInfoDrawer={toggleAddAppointmentInfoDrawer}
          openCommentsTab={openCommentsTab}
          toggleCommentsTab={toggleCommentsTab}
          drawerType={drawerType}
          drawerData={drawerData}
          handelEdit={handelEdit}
          handleDeleteOpenModal={handleDeleteOpenModal}
        />
      )}

      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          flexWrap: 'wrap',
        }}
      >
        <Typography
          sx={{
            marginTop: 2.5,
            marginBottom: 1,
            fontSize: 24,
            color: '#3A3A50',
          }}
        >
          Appointments
        </Typography>
      </Grid>
      <Box
        sx={{
          width: '100%',
          // width: { xs: '100%', sm: 'fit-content' },
          display: 'flex',
          // alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <CustomTextButton
          text="+ New appointment"
          onClick={() => {
            setDrawerType('addAppointment');
            toggleAddAppointmentDrawer();
          }}
        />

        <Box sx={{ display: 'flex', gap: '10px', ml: '10px' }}>
          <CustomIconButton Icon={UploadIcon} toolTipText="EXPORT TO CSV" />
          {FilterButton}
        </Box>
      </Box>
      {/* --------------------SEARCH------------------------------- */}

      {FilterBar}

      {/* --------------------TABLE--------------------------------- */}
      <Divider light sx={{ width: '100%', marginTop: '30px' }} />
      <Grid
        container
        item
        justifyContent="space-between"
        sx={{ marginTop: '20px', height: '100%' }}
      >
        <div style={{ height: '100%', width: '100%' }}>
          <StripedDataGrid
            sx={{
              minHeight: '60vh',
              border: 0,
              '& .MuiDataGrid-virtualScroller': {
                minHeight: '300px !important',
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                fontSize: '12px',
                fontWeight: '900 !important',
              },
              '& .MuiDataGrid-main, .MuiDataGrid-footerContainer': {
                backgroundColor: 'white',
                border: 'none',
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
            }}
            getRowClassName={(params) => {
              return params.row.isHidden ? 'even' : 'odd';
            }}
            rows={rows}
            columns={columns}
            checkboxSelection
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            autoHeight
            loading={tableLoading}
            selectionModel={selectionModel}
            rowCount={1000}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => {
              setPageSize(newPageSize);
            }}
            rowsPerPageOptions={defaultPageSizeOptions}
            page={pageCount - 1}
            onPageChange={(newPage) => {
              setPageCount(newPage + 1);
            }}
            paginationMode="server"
            components={{
              NoRowsOverlay: NoData,
              NoResultsOverlay: NoData,
              Footer: () => (
                <CustomTableFooter
                  selectionModel={selectionModel}
                  handleDeleteOpenModal={handleDeleteOpenModal}
                />
              ),
              MoreActionsIcon: () => (
                <GridMoreVertIcon
                  sx={{
                    backgroundColor: 'white',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
                    borderRadius: '4px',
                    padding: '3px',
                    cursor: 'pointer',
                  }}
                />
              ),
              NoRowsOverlay: () => {
                return (
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'Center',
                      gap: '20px',
                    }}
                  >
                    <img
                      src="https://user-images.githubusercontent.com/109676648/254145031-57027cd6-fecd-4ce2-96fc-9d891c8de53a.svg"
                      alt="no data"
                      style={{
                        width: '100px',
                        height: '100px',
                      }}
                    />
                    No data at the moment 😔
                  </Box>
                );
              },
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default Appointment;
