import { configureStore } from '@reduxjs/toolkit';
import snackbarSlice from './slices/snackbar.slice';
import drawersSlice from './slices/drawers.slice';
import userSlice, { fetchUser } from './slices/user.slice.js';
import initialGuideSlice from './slices/initialGuide.slice';

export const store = configureStore({
  reducer: {
    snackbar: snackbarSlice,
    user: userSlice,
    drawers: drawersSlice,
    initialGuide: initialGuideSlice
  },
});
