import {
  Box,
  ClickAwayListener,
  Grid,
  MenuItem,
  Popper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CloseIcon from '@mui/icons-material/Close';
import ReactDatePicker from 'react-datepicker';
import ClearIcon from '@mui/icons-material/Clear';
import { getStaffList } from '../Api/tenant/staff';
import { getCustomerList } from '../Api/tenant/customers';
import { getServiceList } from '../Api/tenant/services';
import { debounce } from 'lodash';
import Drawer from '../components/Drawer';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import CustomTextButton from '../components/CustomButtons/CustomTextButton';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';

const initalState = [];

const appointmentReducer = (state, { type, payload }) => {
  switch (type) {
    case 'SELECT':
      const newArray = [...state];
      const findAns = newArray.find((item) => item.id === payload.id);
      if (findAns) {
        findAns.ans = payload.ans;
        return newArray;
      } else {
        const ans = [
          ...state,
          {
            id: payload.id,
            ans: payload.ans,
            queryLabel: payload.queryLabel,
          },
        ];

        return ans;
      }
    case 'CLEAR_ALL':
      return [];

    case 'CLEAR':
      const ansData = state.filter((ans) => ans.id !== payload);
      return [...ansData];

    default:
      return state;
  }
};

function FilterButton({
  setIsFitlerBar,
  isFilterBar,
  isSmallScreen,
  clearFilters,
  isInsideResponsiveModal,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const isClearFiltersMenu = Boolean(anchorEl);
  const id = isClearFiltersMenu ? 'simple-popper' : undefined;

  const handleCLearFiltersMenu = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  return (
    <>
      {isSmallScreen && !isInsideResponsiveModal ? (
        <CustomTextButton
          text={''}
          sx={{
            color: !isFilterBar ? 'white' : '#1976d2',
            backgroundColor: !isFilterBar ? '#1976d2' : 'white',
            '&:hover': {
              backgroundColor: !isFilterBar ? '#1976d2' : 'white',
            },
          }}
          onClick={(e) => {
            setIsFitlerBar((prev) => !prev);
          }}
        >
          {!isFilterBar ? (
            <FilterListIcon sx={{ ml: 0, color: 'white' }} />
          ) : (
            <FilterListOffIcon sx={{ ml: 0, color: '#1976d2' }} />
          )}
        </CustomTextButton>
      ) : (
        <>
          <CustomTextButton
            text={'Filter'}
            sx={{
              color: 'white',
              backgroundColor: '#1976d2',
              '&:hover': { backgroundColor: '#1976d2' },
            }}
            onClick={(e) => {
              handleCLearFiltersMenu(e);
            }}
          >
            <FilterListIcon sx={{ ml: 10, color: 'white' }} />
          </CustomTextButton>
          <Popper
            id={id}
            open={isClearFiltersMenu}
            anchorEl={anchorEl}
            sx={{ zIndex: 9999 }}
          >
            <ClickAwayListener onClickAway={handleCLearFiltersMenu}>
              <Box
                sx={{
                  width: anchorEl && anchorEl.offsetWidth + 'px',
                  py: 1,
                  bgcolor: 'white',
                  border: '1px #f3eded solid',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  clearFilters();
                  handleCLearFiltersMenu();
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    px: 1,
                  }}
                >
                  Clear all <DeleteIcon sx={{ color: 'red' }} />
                </Box>
              </Box>
            </ClickAwayListener>
          </Popper>
        </>
      )}
    </>
  );
}

function FilterBar({
  state,
  dispatch,
  filterOption,
  setFilterOption,
  debouncedOnChangeHandler,
  isSmallScreen,
  setIsFitlerBar,
  isFilterBar,
  textSearchRef,
  FilterButton,
}) {
  const [activeFilterElement, setActiveFilterElement] = useState(null);
  const [dateValue, setDateValue] = useState('Date');
  const [startDate, setStartDate] = useState();

  const handelSelectDate = (categoryId, option) => {
    // if (option.length >= 0) {
    //   dispatch({ type: "CLEAR", payload: categoryId });
    // }

    dispatch({
      type: 'SELECT',
      payload: {
        id: categoryId,
        ans: new Date(option).toJSON().slice(0, 10),
        queryLabel: 'date',
      },
    });
    const newArray = [...filterOption];
    const optionObject = newArray.find((array) => array.id === categoryId);
    optionObject.isOpen = false;
    setFilterOption(newArray);
    setStartDate(option);
    setDateValue(option);
  };

  const handelClearDate = ({ calcelId }) => {
    dispatch({ type: 'CLEAR', payload: calcelId });
    setDateValue('Date');
  };

  const findAns = (id) => {
    const find = state.find((item) => item.id === id);
    return find;
  };
  const handelClear = (calcelId) => {
    dispatch({ type: 'CLEAR', payload: calcelId });
  };

  const handleFilterOpen = async (e, id) => {
    e && setActiveFilterElement(e.currentTarget);
    const newArr = [...filterOption];
    const newObj = newArr.find((item) => item.id === id); // The clicked filter
    newObj.isOpen = !newObj.isOpen;
    newArr.forEach((item) => {
      if (item.id !== id) {
        item.isOpen = false;
      }
    });

    setFilterOption(newArr);

    let newFilterArray = await Promise.all(
      newArr.map(async (filter) => {
        if (filter.id === newObj.id) {
          switch (newObj.title.toLowerCase()) {
            case 'service':
              const serviceFilters = await getServiceList(100, 1).then(
                (res) => {
                  const serviceNames = res.data.map((ser) => ser?.name);
                  return serviceNames;
                }
              );
              return { ...filter, options: serviceFilters };
            case 'customer':
              const customerFilters = await getCustomerList(100, 1).then(
                (res) => {
                  const customerNames = res.data.map(
                    (cus) => cus.User.firstName + ' ' + cus.User.lastName
                  );
                  return customerNames;
                }
              );
              return { ...filter, options: customerFilters };
            case 'staff':
              const staffFilters = await getStaffList(100, 1).then((res) => {
                const staffNames = res.data.map(
                  (staff) => staff.User.firstName + ' ' + staff.User.lastName
                );
                return staffNames;
              });
              return { ...filter, options: staffFilters };

            default:
              return filter;
          }
        } else {
          return filter;
        }
      })
    );

    setFilterOption(newFilterArray);
  };

  const handelOnFilterOption = (id, value) => {
    const newArray = [...filterOption];
    const newObj = newArray.find((item) => item.id === id);
    newObj.search = value;
    setFilterOption(newArray);
  };

  // ! TODO: chagne this to seprate component
  const isXsScreen = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDateRangeOpen, setIsDateRangeOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  return (
    <>
      {!isSmallScreen ? (
        <Grid
          container
          item
          sx={{
            justifyContent: 'end',
            marginTop: '0.85rem',
          }}
          columnSpacing={{ xs: '3px', md: '20px' }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {filterOption.map((item, index) => {
            return (
              <>
                {item.type === 'search' && (
                  <Grid item xs={12} sm={12} md={2}>
                    <div
                      style={{
                        backgroundColor: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: { xs: 'start', md: 'center' },
                        height: '40px',
                        boxShadow: '0 0 30px 0 rgb(0 0 0 / 5%)',
                      }}
                    >
                      <div style={{ display: 'flex' }}>
                        <input
                          type="text"
                          className="quickSearch"
                          placeholder="Search..."
                          onChange={(e) =>
                            debouncedOnChangeHandler(item, e.target.value)
                          }
                          ref={textSearchRef}
                          style={{
                            width: '100%',
                            fontSize: '14px',
                            boxSizing: 'border-box',
                            border: 'none',
                            outline: 'none',
                            color: '#929DA6',
                            paddingLeft: '10px',
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                )}
                {item.type === 'date' && (
                  <Grid item xs={12} sm={12} md={2}>
                    <Box
                      sx={{
                        backgroundColor: 'white',
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        paddingX: '10px',
                        fontSize: '14px',
                      }}
                    >
                      <ReactDatePicker
                        value={typeof dateValue === 'string' && dateValue}
                        selected={typeof startDate === 'object' && startDate}
                        onChange={(date) => handelSelectDate(item.id, date)}
                      />
                      {item.id && dateValue != 'Date' ? (
                        <CloseIcon
                          sx={{ fontSize: '14px', cursor: 'pointer' }}
                          onClick={() => handelClearDate(item.id)}
                        />
                      ) : (
                        <div></div>
                      )}
                    </Box>
                  </Grid>
                )}
                {item.type === 'dateRange' && (
                  <Grid item xs={12} sm={12} md={2}>
                    <Box
                      sx={{
                        backgroundColor: 'white',
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        paddingX: '10px',
                        fontSize: '14px',
                      }}
                    >
                      <Grid
                        sx={{
                          flex: '1',
                          overflow: 'hidden',
                          pl: 0.5,
                          background: 'white',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: {
                            xs: 'center',
                            lg: 'justify-between',
                          },
                          // height: '60px',
                          height: '40px',
                          marginTop: '2px',
                          marginLeft: '2px',
                          minWidth: '150px',
                          cursor: 'pointer',
                        }}
                      >
                        <Box
                          sx={{
                            overflow: 'hidden',
                            flex: 1,
                            background: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            height: '60px',
                            minWidth: '108px',
                            marginTop: '2px',
                            cursor: 'pointer',
                            justifyContent:
                              !selectedDate && isXsScreen ? 'center' : 'start',
                          }}
                          onClick={(e) => {
                            setAnchorEl(e.currentTarget.parentElement);
                            setIsDateRangeOpen(!isDateRangeOpen);
                          }}
                        >
                          <Typography
                            sx={{
                              // display: { xs: "none", md: "block" },
                              whiteSpace: 'nowrap',
                              fontSize: '14px',
                              color: selectedDate ? '#495057' : '#929DA6',
                              marginRight:
                                !selectedDate && isXsScreen ? '55px' : '0',
                            }}
                          >
                            {selectedDate?.startDate
                              ? `${selectedDate?.startDate}` + ' - '
                              : 'Select a Date'}
                            {selectedDate?.endDate &&
                              `${selectedDate?.endDate}`}
                          </Typography>
                        </Box>
                        {selectedDate && (
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            marginRight={isXsScreen ? '5px' : '15px'}
                            onClick={() => {
                              setSelectedDate(null);
                              setDateRange({
                                startDate: new Date(),
                                endDate: new Date(),
                                key: 'selection',
                              });
                            }}
                          >
                            <CloseIcon
                              sx={{ fontSize: '14px', cursor: 'pointer' }}
                            />
                          </Box>
                        )}
                      </Grid>

                      {isDateRangeOpen && (
                        <ClickAwayListener
                          onClickAway={() => {
                            setIsDateRangeOpen((prev) => !prev);
                            setAnchorEl(null);
                          }}
                        >
                          <Popper
                            placement={
                              isXsScreen
                                ? 'bottom'
                                : isSmallScreen
                                ? 'left-end'
                                : 'bottom-start'
                            }
                            open={isDateRangeOpen}
                            anchorEl={anchorEl}
                            sx={{
                              border: '1px solid',
                              borderColor: 'rgba(0, 0, 0, 0.08)',
                              borderRadius: '15px',
                              overflow: 'hidden',
                            }}
                          >
                            <DateRangePicker
                              ranges={[dateRange]}
                              onChange={(value) => {
                                setDateRange(value.selection);
                                setSelectedDate({
                                  startDate: moment(
                                    value.selection.startDate
                                  ).format('DD/MM/YYYY'),
                                  endDate: moment(
                                    value.selection.endDate
                                  ).format('DD/MM/YYYY'),
                                });
                              }}
                            />
                          </Popper>
                        </ClickAwayListener>
                      )}
                      {/* <ReactDatePicker
                        value={typeof dateValue === 'string' && dateValue}
                        selected={typeof startDate === 'object' && startDate}
                        onChange={(date) => handelSelectDate(item.id, date)}
                      /> */}
                      {item.id && dateValue != 'Date' ? (
                        <CloseIcon
                          sx={{ fontSize: '14px', cursor: 'pointer' }}
                          onClick={() => handelClearDate(item.id)}
                        />
                      ) : (
                        <div></div>
                      )}
                    </Box>
                  </Grid>
                )}
                {item.type === 'select' && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={1.6}
                    sx={{ position: 'relative', cursor: 'pointer' }}
                    key={index}
                  >
                    <div
                      style={{
                        backgroundColor: 'white',
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        paddingLeft: '10px',
                        paddingRight: '10px',
                      }}
                      onClick={
                        findAns(item.id)
                          ? () => handelClear(item.id)
                          : (e) => handleFilterOpen(e, item.id)
                      }
                    >
                      <Typography
                        style={{
                          color: findAns(item.id) ? '#495057' : '#929DA6',
                          fontSize: '14px',
                        }}
                      >
                        {findAns(item.id) ? findAns(item.id).ans : item.title}
                      </Typography>
                      {findAns(item.id) ? (
                        <ClearIcon
                          sx={{ fontSize: '14px', padding: '10px' }}
                          onClick={() => handelClear(item.id)}
                        />
                      ) : item.isOpen ? (
                        <KeyboardArrowUpIcon sx={{ color: '#929da6' }} />
                      ) : (
                        <KeyboardArrowDownIcon sx={{ color: '#929da6' }} />
                      )}
                    </div>

                    {item.isOpen && (
                      <>
                        <ClickAwayListener
                          onClickAway={() => {
                            handleFilterOpen(null, item.id);
                          }}
                        >
                          <Grid
                            style={{
                              backgroundColor: 'white',
                              position: 'absolute',
                              zIndex: 2,
                              border: '1px solid #ddd',
                              padding: '5px',
                              boxSizing: 'border-box',
                              width: `${
                                activeFilterElement &&
                                activeFilterElement.offsetWidth
                              }px`,
                            }}
                            sx={{
                              width: { xs: '99.5%', md: '515px' },
                            }}
                          >
                            <TextField
                              sx={{
                                width: '100%',
                              }}
                              InputProps={{
                                disableUnderline: true,
                                style: {
                                  padding: '0',
                                  height: '40px',
                                  width: '100%',
                                },
                              }}
                              onChange={(e) =>
                                handelOnFilterOption(item.id, e.target.value)
                              }
                              value={item.search}
                              focused={false}
                            />

                            <div
                              style={
                                item.options.length > 7
                                  ? {
                                      maxHeight: '200px',
                                      overflowY: 'scroll',
                                    }
                                  : {
                                      maxHeight: '200px',
                                    }
                              }
                            >
                              {item.search
                                ? item.options
                                    .filter((op) =>
                                      op
                                        .toLowerCase()
                                        .includes(item.search.toLowerCase())
                                    )
                                    .map((option) => (
                                      <MenuItem
                                        key={option}
                                        onClick={() =>
                                          debouncedOnChangeHandler(item, option)
                                        }
                                        sx={{
                                          padding: '5px',
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            color: '#828F9A',
                                            fontSize: '14px',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                          }}
                                        >
                                          {option}
                                        </Box>
                                      </MenuItem>
                                    ))
                                : item.options.map((option) => (
                                    <MenuItem
                                      key={option}
                                      sx={{
                                        padding: '5px',
                                      }}
                                      onClick={() => {
                                        debouncedOnChangeHandler(item, option);
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          color: '#828F9A',
                                          fontSize: '14px',
                                          textOverflow: 'ellipsis',
                                          whiteSpace: 'nowrap',
                                          overflow: 'hidden',
                                        }}
                                      >
                                        {option}
                                      </Box>
                                    </MenuItem>
                                  ))}
                            </div>
                          </Grid>
                        </ClickAwayListener>
                      </>
                    )}
                  </Grid>
                )}
              </>
            );
          })}
        </Grid>
      ) : (
        <Drawer
          noHeaderBorder
          title={<div>{FilterButton}</div>}
          state={{ open: isFilterBar }}
          toggleDrawer={() => {
            setIsFitlerBar((prev) => !prev);
          }}
        >
          <Grid
            container
            item
            sx={{
              marginTop: 2,
              px: '41px',
              py: '32px',
            }}
            rowSpacing={{ xs: '16px' }}
            columns={{ xs: 12 }}
          >
            {filterOption.map((item, index) => {
              return (
                <>
                  {item.type === 'search' && (
                    <Grid item xs={12} sm={12} md={2}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: { xs: 'start', md: 'center' },
                          height: '40px',
                          boxShadow: '0 0 30px 0 rgb(0 0 0 / 5%)',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            width: '100%',
                            height: '100%',
                          }}
                        >
                          <input
                            type="text"
                            className="quickSearch"
                            placeholder="Search..."
                            onChange={(e) =>
                              debouncedOnChangeHandler(item, e.target.value)
                            }
                            style={{
                              width: '100%',
                              height: '100%',
                              fontSize: '14px',
                              boxSizing: 'border-box',
                              color: '#929DA6',
                              paddingLeft: '10px',
                              border: '#d6d6d6 solid 1px',
                              borderRadius: '5px',
                            }}
                          />
                        </div>
                      </div>
                    </Grid>
                  )}
                  {item.type === 'date' && (
                    <Grid item xs={12} sm={12} md={2}>
                      <Box
                        sx={{
                          backgroundColor: 'white',
                          height: '40px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          paddingX: '10px',
                          fontSize: '14px',
                          border: '#d6d6d6 solid 1px',
                          borderRadius: '5px',
                        }}
                      >
                        <ReactDatePicker
                          value={typeof dateValue === 'string' && dateValue}
                          selected={typeof startDate === 'object' && startDate}
                          onChange={(date) => handelSelectDate(item.id, date)}
                        />
                        {item.id && dateValue != 'Date' ? (
                          <CloseIcon
                            sx={{ fontSize: '14px', cursor: 'pointer' }}
                            onClick={() => handelClearDate(item.id)}
                          />
                        ) : (
                          <div></div>
                        )}
                      </Box>
                    </Grid>
                  )}
                  {item.type === 'select' && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={1.6}
                      sx={{ position: 'relative', cursor: 'pointer' }}
                      key={index}
                    >
                      <div
                        style={{
                          backgroundColor: 'white',
                          height: '40px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          paddingLeft: '10px',
                          paddingRight: '10px',
                          border: '#d6d6d6 solid 1px',
                          borderRadius: '5px',
                        }}
                        onClick={
                          findAns(item.id)
                            ? () => handelClear(item.id)
                            : (e) => handleFilterOpen(e, item.id)
                        }
                      >
                        <Typography
                          style={{
                            color: findAns(item.id) ? '#495057' : '#929DA6',
                            fontSize: '14px',
                          }}
                        >
                          {findAns(item.id) ? findAns(item.id).ans : item.title}
                        </Typography>
                        {findAns(item.id) ? (
                          <ClearIcon
                            sx={{ fontSize: '14px', padding: '10px' }}
                            onClick={() => handelClear(item.id)}
                          />
                        ) : item.isOpen ? (
                          <KeyboardArrowUpIcon sx={{ color: '#929da6' }} />
                        ) : (
                          <KeyboardArrowDownIcon sx={{ color: '#929da6' }} />
                        )}
                      </div>
                      {item.isOpen && (
                        <Grid
                          style={{
                            backgroundColor: 'white',
                            position: 'absolute',
                            zIndex: 1,
                            border: '1px solid #ddd',
                            padding: '5px',
                            boxSizing: 'border-box',
                            width: `${
                              activeFilterElement &&
                              activeFilterElement.offsetWidth
                            }px`,
                          }}
                          sx={{
                            width: { xs: '99.5%', md: '215px' },
                          }}
                        >
                          <TextField
                            sx={{
                              width: '100%',
                            }}
                            InputProps={{
                              disableUnderline: true,
                              style: {
                                padding: '0',
                                height: '40px',
                                width: '100%',
                              },
                            }}
                            onChange={(e) =>
                              handelOnFilterOption(item.id, e.target.value)
                            }
                            value={item.search}
                            focused={false}
                          />

                          <div
                            style={
                              item.options.length > 7
                                ? {
                                    maxHeight: '200px',
                                    overflowY: 'scroll',
                                  }
                                : {
                                    maxHeight: '200px',
                                  }
                            }
                          >
                            {item.search
                              ? item.options
                                  .filter((op) =>
                                    op
                                      .toLowerCase()
                                      .includes(item.search.toLowerCase())
                                  )
                                  .map((option) => (
                                    <MenuItem
                                      key={option}
                                      onClick={() =>
                                        debouncedOnChangeHandler(item, option)
                                      }
                                      sx={{
                                        padding: '5px',
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          color: '#828F9A',
                                          fontSize: '14px',
                                          textOverflow: 'ellipsis',
                                          whiteSpace: 'nowrap',
                                          overflow: 'hidden',
                                        }}
                                      >
                                        {option}
                                      </Box>
                                    </MenuItem>
                                  ))
                              : item.options.map((option) => (
                                  <MenuItem
                                    key={option}
                                    sx={{
                                      padding: '5px',
                                    }}
                                    onClick={() => {
                                      debouncedOnChangeHandler(item, option);
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        color: '#828F9A',
                                        fontSize: '14px',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                      }}
                                    >
                                      {option}
                                    </Box>
                                  </MenuItem>
                                ))}
                          </div>
                        </Grid>
                      )}
                    </Grid>
                  )}
                </>
              );
            })}
          </Grid>
        </Drawer>
      )}
    </>
  );
}

function useFilterBar({ filters }) {
  const [filterOption, setFilterOption] = useState(filters);
  const [state, dispatch] = useReducer(appointmentReducer, initalState);
  const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('lg'));
  const [isFilterBar, setIsFitlerBar] = useState(false);
  const textSearchRef = useRef();
  const handelSelect = (optionObj, option) => {
    const categoryId = optionObj.id;

    dispatch({
      type: 'SELECT',
      payload: {
        id: categoryId,
        ans: option,
        queryLabel: optionObj.queryLabel,
      },
    });
    const newArray = [...filterOption];
    const optionObject = newArray.find((array) => array.id === categoryId);
    optionObject.isOpen = false;
    setFilterOption(newArray);
  };

  // debounced search event
  const debouncedOnChangeHandler = useMemo(
    () => debounce(handelSelect, 300),
    []
  );

  //cleanup for debounce
  useEffect(() => {
    return () => {
      debouncedOnChangeHandler.cancel();
    };
  }, []);

  const clearFilters = () => {
    dispatch({
      type: 'CLEAR_ALL',
    });
    textSearchRef.current.value = '';
  };

  return {
    FilterBar: (
      <FilterBar
        state={state}
        dispatch={dispatch}
        filterOption={filterOption}
        setFilterOption={setFilterOption}
        debouncedOnChangeHandler={debouncedOnChangeHandler}
        isSmallScreen={isSmallScreen}
        setIsFitlerBar={setIsFitlerBar}
        isFilterBar={isFilterBar}
        textSearchRef={textSearchRef}
        FilterButton={
          <FilterButton
            setIsFitlerBar={setIsFitlerBar}
            isSmallScreen={isSmallScreen}
            clearFilters={clearFilters}
            isInsideResponsiveModal={true}
          />
        }
      />
    ),
    FilterButton: (
      <FilterButton
        setIsFitlerBar={setIsFitlerBar}
        isSmallScreen={isSmallScreen}
        clearFilters={clearFilters}
      />
    ),
    state,
  };
}

export default useFilterBar;
