import { UpnizeBackend } from '../Axios';

export const getStaffList = async (rows, page, queries) => {
  let response = await UpnizeBackend.get(
    `/staff?rows=${rows}&page=${page}${queries ? queries : ''}`
  );
  return response;
};

export const getStaffById = async (id) => {
  let response = await UpnizeBackend.get(`/staff/byId/${id}`);
  return response;
};

export const addStaff = async (body) => {
  let response = await UpnizeBackend.post(`/staff/add`, body);
  return response;
};

export const editStaffById = async (body, id) => {
  let response = await UpnizeBackend.put(`/staff/edit/${id}`, body);
  return response;
};

export const deleteStaffById = async (id) => {
  let response = await UpnizeBackend.delete(`/staff/delete`, {
    data: { ids: id },
  });
  return response;
};
