import { useState } from 'react';
import { Grid, Switch, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { styled } from '@mui/material/styles';
import SaveSlideBar from '../../SaveSlideBar';
import { editSettings } from '../../../Api/tenant/settings';
import CustomSettingsTitle from '../../CustomSettingsTitle/CustomSettingsTitle';

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 36,
  height: 20.5,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 1.5,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        // backgroundColor: theme.palette.mode === "dark" ? "#8468FA" : "#8468FA",
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 17,
    height: 17,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const Notifications = ({ fetchCurrentSettings, currentSettings }) => {
  const [isSaveBar, setIsSaveBar] = useState(false);
  const [notificationsSettings, setNotificationsSettings] = useState({
    all_status_appointments: true,
    send_only_when_appointment_created: true,
    press_release_news: true,
    blogs_and_knowledge: true,
  });

  const handleSave = () => {
    editSettings({
      ...currentSettings,
      notifications: {
        ...notificationsSettings,
      },
    }).then((res) => {
      if (res.status === 200) {
        fetchCurrentSettings().then(() => {
          setIsSaveBar(false);
        });
      }
    });
  };

  const handleCancel = () => {
    setNotificationsSettings(currentSettings?.notifications || {});
    setIsSaveBar(false);
  };
  return (
    <>
      <Grid
        xs={12}
        sx={{
          marginTop: { xs: 9, sm: 14 },
          backgroundColor: 'white',
          pb: 1,
          mb: 5,
        }}
      >
        <Grid
          sx={{
            padding: { xs: '15px', md: '15px 25px' },
            borderBottom: '1px solid #e3eaf3',
          }}
        >
          {/* <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>
            Account Settings{' '}
            <span style={{ color: '#c1c1c1' }}>
              <CircleIcon sx={{ fontSize: '5px', marginX: '3px' }} />{' '}
              Notifications
            </span>
          </Typography>*/}
          <CustomSettingsTitle title="Account Settings" subTitle="Notifications" />
        </Grid>
        <Grid container sx={{ padding: { xs: '15px', md: '15px 25px' } }}>
          <Typography variant="subtitle2" mb={2}>
            Select Which events in Upnize App that you would like to receive
            e-mail notifications about.
          </Typography>
          <Grid
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            <Grid item xs={12} md={5.9}>
              <Typography variant="subtitle2" fontWeight={700} mb={1}>
                All status appointment
              </Typography>

              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  border: '1px solid rgba(0, 0, 0, 0.23)',
                  borderRadius: '4px',
                  paddingX: 2,
                  paddingY: 1,
                  height: '40px',
                }}
                onClick={() => {
                  setNotificationsSettings((prev) => {
                    return {
                      ...prev,
                      all_status_appointments: !prev.all_status_appointments,
                    };
                  });
                  setIsSaveBar(true);
                }}
              >
                <Typography variant="subtitle2">Request email</Typography>

                <IOSSwitch
                  size="small"
                  sx={{ marginLeft: 2 }}
                  name="all_status_appointments"
                  checked={notificationsSettings.all_status_appointments}
                />
              </Grid>
            </Grid>
            {!notificationsSettings.all_status_appointments && (
              <Grid item xs={12} md={5.9}>
                <Typography
                  variant="subtitle2"
                  fontWeight={700}
                  mb={1}
                  sx={{ mt: { xs: 2, md: 0 } }}
                >
                  Send only when appointment is created
                </Typography>

                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    border: '1px solid rgba(0, 0, 0, 0.23)',
                    borderRadius: '4px',
                    paddingX: 2,
                    paddingY: 1,
                    height: '40px',
                  }}
                  onClick={() => {
                    setNotificationsSettings((prev) => {
                      return {
                        ...prev,
                        send_only_when_appointment_created:
                          !prev.send_only_when_appointment_created,
                      };
                    });
                    setIsSaveBar(true);
                  }}
                >
                  <Typography variant="subtitle2">Request email</Typography>
                  <IOSSwitch
                    size="small"
                    sx={{ marginLeft: 2 }}
                    name="send_only_when_appointment_created"
                    checked={
                      notificationsSettings.send_only_when_appointment_created
                    }
                  />
                </Grid>
              </Grid>
            )}
          </Grid>

          <Typography variant="subtitle2" mt={6} mb={2}>
            Select Which events in Upnize.com that you would like to receive
            e-mail notifications about.
          </Typography>
          <Grid
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            <Grid item xs={12} md={5.9}>
              <Typography variant="subtitle2" fontWeight={700} mb={1}>
                Press release news
              </Typography>

              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  border: '1px solid rgba(0, 0, 0, 0.23)',
                  borderRadius: '4px',
                  paddingX: 2,
                  paddingY: 1,
                  height: '40px',
                }}
                onClick={() => {
                  setNotificationsSettings((prev) => {
                    return {
                      ...prev,
                      press_release_news: !prev.press_release_news,
                    };
                  });
                  setIsSaveBar(true);
                }}
              >
                <Typography variant="subtitle2">Request email</Typography>
                <IOSSwitch
                  size="small"
                  sx={{ marginLeft: 2 }}
                  name="press_release_news"
                  checked={notificationsSettings.press_release_news}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={5.9}>
              <Typography
                variant="subtitle2"
                fontWeight={700}
                mb={1}
                sx={{ mt: { xs: 2, md: 0 } }}
              >
                Blogs and Upnize knowledge center
              </Typography>

              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  border: '1px solid rgba(0, 0, 0, 0.23)',
                  borderRadius: '4px',
                  paddingX: 2,
                  paddingY: 1,
                  height: '40px',
                }}
                onClick={() => {
                  setNotificationsSettings((prev) => {
                    return {
                      ...prev,
                      blogs_and_knowledge: !prev.blogs_and_knowledge,
                    };
                  });
                  setIsSaveBar(true);
                }}
              >
                <Typography variant="subtitle2">Request email</Typography>
                <IOSSwitch
                  size="small"
                  sx={{ marginLeft: 2 }}
                  name="blogs_and_knowledge"
                  checked={notificationsSettings.blogs_and_knowledge}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SaveSlideBar
        handleCancel={handleCancel}
        handleSave={handleSave}
        isSaveBar={isSaveBar}
        setIsSaveBar={setIsSaveBar}
      />
    </>
  );
};

export default Notifications;
