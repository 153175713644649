import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const Coupons = () => {
  return (
    <Grid
      sx={{
        boxShadow: " rgba(149, 157, 165, 0.2) 0px 8px 24px",
        padding: "2rem",
        margin: "1rem",
      }}
    >
      <Grid container>
        <Grid xs={12} sm={4} item>
        <Typography color="#6c70dc">Coupon code</Typography>
        </Grid>
        <Grid xs={12} sm={8} item>
        <Typography color="#6c70dc">Coupon code</Typography>
        </Grid>
      </Grid>
      <Grid container marginTop=".5rem">
        <Grid xs={12} sm={4} item>
        <Box backgroundColor="#c7cfd9" color="#fff" padding="0.25rem" fontSize="14px" sx={{display:'inline-block'}}>N/A</Box>
        </Grid>
        <Grid xs={12} sm={8} item>
        <Typography color="#c7cfd9">N/A</Typography>
        </Grid>
      </Grid>
      
    </Grid>
  );
};

export default Coupons;
