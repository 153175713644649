import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import styled from "@emotion/styled";
import { useState } from "react";
import Extras from "./Extras";
import AppointmentDetails from "./AppointmentDetails";
import Coupon from "./Coupon";
import Rating from "./Rating";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledTab = styled(Tab)({
  fontSize: "12px",
  fontWeight: "bold",
  padding: 0,
});

export default function ServiceTab({ drawerType,categoryies }) {
  const [serviceData, setServiceData] = useState({
    isDeposit: false,
    isRecurring: false,
    isFullPeriod: false,
    isFixedFrequency: false,
  });
  const [staffs, setStaffs] = useState([
    { user: "Alex Gender", Isprice: false, price: 0, deposite: 0, method: "%" },
  ]);
  const [setting, setSetting] = useState({ isOpenPanel: false, method: [] });

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", mx: 3, mt: 2 }}>
        <Tabs sx={{ fontSize: "10px" }} value={value} onChange={handleChange}>
          <StyledTab label="Appointment details" {...a11yProps(0)} />
          <StyledTab label="Extras" {...a11yProps(1)} />
          <StyledTab label="Coupons" {...a11yProps(2)} />
          {drawerType === "edit" && <StyledTab label="Rating" {...a11yProps(3)} /> }
          
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <AppointmentDetails
          serviceData={serviceData}
          setServiceData={setServiceData}
          categoryies={categoryies}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Extras />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Coupon />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Rating />
      </TabPanel>
    </Box>
  );
}
