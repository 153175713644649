export const tempData = {
  coloum: [
    {
      id: 1,
      name: "Alexender Inci",
      location: "tomtbergavagen 100, Stockholm, sweden",
      category: "4D Ultrsound",
      phone: "2345 234 234",
      services: [
        "Service1sssssssssss",
        "Service2",
        "Service3",
        "Service5",
        "Service6",
        "Servicesss",
        "Servicesss",
        "Servicesss",
        "Servicesss",
      ],
      isFavorite: true,
      duration: "2 hours",
      notes: [
        {
          name: "Name",
          location: "3 days ago",
          body: "Your time at 2nd April is cancelled. I could help you booking a new appointment. let me know? ",
          image: "url",
        },
        {
          name: "name",
          location: "location",
          body: "body data",
          image: "url",
        },
        {
          name: "name",
          location: "location",
          body: "body data",
          image: "url",
        },
        {
          name: "name",
          location: "location",
          body: "body data",
          image: "url",
        },
        {
          name: "name",
          location: "location",
          body: "body data",
          image: "url",
        },
        {
          name: "name",
          location: "location",
          body: "body data",
          image: "url",
        },
      ],
    },
    {
      id: 2,
      name: "Inci Alexender very long name ssssssssss",
      location: "09/07/2022 04:00 AM",
      category: "4D Ultrsound",
      services: [
        "Service1",
        "Service2",
        "Service3",
        "Service3",
        "Service5",
        "Service6",
        "Servicesss",
      ],
      duration: "2 hours",
    },

    {
      id: 3,
      name: "Inci Alexender",
      location: "09/07/2022 04:00 AM",
      category: "4D Ultrsound",
      phone: "2345 234 234",
      services: [
        "Service1",
        "Service2",
        "Service3",
        "Service5",
        "Service6",
        "Servicesss",
      ],
      notes: [
        {
          name: "Name",
          location: "10 days ago",
          body: "you booking a new appointment. let me know? ",
          image: "url",
        },
        {
          name: "name",
          location: "location",
          body: "body data, you booking a new appointment. ",
          image: "url",
        },
      ],
    },

    {
      id: 4,
      name: "Inci Alexender",
      location: "09/07/2022 04:00 AM",
      category: "4D Ultrsound",
      services: [
        "Service1",
        "Service2",
        "Service3",
        "Service5",
        "Service6",
        "Servicesss",
      ],
      isFavorite: true,
    },

    {
      id: 5,
      name: "Inci Alexender",
      location: "09/07/2022 04:00 AM",
      category: "4D Ultrsound",
      phone: "2345 234 234",
      services: [
        "Service1",
        "Service2",
        "Service3",
        "Service5",
        "Service6",
        "Servicesss",
      ],
    },

    {
      id: 6,
      name: "Inci Alexender",
      location: "09/07/2022 04:00 AM",
      category: "4D Ultrsound",
      services: [
        "Service1",
        "Service2",
        "Service3",
        "Service5",
        "Service6",
        "Servicesss",
      ],
      duration: "2 hours",
    },
  ],
};
