import { UpnizeBackend } from '../Axios';

export const getGiftCardList = async (rows, page, queries) => {
  let response = await UpnizeBackend.get(
    `/giftcards?rows=${rows}&page=${page}${queries ? queries : ''}`
  );
  return response;
};

export const getGiftCardById = async (id) => {
  let response = await UpnizeBackend.get(`/giftcards/byId/${id}`);
  return response;
};

export const addGiftCard = async (body) => {
  let response = await UpnizeBackend.post(`/giftcards/add`, body);
  return response;
};

export const editGiftCardById = async (body, id) => {
  let response = await UpnizeBackend.put(`/giftcards/edit/${id}`, body);
  return response;
};

export const deleteGiftCardById = async (id) => {
  let response = await UpnizeBackend.delete(`/giftcards/delete`, {
    data: { ids: id },
  });
  return response;
};
