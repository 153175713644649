import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import {
  IconButton,
  Menu,
  MenuItem,
  TableFooter,
  Typography,
} from '@mui/material';
import CustomTextButton from '../../components/CustomButtons/CustomTextButton';
import AddJournal from './AddJournal';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { GridActionsCellItem } from '@mui/x-data-grid';
import AvatarComp from '../../components/Avatar';
import useUser from '../../hooks/useUser';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import dayjs from 'dayjs';
import Modal from '../../components/Modal';
import { deleteJournalById } from '../../Api/tenant/customers';
import { formatDate } from '../../utils/formatDate';

const ThreeDotsMenu = ({ setOpenModal, inf, handleOpenEditDrawer }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <MoreVertIcon
        id="demo-positioned-button"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{
          backgroundColor: 'white',
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
          borderRadius: '4px',
          padding: '3px',
          cursor: 'pointer',
        }}
      />
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={handleOpenEditDrawer(inf.id)}>Edit</MenuItem>
        <MenuItem
          onClick={() =>
            setOpenModal({
              open: true,
              type: 'delete-modal',
              id: inf.id,
            })
          }
        >
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};
const headCells = [
  {
    id: 1,
    numeric: false,
    disablePadding: true,
    label: 'TITLE',
  },
  {
    id: 2,
    numeric: true,
    disablePadding: false,
    label: 'ATTACHMENTS',
  },
  {
    id: 3,
    numeric: true,
    disablePadding: false,
    label: 'LAST MODIFIED',
  },
  {
    id: 4,
    numeric: true,
    disablePadding: false,
    label: 'CREATED BY',
  },
  {
    id: 5,
    numeric: true,
    disablePadding: false,
    label: 'CREATED',
  },
  {
    id: 6,
    numeric: true,
    disablePadding: false,
    label: 'ACTIONS',
  },
];

function EnhancedTableHead() {
  return (
    <TableHead
      sx={{
        margin: '10px',
        backgroundColor: '#EFF1FC',
      }}
    >
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell key={headCell.id} align={index === 5 ? 'right' : 'left'}>
            <TableSortLabel
              sx={{
                fontWeight: 'bold',
                whiteSpace: 'nowrap',
                fontSize: '12px',
                color: '#3A3A50 !important',
              }}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const JournalAttachment = ({ customerJournals = [], fetchJournals }) => {
  const { user } = useUser();
  const [openModal, setOpenModal] = useState({ open: false, type: null });
  const [drawerType, setDrawerType] = useState('add');
  const [drawerData, setDrawerData] = useState(null);

  const [addJournalDrawer, setAddJournalDrawer] = useState(false);
  const toggleAddJournalDrawer = () => {
    setAddJournalDrawer(!addJournalDrawer);
  };
  const [selected, setSelected] = useState(0);

  const handleOpenModal = () => {
    setOpenModal({ open: false, type: null });
  };

  const handleDeleteJournal = async (id) => {
    try {
      await deleteJournalById(id);
      await fetchJournals();
    } catch (err) {
      console.log(err);
    }
  };

  const handleOpenEditDrawer = (id) => () => {
    const customerJournal = customerJournals?.find(
      (journal) => journal.id === id
    );
    if (!customerJournal) return;
    setDrawerType('edit');
    setDrawerData(customerJournal);
    toggleAddJournalDrawer();
  };

  const TableData = () =>
    customerJournals.map((inf, index) => {
      return (
        <>
          <TableRow
            hover
            key={inf.id}
            sx={{
              borderBottom: 'none',
            }}
          >
            <TableCell
              component="th"
              scope="row"
              padding="none"
              sx={{
                pl: '20px',
                minWidth: '200px',
                maxWidth: '250px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: '10px',
                }}
              >
                <Box
                  aria-label="expand row"
                  // size="small"
                  onClick={() => {
                    selected === inf.id ? setSelected(0) : setSelected(inf.id);
                    // setOpen(!open);
                  }}
                >
                  {selected === inf.id ? (
                    <KeyboardArrowDownIcon
                      sx={{
                        backgroundColor: '#1976d2',
                        color: '#fff',
                        fontSize: '22px',
                        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
                        cursor: 'pointer',
                        borderRadius: '4px',
                      }}
                    />
                  ) : (
                    <KeyboardArrowRightIcon
                      sx={{
                        color: '#1976d2',
                        fontSize: '22px',
                        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
                        cursor: 'pointer',
                        borderRadius: '4px',
                      }}
                    />
                  )}
                </Box>

                {inf.title}
              </Box>
            </TableCell>
            <TableCell
              align="left"
              sx={{ whiteSpace: 'nowrap', maxWidth: '300px', width: '250px' }}
            >
              <Box sx={{ width: '100%', display: 'flex', gap: '10px' }}>
                {inf.attachments.map((img) => (
                  <Box
                    sx={{
                      width: '30px',
                      height: '30px',
                    }}
                  >
                    <a
                      href={
                        process.env.REACT_APP_UPNIZE_BACKEND_URL +
                        '/apps/backend/' +
                        img.path
                      }
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img
                        src={
                          process.env.REACT_APP_UPNIZE_BACKEND_URL +
                          '/apps/backend/' +
                          img.path
                        }
                        alt="journal-img"
                        style={{
                          width: '100%',
                          height: '100%',
                        }}
                      />
                    </a>
                  </Box>
                ))}
              </Box>
            </TableCell>
            <TableCell
              align="left"
              sx={{
                maxWidth: '200px',
                width: '200px',
              }}
            >
              {inf.updatedBy
                ? `${inf.updatedBy?.firstName} ${inf.updatedBy?.lastName}`
                : '-'}{' '}
              | {formatDate(inf.updatedAt)}
            </TableCell>
            <TableCell
              align="left"
              sx={{ whiteSpace: 'nowrap', maxWidth: '200px', width: '200px' }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AvatarComp
                  img={user?.image}
                  username={
                    inf.createdBy
                      ? `${inf.createdBy?.firstName} ${inf.createdBy?.lastName}`
                      : '-'
                  }
                />
                <Typography fontSize="14px" marginLeft={'10px'} color="inherit">
                  {inf.createdBy
                    ? `${inf.createdBy?.firstName} ${inf.createdBy?.lastName}`
                    : '-'}
                </Typography>
              </Box>
            </TableCell>
            <TableCell
              align="left"
              sx={{
                maxWidth: '150px',
                width: '150px',
              }}
            >
              {formatDate(inf.createdAt)}
            </TableCell>
            <TableCell
              align="right"
              sx={{
                maxWidth: '30px',
                width: '30px',
              }}
            >
              <ThreeDotsMenu
                inf={inf}
                setOpenModal={setOpenModal}
                handleOpenEditDrawer={handleOpenEditDrawer}
              />
            </TableCell>
          </TableRow>
          {selected === inf.id && (
            <TableRow
              sx={{
                borderTop: 'none',
              }}
            >
              <TableCell colSpan={6}>
                <Collapse
                  in={selected === inf.id}
                  timeout="auto"
                  unmountOnExit
                  sx={{
                    padding: '10px',
                    border: '1px solid #eee',
                  }}
                >
                  {inf.description}
                </Collapse>
              </TableCell>
            </TableRow>
          )}
        </>
      );
    });

  const columns = [
    {
      field: 'title',
      headerName: 'TITLE',
      minWidth: 280,
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            gap: '10px',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '10px',
              alignItems: 'center',
            }}
          >
            <IconButton
              onClick={() => {
                selected === params.row.id
                  ? setSelected(0)
                  : setSelected(params.row.id);
                // setOpen(!open);
              }}
              aria-label="expand"
              size="small"
            >
              {selected === params.row.id ? (
                <KeyboardArrowDownIcon
                  sx={{
                    backgroundColor: '#1976d2',
                    color: '#fff',
                    fontSize: '22px',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
                    cursor: 'pointer',
                    borderRadius: '4px',
                  }}
                />
              ) : (
                <KeyboardArrowRightIcon
                  sx={{
                    color: '#1976d2',
                    fontSize: '22px',
                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
                    cursor: 'pointer',
                    borderRadius: '4px',
                  }}
                />
              )}
            </IconButton>
            {params.row.title}
          </Box>
          <Box
            sx={{
              border: selected === params.row.id ? '1px solid #eee' : '0px',
              width: '100%',
              padding: selected === params.row.id ? '10px' : '0px',
              mr: '5px',
            }}
          >
            <Collapse
              in={selected === params.row.id}
              timeout="auto"
              unmountOnExit
            >
              {params.row.desc}
            </Collapse>
          </Box>
        </Box>
      ),
    },
    {
      field: 'attachments',
      headerName: 'ATTACHMENTS',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <Box sx={{ width: '100%', display: 'flex', gap: '10px' }}>
          {params.row.attachments.map((img) => (
            <Box
              sx={{
                width: '30px',
                height: '30px',
              }}
            >
              <img
                src={img}
                style={{
                  width: '100%',
                  height: '100%',
                }}
              />
            </Box>
          ))}
        </Box>
      ),
    },
    {
      field: 'lastModified',
      headerName: 'LAST MODIFIED',
      flex: 1,
      minWidth: 10,
    },
    {
      field: 'UserId',
      headerName: 'CREATED BY',
      flex: 1,
      minWidth: 190,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <AvatarComp
            img={user?.image}
            username={
              params.row.UserId === user?.id
                ? `${user?.firstName} ${user?.lastName}`
                : 'David Hatem'
            }
          />
          <Typography fontSize="14px" marginLeft={'10px'} color="inherit">
            {params.row.UserId === user?.id
              ? `${user?.firstName} ${user?.lastName}`
              : 'David Hatem'}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'createdAt',
      headerName: 'CREATED',
      flex: 1,
      minWidth: 100,
      maxWidth: 300,
      renderCell: (params) => {
        let d = new Date(),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
        return [year, month, day].join('-');
      },
    },
    {
      field: 'actions',
      headerName: 'ACTIONS',
      type: 'actions',
      align: 'right',
      minWidth: 120,
      getActions: (params) => {
        if (params.row.isHidden) {
          return [
            <GridActionsCellItem
              icon={<VisibilityOffIcon />}
              label="Hide Section"
            />,
            <GridActionsCellItem
              label="Edit"
              onClick={() => {
                setDrawerType('editJournal');
                toggleAddJournalDrawer();
              }}
              showInMenu
            />,

            <GridActionsCellItem label="Remove" showInMenu />,
          ];
        }
        return [
          <GridActionsCellItem
            label="Edit"
            onClick={() => {
              setDrawerType('editJournal');
              toggleAddJournalDrawer();
            }}
            showInMenu
          />,
          <GridActionsCellItem label="Remove" showInMenu />,
        ];
      },
    },
  ];

  return (
    <Box sx={{ width: '100%', zIndex: 0 }}>
      {openModal.type === 'delete-modal' && (
        <Modal
          handleOpenModal={handleOpenModal}
          type={'DELETE'}
          open={openModal.open}
          id={openModal.id}
          handleDelete={handleDeleteJournal}
        />
      )}
      {addJournalDrawer && (
        <AddJournal
          toggleDrawer={toggleAddJournalDrawer}
          drawerType={drawerType}
          drawerData={drawerData}
          fetchJournals={fetchJournals}
        />
      )}
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          gap: '15px',
        }}
      >
        <CustomTextButton
          text="+ Create journal"
          onClick={() => {
            setDrawerType('add');
            toggleAddJournalDrawer();
          }}
        />
      </Box>

      <Paper
        sx={{
          width: '100%',
          mt: '12px',
          mb: 2,
          fontSize: '14px',
          shadow: 'none !important',
        }}
      >
        <TableContainer
          sx={{
            shadow: 'none !important',
          }}
        >
          <Table
            sx={{ minWidth: 750, shadow: 'none !important' }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead />
            <TableBody
              sx={{
                shadow: 'none !important',
              }}
            >
              {customerJournals.length ? <TableData /> : null}
            </TableBody>
            <TableFooter
              sx={{
                shadow: 'none !important',
              }}
            >
              {customerJournals.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    sx={{
                      py: '50px',
                    }}
                  >
                    <Box
                      sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'Center',
                        gap: '20px',
                      }}
                    >
                      <img
                        src="https://user-images.githubusercontent.com/109676648/254145031-57027cd6-fecd-4ce2-96fc-9d891c8de53a.svg"
                        alt="no data"
                        style={{
                          width: '100px',
                          height: '100px',
                        }}
                      />
                      No data at the moment 😔
                    </Box>
                  </TableCell>
                </TableRow>
              ) : null}
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default JournalAttachment;
