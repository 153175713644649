import React, { useEffect, useState } from "react";

import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import CloseIcon from "@mui/icons-material/Close";

const UsageHistory = ({toggleDrawer
}) => {

  return (
    <Grid container>
      {/* sx={{ width: "100vw"}} */}
      <Grid item xs={12} sm={2}>
        <Drawer
          variant="temporary"
          anchor="right"
          open={true}
          onClose={() => {}}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: { xs: "100vw", md: "550px" },
              backgroundColor: "white",
            },
          }}
        >
          <Grid item container>
            {/* -----------HEADING */}
            <Grid
              item
              container
              sx={{ borderBottom: "1px solid #e3eaf3", paddingX: 3 }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContents: "space-between",
                  alignItems: "center",
                  height: "81px",
                }}
              >
                <Grid
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContents: "space-between",
                    alignItems: "center",
                  }}
                >
                  <img src="https://demo.booknetic.com/saas/wp-content/plugins/booknetic/app/Backend/Base/assets/icons/add-employee.svg" alt="" />
                  <Typography
                    sx={{
                      fontSize: 22,
                      fontWeight: 500,
                      marginLeft: 2,
                      color: "#292D32",
                    }}
                  >
                    Usage history
                  </Typography>
                </Grid>
                <CloseIcon
                  sx={{ color: "#ADBFC7", cursor: "pointer" }}
                  onClick={toggleDrawer}
                />
              </Grid>
            </Grid>
            {/* -----------FORM----- */}
            <Grid
              sx={{
                paddingX: 3,
                paddingTop: 3,
                height: "77vh",
                overflowY: "auto",
              }}
            >
              <Grid container>
                <Typography variant="subtitle1">Coupon is not used yet!</Typography>
              </Grid>
            </Grid>
            {/* -----Button */}
            <Grid
              item
              container
              sx={{
                display: "flex",
                justifyContent: "space-between",
                boxShadow: "5px -2px 10px -5px #e4ebf4",
                borderTop: "1px solid #E4EBF4",
                bottom: 5,
                paddingTop: 1,
                backgroundColor: "white",
                paddingX: 2,
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    width: { xs: "100%", sm: "auto" },
                    marginTop: 2,
                    marginBottom: 1,
                    backgroundColor: "WHITE",
                    color: "GREY",
                    ":hover": { backgroundColor: "GREY", color: "WHITE" },
                  }}
                  onClick={toggleDrawer}
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Drawer>
      </Grid>
    </Grid>
  );
};

export default UsageHistory;