import * as React from 'react';
import Stack from '@mui/material/Stack';
import notification from "../../assets/images/notifications.png";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CustomTextButton from '../../components/CustomButtons/CustomTextButton';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';

const UpsellNotification = () => {
  return (  
    <React.Fragment>
      <Container maxWidth="sm">
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center', 
          marginTop: '10%',
          paddingBottom: '5%',
          textAlign: 'center',
  
          '& img': {
            width: '100%',
            maxWidth: '80%',
            height: 'auto',
            padding: '10px', 
          },
          '@media (max-width: 600px)': {
            '& img': {
              padding: '10px',
            },
          },
        }}>
          <img
            src={notification}
            alt="persons image"
          />
          <h1>Email notifications</h1>
          <Typography >Upgrade to our email notification feature to keep both your customers and staff informed
           about the latest events effortlessly. Customize emails effortlessly using our intuitive WYSIWYG format.
           Elevate your communication strategy and streamline your operations today.</Typography>

          <Stack spacing={2} direction="row" marginTop={4}>
            <Link to="https://upnize.com/help-center/email-notifications">
            
            <CustomTextButton sx={{textTransform: 'capitalize'}}
                  variant="contained"
                  size="large"
                >
                  Learn more
                </CustomTextButton>
            </Link>
            <Link to="/subscription">
                <CustomTextButton sx={{textTransform: 'capitalize'}}
                  type="blue"
                  variant="contained"
                  size="large" >
                    Upgrade
                  </CustomTextButton>
            </Link>
          </Stack>
        </Box>
      </Container>
    </React.Fragment>
  );
}

export default UpsellNotification;
