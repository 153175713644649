import { UpnizeBackend } from '../Axios';

export const getTaskList = async (rows, page, searchQuery) => {
  let response = await UpnizeBackend.get(`/tasks`);
  /*   let response = await UpnizeBackend.get(
    `/tasks?rows=${rows}&page=${page}${
      searchQuery ? `&keyword=${searchQuery}` : ''
    }`
  ); */
  return response;
};

// Used in tasks dropdownmenu
export const getDropdownTaskList = async (id) => {
  let response = await UpnizeBackend.get(`/tasks/dropdown`);
  return response;
};

export const getTaskById = async (id) => {
  let response = await UpnizeBackend.get(`/tasks/byId/${id}`);
  return response;
};

export const addTask = async (body) => {
  let response = await UpnizeBackend.post(`/tasks/add`, body);
  return response;
};

export const editTaskById = async (body, id) => {
  let response = await UpnizeBackend.put(`/tasks/edit/${id}`, body);
  return response;
};

export const deleteTaskById = async (id) => {
  let response = await UpnizeBackend.delete(`/tasks/delete`, {
    data: { ids: id },
  });
  return response;
};
