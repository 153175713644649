import {
  Avatar,
  Box,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import classes from "./Details.module.css";
import StarIcon from "@mui/icons-material/Star";

const tempOpening = [
  { day: "Monday", time: "09:00 AM - 6:00 PM" },
  { day: "Tuesday", time: "09:00 AM - 6:00 PM" },
  { day: "Wednesday", time: "09:00 AM - 6:00 PM" },
  { day: "Thursday", time: "09:00 AM - 6:00 PM" },
  { day: "Friday", time: "09:00 AM - 6:00 PM" },
  { day: "Saturday", time: "closed" },
  { day: "Sunday", time: "closed" },
];

const tempService = [
  "Service1ssss",
  "Service2",
  "some diff text",
  "Service3",
  "Service5",
  "Service6",
  "Servicesss",
  "some diff text",
  "Servicesss",
  "Servicesss",
  "Servicesss",
  "Service2",
  "some diff text",
  "Service5",
  "Service6",
  "Servicesss",
  "some diff text",
  "Servicesss",
  "Servicesss",
  "Service2",
  "Service5",
  "Service6",
  "some diff text",
  "Servicesss",
  "Servicesss",
  "Service2",
  "Service3",
  "Service5",
  "some diff text",

  "Service6",
  "Servicesss",
  "Servicesss",
  "some diff text",
  "Service2",
  "Service3",
  "Service6",
  "some diff text",
  "Servicesss",
  "Servicesss",
  "Servicesss",
];

const tempComments = [
  {
    name: "Ronald Henriksson",
    date: "for 3 days ago",
    stars: 3,
    body: "great resturant ever! I highly recommend to everyone who love food",
  },
  {
    stars: 5,
    name: "Ronald Henriksson",
    date: "for 3 days ago",
    body: "Your time at 2nd april is cancelled. I could help you booking a new appointment. let me know?",
  },
  {
    stars: 1,
    name: "Ronald Henriksson",
    date: "for 3 days ago",
    body: "Your time at 2nd april is cancelled. I could help you booking a new appointment. let me know?",
  },
];

function Details() {
  return (
    <Box>
      <Grid
        xs={12}
        sx={{ marginTop: "30px" }}
        className={classes.info__container}
      >
        <Grid xs={12} md={6} className={classes.info__row}>
          <Typography className={classes.info__title}>Company name</Typography>
          <Typography className={classes.info__value} fontWeight={700}>
            Dynamite carwash
          </Typography>
          <Typography
            className={classes.info__value}
            color={"#9880ED !important"}
          >
            contact@dynamite
          </Typography>
          <Typography
            className={classes.info__value}
            color={"#9880ED !important"}
          >
            www.dynamite.com
          </Typography>
          <Typography
            className={classes.info__value}
            color={"#9880ED !important"}
          >
            23525 21334 23423
          </Typography>
          <Grid xs={12} marginTop={2}>
            <Typography className={classes.info__title}>Location</Typography>

            <Typography className={classes.info__value}>
              tomtbergavagen 100m,211231 norsborg, stockholm, sweden
            </Typography>
          </Grid>
        </Grid>

        <Grid xs={12} md={6} className={classes.info__row}>
          <Typography className={classes.info__title}>Opening hour</Typography>
          <Box display={"flex"}>
            <Grid xs={3.5}>
              {tempOpening.map((obj) => {
                return (
                  <Typography className={classes.info__value}>
                    {obj.day}
                  </Typography>
                );
              })}
            </Grid>
            <Grid xs={6}>
              {tempOpening.map((obj) => {
                return (
                  <Typography
                    className={classes.info__value}
                    color={obj.time === "closed" && "#E45F88"}
                  >
                    {obj.time}
                  </Typography>
                );
              })}
            </Grid>
          </Box>
        </Grid>
      </Grid>
      {/* services */}
      <Grid className={classes.service__section}>
        <Typography className={classes.info__title}>Service</Typography>
        <Grid className={classes.service__container}>
          {tempService.map((s, i) => {
            return <Box className={classes.service__chip}>{s}</Box>;
          })}
        </Grid>
      </Grid>
      {/* reviews/comments */}
      <Box sx={{ mt: "30px" }}>
        <Typography sx={{ mb: "15px" }} className={classes.info__title}>
          Customers reviews
        </Typography>

        {tempComments.map((c) => {
          return (
            <Box className={classes.comment__container}>
              <Box className={classes.comment__title__container}>
                <Typography className={classes.comment__title}>
                  {c.name}
                </Typography>
                <Typography className={classes.comment__secondary__title}>
                  {c.date}
                </Typography>
              </Box>
              <Box display={"flex"}>
                {Array(c.stars)
                  .fill()
                  .map(() => (
                    <StarIcon sx={{ fontSize: 20, p: 0, m: 0 }} />
                  ))}
              </Box>
              <Typography>{c.body}</Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

export default Details;
