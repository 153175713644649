import { useLayoutEffect, useState } from 'react';
import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'react-phone-number-input/style.css';
import ProfileImageField from '../../ProfileImageField';
import countrydata from './Countrydata.json';
import SaveSlideBar from '../../SaveSlideBar';
import { editUserById } from '../../../Api/common/users';
import { useEffect } from 'react';
import { fetchUser, selectUser } from '../../../redux/slices/user.slice.js';
import { useDispatch, useSelector } from 'react-redux';
import CustomSettingsTitle from '../../CustomSettingsTitle/CustomSettingsTitle';
import CustomPhoneInput from '../../CustomPhoneInput';
import moment from 'moment';

const ProfileSettings = ({ fetchCurrentSettings, currentSettings }) => {
  const [states, setStates] = useState([]);
  const [isSaveBar, setIsSaveBar] = useState(false);
  const { user, isUserLoading } = useSelector(selectUser);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    country: '',
    city: '',
    gender: '',
    dob: '',
    language: '',
    image: null,
  });
  const dispatch = useDispatch();

  const genderList = ['Male', 'Female', 'Other'];

  useEffect(() => {
    const getStates = countrydata.find(
      (country) => country.sortname === formData.country
    )?.states;
    setStates(getStates || []);
  }, [formData.country]);

  const handleFormData = (key, selectedValue) => {
    if (key === 'country') {
      setFormData({
        ...formData,
        city: '',
        [key]: selectedValue,
      });
      setIsSaveBar(true);
      return;
    }
    setFormData({ ...formData, [key]: selectedValue });
    setIsSaveBar(true);
  };

  const setImage = (image) => {
    setFormData((prev) => {
      return { ...formData, image };
    });
  };

  useLayoutEffect(() => {
    setFormData({ ...user } || {});
  }, [user]);

  // click handlers
  const handleSave = () => {
    const formdata = new FormData();

    for (const key in formData) {
      formdata.append(key, formData[key]);
    }

    editUserById(formdata, user.id).then((res) => {
      if (res.status === 200) {
        dispatch(fetchUser())
          .unwrap()
          .then((res) => {
            setIsSaveBar(false);
          });
      }
    });
  };

  const handleCancel = () => {
    setFormData(user || {});
    setIsSaveBar(false);
  };

  return (
    <>
      <Grid
        xs={12}
        sx={{
          marginTop: { xs: 9, sm: 14 },
          backgroundColor: 'white',
          pb: 1,
          mb: 5,
        }}
      >
        <Grid
          sx={{
            padding: { xs: '15px', md: '15px 25px' },
            borderBottom: '1px solid #e3eaf3',
          }}
        >
          {/* <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>
            Account Settings{' '}
            <span style={{ color: '#c1c1c1' }}>
              <CircleIcon sx={{ fontSize: '5px', marginX: '3px' }} /> Profile
            </span>
          </Typography> */}
          <CustomSettingsTitle title="Account Settings" subTitle="Profile" />
        </Grid>

        <Grid container style={{ padding: '15px 25px' }}>
          <Grid xs={12} sx={{ paddingRight: { sm: 2 }, marginTop: 1 }}>
            <ProfileImageField
              setImage={setImage}
              image={formData.image}
              setIsSaveBar={setIsSaveBar}
            />
          </Grid>

          <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">First Name</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={formData.firstName}
                onChange={(e) => handleFormData('firstName', e.target.value)}
                size="small"
                required
                variant="outlined"
                sx={{ marginBottom: '2rem', width: '100%', fontSize: '14px' }}
              />
            </Grid>
          </Grid>

          <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">Last Name</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={formData.lastName}
                onChange={(e) => handleFormData('lastName', e.target.value)}
                size="small"
                required
                variant="outlined"
                sx={{ marginBottom: '2rem', width: '100%', fontSize: '14px' }}
              />
            </Grid>
          </Grid>

          <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">Email</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={formData.email}
                onChange={(e) => handleFormData('email', e.target.value)}
                size="small"
                required
                variant="outlined"
                sx={{ marginBottom: '2rem', width: '100%', fontSize: '14px' }}
              />
            </Grid>
          </Grid>

          <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">Phone Number</Typography>
            </Grid>
            <Grid item xs={12}>
              <CustomPhoneInput
                value={formData.phone}
                onChange={(e) => handleFormData('phone', e)}
              />
            </Grid>
          </Grid>

          <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">Country</Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                options={countrydata}
                value={
                  countrydata.find(
                    (country) => country.sortname === formData.country
                  ) || null
                }
                getOptionLabel={(option) => option.country_name}
                onChange={(e, v) => handleFormData('country', v.sortname)}
                sx={{ marginBottom: '2rem', fontSize: '14px' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    placeholder="Choose Country..."
                  />
                )}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <TextField
                value={formData.country}
                onChange={(e) => handleFormData("country", e.target.value)}
                id="outlined-basic"
                size="small"
                required
                variant="outlined"
                sx={{ marginBottom: "2rem", width: "100%", fontSize: "14px" }}
              />
            </Grid> */}
          </Grid>

          <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">City</Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                options={states.map((getstate, index) => getstate.state_name)}
                value={formData.city}
                onSelect={(e) =>
                  handleFormData('city', e.target.value.toString())
                }
                sx={{ marginBottom: '2rem', fontSize: '14px' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    placeholder="Choose City..."
                  />
                )}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <TextField
                value={formData.city}
                onChange={(e) => handleFormData("city", e.target.value)}
                id="outlined-basic"
                size="small"
                required
                variant="outlined"
                sx={{ marginBottom: "2rem", width: "100%", fontSize: "14px" }}
              />
            </Grid> */}
          </Grid>

          <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">Gender</Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={genderList}
                value={formData.gender}
                onSelect={(e) =>
                  handleFormData('gender', e.target.value.toString())
                }
                sx={{ marginBottom: '2rem', fontSize: '14px' }}
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </Grid>
          </Grid>

          <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">Birthday</Typography>
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  value={formData.dob}
                  onChange={(e) =>
                    handleFormData('dob', moment(e).format('YYYY-MM-DD'))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      required
                      variant="outlined"
                      sx={{
                        marginBottom: '2rem',
                        width: '100%',
                        fontSize: '14px',
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>

          <Grid xs={12} sm={6} sx={{ paddingRight: { sm: 1 } }}>
            <Grid item xs={12} sx={{ marginY: 1 }}>
              <Typography variant="subtitle2">Language</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={formData.language}
                onChange={(e) => handleFormData('language', e.target.value)}
                size="small"
                required
                variant="outlined"
                sx={{ marginBottom: '2rem', width: '100%', fontSize: '14px' }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SaveSlideBar
        handleCancel={handleCancel}
        handleSave={handleSave}
        isSaveBar={isSaveBar}
        setIsSaveBar={setIsSaveBar}
      />
    </>
  );
};

export default ProfileSettings;
