import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Button,
  useTheme,
  useMediaQuery,
  MenuList,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DonutChart from '../../components/DonutChart';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import BarChart from '../../components/BarChart';
import AreaLineChart from '../../components/AreaLineChart';
import CloseIcon from '@mui/icons-material/Close';

function createData(id, name) {
  return { id, name };
}

const mostEarningStaffs = [
  { id: 1, name: 'Ronald Aigbobo', value: 3, color: '#FFD17A' },
  { id: 2, name: 'Mark S. Borchert, MD', value: 2, color: '#DBC9FF' },
  { id: 3, name: 'Rima Jubran, MD', value: 5, color: '#84CEF9' },
  { id: 4, name: 'David Freyer, DO', value: 1, color: '#9EFFB2' },
];

const mostEarningLocations = [
  { id: 1, name: 'California', value: 6, color: '#FFD17A' },
  { id: 2, name: 'Los Angeles', value: 5, color: '#DBC9FF' },
];

const reportsByTheNumberOfAppointments = [
  { id: 1, xValue: '2022-08-01', yValue: 1 },
  { id: 2, xValue: '2022-08-13', yValue: 5 },
  { id: 3, xValue: '2022-08-16', yValue: 10 },
  { id: 4, xValue: '2022-08-19', yValue: 7 },
  { id: 5, xValue: '2022-08-22', yValue: 22 },
];

const reportsByAppointmentEarnings = [
  { id: 1, xValue: '2022-08-01', yValue: 0 },
  { id: 2, xValue: '2022-08-3', yValue: 5 },
  { id: 3, xValue: '2022-08-13', yValue: 8 },
  { id: 4, xValue: '2022-08-16', yValue: 10 },
  { id: 5, xValue: '2022-08-19', yValue: 7 },
  { id: 6, xValue: '2022-08-22', yValue: 22 },
  { id: 7, xValue: '2022-08-23', yValue: 0 },
];

const locationFilter = ['California', 'Los Angeles', 'New York'];

const servicesFilter = [
  'Oral hygiene',
  'Tooth whitening',
  'Implants',
  '3D/4D Ultrasound',
  'OB/GYN',
  'Maternity',
  'Buttock Lift',
  'Liposuction',
  'Fat Transfer',
];
const staffFilter = [
  'Roland Aigbobo',
  'Mark S. Borchert, MD',
  'Joseph Church, MD',
  'Thomas Coates, MD',
  'Rima Jubran, MD',
  'David Freyer, DO',
  'Dennis R. Maceri, MD',
];

const statusFilter = [
  'Pending',
  'Approved',
  'Canceled',
  'Rejected',
  'Completed',
  'No show',
  'Waiting for payment',
];

const rows = [
  createData(1, 'All sales'),
  createData(2, 'Sales by service'),
  createData(3, 'Sales by location'),
  createData(4, 'Sales by customer'),
  createData(5, 'Sales by staff'),
  createData(6, 'All booked appointment'),
  createData(7, 'Most booked service'),
  createData(8, 'Most serviced staff'),
  createData(9, 'Most booked customer'),
  createData(10, 'Absence'),
];

const ActionSection = ({ handlers }) => {
  const {
    handleDaily,
    handleWeekly,
    handleMonthly,
    handleQuarterly,
    handleYearly,
  } = handlers;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid>
      <Grid container alignItems="center">
        <Grid container xs={12} justifyContent="flex-end" alignItems="center">
          <IconButton onClick={handleClick}>
            <MoreVertIcon sx={{ fontSize: 20 }} />
          </IconButton>
        </Grid>
      </Grid>

      <Menu
        id="long-menu"
        elevation={1}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleDaily}>Daily report</MenuItem>
        <MenuItem onClick={handleWeekly}>Weekly report</MenuItem>
        <MenuItem onClick={handleMonthly}>Monthly report</MenuItem>
        <MenuItem onClick={handleQuarterly}>Quarterly report</MenuItem>
        <MenuItem onClick={handleYearly}>Yearly report</MenuItem>
      </Menu>
    </Grid>
  );
};

const DonutChartMenu = ({ handleMenuSelection }) => {
  return (
    <>
      <MenuItem onClick={() => handleMenuSelection('THIS WEEK')}>
        This week
      </MenuItem>
      <MenuItem onClick={() => handleMenuSelection('PREVIOUS WEEK')}>
        Previous week
      </MenuItem>
      <MenuItem onClick={() => handleMenuSelection('THIS MONTH')}>
        This month
      </MenuItem>
      <MenuItem onClick={() => handleMenuSelection('PREVIOUS MONTH')}>
        Previous month
      </MenuItem>
      <MenuItem onClick={() => handleMenuSelection('THIS YEAR')}>
        This year
      </MenuItem>
      <MenuItem onClick={() => handleMenuSelection('PREVIOUS YEAR')}>
        Previous year
      </MenuItem>
    </>
  );
};

const BarChartMenu = ({ handleMenuSelection }) => {
  return (
    <>
      <MenuItem onClick={() => handleMenuSelection('DAILY')}>Daily</MenuItem>
      <MenuItem onClick={() => handleMenuSelection('MONTHLY')}>
        Monthly
      </MenuItem>
      <MenuItem onClick={() => handleMenuSelection('ANNUALY')}>
        Annualy
      </MenuItem>
    </>
  );
};

const Report = () => {
  const [formData, setFormData] = useState({
    reportsByAppointmentEarnings: {
      locationFilter: '',
      servicesFilter: '',
      staffFilter: '',
      statusFilter: '',
    },
    reportsByTheNumberOfAppointments: {
      locationFilter: '',
      servicesFilter: '',
      staffFilter: '',
      statusFilter: '',
    },
  });
  const [filterMenuList, setFilterMenuList] = useState([]);
  const [menuFilterInput, setMenuFilterInput] = useState('');
  const matches = useMediaQuery(useTheme().breakpoints.up('sm'));
  const [menuResult, setMenuResult] = useState({
    mostEarningStaffs: 'THIS WEEK',
    mostEarningLocations: 'THIS WEEK',
    reportsByAppointmentEarnings: 'DAILY',
    reportsByTheNumberOfAppointments: 'DAILY',
  });
  const [anchorElFilter, setAnchorElFilter] = useState({
    el: null,
    diagramName: null,
  });
  const [anchorEl, setAnchorEl] = useState({
    el: null,
    chart: null,
    diagram: null,
  });
  const open = Boolean(anchorEl.el);
  const handleClick = (event, chart, diagram) => {
    setAnchorEl({ el: event.currentTarget, chart, diagram });
  };
  const handleClose = () => {
    setAnchorEl({ el: null, chart: null, diagram: null });
  };

  const handleMenuSelection = (value) => {
    setMenuResult((prevState) => ({
      ...prevState,
      [anchorEl.diagram ? anchorEl.diagram : 'mostEarningStaffs']: value,
    }));
    handleClose();
  };

  const handleDaily = () => {};

  const handleWeekly = () => {};

  const handleMonthly = () => {};

  const handleQuarterly = () => {};

  const handleYearly = () => {};

  const openMenuFilter = Boolean(anchorElFilter.el);

  const handleMenuClick = (event, diagramName) => {
    const menuName = event.currentTarget.getAttribute('name');
    switch (menuName) {
      case 'servicesFilter':
        setFilterMenuList(servicesFilter);
        break;
      case 'locationFilter':
        setFilterMenuList(locationFilter);
        break;
      case 'staffFilter':
        setFilterMenuList(staffFilter);
        break;
      case 'statusFilter':
        setFilterMenuList(statusFilter);
        break;
    }

    setAnchorElFilter({ el: event.currentTarget, diagramName });
  };

  const handleClearFilter = (event, diagramName) => {
    event.stopPropagation();
    const menuName = event.currentTarget.getAttribute('name');

    setFormData((prevState) => ({
      ...prevState,
      [diagramName]: {
        ...prevState[diagramName],
        [menuName]: '',
      },
    }));
  };

  const handleMenuClose = () => {
    setAnchorElFilter({ el: null, diagramName: null });
    setMenuFilterInput('');
  };

  const handleMenuFilterInput = (e) => {
    const value = e.target.value;

    if (value.length === 0) {
      switch (anchorEl.getAttribute('name')) {
        case 'eventType':
          setFilterMenuList([
            'New booking',
            'Booking rescheduled',
            'Booking status changed',
            'Booking starts',
            'Booking ends',
            'New customer created',
            'New rating',
          ]);
          break;
      }
    } else {
      setFilterMenuList((prevState) =>
        prevState.filter((prevValue) => prevValue.startsWith(value))
      );
    }

    setMenuFilterInput(e.target.value);
  };

  const handleCheckedMenuFilter = (value) => {
    const menuName = anchorElFilter.el.getAttribute('name');

    setFormData((prevState) => ({
      ...prevState,
      [anchorElFilter.diagramName]: {
        ...prevState[anchorElFilter.diagramName],
        [menuName]: value,
      },
    }));

    handleMenuClose();
  };

  return (
    <Grid
      item
      container
      sx={{
        minWidth: { xs: '100%' },
        // paddingLeft: 4,
        // paddingRight: 4,
        px: '20px',
      }}
    >
      <Menu
        id="menu-appbar"
        anchorEl={anchorElFilter.el}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openMenuFilter}
        onClose={handleMenuClose}
        elevation={0}
        MenuListProps={{
          sx: {
            width: anchorElFilter.el && anchorElFilter.el.offsetWidth,
            padding: 0,
            border: '1px solid #E4EBF4',
            borderRadius: '4px',
            borderTop: 'none',
          },
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <input
            value={menuFilterInput}
            onChange={handleMenuFilterInput}
            type="text"
            style={{
              border: '1px solid #e3eaf3',
              outline: 'none',
              fontSize: '14px',
              borderRadius: '2px',
              height: '1.5rem',
              width: '95%',
            }}
          />
        </div>
        <div>
          <MenuList dense>
            {filterMenuList.map((value, index) => (
              <MenuItem
                sx={{
                  ':hover': {
                    backgroundColor: '#adbfc7',
                    color: 'white',
                  },
                }}
                key={`filter-${index}`}
                onClick={() => handleCheckedMenuFilter(value)}
              >
                {value}
              </MenuItem>
            ))}
          </MenuList>
        </div>
      </Menu>

      {/** Non filter menu */}
      <Menu
        elevation={1}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl.el}
        open={open}
        onClose={handleClose}
      >
        {open && anchorEl.chart === 'donut' && (
          <DonutChartMenu handleMenuSelection={handleMenuSelection} />
        )}
        {open && anchorEl.chart === 'bar' && (
          <BarChartMenu handleMenuSelection={handleMenuSelection} />
        )}
      </Menu>

      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          flexWrap: 'wrap',
          paddingBottom: { xs: '20px' },
          borderBottom: { xs: '1px solid #e3eaf3' },
        }}
      >
        <Typography
          sx={{
            marginTop: 2.5,
            marginBottom: 1,
            fontSize: 24,
            color: '#3A3A50',
          }}
        >
          Reports
        </Typography>
      </Grid>

      <Grid container justifyContent="space-between" sx={{ marginTop: '20px' }}>
        <Grid
          xs={12}
          lg={6}
          sx={{
            paddingRight: { xs: 0, lg: 2 },
          }}
        >
          <Grid
            sx={{
              height: '450px',
              backgroundColor: 'white',
              marginTop: '20px',
              boxShadow:
                '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
            }}
          >
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              flexWrap="nowrap"
              sx={{
                minHeight: '50px',
                paddingX: '20px',
                borderBottom: '2px solid #E3EBF3',
              }}
            >
              <Typography color="#81909A">MOST EARNING STAFFS</Typography>

              <Button
                variant="contained"
                disableElevation
                onClick={(e) => handleClick(e, 'donut', 'mostEarningStaffs')}
                sx={{
                  height: 'fit-content',
                  backgroundColor: 'transparent',
                  color: '#ADC0C8',
                  padding: 0,
                  ':hover': { backgroundColor: 'transparent' },
                }}
                endIcon={<KeyboardArrowDownIcon />}
              >
                {menuResult.mostEarningStaffs}
              </Button>
            </Grid>

            <Grid container flexWrap="nowrap">
              <DonutChart data={mostEarningStaffs} />
              <Grid
                sx={{
                  marginTop: '20px',
                  flexGrow: 1,
                  minWidth: 'fit-content',
                  paddingRight: 2,
                }}
              >
                {mostEarningStaffs.map((mostEarningStaff) => (
                  <Grid container sx={{ marginY: '10px' }}>
                    <Grid
                      sx={{
                        height: '25px',
                        width: '25px',
                        borderRadius: '50%',
                        backgroundColor: mostEarningStaff.color,
                        marginRight: '10px',
                      }}
                    ></Grid>
                    <Typography fontWeight={500} color="#6C6C6C">
                      {mostEarningStaff.name} : {mostEarningStaff.value}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          xs={12}
          lg={6}
          sx={{
            height: '450px',
            backgroundColor: 'white',
            marginTop: '20px',
            boxShadow:
              '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
          }}
        >
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            flexWrap="nowrap"
            sx={{
              minHeight: '50px',
              paddingX: '20px',
              borderBottom: '2px solid #E3EBF3',
            }}
          >
            <Typography color="#81909A">MOST EARNING LOCATIONS</Typography>

            <Button
              variant="contained"
              disableElevation
              onClick={(e) => handleClick(e, 'donut', 'mostEarningLocations')}
              sx={{
                height: 'fit-content',
                backgroundColor: 'transparent',
                color: '#ADC0C8',
                padding: 0,
                ':hover': { backgroundColor: 'transparent' },
              }}
              endIcon={<KeyboardArrowDownIcon />}
            >
              {menuResult.mostEarningLocations}
            </Button>
          </Grid>

          <Grid container flexWrap="nowrap">
            <DonutChart data={mostEarningLocations} />
            <Grid
              sx={{
                marginTop: '20px',
                flexGrow: 1,
                minWidth: 'fit-content',
                paddingRight: 2,
              }}
            >
              {mostEarningLocations.map((mostEarningLocation) => (
                <Grid container sx={{ marginY: '10px' }}>
                  <Grid
                    sx={{
                      height: '25px',
                      width: '25px',
                      borderRadius: '50%',
                      backgroundColor: mostEarningLocation.color,
                      marginRight: '10px',
                    }}
                  ></Grid>
                  <Typography fontWeight={500} color="#6C6C6C">
                    {mostEarningLocation.name} : {mostEarningLocation.value}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        {/**reportsByTheNumberOfAppointments */}
        <Grid
          xs={12}
          lg={6}
          sx={{
            paddingRight: { xs: 0, lg: 2 },
          }}
        >
          <Grid
            sx={{
              height: 'fit-content',
              backgroundColor: 'white',
              marginTop: '20px',
              boxShadow:
                '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
            }}
          >
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              flexWrap="nowrap"
              sx={{
                minHeight: '50px',
                paddingX: '20px',
                borderBottom: '2px solid #E3EBF3',
              }}
            >
              <Typography color="#81909A">
                REPORTS BY THE NUMBER OF APPOINTMENTS
              </Typography>

              <Button
                variant="contained"
                disableElevation
                onClick={(e) =>
                  handleClick(e, 'donut', 'reportsByTheNumberOfAppointments')
                }
                sx={{
                  height: 'fit-content',
                  backgroundColor: 'transparent',
                  color: '#ADC0C8',
                  padding: 0,
                  ':hover': { backgroundColor: 'transparent' },
                }}
                endIcon={<KeyboardArrowDownIcon />}
              >
                {menuResult.reportsByTheNumberOfAppointments}
              </Button>
            </Grid>

            <Grid container>
              <Grid container sx={{ marginX: '30px' }}>
                {/* Services Filter */}
                <Grid xs={12} lg={6} sx={{ paddingRight: 1, marginBottom: 1 }}>
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">Services filter</Typography>
                  </Grid>

                  <Grid
                    name="servicesFilter"
                    onClick={(e) =>
                      handleMenuClick(e, 'reportsByTheNumberOfAppointments')
                    }
                    xs={12}
                    style={{
                      position: 'relative',
                      border: '1px solid #E4EBF4',
                      backgroundColor: 'white',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      height: '45px',
                      padding: '0 12px',
                    }}
                  >
                    <Typography sx={{ color: '#978c80' }} variant="subtitle2">
                      {formData.reportsByTheNumberOfAppointments.servicesFilter
                        .length > 0
                        ? formData.reportsByTheNumberOfAppointments
                            .servicesFilter
                        : ''}
                    </Typography>
                    {formData.reportsByTheNumberOfAppointments
                      .servicesFilter && (
                      <CloseIcon
                        name="servicesFilter"
                        onClick={(e) =>
                          handleClearFilter(
                            e,
                            'reportsByTheNumberOfAppointments'
                          )
                        }
                        style={{
                          position: 'absolute',
                          right: '35px',
                          cursor: 'pointer',
                          fontSize: '20px',
                        }}
                      />
                    )}
                    <ArrowDropDownIcon sx={{ color: '#978c80' }} />
                  </Grid>
                </Grid>

                {/* Location Filter */}
                <Grid xs={12} lg={6} sx={{ paddingRight: 1, marginBottom: 1 }}>
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">Location filter</Typography>
                  </Grid>

                  <Grid
                    name="locationFilter"
                    onClick={(e) =>
                      handleMenuClick(e, 'reportsByTheNumberOfAppointments')
                    }
                    xs={12}
                    style={{
                      position: 'relative',
                      border: '1px solid #E4EBF4',
                      backgroundColor: 'white',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      height: '45px',
                      padding: '0 12px',
                    }}
                  >
                    <Typography sx={{ color: '#978c80' }} variant="subtitle2">
                      {formData.reportsByTheNumberOfAppointments.locationFilter
                        .length > 0
                        ? formData.reportsByTheNumberOfAppointments
                            .locationFilter
                        : ''}
                    </Typography>
                    {formData.reportsByTheNumberOfAppointments
                      .locationFilter && (
                      <CloseIcon
                        name="locationFilter"
                        onClick={(e) =>
                          handleClearFilter(
                            e,
                            'reportsByTheNumberOfAppointments'
                          )
                        }
                        style={{
                          position: 'absolute',
                          right: '35px',
                          cursor: 'pointer',
                          fontSize: '20px',
                        }}
                      />
                    )}
                    <ArrowDropDownIcon sx={{ color: '#978c80' }} />
                  </Grid>
                </Grid>

                {/* Staff Filter */}
                <Grid xs={12} lg={6} sx={{ paddingRight: 1, marginBottom: 1 }}>
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">Staff filter</Typography>
                  </Grid>

                  <Grid
                    name="staffFilter"
                    onClick={(e) =>
                      handleMenuClick(e, 'reportsByTheNumberOfAppointments')
                    }
                    xs={12}
                    style={{
                      position: 'relative',
                      border: '1px solid #E4EBF4',
                      backgroundColor: 'white',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      height: '45px',
                      padding: '0 12px',
                    }}
                  >
                    <Typography sx={{ color: '#978c80' }} variant="subtitle2">
                      {formData.reportsByTheNumberOfAppointments.staffFilter
                        .length > 0
                        ? formData.reportsByTheNumberOfAppointments.staffFilter
                        : ''}
                    </Typography>
                    {formData.reportsByTheNumberOfAppointments.staffFilter && (
                      <CloseIcon
                        name="staffFilter"
                        onClick={(e) =>
                          handleClearFilter(
                            e,
                            'reportsByTheNumberOfAppointments'
                          )
                        }
                        style={{
                          position: 'absolute',
                          right: '35px',
                          cursor: 'pointer',
                          fontSize: '20px',
                        }}
                      />
                    )}
                    <ArrowDropDownIcon sx={{ color: '#978c80' }} />
                  </Grid>
                </Grid>

                {/* Status Filter */}
                <Grid xs={12} lg={6} sx={{ paddingRight: 1, marginBottom: 1 }}>
                  <Grid item xs={12} sx={{ marginY: 1 }}>
                    <Typography variant="subtitle2">Status filter</Typography>
                  </Grid>

                  <Grid
                    name="statusFilter"
                    onClick={(e) =>
                      handleMenuClick(e, 'reportsByTheNumberOfAppointments')
                    }
                    xs={12}
                    style={{
                      position: 'relative',
                      border: '1px solid #E4EBF4',
                      backgroundColor: 'white',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      height: '45px',
                      padding: '0 12px',
                    }}
                  >
                    <Typography sx={{ color: '#978c80' }} variant="subtitle2">
                      {formData.reportsByTheNumberOfAppointments.statusFilter
                        .length > 0
                        ? formData.reportsByTheNumberOfAppointments.statusFilter
                        : ''}
                    </Typography>
                    {formData.reportsByTheNumberOfAppointments.statusFilter && (
                      <CloseIcon
                        name="statusFilter"
                        onClick={(e) =>
                          handleClearFilter(
                            e,
                            'reportsByTheNumberOfAppointments'
                          )
                        }
                        style={{
                          position: 'absolute',
                          right: '35px',
                          cursor: 'pointer',
                          fontSize: '20px',
                        }}
                      />
                    )}
                    <ArrowDropDownIcon sx={{ color: '#978c80' }} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container justifyContent="center">
                <BarChart data={reportsByTheNumberOfAppointments} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          xs={12}
          lg={6}
          sx={{
            height: 'fit-content',
            backgroundColor: 'white',
            marginTop: '20px',
            boxShadow:
              '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
          }}
        >
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            flexWrap="nowrap"
            sx={{
              minHeight: '50px',
              paddingX: '20px',
              borderBottom: '2px solid #E3EBF3',
            }}
          >
            <Typography color="#81909A">
              REPORTS BY APPOINTMENT EARNINGS {`($)`}
            </Typography>

            <Button
              variant="contained"
              disableElevation
              onClick={(e) =>
                handleClick(e, 'donut', 'reportsByAppointmentEarnings')
              }
              sx={{
                height: 'fit-content',
                backgroundColor: 'transparent',
                color: '#ADC0C8',
                padding: 0,
                ':hover': { backgroundColor: 'transparent' },
              }}
              endIcon={<KeyboardArrowDownIcon />}
            >
              {menuResult.reportsByAppointmentEarnings}
            </Button>
          </Grid>

          <Grid container>
            <Grid container sx={{ marginX: '30px' }}>
              {/* Services Filter */}
              <Grid xs={12} lg={6} sx={{ paddingRight: 1, marginBottom: 1 }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">Services filter</Typography>
                </Grid>

                <Grid
                  name="servicesFilter"
                  onClick={(e) =>
                    handleMenuClick(e, 'reportsByAppointmentEarnings')
                  }
                  xs={12}
                  style={{
                    position: 'relative',
                    border: '1px solid #E4EBF4',
                    backgroundColor: 'white',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: '45px',
                    padding: '0 12px',
                  }}
                >
                  <Typography sx={{ color: '#978c80' }} variant="subtitle2">
                    {formData.reportsByAppointmentEarnings.servicesFilter
                      .length > 0
                      ? formData.reportsByAppointmentEarnings.servicesFilter
                      : ''}
                  </Typography>
                  {formData.reportsByAppointmentEarnings.servicesFilter && (
                    <CloseIcon
                      name="servicesFilter"
                      onClick={(e) =>
                        handleClearFilter(e, 'reportsByAppointmentEarnings')
                      }
                      style={{
                        position: 'absolute',
                        right: '35px',
                        cursor: 'pointer',
                        fontSize: '20px',
                      }}
                    />
                  )}
                  <ArrowDropDownIcon sx={{ color: '#978c80' }} />
                </Grid>
              </Grid>

              {/* Location Filter */}
              <Grid xs={12} lg={6} sx={{ paddingRight: 1, marginBottom: 1 }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">Location filter</Typography>
                </Grid>

                <Grid
                  name="locationFilter"
                  onClick={(e) =>
                    handleMenuClick(e, 'reportsByAppointmentEarnings')
                  }
                  xs={12}
                  style={{
                    position: 'relative',
                    border: '1px solid #E4EBF4',
                    backgroundColor: 'white',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: '45px',
                    padding: '0 12px',
                  }}
                >
                  <Typography sx={{ color: '#978c80' }} variant="subtitle2">
                    {formData.reportsByAppointmentEarnings.locationFilter
                      .length > 0
                      ? formData.reportsByAppointmentEarnings.locationFilter
                      : ''}
                  </Typography>
                  {formData.reportsByAppointmentEarnings.locationFilter && (
                    <CloseIcon
                      name="locationFilter"
                      onClick={(e) =>
                        handleClearFilter(e, 'reportsByAppointmentEarnings')
                      }
                      style={{
                        position: 'absolute',
                        right: '35px',
                        cursor: 'pointer',
                        fontSize: '20px',
                      }}
                    />
                  )}
                  <ArrowDropDownIcon sx={{ color: '#978c80' }} />
                </Grid>
              </Grid>

              {/* Staff Filter */}
              <Grid xs={12} lg={6} sx={{ paddingRight: 1, marginBottom: 1 }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">Staff filter</Typography>
                </Grid>

                <Grid
                  name="staffFilter"
                  onClick={(e) =>
                    handleMenuClick(e, 'reportsByAppointmentEarnings')
                  }
                  xs={12}
                  style={{
                    position: 'relative',
                    border: '1px solid #E4EBF4',
                    backgroundColor: 'white',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: '45px',
                    padding: '0 12px',
                  }}
                >
                  <Typography sx={{ color: '#978c80' }} variant="subtitle2">
                    {formData.reportsByAppointmentEarnings.staffFilter.length >
                    0
                      ? formData.reportsByAppointmentEarnings.staffFilter
                      : ''}
                  </Typography>
                  {formData.reportsByAppointmentEarnings.staffFilter && (
                    <CloseIcon
                      name="staffFilter"
                      onClick={(e) =>
                        handleClearFilter(e, 'reportsByAppointmentEarnings')
                      }
                      style={{
                        position: 'absolute',
                        right: '35px',
                        cursor: 'pointer',
                        fontSize: '20px',
                      }}
                    />
                  )}
                  <ArrowDropDownIcon sx={{ color: '#978c80' }} />
                </Grid>
              </Grid>

              {/* Status Filter */}
              <Grid xs={12} lg={6} sx={{ paddingRight: 1, marginBottom: 1 }}>
                <Grid item xs={12} sx={{ marginY: 1 }}>
                  <Typography variant="subtitle2">Status filter</Typography>
                </Grid>

                <Grid
                  name="statusFilter"
                  onClick={(e) =>
                    handleMenuClick(e, 'reportsByAppointmentEarnings')
                  }
                  xs={12}
                  style={{
                    position: 'relative',
                    border: '1px solid #E4EBF4',
                    backgroundColor: 'white',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: '45px',
                    padding: '0 12px',
                  }}
                >
                  <Typography sx={{ color: '#978c80' }} variant="subtitle2">
                    {formData.reportsByAppointmentEarnings.statusFilter.length >
                    0
                      ? formData.reportsByAppointmentEarnings.statusFilter
                      : ''}
                  </Typography>
                  {formData.reportsByAppointmentEarnings.statusFilter && (
                    <CloseIcon
                      name="statusFilter"
                      onClick={(e) =>
                        handleClearFilter(e, 'reportsByAppointmentEarnings')
                      }
                      style={{
                        position: 'absolute',
                        right: '35px',
                        cursor: 'pointer',
                        fontSize: '20px',
                      }}
                    />
                  )}
                  <ArrowDropDownIcon sx={{ color: '#978c80' }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              <AreaLineChart initData={reportsByAppointmentEarnings} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <TableContainer
        sx={{ marginTop: '50px', marginBottom: '80px' }}
        component={Paper}
      >
        <Table sx={{ width: '100%' }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 700 }}>REPORT NAME</TableCell>
              <TableCell sx={{ fontWeight: 700 }} align="right">
                ACTIONS
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">
                  <ActionSection
                    handlers={{
                      handleDaily,
                      handleWeekly,
                      handleMonthly,
                      handleQuarterly,
                      handleYearly,
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default Report;
