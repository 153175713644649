import { useState, useLayoutEffect } from 'react';
import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Menu,
  MenuList,
  Radio,
  RadioGroup,
  Switch,
  TextareaAutosize,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CircleIcon from '@mui/icons-material/Circle';
import CustomSettingsTitle from '../../CustomSettingsTitle/CustomSettingsTitle';
import SaveSlideBar from '../../SaveSlideBar';
import { useDispatch } from 'react-redux';
import { editSettings } from '../../../Api/tenant/settings';
import { fetchUser } from '../../../redux/slices/user.slice.js';
import { IOSSwitch } from '../../CustomCheckbox';

const keywordsInitialValue = [
  { label: 'Appointment ID', value: '{appointment_id}' },
  { label: 'Appointment date', value: '{appointment_date}' },
  { label: 'Appointment date-time', value: '{appointment_date_time}' },
  {
    label: 'Appointment date (customer timezone)',
    value: '{appointment_date_client}',
  },
];

const linkExpiresAfterOptions = [
  'Disabled',
  '1m',
  '2m',
  '3m',
  '4m',
  '5m',
  '10m',
];

const RadioButton = ({ checked }) => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" fontSize="24px">
    {!checked && (
      <circle
        cx="50%"
        cy="50%"
        r="11px"
        stroke="#cfd8dc"
        stroke-width="1px"
        fill="none"
      />
    )}

    {checked && (
      <circle
        cx="50%"
        cy="50%"
        r="8px"
        stroke="#53D56C"
        stroke-width="6px"
        fill="none"
      />
    )}
  </svg>
);

const RatingForm = ({ fetchCurrentSettings, currentSettings }) => {
  const [isSaveBar, setIsSaveBar] = useState(false);
  const [isEnabled, setIsEnabled] = useState('disabled');
  const [formData, setFormData] = useState({
    enabled: false,
    linkExpiresAfter: 0,
    showStaffRating: false,
    showServiceRating: false,
    showDescription: false,
    eventDescription: '',
  });
  const mediaQuery1238 = useMediaQuery('(min-width:1238px)');

  const [anchorEl, setAnchorEl] = useState({
    element: null,
    textFieldName: null,
  });
  const [filterMenuList, setFilterMenuList] = useState(keywordsInitialValue);
  const [menuFilterInput, setMenuFilterInput] = useState('');

  const openMenu = Boolean(anchorEl.element);

  const handleMenuClick = (event, textFieldName) => {
    setFilterMenuList(keywordsInitialValue);

    setAnchorEl({ element: event.currentTarget, textFieldName });
  };

  const handleChange = (key, value) => {
    console.log(key);
    setIsSaveBar(true);
    setFormData((prev) => {
      return { ...prev, [key]: value };
    });
  };

  const handleMenuFilterInput = (e) => {
    const value = e.target.value;

    if (value.length === 0) {
      setFilterMenuList(keywordsInitialValue);
    } else {
      setFilterMenuList((prevState) =>
        prevState.filter((prevValue) => prevValue.label.startsWith(value))
      );
    }

    setMenuFilterInput(e.target.value);
  };

  const handleMenuClose = () => {
    setAnchorEl({ element: null, textFieldName: null });
    setMenuFilterInput('');
  };

  const handleSelectedTag = (tag) => {
    if (anchorEl.textFieldName === 'event-description') {
      setFormData((prevState) => {
        return {
          ...prevState,
          eventDescription: prevState.eventDescription + tag,
        };
      });
    }
    setAnchorEl({ element: null, textFieldName: null });
  };

  const dispatch = useDispatch();

  useLayoutEffect(() => {
    setFormData(currentSettings.FEPanels.RatingForm);
    setIsEnabled(
      currentSettings.FEPanels.RatingForm.enabled ? 'enabled' : 'disabled'
    );
  }, []);

  // click handlers
  const handleSave = () => {
    editSettings({
      ...currentSettings,
      FEPanels: {
        ...currentSettings.FEPanels,
        RatingForm: {
          ...formData,
          enabled: isEnabled == 'disabled' ? false : true,
        },
      },
    }).then((res) => {
      if (res.status === 200) {
        fetchCurrentSettings();
        dispatch(fetchUser())
          .unwrap()
          .then((res) => {
            setIsSaveBar(false);
          });
      }
    });
  };

  const handleCancel = () => {
    setFormData(currentSettings.FEPanels.RatingForm);
    setIsEnabled(
      currentSettings.FEPanels.RatingForm.enabled ? 'enabled' : 'disabled'
    );
    setIsSaveBar(false);
  };

  return (
    <>
      <Menu
        anchorEl={anchorEl.element}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openMenu}
        onClose={handleMenuClose}
        elevation={0}
        MenuListProps={{
          sx: {
            width: anchorEl.element && anchorEl.element.offsetWidth + 200,
            marginTop: 0.5,
            paddingTop: 1,
            border: '1px solid #E4EBF4',
            borderRadius: '4px',
          },
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <input
            value={menuFilterInput}
            onChange={handleMenuFilterInput}
            type="text"
            style={{
              border: '1px solid #e3eaf3',
              outline: 'none',
              fontSize: '14px',
              borderRadius: '2px',
              height: '1.5rem',
              width: '95%',
            }}
          />
        </div>
        <div>
          <MenuList dense>
            {filterMenuList.map((keyword, index) => (
              <Grid
                container
                justifyContent="flex-end"
                flexWrap="nowrap"
                sx={{
                  width: '100%',
                  padding: '5px 0',
                  cursor: 'pointer',
                  ':hover': {
                    backgroundColor: '#adbfc7',
                    color: 'white',
                  },
                }}
                key={`draft-filter-${index}`}
                onClick={() => handleSelectedTag(keyword.value)}
              >
                <Grid
                  sx={{ width: '100%', fontSize: '13px', color: '#007bff' }}
                >
                  {keyword.label}
                </Grid>{' '}
                <Grid
                  sx={{
                    width: '180px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    fontSize: '12px',
                    color: '#9ba6af',
                  }}
                >
                  {keyword.value}
                </Grid>
              </Grid>
            ))}
          </MenuList>
        </div>
      </Menu>

      <Grid xs={12} style={{ backgroundColor: 'white', marginTop: '20px' }}>
        <Grid
          sx={{
            padding: { xs: '15px', md: '15px 25px' },
            borderBottom: '1px solid #e3eaf3',
          }}
        >
          <CustomSettingsTitle
            title="Front-end Panels"
            subTitle="Rating Form"
          />
        </Grid>
        <Grid container>
          <FormControl
            sx={{
              width: '100%',
              boxShadow: '0 15px 15px -5px rgb(0 0 0 / 5%)',
              padding: { xs: '15px', md: '15px 25px' },
            }}
          >
            <RadioGroup
              row
              value={isEnabled}
              onChange={(e) => {
                setIsEnabled(e.target.value);
                setIsSaveBar(true);
              }}
            >
              <FormControlLabel
                value="disabled"
                control={
                  <Radio
                    icon={<RadioButton />}
                    checkedIcon={<RadioButton checked />}
                  />
                }
                label="Disabled"
              />
              <FormControlLabel
                value="enabled"
                sx={{ marginLeft: '20px' }}
                control={
                  <Radio
                    icon={<RadioButton />}
                    checkedIcon={<RadioButton checked />}
                  />
                }
                label="Enabled"
              />
            </RadioGroup>
          </FormControl>

          <Grid container sx={{ marginTop: '40px', paddingX: '25px' }}>
            {isEnabled === 'enabled' && (
              <>
                <Grid container xs={12}>
                  <Grid
                    xs={12}
                    sm={mediaQuery1238 ? 6 : 12}
                    sx={{ paddingRight: 1 }}
                  >
                    <Grid item xs={12} sx={{ marginY: 1 }}>
                      <Typography variant="subtitle2">
                        Link expires after
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        disablePortal
                        options={linkExpiresAfterOptions}
                        value={formData.linkExpiresAfter}
                        sx={{ marginBottom: '2rem', fontSize: '14px' }}
                        onChange={(e, data) =>
                          handleChange('linkExpiresAfter', data)
                        }
                        renderInput={(params) => (
                          <TextField {...params} size="small" />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container xs={12}>
                  <Grid
                    container
                    item
                    xs={mediaQuery1238 ? 6 : 12}
                    sx={{ paddingRight: 1, marginTop: 1 }}
                  >
                    <Grid xs={12}></Grid>
                    <Grid
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        handleChange(
                          'showStaffRating',
                          !formData.showStaffRating
                        );
                      }}
                      xs={12}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        border: '1px solid rgba(0, 0, 0, 0.23)',
                        borderRadius: '4px',
                        paddingX: 2,
                        paddingY: 1,
                        height: '40px',
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ lineHeight: '1.2 !important' }}
                      >
                        Show the Staff rating in booking form
                      </Typography>
                      <IOSSwitch
                        size="small"
                        defaultChecked
                        sx={{ marginLeft: 2 }}
                        onChange={(e) =>
                          handleChange('showStaffRating', e.target.checked)
                        }
                        checked={formData.showStaffRating}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    item
                    xs={mediaQuery1238 ? 6 : 12}
                    sx={{ paddingRight: 1, marginTop: 1 }}
                  >
                    <Grid xs={12}></Grid>
                    <Grid
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        handleChange(
                          'showServiceRating',
                          !formData.showServiceRating
                        );
                      }}
                      xs={12}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        border: '1px solid rgba(0, 0, 0, 0.23)',
                        borderRadius: '4px',
                        paddingX: 2,
                        paddingY: 1,
                        height: '40px',
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ lineHeight: '1.2 !important' }}
                      >
                        Show the Service rating in booking form
                      </Typography>
                      <IOSSwitch
                        size="small"
                        defaultChecked
                        sx={{ marginLeft: 2 }}
                        onChange={(e) =>
                          handleChange('showServiceRating', e.target.checked)
                        }
                        checked={formData.showServiceRating}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container xs={12}>
                  <Grid
                    container
                    item
                    xs={mediaQuery1238 ? 6 : 12}
                    sx={{ paddingRight: 1, marginTop: 4, marginBottom: 3 }}
                  >
                    <Grid xs={12}></Grid>
                    <Grid
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        handleChange(
                          'showDescription',
                          !formData.showDescription
                        );
                      }}
                      xs={12}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        border: '1px solid rgba(0, 0, 0, 0.23)',
                        borderRadius: '4px',
                        paddingX: 2,
                        paddingY: 1,
                        height: '40px',
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ lineHeight: '1.2 !important' }}
                      >
                        Show description field
                      </Typography>
                      <IOSSwitch
                        size="small"
                        defaultChecked
                        sx={{ marginLeft: 2 }}
                        onChange={(e) =>
                          handleChange('showDescription', e.target.checked)
                        }
                        checked={formData.showDescription}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container xs={12} sx={{ marginY: 1, marginBottom: 2 }}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      Event Description
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ position: 'relative', width: '100%' }}
                  >
                    <TextareaAutosize
                      size="small"
                      required
                      variant="outlined"
                      value={formData.eventDescription}
                      onChange={(e) =>
                        handleChange('eventDescription', e.target.value)
                      }
                      sx={{
                        marginBottom: '1rem',
                        width: '100%',
                        fontSize: '14px',
                      }}
                      style={{
                        width: '100%',
                        font: 'inherit !important',
                        minHeight: '120px',
                        fontSize: '14px',
                        outlineColor: '#3f50b5',
                        borderColor: '#bdbdbd',
                      }}
                    />
                    <LocalOfferIcon
                      onClick={(e) => handleMenuClick(e, 'event-description')}
                      sx={{
                        position: 'absolute',
                        top: '10px',
                        right: '12px',
                        cursor: 'pointer',
                        color: '#6C70DC',
                      }}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <SaveSlideBar
        handleCancel={handleCancel}
        handleSave={handleSave}
        isSaveBar={isSaveBar}
        setIsSaveBar={setIsSaveBar}
      />
    </>
  );
};

export default RatingForm;
