import {
  Box,
  Grid,
  MenuItem,
  Select,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React from "react";

const Rating = () => {
  return (
    <Grid
      sx={{
        boxShadow: " rgba(149, 157, 165, 0.2) 0px 8px 24px",
        padding: "2rem",
      }}
    >
      <Typography marginBottom={1}>Rating</Typography>
      <Select fullWidth defaultValue={"none"} size="small">
        <MenuItem value="none">--none--</MenuItem>
        <MenuItem value="1">1</MenuItem>
        <MenuItem value="2">2</MenuItem>
        <MenuItem value="3">3</MenuItem>
        <MenuItem value="4">4</MenuItem>
        <MenuItem value="5">5</MenuItem>
      </Select>
      <Box marginTop={3}>
        <Typography marginBottom={1}>Review</Typography>
        <TextareaAutosize minRows={8} style={{ width: "100%"}} />
      </Box>
    </Grid>
  );
};

export default Rating;
