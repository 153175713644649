import { Grid, Grow, LinearProgress, Typography } from "@mui/material";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import classes from "./InitialGuide.module.css";
import InitialGuideRow from "../InitialGuideRow";

const InitialGuide = ({ initialGuideData, openInitialGuide }) => {
  const MIN = 0;
  const MAX = 3;

  const normalise = (value) => ((value - MIN) * 100) / (MAX - MIN);
  const progress = initialGuideData.filter((data) => data.isDone).length;

  return (
    <Grow
      in={openInitialGuide}
      className={classes.container}
      sx={{
        width: { xs: "calc(100% - 80px)", sm: "360px" },
      }}
    >
      <Grid>
        <Grid className={classes.titleContainer}>
          <RocketLaunchIcon color="#363638" />
          <Typography className={classes.title} variant="h6">
            initial guide
          </Typography>
        </Grid>

        <Grid className={classes.progressContainer}>
          <LinearProgress
            className={classes.progressBar}
            variant="determinate"
            value={normalise(progress)}
          />
          <Typography className={classes.progress}>
            <span>0{progress}</span>/03
          </Typography>
        </Grid>

        <Grid className={classes.initialGuideContainer}>
          {initialGuideData.map((data) => (
            <InitialGuideRow
              number={data.num}
              title={data.title}
              isDone={data.isDone}
              link={data.link}
            />
          ))}
        </Grid>
      </Grid>
    </Grow>
  );
};

export default InitialGuide;
