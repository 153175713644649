import { useLayoutEffect, useState } from 'react';
import React from 'react';
import {
  useLocation,
  Link as SideBarLink,
  useNavigate,
} from 'react-router-dom';
import './index.css';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import Toolbar from '@mui/material/Toolbar';
import {
  Avatar,
  colors,
  Fab,
  Paper,
  Popover,
  Tooltip,
  tooltipClasses,
} from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Grid from '@mui/material/Grid';
import SharePageModal from '../SharePageModal';
import PersonIcon from '@mui/icons-material/Person';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ShareIcon from '@mui/icons-material/Share';
import UpnizeLogo from '../../assets/images/Upnize-logo.png';
import LogoutIcon from '@mui/icons-material/Logout';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import TranslateIcon from '@mui/icons-material/Translate';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import { styled } from '@mui/material/styles';
import { Button, Link, Menu, MenuItem } from '@mui/material';
import { pages } from '../../utils/pages';
import { useContext } from 'react';
import { Context } from '../../context/Context';
import AvatarComp from '../Avatar';
import InitialGuide from '../InitialGuide';
import { useEffect } from 'react';
import { logout } from '../../Api/common/users';
import CheckCircleOutlineTwoTone from '@mui/icons-material/CheckCircleOutlineTwoTone';
import { useDispatch, useSelector } from 'react-redux';
import { clearUser, selectUser } from '../../redux/slices/user.slice.js';
import Modal from '../Modal';
import TrialModal from './TrialModal';
import TrialButton from './TrialButton';
import moment from 'moment';
import CustomTooltip from '../CustomTooltip';
import PublicDrawers from '../../pages/PublicDrawers';
import DisableSectionWrapper from '../DisableSectionWrapper';
import { premiumPages } from '../../constants';
import crown from '../../assets/images/crown1.svg';
import UpsellComponentWrapper from '../UpsellComponentWrapper';
import ErrorBoundary from '../ErrorBoundary';
import TasksDropDown from '../TasksDropDown';
import { useTranslation } from 'react-i18next';

const drawerWidth = 240;
const buttonProps = {
  variant: 'contained',
  size: 'large',
};

// inital guide will be disabled in these pages
const noInitialGuidePages = ['/'];

const System = ({ children }) => {
  const [sidebarItems, setSidebarItems] = useState(pages);
  const [mobileOpen, setMobileOpen] = useState(false);
  // TODO: merge these into one state
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElHelp, setAnchorElHelp] = useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null); // bad naming
  const open = Boolean(anchorEl);
  const open1 = Boolean(anchorEl2); // bad naming

  const openHelp = Boolean(anchorElHelp);
  const [openInitialGuide, setOpenInitialGuide] = useState(false);
  const [addCouponDrawer, setAddCouponDrawer] = useState(false);
  const [drawerType, setDrawerType] = useState('add');
  const [drawerData, setDrawerData] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, isUserLoading } = useSelector(selectUser);
  const [isInitialGuide, setIsInitialGuide] = useState(
    !noInitialGuidePages.includes(window.location.pathname)
  );
  const [initialGuideData, setInitialGuideData] = useState([
    {
      num: 1,
      title: 'Company details',
      isDone: true,
      link: '/setting?main=company&sub=company-details',
    },
    {
      num: 2,
      title: 'Business hours',
      isDone: true,
      link: '/setting?main=company&sub=business-hours',
    },
    {
      num: 3,
      title: 'Create service',
      isDone: true,
      link: '/service',
    },
  ]);
  const { i18n } = useTranslation();

  // adding premium badge on pages (sidebar items)
  useLayoutEffect(() => {
    const pagesWithBadge = pages.map((page) => {
      if (page) {
        const premIndex = premiumPages.findIndex(
          (ele) => ele.path === page.link
        );
        const premItem = premiumPages[premIndex];

        return {
          ...page,
          iconEnd: premItem ? (
            <UpsellComponentWrapper code={premItem.code}>
              <Avatar
                src={crown}
                alt="Crown"
                sx={{ height: '20px', width: '20px', borderRadius: '0' }}
              />
            </UpsellComponentWrapper>
          ) : null,
        };
      }
    });

    setSidebarItems(pagesWithBadge);
  }, []);

  useEffect(() => {
    // setting inital guide data
    if (!isUserLoading && user?.id) {
      setInitialGuideData([
        {
          num: 1,
          title: 'Company details',
          isDone: user?.companyDeatils,
          link: '/setting?main=company&sub=company-details',
        },
        {
          num: 2,
          title: 'Business hours',
          isDone: user?.businessHours,
          link: '/setting?main=company&sub=business-hours',
        },
        {
          num: 3,
          title: 'Create service',
          isDone: user?.services,
          link: '/service',
        },
      ]);
    }
    // setting locale (first day of the week)
    moment.updateLocale('en', {
      week: {
        dow:
          user.settings.GeneralSettings.weekStart.toLowerCase() == 'monday'
            ? 1
            : 0,
      },
    });
  }, [user]);

  useEffect(() => {
    setIsInitialGuide(!noInitialGuidePages.includes(window.location.pathname));
  }, [window.location.pathname]);

  useEffect(() => {
    if (
      initialGuideData.filter((data) => !data.isDone).length > 0 &&
      typeof initialGuideData[0].isDone == 'boolean'
    ) {
      setOpenInitialGuide(true);
    }
  }, [initialGuideData]);

  const toggleAddCouponDrawer = () => {
    setAddCouponDrawer(!addCouponDrawer);
  };

  const context = useContext(Context);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClickMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClickMenuHelp = (e) => {
    setAnchorElHelp(e.currentTarget);
  };

  const handleCloseMenuHelp = () => {
    setAnchorElHelp(null);
  };

  const handlePanelSwitch = () => {
    context.setUser({ type: 'customer' });
  };
  //checkbox menu
  const handleClick = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl2(null);
  };

  const drawer = (
    <div>
      <Toolbar
        style={{
          height: '64px',
          minHeight: '64px',
          paddingBottom: '10px',
          paddingTop: '10px',
        }}
      >
        <img src={UpnizeLogo} style={{ height: '45px' }} alt="logo" />
      </Toolbar>
      <Divider sx={{ bgcolor: '#3D4145' }} />
      <Box paddingY={'8px'}>
        {sidebarItems.map(
          (page, index) =>
            page && (
              <>
                <SideBarLink
                  key={index}
                  to={page.link}
                  style={{
                    textDecoration: 'none',
                  }}
                  onClick={() => {
                    if (mobileOpen) {
                      handleDrawerToggle();
                    }
                    if (
                      initialGuideData.filter((data) => !data.isDone).length > 0
                    ) {
                      setOpenInitialGuide(true);
                    }
                  }}
                >
                  <ListItem
                    sx={{
                      backgroundColor:
                        location.pathname === page.link ? '#5D5D7E' : 'inherit',
                      color:
                        location.pathname === page.link ? '#fff' : '#626D76',
                      '&:hover': {
                        backgroundColor: '#2E314A',
                      },
                    }}
                    key={index}
                  >
                    <ListItemIcon
                      sx={{
                        color:
                          location.pathname === page.link
                            ? '#FFFFFF'
                            : '#626D76',
                      }}
                    >
                      {page.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={page.text}
                      sx={
                        location.pathname === page.link
                          ? { color: 'white' }
                          : { color: '#626c76' }
                      }
                    />
                    <Box
                      sx={{
                        color:
                          location.pathname === page.link
                            ? '#FFFFFF'
                            : '#626D76',
                        width: '20px',
                        height: '20px',
                      }}
                    >
                      {page.iconEnd}
                    </Box>
                  </ListItem>
                </SideBarLink>
                {page.child?.map((childPage, index) => {
                  return (
                    <SideBarLink
                      to={childPage.link}
                      style={{
                        textDecoration: 'none',
                        '&:hover': { background: '#2E314A' },
                      }}
                      key={index}
                    >
                      {' '}
                      <ListItem
                        sx={
                          location.pathname === childPage.link
                            ? {
                                backgroundColor: '#5D5D7E',
                                color: '#fff',
                                '&:hover': {
                                  backgroundColor: '#2E314A',
                                },
                              }
                            : { color: '#959595' }
                        }
                        key={index}
                      >
                        <ListItemIcon
                          sx={
                            location.pathname === childPage.link
                              ? { color: '#FFFFFF' }
                              : { color: '#8468FA' }
                          }
                        >
                          {/* {page.icon} */}
                        </ListItemIcon>
                        <ListItemText
                          primary={childPage.text}
                          sx={
                            location.pathname === childPage.link
                              ? { color: 'white' }
                              : { color: '#626c76' }
                          }
                        />
                      </ListItem>
                    </SideBarLink>
                  );
                })}
              </>
            )
        )}
      </Box>
    </div>
  );

  const [openModalTrial, setOpenModalTrial] = useState(false);

  const handleOpenModalTrial = () => {
    setOpenModalTrial(true);
  };

  const handleCloseModalTrial = () => {
    setOpenModalTrial(false);
  };

  const [openModal, setOpenModal] = useState({ open: false, type: null });
  const handleOpenModal = () => {
    setOpenModal({ open: false, type: null });
  };

  return (
    <>
      <TrialModal open={openModalTrial} handleClose={handleCloseModalTrial} />

      {/* tasks dropdown menu  */}
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl2}
        open={open1}
        onClose={handleClose}
        elevation={0}
        sx={{
          zIndex: 1,
        }}
        PaperProps={{ style: { marginTop: '22px' } }}
      >
        <Box sx={{ border: 1, borderColor: 'divider' }}>
          <TasksDropDown />

          <Box sx={{ px: 2, py: 1, borderTop: 1, borderTopColor: 'divider' }}>
            <SideBarLink
              to="/task"
              onClick={handleClose}
              style={{ textDecoration: 'none' }}
            >
              Go to task management
            </SideBarLink>
          </Box>
        </Box>
      </Popover>

      {addCouponDrawer && (
        <SharePageModal
          toggleAddCouponDrawer={toggleAddCouponDrawer}
          drawerType={drawerType}
          drawerData={drawerData}
        />
      )}
      {openModal.type === 'invite-customer-modal' && (
        <Modal
          handleOpenModal={handleOpenModal}
          type="INVITE_CUSTOMER"
          open={openModal.open}
          id={openModal.id}
          // fetchCategories={fetchCategories}
        />
      )}
      <Menu
        sx={{
          '& .css-edx3xz-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper':
            {
              marginTop: '6px',
              // marginTop: { xs: '17px', sm: '30px', md: '10px' },
              width: { xs: '100%', sm: 'auto' },
            },
        }}
        anchorEl={anchorEl}
        id="navbar-menu"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleCloseMenu}
        elevation={0}
        MenuListProps={{
          sx: {
            // width: anchorEl && anchorEl.offsetWidth,
            border: '1px solid #e3eaf3',
            borderRadius: '0px',
          },
        }}
      >
        {/* <SideBarLink
              to={"/billing"}
              style={{
                textDecoration: "none",
              }}
            >
              <MenuItem>
                <CreditCardIcon sx={{ marginRight: "1rem" }} />
                <ListItemText>Billings</ListItemText>
              </MenuItem>
            </SideBarLink> */}
        {/* <MenuItem
              onClick={() => {
                setDrawerType("add");
                toggleAddCouponDrawer();
                handleCloseMenu();
              }}
            >
              <ShareIcon sx={{ marginRight: "1rem" }} />
              <ListItemText>Share your page</ListItemText>
            </MenuItem>
            <Divider sx={{ bgcolor: "#EEEEEE" }} /> */}
        <SideBarLink
          to={'/setting?main=account-settings&sub=profile-settings'}
          onClick={() => {
            handleCloseMenu();
          }}
          style={{
            textDecoration: 'none',
          }}
        >
          <MenuItem
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
          >
            <ListItemText>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  color: '#303439',
                }}
              >{`${user?.firstName} ${user?.lastName}`}</Typography>
            </ListItemText>
            <ListItemText>{user?.email}</ListItemText>
          </MenuItem>
          {/* <MenuItem></MenuItem> */}
        </SideBarLink>
        <SideBarLink
          onClick={() => {
            handleCloseMenu();
          }}
          style={{
            textDecoration: 'none',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              ':hover': { backgroundColor: '#fff' },
              mx: '16px',
            }}
          >
            <Button
              onClick={handlePanelSwitch}
              sx={{
                color: '#303439',
                backgroundColor: '#fff',
                height: '40px',
                width: '100%',
                textTransform: 'capitalize',
                '&:hover': {
                  backgroundColor: '#fff',
                },
                borderRadius: '999px',
                boxShadow:
                  '0px 2px 4px -1px rgb(0 0 0 / 5%), 0px 4px 5px 0px rgb(0 0 0 / 5%), 0px 1px 10px 0px rgb(0 0 0 / 5%), 0px 1px 10px 0px rgb(0 0 0 / 5%)',
                my: 1,
              }}
            >
              Switch to buying
            </Button>
          </Box>
        </SideBarLink>
        <Divider sx={{ bgcolor: '#EEEEEE' }} />
        <SideBarLink
          to={'/setting?main=account-settings&sub=profile-settings'}
          onClick={() => {
            handleCloseMenu();
          }}
          style={{
            textDecoration: 'none',
          }}
        >
          <MenuItem>
            <PersonIcon sx={{ marginRight: '1rem' }} />
            <ListItemText>Profile</ListItemText>
          </MenuItem>
        </SideBarLink>
        <SideBarLink
          onClick={() => {
            handleCloseMenu();
          }}
          to={'/setting?main=account-settings&sub=change-password'}
          style={{
            textDecoration: 'none',
          }}
        >
          <MenuItem>
            <ChangeCircleIcon sx={{ marginRight: '1rem' }} />
            <ListItemText>Edit Password</ListItemText>
          </MenuItem>
        </SideBarLink>
        <MenuItem
          onClick={() => {
            i18n.changeLanguage(i18n.language === 'sv' ? 'en' : 'sv');
            navigate(0);
          }}
        >
          <TranslateIcon sx={{ marginRight: '1rem' }} />
          <ListItemText>
            Translate to {i18n.language === 'sv' ? 'English' : 'Swedish'}
          </ListItemText>
        </MenuItem>

        <MenuItem
          onClick={() => {
            logout().then((res) => {
              if (res.status === 200) {
                dispatch(clearUser())
                  .unwrap()
                  .then((res) => {
                    navigate('/login');
                  });
              }
            });
          }}
        >
          <LogoutIcon sx={{ marginRight: '1rem' }} />
          <ListItemText>Logout</ListItemText>
        </MenuItem>
        <Divider
          sx={{ bgcolor: '#EEEEEE', display: { xs: 'block', sm: 'none' } }}
        />

        <DisableSectionWrapper>
          <MenuItem
            sx={{
              display: { xs: 'block', sm: 'none' },
            }}
          >
            <Grid
              sx={{
                height: '40px',
                display: { xs: 'block', sm: 'none' },
                width: '100%',
              }}
            >
              <TrialButton handleOpenModalTrial={handleOpenModalTrial} />
            </Grid>
          </MenuItem>
        </DisableSectionWrapper>
      </Menu>

      <Box>
        <Box
          component="nav"
          sx={
            {
              // width: { sm: "240px" },
              // flexShrink: { sm: 0 },
              // overflowX: "hidden",
            }
          }
        >
          <Drawer
            // container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', md: 'none' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
                backgroundColor: '#161A33',
                color: '#6c757d',
                fontSize: '24px',
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', md: 'block' },
              '& ::-webkit-scrollbar': { width: '5px' },
              '& ::-webkit-scrollbar-track': {
                // boxShadow: "inset 0 0 5px grey",
                // borderRadius:" 10px",
                backgroundColor: '#292D32',
              },
              '& ::-webkit-scrollbar-thumb': {
                backgroundColor: '#6c757d',
                borderRadius: '10px',
              },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidth,
                backgroundColor: '#161A33',
                color: '#6c757d',
                fontWeight: '700',
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <AppBar
          position="fixed"
          sx={{
            width: { md: `calc(100% - ${drawerWidth}px)` },
            minHeight: '64px',
            // height: '64px',
            ml: { md: `${drawerWidth}px` },
            backgroundColor: 'white',
            justifyContent: { xs: 'center', md: 'center' },
            color: 'black',
            boxShadow:
              '0px 2px 4px -1px rgb(0 0 0 / 5%), 0px 4px 5px 0px rgb(0 0 0 / 5%), 0px 1px 10px 0px rgb(0 0 0 / 5%)',
          }}
        >
          <Toolbar
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              p: '10px 20px !important',
              m: 0,
            }}
          >
            <Box
              sx={{
                display: { xs: 'flex', md: 'none' },
                alignItems: 'center',
              }}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ display: { md: 'none' } }}
              >
                <MenuIcon />
              </IconButton>
              {/* <IconButton
                    aria-label="open drawer"
                    sx={{
                      ml: "16px",
                      color: "white",
                      backgroundColor: "#1976D2",
                      ":hover": { backgroundColor: "#1976D2" },
                    }}
                    size="small"
                    onClick={() => {
                      setDrawerType("add");
                      toggleAddCouponDrawer();
                    }}
                  >
                    <ShareIcon sx={{ display: { xs: "block", md: "none" } }} />
                  </IconButton> */}
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {/* <Button
                    sx={{
                      textTransform: "none",
                      display: { xs: "none", md: "block" },
                    }}
                    variant="contained"
                    onClick={() => {
                      setDrawerType("add");
                      toggleAddCouponDrawer();
                    }}
                  >
                    Share your page
                  </Button>
                  */}
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '10px',
                width: '100%',
                // justifyContent: { md: 'space-between' },
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box>
                <Grid
                  sx={{
                    width: '40px',
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '8px',
                    backgroundColor: openHelp ? '#1976d2' : '#fff',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    color: openHelp ? '#fff' : '#1976d2',
                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                  }}
                >
                  <CustomTooltip arrow title="Share booking widget">
                    <ShareIcon />
                  </CustomTooltip>
                </Grid>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  gap: '10px',
                  justifyContent: { md: 'space-between' },
                  alignItems: 'center',
                }}
              >
                <DisableSectionWrapper>
                  <Grid
                    sx={{
                      height: '40px',
                      display: { xs: 'none', sm: 'block' },
                    }}
                  >
                    <TrialButton handleOpenModalTrial={handleOpenModalTrial} />
                  </Grid>
                </DisableSectionWrapper>
                <Grid
                  sx={{
                    width: '40px',
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '8px',
                    backgroundColor: openHelp ? '#1976d2' : '#fff',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    color: openHelp ? '#fff' : '#1976d2',
                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                  }}
                  onClick={(e) => {
                    if (anchorEl2) {
                      handleClose();
                    } else {
                      handleClick(e);
                    }
                  }}
                >
                  {/* ------------Menu check button starts here---------------- */}
                  <div>
                    <CustomTooltip
                      arrow
                      title="Task"
                      aria-controls={open1 ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open1 ? 'true' : undefined}
                    >
                      <div>
                        <CheckCircleOutlineTwoTone />
                      </div>
                    </CustomTooltip>
                  </div>

                  {/* ------------Menu check button ends here---------------- */}
                </Grid>
                <Grid
                  sx={{
                    width: '40px',
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '8px',
                    backgroundColor: openHelp ? '#1976d2' : '#fff',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    color: openHelp ? '#fff' : '#1976d2',
                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                  }}
                >
                  <CustomTooltip arrow title="Invite users">
                    <PersonAddAltOutlinedIcon
                      onClick={() => {
                        setOpenModal({
                          open: true,
                          type: 'invite-customer-modal',
                        });
                      }}
                    />
                  </CustomTooltip>
                </Grid>
                <Grid
                  sx={{
                    width: '40px',
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '8px',
                    backgroundColor: openHelp ? '#1976d2' : '#fff',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    color: openHelp ? '#fff' : '#1976d2',
                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                  }}
                >
                  <CustomTooltip arrow title="Notifications">
                    <NotificationsNoneOutlinedIcon />
                  </CustomTooltip>
                </Grid>
                <Grid
                  // container
                  // xs={12}
                  onClick={handleClickMenu}
                  sx={{
                    // width: '100%',
                    // justifyContent: 'center',
                    // alignItems: 'center',
                    cursor: 'pointer',
                  }}
                >
                  {/* <Typography
                      variant="subtitle2"
                      noWrap
                      component="div"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: 2,
                      }}
                    >
                      Hello {`${user?.firstName} ${user?.lastName}`}
                    </Typography> */}
                  <AvatarComp
                    // onClick={() => {
                    //   setIsAvatarSelected(!isAvatarSelected);
                    // }}
                    username={`${user?.firstName} ${user?.lastName}`}
                    img={user?.image}
                    sx={{
                      border: open ? '2px solid #1976d2' : 'none',
                    }}
                  />
                </Grid>
                <CustomTooltip arrow title="Help center">
                  <a
                    href={
                      process.env.REACT_APP_ENV === 'TEST'
                        ? `https://test.upnize.com/help-center`
                        : `https://upnize.com/help-center`
                    }
                    target="_blank"
                  >
                    <Grid
                      // onClick={handleClickMenuHelp}
                      sx={{
                        width: '40px',
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '8px',
                        backgroundColor: openHelp ? '#1976d2' : '#fff',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        color: openHelp ? '#fff' : '#1976d2',
                        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                      }}
                    >
                      <HelpOutlineIcon
                        style={
                          {
                            // transform: 'scale(1.3)',
                          }
                        }
                      />
                    </Grid>
                  </a>
                </CustomTooltip>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>

        <Grid
          container
          id="page-body"
          sx={{
            maxWidth: { xs: '100vw', md: `calc(100% - ${drawerWidth}px)` },
            minWidth: { xs: '100vw', md: `calc(100% - ${drawerWidth}px)` },
            mt: '84px',
            ml: { xs: '0px', md: `${drawerWidth}px` },
            backgroundColor: '#F5F7FA',
          }}
        >
          <PublicDrawers />
          <div
            id="system"
            style={{
              backgroundColor: '#F5F7FA',
              width: '100%',
              overflowY: 'hidden',
              minHeight: '90vh',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <ErrorBoundary location={window.location.pathname}>
              <Box sx={{ flex: 1 }}>{children}</Box>
            </ErrorBoundary>

            {isInitialGuide &&
              !initialGuideData.every((data) => data.isDone) && (
                <>
                  <InitialGuide
                    initialGuideData={initialGuideData}
                    openInitialGuide={openInitialGuide}
                  />
                  {initialGuideData.filter((data) => !data.isDone).length >
                    0 && (
                    <Grid
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'fixed',
                        height: '25px',
                        width: '25px',
                        bottom: '5.6rem',
                        right: '2.2rem',
                        background: '#FB3E6E',
                        color: 'white',
                        zIndex: 999,
                        borderRadius: '10px',
                      }}
                    >
                      {initialGuideData.filter((data) => !data.isDone).length}
                    </Grid>
                  )}

                  <Fab
                    aria-label="add"
                    sx={{
                      position: 'fixed',
                      bottom: '3.3rem',
                      right: '2.5rem',
                      background: '#6c70dc',
                      color: 'white',
                      zIndex: 998,
                      '&:hover': { background: '#6b6fe2', color: 'white' },
                    }}
                    onClick={() => setOpenInitialGuide((prev) => !prev)}
                  >
                    <p style={{ fontSize: '25px', textTransform: 'lowercase' }}>
                      i
                    </p>
                  </Fab>
                </>
              )}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                color: '#656565',
              }}
            >
              <Link
                href="https://upnize.com/"
                variant="p"
                sx={{
                  paddingX: '5px',
                  color: '#656565',
                  textDecoration: 'none',
                  '&:hover': {
                    color: colors.blue[900],
                  },
                }}
              >
                © 2022 Upnize
              </Link>
              <p>|</p>
              <Link
                href="https://upnize.com/help-center"
                variant="a"
                target="_blank"
                sx={{
                  paddingX: '5px',
                  color: '#656565',
                  textDecoration: 'none',
                  '&:hover': {
                    color: colors.blue[900],
                  },
                }}
              >
                Need help?
              </Link>
              <p>|</p>
              <Link
                href="https://upnize.com/terms-of-service"
                variant="a"
                target="_blank"
                sx={{
                  paddingX: '5px',
                  color: '#656565',
                  textDecoration: 'none',
                  '&:hover': {
                    color: colors.blue[900],
                  },
                }}
              >
                Terms of service
              </Link>
            </Box>
          </div>
        </Grid>
      </Box>
    </>
  );
};

export default System;
