import { Button, Grid, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const DeleteModal = ({ handleOpenModal, setDataId }) => {
  const handelRemove = () => {
    handleOpenModal();
    setDataId(true);
  };
  return (
    <Grid
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        boxShadow: 24,
        padding: '15px 25px',
      }}
    >
      <Grid container flexDirection="column" alignItems="center">
        <DeleteIcon
          sx={{ fontSize: 65, marginBottom: '20px', marginTop: '45px' }}
        />
        <Typography sx={{ fontSize: 21, fontWeight: 'bold' }}>
          Are you sure you want to remove?
        </Typography>
      </Grid>

      <Grid container sx={{ justifyContent: 'center' }}>
        <Button
          sx={{
            width: { xs: '100%', sm: 130 },
            marginTop: { xs: 1, sm: 5 },
            marginBottom: 1,
            backgroundColor: 'white',
            color: '#b9a6a6',
            height: '45px',
            borderRadius: '2px',
            fontSize: '12px',
            fontWeight: 600,
            ':hover': {
              backgroundColor: '#b9a6a6',
              color: 'white',
            },
          }}
          variant="contained"
          onClick={handleOpenModal}
        >
          Cancel
        </Button>
        <Button
          sx={{
            width: { xs: '100%', sm: 130 },
            marginTop: { xs: 1, sm: 5 },
            marginBottom: 1,
            marginLeft: { xs: 0, sm: 2 },
            backgroundColor: '#c63f44',
            height: '45px',
            borderRadius: '2px',
            fontSize: '12px',
            fontWeight: 600,
            ':hover': {
              backgroundColor: '#8c2428',
            },
          }}
          variant="contained"
          onClick={handelRemove}
        >
          Remove
        </Button>
      </Grid>
    </Grid>
  );
};

export default DeleteModal;
