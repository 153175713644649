import { useEffect, useState, useMemo } from 'react';
import { Checkbox, FormControlLabel, Grid, styled } from '@mui/material';
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import { getLocationList } from '../../../Api/tenant/locations';
import NoData from '../../../components/NoData';
import { CustomPagination } from '../../../components/CustomPagination';
import classes from './Staff.module.css';
import { debounce } from 'lodash';

const DataGrid = styled(MuiDataGrid)(({ theme }) => ({
  '& .MuiDataGrid-columnHeaders': { display: 'none' },
  '& .MuiDataGrid-virtualScroller': {
    marginTop: '0!important',
    marginBottom: '0!important',
  },
}));

export const StaffLocations = ({ locations, setLocations }) => {
  const columns = [
    {
      field: 'service',
      width: 150,
      flex: 1,
      renderCell: (params) => (
        <Grid
          container
          sx={{
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>{params.row.name}</div>
          <FormControlLabel
            control={
              <Checkbox
                checked={locations.some(
                  (location) => location.id == params.row.id
                )}
                onChange={handleChangeSelected}
                name={params.row.id}
              />
            }
          />
        </Grid>
      ),
    },
  ];
  const [staffOptions, setStaffOptions] = useState({
    locations: [],
  });
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [tableLoading, setTableLoading] = useState(true);
  const [rowCount, setRowCount] = useState(0);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleChangeSelected = (event) => {
    const { name, checked } = event.target;
    setLocations((prevSelected) =>
      checked
        ? [...prevSelected, { id: Number(name) }]
        : prevSelected.filter((item) => item.id !== Number(name))
    );
  };

  // debounced search event
  const debouncedOnChangeHandler = useMemo(
    () =>
      debounce((e) => {
        setSearchQuery(e.target.value);
        setPage(1);
      }, 300),
    []
  );

  //cleanup for debounce
  useEffect(() => {
    return () => {
      debouncedOnChangeHandler.cancel();
    };
  }, []);

  // API
  const fetchRequiredData = async () => {
    const locations = await getLocationList(10, page);
    setRowCount(locations.data.count);

    return {
      locations: locations.data.rows,
    };
  };

  const handleSearch = async () => {
    const locations = await getLocationList(1000, 1, `&keyword=${searchQuery}`);
    setRowCount(locations.data.count);

    return {
      locations: locations.data.rows,
    };
  };

  useEffect(() => {
    if (!searchQuery) {
      fetchRequiredData().then((data) => {
        setStaffOptions(data);
        setTableLoading(false);
      });
    }
  }, [page, searchQuery]);

  useEffect(() => {
    if (searchQuery) {
      setTableLoading(true);
      handleSearch().then((data) => {
        setStaffOptions(data);
        setTableLoading(false);
      });
    }
  }, [searchQuery]);

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          marginTop: { xs: '15px' },
          paddingBottom: { xs: '30px' },
          borderBottom: { xs: '1px solid #e3eaf3' },
        }}
      >
        <div className={classes['input-wrap']}>
          <input
            onChange={(event) => {
              debouncedOnChangeHandler(event);
            }}
            className={classes.input}
            type="text"
            placeholder="Search"
          />
          <div className={classes['icon-wrap']}>
            <SearchIcon className={classes.icon} />
          </div>
        </div>
      </Grid>
      <DataGrid
        sx={{
          '& .coloured': { textAlign: 'center', color: '#7181AD' },
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
            height: '400px',

            display: 'none',
          },
          '&.MuiDataGrid-columnHeaders, &.MuiDataGrid-columnHeader': {
            display: 'none !important',
          },
          '&.MuiDataGrid-virtualScroller': {
            marginTop: '0 !important',
          },
          minHeight: staffOptions.locations.length === 0 && '300px !important',
        }}
        loading={tableLoading}
        rows={staffOptions.locations}
        columns={columns}
        pageSize={10}
        page={page - 1}
        autoHeight={true}
        onPageChange={(params) => handlePageChange(params.page + 1)}
        components={{
          NoRowsOverlay: NoData,
          NoResultsOverlay: NoData,
          Pagination: () => (
            <CustomPagination
              rowCount={rowCount}
              page={page}
              onPageChange={handlePageChange}
            />
          ),
        }}
      />
    </div>
  );
};
