import { Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";

const Extras = ({ subServices }) => {
  // const [extras, setExtras] = useState([
  //   { id: 1, name: "sdassd", value: "", minQuantity: 1, maxQuantity: 10 },
  //   { id: 2, title: "fffffff", value: "", minQuantity: 2, maxQuantity: 5 },
  // ]);
  const [extras, setExtras] = useState(subServices || []);
  const isExtra = extras.length != 0;

  const handleTextField = (id) => {
    return (e) => {
      let value = e.target.value;

      setExtras((prevState) => {
        return prevState.map((extra) => {
          if (extra.id !== id) return extra;

          return {
            ...extra,
            value: isNaN(value) || value <= 0 ? 0 : parseInt(value),
          };
        });
      });
    };
  };
  return (
    <>
      {isExtra ? (
        <Grid sx={{ marginTop: "20px" }}>
          {extras.map((extra, index) => (
            <Grid
              key={`extra-${index}`}
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                alignItems: { xs: "flex-start", md: "center" },
                justifyContent: "space-between",
                boxShadow: "0 0 25px 0 rgb(0 0 0 / 5%)",
                padding: "20px",
                marginBottom: "20px",
                background:
                  "linear-gradient(to right,#c0d6df 50%,transparent 0%),linear-gradient(#c0d6df 50%,transparent 0%),linear-gradient(to right,#c0d6df 50%,transparent 0%),linear-gradient(#c0d6df 50%,transparent 0%)",
                backgroundPosition: "top,right,bottom,left",
                backgroundRepeat: "repeat-x,repeat-y",
                backgroundSize: "8px 1px,1px 8px",
              }}
            >
              <Typography sx={{ fontSize: "14px" }}>{extra.name}</Typography>
              <Grid
                sx={{
                  display: "flex",
                  width: { xs: "100%", md: "auto" },
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: { xs: "flex-start", md: "center" },
                  fontSize: "14px",
                }}
              >
                <TextField
                  size="small"
                  sx={{
                    margin: { xs: "10px 0", md: "0px" },
                    width: { xs: "100%", md: "auto" },
                  }}
                  inputProps={{ style: { fontSize: "14px", padding: "10px" } }}
                  value={extra.value}
                  onChange={handleTextField(extra.id)}
                />
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#828f9a",
                    marginLeft: { xs: "0px", md: "15px" },
                    width: "230px",
                  }}
                >
                  {`(`}max quantity: {extra.maxQuantity}, min quantity:{" "}
                  {extra.minQuantity}
                  {`)`}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid sx={{ display: "flex", justifyContent: "center" }} container>
          <Typography variant="subtitle2" color="#828f9a">
            No extras found
          </Typography>
        </Grid>
      )}
    </>
  );
};

export default Extras;
