import React, { useEffect, useRef, useState } from 'react';
import {
  Autocomplete,
  Box,
  Grid,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Link } from 'react-router-dom';
import { IOSSwitch } from '../../../components/CustomCheckbox';

// TODO: Use it if we need to set the tsc
{
  // const [currentLang, setCurrentLang] = useState({
  //   label: 'ENG',
  //   text: 'Write here your company’s terms and conditions for buyers…',
  // });
  // const [quillHtml, setQuillHtml] = useState('');
  // const langs = [
  //   {
  //     label: 'ENG',
  //     text: 'Write here your company’s terms and conditions for buyers…',
  //   },
  //   { label: 'SV', text: 'Skriv företags köp villkor…' },
  //   { label: 'ESP', text: 'Escribir condiciones de compra de la empresa...' },
  //   { label: 'DE', text: 'Firmeneinkaufsbedingungen schreiben…' },
  // ];
  //
  /* <ReactQuill
value={quillHtml}
theme="snow"
modules={modules}
// formats={formats}
placeholder={currentLang.text}
// onChange={handleProcedureContentChange}
style={{ height: '100%' }}
ref={placeholderRef}
></ReactQuill> */
}

const settingsItemsForRender = [
  {
    title: 'Make my company visible on website',
    checkboxTitle: 'Visible',
    key: 'website',
    tooltip: 'test',
  },
  {
    title: 'Make my service visible',
    checkboxTitle: 'View services',
    key: 'services',
  },
  {
    title: 'View my restaurant menu',
    checkboxTitle: 'View restaurant food menu',
    key: 'menus',
  },
  {
    title: 'Show my product list',
    checkboxTitle: 'View products',
    key: 'products',
  },
  {
    title: "Allow people to read customer's reviews",
    checkboxTitle: 'View reviews',
    key: 'reviews',
  },
  {
    title: 'Show header image',
    checkboxTitle: 'Upload image 1920x300',
    key: 'headerImage',
  },
  {
    title: 'Allow people to read the terms & conditions',
    checkboxTitle: 'Terms and conditions',
    key: 'tcs',
  },
  {
    title: 'Make my booking widget visible',
    checkboxTitle: 'View booking widget',
    key: 'bookingWidget',
  },
  {
    title: 'Show staff member availability',
    checkboxTitle: 'View staff members',
    key: 'staff',
  },
  {
    title: 'Show weekly lunch menu',
    checkboxTitle: 'View lunch menu',
    key: 'weeklyLunch',
  },
  {
    title: 'Show product price',
    checkboxTitle: 'View price',
    key: 'productPrices',
  },
  {
    title: 'Allow people to read about the company',
    checkboxTitle: 'About company',
    key: 'about',
  },
  {
    title: 'Show interior and exterior images',
    checkboxTitle: 'Upload images 500x300',
    key: 'IntExtImages',
  },
];

function LocationSettings({ locationSettings, setLocationSettings }) {
  return (
    <>
      <Grid
        container
        style={{
          backgroundColor: 'white',
          marginTop: '20px',
        }}
      >
        {settingsItemsForRender.map((sett) => {
          return (
            <Grid
              xs={12}
              md={6}
              sx={{
                paddingRight: { md: 1 },
                marginBottom: '30px',
              }}
            >
              <Grid
                item
                sx={{ marginY: 1, display: 'flex', alignItems: 'center' }}
              >
                <Typography variant="subtitle2" sx={{ fontSize: '14px' }}>
                  {sett.title}
                </Typography>
                {sett.tooltip && (
                  <InfoIcon
                    sx={{
                      fontSize: '14px',
                      color: '#857C93',
                      marginLeft: '5px',
                    }}
                  />
                )}
              </Grid>
              <Box
                style={{ cursor: 'pointer' }}
                item
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  border: '1px solid rgba(0, 0, 0, 0.23)',
                  borderRadius: '4px',
                  paddingX: 2,
                  height: '38px',
                }}
                onClick={() => {
                  setLocationSettings((prev) => {
                    return { ...prev, [sett.key]: !prev[sett.key] };
                  });
                }}
              >
                <Typography variant="subtitle2">
                  {sett.checkboxTitle}
                </Typography>
                <IOSSwitch
                  size="small"
                  defaultChecked
                  sx={{ marginLeft: 2 }}
                  checked={locationSettings[sett.key]}
                />
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}

export default LocationSettings;
