import { Grid, Typography } from '@mui/material'
import React from 'react'

const Rating = () => {
    return (
        <Grid sx={{ boxShadow: " rgba(149, 157, 165, 0.2) 0px 8px 24px", padding: '2rem' ,margin:"1rem"}}>
            <Grid sx={{marginTop:'1rem'}}>
                <Typography>Rated:</Typography>
                <span> - </span>
            </Grid>
            <Grid sx={{marginTop:'1rem'}}>
                <Typography>Comment:</Typography>
                <span> - </span>
            </Grid>
        </Grid>
    )
}

export default Rating