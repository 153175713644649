import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import SaveSlideBar from '../../SaveSlideBar';
import { Box } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridMoreVertIcon } from '@mui/x-data-grid';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { styled } from '@mui/material/styles';
import { gridClasses } from '@mui/x-data-grid';
import CustomTableFooter from '../../CustomTableFooter';
import { editSettings } from '../../../Api/tenant/settings';
import AddCategories from './AddCategories';
import Modal from '../../Modal';
import {
  deleteCategoryById,
  editCategoryById,
  getCategoryList,
} from '../../../Api/tenant/categories';
import useCategory from '../../../hooks/useCategory';
import CustomSettingsTitle from '../../CustomSettingsTitle/CustomSettingsTitle';
import AvatarComp from '../../Avatar';
import CustomTextButton from '../../CustomButtons/CustomTextButton';

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    // backgroundColor: theme.palette.grey[200],
    color: theme.palette.grey[500],
  },
}));

const SubCategorySettings = ({ fetchCurrentSettings, currentSettings }) => {
  const { categories } = useCategory();

  const catRef = useRef();
  const [catName, setCatName] = useState('');
  const [catType, setCatType] = useState('');
  const [catParentId, setCatParentId] = useState();

  const [tableLoading, setTableLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [subCategories, setSubCategories] = useState(null);

  const fetchCategories = useCallback(async () => {
    try {
      setTableLoading(true);
      const { data } = await getCategoryList(100, 1);
      console.log(data);
      setSubCategories(data.filter((cat) => !cat.level));
      setTableLoading(false);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  // update table rows on fetch
  useEffect(() => {
    if (subCategories) {
      setRows(subCategories);
      setTableLoading(false);
    }
  }, [subCategories]);
  const deleteCategory = async (id) => {
    deleteCategoryById(id).then((res) => {
      fetchCategories();
    });
  };
  const editCategory = async (catName, id, type = null) => {
    editCategoryById(catName, id).then((res) => {
      fetchCategories();
      // console.log(res);
    });
  };
  const editCategoryParent = useCallback(async (body, id, type = null) => {
    editCategoryById(body, id).then((res) => {
      fetchCategories();
      // console.log(res);
    });
  }, [fetchCategories]);
  const [isSaveBar, setIsSaveBar] = useState(false);
  const [context, setContext] = useState({
    timeSlotLength: '',
    sLengthSD: '',
    mtrptb: '',
    lbd: '',
    weekStart: '',
    dFormat: '',
    tFormat: '',
    das: '',
    showTimeSlots: true,
    linkExpire: '',
    timezone: '',
    adminBook: false,
    registredBook: true,
  });

  const clearParentCategory = useCallback(async (categoryId) => {
    try {
      await editCategoryById(
        {
          parentId: null,
        },
        categoryId
      );
      fetchCategories();
    } catch (err) {
      console.log(err);
    }
  }, [fetchCategories]);

  useLayoutEffect(() => {
    setContext(currentSettings.GeneralSettings);
  }, []);

  const columns = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'CATEGORY NAME',
        flex: 1,
        minWidth: 180,
      },
      {
        field: 'category',
        headerName: 'PARENT GATEGORY',
        minWidth: 200,
        flex: 1,
        renderCell: (params) => (
          <Box sx={{ width: '100%' }}>
            <Autocomplete
              ref={catRef}
              sx={{
                width: '100%',
                '& fieldset': { borderRadius: 1.5 },
                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                  border: '0px solid #eee',
                  outline: 'none',
                },
                '& .MuiAutocomplete-input': {
                  paddingLeft: '0px !important',
                },
                '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
                  paddingLeft: '0px !important',
                },
                // '& .css-vputpu-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input':
                //   {
                //     padding: '100px',
                //   },
              }}
              size="small"
              // disablePortal
              id="combo-box-demo"
              // options={categories}
              options={categories.sort(
                (a, b) => -b.type?.localeCompare(a.type)
              )}
              groupBy={(option) => option.type || 'UnAssigned To Page Type'}
              getOptionLabel={(option) => option.name}
              value={
                categories.filter((cat) => cat.id === params.row.parentId)[0] ||
                null
              }
              onChange={(e, v) => {
                catRef.current = v;
                editCategoryParent(
                  { parentId: catRef.current.id },
                  params.row.id
                );
              }}
              // sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  sx={{
                    border: 'none',
                    outline: 'none',
                  }}
                  {...params}
                  placeholder="Select category"
                />
              )}
            />
            {/* </FormControl> */}
          </Box>
        ),
      },
      /*
    {
      field: 'usage',
      headerName: 'USAGE',
      flex: 1,
      minWidth: 90,
    },
    */
      {
        field: 'UserId',
        headerName: 'CREATED BY',
        flex: 1,
        minWidth: 190,
        renderCell: (params) => {
          const user = params.row.User;

          if (!user) return <></>;

          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <AvatarComp
                img={user?.image}
                username={`${user?.firstName} ${user?.lastName}`}
              />
              <Typography fontSize="14px" marginLeft={'10px'} color="inherit">
                {`${user?.firstName} ${user?.lastName}`}
              </Typography>
            </Box>
          );
        },
      },
      {
        field: 'createdAt',
        headerName: 'CREATED',
        flex: 1,
        minWidth: 100,
        renderCell: (params) => {
          let d = new Date(params.row.createdAt),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
          if (month.length < 2) month = '0' + month;
          if (day.length < 2) day = '0' + day;
          return [year, month, day].join('-');
        },
      },
      {
        field: 'actions',
        headerName: 'ACTIONS',
        type: 'actions',
        align: 'right',
        minWidth: 120,
        getActions: (params) => {
          let removeParentCategoryBtn = <></>;

          if (params.row.parentId) {
            removeParentCategoryBtn = (
              <GridActionsCellItem
                label="Clear parent category"
                onClick={() => clearParentCategory(params.id)}
                showInMenu
              />
            );
          }
          if (params.row.isHidden) {
            return [
              <GridActionsCellItem
                icon={<VisibilityOffIcon />}
                label="Hide Section"
              />,
              <GridActionsCellItem
                label="Edit"
                onClick={() => {
                  setCatName(params.row.name);
                  setOpenModal({
                    open: true,
                    type: 'editSub-modal',
                    id: params.id,
                  });
                }}
                showInMenu
              />,

              <GridActionsCellItem
                label="Remove"
                onClick={() => {
                  setOpenModal({
                    open: true,
                    type: 'delete-modal',
                    id: params.id,
                  });
                }}
                showInMenu
              />,
              <GridActionsCellItem
                label="Clear parent category"
                onClick={() => clearParentCategory(params.id)}
                showInMenu
              />,
            ];
          }
          return [
            <GridActionsCellItem
              label="Edit"
              onClick={() => {
                setCatName(params.row.name);
                setCatType(params.row.type);
                setCatParentId(params.row.parentId);
                setOpenModal({
                  open: true,
                  type: 'editSub-modal',
                  id: params.id,
                });
              }}
              showInMenu
            />,
            <GridActionsCellItem
              label="Remove"
              onClick={() => {
                setOpenModal({
                  open: true,
                  type: 'delete-modal',
                  id: params.id,
                });
              }}
              showInMenu
            />,
            removeParentCategoryBtn,
          ];
        },
      },
    ],
    [categories, clearParentCategory, editCategoryParent]
  );
  const [selectionModel, setSelectionModel] = useState([]);
  const [drawerType, setDrawerType] = useState('add');
  const [openModal, setOpenModal] = useState({ open: false, type: null });
  const [addCouponDrawer, setAddCouponDrawer] = useState(false);
  const [drawerData, setDrawerData] = useState(null);

  const toggleAddCouponDrawer = () => {
    setAddCouponDrawer(!addCouponDrawer);
  };
  const handleOpenModal = () => {
    setOpenModal({ open: false, type: null });
  };
  const handleDeleteOpenModal = () => {
    setOpenModal({ open: true, type: 'delete-modal' });
  };

  const handelHide = (id) => {
    const data = [...rows];
    const info = data.find((item) => item.id === id);
    info.isHidden = !info.isHidden;
    setRows(data);
  };
  const handelHidedata = (o) => {
    handelHide(o.id);
  };
  // click handlers
  const handleSave = () => {
    editSettings({ ...currentSettings, GeneralSettings: context }).then(
      (res) => {
        if (res.status === 200) {
          fetchCurrentSettings();
          setIsSaveBar(false);
        }
      }
    );
  };

  const handleCancel = () => {
    setContext(currentSettings.GeneralSettings);
    setIsSaveBar(false);
  };

  return (
    <>
      <Grid
        xs={12}
        sx={{
          marginTop: { xs: 9, sm: 14 },
          backgroundColor: 'white',
          pb: 1,
          mb: 5,
        }}
      >
        {openModal.type === 'delete-modal' && (
          <Modal
            handleOpenModal={handleOpenModal}
            type={'DELETE'}
            open={openModal.open}
          />
        )}
        {openModal.type === 'delete-modal' && (
          <Modal
            handleOpenModal={handleOpenModal}
            type={'DELETE'}
            open={openModal.open}
            id={openModal.id}
            handleDelete={deleteCategory}
          />
        )}
        {openModal.type === 'addSub-modal' && (
          <Modal
            handleOpenModal={handleOpenModal}
            type={'ADDSUB'}
            open={openModal.open}
            id={openModal.id}
            fetchCategories={fetchCategories}
          />
        )}
        {openModal.type === 'editSub-modal' && (
          <Modal
            handleOpenModal={handleOpenModal}
            type={'EDITSUB'}
            open={openModal.open}
            id={openModal.id}
            handleEdit={editCategory}
            catName={catName}
            catType={catType}
            catParentId={catParentId}
            fetchCategories={fetchCategories}
          />
        )}
        {addCouponDrawer && (
          <AddCategories
            toggleDrawer={toggleAddCouponDrawer}
            drawerType={drawerType}
            drawerData={drawerData}
            handelHide={handelHide}
            // fetchServices={fetchServices}
          />
        )}
        <Grid
          sx={{
            padding: { xs: '15px', md: '15px 25px' },
            borderBottom: '1px solid #e3eaf3',
          }}
        >
          <CustomSettingsTitle title="Process" subTitle="Subcategory" />
        </Grid>
        {/* here */}
        <Box sx={{mt:2, ml: 2}}>
        <CustomTextButton
          text="+ subcategory"
          onClick={() => {
            // setDrawerType('addSub');
            // toggleAddCouponDrawer();
            setOpenModal({ open: true, type: 'addSub-modal' });
          }}
        />
        </Box>
        <Grid
          container
          item
          justifyContent="space-between"
        >
          <div style={{ width: '100%' }}>
            <StripedDataGrid
              disableSelectionOnClick
              sx={{
                border: 0,
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontSize: '12px',
                  fontWeight: '900 !important',
                },
                '& .MuiDataGrid-main, .MuiDataGrid-footerContainer': {
                  backgroundColor: 'white',
                  border: 'none',
                },
                '& .MuiDataGrid-columnHeader:focus': {
                  outline: 'none',
                  border: 'none',
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                  border: 'none',
                },
                minHeight: '60vh',
              }}
              getRowClassName={(params) => {
                return params.row.isHidden ? 'even' : 'odd';
              }}
              rows={rows}
              columns={columns}
              loading={tableLoading}
              checkboxSelection
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              selectionModel={selectionModel}
              autoHeight
              components={{
                Footer: () => (
                  <CustomTableFooter
                    selectionModel={selectionModel}
                    handleDeleteOpenModal={handleDeleteOpenModal}
                  />
                ),
                MoreActionsIcon: () => (
                  <GridMoreVertIcon
                    sx={{
                      backgroundColor: 'white',
                      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 6px',
                      borderRadius: '4px',
                      padding: '3px',
                      cursor: 'pointer',
                    }}
                  />
                ),
              }}
            />
          </div>
        </Grid>
      </Grid>
      <SaveSlideBar
        handleCancel={handleCancel}
        handleSave={handleSave}
        isSaveBar={isSaveBar}
        setIsSaveBar={setIsSaveBar}
      />
    </>
  );
};

export default SubCategorySettings;
