import { Box, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { createRef } from 'react';
import { useLayoutEffect } from 'react';
import classes from './Providers.module.css';

function Chip({
  params,
  minServices,
  setMinServices,
  handleShowmore,
  cuttingHeight,
}) {
  const serviceFieldRef = createRef();
  const [services, setServices] = useState(params.value);
  const [isShowmore, setIsShowmore] = useState(false);

  useLayoutEffect(() => {
    if (serviceFieldRef.current.clientHeight > cuttingHeight) {
      setIsShowmore(true);
      setMinServices(minServices - 1);
      setServices(params.value.slice(0, minServices));
    }
  }, [serviceFieldRef]);

  return (
    <Box className={classes.service__container} ref={serviceFieldRef}>
      {services &&
        services.map((s) => (
          <Box className={classes.service__chip}>{s.name}</Box>
        ))}
      {isShowmore && (
        <Box
          color={'#8F76FA'}
          sx={{ cursor: 'pointer' }}
          onClick={handleShowmore}
        >
          Show more
        </Box>
      )}
    </Box>
  );
}

function ServiceChip({ params, handleShowmore }) {
  const [minServices, setMinServices] = useState(6);
  const mediaQuery700 = useMediaQuery('(min-width:700px)');
  const [cuttingHeight, setCuttingHeight] = useState(mediaQuery700 ? 64 : 120);

  return (
    <Chip
      params={params}
      minServices={minServices}
      setMinServices={setMinServices}
      handleShowmore={handleShowmore}
      mediaQuery700={mediaQuery700}
      cuttingHeight={cuttingHeight}
    />
  );
}

export default ServiceChip;
